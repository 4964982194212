import React from 'react';
import PropTypes from 'prop-types';
import styles from './LibraryMaterialsList.module.css';
import LibraryMaterialsListItem from './ListItem';

const LibraryMaterialsList = ({ data }) => {
  return (
    <div className={styles.container}>
      {data.map((item, index) => <LibraryMaterialsListItem data={item} key={index}/>)}
    </div>
  );
};

LibraryMaterialsList.propTypes = {
  data: PropTypes.array,
};

export default LibraryMaterialsList;
