import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bookDataRequestStart } from "../../redux/actions/bookData/data";
import "./CatalogCard.css";
import CatalogCardBody from './CatalogCardBody';

const mapStateToProps = ({ bookData }) => ({
  data: bookData
});

const matchDispatchToProps = dispatch => ({
  requestData: id => dispatch(bookDataRequestStart(id)),
});

const CatalogCard = ({ match, data, requestData }) => {

  console.log(data);
  useEffect(() => requestData(match.params.id), [requestData]);
  useEffect(() => {if (data) window.print()}, [data]);



  const calculateAuthorsArrays = (arr) => {
    let sorted = arr.map(item => item.name);

    return sorted.map((item, index) => ([
      sorted[index],
      ...sorted.filter(itm => itm !== item).sort()
    ]));
  };

  return (<>
    {data && (data.authors.length ?
      calculateAuthorsArrays(data.authors).map(item => <CatalogCardBody data={data} authors={item}/>)
     :
      <CatalogCardBody data={data} authors={data.authors} />
    )}
  </>);
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(CatalogCard);
