import { ERROR, LIBRARY_SAVE_REQUEST, START, SUCCESS } from "../../action-types";

export const librarySaveRequestStart = (data, callback) => ({
  type: `${LIBRARY_SAVE_REQUEST}${START}`,
  payload: data, callback
});

export const librarySaveRequestSuccess = (data) => ({
  type: `${LIBRARY_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const librarySaveRequestError = (data) => ({
  type: `${LIBRARY_SAVE_REQUEST}${ERROR}`,
  payload: data
});

