import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { OPTIONS_LOGO_GET_REQUEST, OPTIONS_LOGO_SAVE_REQUEST, START } from "../../../actions/action-types";
import {
  optionsLogoGetRequestError,
  optionsLogoGetRequestStart,
  optionsLogoGetRequestSuccess
} from "../../../actions/options/logo/get";
import { getOptionsLogo, saveOptionsLogo } from "../../../../api/options/optionsLogo";
import { optionsSaveRequestError, optionsSaveRequestSuccess } from "../../../actions/options/save";

export default function* optionsLogoWatcher() {
  yield takeLatest(`${OPTIONS_LOGO_GET_REQUEST}${START}`, optionsGetLogoWorker);
  yield takeLatest(`${OPTIONS_LOGO_SAVE_REQUEST}${START}`, optionsSaveLogoWorker);
}

function* optionsGetLogoWorker() {
  try {
    const response = yield call(getOptionsLogo);
    const url = `data:image/png;base64, ${Buffer.from(response.data, 'binary')
      .toString('base64')}`;
    yield put(optionsLogoGetRequestSuccess(url));
  } catch (error) {
    yield put(optionsLogoGetRequestError(error))
  }
}

function* optionsSaveLogoWorker({payload: image}) {
  try {
    const response = yield call(saveOptionsLogo, image);
    yield put(optionsSaveRequestSuccess(response.data));
    yield put(optionsLogoGetRequestStart());
  } catch (error) {
    yield put(optionsSaveRequestError(error));
  }
}