import React from 'react';
import PropTypes from 'prop-types';
import styles from './PublishersTable.module.css';
import { ADD, EDIT } from "../../../constants/modalTypes";
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";

const PublishersTable = ({ initialFormFields, data, setModal, selectedId, handleSelect, onDelete }) => {
  const openEditModal = (item) => setModal({ modalType: EDIT, data: item });
  const openCreationModal = () => setModal({ modalType: ADD, data: initialFormFields });

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <div className={styles.button} onClick={openCreationModal}>
          добавить
        </div>
        <div className={styles.button} onClick={onDelete}>
          удалить
        </div>
      </div>
      <DataTable data={data} onCellClick={openEditModal}>
        <DataTableCol title="Издательство" value="name" colSpan={3}/>
        <DataTableCol title="Полное название" value="fullName" colSpan={4}/>
        <DataTableCol title="Город" value="city" colSpan={2}/>
        <DataTableCol title="Префикс ISBN" value="isbn" colSpan={2}/>
        <DataTableCol title="Выбрано" passClicks resolver={(item) =>
          <input type="checkbox" checked={item.id === selectedId}
                 onChange={() => handleSelect(item.id)}/>}
        />
      </DataTable>
    </div>
  );
};

PublishersTable.propTypes = {
  data: PropTypes.array,
  setModal: PropTypes.func,
  initialFormFields: PropTypes.object
};

PublishersTable.defaultProps = {
  data: []
};

export default PublishersTable;
