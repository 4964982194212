import {
  AUTH_REQUEST,
  RESET_AUTH_DATA, START, SUCCESS, ERROR
} from "../action-types";

export const authRequestStart = (username, password) => ({
  type: `${AUTH_REQUEST}${START}`,
  payload: { username, password }
});

export const authRequestSuccess = (data) => ({
  type: `${AUTH_REQUEST}${SUCCESS}`,
  payload: data
});

export const authRequestError = (data) => ({
  type: `${AUTH_REQUEST}${ERROR}`,
  payload: data
});

export const resetAuthData = () => ({
  type: RESET_AUTH_DATA
});

