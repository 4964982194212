import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Modal.module.css';

const Modal = ({ title, isVisible, children, className, contentClassName }) => {
  if (!isVisible) return null;

  return (
    <>
      <div className={styles.background}/>
      <div className={styles.container}>
        <div className={classNames(styles.modal, className)}>
          <div className={styles.modalHead}>
            {title}
          </div>
          <div className={classNames(styles.modalContent, contentClassName)}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  contentClassName: PropTypes.string
};

export default Modal;
