import React, { useState, useEffect } from 'react';
import styles from './DynamicCollectionFormItem.module.css';
import PropTypes from 'prop-types';
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../../Modal/InputWrapper";

const emptyData = {
  field: "",
  fieldtext: ""
};

const DynamicCollectionFormItem = ({ data, onSave, onDelete }) => {

  const [formData, setFormData] = useState(data || emptyData);

  useEffect(() => {
    if (!data) {
      setFormData(emptyData);
      return;
    }
    setFormData({ ...data });
  }, [data]);

  return (
    <div className={styles.container}>
      <Row>
        <Col md={4}>
          <ModalInputWrapper
            label={"Поле"}
            labelSpan={12}
            inputSpan={12}
            value={formData.field}
            name={"field"}
            onChange={({ target }) => setFormData({ ...formData, [target.name]: target.value })}/>
        </Col>
        <Col md={4}>
          <ModalInputWrapper
            label={"Значение"}
            labelSpan={12}
            inputSpan={12}
            value={formData.fieldtext}
            name={"fieldtext"}
            onChange={({ target }) => setFormData({ ...formData, [target.name]: target.value })}/>
        </Col>
        <Col md={2}>
          <div className={styles.buttonContainer}>
            <div className={styles.button} onClick={() => onSave(formData)}>
              сохранить
            </div>
          </div>
        </Col>
        <Col md={2}>
          <div className={styles.buttonContainer}>
            {(formData && formData.id !== undefined) &&
            <div className={styles.button} onClick={() => onDelete(formData.id)}>
              удалить
            </div>}
          </div>
        </Col>
      </Row>
    </div>
  );
};

DynamicCollectionFormItem.propTypes = {
  data: PropTypes.object,
  onSave: PropTypes.func,
  onDelete: PropTypes.func
};

export default DynamicCollectionFormItem;
