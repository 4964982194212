import React from 'react';
import PropTypes from 'prop-types';
import styles from './SearchAuditForm.module.css'
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";

const SearchAuditForm = ({ data, setData, search }) => {
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  return (
    <div className={styles.container}>
      <Row>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="from"
                             value={data.from}
                             label="С (дата)"
                             type="date"
                             labelSpan={12}
                             inputSpan={12}/>
        </Col>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="to"
                             value={data.to}
                             label="До (дата)"
                             type="date"
                             labelSpan={12}
                             inputSpan={12}/>
        </Col>
        <Col md={12} className={"text-right"}>
          <Button label={"Найти"} onClick={search}/>
        </Col>
      </Row>
    </div>
  );
};

SearchAuditForm.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  search: PropTypes.func,
  dataset: PropTypes.array
};

export default SearchAuditForm;
