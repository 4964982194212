import React from 'react';
import PropTypes from 'prop-types';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";


const SchedulerList = ({data, setModalData}) => {
    return (
        <DataTable data={data} onCellClick={item => setModalData(item)}>
            <DataTableCol title={'Название'} value={'schedulename'}/>
            <DataTableCol title={'Комментарий'} value={'schedulecomment'}/>
            <DataTableCol title={'Статус'} value={'status'}/>
            <DataTableCol title={'Включен'} resolver={item =>
                <input type="checkbox" checked={item.enabled}/>
            }/>
        </DataTable>
    );
};

SchedulerList.propTypes = {
    data: PropTypes.array,
    setModalData: PropTypes.func,
};

export default SchedulerList;