import React, {useRef, useEffect, useState} from "react";
import {connect} from "react-redux";
import styles from "./ExpertOpinion.module.css";
import {pagesActions} from "../../../../redux/factory/PagesReduxData";
import ExportOpinionTable from "./Table";

const mapStateToProps = ({pages}) => ({
    list: pages.list
});

const matchDispatchToProps = dispatch => ({
    saveImg: data => dispatch(pagesActions.savePageRequestStart(data)),
    getList: id => dispatch(pagesActions.listRequestStart(id)),
    delOpinion: id => dispatch(pagesActions.deleteRequestStart(id)),
    editOpinion: (id, data) =>
        dispatch(pagesActions.editRequestStart({id, data}))
});

const BookEditPages = ({saveImg, bookId, list, getList, delOpinion, editOpinion}) => {
    useEffect(() => {
        getList(bookId)
    }, [bookId]);

    const [selectedId, setSelectedId] = useState(null);
    const [inputData, setInputData] = useState({name: "", ordering: ""});

    const addImg = () => {
        ref.current.click();
    };

    const handleSelect = id => {
        id === selectedId ? setSelectedId(null) : setSelectedId(id);
    };

    const inputChanger = e => {
        setInputData({...inputData, [e.target.name]: e.target.value});
    };

    const ref = useRef(null);
    return (
        <div className={styles.expertOpinion}>
            <div className={styles.controlPanel}>
                <input
                    type="file"
                    ref={ref}
                    className={styles.input}
                    onChange={e => {
                        saveImg({file: e.target.files[0], id: bookId});
                    }}
                />
                <div onClick={addImg} className={styles.button}>
                    Добавить
                </div>
                <div
                    onClick={() => delOpinion({selectedId, bookId})}
                    className={styles.button}
                >
                    Удалить
                </div>
                <input
                    className={styles.inputData}
                    value={inputData.name}
                    name="name"
                    placeholder="Имя"
                    onChange={inputChanger}
                />
                <input
                    className={styles.inputData}
                    value={inputData.ordering}
                    name="ordering"
                    placeholder="Порядок"
                    onChange={inputChanger}
                />
                <div
                    className={styles.button}
                    onClick={() => {
                        editOpinion(selectedId, inputData);
                        setInputData({
                            name: "",
                            ordering: ""
                        });
                    }}
                >
                    Изменить
                </div>
            </div>
            <ExportOpinionTable
                list={list}
                selectedId={selectedId}
                handleSelect={handleSelect}
            />
        </div>
    );
};

export default connect(
    mapStateToProps,
    matchDispatchToProps
)(BookEditPages);
