import { combineReducers } from "redux";
import { usersCountReducer } from "./count/usersCountReducer";
import { usersListReducer } from "./list/usersListReducer";
import { usersPhotoReducer } from "./photo/usersPhotoReducer";
import { usersSearchReducer } from "./search/usersSearchReducer";

export const usersReducer = combineReducers({
  count: usersCountReducer,
  list: usersListReducer,
  search: usersSearchReducer,
  photo: usersPhotoReducer
});
