import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import styles from "./FundMovementSearchForm.module.css";


const FundMovementSearchForm = ({ searchData, setSearchData, onSubmit, librariesList }) => {
  const onChange = ({ target: { name, value } }) => setSearchData({ ...searchData, [name]: value });

  return (
    <div className={styles.container}>
      <Row>
        <Col md={3}>
          <ModalInputWrapper
            value={searchData.from}
            name="from"
            onChange={onChange}
            type={"date"}
            label={"С"}
          />
        </Col>
        <Col md={3}>
          <ModalInputWrapper
            value={searchData.to}
            name="to"
            onChange={onChange}
            type={"date"}
            label={"По"}
          />
        </Col>
        <Col md={4}>
          <ModalInputWrapper label={'Библиотека'}>
            <select
              name="libraryid"
              onChange={onChange}
              value={searchData.libraryid}
              className={styles.select}
            >
              <option value="0" disabled>Выберите библиотеку</option>
              {librariesList.map(item =>
                <option value={item.id}>{item.name}</option>
              )}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={2}>
          <ModalInputWrapper label={''}>
            <Button label={"Составить"} onClick={onSubmit}/>
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

FundMovementSearchForm.propTypes = {
  data: PropTypes.object,
  setSearchData: PropTypes.func,
  startSearch: PropTypes.func,
  types: PropTypes.array,
  librariesList: PropTypes.array
};

export default FundMovementSearchForm;
