import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const CatalogCardBody = ({ data, authors }) => {
  const { title, year, bbk, udk, publishers, pageNum, annotation, isbn, authorSign } = data || {};



  return (
    <div className="catalogCard">
      <div className={'d-flex'}>
        <div className="authorSign">
          {authorSign}
        </div>
        <div className="topRow">
          <div className="authData">
            <div className="auth">
              {authors ? authors.map(item => <>{`${item} `}</>) : () => null}
            </div>
            <div>
              <i>{title},{" "}{year}г.</i>
              <p className="annotation">{annotation}</p>
              <p>
                ISBN {isbn}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="botRow">
        <div className="bbkSign">
          <div>{pageNum} стр.</div>
          <div> изд-во:
            {publishers
              ? publishers.map(item => <>{`${item.name} `}</>)
              : () => null}
          </div>
        </div>
        <div>
          <div className="bbks">
            <div><b>ББК</b></div>
            <div>{bbk && bbk}</div>
          </div>
          <div className="bbks">
            <div><b>УДК</b></div>
            <div>{udk && udk}</div>
          </div>
        </div>
        <div className="hole" />
      </div>
    </div>
  );
};

CatalogCardBody.propTypes = {
  data: PropTypes.object,
  authors: PropTypes.array
};

export default CatalogCardBody;