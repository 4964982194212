import React from 'react';
import PropTypes from 'prop-types';
import DataTable from "../../../DataTable";
import DataTableCol from "../../../DataTable/Column";

import {WEEK_DAYS} from "../index";


const SchedulerModalTable = ({data, setModal}) => {
    return (
        <DataTable data={data} onCellClick={item => setModal(item)}>
            <DataTableCol title={'день недели'} resolver={item => {
                return Number.isInteger(item.day) ? WEEK_DAYS.find(day => item.day == day.number).name : '-'
            }}/>
            <DataTableCol title={'интервал'} value={'interval'}/>
            <DataTableCol title={'время'} resolver={item => {
                return Array.isArray(item.schedule) ?
                    item.schedule.map(el => <p>{ el }</p>)
                    :
                    '-'
            }}/>
        </DataTable>
    );
};

SchedulerModalTable.propTypes = {
    data: PropTypes.array,
    setModal: PropTypes.func,
};

export default SchedulerModalTable;