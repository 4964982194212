import { SCHEDULER_METADATA_COUNT_REQUEST, START, SUCCESS, ERROR } from "../../action-types";



export const schedulerMetadataCountRequestStart = () => ({
  type: `${SCHEDULER_METADATA_COUNT_REQUEST}${START}`
});

export const schedulerMetadataCountRequestSuccess = (data) => ({
  type: `${SCHEDULER_METADATA_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const schedulerMetadataCountRequestError = (error) => ({
  type: `${SCHEDULER_METADATA_COUNT_REQUEST}${ERROR}`,
  payload: error
});
