import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './UDKListItem.module.css';

const UDKListItem = ({ data, level, fetchSubList, collapseList, onSelect }) => {
  const onClick = (e) => {
    if(e.target.tagName !== 'BUTTON') {
      data.subList ? collapseList(data.id) : fetchSubList(data.id);
    }
  };

  const coloredStyle = classNames({
    [styles.firstLevel]: level === 1,
    [styles.secondLevel]: level === 2
  });

  return (
    <tr onClick={(e) => onClick(e)} className={styles.listItem}>
      {Array(level - 1).fill(1).map((num, index) => (
        <td colSpan={1} key={index}> </td>
      ))}
      <td colSpan={6} className={styles.udkNumber}>{data.udk}</td>
      <td colSpan={18 - level} className={coloredStyle}>{data.description}</td>
      <td colSpan={8} className={coloredStyle}>{onSelect ? <button onClick={() => onSelect(data.udk)}>Добавить</button> : data.comments}</td>
    </tr>
  );
};

UDKListItem.propTypes = {
  data: PropTypes.object,
  level: PropTypes.number,
  fetchSubList: PropTypes.func,
  collapseList: PropTypes.func,
  onSelect: PropTypes.func,
};

export default UDKListItem;
