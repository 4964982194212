import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./SearchBooksModal.module.css";
import { availableBooksActions } from "../../../../redux/factory/availableBooksModalReduxData";
import { connect } from "react-redux";
import classNames from "classnames";
import BookSearchForm from "../../../../components/Covers/SearchForm";
import ModalWindow from "../../../../components/ModalWindow";
import CoverPhotoPlaceholder from "../../../../components/Covers/List/Item/CoverPhotoPlaceholder";
import BookCover from "../../../../components/BookEdit/Tabs/IndexTab/BookCover";
import { resolveBookCoverUrl } from "../../../../utils/resolvePhotoUrl";
import { saveBookCover } from "../../../../redux/actions/bookData/data";
import Modal from 'react-bootstrap/Modal';
import {createNotification} from "../../../../components/notification/notification";

const mapStateToProps = ({ availableBooks: { list, form } }) => ({
  list,
  form
});

const mapDispatchToProps = dispatch => ({
  requestList: () => dispatch(availableBooksActions.listRequestStart()),
  moveBook: book => dispatch(availableBooksActions.moveBook(book)),
  setSearchData: data => dispatch(availableBooksActions.setFormData(data)),
  uploadBookCover: ({ file, bookId }) =>
    dispatch(saveBookCover({ file, bookId }))
});

const SearchBooksModal = ({
  userId,
  requestList,
  list,
  moveBook,
  onClose,
  setSearchData,
  resolution,
  uploadBookCover,
  form
}) => {
  const [coverModalOpen, setCoverModalOpen] = useState(false);
  const [resolutionModalOpen, setResolutionModalOpen] = useState(false);
  const [currentBook, setCurrentBook] = useState(null);

  useEffect(() => {
    requestList();
  }, []);

  const submitMove = data => {
    setCoverModalOpen(false);
    if(data.returnDate) {
      moveBook({
        typeFrom: 1,
        placeFrom: data.placeid,
        placeTo: userId,
        typeTo: 2,
        instanceId: data.instanceid,
        returndate: data.returnDate,
        mid: userId,
        coverImage: data.coverImage
      });
      requestList();
    } else {
      createNotification('error', 'Необходимо заполнить предполагаемую дату возврата')
    }

  };

  const checkBeforeMove = index => {
    const currentBook = list[index];
    const isBookHasImage = !!currentBook.coverImage;
    if (resolution) {
      setResolutionModalOpen(true);
    } else if (!isBookHasImage) {
      setCoverModalOpen(true);
      setCurrentBook(index);
    } else {
      submitMove(currentBook);
    }
  };

  const updateInstanceReturnDate = (value, index) => {
    list[index]['returnDate'] = value;
  };

  return (
    <ModalWindow title="Книги" isOpen={true} onRequestClose={onClose}>
      <Modal
        show={resolutionModalOpen}
        size="sm"
        onHide={() => setResolutionModalOpen()}
        centered
      >
        <Modal.Header closeButton style={{backgroundColor:"#304875",color:"#fff"}}>Ошибка</Modal.Header>
        <Modal.Body>Имеются просроченные книги. Выдача запрещена.</Modal.Body>
      </Modal>
      <ModalWindow
        title="Загрузить обложку"
        isOpen={coverModalOpen}
        width={"600px"}
        height={"500px"}
        onRequestClose={() => setCoverModalOpen(false)}
      >
        {currentBook !== null && (
          <Row>
            <Col md={5}>
              <BookCover
                bookId={list[currentBook].id}
                onUpload={uploadBookCover}
                coverUrl={
                  list[currentBook].coverImage &&
                  resolveBookCoverUrl(
                    list[currentBook].coverImage,
                    list[currentBook].id
                  )
                }
              />
            </Col>
            <Col md={7}>
              <h3>У этой книги отсутствует обложка</h3>
              <div>
                Вы можете загрузить ее прямо сейчас или продолжить без
                добавления обложки.
              </div>
              <div className="mt-3">
                <button
                  className={classNames(styles.button)}
                  onClick={() => submitMove(list[currentBook])}
                >
                  Продолжить выдачу
                </button>
                <button
                  className={classNames(styles.button, styles.buttonDecline)}
                  onClick={() => setCoverModalOpen(false)}
                >
                  Отменить выдачу
                </button>
              </div>
            </Col>
          </Row>
        )}
      </ModalWindow>
      <div className={styles.container}>
        <BookSearchForm setData={setSearchData} data={form} hideInstitutions>
          <Row className={"justify-content-end"}>
            <Col sm={"auto"}>
              <div
                onClick={requestList}
                className={classNames(styles.button, styles.buttonFind)}
              >
                Найти
              </div>
            </Col>
          </Row>
        </BookSearchForm>

        {list.map((element, index) => {
          return (
            <div className={classNames(styles.elementContainer)} key={index}>
              <Row className={"align-items-center"}>
                <Col md={1}>
                  <CoverPhotoPlaceholder
                    url={element.coverImage}
                    bookId={element.id}
                    alt={element.title}
                  />
                </Col>
                <Col md={7}>
                  <div className={styles.elementName}>
                    {element.title}
                    <div className={styles.elementDescription}>
                      Авторы: {element.authors}
                    </div>
                    <div className={styles.elementDescription}>
                      Инвентарный номер: {element.number}
                    </div>
                  </div>
                </Col>
                <Col md={2}>
                  <input
                    type="date"
                    onChange={(e) => updateInstanceReturnDate(e.target.value, index)}/>
                </Col>
                <Col md={2} className={"text-right"}>
                  <button
                    onClick={() => checkBeforeMove(index)}
                    className={styles.button}
                  >
                    Выдать на руки
                  </button>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    </ModalWindow>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBooksModal);
