import {
  call,
  put,
  takeLatest
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";
import {
  ROLE_MEMBER_DELETE_REQUEST,
  START
} from "../../../actions/action-types";
import { memberRoleAction } from "../../../../api/roles/roles";
import { roleMembersListRequestStart } from "../../../actions/roles/membersList";
import {
  roleMemberDeleteRequestError,
  roleMemberDeleteRequestSuccess
} from "../../../actions/roles/deleteMember";

export default function* roleMemberDeleteWatcher() {
  yield takeLatest(
    `${ROLE_MEMBER_DELETE_REQUEST}${START}`,
    roleMemberDeleteWorker
  );
}

function* roleMemberDeleteWorker({ payload }) {
  const { roleId, memberId } = payload;
  try {
    const response = yield call(memberRoleAction, {
      roleId,
      memberId,
      actionType: "delete"
    });
    yield put(roleMemberDeleteRequestSuccess(response.data));
    yield put(roleMembersListRequestStart(roleId));
  } catch (error) {
    yield put(roleMemberDeleteRequestError(error));
  }
}
