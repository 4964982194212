import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ModalAddOther.module.css';
import Modal from "../../../Modal";
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import ModalWindow from "../../../ModalWindow";
import Button from "../../../Button";

const ModalAddOther = ({ onClose, onSave, isVisible }) => {  
  const [name, setName] = useState("");
  const [isError, setIsError] = useState(false);

  const handleChange = (event) => {    
    setName(event.target.value);
    setIsError(event.target.value === "");
  };

  const handleSave = () => {
    if (!name) {
      setIsError(true);
      return;
    }
    onSave(name);
  };

  useEffect(() => {
    setName("");
  }, [isVisible]);

  return (
    <ModalWindow title={`Добавить объект`}
                 isOpen={isVisible}
                 onRequestClose={onClose}
    >
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Название" inputSpan={12} labelSpan={12}>
            <input className={styles.input} type="text" value={name} onChange={handleChange}/>
          </ModalInputWrapper>          
        </Col>
        <Col md={12}>
          {isError &&
          <div className={styles.errorMessage}>
            {`Вы должны ввести название объекта!`}
          </div>}
        </Col>
        <Col md={12}>
          <div className={styles.bottomSection}>
              <Button onClick={handleSave} label={'Сохранить'} colorType={'green'}/>
              <Button onClick={onClose} label={'Отмена'} colorType={'red'} style={'ml-2'}/>
          </div>
        </Col>
      </Row>      
    </ModalWindow>
  );
};

ModalAddOther.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  isVisible: PropTypes.bool
};

export default ModalAddOther;
