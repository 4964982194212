import React from 'react';
import PropTypes from 'prop-types';
import styles from './AccessLogForm.module.css';
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";

const SecurityLogForm = ({ data, setData, search }) => {
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  return (
    <div className={"formContainer"}>
      <Row>
        <Col md={4}>
          <ModalInputWrapper onChange={onChange}
                             name="from"
                             value={data.from}
                             label="С (дата)"
                             type="date"/>
        </Col>
        <Col md={4}>
          <ModalInputWrapper onChange={onChange}
                             name="to"
                             value={data.to}
                             label="До (дата)"
                             type="date"/>
        </Col>        
        <Col md={2} className={styles.search}>
          <Button label={"Найти"} onClick={search}/>
        </Col>
      </Row>
    </div>
  );
};

SecurityLogForm.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  search: PropTypes.func
};

export default SecurityLogForm;
