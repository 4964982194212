import { api } from "../../App";

export const editBook = data => {
  return api.put(`/book/${data.bookid}`, data);
};

export const compressBook = bookId => {
  return api.put(`/book/${bookId}`, { compressPdfFile: true });
};

export const decompressBook = bookId => {
  return api.put(`/book/${bookId}`, { compressPdfFile: false });
};

export const saveScan = ({ bookId, file, side }) => {
  const formData = new FormData();
  formData.append("card", file);

  return api.post(`bookcard/${bookId}?side=${side}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const savePdf = ({ bookId, file, body }) => {
  const formData = new FormData();
  formData.append("file", file);

  return api.post(`/bookfile/${bookId}?filedate=${body.filedate}&activeversion=true`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
  });
};

export const deletePdf = ({ bookId, fileId }) => {
  return api.delete(`/bookfile/${bookId}/${fileId}`)
};

export const cleanScan = ({ side, bookId }) => {
  return api.delete(`bookcard/${bookId}?side=${side}`);
};

export const saveCover = ({ bookId, file }) => {
  const formData = new FormData();
  formData.append("cover", file);

  return api.put(`bookcover/${bookId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const cleanCover = ({ bookId }) => {
  return api.delete(`/bookcover/${bookId}`);
};

export const generateSign = authorLastname => {
  return api.get(`/havkina/calcindex?author=${authorLastname}`);
};
