import {api} from "../../App";

export const getBookmark = (bookId, fileId) => {
    return api.get(`/bookmark/${bookId}/${fileId}`)
};

export const setBookmark = (bookId, fileId, bookmarks) => {
    const params = {
        "mark": bookmarks,
    };

    return api.post(`/bookmark/${bookId}/${fileId}`, params)
};

export const deleteBookmark = (bookId, fileId) => {
    return api.delete(`/bookmark/${bookId}/${fileId}`)
};