import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { RUBRICATOR_PARENT_SEARCH_REQUEST, START } from "../../../actions/action-types";
import { rubricatorList } from "../../../../api/rubricator/rubricator";
import { RUBRICATOR_SORT } from "../../../../constants/sort/rubricatorSort";
import {
  rubricatorParentSearchRequestError,
  rubricatorParentSearchRequestSuccess
} from "../../../actions/rubricator/parentSearch";

export default function* rubricatorParentSearchWatcher() {
  yield takeLatest(`${RUBRICATOR_PARENT_SEARCH_REQUEST}${START}`, rubricatorParentSearchWorker)
}

function* rubricatorParentSearchWorker({ payload: query }) {
  try {
    const response = yield call(rubricatorList, RUBRICATOR_SORT.NAME, 10, 0, query);
    yield put(rubricatorParentSearchRequestSuccess(response.data));
  } catch (error) {
    yield put(rubricatorParentSearchRequestError(error));
  }
}
