import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {JSONModel} from 'react-3d-viewer';


const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

const ModelViewer = () => {

    let width = window.innerWidth;
    let height = window.innerHeight;

    return (
        <div>
            <JSONModel
                src={'https://biblio.nintegra.ru/static/t-80/t-80.js'}
                texPath={'https://biblio.nintegra.ru/static/t-80/t-80-texture.jpg'}
                width={width}
                height={height}
            />
        </div>
    );
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModelViewer);