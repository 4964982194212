import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
  HAVKINA_CALC_INDEX_REQUEST,
  HAVKINA_COUNT_REQUEST,
  HAVKINA_LIST_REQUEST,
  START
} from "../../actions/action-types";
import { havkinaCountRequestError, havkinaCountRequestSuccess } from "../../actions/havkina/count";
import { getHavkinaCount, getHavkinaIndex, getHavkinaList } from "../../../api/havkina/havkina";
import { setHavkinaSearchData } from "../../actions/havkina/search";
import {
  havkinaListRequestError,
  havkinaListRequestStart,
  havkinaListRequestSuccess
} from "../../actions/havkina/list";
import { havkinaCalcIndexRequestSuccess } from "../../actions/havkina/calcIndex";

export default function* havkinaWatcher() {
  yield takeLatest(`${HAVKINA_COUNT_REQUEST}${START}`, havkinaCountWorker);
  yield takeLatest(`${HAVKINA_LIST_REQUEST}${START}`, havkinaListWorker);
  yield takeLatest(`${HAVKINA_CALC_INDEX_REQUEST}${START}`, havkinaIndexWorker)
}

function* havkinaCountWorker() {
  const searchData = yield select(({ havkina }) => havkina.search);
  try {
    const response = yield call(getHavkinaCount, searchData.search);
    const count = parseInt(response.data.count, 10);
    yield put(havkinaCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setHavkinaSearchData({...searchData, pageCount}));
    yield put(havkinaListRequestStart());
  } catch (error) {
    yield put(havkinaCountRequestError(error));
  }
}

function* havkinaListWorker() {
  const searchData = yield select(({ havkina }) => havkina.search);
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
  try {
    const response = yield call(getHavkinaList, searchData);
    yield put(havkinaListRequestSuccess(response.data));
  } catch (error) {
    yield put(havkinaListRequestError(error));
  }
}

function* havkinaIndexWorker({payload: author}) {
  try {
    const response = yield call(getHavkinaIndex, author);
    yield put(havkinaCalcIndexRequestSuccess(response.data ? response.data[0].index : ""));
  } catch (error) {
    yield put(havkinaListRequestError(error));
  }
}
