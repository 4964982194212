import { all } from 'redux-saga/effects';
import documentsSaga from "./document/documentsSaga";
import documentBooksSaga from "./books/documentBooksSaga";
import documentInstancesSaga from "./instances/instancesSaga";
import fundAllocationSaga from './fundAllocation/fundAllocationSaga';
import documentBookDistributeSaga from './bookDistribute/documentBookDistributeSaga';

export default function* documentSaga() {
  yield all([
    documentsSaga(),
    documentBooksSaga(),
    documentInstancesSaga(),
    fundAllocationSaga(),
    documentBookDistributeSaga()
  ]);
}
