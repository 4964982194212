import React, {useEffect} from 'react';
import styles from './AggregationReportScene.module.css';
import ReportInfoBlock from "../../../components/Reports/InfoBlock";
import PropTypes from 'prop-types';
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import {reportGetRequestStart} from "../../../redux/actions/reports/get";
import {connect} from "react-redux";
import SpinnerOuter from "../../../components/UtulityComponents/SpinnerOuter";
import AggregationPDFPrint from "../../../components/Reports/Aggregation/PDFPrint";
import {AgregationTableType} from './utils';


const mapStateToProps = ({reports}) => ({
    data: reports.report,
    loader: reports.loader
});

const mapDispatchToProps = {
    requestReport: reportGetRequestStart
};



const AggregationReportScene = ({requestReport, data, ownId, loader}) => {

    useEffect(() => {
        requestReport({
            id: ownId,
            formData: null
        });
    }, []);

    return (
        <SceneContainer title="Агрегация информации">
            {data[ownId] &&
                <AggregationPDFPrint title="Агрегация информации" data={data[ownId]}/>
            }

            {loader && <SpinnerOuter isLoading={loader}/>}
            {!loader && data[ownId] && data[ownId].map((item, index) =>
                <div className={styles.dataContainer} key={index}>
                    {AgregationTableType.map(el =>
                        <ReportInfoBlock
                            label={el.label}
                            value={item[el.value]}/>
                    )}
                </div>
            )}
        </SceneContainer>
    );
};

AggregationReportScene.propTypes = {
    data: PropTypes.array
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AggregationReportScene);
