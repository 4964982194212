import React from 'react';
import PropTypes from 'prop-types';
import styles from './ReadingList.module.css';
import ReadingListItem from './Item';

const ReadingList = ({data, reserveBook, unreserveBook, loadSuccess, addBookScanOrder}) => {
    return (
        <div className={styles.container}>
            {(loadSuccess && data.length === 0) ?
                <div className="emptyNotify">По Вашему запросу ничего не найдено</div>
                :
                data.map((item, index) =>
                    <ReadingListItem
                        data={item} key={index}
                        addBookScanOrder={addBookScanOrder}
                        reserveBook={reserveBook}
                        unreserveBook={unreserveBook}
                    />
                )
            }
        </div>
    );
};

ReadingList.propTypes = {
    data: PropTypes.array,
    reserveBook: PropTypes.func,
    unreserveBook: PropTypes.func,
    loadSuccess: PropTypes.bool,
};

export default ReadingList;
