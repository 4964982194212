import { SET_ACCESS_CATEGORY_FORM_DATA } from "../../../actions/action-types";


const initialState = {
  roleid: "",
  set: undefined
};

export const accessCategoryFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCESS_CATEGORY_FORM_DATA:
      return action.payload;

    default: return state;
  }
};
