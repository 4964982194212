import React from 'react';
import PropTypes from 'prop-types';
import DataTableCol from "../../DataTable/Column";
import DataTable from "../../DataTable";
import CoverPhotoPlaceholder from "../../Covers/List/Item/CoverPhotoPlaceholder";
import classNames from "classnames";
import styles from "../../BookTransfer/Table/BookTransferTable.module.css";
import {ICONS} from "../../../utils/projectIcons";


const BookReserveTable = ({ list, unreserveBook }) => {
  return (
    <div className="mt-2 mb-2">
      <DataTable data={list}>
        <DataTableCol colSpan={2} title="Читатель" value="displayname"/>
        <DataTableCol colSpan={1} title={''} resolver={item => <CoverPhotoPlaceholder url={item.coverImage} bookId={item.bookid} alt={item.title}/>}/>
        <DataTableCol colSpan={3} title="Название" resolver={item => `${item.title}, ${item.year}г. ${item.pageNum || 0}стр.`}/>
        <DataTableCol colSpan={2} title="Авторы" resolver={item => (
          item.authors ? item.authors.map(author => author.name) : '-'
        )}/>
        <DataTableCol colSpan={2} title="Издательства" resolver={item => (
          item.publishers ? item.publishers.map(publisher => publisher.name) : '-'
        )}/>
        <DataTableCol title="" resolver={item =>
          <div className="d-flex">
            <button
              title="Отменить бронь"
              className={classNames("bg_red", styles.iconButton)}
              onClick={() => unreserveBook(item.id)}
            >
              <img src={ICONS.CANCEL} alt=""/>
            </button>
          </div>
        }/>
      </DataTable>
    </div>
  );
};

BookReserveTable.propTypes = {
  list: PropTypes.array,
  unreserveBook: PropTypes.func
};

BookReserveTable.defaultProps = {
  list: []
};

export default BookReserveTable;