import React, {useEffect, useState} from 'react';
import styles from './SecurityAccessLogScene.module.css';
import {securityLogCountRequestStart} from "../../redux/actions/securityLog/count";
import {setSecurityLogFormData} from "../../redux/actions/securityLog/formData";
import {setSecurityLogSearchData} from "../../redux/actions/securityLog/searchData";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Pagination from "../../components/Pagination";
import SecurityLogTable from "../../components/SecurityLog/Table";
import SecurityLogForm from "../../components/SecurityLog/Form";
import {SORT_DIRECTION} from "../../constants/sortDirections";

const mapStateToProps = ({securityLog}) => ({
    list: securityLog.list,
    count: securityLog.count,
    formData: securityLog.form,
    searchData: securityLog.search
});

const mapDispatchToProps = dispatch => ({
    requestList: () => dispatch(securityLogCountRequestStart()),
    setFormData: (data) => dispatch(setSecurityLogFormData(data)),
    setSearchData: (data) => dispatch(setSecurityLogSearchData(data))
});

const SecurityAccessLogScene = ({count, list, requestList, formData, setFormData, searchData, setSearchData}) => {
    useEffect(() => {
        requestList()
    }, [requestList]);

    const [dir, toggleDir] = useState(true);

    const setSort = (sortBy) => {
        const direction = dir ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC;
        setSearchData({...searchData, sortBy, sortDirection: direction});
        toggleDir(!dir);
        requestList();
    };

    const setCurrentPage = (page) => {
        setSearchData({...searchData, page});
        requestList()
    };

    return (
        <div className={styles.container}>
            <Row>
                <Col md={12}>
                    <div className={styles.title}>
                        Подсистема журналирования
                    </div>
                </Col>
                <Col md={12}>
                    <SecurityLogForm data={formData} setData={setFormData} search={requestList}/>
                </Col>
                <Col md={12}>
                    <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page}
                                setCurrent={setCurrentPage}/>
                </Col>
                <Col md={12}>
                    <SecurityLogTable data={list} sortBy={searchData.sortBy} setSort={setSort}/>
                </Col>
                <Col md={12}>
                    <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page}
                                setCurrent={setCurrentPage}/>
                </Col>
            </Row>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityAccessLogScene);
