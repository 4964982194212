import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
  LIBRARY_DELETE_FUND_REQUEST,
  LIBRARY_FUND_LIST_REQUEST,
  LIBRARY_SAVE_FUND_REQUEST,
  START
} from "../../../../actions/action-types";
import {
  addLibraryFund,
  deleteLibraryFund,
  editLibraryFund,
  getLibraryFunds
} from "../../../../../api/library/library";
import {
  libraryFundListRequestError,
  libraryFundListRequestStart,
  libraryFundListRequestSuccess
} from "../../../../actions/library/info/fund/list";
import { librarySaveFundRequestError, librarySaveFundRequestSuccess } from "../../../../actions/library/info/fund/save";
import {
  libraryDeleteFundRequestError,
  libraryDeleteFundRequestSuccess
} from "../../../../actions/library/info/fund/delete";

export default function* libraryListFundWatcher() {
  yield takeLatest(`${LIBRARY_FUND_LIST_REQUEST}${START}`, libraryListFundListWorker);
  yield takeLatest(`${LIBRARY_SAVE_FUND_REQUEST}${START}`, libraryListFundSaveWorker);
  yield takeLatest(`${LIBRARY_DELETE_FUND_REQUEST}${START}`, libraryListFundDeleteWorker);
}

function* libraryListFundListWorker({payload: libraryId}) {
  try {
    const response = yield call(getLibraryFunds, libraryId);
    yield put(libraryFundListRequestSuccess(response.data));
  } catch (error) {
    yield put(libraryFundListRequestError(error));
  }
}

function* libraryListFundSaveWorker({payload: { data, libraryId }}) {
  try {
    const response = yield call(data.id ? editLibraryFund : addLibraryFund, libraryId, data);
    yield put(librarySaveFundRequestSuccess(response.data));
    yield put(libraryFundListRequestStart(libraryId));
  } catch (error) {
    yield put(librarySaveFundRequestError(error))
  }
}

function* libraryListFundDeleteWorker({payload: {libraryId, fundId}}) {
  try {
    const response = yield call(deleteLibraryFund, libraryId, fundId);
    yield put(libraryDeleteFundRequestSuccess(response.data));
    yield put(libraryFundListRequestStart(libraryId));
  } catch(error) {
    yield put(libraryDeleteFundRequestError(error));
  }
}