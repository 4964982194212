import React from "react";
import {connect} from "react-redux";
import styles from "./BookEditIndexTab.module.css";
import Button from "../../../Button";
import ScanImage from "./BookScanImage";
import classNames from "classnames";
import ChooseImage from "../../../ChooseFile";
import photoPlaceholder from "../../../../img/noCover.png";


const mapStateToProps = ({ bookEdit }) => ({
  coverLoading: bookEdit.cover.isLoading
});

const BookCover = ({ bookId, coverUrl, onUpload, onClean, coverLoading }) => {

  return (
    <div className={styles.scansContainer}>
      <div className={styles.scanImageContainer}>
        {coverLoading ? (
          <div>изображение загружается</div>
        ) : (
          <ScanImage
            url={coverUrl}
            style={styles.scanImage}
            notFoundImage={photoPlaceholder}
          />
        )}

      </div>
      <div className={styles.buttonsContainer}>
        <ChooseImage
          onChange={file => {
            onUpload({
              file,
              bookId
            });
          }}
        >
          <Button style={styles.uploadImageButton} label="Загрузить" />
        </ChooseImage>
        {onClean &&
          <Button
            onClick={() =>
              onClean({
                bookId
              })
            }
            style={classNames(styles.uploadImageButton, styles.cleanImageButton)}
            label="Очистить"
          />
        }

      </div>
    </div>
  );
};

export default connect(mapStateToProps)(BookCover);
