import React from "react";
import PropTypes from "prop-types";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

import CoverPhotoPlaceholder from "../../../Covers/List/Item/CoverPhotoPlaceholder";
import CoverListItemInfo from "../../../Covers/List/Item/InfoBlock";
import ControlsPanel from "./ControlsPanel";

import styles from "../../../Covers/List/Item/CoversListItem.module.css";

const ReadingListItem = ({data, reserveBook, unreserveBook, addBookScanOrder}) => {

    return (
        <div className={styles.container}>
            <Row>
                <Col md={2}>
                    <CoverPhotoPlaceholder
                        url={data.coverImage}
                        bookId={data.bookid}
                        alt={data.title}
                    />
                </Col>
                <Col md={10}>
                    <Row>
                        <Col md={{span: 9, offset: 3}}>
                            <Link to={`/book/${data.bookid}`} className={styles.title}>
                                {data.title}
                            </Link>
                            <div className={styles.value}>{data.authors}</div>
                        </Col>
                        <Col md={12}>
                            <CoverListItemInfo label={"Год"} value={data.year}/>
                            <CoverListItemInfo label={"ISBN"} value={data.isbn}/>
                            <CoverListItemInfo
                                label={"Размещение"}
                                value={data.clients || "n/a"}
                            />
                            <CoverListItemInfo label={"Фонд"} value={"n/a"}/>
                            {data.covermatch !== undefined && (
                                <CoverListItemInfo
                                    label={"% совпадения"}
                                    value={data.covermatch}
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col md={12} className={"text-right"}>
                    <ControlsPanel data={data} reserveBook={reserveBook} unreserveBook={unreserveBook}
                                   addBookScanOrder={addBookScanOrder}/>
                </Col>
            </Row>
        </div>
    );
};

ReadingListItem.propTypes = {
    data: PropTypes.object,
    reserveBook: PropTypes.func,
    unreserveBook: PropTypes.func
};

export default ReadingListItem;
