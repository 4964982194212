import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
  AUDIT_DATASETS_REQUEST,
  AUDIT_LOG_COUNT_REQUEST,
  AUDIT_LOG_LIST_REQUEST,
  START
} from "../../actions/action-types";
import {
  auditLogListRequestError,
  auditLogListRequestStart,
  auditLogListRequestSuccess
} from "../../actions/auditLog/list";
import { auditLogCountRequestError, auditLogCountRequestSuccess } from "../../actions/auditLog/count";
import { getAuditDatasets, getAuditLogCount, getAuditLogList } from "../../../api/accessLog/audit";
import { setAuditLogSearchData } from "../../actions/auditLog/searchData";
import { auditGetDatasetsRequestError, auditGetDatasetsRequestSuccess } from "../../actions/auditLog/dataset";

export default function* auditLogWatcher() {
  yield takeLatest(`${AUDIT_LOG_COUNT_REQUEST}${START}`, auditLogCountWorker);
  yield takeLatest(`${AUDIT_LOG_LIST_REQUEST}${START}`, auditLogListWorker);
  yield takeLatest(`${AUDIT_DATASETS_REQUEST}${START}`, auditLogDatasetWorker);
}

function* auditLogCountWorker() {
  const formData = yield select(({ auditLog }) => auditLog.form);
  const searchData = yield select(({ auditLog }) => auditLog.search);
  try {
    const response = yield call(getAuditLogCount, formData);
    const count = parseInt(response.data.count, 10);
    yield put(auditLogCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setAuditLogSearchData({...searchData, pageCount}));
    yield put(auditLogListRequestStart());
  } catch (error) {
    yield put(auditLogCountRequestError(error));
  }

}

function* auditLogListWorker() {
  const formData = yield select(({ auditLog }) => auditLog.form);
  const searchData = yield select(({ auditLog }) => auditLog.search);
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
  try {
    const response = yield call(getAuditLogList, searchData, formData);
    yield put(auditLogListRequestSuccess(response.data));
  } catch (error) {
    yield put(auditLogListRequestError(error));
  }
}

function* auditLogDatasetWorker() {
  try {
    const response = yield call(getAuditDatasets);
    yield put(auditGetDatasetsRequestSuccess(response.data));
  } catch (error) {
    yield put(auditGetDatasetsRequestError(error));
  }
}