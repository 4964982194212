import React from 'react';
import PropTypes from 'prop-types';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import {resolveDateWithTimeZone} from "../../../utils/Dates/resolveDateWithTimeZone";
import classNames from "classnames";
import styles from "./BookTransferTable.module.css";
import {ICONS} from "../../../utils/projectIcons";
import {BOOK_TRANSFER_STATUS_LIST} from "../../../scenes/BookTransfer";
import {resolveBookCoverUrl} from "../../../utils/resolvePhotoUrl";
import {Link} from "react-router-dom";


const BookTransferTable = ({ data, deleteItem, saveFile }) => {
  return (
    <div className="mt-2 mb-2">
      <DataTable data={data}>
        <DataTableCol title="Дата создания" resolver={item => resolveDateWithTimeZone(item.tscreated)}/>
        <DataTableCol title="Обложка" resolver={item => <img className={styles.coverImage} src={resolveBookCoverUrl(item.coverImage, item.bookId)} alt={item.title}/>}/>
        <DataTableCol title="Название" value="title"/>
        <DataTableCol title={'Авторы'} resolver={item => (item.authors && item.authors.length > 0 &&
          item.authors.map(author => author.name))}
        />
        <DataTableCol title={'Издательства'} resolver={item => (item.publishers && item.publishers.length > 0 &&
          item.publishers.map(publisher => publisher.name))}
        />
        <DataTableCol title="Статус" resolver={item =>
          <span className={classNames(styles.status, styles[`status${item.status}`])}>
              {BOOK_TRANSFER_STATUS_LIST.get(item.status)}
            </span>
        }/>
        <DataTableCol title="Дата окончания" resolver={item => (item.tsfinished ? resolveDateWithTimeZone(item.tsfinished) : '-')}/>
        <DataTableCol title="" resolver={item =>
          <div className="d-flex">
            <button
              title="Удалить"
              className={classNames("bg_red", styles.iconButton)}
              onClick={() => deleteItem(item.id)}
            >
              <img src={ICONS.TRASH} alt=""/>
            </button>
            {item.status === 5 &&
              <Link
                to={`/book/${item.bookId}`}
                target="_blank"
                title="Перейти к книге"
                className={classNames("bg_accent", styles.iconButton)}
                onClick={() => saveFile(item.id)}
              >
                <img src={ICONS.LINK} alt=""/>
              </Link>
            }
          </div>
        }/>
      </DataTable>
    </div>
  );
};

BookTransferTable.propTypes = {
  data: PropTypes.array.isRequired,
  deleteItem: PropTypes.func,
  saveFile: PropTypes.func
};

export default BookTransferTable;