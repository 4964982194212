import { BBK_LIST_REQUEST, SUCCESS } from "../../actions/action-types";

const initialState = [];

export const bbkListReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${BBK_LIST_REQUEST}${SUCCESS}`:
      return [...action.payload];

    default: return state;
  }
};