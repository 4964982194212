import React from "react";
import classNames from "classnames";
import moment from "moment";
import styles from "./DataTableCol.module.css";
import PropTypes from "prop-types";

const TIME_WITHOUT_FORMATING = "eventtime";

const DataTableCol = ({
                          isHeader,
                          title,
                          value,
                          item,
                          className,
                          colSpan,
                          resolver,
                          onClick,
                          sortBy,
                          passClicks,
                          sortParams,
                          rowWidth,
                          hideChecks,
                          column
                      }) => {
    const isActive = sortParams
        ? sortParams[0] === sortBy
        : sortBy && sortBy === value;

    if (passClicks && hideChecks) return null;

    return isHeader ? (
        <th
            className={classNames(className, {[styles.isActive]: isActive})}
            colSpan={colSpan}
            onClick={() =>
                onClick &&
                !passClicks &&
                onClick(...(sortParams ? sortParams : [value]))
            }
            style={{width: rowWidth}}
        >
            {title}
        </th>
    ) : (
        <td
            className={classNames(className, {[styles.isActive]: isActive})}
            colSpan={colSpan}
            onDoubleClick={() => onClick && !passClicks && onClick(item)}
        >
            {resolver
                ? resolver({...item, column_index: column})
                : value === TIME_WITHOUT_FORMATING
                    ? moment(item[value]).format("DD.MM.YYYY H:mm:ss")
                    : item[value]}
        </td>
    );
};

DataTableCol.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    colSpan: PropTypes.number,
    resolver: PropTypes.func,
    passClicks: PropTypes.bool,
    sortParams: PropTypes.array,

    //Всё, что ниже, ставится автоматически
    isHeader: PropTypes.bool,
    item: PropTypes.any,
    className: PropTypes.string,
    onClick: PropTypes.func,
    column: PropTypes.number,
};

DataTableCol.defaultProps = {
    colSpan: 1
};

export default DataTableCol;
