import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
    NEW_BOOKS_ORDER_COUNT_REQUEST,
    NEW_BOOKS_ORDER_REQUEST,
    NEW_BOOKS_ORDER_SAVE_REQUEST,
    NEW_BOOKS_ORDER_DELETE_REQUEST,
    START
} from "../../../actions/action-types";
import {
    getBookOrdersCount,
    getBookOrdersList,
    deleteNewBookOrder,
    saveNewBookOrder
} from "../../../../api/newBooksOrder/orders";
import {
    newBooksCountRequestError,
    newBooksCountRequestSuccess
} from "../../../actions/newBooksOrder/count/count";
import { setNewBooksOrderSearchData } from "../../../actions/newBooksOrder/search/search";
import {
    newBooksOrderListRequestError,
    newBooksOrderListRequestStart,
    newBooksOrderListRequestSuccess
} from "../../../actions/newBooksOrder/orders/list";

import {
    documentNumberRequestStart,
} from "../../../actions/documents/documents/number";
import {
    documentDeleteRequestSuccess,
    documentDeleteRequestError
} from "../../../actions/newBooksOrder/orders/delete";
import {
    documentSaveRequestError,
    documentSaveRequestSuccess
} from "../../../actions/newBooksOrder/orders/save";
import {setLatestDocument} from "../../../actions/newBooksOrder/orders/latest";


export default function* documentWatcher() {
    yield takeLatest(`${NEW_BOOKS_ORDER_COUNT_REQUEST}${START}`, documentCountWorker);
    yield takeLatest(`${NEW_BOOKS_ORDER_REQUEST}${START}`, documentListWorker);
    yield takeLatest(`${NEW_BOOKS_ORDER_DELETE_REQUEST}${START}`, documentDeleteWorker);
    yield takeLatest(`${NEW_BOOKS_ORDER_SAVE_REQUEST}${START}`, documentSaveWorker);
}

function* documentCountWorker() {
    const searchData = yield select(({ newBooksOrder }) => newBooksOrder.search);
    try {
        const response = yield call(getBookOrdersCount, searchData);
        const count = parseInt(response.data.count, 10);
        yield put(newBooksCountRequestSuccess(count));
        const pageCount = Math.ceil(count / searchData.maxResults);
        yield put(setNewBooksOrderSearchData({ ...searchData, pageCount }));
        yield put(newBooksOrderListRequestStart());
    } catch (error) {
        yield put(newBooksCountRequestError(error));
    }
}

function* documentListWorker() {
    const searchData = yield select(({ newBooksOrder }) => newBooksOrder.search);
    searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
    try {
        const response = yield call(getBookOrdersList, searchData);
        yield put(newBooksOrderListRequestSuccess(response.data));
        yield put(documentNumberRequestStart());
    } catch (error) {
        yield put(newBooksOrderListRequestError(error));
    }
}

function* documentDeleteWorker({ payload: orderId }) {
    try {
        const response = yield call(deleteNewBookOrder, orderId);
        yield put(documentDeleteRequestSuccess(response));
        yield put(newBooksOrderListRequestStart());
    } catch (error) {
        yield put(documentDeleteRequestError(error));
    }
}

function* documentSaveWorker({ payload: data }) {
    try {
        if(!data.id) {
            const response = yield call( saveNewBookOrder, data);
            yield put(documentSaveRequestSuccess(response.data));
            yield put(setLatestDocument(response.data));
            yield put(newBooksOrderListRequestStart());
        }
    } catch (error) {
        yield put(documentSaveRequestError(error));
    }
}
