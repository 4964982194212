import {SET_SCHEDULER_FORM_DATA} from "../../../../actions/action-types";


const initialState = {
  search: "",
};

export const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCHEDULER_FORM_DATA:
      return action.payload;

    default: return state;
  }
};
