import {
  call,
  put,
  takeLatest
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";
import {
  createActionWithPayload,
  createSimpleActionsForReducer
} from "../actions/actionsFactory";
import { combineReducers } from "redux";
import {
  createSimpleReducer,
  createSimpleReducerWithLoader
} from "../reducers/reducersFactrory";
import { ERROR, START, SUCCESS } from "../actions/action-types";
import { api } from "../../App";
import { getData } from "../../api/covers/loadbyimage";

const REQUEST_LIST = "SEARCH_BY_IMAGE_REQUEST";
const SET_SEARCH_DATA = "SET_SEARCH_DATA";


export const searchByImageActions = {
  ...createSimpleActionsForReducer("list", REQUEST_LIST),
  setSearchData: createActionWithPayload(SET_SEARCH_DATA)
};

export const itemsNumberPerPage = 10;

export const searchByImageReducers = combineReducers({
  list: createSimpleReducerWithLoader([], REQUEST_LIST),
  isFirstTimeFetch: createSimpleReducer(true, null, null, (state, action) => {
    if (action.type === `${REQUEST_LIST}${SUCCESS}`) {
      return false;
    }
  }),
  isError: createSimpleReducer(false, null, null, (state, action) => {
    if (action.type === `${REQUEST_LIST}${ERROR}`) {
      return true;
    }
    if (action.type === `${REQUEST_LIST}${START}`) {
      return false;
    }
  }),
  search: createSimpleReducer(
    { pageCount: 0, currentPage: 0, allItemsNumber: 0, file: null },
    SET_SEARCH_DATA
  )
});

export function* searchByImageSagaWatcher() {
  yield takeLatest(`${REQUEST_LIST}${START}`, loadSearchListSaga);
}

function* loadSearchListSaga({ payload: file }) {
  try {
    const response = yield call(getData, file);
    const data = response.data;
    yield put(
      searchByImageActions.setSearchData({
        pageCount: Math.ceil(data.length / itemsNumberPerPage),
        currentPage: 1,
        allItemsNumber: data.length
      })
    );
    yield put(searchByImageActions.listRequestSuccess(data));
  } catch (e) {
    put(searchByImageActions.listRequestError());
  }
}
