import { api } from '../../App';

export const authorize = (username, password) => {
  const data = {
    username, password
  };
  return api.post(`/login`, data)
};

export const checkAuth = () => {
  return api.get('/test');
};

export const logout = () => {
  return api.get('/logout');
};