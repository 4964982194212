import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { COLLECTIONS_LIST_REQUEST, START } from "../../../actions/action-types";
import { getCollectionList } from "../../../../api/collections/collections";
import { collectionsListRequestError, collectionsListRequestSuccess } from "../../../actions/collections/list";

export default function* collectionsListWatcher() {
  yield takeLatest(`${COLLECTIONS_LIST_REQUEST}${START}`, collectionsListWorker)
}

function* collectionsListWorker() {
  try {
    const response = yield call(getCollectionList);
    yield put(collectionsListRequestSuccess(response.data));
  } catch(error) {
    yield put(collectionsListRequestError(error));
  }
}