import { SCHEDULE_LIST_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = [];

export const scheduleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${SCHEDULE_LIST_REQUEST}${SUCCESS}`:
      return action.payload;
    default:
      return state;
  }
};
