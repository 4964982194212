import React, {useEffect, useState} from "react";
import styles from "./BookEditScene.module.css";
import {bookDataRequestStart} from "../../redux/actions/bookData/data";
import {connect} from "react-redux";
import {Tab, Tabs} from "react-bootstrap";
import BookEditIndexTab from "../../components/BookEdit/Tabs/IndexTab";
import {bookSaveRequestStart} from "../../redux/actions/books/save";
import {transformBookData} from "../../utils/transformBookData";
import {bookCompressRequestStart} from "../../redux/actions/books/compressBook";
import {bookDecompressRequestStart} from "../../redux/actions/books/decompressBook";
import Organizations from "../../components/BookEdit/Tabs/Organizations";
import {api} from "../../App";
import {createNotification} from "../../components/notification/notification";
import ExpertOpinion from "../../components/BookEdit/Tabs/ExpertOpinion";
import Pages from "../../components/BookEdit/Tabs/Pages";
import AdditionalDataTab from "../../components/BookEdit/Tabs/AdditionalData";
import BookHistoryChangeTab from "../../components/BookEdit/Tabs/HistiryChange/index";
import {bookChangeHistoryActions} from "../../redux/factory/bookHistoryChangeReduxData";
import NotesTab from "../../components/BookEdit/Tabs/Notes/index.jsx";
import {bookNotesActions} from "../../redux/factory/bookNotesReduxData";
import GUKTab from "../../components/BookEdit/Tabs/GUK";
import BookEditFilesTab from '../../components/BookEdit/Tabs/Files';
import {gukTabDataRequestStart} from '../../redux/actions/gukTab/getData';
import BookEditControls from "../../components/BookEdit/Controls";
import {setBookData} from '../../redux/actions/bookData/setData';
import BookEditInvNumbersTab from "../../components/BookEdit/Tabs/InvNumbers";

const mapStateToProps = ({bookData, bookNotes, bookChangeHistory, bookEdit}) => ({
    bookData: bookData,
    historyList: bookChangeHistory.list,
    notesTypes: bookNotes.types,
    notesList: bookNotes.list,
    gukTabData: bookEdit.gukTab
});

const mapDispatchToProps = {
    requestData: bookDataRequestStart,
    setBookData: setBookData,
    requestGukTabData: gukTabDataRequestStart,
    saveData: bookSaveRequestStart,
    compressBook: bookCompressRequestStart,
    decompressBook: bookDecompressRequestStart,
    getBookChangeHistory: bookChangeHistoryActions.listRequestStart,
    getNotesTypes: bookNotesActions.typesRequestStart,
    getNotesList: bookNotesActions.listRequestStart,
    addNote: bookNotesActions.addRequestStart,
    delNote: bookNotesActions.delRequestStart,
};

const BookEditScene = ({
                           match,
                           bookData,
                           requestData,
                           saveData,
                           compressBook,
                           decompressBook,
                           history,
                           getBookChangeHistory,
                           historyList,
                           getNotesTypes,
                           notesTypes,
                           notesList,
                           getNotesList,
                           addNote,
                           delNote,
                           gukTabData,
                           requestGukTabData,
                           setBookData
                       }) => {
    const bookId = match.params.id;
    const [saveTime, setSaveTime] = useState(new Date().toISOString());


    useEffect(() => {
        requestData(bookId);
    }, [requestData, bookId]);

    useEffect(() => {
        onSave(true);
    }, [saveTime]);

    useEffect(() => {
        requestGukTabData();
    }, [requestGukTabData]);


    const onSave = withoutPopup => {
        if (bookData !== null) {
            if (!!bookData.title) {
                saveData(transformBookData(bookData), withoutPopup);
            } else {
                createNotification("error", 'Пожалуйста, заполните поле "Название" ');
            }
        }
    };

    const checkedOnSave = () => {
        if(!bookData.book_source) createNotification('error', 'Поле источник не заполнено');
        else {
        const checkRequestBody = {
            title: bookData.title,
            year: Number(bookData.year) || 0,
            pageNum: Number(bookData.pageNum) || 0,
            authorIds: bookData.authors.map(item => item.id),
            publisherIds: bookData.publishers.map(item => item.id),
            extractreason: Number(bookData.extractreason) || 0,
            subscriptionenddate: bookData.subscriptionenddate
        };
        api.post(`/bookduplicate`, checkRequestBody)
            .then(response => {
                if (
                    (response.data.length === 1 &&
                        Number(response.data[0].id) === Number(bookData.id)) ||
                    response.data.length === 0
                ) {
                    onSave();
                    history.location.search = "";
                } else {
                    createNotification(
                        "error",
                        "Такая книга уже существует. Дубликаты запрещены."
                    );
                }
            })
        }
    };


    return (
        <div className={styles.container}>
            <div className={'d-flex justify-content-between'}>
                <div className={styles.title}>Редактирование книги - {bookData && bookData.title}</div>
                <div className={styles.title}>{bookData && bookData.bookid}</div>
            </div>

            {bookData && (
                <Tabs id={'bookEditTabs'} defaultActiveKey="index">

                    <Tab.Pane eventKey="index" title="Общие" className={styles.tabContent}>
                        <BookEditIndexTab data={bookData} setData={setBookData} checkedOnSave={checkedOnSave}/>
                    </Tab.Pane>

                    <Tab.Pane eventKey="location" title="Местоположение" mountOnEnter={true}>
                        <Organizations
                            setData={data => {
                                setBookData(data);
                                setSaveTime(new Date().toISOString());
                            }}
                            data={bookData}
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey="pages" title="Страницы" mountOnEnter={true}>
                        <Pages bookId={bookId}/>
                    </Tab.Pane>

                    <Tab.Pane eventKey="expert" title="Экспертное заключение" mountOnEnter={true}>
                        <ExpertOpinion bookId={bookId}/>
                    </Tab.Pane>

                    <Tab.Pane eventKey="changelog" title="История изменений" mountOnEnter={true}>
                        <BookHistoryChangeTab
                            bookId={bookId}
                            getBookChangeHistory={getBookChangeHistory}
                            list={historyList || []}
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey="additionalDesc" title="Доп. описание" mountOnEnter={true}>
                        <AdditionalDataTab data={bookData} setData={setBookData}/>
                    </Tab.Pane>

                    <Tab.Pane eventKey="guk" title="ГУК" mountOnEnter={true}>
                        <GUKTab data={bookData} setData={setBookData} selectData={gukTabData}/>
                    </Tab.Pane>

                    <Tab.Pane eventKey="additionalInfo" title="Примечания" mountOnEnter={true}>
                        <NotesTab
                            getTypes={getNotesTypes}
                            types={notesTypes}
                            getList={getNotesList}
                            list={notesList || []}
                            bookId={bookId}
                            addNote={addNote}
                            delNote={delNote}
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey="inv_numbers" title="Инв.номера" mountOnEnter={true}>
                        <BookEditInvNumbersTab bookId={bookId}/>
                    </Tab.Pane>

                    <Tab.Pane eventKey="files" title="Файлы" mountOnEnter={true}>
                        <BookEditFilesTab bookId={bookId}/>
                    </Tab.Pane>

                </Tabs>
            )}

            <BookEditControls
                bookData={bookData}
                bookId={bookId}
                history={history}
                compressBook={compressBook}
                requestData={requestData}
                decompressBook={decompressBook}
                checkedOnSave={checkedOnSave}
            />

        </div>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BookEditScene);
