import { bookEditCoverReducer } from "./bookCover/bookEditCoverReducer";
import { bookEditFileReducer } from "./bookFile/bookEditFileReducer";
import { bookEditFilesReducer, bookEditSortReducer } from '../../factory/BookEditFiles/bookEditFilesReduxData';
import { bookEditGukTabReducer } from './gukTab/bookEditGukTabReducer';
import {combineReducers} from "redux";

export const booksEditReducer = combineReducers({
  cover: bookEditCoverReducer,
  fileVersions: bookEditFileReducer,
  files: bookEditFilesReducer,
  sortData: bookEditSortReducer,
  gukTab: bookEditGukTabReducer
});
