import React from 'react';
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";

const LibrariesDiaryReportScene = ({}) => {
  return (
    <SceneContainer title={"Дневник библиотеки"}>
      fdfslfsdf dsf
    </SceneContainer>
  );
};

LibrariesDiaryReportScene.propTypes = {};

export default LibrariesDiaryReportScene;