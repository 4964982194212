import React, { useEffect, useState } from "react";
import styles from "./AdminUsersScene.module.css";
import { connect } from "react-redux";
import { usersCountRequestStart } from "../../redux/actions/adminUsers/count";
import { usersSaveRequestStart } from "../../redux/actions/adminUsers/save";
import { usersDeleteRequestStart } from "../../redux/actions/adminUsers/delete";
import { setUsersSearchData } from "../../redux/actions/adminUsers/search";
import { Col, Row } from "react-bootstrap";
import Search from "../../components/Search";
import AdminUsersTable from "../../components/AdminUsers/Table";
import AdminUsersModal from "../../components/AdminUsers/Modal";
import Pagination from "../../components/Pagination";

const mapStateToProps = ({ adminUsers: users }) => ({
  list: users.list,
  count: users.count,
  searchData: users.search
});

const mapDispatchToProps = dispatch => ({
  requestUsers: () => dispatch(usersCountRequestStart()),
  saveUser: data => dispatch(usersSaveRequestStart(data)),
  deleteUser: mid => dispatch(usersDeleteRequestStart(mid)),
  setSearchData: data => dispatch(setUsersSearchData(data))
});

const AdminUsersScene = ({
  list,
  requestUsers,
  deleteUser,
  saveUser,
  searchData,
  setSearchData,
  count
}) => {
  useEffect(() => {
    requestUsers();
  }, [requestUsers]);

  useEffect(() => {
    setModalData(modalData => {
      const { data } = modalData;
      if (data) {
        data.photosize = list.find(item => item.mid === data.mid).photosize;
        return { ...modalData, data: { ...data } };
      }
      return modalData;
    });
  }, [list]);

  const [modalData, setModalData] = useState({ modalType: null, data: null });
  const [selectedId, setSelectedId] = useState(null);

  const onSubmitModal = data => {
    saveUser(data);
    setModalData({ modalType: null, data: null });
  };

  const setSort = (sortBy, sortDirection) => {
    setSearchData({
      ...searchData,
      sortBy,
      page: 1,
      sortDirection: sortDirection
    });
    requestUsers();
  };

  const handleSelect = id => setSelectedId(selectedId === id ? null : id);

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page });
    requestUsers();
  };

  return (
    <div className={styles.container}>
      <Row>
        <Col md={6}>
          <div className={styles.title}>Администрирование / Пользователи</div>
        </Col>
        <Col md={6}>
          <Search
            value={searchData.query}
            onChange={query => setSearchData({ ...searchData, query })}
            onSubmit={requestUsers}
            placeholder={"поиск"}
          />
        </Col>
        <Col md={12}>
          <Pagination
            count={count}
            pageCount={searchData.pageCount}
            current={searchData.page}
            setCurrent={setCurrentPage}
          />
        </Col>
        <Col md={12}>
          <AdminUsersModal
            onSubmit={onSubmitModal}
            setModalData={setModalData}
            modalData={modalData}
          />
          <AdminUsersTable
            data={list}
            setModal={setModalData}
            sortBy={searchData.sortBy}
            setSort={setSort}
            handleSelect={handleSelect}
            selectedId={selectedId}
            onDelete={() => deleteUser(selectedId)}
          />
        </Col>
        <Col md={12}>
          <Pagination
            count={count}
            pageCount={searchData.pageCount}
            current={searchData.page}
            setCurrent={setCurrentPage}
          />
        </Col>
      </Row>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsersScene);
