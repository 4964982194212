import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {resolveUserPhoto} from "../../../../utils/resolveUserPhoto";
import classNames from "classnames";
import styles from "./ReaderHeader.module.css";
import {Col, Row} from "react-bootstrap";
import UserMenu from "../UserMenu";
import {logoutRequestStart} from "../../../../redux/actions/user/logout";
import {BASE_URL_WITHOUT_API} from "../../../../constants/api";
import {readerDataRequestStart} from "../../../../redux/actions/reader/getData";

const mapStateToProps = ({ auth, options, readerData }) => ({
  authData: auth.data,
  layoutOptions: options.layout,
  readerData
});

const mapDispatchToProps = {
  logOut:logoutRequestStart,
  requestReaderData: readerDataRequestStart,
};

const ReaderHeader = ({ authData, logOut, layoutOptions, readerData, requestReaderData }) => {
  const displayName = `${authData.lastname} ${authData.firstname} ${authData.patronymic}`;
  let userAvatar;

  useEffect(() => {
    requestReaderData(authData.mid);
  }, [requestReaderData]);

  if(authData) {
    userAvatar = resolveUserPhoto(authData.mid);
  }

  return (
    <div className={styles.container}
         style={{
           backgroundImage: `url(${BASE_URL_WITHOUT_API}${layoutOptions.header.image})`,
           backgroundColor: layoutOptions.header.background
         }}>
      <div className={styles.number}>
        Изделие 83т505
      </div>
        <Row className={'align-items-end'}>
          <Col xl={4} className={'d-none d-xl-block'}>
            <div className={classNames(styles.userContainer, 'd-flex align-items-center')}>
              <div
                className={styles.userPhoto}
                style={{backgroundImage: `url("${userAvatar}")`}}
              />
              <div className={styles.userInfo}>
                <span>{displayName}</span>
                <span>{readerData && readerData.groupname && `${readerData.faculty}, группа: ${readerData.groupname}`}</span>
                <div onClick={logOut} className={styles.logout}>выйти</div>
              </div>
            </div>
          </Col>
          <Col xl={4}>
            <div className={styles.smallDesktopUserInfo}>
              <div
                className={classNames(styles.userPhoto, 'd-xl-none')}
                style={{backgroundImage: `url("${userAvatar}")`}}
              />
              <div className={classNames(styles.ministrySection, 'text-center')}>
                <div className={styles.ministryIcon}/>
                <div className={styles.ministry}>
                  Министерство обороны Российской Федерации
                </div>
                <div className={styles.library}>
                  СПО Электронная Библиотека
                </div>
              </div>
            </div>
          </Col>
        </Row>
      <div className={'d-xl-none'}>
        <UserMenu data={authData} classes={styles.userMenu} layoutOptions={layoutOptions}/>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReaderHeader);
