import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from "../../../../Button";
import {createNotification} from "../../../../notification/notification";
import ReadingListExportButton from "../ExportButton";
import {RESERVE_STATE} from "../../../../../constants/bookState";
import ReadingListItemFiles from "../../FileList";
import ReadingListUploadingFiles from "../../UploadingList";
import {getBookReserveState} from "../../../../../utils/getBookReserveState";

const ControlsPanel = ({data, reserveBook, unreserveBook, addBookScanOrder}) => {
    const [filesListOpen, setFilesListOpen] = useState({
        isOpen: false,
        type: ''
    });

    const reserveState = getBookReserveState(data);

    const onReserve = () => {
        if (reserveState === RESERVE_STATE.AVAILABLE)
            return reserveBook(data.availableinstances[0]);
        if (reserveState === RESERVE_STATE.RESERVED)
            return unreserveBook(data.reservations[0]);
    };


    const switchFileList = (type) => {
        if (type === filesListOpen.type) {
            setFilesListOpen({
                ...filesListOpen,
                isOpen: !filesListOpen.isOpen
            })
        } else {
            setFilesListOpen({
                isOpen: true,
                type: type
            });
        }
    };

    const accessDeniedNotification = () => {
        createNotification(
            'warning',
            'Этот электронный материал запрещен к использованию на основании закона об авторском праве'
        );
    };

    const NoAccessButtonPanel = () => <>
        <Button
            label={'Забронировать'}
            style={'ml-2'}
            colorType={'transparent'}
            onClick={accessDeniedNotification}
        />
        <Button
            label={' '}
            style={'ml-2'}
            colorType={'transparent'}
            onClick={accessDeniedNotification}
        />
        <Button
            label={'Открыть'}
            style={'ml-2'}
            colorType={'transparent'}
            onClick={accessDeniedNotification}
        />
        <Button
            label={'Выгрузить на ЭН'}
            style={'ml-2'}
            colorType={'transparent'}
            onClick={accessDeniedNotification}
        />
    </>;

    const StandartButtonPanel = () => <>
        <Button
            label={reserveState === RESERVE_STATE.RESERVED
                ? "Снять бронь"
                : "Забронировать"}
            onClick={onReserve}
            colorType={'transparent'}
            disabled={reserveState === RESERVE_STATE.UNAVAILABLE}
        />

        <ReadingListExportButton bookId={data.bookid}/>

        {(data.files && data.files.length) ?
            <Button
                label={'Открыть'}
                style={'ml-2'}
                colorType={'transparent'}
                onClick={() => switchFileList('открыть')}
            />
            :
            <Button
                label={'Заказ на сканирование'}
                style={'ml-2'}
                colorType={'transparent'}
                onClick={() => addBookScanOrder(data.bookid, data.clients)}
            />
        }

        <Button
            label={'Выгрузить на ЭН'}
            style={'ml-2'}
            colorType={'transparent'}
            onClick={() => switchFileList('выгрузить')}
            disabled={!(data.files && data.files.length)}
        />
        {filesListOpen.isOpen &&
        <>
            {filesListOpen.type === 'открыть' &&
            <ReadingListItemFiles fileList={data.files} bookId={data.bookid}/>
            }
            {filesListOpen.type === 'выгрузить' &&
            <ReadingListUploadingFiles fileList={data.files} bookId={data.bookid}/>
            }
        </>
        }
    </>;

    return data.rightofuse === "Запрещено" ?
        <NoAccessButtonPanel />
        :
        <StandartButtonPanel />;
};

ControlsPanel.propTypes = {
    data: PropTypes.object,
};

export default ControlsPanel;