import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { AUTH_REQUEST, LOGOUT_REQUEST, START } from "../../actions/action-types";
import { authRequestError, authRequestSuccess, resetAuthData } from "../../actions/user/auth";
import { authorize, logout } from "../../../api/user/auth";
import { logoutRequestError, logoutRequestSuccess } from "../../actions/user/logout";

export default function* authWatcher() {
  yield takeLatest(`${AUTH_REQUEST}${START}`, authWorker);
  yield takeLatest(`${LOGOUT_REQUEST}${START}`, logoutWorker);
}

function* authWorker(action) {
  const { username, password } = action.payload;
  try {
    const response = yield call(authorize, username, password);
    yield put(authRequestSuccess(response.data));
  } catch (error) {
    yield put(authRequestError(error.response.status));
  }
}

function* logoutWorker() {
  try {
    const response = yield call(logout);
    yield put(logoutRequestSuccess(response.data));
    yield put(resetAuthData());
  } catch (error) {
    yield put(logoutRequestError(error))
  }
}
