export const accessIsSetByUserStatusList = [{
  value: undefined,
  label: 'Показать все значения'
},{
  value: true,
  label: 'Показать только установленные значения'
},{
  value: false,
  label: 'Показать только не установленные значения'
}];
