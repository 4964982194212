import React, { useEffect } from "react";
import {connect} from "react-redux";
import ModalWindow from "../../../../ModalWindow";
import { libraryFundRequestStart } from '../../../../../redux/actions/documents/fundAllocation/libraryFund';
import { bookDistributeSaveRequestStart } from '../../../../../redux/actions/documents/bookDistribute/save';
import Button from "../../../../Button";
import FundMovingBookItem from "./BookItem";
import {bookDistributeLoadRequestStart} from "../../../../../redux/actions/documents/bookDistribute/load";

const mapStateToProps = ({ documents }) => ({
  fundsList: documents.libraryFunds,
  bookDistribute: documents.bookDistribute
});

const mapDispatchToProps =  {
  saveDocument: bookDistributeSaveRequestStart,
  requestFundList: libraryFundRequestStart,
  loadBookDistribute: bookDistributeLoadRequestStart,
};

const FundMovingModal = ({ modalData, booksData, setClosed, isOpen, requestFundList,
                           fundsList, saveDocument, loadBookDistribute, bookDistribute, requestBooks }) => {
  const distributionData = {};
  const distributedBooks = bookDistribute.books;

  useEffect(() => {
    requestFundList(modalData.data.library);
  }, [requestFundList]);

  useEffect(() => {
      loadBookDistribute(modalData.data.id);
  }, [loadBookDistribute]);


  const onsubmit = () => {
    if(!checkFundSum(distributionData)) {
      return alert('Необходимо распределить книги в соответствии с количеством экземпляров');
    }

    let result = {
      "documentid": modalData.data.id,
      "books": []
    };

    for(let book in distributionData) {
      result["books"].push({
        "bookid": Number(book),
        "funds": distributionData[book].funds
      });
    }
    if(distributedBooks && distributedBooks.length) {
      saveDocument(result, modalData.data.id);
    } else {
      saveDocument(result);
    }
    setClosed();
  };

  const checkFundSum = (arrBook) => {
    for(let book in arrBook) {
      if(arrBook[book].sum !== arrBook[book].amount) {
        return false
      }
    }
    return true;
  };

  return (
    <>
      {(!!fundsList.length && distributedBooks) &&
        <ModalWindow
          isOpen={isOpen}
          onRequestClose={setClosed}
          title={'Распределить книги по фондам'}
        >
          <table>
            <thead>
            <tr>
              <th>Название</th>
              <th>Кол-во</th>
              {fundsList.map((item, index) => (
                <th key={index}>{item.name}</th>
              ))}
              <th>Итого</th>
            </tr>
            </thead>
            <tbody>
            {booksData.map((item, index) =>(
              <FundMovingBookItem
                key={index}
                bookItem={item}
                fundsList={fundsList}
                distributionData={distributionData}
                distributedBooks={distributedBooks}
              />
            ))}
            </tbody>
          </table>
          <div className='mt-3 text-right'>
            <Button
              label="Распределить по фондам"
              onClick={onsubmit}
            />
            <Button
              label="Отмена"
              colorType={'red'}
              style={'ml-2'}
              onClick={setClosed}
            />
          </div>
        </ModalWindow>
      }
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FundMovingModal);
