import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./AuthorModal.module.css";
import { EDIT } from "../../../constants/modalTypes";
import { Col, Row } from "react-bootstrap";
import Modal from "../../Modal";
import ModalInputWrapper from "../../Modal/InputWrapper";
import {
  authorImageRequestStart,
  authorImageRequestSuccess
} from "../../../redux/actions/authors/loadImage";
import { setAuthorImage } from "../../../redux/actions/authors/setAuthorImage";
import { connect } from "react-redux";
import { authorSaveImageRequestStart } from "../../../redux/actions/authors/saveImage";
import { authorDeleteImageRequestStart } from "../../../redux/actions/authors/deleteImage";
import { Link } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import { Tab } from "react-bootstrap";
import ImageContainer from './ImageContainer';
import { useFormik } from "formik";
import * as Yup from 'yup';
import Button from "../../Button";
import moment from "moment";
import CatalogsModalWithForm from "../../Catalogs/ModalWithForm";


const mapStateToProps = ({ authors, currentAuthorBooks }) => ({
  imgUrl: authors.image,
  temporaryUrl: authors.setImage,
  books: currentAuthorBooks
});

const mapDispatchToProps = {
  requestImage: authorImageRequestStart,
  clearImage: authorImageRequestSuccess,
  setAuthorImage: setAuthorImage,
  saveImage: authorSaveImageRequestStart,
  deleteImage: authorDeleteImageRequestStart
};

const validationSchema = Yup.object().shape({
  lastname: Yup.string()
    .required('Это обязательное поле')
    .max(1024, 'Недопустимая длинна фамилии'),
  firstname: Yup.string()
    .required('Это обязательное поле')
    .max(1024, 'Недопустимая длинна имени'),
  patronymic: Yup.string()
    .notRequired()
    .max(1024, 'Недопустимая длинна имени'),
});

const AuthorModal = ({
  onSubmit,
  modalData,
  imgUrl,
  requestImage,
  deleteImage,
  saveImage,
  books,
  clearImage,
  setAuthorImage,
  temporaryUrl,
  closeModal
}) => {
  const { modalType, data } = modalData;

  useEffect(() => {
    if (data && data.photosize && !imgUrl) {
      requestImage(data.id);
    }
  }, [data, imgUrl, requestImage]);

  const modalForm = useFormik({
    initialValues: data,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: values => onSubmit(values)
  });

  const onDeleteImage = () => deleteImage(data.id);

  const onLoadImage = e => {
    modalType === EDIT
      ? saveImage(e.target.files[0], data.id)
      : setAuthorImage(e.target.files[0]);
  };


  return (
      <CatalogsModalWithForm
          title={`Автор: ${modalType === EDIT ? "Редактирование" : "Добавление"}`}
          closeModal={() => {
              clearImage(null);
              setAuthorImage(null);
              closeModal();
          }}
          submitHandler={modalForm.handleSubmit}
      >
          <Tabs defaultActiveKey="edit">
              <Tab eventKey="edit" title="Редактирование">
                  {data && (
                      <Row>
                          <Col md={3}>
                              <ImageContainer
                                  onDeleteImage={onDeleteImage}
                                  onLoadImage={onLoadImage}
                                  temporaryUrl={temporaryUrl}
                                  imgUrl={imgUrl}
                                  photosize={data.photosize}
                              />
                          </Col>
                          <Col md={9}>
                              <Row>
                                  <Col md={12}>
                                      <ModalInputWrapper
                                          label="Биография"
                                          name="description"
                                          type="textarea"
                                          value={modalForm.values.description}
                                          error={modalForm.errors.description}
                                          onChange={modalForm.handleChange}
                                      />
                                  </Col>
                                  <Col md={12}>
                                      <ModalInputWrapper
                                          label="Фамилия"
                                          name="lastname"
                                          value={modalForm.values.lastname}
                                          error={modalForm.errors.lastname}
                                          onChange={modalForm.handleChange}
                                      />
                                  </Col>
                                  <Col md={12}>
                                      <ModalInputWrapper
                                          label="Имя"
                                          name="firstname"
                                          value={modalForm.values.firstname}
                                          error={modalForm.errors.firstname}
                                          onChange={modalForm.handleChange}
                                      />
                                  </Col>
                                  <Col md={12}>
                                      <ModalInputWrapper
                                          label="Отчество"
                                          name="patronymic"
                                          value={modalForm.values.patronymic}
                                          error={modalForm.errors.patronymic}
                                          onChange={modalForm.handleChange}
                                      />
                                  </Col>
                                  <Col md={12}>
                                      <ModalInputWrapper
                                          label="Начальный элемент ввода имени"
                                          value={modalForm.values.lastname}
                                      />
                                  </Col>
                                  <Col md={12}>
                                      <ModalInputWrapper
                                          label="Часть имени, кроме начального элемента"
                                          value={`${modalForm.values.firstname} ${modalForm.values.patronymic}`}
                                      />
                                  </Col>
                                  <Col md={12}>
                                      <ModalInputWrapper
                                          label="Ученая степень"
                                          name="academicdegree"
                                          value={modalForm.values.academicdegree}
                                          error={modalForm.errors.academicdegree}
                                          onChange={modalForm.handleChange}
                                      />
                                  </Col>
                                  <Col md={6}>
                                      <ModalInputWrapper
                                          label="Дата рождения"
                                          type="date"
                                          name="birthdate"
                                          value={moment(modalForm.values.birthdate).format('YYYY-MM-DD')}
                                          error={modalForm.errors.birthdate}
                                          onChange={modalForm.handleChange}
                                      />
                                  </Col>
                                  <Col md={6}>
                                      <ModalInputWrapper
                                          label="Дата смерти"
                                          type="date"
                                          name="deathdate"
                                          value={moment(modalForm.values.deathdate).format('YYYY-MM-DD')}
                                          error={modalForm.errors.deathdate}
                                          onChange={modalForm.handleChange}
                                      />
                                  </Col>
                                  <Col md={12}>
                                      <ModalInputWrapper
                                          label="Авторский знак"
                                          name="authorsign"
                                          value={modalForm.values.authorsign}
                                          error={modalForm.errors.authorsign}
                                          onChange={modalForm.handleChange}
                                      />
                                  </Col>
                              </Row>
                          </Col>
                      </Row>
                  )}
              </Tab>
              <Tab eventKey="books" title="Книги">
                  {books.length > 0 && (
                      <>
                          <Col md={12}>
                              <div className={styles.booksBoxTitle}>Список книг</div>
                              <div className={styles.booksBox}>
                                  {books.map(item => (
                                      <div className={styles.booksBox_row}>
                                          <div className={styles.booksBox_cell}>{item.title}</div>
                                          <Link
                                              to={`/book/${item.id}/edit`}
                                              target="_blank"
                                              className={styles.booksBox_button}
                                          >
                                              Ред.
                                          </Link>
                                      </div>
                                  ))}
                              </div>
                          </Col>
                      </>
                  )}
              </Tab>
          </Tabs>
      </CatalogsModalWithForm>
  );
};

AuthorModal.propTypes = {
  modalData: PropTypes.object,
  setModalData: PropTypes.func,
  onSubmit: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorModal);
