import { SUCCESS, TEACHERS_LIST_REQUEST } from "../../actions/action-types";

const initialState = [];

export const teachersReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${TEACHERS_LIST_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
