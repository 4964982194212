import React from "react";
import PropTypes from "prop-types";
import styles from "./AdminUsersTable.module.css";
import { ADD, EDIT } from "../../../constants/modalTypes";
import DataTableCol from "../../DataTable/Column";
import DataTable from "../../DataTable";

const AdminUsersTable = ({
  data,
  setModal,
  sortBy,
  setSort,
  selectedId,
  handleSelect,
  onDelete
}) => {
  const openModal = item => {
    setModal({
      modalType: EDIT,
      data: item
    });
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.button}
        onClick={() => setModal({
          modalType: ADD,
          data: {
            lastname: "",
            firstname: "",
            patronymic: "",
            login: "",
            password: ""
          }
        })}
      >
        добавить
      </div>
      <div className={styles.button} onClick={onDelete}>
        удалить
      </div>
      <DataTable
        data={data}
        sortBy={sortBy}
        onHeadClick={setSort}
        onCellClick={openModal}
      >
        <DataTableCol
          title="ФИО"
          value="displayname"
          className={styles.leftAlign}
          colSpan={4}
        />
        <DataTableCol title="Логин" value="login" />
        <DataTableCol
          title="Выбрано"
          passClicks
          resolver={item => (
            <input
              type="checkbox"
              checked={item.mid === selectedId}
              onChange={() => handleSelect(item.mid)}
            />
          )}
        />
      </DataTable>
    </div>
  );
};

AdminUsersTable.propTypes = {
  data: PropTypes.array,
  setModal: PropTypes.func,
  sortBy: PropTypes.string,
  setSort: PropTypes.func,
  selectedId: PropTypes.number,
  handleSelect: PropTypes.func,
  onDelete: PropTypes.func
};

AdminUsersTable.defaultProps = {
  data: []
};

export default AdminUsersTable;
