/*IMAGES*/
import readingImage from '../../img/books.jpg';
import videoImage from '../../img/video.jpg';
import tvImage from '../../img/television.jpg';
import audioImage from '../../img/audio__aa.jpg';
import presentImage from '../../img/presentation.jpg';
import documentImage from '../../img/image5.jpg';
import modelImage from '../../img/armata_06_s.jpg';
import nirImage from '../../img/nir.jpg'
import magazineImage from '../../img/bvc432gr.jpg';
import coursesImage from '../../img/534dgsgd.jpg';
import anplImage from '../../img/nf346tbds.jpg';
import fictionBooks from '../../img/fictionbook.jpg'
import React from "react";

export const libraryGridData = [
  {
    "title": 'Образовательные ресурсы',
    "subtitle": 'библиографических записей',
    "image": readingImage,
    "destination": '/reading',
    "id": '1'
  },
  {
    "title": 'Военное видео',
    "subtitle": 'видеозаписей',
    "image": videoImage,
    "destination": '/librarymaretials/5',
    "id": '5'
  },
  {
    "title": 'Цифровое телевидение',
    "subtitle": '5 ТВ каналов',
    "image": tvImage,
    "destination": 'http://tv.biblio.nintegra.ru/',
    "id": '',
    "isExternal": true
  },
  {
    "title": 'Художественная литература',
    "subtitle": 'изданий',
    "image": fictionBooks,
    "destination": '/librarymaretials/1',
  },
  {
    "title": 'Аудио',
    "subtitle": 'аудиозаписей',
    "image": audioImage,
    "destination": '/librarymaretials/6',
    "id": '6'
  },
  {
    "title": 'Презентации',
    "subtitle": 'презентаций',
    "image": presentImage,
    "destination": '/librarymaretials/4',
    "id": '4'
  },
  {
    "title": 'Документы',
    "subtitle": 'документов',
    "image": documentImage,
    "destination": '/librarymaretials/7',
    "id": '7'
  },
  {
    "title": '3D модели',
    "subtitle": 'моделей',
    "image": modelImage,
    "destination": '/librarymaretials/10',
    "id": '10'
  },
  {
    "title": 'Научные работы',
    "subtitle": 'научных работ',
    "image": nirImage,
    "destination": '/librarymaretials/ElectronicResourcesUsage',
    "id": '13'
  },
  {
    "title": 'Журналы, брошюры',
    "subtitle": 'периодических изданий',
    "image": magazineImage,
    "destination": '/librarymaretials/11',
    "id": '11'
  },
  {
    "title": 'Электронные учебники',
    "subtitle": 'электронных учебников',
    "image": coursesImage,
    "destination": '/librarymaretials/12',
    "id": '12'
  },
  {
    "title": 'Антиплагиат',
    "subtitle": 'записей в базе данных',
    "image": anplImage,
    "destination": '/librarymaretials/null',
    "id": ''
  },
];
