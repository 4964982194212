import React from 'react';
import PropTypes from 'prop-types';
import styles from './DocumentsSearchForm.module.css';
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";

const DocumentsSearchForm = ({ data, setSearchData, startSearch, types }) => {
  const onChange = ({ target: { name, value } }) => setSearchData({ ...data, [name]: value });

  return (
    <div className={"formContainer"}>
      <Row>
        <Col md={10}>
          <Row>
            <Col md={6}>
              <ModalInputWrapper label={"Вид документа"}>
                <select value={data.documenttype}
                        className={styles.select}
                        name="documenttype"
                        onChange={onChange}>
                  <option value={""}>
                    Не выбрано
                  </option>
                  {types.map((item, index) =>
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  )}
                </select>
              </ModalInputWrapper>
            </Col>
            <Col md={3}>
              <ModalInputWrapper
                 name="from"
                 onChange={onChange}
                 type={"date"}
                 label={"С"}
              />
            </Col>
            <Col md={3}>
              <ModalInputWrapper
                 name="to"
                 onChange={onChange}
                 type={"date"}
                 label={"По"}
              />
            </Col>
            <Col md={12}>
              <ModalInputWrapper
                label={'Поиск по примечанию'}
                 onChange={onChange}
                 name={"query"}
                 value={data.query}
              />
            </Col>
          </Row>
        </Col>
        <Col md={2}>
          <ModalInputWrapper label={''}>
            <Button
              label={"Найти"}
              onClick={startSearch}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

DocumentsSearchForm.propTypes = {
  data: PropTypes.object,
  setSearchData: PropTypes.func,
  startSearch: PropTypes.func,
  types: PropTypes.array
};

export default DocumentsSearchForm;
