import React from 'react';
import PropTypes from 'prop-types';
import styles from './AccessLogTable.module.css';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import { SECURITY_ACCESS_SORT } from "../../../constants/sort/securityAccessSort";
import { SORT_DIRECTION } from "../../../constants/sortDirections";

const CheckDataLogTable = ({ data, sortBy, setSort }) => {


 return (
    <div className={styles.container}>
      <DataTable data={data} sortBy={sortBy} onHeadClick={setSort}>
        <DataTableCol title="Дата и время" sortParams={[SECURITY_ACCESS_SORT.ISSUE_TIME, SORT_DIRECTION.DESC]} resolver={({issue_time}) => new Date(issue_time).toLocaleString()} colSpan={3}/>
        <DataTableCol title="ID пакета" sortParams={[SECURITY_ACCESS_SORT.FROM_IP]} value="id" colSpan={2}/>
        <DataTableCol title="Размер" sortParams={[SECURITY_ACCESS_SORT.USERNAME]} resolver={() => Math.ceil(Math.random()*1000) + "KB"} colSpan={2}/>
        <DataTableCol title="CRC" sortParams={[SECURITY_ACCESS_SORT.MESSAGE]} value="crc" colSpan={2}/>
        <DataTableCol title="Статус" sortParams={[SECURITY_ACCESS_SORT.ISSUE_TYPE]} value="status" />
      </DataTable>
    </div>
  );
};

CheckDataLogTable.propTypes = {
  data: PropTypes.array,
  sortBy: PropTypes.string,
  setSort: PropTypes.func,
};

export default CheckDataLogTable;
