import React from 'react';
import PropTypes from 'prop-types';
import styles from "../../FundAccounting/SearchForm/SummaryControlSearchForm.module.css";
import {Col, Row} from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import {STATUS_LIST} from "../../../scenes/BookScanPlan";

const SearchForm = ({ formData, setFormData, onSubmit }) => {
  const onChange = ({ target: { name, value } }) => setFormData({ ...formData, [name]: value });

  return (
    <div className={styles.container}>
      <Row>
        <Col md={3}>
          <ModalInputWrapper
            value={formData.from}
            name="from"
            onChange={onChange}
            type={"date"}
            label={"С"}
          />
        </Col>
        <Col md={3}>
          <ModalInputWrapper
            value={formData.to}
            name="to"
            onChange={onChange}
            type={"date"}
            label={"По"}
          />
        </Col>
        <Col md={4}>
          <ModalInputWrapper label={'Статус'}>
            <select
              name="status"
              onChange={onChange}
              value={formData.status}
              className={styles.select}
            >
              <option value="">Все</option>
              {Array.from(STATUS_LIST.entries()).map((item, index) => <option key={index} value={item[0]}>{item[1]}</option>)}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={2}>
          <ModalInputWrapper label={''}>
            <Button label={"Составить"} onClick={onSubmit}/>
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

SearchForm.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default SearchForm;