import { INVENTORY_COVERS_COUNT_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const inventoryCoversCountRequestStart = (data) => ({
  type: `${INVENTORY_COVERS_COUNT_REQUEST}${START}`,
  payload: data
});

export const inventoryCoversCountRequestSuccess = (data) => ({
  type: `${INVENTORY_COVERS_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const inventoryCoversCountRequestError = (data) => ({
  type: `${INVENTORY_COVERS_COUNT_REQUEST}${ERROR}`,
  payload: data
});
