import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
  FUND_ACCOUNTING_REQUEST,
  START
} from "../../actions/action-types";
import {
  fundAccountingRequestSuccess,
  fundAccountingRequestError
} from "../../actions/fundAccounting/list";
import {getFundAccounting} from "../../../api/fundAccounting/list";



export default function* fundAccountingWatcher() {
  yield takeLatest(`${FUND_ACCOUNTING_REQUEST}${START}`, fundAccountingListWorker);
}

function* fundAccountingListWorker() {
  const searchData = yield select(({fundAccounting}) => fundAccounting.search);
  try {
    const response = yield call(getFundAccounting, searchData);
    yield put(fundAccountingRequestSuccess(response.data));
  } catch (e) {
    yield put(fundAccountingRequestError(e));
  }
}
