import { api } from "../../App";

export const fetchTestsCount = () => {
    const params = {
        count: true,
    };

    return api.get('/tests', { params });
};

export const fetchTestsList = () => {

    return api.get('/tests');
};

export const createTestItem = () => {
    const params = {
        "title": "тест1",
        "mid":"1",
        "starttime":"31.12.2018",
        "period": "255555",
        "score": "5",
        "pca": "10",
        "rating": "10",
        "testresult": "False"
    };

    return api.post('/tests');
};