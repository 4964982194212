import React from 'react';
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import BookGivingStatsReportForm from "../../../components/Reports/BookGivingStats/Form";
import BookGivingStatsTable from "../../../components/Reports/BookGivingStats/Table";

const BookGivingStatsReportScene = ({  }) => {

  const data = [];

  const requestData = (data) => {
    console.log(data)
  };

  return (
    <SceneContainer title={"Статистика книговыдачи"}>
      <BookGivingStatsReportForm onSubmit={requestData}/>

      <BookGivingStatsTable data={data}/>
    </SceneContainer>
  );
};

BookGivingStatsReportScene.propTypes = {};

export default BookGivingStatsReportScene;