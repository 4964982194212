import { ALPHABET_ITEMS_REQUEST, SUCCESS } from "../../../../actions/action-types";

const initialState = [];

export const alphabetItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${ALPHABET_ITEMS_REQUEST}${SUCCESS}`:
      return action.payload;

    default:
      return state;
  }
};