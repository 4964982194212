import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {rolesListRequestStart} from "../../../redux/actions/roles/list";
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import SpinnerOuter from "../../../components/UtulityComponents/SpinnerOuter";
import CategoryPermissionsSearchForm from "../../../components/AdminCategoryPermissions/SearchForm";
import {setPermissionsBySeriesFormData} from "../../../redux/actions/permissions/bySeries/form";
import {setPermissionsBySeriesSearchData} from "../../../redux/actions/permissions/bySeries/search";
import {permissionsBySeriesEditRequestStart} from "../../../redux/actions/permissions/bySeries/edit";
import {SORT_DIRECTION} from "../../../constants/sortDirections";
import Pagination from "../../../components/Pagination";
import CategoryPermissionsDataTable from "../../../components/AdminPermissions/CategoryPermissons/DataTable";
import {permissionsBySeriesCountRequestStart} from "../../../redux/actions/permissions/bySeries/count";
import {permissionsBySeriesAddRequestStart} from "../../../redux/actions/permissions/bySeries/add";
import SeriesPermissionsDataTable from "../../../components/AdminPermissions/SeriesPermissions/DataTable";


const mapStateToProps = ({ roles, accessSeries }) => ({
  roles: roles.list,
  list: accessSeries.list,
  count: accessSeries.count,
  loader: accessSeries.loader,
  formData: accessSeries.form,
  searchData: accessSeries.search,
});

const mapDispatchToProps = {
  requestList: permissionsBySeriesCountRequestStart,
  requestRoles: rolesListRequestStart,
  setFormData: setPermissionsBySeriesFormData,
  setSearchData: setPermissionsBySeriesSearchData,
  setAccessStatus: permissionsBySeriesEditRequestStart,
  addCategoryToRole: permissionsBySeriesAddRequestStart
};

const SeriesPermissionsScene = ({
  roles,
  requestRoles,
  list,
  requestList,
  formData,
  setFormData,
  count,
  loader,
  searchData,
  setSearchData,
  setAccessStatus,
  addCategoryToRole
}) => {
  useEffect(() => {
    !roles.length && requestRoles();
  }, [roles, requestRoles]);

  const setSort = (sortBy) => {
    let sortDir = searchData.sortDirection === SORT_DIRECTION.ASC ?
      SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;

    setSearchData({
      ...searchData,
      sortDirection: sortDir,
      'orderBy': sortDir === SORT_DIRECTION.ASC ? sortBy : null,
      'orderByD': sortDir === SORT_DIRECTION.DESC ? sortBy : null,
      page: 1
    });
    requestList();
  };

  const setCurrentPage = (page) => {
    setSearchData({ ...searchData, page });
    requestList();
  };

  const onChangeAccessStatus = (event, series) => {
    const params = {
      role: formData.roleid,
      access: event.target.value,
      seriesid: series.category,
      itemid: series.id
    };
    series.id ?
      setAccessStatus(params)
      :
      addCategoryToRole(params)
  };

  return (
    <SceneContainer title={'Администрирование / Настройка прав доступа по сериям'}>
      <CategoryPermissionsSearchForm
        onSubmit={requestList}
        formData={formData}
        setFormData={setFormData}
        roles={roles}
      />

      {loader ?
        <SpinnerOuter isLoading={loader}/>
        :
        <>
          {!!list.length &&
          <div>
            <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>

            <SeriesPermissionsDataTable data={list} setSort={setSort} sortBy={searchData.sortBy} onChange={onChangeAccessStatus}/>

            <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
          </div>
          }
        </>
      }

    </SceneContainer>
  );
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeriesPermissionsScene);