import React from 'react';
import PropTypes from 'prop-types';
import styles from './InvNumbersItem.module.css';
import { Col, Row } from "react-bootstrap";

const InvNumbersModalSearchItem = ({ data, onClick }) => {
    return (
        <div className={styles.container} onClick={() => onClick(data)}>
            <Row>
                <Col md={4} className={styles.colBorder}>
                    <div className={styles.colInner}>
                        <span>Название</span>
                        {data.title}
                    </div>
                </Col>
                <Col md={4} className={styles.colBorder}>
                    <div className={styles.colInner}>
                        <span>Авторы</span>
                        {data.authors}
                    </div>
                </Col>
                <Col md={2} className={styles.colBorder}>
                    <div className={styles.colInner}>
                        <span>Штрих код</span>
                        {data.barcode}
                    </div>
                </Col>
                <Col md={2}>
                    <div className={styles.colInner}>
                        <span>Инв. номер</span>
                        {data.number}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

InvNumbersModalSearchItem.propTypes = {
    data: PropTypes.object,
    selected: PropTypes.bool
};

export default InvNumbersModalSearchItem;
