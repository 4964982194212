import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
  exportBooksRequestSuccess,
  exportBooksRequestError
} from '../../actions/bookExport/exportBooks';
import {
  exportBooks,
  getBookExports,
  deleteBookExport
} from '../../../api/booksExport/booksExport';
import {
  EXPORT_BOOKS_REQUEST, BOOK_EXPORTS_LOAD, BOOK_EXPORTS_DELETE, START
} from "../../actions/action-types";
import {
  bookExportsLoadRequestStart,
  bookExportsLoadRequestSuccess,
  bookExportsLoadRequestError
} from '../../actions/bookExport/getExports';
import {
  bookExportsDeleteRequestSuccess,
  bookExportsDeleteRequestError
} from '../../actions/bookExport/delete';

export default function* exportBooksWatcher() {
  yield takeLatest(`${EXPORT_BOOKS_REQUEST}${START}`, exportBooksWorker);
  yield takeLatest(`${BOOK_EXPORTS_LOAD}${START}`, bookExportListWorker);
  yield takeLatest(`${BOOK_EXPORTS_DELETE}${START}`, bookExportDeleteWorker);
}


function* exportBooksWorker ({ payload: bookIds }) {
    try {
      const response = yield call(exportBooks, bookIds);
      yield put(exportBooksRequestSuccess(response.data));
    } catch(error) {
      yield put(exportBooksRequestError(error));
    }
}

function* bookExportListWorker () {
    try {
      const response = yield call(getBookExports);
      yield put(bookExportsLoadRequestSuccess(response.data));
    } catch(error) {
      yield put(bookExportsLoadRequestError(error));
    }
}

function* bookExportDeleteWorker ({ payload: itemId}) {
    try {
      yield call(deleteBookExport, itemId);
      yield put(bookExportsDeleteRequestSuccess());
      yield put(bookExportsLoadRequestStart());
    } catch(error) {
      yield put(bookExportsDeleteRequestError(error));
    }
}
