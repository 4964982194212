import React, { useEffect } from 'react';
import styles from "../Aggregation/AggregationReportScene.module.css";
import ReportInfoBlock from "../../../components/Reports/InfoBlock";
import Chart from 'chart.js';
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import {reportGetRequestStart} from "../../../redux/actions/reports/get";
import {connect} from "react-redux";


const mapStateToProps = ({ reports }) => ({
  data: reports.report
});

const mapDispatchToProps = {
  requestReport: reportGetRequestStart
};


const CoversScanReportScene = ({ data, requestReport, ownId }) => {
  let bookScanPieChart, canvas;
  let dataArray = data[ownId];

  useEffect(() => {
    requestReport({
      id: ownId,
      formData: null
    });
  }, []);

  useEffect(() => {
    if(!dataArray) return;

    bookScanPieChart = new Chart(canvas.getContext('2d'), {
      type: "pie",
      data: {
        datasets: [{
          data: [dataArray[0].amount, dataArray[1].amount],
          backgroundColor: ['rgb(98, 209, 15)', 'rgb(64, 15, 255)']
        }],
        labels: [dataArray[0].name, dataArray[1].name]
      }
    });
  });


  return (
    <SceneContainer title={" План сканирования обложек"}>
      <div className={styles.dataContainer}>
        {dataArray && dataArray.map((item, index) =>
          <ReportInfoBlock  key={index} label={item.name} value={item.amount} />
        )}
      </div>
      <div className={styles.chartContainer}>
        <canvas
          ref={el => canvas = el}
        />
      </div>
    </SceneContainer>
  );
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoversScanReportScene);
