import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import {BOOK_TRANSFER_STATUS_LIST} from "../../../scenes/BookTransfer";


const BookTransferSearchForm = ({ data, setFormData, startSearch }) => {
  const onChange = ({ target: { name, value } }) => setFormData({ ...data, [name]: value });

  return (
    <div className="formContainer">
      <Row>
        <Col md={4}>
          <ModalInputWrapper
             name="from"
             onChange={onChange}
             type={"date"}
             label={"Дата (С)"}
          />
        </Col>
        <Col md={4}>
          <ModalInputWrapper
             name="to"
             onChange={onChange}
             type={"date"}
             label={"Дата (По)"}
          />
        </Col>
        <Col md={4}>
          <ModalInputWrapper label="Статус">
            <select
              name="status"
              onChange={onChange}
            >
              {Array.from(BOOK_TRANSFER_STATUS_LIST.entries())
                .map((item, index) => <option key={index} value={item[0]}>{item[1]}</option>)
              }
            </select>
          </ModalInputWrapper>
        </Col>
        <Col>
          <ModalInputWrapper
             name="query"
             onChange={onChange}
             type={"text"}
             label={"Поиск"}
          />
        </Col>
        <Col md={"auto"}>
          <ModalInputWrapper label={''}>
            <Button label={"Найти"} onClick={startSearch}/>
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

BookTransferSearchForm.propTypes = {
  data: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  startSearch: PropTypes.func.isRequired,
  types: PropTypes.array
};

export default BookTransferSearchForm;
