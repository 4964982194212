import { INV_NUMBER_SAVE_REQUEST, ERROR, START, SUCCESS } from "../../action-types";
import {createNotification} from "../../../../components/notification/notification";
import {BOOK_EDIT_INV_NUMBERS_SAVE_REQUEST} from "../../action-types";

export const bookInvNumberSaveRequestStart = (data) => ({
  type: `${BOOK_EDIT_INV_NUMBERS_SAVE_REQUEST}${START}`,
  payload: data
});

export const bookInvNumberSaveRequestSuccess = (data) => {
  const { number } = data;
  createNotification('success', `Инвентарный номер ${number} успешно добавлен в список`);

  return {
    type: `${BOOK_EDIT_INV_NUMBERS_SAVE_REQUEST}${SUCCESS}`,
    payload: data
  }
};

export const bookInvNumberSaveRequestError = (data) => {
  createNotification('error', 'Ошибка добавления инвентарного номера');

  return {
    type: `${BOOK_EDIT_INV_NUMBERS_SAVE_REQUEST}${ERROR}`,
    payload: data
  };
};
