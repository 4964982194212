import {COVERS_LIST_REQUEST, SET_LOCATION_CHANGE, SUCCESS} from "../../../actions/action-types";

const initialState = [];

export const coversListReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${COVERS_LIST_REQUEST}${SUCCESS}`:
            return action.payload;
        case SET_LOCATION_CHANGE:
            return initialState;

        default:
            return state;
    }
};