import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import styles from "./InventaryBookSearchForm.module.css";


const InventaryBookSearchForm = ({ data, setSearchData, startSearch }) => {
  const onChange = ({ target: { name, value } }) => setSearchData({ ...data, [name]: value });

  return (
    <div className={styles.container}>

      <Row>
        <Col md={3}>
          <ModalInputWrapper
             name="from"
             onChange={onChange}
             type={"date"}
             label={"С"}
             ref={el => this.inputTitle = el}
          />
        </Col>
        <Col md={3}>
          <ModalInputWrapper
             name="to"
             onChange={onChange}
             type={"date"}
             label={"По"}
          />
        </Col>
        <Col md={4}>
          <ModalInputWrapper
             name="search"
             onChange={onChange}
             type={"text"}
             label={"Фонд"}
          />
        </Col>
        <Col md={2}>
          <ModalInputWrapper label={''}>
            <Button label={"Найти"} style={styles.button} onClick={startSearch}/>
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

InventaryBookSearchForm.propTypes = {
  data: PropTypes.object,
  setSearchData: PropTypes.func,
  startSearch: PropTypes.func,
  types: PropTypes.array
};

export default InventaryBookSearchForm;
