import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {ADD, EDIT} from "../../constants/modalTypes";
import {SORT_DIRECTION} from '../../constants/sortDirections';

import {documentCountRequestStart} from "../../redux/actions/documents/documents/count";
import {setDocumentSearchData} from "../../redux/actions/documents/documents/search";
import {documentSaveRequestStart} from "../../redux/actions/documents/documents/save";
import {documentDeleteRequestStart} from "../../redux/actions/documents/documents/delete";
import {documentTypesAllRequestStart} from "../../redux/actions/documents/types/all";

import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import DocumentsSearchForm from "../../components/Documents/SearchForm";
import DocumentsTable from "../../components/Documents/Table";
import DocumentsModal from "../../components/Documents/Modal";
import Pagination from "../../components/Pagination";


const mapStateToProps = ({documents, documentTypes}) => ({
    list: documents.list,
    allTypes: documentTypes.all,
    searchData: documents.search,
    latestDocument: documents.latest,
    count: documents.count
});

const mapDispatchToProps = {
    requestList: documentCountRequestStart,
    requestAllTypes: documentTypesAllRequestStart,
    setSearchData: setDocumentSearchData,
    saveDocument: documentSaveRequestStart,
    deleteDocument: documentDeleteRequestStart,
};



const DocumentsScene = ({
                            count,
                            list,
                            requestList,
                            searchData,
                            setSearchData,
                            saveDocument,
                            deleteDocument,
                            allTypes,
                            requestAllTypes,
                            latestDocument
                        }) => {

        const [modalData, setModalData] = useState({modalType: null, data: null});
        const [selectedId, setSelectedId] = useState(null);

        useEffect(() => {
            requestList();
            requestAllTypes();
        }, [requestList, requestAllTypes]);

        useEffect(() => {
            setModalData(modalData =>
                modalData.modalType === ADD ? {
                    modalType: EDIT,
                    data: latestDocument
                } : modalData)
        }, [latestDocument]);

        const onSaveModal = (data) => {
            saveDocument(data);
        };

        const setCurrentPage = (page) => {
            setSearchData({...searchData, page});
            requestList()
        };

        const setSort = (sortBy) => {
            let sortDir = searchData.sortDirection === SORT_DIRECTION.ASC ?
                SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;

            setSearchData({...searchData, sortDirection: sortDir, sortBy, page: 1});
            requestList();
        };

        const handleSelect = (id) => {
            setSelectedId(selectedId === id ? null : id)
        };

        const onDelete = () => {
            selectedId && deleteDocument(selectedId);
        };

        const PaginationComponent = () => (
            <Pagination
                count={count}
                pageCount={searchData.pageCount}
                current={searchData.page}
                setCurrent={setCurrentPage}
            />
        );

        return (
            <SceneContainer title={'Документы'}>
                {!!allTypes.length &&
                <DocumentsSearchForm
                    types={allTypes}
                    data={searchData}
                    setSearchData={setSearchData}
                    startSearch={requestList}
                />
                }

                <PaginationComponent/>

                <DocumentsModal
                    types={allTypes}
                    onSave={onSaveModal}
                    setModalData={setModalData}
                    modalData={modalData}
                />
                <DocumentsTable
                    data={list}
                    selectedId={selectedId}
                    handleSelect={handleSelect}
                    onDelete={onDelete}
                    setModal={setModalData}
                    sortBy={searchData.sortBy}
                    setSort={setSort}
                />

                <PaginationComponent/>
            </SceneContainer>
        );
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsScene);
