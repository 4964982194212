import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {ADD, EDIT} from "../../../constants/modalTypes";
import styles from './BooksOrderTable.module.css';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import {BOOKSORDER_SORT} from "../../../constants/sort/bookOrderSort";
import DoublePrint from "../../PDFPrint";
import idGen from "../../../utils/generateID";

const BooksOrderTable = ({data, setModal, sortBy, setSort, onDelete, handleSelect, selectedId}) => {

    const openModal = (item) => setModal({modalType: EDIT, data: item});
    const id = useMemo(idGen, [data]);

    return (
        <div className={styles.container}>
            <div className={'d-flex justify-content-between mt-2 mb-2'}>
                <div>
                    <div className={styles.button} onClick={() => setModal({modalType: ADD, data: null})}>
                        добавить
                    </div>
                    <div className={styles.button} onClick={onDelete}>
                        удалить
                    </div>
                </div>
                <DoublePrint
                    tableData={data}
                    headers={[
                        {
                            title: 'ФИО',
                            value: 'creatorname',
                        },
                        {
                            title: 'Учебный курс',
                            value: 'cathedra',
                        },
                        {
                            title: 'Количество',
                            value: 'sumbooks',
                        },
                        {
                            title: 'Эл. копий',
                            value: 'sumdigitalbooks',
                        },
                        {
                            title: 'Дата заказа',
                            value: ({creationtime}) => new Date(creationtime).toLocaleDateString(),
                        }
                    ]}
                    title={'Заказ на подбор литературы'}
                    orientation={"landscape"}
                />
            </div>

            <DataTable data={data} sortBy={sortBy} onHeadClick={setSort} onCellClick={openModal} tableId={id}>
                <DataTableCol
                    sortParams={[BOOKSORDER_SORT.NAME]}
                    title="ФИО"
                    value="creatorname"
                    rowWidth={"30%"}
                />
                <DataTableCol
                    sortParams={[BOOKSORDER_SORT.CATHEDRA]}
                    title="Учебный курс"
                    value="cathedra"
                    rowWidth={"15%"}
                />
                <DataTableCol
                    sortParams={[BOOKSORDER_SORT.NUMBER]}
                    title="Количество"
                    value="sumbooks"
                    rowWidth={"15%"}
                />
                <DataTableCol
                    sortParams={[BOOKSORDER_SORT.DIGITAL_NUMBER]}
                    title="Эл. копий"
                    value="sumdigitalbooks"
                    rowWidth={"15%"}
                />
                <DataTableCol
                    sortParams={[BOOKSORDER_SORT.DATE]}
                    title="Дата заказа"
                    resolver={({creationtime}) => new Date(creationtime).toLocaleDateString()}
                    rowWidth={"15%"}
                />
                <DataTableCol title="Выбрано" passClicks resolver={(item) =>
                    <input type="checkbox" checked={item.id === selectedId}
                           onChange={() => handleSelect(item.id)}/>}
                />

            </DataTable>
        </div>
    );
};

BooksOrderTable.propTypes = {
    data: PropTypes.array,
    setModal: PropTypes.func,
    sortBy: PropTypes.string,
    setSort: PropTypes.func,
    selectedId: PropTypes.any,
    handleSelect: PropTypes.func,
    onDelete: PropTypes.func
};

BooksOrderTable.defaultProps = {
    data: []
};

export default BooksOrderTable;
