import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalInputWrapper from "../../Modal/InputWrapper";
import styles from './LayoutOptions.module.css';
import Button from "../../Button";
import classNames from 'classnames';
import { BASE_URL_WITHOUT_API } from '../../../constants/api';
import {Col, Row} from "react-bootstrap";

const LayoutOptions = ({ options, saveOptions, setOptionsData, headerImages }) => {

  const onChange = (e, destination) => {
    let updatedOptions = {...options};
    updatedOptions[destination][e.target.name] = [e.target.value];
    setOptionsData(updatedOptions);
  };

  return (
    <div>
      <div className={styles.section}>
        <h4>Шапка сайта</h4>
        <ModalInputWrapper
          label={"Цвет шапки сайта"}
          type="color"
          value={options.header.background}
          name="background"
          onChange={(e) => onChange(e, 'header')}
        />
        <h5>Картинка в шапке</h5>
        <Row>
          {headerImages.logo.map((item, index) => <Col key={index} md={3}>
            <label
              htmlFor={`headerImageInput${index}`}
              className={classNames(options.header.image[0] === item.url && styles.activeImage, styles.imageRadio)}
            >
              <img src={`${BASE_URL_WITHOUT_API}${item.url}`} alt={item.file} className={styles.image} />
              <input
                type="radio"
                name="image"
                value={item.url}
                id={`headerImageInput${index}`}
                onChange={(e) => onChange(e, 'header')}
              />
            </label>
          </Col>)}
        </Row>
      </div>
      <div className={styles.section}>
        <h4>Главное меню</h4>
        <ModalInputWrapper
          label={"Цвет главного меню"}
          type="color"
          value={options.menu.background}
          name="background"
          onChange={(e) => onChange(e, 'menu')}
        />
        <ModalInputWrapper
          label={"Цвет активного пункта главного меню"}
          type="color"
          value={options.menu.hover}
          name="hover"
          onChange={(e) => onChange(e, 'menu')}
        />
      </div>
      <div className={styles.section}>
        <h4>Главная страница</h4>
        <h5>Картинка на главной странице</h5>
        <Row>
          {headerImages.backgroundimage.map((item, index) => <Col md={3}>
            <label
              htmlFor={`bodyImageInput${index}`}
              className={classNames(options.body.backgroundimage[0] === item.url && styles.activeImage, styles.imageRadio)}
            >
              <img src={`${BASE_URL_WITHOUT_API}${item.url}`} alt={item.file} className={styles.image} />
              <input
                type="radio"
                name="backgroundimage"
                value={item.url}
                id={`bodyImageInput${index}`}
                onChange={(e) => onChange(e, 'body')}
              />
            </label>
          </Col>)}
        </Row>
      </div>
    </div>
  );
};

LayoutOptions.propTypes = {
  options: PropTypes.object
};

export default LayoutOptions;
