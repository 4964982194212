import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { setLibrarySearchData } from "../../redux/actions/library/info/search";
import { libraryListRequestStart } from "../../redux/actions/library/info/list";
import { librarySaveRequestStart } from "../../redux/actions/library/info/save";
import LibraryModal from "../../components/LibraryList/Modal";
import LibraryTable from "../../components/LibraryList/Table";
import { libraryDeleteRequestStart } from "../../redux/actions/library/info/delete";
import { libraryInstitutionsRequestStart } from "../../redux/actions/library/info/institutions";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";

const mapStateToProps = ({ library }) => ({
  list: library.info.list,
  points: library.info.points,
  funds: library.info.funds,
  searchData: library.info.search,
  institutions: library.info.institutions
});

const mapDispatchToProps = {
  setSearchData: setLibrarySearchData,
  requestLibraries: libraryListRequestStart,
  saveLibrary: librarySaveRequestStart,
  deleteLibrary: libraryDeleteRequestStart,
  requestInstitutions: libraryInstitutionsRequestStart
};

const MODAL_FORM_FIELDS = {
  name: "",
  idclient: ""
};

const LibraryListScene = ({ list, requestLibraries, searchData, setSearchData, saveLibrary, deleteLibrary, institutions, requestInstitutions }) => {
  useEffect(() => {
    requestLibraries()
  }, [requestLibraries]);

  useEffect(() => {
    requestInstitutions()
  }, [requestInstitutions]);

  const [modalData, setModalData] = useState({ modalType: null, data: null });
  const [selectedId, setSelectedId] = useState(null);

  const setSort = (sortBy) => {
    setSearchData({ ...searchData, sortBy, page: 1 });
  };

  const onSubmitModal = (values) => {
    saveLibrary({...modalData, data: {...modalData.data, ...values}}, closeModal);
  };

  const handleSelect = (id) => setSelectedId(selectedId === id ? null : id);

  const closeModal = () => {
    setModalData({ modalType: null, data: null });
  };

  return (
    <SceneContainer title="Справочники / Библиотеки">
      <Row>
        <Col md={12}>
          {modalData.data &&
            <LibraryModal
              onSubmit={onSubmitModal}
              setModalData={setModalData}
              modalData={modalData}
              institutions={institutions}
              closeModal={closeModal}
            />
          }
          <LibraryTable
            data={list}
            sortBy={searchData.sortBy}
            setSort={setSort}
            setModal={setModalData}
            handleSelect={handleSelect}
            selectedId={selectedId}
            initialFormFields={MODAL_FORM_FIELDS}
            onDelete={() => deleteLibrary(selectedId)}/>
        </Col>
      </Row>
    </SceneContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryListScene);
