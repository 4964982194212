import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { getFundMoving } from "../../../api/fundMoving/fundMoving";
import {
  FUND_MOVING_LOAD_REQUEST,
  START
} from "../../actions/action-types";
import {
  fundMovingLoadSuccess,
  fundMovingLoadError
} from "../../actions/fundMoving/load";

export default function* fundMovingWatcher() {
  yield takeLatest(`${FUND_MOVING_LOAD_REQUEST}${START}`, fundMovingWorker);
}

function* fundMovingWorker() {
  const searchData = yield select(({fundMoving}) => fundMoving.search);

  try {
    const response = yield call(getFundMoving, searchData);
    yield put(fundMovingLoadSuccess(response.data))
  } catch (e) {
    yield put(fundMovingLoadError(e))
  }
}
