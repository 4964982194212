import {
  SEARCH_AUDIT_ITEM_REQUEST,
  SUCCESS,
  START
} from "../../../actions/action-types";

const initialState = {
  isLoading: false,
  data: {}
};

export const searchAuditItemReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${SEARCH_AUDIT_ITEM_REQUEST}${START}`:
      return {
        ...state,
        isLoading: true
      };
    case `${SEARCH_AUDIT_ITEM_REQUEST}${SUCCESS}`:
      return {
        isLoading: false,
        data: JSON.parse(action.payload.payloadbody)
      };

    default: return state;
  }
};
