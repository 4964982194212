import {
  BOOK_CLEAN_COVER,
  BOOK_CLEAN_SCAN,
  BOOK_COVER_SAVE,
  BOOK_DATA_REQUEST,
  BOOK_SCAN_SAVE,
  BOOK_FILE_REQUEST,
  BOOK_PDF_SAVE,
  BOOK_PDF_DELETE,
  ERROR,
  START,
  SUCCESS
} from "../action-types";

export const bookDataRequestStart = id => ({
  type: `${BOOK_DATA_REQUEST}${START}`,
  payload: id
});

export const bookDataRequestSuccess = data => ({
  type: `${BOOK_DATA_REQUEST}${SUCCESS}`,
  payload: data
});

export const bookDataRequestError = data => ({
  type: `${BOOK_DATA_REQUEST}${ERROR}`,
  payload: data
});

export const saveBookScan = ({ file, bookId, side }) => ({
  type: `${BOOK_SCAN_SAVE}`,
  payload: { file, bookId, side }
});

export const savePdf = ({ bookId, file, body }) => ({
  type: `${BOOK_PDF_SAVE}`,
  payload: { bookId, file, body }
});

export const deletePdfRequest = ({ bookId, fileId }) => ({
  type: `${BOOK_PDF_DELETE}`,
  payload: { bookId, fileId }
});

export const cleanBookScan = ({ side, bookId }) => ({
  type: `${BOOK_CLEAN_SCAN}`,
  payload: { side, bookId }
});

export const saveBookCover = ({ file, bookId }) => ({
  type: `${BOOK_COVER_SAVE}${START}`,
  payload: { file, bookId }
});

export const saveBookCoverSuccess = (data) => ({
  type: `${BOOK_COVER_SAVE}${SUCCESS}`,
  payload: data
});

export const saveBookCoverError = (error) => ({
  type: `${BOOK_COVER_SAVE}${ERROR}`,
  payload: error
});

export const cleanBookCover = ({ bookId }) => ({
  type: `${BOOK_CLEAN_COVER}`,
  payload: { bookId }
});

export const bookFileRequestStart = bookId => ({
  type: `${BOOK_FILE_REQUEST}${START}`,
  payload: bookId
});

export const bookFileRequestSuccess = data => ({
  type: `${BOOK_FILE_REQUEST}${SUCCESS}`,
  payload: data
});

export const bookFileRequestError = data => ({
  type: `${BOOK_FILE_REQUEST}${ERROR}`,
  payload: data
});
