import React from 'react';
import Spinner from "react-bootstrap/Spinner";
import styles from "./SpinnerOuter.module.css";

const SpinnerOuter = ({ isLoading }) => {
  if(isLoading === undefined) isLoading = true;
  return (
    <>
      {isLoading &&
        <div className={styles.spinnerContainer}>
          <Spinner animation="grow" variant="primary"/>
        </div>
      }
    </>
  );
};

export default SpinnerOuter;
