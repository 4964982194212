import {call, put, takeLatest} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
    START, INVENTORY_BOOK_LIST_REQUEST, INVENTORY_BOOK_SAVE_REQUEST, INVENTORY_BOOK_DELETE_REQUEST
} from "../../../actions/action-types";
import {
    addBookToInventory,
    deleteBookToInventory,
    editBookToInventory,
    getInventoryBooksList
} from "../../../../api/inventory/bookToDocument";

import {
    documentBookListRequestError,
    documentBookListRequestStart,
    documentBookListRequestSuccess
} from "../../../actions/documents/books/list";
import {
    documentBookSaveRequestError,
    documentBookSaveRequestSuccess
} from "../../../actions/documents/books/save";
import {
    documentBookDeleteRequestError,
    documentBookDeleteRequestSuccess
} from "../../../actions/documents/books/delete";
import {
    inventoryBookListRequestError,
    inventoryBookListRequestStart,
    inventoryBookListRequestSuccess
} from "../../../actions/inventory/books/list";
import {inventoryBookSaveRequestError, inventoryBookSaveRequestSuccess} from "../../../actions/inventory/books/save";
import {
    inventoryBookDeleteRequestError,
    inventoryBookDeleteRequestSuccess
} from "../../../actions/inventory/books/delete";

export default function* documentBooksWatcher() {
    yield takeLatest(`${INVENTORY_BOOK_LIST_REQUEST}${START}`, inventoryBooksListWorker);
    yield takeLatest(`${INVENTORY_BOOK_SAVE_REQUEST}${START}`, inventoryBooksSaveWorker);
    yield takeLatest(`${INVENTORY_BOOK_DELETE_REQUEST}${START}`, inventoryBooksDeleteWorker);
}

function* inventoryBooksListWorker({payload: documentId}) {
    try {
        const response = yield call(getInventoryBooksList, documentId);
        yield put(inventoryBookListRequestSuccess(response.data));
    } catch (error) {
        yield put(inventoryBookListRequestError(error));
    }
}

function* inventoryBooksSaveWorker({payload: data}) {
    try {
        const response = yield call(data.id ? editBookToInventory : addBookToInventory, data);
        yield put(inventoryBookSaveRequestSuccess(response.data));
        yield put(inventoryBookListRequestStart(data.documentid));
    } catch (error) {
        yield put(inventoryBookSaveRequestError(error))
    }
}

function* inventoryBooksDeleteWorker({payload: data}) {
    try {
        const response = yield call(deleteBookToInventory, data.id);
        yield put(inventoryBookDeleteRequestSuccess(response.data));
        yield put(inventoryBookListRequestStart(parseInt(data.inventory)));
    } catch (error) {
        yield put(inventoryBookDeleteRequestError(error));
    }
}
