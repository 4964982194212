import {
  AUTH_REQUEST, CHECK_AUTH_REQUEST, ERROR,
  RESET_AUTH_DATA,
  SUCCESS
} from "../../actions/action-types";

const initialState = {
  data: undefined,
  error: null
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_AUTH_DATA:
      return {
        data: null,
        error: null
      };

    case `${AUTH_REQUEST}${SUCCESS}`:
      return {
        data: action.payload,
        error: null
      };

    case `${CHECK_AUTH_REQUEST}${SUCCESS}`:
      return {
        data: action.payload,
        error: null
      };

    case `${AUTH_REQUEST}${ERROR}`:
      return {
        data: null,
        error: action.payload
      };

    case `${CHECK_AUTH_REQUEST}${ERROR}`:
      return {
        data: null,
        error: null
      };

    default:
      return state;
  }
};