import React from 'react';
import PropTypes from 'prop-types';
import styles from './MessageSearchForm.module.css';

const READ_STATUSES = [
  {
    title: "Показать все",
    value: ""
  },
  {
    title: "Показать не прочитанные",
    value: "true"
  }
];

const MessageSearchForm = ({ data, onSubmit }) => {
  return (
    <div className={styles.container}>
      <select name="unread" value={data.unread} onChange={onSubmit}>
        {READ_STATUSES.map((item, index) => <option key={index} value={item.value}>{item.title}</option>)}
      </select>
    </div>
  );
};

MessageSearchForm.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MessageSearchForm;