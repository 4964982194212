import React from 'react';
import { configureStore } from "./redux/store";
import { Provider } from "react-redux";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Routes } from './routing/Routes';
import { configureApi } from "./api";
import './css/bootstrap.min.css';
import './css/global.css';


const store = configureStore();

export const api = configureApi(store);


function App() {
  return (
    <Provider store={store}>
        <Routes/>
        <NotificationContainer/>
    </Provider>
  );
}

export default App;
