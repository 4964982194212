import { ERROR, IMPORT_BOOKS_REQUEST, START, SUCCESS } from "../action-types";

export const importBooksRequestStart = () => ({
  type: `${IMPORT_BOOKS_REQUEST}${START}`,
});

export const importBooksRequestSuccess = (data) => ({
  type: `${IMPORT_BOOKS_REQUEST}${SUCCESS}`,
  payload: data
});

export const importBooksRequestError = (data) => ({
  type: `${IMPORT_BOOKS_REQUEST}${ERROR}`,
  payload: data
});