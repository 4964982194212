import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { PUBLISHERS_SAVE_REQUEST, START } from "../../../actions/action-types";
import { ADD } from "../../../../constants/modalTypes";
import { publishersAdd, publishersEdit } from "../../../../api/publishers/publishers";
import { savePublishersDataRequestError, savePublishersDataRequestSuccess } from "../../../actions/publishers/save";
import { publishersCountRequestStart } from "../../../actions/publishers/count";

export default function* savePublishersWatcher() {
  yield takeLatest(`${PUBLISHERS_SAVE_REQUEST}${START}`, savePublishersWorker)
}

function* savePublishersWorker({payload: { data, modalType: type }, callback}) {
  try {
    let response;
    if (type === ADD) {
      response = yield call(publishersAdd, data);
    } else {
      response = yield call(publishersEdit, data);
    }
    yield put(savePublishersDataRequestSuccess(response.data));
    yield call(callback);
    yield put(publishersCountRequestStart());
  } catch (error) {
    yield put(savePublishersDataRequestError(error))
  }
}