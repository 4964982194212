import {Font, StyleSheet} from "@react-pdf/renderer";

import mt from '../../../../../fonts/Montserrat-Regular.ttf';
import mtbold from '../../../../../fonts/Montserrat-Bold.ttf';
import mtsemibold from '../../../../../fonts/Montserrat-SemiBold.ttf';

Font.register({ family: 'Montserrat', fonts: [
        { src: mt }, // font-style: normal, font-weight: normal
        { src: mtbold, fontWeight: 600 },
        { src: mtsemibold, fontWeight: 700 },
    ]});

export const pdfStyles = StyleSheet.create({
    page: { fontFamily: 'Montserrat', fontSize: 10, borderWidth: 30  },

    //utility
    bold: { fontWeight: 'bold' },
    semiBold: { fontWeight: 600 },
    textRight: { textAlign: 'right' },
    textCenter: { textAlign: 'center' },

    mb3: { marginBottom: 3 },
    mb5: { marginBottom: 5 },
    mb10: { marginBottom: 10 },
    mb15: { marginBottom: 15 },

    mt3: { marginTop: 3 },
    mt5: { marginTop: 5 },
    mt10: { marginTop: 10 },
    mt15: { marginTop: 15 },

    font15: { fontSize: 15 },
    font18: { fontSize: 18 },
    font20: { fontSize: 20 },


    /* FLEX */
    dFlex: { display: 'flex',  flexDirection: "row" },
    justifyBetween: { justifyContent: 'space-between' },


    /* TABLE STYLES */
    text: { whiteSpace: 'normal', wordWrap: 'break-word', height: 'auto', fontSize: 10},
    textX: {fontFamily: 'Montserrat', fontSize: 12, paddingLeft: 10, borderWidth: 0, marginLeft: 20, marginRight: 20, textAlign: 'center'},
    textZ: {fontFamily: 'Montserrat', fontSize: 12, paddingLeft: 10, borderWidth: 0, margin: 20, textAlign: 'center'},
    table: { wordWrap: 'break-word', width: "96%", marginTop: '-20', margin: '2%', flexDirection: "column"},
    tableRow: { flexDirection: "row" , marginBottom: 0, padding: 0, maxHeight: 60 },
    tableSmallRow: { flexDirection: "row" , marginBottom: 0, padding: 0, maxHeight: 30, width: '100%' },
    tableHeadRow: { margin: "auto", flexDirection: "row", height: 90, width: '100%', marginBottom: 0, padding: 0, display: 'flex', justifyContent: 'space-between', borderStyle: "solid", borderWidth: 1, borderColor: "#ccc", },
    tableColNum: { width: "10%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 1, borderTopWidth: 1, borderColor: "#111" },
    tableCol: { borderStyle: "solid", borderWidth: 1, borderColor: "#111", height: '100%' },
    tableColXl: { width: "65%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 1, borderTopWidth: 1, borderColor: "#111" },
    tableCell: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row', fontSize: 12, fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center', margin: '3px 0' },
    tableCol3: { width: "3%" },
    tableCol5: { width: "5%" },
    tableCol10: { width: "10%" },
    tableCol15: { width: "15%" },
    tableCol20: { width: "20%" },
    tableCol25: { width: "25%" },
    tableCol30: { width: "30%" },
    tableCol35: { width: "35%" },
    tableCol40: { width: "40%" },
    tableCol50: { width: "50%" },
    tableCol55: { width: "55%" },
    tableCol60: { width: "60%" },
    tableCol70: { width: "70%" },
    tableCol80: { width: "80%" },
    tableCol90: { width: "90%" },
    tableCol100: { width: "100%" },
    /* TABLE STYLES */
});