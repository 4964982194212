import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {CarouselProvider, Slider, Slide, ButtonBack, ButtonNext} from 'pure-react-carousel';
import classNames from 'classnames';
import 'pure-react-carousel/dist/react-carousel.es.css';
import styles from './LibraryCarousel.module.css';
import LibraryCarouselItem from "./Item";
import {Col, Row} from "react-bootstrap";
import Button from "../../Button";
import {BASE_URL_WITHOUT_API} from "../../../constants/api";

const LibraryCarousel = ({data, nextPage, prevPage}) => {
    const [rerenderCount, setRerenderCount] = useState(0);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        setRerenderCount(count => count + 1);
    }, [data]);

    const changeCounter = inc => {
        if (counter > -1 && counter < data.length) setCounter(counter + inc);
    };

    const openLibraryCard = () => {
        const libraryCard = window.open(
            `${window.location.origin}/book/${data[counter].bookId}`
        );
        libraryCard.focus();
    };

    return (
        <div className={styles.container}>
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={53}
                orientation={'vertical'}
                totalSlides={data.length}
                dragEnabled={false}
                key={rerenderCount}>
                <div className={styles.buttonsContainer}>
                    <Row>
                        <Col md={{span: 4, offset: 4}} className={"text-center"}>
                            <ButtonBack className={classNames(styles.button, styles.buttonUp)} onClick={() => changeCounter(-1)} />
                        </Col>
                        <Col md={{span: 3, offset: 1}} className={"text-center"}>
                            <div className={classNames(styles.button, styles.buttonPrevPage)} onClick={prevPage}/>
                        </Col>
                    </Row>
                </div>
                <Slider>
                    {data.map((item, index) =>
                        <Slide key={index} index={index}>
                            <LibraryCarouselItem data={item} index={index}/>
                        </Slide>
                    )}
                </Slider>
                <div className={styles.buttonsContainer}>
                    <Row>
                        <Col md={{span: 4, offset: 4}} className={"text-center"} onClick={() => changeCounter(1)}>
                            <ButtonNext className={classNames(styles.button, styles.buttonDown)} />
                        </Col>
                        <Col md={{span: 3, offset: 1}} className={"text-center"}>
                            <div className={classNames(styles.button, styles.buttonNextPage)} onClick={nextPage}/>
                        </Col>
                    </Row>
                </div>
            </CarouselProvider>
            <div className={styles.openLK} ><Button label={'Перейти к книге'} onClick={openLibraryCard} /></div>
        </div>
    );
};

LibraryCarousel.propTypes = {
    data: PropTypes.array,
    nextPage: PropTypes.func,
    prevPage: PropTypes.func
};

export default LibraryCarousel;
