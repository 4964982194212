import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { getBookDistribute, setBookDistribute, deleteBookDistribute } from '../../../../api/documents/bookDistribute';
import {
  DOCUMENT_BOOK_DISTRIBUTE_SAVE_REQUEST,
  DOCUMENT_BOOK_DISTRIBUTE_GET_LIST_REQUEST,
  START
} from "../../../actions/action-types";
import {
  bookDistributeSaveRequestSuccess,
  bookDistributeSaveRequestError
} from "../../../actions/documents/bookDistribute/save";
import {
  bookDistributeLoadRequestSuccess,
  bookDistributeLoadRequestError, bookDistributeLoadRequestStart
} from "../../../actions/documents/bookDistribute/load";

export default function* documentBookDistributeWatcher() {
  yield takeLatest(`${DOCUMENT_BOOK_DISTRIBUTE_SAVE_REQUEST}${START}`, bookDistributeSaveWorker);
  yield takeLatest(`${DOCUMENT_BOOK_DISTRIBUTE_GET_LIST_REQUEST}${START}`, bookDistributeLoadWorker);
}

function* bookDistributeSaveWorker ({ payload: data }) {
    try {
      if(data.documentId) {
        yield call(deleteBookDistribute, data.data, data.documentId);
        yield call(setBookDistribute, data.data);
      } else {
        yield call(setBookDistribute, data.data);
      }
      yield put(bookDistributeSaveRequestSuccess());
      yield put(bookDistributeLoadRequestStart(data.documentId));
    } catch(error) {
      yield put(bookDistributeSaveRequestError(error));
    }
}

function* bookDistributeLoadWorker ({ payload: data }) {
    try {
      const response = yield call(getBookDistribute, data);
      yield put(bookDistributeLoadRequestSuccess(response.data));
    } catch(error) {
      yield put(bookDistributeLoadRequestError(error));
    }
}
