import React, {useState} from "react";
import {connect} from "react-redux";
import {Col} from "react-bootstrap";
import classNames from 'classnames';
import styles from "../DocumentsModal.module.css";

import {documentBookDeleteRequestStart} from "../../../../redux/actions/documents/books/delete";
import {coversCountRequestStart} from "../../../../redux/actions/covers/count";
import {setCoversFormData} from "../../../../redux/actions/covers/form";

import InvNumbersModalSearchItem from "../../../InvNumbers/Modal/SearchItem";
import DocumentsEditBookForm from "../../EditBookForm";
import DocumentBooksTable from "../../BooksTable";
import FundMovingModal from "../submodals/FundMovingModal";
import BookSearchForm from "../../../Covers/SearchForm";
import SpinnerOuter from "../../../UtulityComponents/SpinnerOuter";
import Button from "../../../Button";
import {documentBookSaveRequestStart} from "../../../../redux/actions/documents/books/save";
import PrintDestroyAct from "../PrintDestroyAct";

const mapStateToProps = ({covers, documents}) => ({
    loader: covers.loader,
    loadSuccess: covers.loadSuccess,
    formData: covers.form,
    searchResults: covers.list,
    books: documents.books,
    bookDistribute: documents.bookDistribute
});

const mapDispatchToProps = dispatch => ({
    deleteBook: data => dispatch(documentBookDeleteRequestStart(data)),
    setFormData: (data) => dispatch(setCoversFormData(data)),
    startCoversSearch: () => dispatch(coversCountRequestStart()),
    saveBook: data => dispatch(documentBookSaveRequestStart(data))
});

const DocumentsEdit = ({
                           loader, loadSuccess, modalData, currentBook, books, setCurrentBook,
                           saveBook, deleteBook, searchOpen, onAddBook, formData, setFormData, searchResults,
                           startCoversSearch, onSelectFromSearch, requestBooks, onCreateNewBook, formValidity,
                           documentid
                       }) => {

    const [fundMovingModalOpen, setFundMovingModalOpen] = useState(false);


    const onBookSave = (data) => {
        saveBook({...data, documentid});
        setCurrentBook(null);
    };

    const onBookDelete = (data) => {
        deleteBook(data);
        setCurrentBook(null);
    };

    return (
        <>
            {fundMovingModalOpen &&
            <FundMovingModal
                setClosed={() => setFundMovingModalOpen(false)}
                isOpen={fundMovingModalOpen}
                modalData={modalData}
                booksData={books}
                requestBooks={requestBooks}
            />
            }

            <Col md={12}>
                {<>
                    <div className={styles.booksSection}>
                        <div className={styles.title}>
                            Книги
                        </div>
                        <DocumentBooksTable
                            data={books}
                            modalData={modalData}
                            handleSelect={setCurrentBook}
                            selectedId={currentBook ? currentBook.id : null}
                        />
                        {currentBook &&
                        <DocumentsEditBookForm
                            data={currentBook}
                            onSave={onBookSave}
                            modalData={modalData}
                            onDelete={currentBook.id ? onBookDelete : () => setCurrentBook(null)}
                            setData={setCurrentBook}
                        />
                        }
                    </div>
                </>}
                <div className="mb-3">
                    <Button
                        label={!searchOpen ? "Добавить книгу" : "Отменить"}
                        onClick={onAddBook}
                        disabled={!formValidity}
                    />
                    {(Number(modalData.data.documenttype) === 1 && !!books.length) &&
                    <Button label={"Распределить по фондам"}
                            style={'ml-3'}
                            colorType={'green'}
                            onClick={() => setFundMovingModalOpen(true)}/>
                    }
                    <Button
                        label={"Добавить новую БЗ"}
                        style={'ml-3'}
                        colorType={'dark-blue'}
                        onClick={onCreateNewBook}
                        disabled={!formValidity}
                    />
                    {Number(modalData.data.documenttype) === 20 &&
                    <PrintDestroyAct/>
                    }
                </div>

                {searchOpen && <>
                    <BookSearchForm data={formData} setData={setFormData} disableAlphabet={true} onSubmit={startCoversSearch}/>

                    <SpinnerOuter isLoading={loader}/>

                    {loadSuccess && <>
                        <div className={styles.title}>
                            Результаты поиска
                        </div>
                        {searchResults.length > 0 ? <>
                            <div className={styles.searchResultsContainer}>
                                {searchResults.map((item, index) => (
                                    <InvNumbersModalSearchItem data={item} key={index}
                                                               onClick={onSelectFromSearch}/>
                                ))}
                            </div>
                        </> : <>
                            <div className={classNames('text-center', styles.textNotification)}>
                                Ничего не найдено, Вы можете <span onClick={onCreateNewBook}
                                                                   className={styles.textLink}>
                                                        создать новую библиографическую запись
                                                      </span>
                            </div>
                        </>}
                    </>}
                </>}
            </Col>
        </>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsEdit);
