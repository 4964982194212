import React from 'react';
import PropTypes from 'prop-types';
import DataTableCol from "../../DataTable/Column";
import DataTable from "../../DataTable";
import styles from './DocumentBooksTable.module.css';

const DocumentBooksTable = ({ data, selectedId, handleSelect }) => {

    return (
        <div className={styles.container}>
            <DataTable data={data}>
                <DataTableCol title="Название" value="title" rowWidth={'50%'}/>
                <DataTableCol title="Кол-во" value="quantity"/>
                <DataTableCol title="Выбрано" passClicks resolver={(item) =>
                    <input type="checkbox" checked={item.id === selectedId}
                           onChange={() => handleSelect(item)}/>}
                />
            </DataTable>
        </div>
    );
};

DocumentBooksTable.propTypes = {
    data: PropTypes.array
};

export default DocumentBooksTable;
