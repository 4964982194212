import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Sidebar.module.css'
import Button from "../../../components/Button";
import {ICONS} from "../../../utils/projectIcons";

const Sidebar = ({highlights, resetHighlights, selectedId, deleteItem}) => {

    const updateHash = highlight => {
        document.location.hash = `highlight-${highlight.id}`;
    };

    return (
        <div className={styles.sidebar} style={{width: "25vw"}}>
            <div className="description" style={{padding: "1rem"}}>
                <h2 style={{marginBottom: "1rem"}}>Ваши заметки</h2>

                <small>
                    Для создания заметки выделите текст или зажмите Alt и выделите область мышью
                </small>
            </div>

            <ul className={styles.highlights}>
                {highlights.map((highlight, index) => (
                    <li
                        key={index}
                        className={classNames(styles.highlight, {
                            [styles.active]: selectedId === highlight.id
                        })}
                        onClick={() => {
                            updateHash(highlight);
                        }}
                    >
                        <div>
                            <div className={'d-flex justify-content-between'}>
                                <strong>{highlight.comment.text}</strong>
                                <button
                                    title="Удалить элемент"
                                    className={classNames("bg_red iconButton")}
                                    onClick={() => deleteItem(highlight)}
                                >
                                    <img src={ICONS.CANCEL} alt=""/>
                                </button>
                            </div>

                            {highlight.content.text ? (
                                <blockquote style={{marginTop: "0.5rem"}}>
                                    {`${highlight.content.text.slice(0, 90).trim()}…`}
                                </blockquote>
                            ) : null}
                            {highlight.content.image ? (
                                <div
                                    className={styles.highlight__image}
                                    style={{marginTop: "0.5rem"}}
                                >
                                    <img src={highlight.content.image} alt={"Screenshot"}/>
                                </div>
                            ) : null}
                        </div>
                        <div className="highlight__location">
                            Страница {highlight.position.pageNumber}
                        </div>
                    </li>
                ))}
            </ul>
            {highlights.length > 0 ? (
                <div style={{padding: "1rem"}} className={'d-flex justify-content-end'}>
                    <Button label="Очистить заметки" onClick={resetHighlights}/>
                </div>
            ) : null}
        </div>
    );
};

Sidebar.propTypes = {
    highlights: PropTypes.array,
    resetHighlights: PropTypes.func.isRequired,
    selectedId: PropTypes.string,
    deleteItem: PropTypes.func,
};

export default Sidebar;