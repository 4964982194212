import React from 'react';
import PropTypes from 'prop-types';
import styles from "../../../Covers/List/Item/CoversListItem.module.css";
import classNames from "classnames";
import moment from "moment";
import {BASE_URL} from "../../../../constants/api";


const ReadingListUploadingFiles = ({ fileList, bookId }) => (
  <div className={styles.filesOpen}>
    <div className={classNames(styles.filesOpenItem, 'd-flex align-items-center')}>
      <div><b>Название</b></div>
      <div><b>Дата загрузки</b></div>
    </div>
    {fileList.map((item, index) => <div key={`fileListItem${index}`} className={classNames(styles.filesOpenItem, 'd-flex align-items-center')}>
      <div>{item.filename}</div>
      <div>{moment(item.filedate).format('DD.MM.YYYY')}</div>
      <div>
        <a href={`${BASE_URL}/book/${bookId}/file/${item.id}`}
           target="_blank" rel="noopener noreferrer" download={`${item.filename}`}>Скачать</a>
      </div>
    </div>)}
  </div>
);

ReadingListUploadingFiles.propTypes = {
  fileList: PropTypes.array,
  bookId: PropTypes.string
};

export default ReadingListUploadingFiles;