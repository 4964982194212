import React from "react";
import { Table } from "react-bootstrap";
import styles from "./Table.module.css";
import moment from "moment";

const StudentProjectsTable = ({ projects,setModal }) => {
  const sendModal = (id) => {
      setModal({
          status: true,
          id: id
      });
  };
  return (
    <>
      {(projects.length > 0 && (
        <Table bordered className={styles.studentProjectsTable}>
          <thead>
            <tr>
              <th>Вид работы</th>
              <th>Дисциплина</th>
              <th>Дата выдачи</th>
              <th>Срок выполнения</th>
              <th>Статус</th>
              <th>Оценка</th>
            </tr>
          </thead>
          <tbody>
            {projects.map(item => (
              <tr
                key={item.xp_key}
                onDoubleClick={() => sendModal(item.xp_key)}
              >
                <td>{item.type}</td>
                <td>{item.coursetitle}</td>
                <td>{moment(item.issuedate).format("DD.MM.YYYY")}</td>
                <td>{moment(item.duedate).format("DD.MM.YYYY")}</td>
                <td>{item.status}</td>
                <td>{item.grade || "не указано"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )) || (
        <div className={styles.errorMessage}>
          По данным параметрам ничего не найдено
        </div>
      )}
    </>
  );
};

export default StudentProjectsTable;
