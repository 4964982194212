import React, {useEffect, useState} from 'react';
import {createNotification} from "../../components/notification/notification";
import {connect} from "react-redux";

import {setSecurityLogFormData} from "../../redux/actions/securityLog/formData";
import {setSecurityLogSearchData} from "../../redux/actions/securityLog/searchData";
import {electronicCoursesCountRequestStart} from "../../redux/actions/electronicCourses/count";
import {electronicCoursesItemDeleteStart} from "../../redux/actions/electronicCourses/delete";
import {electronicCoursesItemSaveStart} from "../../redux/actions/electronicCourses/save";
import {electronicCoursesFileAddStart} from "../../redux/actions/electronicCourses/file";

import Pagination from "../../components/Pagination";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import ElectronicCoursesTable from '../../components/ElectronicCourses/Table';
import ElectronicCoursesModal from '../../components/ElectronicCourses/Modal';

const mapStateToProps = ({electronicCourses, auth}) => ({
    list: electronicCourses.list,
    searchData: electronicCourses.searchData,
    count: electronicCourses.count,
    authData: auth.data,
});

const mapDispatchToProps = {
    requestList: electronicCoursesCountRequestStart,
    setFormData: setSecurityLogFormData,
    setSearchData: setSecurityLogSearchData,
    deleteItem: electronicCoursesItemDeleteStart,
    addItem: electronicCoursesItemSaveStart,
    addFile: electronicCoursesFileAddStart
};

const ElectronicCourses = ({count, list, requestList, searchData, setSearchData, deleteItem, addItem, addFile, authData}) => {

    const [selectedId, setSelectedId] = useState(null);
    const [modalData, setModalData] = useState({
        isOpen: false, modalType: null, data: null,
    });

    useEffect(() => {
        requestList();
    }, []);

    const handleSelect = (id) => setSelectedId(selectedId === id ? null : id);

    const setCurrentPage = (page) => {
        console.log(page)
        setSearchData({...searchData, page});
        requestList();
    };

    const onItemDelete = () => {
        if (selectedId) {
            deleteItem(selectedId);
        } else {
            createNotification('error', 'Ошибка удаления, курс не выбран')
        }
    };

    return (
        <SceneContainer title="Прохождение электронных курсов">
            {list.length === 0 ?
                <SpinnerOuter isLoading/>
                : <>
                    <Pagination
                        count={count}
                        pageCount={searchData.pageCount}
                        current={searchData.page}
                        setCurrent={setCurrentPage}
                    />
                    <ElectronicCoursesTable
                        isLibrarian={authData.islibrarian}
                        addFile={addFile}
                        addItem={addItem}
                        onItemDelete={onItemDelete}
                        selectedId={selectedId}
                        handleSelect={handleSelect}
                        data={list}
                        sortBy={searchData.sortBy}
                        modalData={modalData}
                        setModalData={setModalData}
                    />
                    <Pagination
                        count={count}
                        pageCount={searchData.pageCount}
                        current={searchData.page}
                        setCurrent={setCurrentPage}
                    />
                    <ElectronicCoursesModal
                        modalData={modalData}
                        addItem={addItem}
                        setModalData={setModalData}
                    />
                </>
            }
        </SceneContainer>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ElectronicCourses);
