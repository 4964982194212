import React from 'react';
import PropTypes from 'prop-types';
import { EDIT } from "../../../constants/modalTypes";
import Modal from "../../Modal";
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Button from "../../Button";


const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Это обязательное поле')
    .max(1024, 'Недопустимая длинна названия'),
  region: Yup.string()
    .required('Выберите регион'),
  transferport: Yup.number()
    .notRequired()
    .max(65535, 'Число порта не может быть выше 65535')
});

const InstitutionsModal = ({ closeModal, onSubmit, modalData, regionList }) => {
  const { modalType, data } = modalData;

  const modalForm = useFormik({
    initialValues: data,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: values => onSubmit(values)
  });

  return (
    <Modal title={`Организация: ${modalType === EDIT ? "редактирование" : "добавление"}`} isVisible={true}>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Название организации"
            type="textarea"
            name="name"
            value={modalForm.values.name}
            error={modalForm.errors.name}
            onChange={modalForm.handleChange}
          />
        </Col>
        <Col md={12}>
          <ModalInputWrapper label="Регион" error={modalForm.errors.region}>
            <select
              name="region"
              value={modalForm.values.region}
              onChange={modalForm.handleChange}
            >
              <option value="" disabled>Выберите из списка</option>
              {regionList.map((item, index) =>
                <option value={item.idsubject} key={index}>{item.subject}</option>
              )}
            </select>
          </ModalInputWrapper>
        </Col>

        <Col md={12}>
          <ModalInputWrapper
            label="Адрес сервера"
            name="transferhost"
            value={modalForm.values.transferhost}
            onChange={modalForm.handleChange}
          />
        </Col>
        <Col md={12}>
          <ModalInputWrapper
            label="Порт сервера"
            name="transferport"
            type="number"
            value={modalForm.values.transferport}
            onChange={modalForm.handleChange}
            error={modalForm.errors.transferport}
          />
        </Col>
        <Col md={12}>
          <ModalInputWrapper
            label="Входящие"
            name="transferinbox"
            value={modalForm.values.transferinbox}
            onChange={modalForm.handleChange}
          />
        </Col>

        <Col md={12} className={'mt-2 d-flex justify-content-end'}>
          <Button label="Сохранить" type="submit" onClick={modalForm.handleSubmit} colorType="green" style={'mr-1'}/>
          <Button label="Отмена" onClick={closeModal} colorType="red"/>
        </Col>
      </Row>
    </Modal>
  );
};

InstitutionsModal.propTypes = {
  modalData: PropTypes.object,
  setModalData: PropTypes.func,
  onSubmit: PropTypes.func,
  closeModal: PropTypes.func
};

export default InstitutionsModal;
