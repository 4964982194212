import {api} from "../../App";
import {SORT_DIRECTION} from "../../constants/sortDirections";

export const getInventoryList = ({sortBy, maxResults, paginateFrom, sortDirection, query, documenttype, from, to}) => {
    const params = {
        maxResults,
        paginateFrom,
        // documenttype,
        // [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy,
        // from,
        // to,
        search: query,
    };
    return api.get('/inventory', {params});
};

export const getInventoryCount = (search) => {
    const params = {count: true, search};
    return api.get('/inventory', {params});
};

export const addDocument = (data) => {
    return api.post('/inventory', data);
};

export const getDocumentData = (id) => {
    return api.get(`/document/${id}`);
};

export const editDocument = (data) => {
    return api.put(`/inventory/${data.id}`, data);
};

export const deleteInventory = (id) => {
    return api.delete(`/inventory/${id}`);
};

export const getDocumentNumber = () => {
    return api.get(`/documentnumber`);
};
