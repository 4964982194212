import React, { useState } from "react";
import { bookPlacesActions } from "../../../redux/factory/bookPlacesReduxData";
import { coversCountRequestStart } from "../../../redux/actions/covers/count";
import { setCoversFormData } from "../../../redux/actions/covers/form";
import { connect } from "react-redux";
import BookSearchForm from "../../../components/Covers/SearchForm";
import Pagination from "../../../components/Pagination";
import { setCoversSearchData } from "../../../redux/actions/covers/search";
import CoversList from "../../../components/Covers/List";
import CoverItemModal from "../../../components/Covers/List/Item/CoverItemModal/CoverItemModal";
import SpinnerOuter from "../../../components/UtulityComponents/SpinnerOuter";
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";


const mapStateToProps = ({ covers, bookPlaces }) => ({
  list: covers.list,
  count: covers.count,
  formData: covers.form,
  searchData: covers.search,
  loader: covers.loader,
  places: bookPlaces
});

const mapDispatchToProps = {
  bookPlacesRequest: bookPlacesActions.bookPlacesRequestStart,
  requestList: coversCountRequestStart,
  setFormData: setCoversFormData,
  setSearchData: setCoversSearchData,
};

const CoversScene = ({
  places,
  bookPlacesRequest,
  count,
  list,
  requestList,
  formData,
  setFormData,
  searchData,
  setSearchData,
  loader
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalId, setModalId] = useState(null);

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page });
    requestList();
  };


  return (
      <SceneContainer title={'Справочники / Обложки'}>
          <CoverItemModal
              show={modalShow}
              close={() => setModalShow(false)}
              places={places}
              request={bookPlacesRequest}
              modalId={modalId}
          />
          <BookSearchForm data={formData} setData={setFormData} onSubmit={requestList} />

          {loader ? (
              <SpinnerOuter/>
          ) : (<>
              <Pagination
                  count={count}
                  pageCount={searchData.pageCount}
                  current={searchData.page}
                  setCurrent={setCurrentPage}
              />
              <CoversList
                  data={list}
                  setModalShow={setModalShow}
                  setModalId={setModalId}
              />
              <Pagination
                  count={count}
                  pageCount={searchData.pageCount}
                  current={searchData.page}
                  setCurrent={setCurrentPage}
              />
          </>)}
      </SceneContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoversScene);
