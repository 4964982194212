import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import styles from "./IndexRowItem.module.css";
import {Col} from "react-bootstrap";

const IndexRowItem = ({ destination, title, subtitle, image, amount, isExternal, newAmount }) => {
  newAmount = Number(newAmount);

  const linkBody = (
    <div className={styles.link} style={{backgroundImage: `url(${image})`}}>
      <div className={styles.title}>
        <span>{title}</span>
      </div>
    </div>
  );

  return (
    <Col xl={3} lg={4}>
      {isExternal ? (
        <a className={styles.outer} href={destination} rel="noreferrer noopener" target="_blank">
          {linkBody}
          <span className={styles.counter}>{subtitle}</span>
        </a>
      ) : (
        <Link to={destination} className={styles.outer}>
          {!!newAmount && <span className={styles.novsLabel}>+{newAmount}</span>}
          {linkBody}
          <span className={styles.counter}>{amount} {subtitle}</span>
        </Link>
      )}
    </Col>
  );
};

IndexRowItem.propTypes = {
  destination: PropTypes.string,
  title: PropTypes.string,
  amount: PropTypes.number,
  image: PropTypes.string
};

export default IndexRowItem;
