import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { SECURITY_LOG_COUNT_REQUEST, SECURITY_LOG_LIST_REQUEST, START } from "../../actions/action-types";
import { securityLogCountRequestError, securityLogCountRequestSuccess } from "../../actions/securityLog/count";
import { getSecurityLogCount, getSecurityLogList } from "../../../api/accessLog/security";
import { setSecurityLogSearchData } from "../../actions/securityLog/searchData";
import {
  securityLogListRequestError,
  securityLogListRequestStart,
  securityLogListRequestSuccess
} from "../../actions/securityLog/list";

export default function* securityLogWatcher() {
  yield takeLatest(`${SECURITY_LOG_COUNT_REQUEST}${START}`, securityLogCountWorker);
  yield takeLatest(`${SECURITY_LOG_LIST_REQUEST}${START}`, securityLogListWorker);
}

function* securityLogCountWorker() {
  const formData = yield select(({securityLog}) => securityLog.form);
  const searchData = yield select(({securityLog}) => securityLog.search);
  try {
    const response = yield call(getSecurityLogCount, formData);
    const count = parseInt(response.data.count, 10);
    yield put(securityLogCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setSecurityLogSearchData({...searchData, pageCount}));
    yield put(securityLogListRequestStart());
  } catch(error) {
    yield put(securityLogCountRequestError(error));
  }
}

function* securityLogListWorker() {
  const formData = yield select(({securityLog}) => securityLog.form);
  const searchData = yield select(({securityLog}) => securityLog.search);
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
  try {
    const response = yield call(getSecurityLogList, searchData, formData);
    yield put(securityLogListRequestSuccess(response.data));
  } catch (error) {
    yield put(securityLogListRequestError(error));
  }
}