import React from 'react';
import PropTypes from 'prop-types';
import DataTableCol from "../../DataTable/Column";
import DataTable from "../../DataTable";
import styles from './DocumentInstancesTable.module.css';

const DocumentBooksTable = ({ data, selectedId, handleSelect }) => {
    return (
        <div className={styles.container}>
                <DataTable data={data}>
                    <DataTableCol title="Название" value="title" rowWidth={'50%'}/>
                    <DataTableCol title="Инв.номер" value="number"/>
                    <DataTableCol title="Удалить" rowWidth={'10%'} passClicks resolver={(item) =>
                        <label className={styles.deleteOuter}>
                            <span>✕</span>
                            <input type="checkbox"
                                   className={styles.deleteBox}
                                   title="удалить"
                                   checked={item.id === selectedId}
                                   onChange={() => handleSelect(item)}/>
                        </label>}
                    />
                </DataTable>
        </div>
    );
};

DocumentBooksTable.propTypes = {
    data: PropTypes.array
};

export default DocumentBooksTable;
