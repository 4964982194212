import React, { useState, useEffect } from "react";
import styles from "./ReaderSearchScene.module.css";
import { Col, Row } from "react-bootstrap";
import Search from "../../components/Search";
import ReaderSearchResults from "../../components/ReaderSearch/Results";
import AdminUsersModal from "../../components/AdminUsers/Modal";
import {
  readerSearchRequestStart,
  setReaderSearchData
} from "../../redux/actions/readerSearch/search";
import { connect } from "react-redux";
import LinkButton from "../../components/LinkButton";
import {ADD, EDIT} from "../../constants/modalTypes";
import { usersSaveRequestStart } from "../../redux/actions/adminUsers/save";
import { searchReaderByCardData } from '../../api/reader/reader';
import {createNotification} from "../../components/notification/notification";


const mapStateToProps = ({ readerSearch }) => ({
  query: readerSearch.search,
  list: readerSearch.list
});

const mapDispatchToProps = dispatch => ({
  setQuery: query => dispatch(setReaderSearchData(query)),
  requestList: () => dispatch(readerSearchRequestStart()),
  saveUser: data => dispatch(usersSaveRequestStart(data)),
});

const ReaderSearchScene = ({
  query,
  setQuery,
  list,
  requestList,
  saveUser,
  history
}) => {
  const [modalData, setModalData] = useState({ modalType: null, data: null });
  const CONNECTION_PORT = 'ws://localhost:18988/cardreader';

  console.log(modalData)
  useEffect(() => {
    const connection = new WebSocket(CONNECTION_PORT);
    
    connection.onopen = function() {
      console.log("Соединение установлено.");
    };

    connection.onclose = function(event) {
      console.log('Код: ' + event.code + ' причина: ' + event.reason);
    };

    connection.onmessage = function(event) {
      console.log(event);
      const readerData = JSON.parse(event.data).cardnumber;
      if(readerData) {
        console.log(readerData);
        onCardReaded(readerData);
      }
    };

    connection.onerror = function(error) {
      console.log("Ошибка " + error.message);
    };
  }, []);

  const onCardReaded = async (rfid) => {
    console.log('начало')
    searchReaderByCardData(rfid)
      .then((response) => {
        if(response.data.length > 0) {
          let searchData = response.data[0];
          history.push(`/library_card/${searchData.mid}`);
        } else {
          createNotification('warning', 'Пользователь с таким читательским формуляром не найден');
        }
      });
  };

  const openModal = () => {
    setModalData({
      modalType: ADD,
      data: {
        lastname: "",
        firstname: "",
        patronymic: "",
        login: "",
        password: ""
      }
    });
  };

  return (
    <div className={styles.container}>
      <Row>
        <Col md={12}>
          <div className={styles.title}>
            Приложите читательский билет к считывателю
          </div>
        </Col>


        <Col md={{ span: 8, offset: 2 }}>
          <Search onSubmit={requestList} onChange={setQuery} value={query} />
          <LinkButton
            onClick={openModal}
          >
            Добавить
          </LinkButton>
          <ReaderSearchResults data={list} />
        </Col>
      </Row>
      <AdminUsersModal
        onSubmit={data => {
          saveUser(data);
          setModalData({ modalType: null, data: null });
        }}
        modalData={modalData}
        setModalData={setModalData}
      />
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReaderSearchScene);
