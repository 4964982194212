import { combineReducers } from "redux";
import { reportReducer } from "./aggregation/reportReducer";
import { reportsListReducer } from "./list/reportsListReducer";
import {reportsLoaderReducer} from "./loader/reportLoaderReducer";

export const reportsReducer = combineReducers({
  report: reportReducer,
  list: reportsListReducer,
  loader: reportsLoaderReducer
});
