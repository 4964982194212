import React from 'react';
import PropTypes from 'prop-types';
import ModalWindow from "../../ModalWindow";
import Button from "../../Button";
import {Col, Row} from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import styles from './BookScanOrderModal.module.css';

const BookScanOrderModal = ({isOpen, closeModal, modalData, setModalData, onSubmit}) => {

  const submitHandler = () => {
    const bookId = modalData.bookId;
    const locationId = modalData.selectedId;

    onSubmit({
      bookId,
      locationId
    });
    closeModal();
  };

  return (
    <ModalWindow
      title={'Выберите организацию для заказа скана книги'}
      isOpen={isOpen}
      onRequestClose={closeModal}
      width={'500px'}
      height={'300px'}
    >
      <Row>
        <Col sm={12}>
          <ModalInputWrapper label={'Выберите учебную организацию'}>
            <select className={styles.select} onChange={e => setModalData({...modalData, selectedId: e.target.value})}>
              <option value="">Выберите учебную организацию</option>
              {modalData.bookClientsArray.map((item, index) =>
                <option key={index} value={item.idclient}>{item.name}</option>
              )}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col sm={12} className={'d-flex justify-content-end'}>
          <Button
            label={'Создать заказ'}
            onClick={submitHandler}
            disabled={!modalData.selectedId}
          />
        </Col>
      </Row>
    </ModalWindow>
  );
};

BookScanOrderModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  modalData: PropTypes.object,
  setModalData: PropTypes.func,
  onSubmit: PropTypes.func
};

export default BookScanOrderModal;