import React from 'react';
import PropTypes from 'prop-types';
import ListItem from "../Item";
import {Row} from "react-bootstrap";

const MediaResourceReaderList = ({ list }) => {
  return (
    <Row>
      {list.map(item => <ListItem key={`mediaResReaderItem${item.id}`} data={item}/>)}
    </Row>
  );
};

MediaResourceReaderList.propTypes = {
  list: PropTypes.array
};

export default MediaResourceReaderList;