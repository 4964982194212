import { START, SUCCESS, TEACHERS_LIST_REQUEST } from "../action-types";

export const saveTeachersList = data => ({
  type: `${TEACHERS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const fetchTeachersList = () => ({
  type: `${TEACHERS_LIST_REQUEST}${START}`
});
