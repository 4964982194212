import {
  createActionWithPayload,
  createSimpleActionsForReducer
} from "../actions/actionsFactory";
import { START, SUCCESS } from "../actions/action-types";
import {
  createSimpleReducer,
  searchDataDefaultInitialState
} from "../reducers/reducersFactrory";
import { combineReducers } from "redux";
import {
  call,
  put,
  takeLatest,
  select
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";
import axios from "axios";

const GOOGLE_BOOKS_REQUEST = "GOOGLE_BOOKS_REQUEST";
const GOOGLE_BOOKS_COUNT_REQUEST = "GOOGLE_BOOKS_COUNT_REQUEST";
const SET_GOOGLE_BOOKS_SEARCH_DATA = "SET_GOOGLE_BOOKS_SEARCH_DATA";

const googleBooksApi = params => {
  return axios.get("https://www.googleapis.com/books/v1/volumes", { params });
};

export const googleBooksActions = {
  ...createSimpleActionsForReducer("list", GOOGLE_BOOKS_REQUEST),
  ...createSimpleActionsForReducer("count", GOOGLE_BOOKS_COUNT_REQUEST),
  setGoogleBooksSearchData: createActionWithPayload(
    SET_GOOGLE_BOOKS_SEARCH_DATA
  )
};

export const googleBooksReducers = combineReducers({
  search: createSimpleReducer(
    { ...searchDataDefaultInitialState },
    `${SET_GOOGLE_BOOKS_SEARCH_DATA}`
  ),
  count: createSimpleReducer(0, `${GOOGLE_BOOKS_COUNT_REQUEST}${SUCCESS}`),
  list: createSimpleReducer([], `${GOOGLE_BOOKS_REQUEST}${SUCCESS}`)
});

function* googleBooksLoadSaga() {
  const searchData = yield select(({ googleBooks: { search } }) => search);
  try {
    const res = yield call(googleBooksApi, {
      //q: searchData.query || '""',
      q: searchData.query? `${searchData.query}+intitle:${searchData.query}` : '""',
      startIndex: (searchData.page - 1) * searchData.maxResults,
      maxResults: searchData.maxResults
    });
    const count = parseInt(res.data.totalItems, 10);
    yield put(googleBooksActions.countRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(
      googleBooksActions.setGoogleBooksSearchData({ ...searchData, pageCount })
    );
    yield put(googleBooksActions.listRequestSuccess(res.data.items));
  } catch (e) {
    yield put(googleBooksActions.listRequestError());
  }
}

export function* googleSagaWatcher() {
  yield takeLatest(`${GOOGLE_BOOKS_REQUEST}${START}`, googleBooksLoadSaga);
}
