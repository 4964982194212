import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {scheduleActions} from "../../redux/factory/scheduleReduxData";
import {connect} from "react-redux";
import {bookSupplyListRequestStart} from "../../redux/actions/bookSupply/list";
import BookSupplyTable from "../../components/BookSupply/Table";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import BookSupplyTitle from "../../components/BookSupply/Title";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import styles from './BookSupply.module.css';
import BookSupplyPDFPrint from "../../components/BookSupply/PDFPrint";


const mapStateToProps = ({schedulePersonalReducer, bookSupply}) => ({
    yearsList: schedulePersonalReducer,
    list: bookSupply.list,
    loader: bookSupply.loader
});

const matchDispatchToProps = {
    requestYears: scheduleActions.loadRequestStart,
    requestList: bookSupplyListRequestStart,
};


const BookSupply = ({ yearsList, requestYears, requestList, list, loader }) => {

    const [selectedYear, setYear] = useState('');

    useEffect(() => {
        requestYears();
    }, [requestYears]);

    const onSelectChange = ({ target: t }) => {
        requestList(t.value);
        setYear(t.options[t.selectedIndex].text);
    };

    return (
        <SceneContainer>
            <BookSupplyTitle onSelectChange={onSelectChange} yearsList={yearsList}/>

            <div className={'d-flex justify-content-between align-items-center mt-3'}>
                <Link to="/booksupplystandarts" className={styles.content_link}>
                    Нормы книгообеспеченности
                </Link>
                {!!list.length &&
                <BookSupplyPDFPrint title={`Книгообеспеченность на ${selectedYear} гг.`} data={list}/>
                }
            </div>

            {loader ?
                <SpinnerOuter isLoading={loader}/>
                :
                <BookSupplyTable milspecs={list}/>
            }
        </SceneContainer>
    );
};


export default connect(
    mapStateToProps,
    matchDispatchToProps
)(BookSupply);