import {RESET_COVERS_FORM_DATA, SET_COVERS_FORM_DATA} from "../../../actions/action-types";


const initialState = {
    title: "",
    author: "",
    categoryid: "",
    isbn: "",
    year: "",
    udk: "",
    bbk: "",
    discipline: "",
    publisher: "",
    textsearch: "",
    idclient: ""
};

export const coversFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COVERS_FORM_DATA:
            return action.payload;

        case RESET_COVERS_FORM_DATA:
            return initialState;

        default:
            return state;
    }
};
