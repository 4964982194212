import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Input.module.css';

const Input = React.forwardRef(({ isModal, type, placeholder, value, onChange, style, size, name, ...otherProps }, ref) => {
  if(type === 'textarea') {
    return (
      <textarea className={classNames(styles.input, style)} name={name} placeholder={placeholder} value={value}
                onChange={onChange}/>
    )
  }

  return (
    <input autoFocus={isModal} ref={ref} className={classNames(styles.input, style)} name={name} type={type} placeholder={placeholder} value={value}
           onChange={onChange} size={size} {...otherProps}/>
  );
});

Input.propTypes = {
  size: PropTypes.number,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  style: PropTypes.string,
  name: PropTypes.string
};

Input.defaultProps = {
  size: 5,
};

export default Input;
