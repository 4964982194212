import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ModalWindow from "../../ModalWindow";
import Button from "../../Button";

import styles from './CatalogsModalWithForm.module.css'

const CatalogsModalWithForm = ({closeModal, submitHandler, title, children}) => {
    return (
        <ModalWindow
            title={title}
            isOpen={true}
            onRequestClose={closeModal}
        >
            { children }

            <div className={classNames('d-flex justify-content-end mt-2 pt-2', styles.controls)}>
                <Button label="Отмена" onClick={closeModal} colorType="red"/>
                <Button label="Сохранить" onClick={submitHandler} colorType="green" style={'ml-2'}/>
            </div>
        </ModalWindow>
    );
};

CatalogsModalWithForm.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
    closeModal: PropTypes.func.isRequired,
    submitHandler: PropTypes.func.isRequired,
};

export default CatalogsModalWithForm;