import {
  BOOK_EDIT_INV_NUMBERS_LIST_REQUEST,
  INV_NUMBERS_LIST_REQUEST,
  SET_LOCATION_CHANGE,
  SUCCESS
} from "../../../actions/action-types";

const initialState = [];

export const invNumbersListReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${INV_NUMBERS_LIST_REQUEST}${SUCCESS}`:
      return action.payload;

    case `${BOOK_EDIT_INV_NUMBERS_LIST_REQUEST}${SUCCESS}`:
      return action.payload;

    case SET_LOCATION_CHANGE:
      return initialState;

    default: return state;
  }
};