import React from 'react';
import PropTypes from 'prop-types';
import styles from './ReportInfoBlock.module.css';

const ReportInfoBlock = ({ label, value, resolver }) => {
  const displayValue = resolver ? resolver(value) : (value === undefined ? "n/a" : value);
  return (
    <div className={styles.container}>
      <div className={styles.label}>
        {label}
      </div>
      <div className={styles.value}>
        {displayValue}
      </div>
    </div>
  );
};

ReportInfoBlock.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  resolver: PropTypes.func
};

export default ReportInfoBlock;
