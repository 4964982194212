import React from 'react';
import styles from './Header.module.css';
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import UserMenu from "./UserMenu";
import { resolveUserPhoto } from "../../../utils/resolveUserPhoto";
import LibrarianHeader from '../Header/LibrarianHeader';
import ReaderHeader from '../Header/ReaderHeader';

const mapStateToProps = ({ auth, options }) => ({
  authData: auth.data,
  layoutOptions: options.layout
});

const Header = ({ authData, layoutOptions }) => {
  let userAvatar;

  if(authData) {
    userAvatar = resolveUserPhoto(authData.mid);
  }

  return (
    <>
      {authData && (
        authData.islibrarian ? (
          <LibrarianHeader layoutOptions={layoutOptions}/>
        ) : (
          <ReaderHeader layoutOptions={layoutOptions}/>
        ))}
    </>
  );
};

export default connect(mapStateToProps)(Header);
