import React from 'react';
import PropTypes from 'prop-types';
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import styles from './BookScanOrdersForm.module.css'
import {Col, Row} from "react-bootstrap";

const BookScanOrdersForm = ({ formData, setFormData, onSubmit }) => {
  return (
    <div className={styles.container}>
      <Row>
        <Col md>
          <ModalInputWrapper
            label={'Название'}
            name={'title'}
            value={formData.title}
            onChange={e => setFormData({...formData, title: e.target.value})}
          />
        </Col>
        <Col md={'auto'}>
          <ModalInputWrapper>
            <Button
              label={'Поиск'}
              onClick={onSubmit}
            />
          </ModalInputWrapper>
        </Col>
      </Row>



    </div>
  );
};

BookScanOrdersForm.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default BookScanOrdersForm;