import { SET_LIBRARY_SEARCH_DATA } from "../../../../actions/action-types";
import { LIBRARY_SORT } from "../../../../../constants/sort/librarySort";

const initialState = {
  sortBy: LIBRARY_SORT.NAME,

};

export const librarySearchReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_LIBRARY_SEARCH_DATA:
      return action.payload;

    default:
      return state;
  }
};
