import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {EPUB_READER_BOOK_REQUEST, START} from "../../actions/action-types";
import {getBookInfo} from "../../../api/book/book";
import {epubReaderBookRequestError, epubReaderBookRequestSuccess} from "../../actions/epubReader/book";

export default function* epubReaderWatcher() {
    yield takeLatest(`${EPUB_READER_BOOK_REQUEST}${START}`, bookWorker);
}


function* bookWorker({ payload: bookId }) {
    try {
        const response = yield call(getBookInfo, bookId);
        yield put(epubReaderBookRequestSuccess(response.data));
    } catch(error) {
        yield put(epubReaderBookRequestError(error));
    }
}