import { ERROR, IMPORT_BOOKS_REQUEST } from "../../../actions/action-types";

const initialState = "";

export const importBooksErrorReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${IMPORT_BOOKS_REQUEST}${ERROR}`:
      return action.payload;

    default: return state;
  }
};