import React from "react";
import classNames from 'classnames';

import "../style/Highlight.css";


const Highlight = ({
                       position,
                       onMouseOver,
                       onMouseOut,
                       comment,
                       isScrolledTo,
                       itemId,
                       selectedId,
                       setSelectedHighlight
                   }) => {

    const {rects, boundingRect} = position;

    const clickHandler = () => {
        setSelectedHighlight(itemId);
    };

    return (
        <div
            className={classNames(
                "Highlight", {
                    "Highlight--active": selectedId === itemId,
                    "Highlight--scrolledTo": isScrolledTo
                }
            )}
            onClick={clickHandler}
        >
            {comment ? (
                <div
                    className="Highlight__emoji"
                    style={{
                        left: 20,
                        top: boundingRect.top
                    }}
                >
                    {comment.emoji}
                </div>
            ) : null}
            <div className="Highlight__parts">
                {rects.map((rect, index) => (
                    <div
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                        key={index}
                        style={rect}
                        className={`Highlight__part`}
                    />
                ))}
            </div>
        </div>
    );
}

export default Highlight;
