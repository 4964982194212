import { ALPHABET_ITEMS_REQUEST, ERROR, START, SUCCESS } from "../../action-types";

export const alphabetItemsRequestStart = () => ({
  type: `${ALPHABET_ITEMS_REQUEST}${START}`
});

export const alphabetItemsRequestSuccess = (data) => ({
  type: `${ALPHABET_ITEMS_REQUEST}${SUCCESS}`,
  payload: data
});

export const alphabetItemsRequestError = (error) => ({
  type: `${ALPHABET_ITEMS_REQUEST}${ERROR}`,
  payload: error
});

