import React from "react";
import PropTypes from "prop-types";
import styles from "./CoversList.module.css";
import CoversListItem from "./Item";

const CoversList = ({ data, setModalShow, setModalId }) => {
  return (
    <div className={styles.container}>
      {data.map((item, index) => (
        <CoversListItem
          data={item}
          key={index}
          setModalShow={setModalShow}
          setModalId={setModalId}
        />
      ))}
    </div>
  );
};

CoversList.propTypes = {
  data: PropTypes.array
};

export default CoversList;
