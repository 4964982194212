import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
    DOCUMENT_INSTANCE_LIST_REQUEST,
    DOCUMENT_INSTANCE_DELETE_REQUEST,
    DOCUMENT_INSTANCE_ADD_REQUEST,
    START
} from "../../../actions/action-types";
import {
    getDocumentInstancesList,
    deleteInstanceToDocument,
    addInstanceToDocument,
} from '../../../../api/documents/instanceToDocument';
import {
    documentInstancesListRequestStart,
    documentInstancesListRequestSuccess,
    documentInstancesListRequestError
} from '../../../actions/documents/instances/list';
import {
    documentInstanceDeleteRequestSuccess,
    documentInstanceDeleteRequestError,
} from '../../../actions/documents/instances/delete';
import {
    documentInstancesAddRequestSuccess,
    documentInstancesAddRequestError,
} from '../../../actions/documents/instances/add';

export default function* documentInstanceWatcher() {
    yield takeLatest(`${DOCUMENT_INSTANCE_LIST_REQUEST}${START}`, documentInstanceListWorker);
    yield takeLatest(`${DOCUMENT_INSTANCE_DELETE_REQUEST}${START}`, documentInstanceDeleteWorker);
    yield takeLatest(`${DOCUMENT_INSTANCE_ADD_REQUEST}${START}`, documentInstanceAddWorker);
}

function* documentInstanceListWorker({ payload: documentId }) {
    try {
        const response = yield call(getDocumentInstancesList, documentId);
        yield put(documentInstancesListRequestSuccess(response.data));
    } catch (error) {
        yield put(documentInstancesListRequestError(error));
    }
}

function* documentInstanceAddWorker({payload: data}) {
    try {
        const response = yield call(addInstanceToDocument, data);
        yield put(documentInstancesAddRequestSuccess(response.data));
        yield put(documentInstancesListRequestStart(data.documentId));
    } catch (error) {
        yield put(documentInstancesAddRequestError(error));
    }
}

function* documentInstanceDeleteWorker({ payload: data }) {
    try {
        const response = yield call(deleteInstanceToDocument, data.id);
        yield put(documentInstanceDeleteRequestSuccess(response.data));
        yield put(documentInstancesListRequestStart(data.documentid));
    } catch (error) {
        yield put(documentInstanceDeleteRequestError(error));
    }
}
