import React, { useState, useEffect } from 'react';
import styles from './AuthScene.module.css';
import { Col, Row } from "react-bootstrap";
import AuthForm from "../../components/Auth/Form";
import { connect } from "react-redux";
import { authRequestStart, resetAuthData } from "../../redux/actions/user/auth";
import Button from "../../components/Button";

const mapStateToProps = state => ({
  error: state.auth.error,
  authData: state.auth.data
});

const mapDispatchToProps = dispatch => ({
  requestAuth: ({ username, password }) => dispatch(authRequestStart(username, password)),
  resetError: () => dispatch(resetAuthData())
});

const AuthScene = ({ requestAuth, error, resetError, authData, history }) => {
  const [formData, setFormData] = useState({ username: "", password: "" });

  useEffect(() => {
    if (authData) {
      history.push('/')
    }
  });

  const onSubmit = (e) => {
    e.preventDefault();
    requestAuth(formData);
  };

  return (
    <div className={styles.container}>
      <Row noGutters>
        <Col md={{ span: 6, offset: 3 }}>
          <AuthForm data={formData} setData={setFormData} onSubmit={onSubmit}/>
          {error &&
          <div className={styles.errorContainer}>
            <div className={styles.errorModal}>
              <div className={styles.text}>
                Неверное сочетание имени пользователя и пароля!
              </div>
              <Button onClick={resetError} label={"ОК"}/>
            </div>
          </div>}
          <div>
            <a href="https://foto.pamyat-naroda.ru/" className={styles.imgLink} target="_blank" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthScene);
