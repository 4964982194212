import { SET_SECURITY_LOG_FORM_DATA } from "../../../actions/action-types";

const initialState = {
  from: "",
  to: "",
  fromip: "",
  username: "",
  issue_type: ""
};

export const turnoutStatsFormReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_SECURITY_LOG_FORM_DATA:
      return action.payload;

    default:
      return state;
  }
};
