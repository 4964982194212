import React from 'react';
import PropTypes from 'prop-types';

import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";

const SDPOptions = ({options, setOptions, saveOptions}) => {

    const onChange = (e) => {
        setOptions({...options, [e.target.name]: e.target.value});
    };


    return (
        <div>
            <div className={'formContainer'}>
                <ModalInputWrapper
                    label={'Наименование номенклатуры дел, книг и журналов'}
                    name={'nomenclatureName'}
                    value={options.nomenclatureName}
                    onChange={onChange}
                />

                <ModalInputWrapper
                    type={'date'}
                    label={'Дата подписания'}
                    name={'nomenclatureSignDate'}
                    value={options.nomenclatureSignDate}
                    onChange={onChange}
                />

                <ModalInputWrapper
                    label={'Должноть подписавшего'}
                    name={'nomenclatureSignPosition'}
                    value={options.nomenclatureSignPosition}
                    onChange={onChange}
                />


                <ModalInputWrapper
                    label={'ФИО подписавшего'}
                    name={'nomenclatureSignPerson'}
                    value={options.nomenclatureSignPerson}
                    onChange={onChange}
                />

                <ModalInputWrapper
                    type={'date'}
                    label={'Дата утверждения'}
                    name={'nomenclatureApprovalDate'}
                    value={options.nomenclatureApprovalDate}
                    onChange={onChange}
                />

                <ModalInputWrapper
                    label={'Должность утвердившего'}
                    name={'nomenclatureApprovalPosition'}
                    value={options.nomenclatureApprovalPosition}
                    onChange={onChange}
                />

                <ModalInputWrapper
                    label={'ФИО утвердившего'}
                    name={'nomenclatureApprovalPerson'}
                    value={options.nomenclatureApprovalPerson}
                    onChange={onChange}
                />
            </div>
            <div className={'formContainer'}>
                <ModalInputWrapper
                    label={'Наименование книги учета'}
                    name={'nomenclatureBookName'}
                    value={options.nomenclatureBookName}
                    onChange={onChange}
                />
                <ModalInputWrapper
                    label={'Номер по номенклатуре'}
                    name={'nomenclatureNumber'}
                    value={options.nomenclatureNumber}
                    onChange={onChange}
                />
                <ModalInputWrapper
                    label={'Дополнительные сведенья'}
                    name={'nomenclatureExtra'}
                    value={options.nomenclatureExtra}
                    onChange={onChange}
                />

            </div>

        </div>
    );
};

SDPOptions.propTypes = {
    options: PropTypes.object,
    setOptions: PropTypes.func,
    saveOptions: PropTypes.func,
};

export default SDPOptions;