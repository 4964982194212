import React from 'react';
import PropTypes from 'prop-types';
import styles from './AccessLogTable.module.css';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import { SECURITY_LOG_TYPE } from "../../../constants/securityLogTypes";
import { SECURITY_ACCESS_SORT } from "../../../constants/sort/securityAccessSort";
import { SORT_DIRECTION } from "../../../constants/sortDirections";

const SecurityLogTable = ({ data, sortBy, setSort }) => {
  return (
    <div className={styles.container}>
      <DataTable data={data} sortBy={sortBy} onHeadClick={setSort}>
        <DataTableCol title="Логин" sortParams={[SECURITY_ACCESS_SORT.USERNAME]} value="username" className={styles.leftAlign} colSpan={2}/>
        <DataTableCol title="Дата и время" sortParams={[SECURITY_ACCESS_SORT.ISSUE_TIME, SORT_DIRECTION.DESC]} resolver={({issue_time}) => new Date(issue_time).toLocaleString()} colSpan={3}/>
        <DataTableCol title="IP" sortParams={[SECURITY_ACCESS_SORT.FROM_IP]} value="fromip" colSpan={2}/>
        <DataTableCol title="Сообщение" sortParams={[SECURITY_ACCESS_SORT.MESSAGE]} value="message" colSpan={2}/>
        <DataTableCol title="Тип" sortParams={[SECURITY_ACCESS_SORT.ISSUE_TYPE]} resolver={({ issue_type }) => SECURITY_LOG_TYPE[issue_type]}/>
      </DataTable>
    </div>
  );
};

SecurityLogTable.propTypes = {
  data: PropTypes.array,
  sortBy: PropTypes.string,
  setSort: PropTypes.func,
};

export default SecurityLogTable;
