import React, { useEffect, useState } from 'react';
import styles from './AdminPermissionsScene.module.css';
import { Col, Row } from "react-bootstrap";
import { rolesListRequestStart } from "../../../redux/actions/roles/list";
import { connect } from "react-redux";
import { permissionsByRoleRequestStart } from "../../../redux/actions/permissions/byRole";
import AdminPermissionsTable from "../../../components/AdminPermissions/Table";
import { permissionsSaveRequestStart } from "../../../redux/actions/permissions/save";
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";

const mapStateToProps = state => ({
  roles: state.roles.list,
  permissions: state.permissions.byRole,
  accessCategories: state.accessCategories
});

const mapDispatchToProps = dispatch => ({
  requestRoles: () => dispatch(rolesListRequestStart()),
  requestPermissions: (id) => dispatch(permissionsByRoleRequestStart(id)),
  changePermission: (roleId, section, value) => dispatch(permissionsSaveRequestStart(roleId, section, value))
});

const AdminPermissionsScene = ({ roles, requestRoles, permissions, requestPermissions, changePermission, accessCategories }) => {
  const [roleId, setRoleId] = useState("");

  useEffect(() => {
    !roles.length && requestRoles();
    roles.length && requestPermissions(roles[0].pmid);
  }, [roles, requestRoles, requestPermissions]);

  useEffect(() => {
    roles.length && setRoleId(roles[0].pmid);
  }, [roles]);

  const onRoleChange = (value) => {
    setRoleId(value);
    requestPermissions(value);
  };

  const onPermissionChange = (section, value) => {
    changePermission(roleId, section, value)
  };

  return (
    <SceneContainer title={'Администрирование / Настройка прав доступа'}>
      <Row>
        <Col md={12}>
          <div className={styles.roleSection}>
            <div className={styles.roleLabel}>
              Роль:
            </div>
            <div className={styles.roleSelect}>
              <select onChange={({target: {value}}) => onRoleChange(value)} value={roleId}>
                {roles.map((item, index) =>
                  <option value={item.pmid} key={index}>{item.name}</option>
                )}
              </select>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <AdminPermissionsTable data={permissions} onPermissionChange={onPermissionChange}/>
        </Col>
      </Row>
    </SceneContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPermissionsScene);
