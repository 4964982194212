import React from 'react';
import {connect} from 'react-redux';
import {reportGetRequestStart} from "../../../redux/actions/reports/get";
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import SpinnerOuter from "../../../components/UtulityComponents/SpinnerOuter";
import ElectronicResourcesUsageTable from "../../../components/Reports/ElectronicResourcesUsage/Table";
import ElectronicResourcesReportForm from "../../../components/Reports/ElectronicResourcesUsage/Form";

const mapStateToProps = ({ reports }) => ({
    data: reports.report,
    loader: reports.loader
});

const mapDispatchToProps = {
    requestReport: reportGetRequestStart
};

const ElectronicResourcesUsageReport = ({ ownId, data, requestReport, loader }) => {
    const requestData = (data) => {
        requestReport({
            id: ownId,
            formData: data
        });
    };

    return (
        <SceneContainer title="Отчеты / Статистика использования электронных ресурсов">
            <ElectronicResourcesReportForm onSubmit={requestData}/>

            {loader ?
                <SpinnerOuter isLoading={loader}/>
                :
                <>
                    {data[ownId] &&
                    <ElectronicResourcesUsageTable data={data[ownId]}/>
                    }
                </>
            }
        </SceneContainer>
    );
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ElectronicResourcesUsageReport);