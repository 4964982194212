import React, {Component} from 'react';
import {connect} from 'react-redux';
import {resolveUserPhoto} from "../../../../utils/resolveUserPhoto";
import styles from "../Header.module.css";
import {Col, Row} from "react-bootstrap";
import UserMenu from "../UserMenu";
import { BASE_URL_WITHOUT_API } from '../../../../constants/api';

const mapStateToProps = ({ auth, options }) => ({
  authData: auth.data,
  layoutOptions: options.layout
});

const mapDispatchToProps = {};

const LibrarianHeader = ({ authData, layoutOptions }) => {
  let userAvatar;

  if(authData) {
    userAvatar = resolveUserPhoto(authData.mid);
  }

  return (
    <div className={styles.container} style={{backgroundColor: layoutOptions.header.background}}>
      <div className="container">
        <Row noGutters>
          <Col
            className={styles.backgroundImage}
            style={{
              backgroundImage: `url(${BASE_URL_WITHOUT_API}${layoutOptions.header.image})`
            }}
          >
            <div className={styles.number}>
              Изделие 83т505
            </div>
            {authData && (
              authData.islibrarian ? (
                <>
                  <Row noGutters>
                    <Col xl={9}>
                      <div className={styles.ministrySection}>
                        <div className={styles.ministry}>
                          Министерство обороны Российской Федерации
                        </div>
                        <div className={styles.ministryIcon}/>
                      </div>
                      <div className={styles.library}>
                        СПО Электронная Библиотека
                      </div>
                    </Col>
                  </Row>
                  <UserMenu layoutOptions={layoutOptions} data={authData}/>
                </>
              ) : (
                <Row className={'align-items-end'} noGutters>
                  <Col lg={"auto"}>
                    <div
                      className={styles.avatar}
                      style={{backgroundImage: `url("${userAvatar}")`}}
                    />
                  </Col>
                  <Col>
                    <Row>
                      <Col lg={"auto"}>
                        <div className={styles.headerInfo}>
                          <div className={styles.ministryIcon}/>
                          <div className={styles.ministrySection}>
                            <div className={styles.ministry}>
                              Министерство обороны Российской Федерации
                            </div>
                          </div>
                          <div className={styles.library}>
                            СПО Электронная Библиотека
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <UserMenu data={authData}/>
                  </Col>
                </Row>
              )
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LibrarianHeader);
