import React, {useEffect, useCallback, useState} from 'react';
import {connect} from "react-redux";
import {Tab, Tabs} from "react-bootstrap";

import {coversReadingSearchRequestStart} from "../../redux/actions/covers/readingSearch";
import {coversCountRequestStart} from "../../redux/actions/covers/count";
import {setCoversFormData} from "../../redux/actions/covers/form";
import {setCoversSearchData} from "../../redux/actions/covers/search";
import Pagination from "../../components/Pagination";
import BookSearchForm from "../../components/Covers/SearchForm";
import ReadingList from "../../components/Reading/List";
import {reserveBookRequestStart} from "../../redux/actions/books/reserveBook";
import {unreserveBookRequestStart} from "../../redux/actions/books/unreserveBook";
import SearchByImageName from '../../components/SearchByImage/form';
import {setCoversFileData} from '../../redux/actions/covers/file';
import SceneContainer from '../../components/UtulityComponents/SceneContainer';
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import ReadingSmallSearch from "../../components/Reading/SmallSearch";
import ReadingAdvancedSearch from '../../components/Reading/AdvancedSearch';
import {coversAdvancedSearchParamsRequestStart} from '../../redux/actions/covers/advancedSearch/params';
import {coversListAdvancedSearchRequestStart} from '../../redux/actions/covers/advancedSearch/advancedSearch';
import {bookScanOrderAddRequestStart} from "../../redux/actions/bookScanOrders/add";
import {libraryInstitutionsRequestStart} from "../../redux/actions/library/info/institutions";
import BookScanOrderModal from "../../components/BookScanOrders/Modal";

import styles from "../BookEdit/BookEditScene.module.css";


const mapStateToProps = ({covers, options, library}) => ({
    list: covers.list,
    count: covers.count,
    formData: covers.form,
    searchData: covers.search,
    loader: covers.loader,
    loadSuccess: covers.loadSuccess,
    file: covers.file,
    advancedSearchParams: covers.advancedSearchParams,
    ownClient: options.own_client_id,
    institutionsList: library.info.institutions,
});

const mapDispatchToProps = {
    requestList: coversReadingSearchRequestStart,
    requestListOfExtendedSearch: coversCountRequestStart,
    requestListOfAdvancedSearch: coversListAdvancedSearchRequestStart,
    setFormData: setCoversFormData,
    setSearchData: setCoversSearchData,
    reserveBook: reserveBookRequestStart,
    unreserveBook: unreserveBookRequestStart,
    setFileData: setCoversFileData,
    requestAdvancedSearchParams: coversAdvancedSearchParamsRequestStart,
    addBookScanOrder: bookScanOrderAddRequestStart,
    requestInstitutions: libraryInstitutionsRequestStart,
};

export const advancedSearchFormRowTemplate = {
    "fieldtitle": '',
    "conditions": '',
    "value": '',
};

const ReadingScene = ({
                          count, loader, list, requestList, requestListOfExtendedSearch, formData, setFormData,
                          searchData, setSearchData, reserveBook, unreserveBook, file, setFileData, loadSuccess,
                          requestAdvancedSearchParams, advancedSearchParams, requestListOfAdvancedSearch,
                          addBookScanOrder, requestInstitutions, institutionsList, ownClient,
                      }) => {


    const [searchType, setSearchType] = useState(1);
    const [advancedSearchData, setAdvancedSearchData] = useState([JSON.parse(JSON.stringify(advancedSearchFormRowTemplate))]);
    const [modalData, setModalData] = useState(null);

    useEffect(() => {
        !institutionsList.length && requestInstitutions();
    }, [requestInstitutions, institutionsList]);

    useEffect(() => {
        requestAdvancedSearchParams()
    }, [requestAdvancedSearchParams]);


    const setCurrentPage = (page) => {
        setSearchData({...searchData, page});

        if (searchType === 3) {
            requestListOfAdvancedSearch(advancedSearchData);
        } else if (searchType === 2) {
            requestList(true);
        } else {
            requestList(false);
        }
    };

    const onImageChange = useCallback(
        file => {
            setFileData(file);
            if(file) {
                requestListOfExtendedSearch(file);
            }
        },
        [requestListOfExtendedSearch]
    );


    const addBookScanOrderHandler = (bookId, bookClients) => {
        const bookClientsArray = (bookClients && bookClients.split(',')) || [];

        //если в списке нет организаций
        if (bookClientsArray.length === 0) {
            addBookScanOrder({bookId, ownClient})
        }
        //если в списке одна организация
        else if (bookClientsArray.length === 1) {
            const selectedClientId = institutionsList.find(item => item.name === bookClientsArray[0]).idclient;
            addBookScanOrder({bookId, selectedClientId})
        }
        //если в списке несколько организаций
        else if (bookClientsArray.length > 1) {
            const checkSelectedBookClientHasOwnClinet = () => {
                return bookClientsArray
                    .map(item => institutionsList.find(institution => institution.name === item.trim()).idclient)
                    .includes(ownClient)
            };
            //если списке есть своя организация то указать ее
            if (checkSelectedBookClientHasOwnClinet()) {
                addBookScanOrder({bookId, ownClient});
            }
            //иначе пользователь должен выбрать местоположение из достпуных
            else {
                setModalData({
                    bookId: bookId,
                    selectedId: null,
                    bookClientsArray: bookClientsArray
                        .map(item => institutionsList.find(institution => institution.name === item.trim()))
                });

            }
        }
    };

    const imageName = file && file.name;

    const closeModal = () => {
        setModalData(null);
    };

    const PaginationComponent = () => (
        <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page}
                    setCurrent={setCurrentPage}/>
    );

    return (
        <SceneContainer title={'Электронный читальный зал'}>
            {modalData &&
            <BookScanOrderModal
                isOpen={true}
                closeModal={closeModal}
                modalData={modalData}
                setModalData={setModalData}
                onSubmit={addBookScanOrder}
            />
            }

            <Tabs
                id={'readingSearchTabs'}
                activeKey={searchType}
                onSelect={(k) => setSearchType(k)}
            >
                <Tab.Pane eventKey="1" title="Обычный поиск" className={styles.tabContent}>
                    <ReadingSmallSearch data={formData} setData={setFormData} onSubmit={() => requestList(false)}/>
                </Tab.Pane>
                <Tab.Pane eventKey="2" title="Расширенный поиск" className={styles.tabContent}>
                    <BookSearchForm data={formData} setData={setFormData} noVerifyStatus={true}
                                    onSubmit={() => requestList(true)}/>
                    <div className={'formContainer'}>
                        <SearchByImageName
                            file={file}
                            imageName={imageName}
                            loading={false}
                            noResults={false}
                            onChangeImage={onImageChange}
                        />
                    </div>
                </Tab.Pane>
                <Tab.Pane eventKey="3" title="Продвинутый поиск" className={styles.tabContent}>
                    <ReadingAdvancedSearch
                        searchParams={advancedSearchParams}
                        onSubmit={() => requestListOfAdvancedSearch(advancedSearchData)}
                        data={advancedSearchData}
                        setData={setAdvancedSearchData}
                    />
                </Tab.Pane>
            </Tabs>

            <PaginationComponent/>
            {loader ?
                <SpinnerOuter isLoading={loader}/>
                :
                <ReadingList
                    data={list}
                    reserveBook={reserveBook}
                    unreserveBook={unreserveBook}
                    loadSuccess={loadSuccess}
                    addBookScanOrder={addBookScanOrderHandler}
                />
            }
            <PaginationComponent/>
        </SceneContainer>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadingScene);
