import React from "react";
import T from "prop-types";
import styles from "./SearchByImage.module.css";
import Button from "../../components/Button";
import Spinner from "react-bootstrap/Spinner";
import ReadingList from "../../components/Reading/List";
import Pagination from "../../components/Pagination";
import ChooseImage from "../../components/ChooseFile";
import SearchByImageName from '../../components/SearchByImage/form';

const propTypes = {
  onChangeImage: T.func.isRequired,
  list: T.array,
  loading: T.bool,
  pageCount: T.number,
  currentPage: T.number,
  setCurrentPage: T.func,
  reserveBook: T.func,
  unreserveBook: T.func,
  allItemsNumber: T.number
};

const defaultProps = {
  onChangeImage: () => null,
  list: [],
  pageCount: 0,
  currentPage: 0,
  setCurrentPage: () => null,
  reserveBook: () => null,
  unreserveBook: () => null
};

const SearchByImage = ({
  onChangeImage,
  loading,
  list,
  reserveBook,
  unreserveBook,
  currentPage,
  setCurrentPage,
  pageCount,
  allItemsNumber,
  noResults,
  file
}) => {
  const imageName = file && file.name;

  return (
    <div className={styles.container}>

      <SearchByImageName
        file={file}
        imageName={imageName}
        loading={loading}
        noResults={noResults}
        onChangeImage={onChangeImage}
      />

      {!!list && !!list.length && !loading && !noResults && (
        <div className={styles.listContainer}>
          <Pagination
            pageCount={pageCount}
            current={currentPage}
            setCurrent={setCurrentPage}
            count={allItemsNumber}
          />
          <ReadingList
            data={list}
            reserveBook={reserveBook}
            unreserveBook={unreserveBook}
          />
        </div>
      )}
    </div>
  );
};

SearchByImage.propTypes = propTypes;
SearchByImage.defaultProps = defaultProps;

export default SearchByImage;
