import React, {useEffect, useRef, useCallback} from "react";
import styles from "./OptionsScene.module.css";
import {Col, OverlayTrigger, Row, Tab, Tabs} from "react-bootstrap";
import {optionsGetRequestStart} from "../../redux/actions/options/get";
import {connect} from "react-redux";
import {libraryInstitutionsRequestStart} from "../../redux/actions/library/info/institutions";
import {setOptionsData} from "../../redux/actions/options/setData";
import {optionsSaveRequestStart} from "../../redux/actions/options/save";
import {optionsLogoGetRequestStart} from "../../redux/actions/options/logo/get";
import {optionsLogoSaveRequestStart} from "../../redux/actions/options/logo/save";
import {outsecrecyToOptions} from "../../utils/toSelectOption";
import {setLayoutOptionsData} from '../../redux/actions/options/layout/setFormData';
import {layoutOptionsSaveRequestStart} from '../../redux/actions/options/layout/save';
import {outsecrecyCountRequestStart} from "../../redux/actions/outsecrecy/count";
import {layoutImagesListRequestStart} from '../../redux/actions/options/layout/layoutImages';
import MainOptions from '../../components/Options/MainOptions';
import LayoutOptions from '../../components/Options/LayoutOptions';
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import SDPOptions from "../../components/Options/SDPOptions";
import Button from "../../components/Button";
import SDPT645 from "../../components/Options/SDPT645";

const mapStateToProps = ({options, library, outsecrecy}) => ({
    options: options.data,
    saveStatus: options.status,
    institutions: library.info.institutions,
    logoUrl: options.logo,
    outsecrecyList: outsecrecy.list,
    layoutOptions: options.layout,
    headerImages: options.headerImages,
});

const mapDispatchToProps = {
    requestOptions: optionsGetRequestStart,
    setOptions: setOptionsData,
    saveOptions: optionsSaveRequestStart,
    requestInstitutions: libraryInstitutionsRequestStart,
    requestLogo: optionsLogoGetRequestStart,
    saveLogo: optionsLogoSaveRequestStart,
    requestOutsecrecy: outsecrecyCountRequestStart,
    setLayoutOptions: setLayoutOptionsData,
    saveLayoutOptions: layoutOptionsSaveRequestStart,
    requestHeaderImages: layoutImagesListRequestStart,

};

const OptionsScene = ({
                          options,
                          requestOptions,
                          institutions,
                          requestInstitutions,
                          requestLogo,
                          saveLogo,
                          logoUrl,
                          setOptions,
                          saveOptions,
                          saveStatus,
                          outsecrecyList,
                          requestOutsecrecy,
                          layoutOptions,
                          saveLayoutOptions,
                          setLayoutOptions,
                          requestHeaderImages,
                          headerImages
                      }) => {

    useEffect(() => {
        requestOptions();
        requestInstitutions();
        requestLogo();
        requestOutsecrecy();
        requestHeaderImages();
    }, [requestOptions, requestInstitutions, requestLogo, requestOutsecrecy, requestHeaderImages]);
    const imageInput = useRef(null);

    const onSaveImage = () => {
        imageInput.current.click();
    };

    const onLoadImage = e => {
        saveLogo(e.target.files[0]);
    };

    const outsecrecyOptions = useCallback(() => {
        return outsecrecyToOptions(outsecrecyList);
    }, [outsecrecyList]);


    const combineOptionsSave = () => {
        saveLayoutOptions();
        saveOptions();
    };



    return (
        <SceneContainer title={'Настройки'}>
            {options && (
                <div className={styles.dataContainer}>
                    <Tabs defaultActiveKey="index">
                        <Tab eventKey="index" title="Основные">
                            <MainOptions
                                options={options}
                                setOptions={setOptions}
                                institutions={institutions}
                                outsecrecyOptions={outsecrecyOptions}
                                logoUrl={logoUrl}
                                onSaveImage={onSaveImage}
                                imageInput={imageInput}
                                onLoadImage={onLoadImage}
                                saveOptions={saveOptions}
                                saveStatus={saveStatus}
                            />
                        </Tab>
                        <Tab eventKey="layout" title="Внешний вид">
                            <LayoutOptions
                                options={layoutOptions}
                                saveOptions={saveLayoutOptions}
                                setOptionsData={setLayoutOptions}
                                headerImages={headerImages}
                            />
                        </Tab>
                        {(options && Number(options.outsecrecy) === 3) &&
                        <Tab eventKey="sdp" title="Сведения о Номенклатуре дел, книг и журналов СДП">
                            <SDPOptions
                                options={options}
                                setOptions={setOptions}
                                saveOptions={saveOptions}
                            />
                        </Tab>
                        }
                        <Tab eventKey="sdpT645" title="Интеграция с изделием т645 ">
                            <SDPT645
                                options={options}
                                setOptions={setOptions}
                                saveOptions={saveOptions}
                            />
                        </Tab>

                    </Tabs>
                    <div className={'d-flex justify-content-end'}>
                        <Button label={'Сохранить'} onClick={combineOptionsSave}/>
                    </div>
                </div>
            )}
        </SceneContainer>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OptionsScene);
