import { BOOK_COVER_SAVE, START, SUCCESS, ERROR } from "../../../actions/action-types";

const initialState = {
  isLoading: false,
};

export const bookEditCoverReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${BOOK_COVER_SAVE}${START}`:
      return {
        isLoading: true
      };
    case `${BOOK_COVER_SAVE}${SUCCESS}`:
      return {
        isLoading: false
      };
    case `${BOOK_COVER_SAVE}${ERROR}`:
      return {
        isLoading: false
      };
    default: return state;
  }
}
