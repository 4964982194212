import React, {useState} from "react";
import {connect} from "react-redux";
import {Col} from "react-bootstrap";
import classNames from 'classnames';
import styles from "../DocumentsModal.module.css";

import InvNumbersModalSearchItem from "../../../InvNumbers/Modal/SearchItem";
import DocumentsEditBookForm from "../../EditBookForm";
import DocumentBooksTable from "../../BooksTable";
import FundMovingModal from "../submodals/FundMovingModal";
import BookSearchForm from "../../../Covers/SearchForm";
import SpinnerOuter from "../../../UtulityComponents/SpinnerOuter";
import Button from "../../../Button";

import {inventoryBookSaveRequestStart} from "../../../../redux/actions/inventory/books/save";
import {inventoryBookDeleteRequestStart} from "../../../../redux/actions/inventory/books/delete";
import {inventoryCoversCountRequestStart} from "../../../../redux/actions/inventoryCovers/count";
import {setInventoryCoversFormData} from "../../../../redux/actions/inventoryCovers/form";

const mapStateToProps = ({inventoryCovers, inventory}) => ({
    loader: inventoryCovers.loader,
    loadSuccess: inventoryCovers.loadSuccess,
    formData: inventoryCovers.form,
    searchResults: inventoryCovers.list,
    books: inventory.books,
    bookDistribute: inventory.bookDistribute
});

const mapDispatchToProps = {
    deleteBook: data => inventoryBookDeleteRequestStart(data),
    setFormData: (data) => setInventoryCoversFormData(data),
    startCoversSearch: () => inventoryCoversCountRequestStart(),
    saveBook: data => inventoryBookSaveRequestStart(data)
};

const DocumentsEdit = ({
                           loader, loadSuccess, modalData, currentBook, books, setCurrentBook,
                           saveBook, deleteBook, searchOpen, onAddBook, formData, setFormData, searchResults,
                           startCoversSearch, onSelectFromSearch, requestBooks, onCreateNewBook, formValidity,
                           documentid
                       }) => {

    const [fundMovingModalOpen, setFundMovingModalOpen] = useState(false);


    const onBookSave = (data) => {
        saveBook({...data, documentid});
        setCurrentBook(null);
    };

    const onBookDelete = (data) => {
        deleteBook(data);
        setCurrentBook(null);
    };

    return (
        <>
            {fundMovingModalOpen &&
            <FundMovingModal
                setClosed={() => setFundMovingModalOpen(false)}
                isOpen={fundMovingModalOpen}
                modalData={modalData}
                booksData={books}
                requestBooks={requestBooks}
            />
            }

            <Col md={12}>
                <>
                    <div className={styles.booksSection}>
                        <div className={styles.title}>
                            Книги
                        </div>
                        {books.length > 0 && <DocumentBooksTable
                            data={books}
                            modalData={modalData}
                            handleSelect={setCurrentBook}
                            selectedId={currentBook ? currentBook.id : null}
                        />}
                        {currentBook &&
                        <DocumentsEditBookForm
                            data={currentBook}
                            onSave={onBookSave}
                            modalData={modalData}
                            onDelete={currentBook.id ? onBookDelete : () => setCurrentBook(null)}
                            setData={setCurrentBook}
                        />
                        }
                    </div>
                </>
                <div className="mb-3">
                    <Button
                        label={!searchOpen ? "Добавить книгу" : "Отменить"}
                        onClick={onAddBook}
                        disabled={!formValidity}
                    />
                </div>

                {searchOpen && <>
                    <BookSearchForm data={formData} setData={setFormData} disableAlphabet={true}
                                    onSubmit={startCoversSearch} simplified hideInstitutions noVerifyStatus/>

                    <SpinnerOuter isLoading={loader}/>

                    {loadSuccess && <>
                        <div className={styles.title}>
                            Результаты поиска
                        </div>
                        {searchResults.length > 0 ? <>
                            <div className={styles.searchResultsContainer}>
                                {searchResults.map((item, index) => (
                                    <InvNumbersModalSearchItem data={item} key={index}
                                                               onClick={onSelectFromSearch}/>
                                ))}
                            </div>
                        </> : <>
                            <div className={classNames('text-center', styles.textNotification)}>
                                Ничего не найдено, Вы можете <span onClick={onCreateNewBook}
                                                                   className={styles.textLink}>
                                                        создать новую библиографическую запись
                                                      </span>
                            </div>
                        </>}
                    </>}
                </>}
            </Col>
        </>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsEdit);
