import { COVERS_COUNT_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const coversCountRequestStart = (data) => ({
  type: `${COVERS_COUNT_REQUEST}${START}`,
  payload: data
});

export const coversCountRequestSuccess = (data) => ({
  type: `${COVERS_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const coversCountRequestError = (data) => ({
  type: `${COVERS_COUNT_REQUEST}${ERROR}`,
  payload: data
});
