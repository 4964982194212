import { ERROR, SECURITY_LOG_LIST_REQUEST, START, SUCCESS } from "../action-types";

export const securityLogListRequestStart = () => ({
  type: `${SECURITY_LOG_LIST_REQUEST}${START}`
});

export const securityLogListRequestSuccess = (data) => ({
  type: `${SECURITY_LOG_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const securityLogListRequestError = (data) => ({
  type: `${SECURITY_LOG_LIST_REQUEST}${ERROR}`,
  payload: data
});