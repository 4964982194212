import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Col, Row} from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import styles from "../../../scenes/Schedule/ScheduleScene.module.css";
import Button from "../../Button";

const MONTHS = [
    {name: "сентябрь", value: 9},
    {name: "октябрь", value: 10},
    {name: "ноябрь", value: 11},
    {name: "декабрь", value: 12},
    {name: "январь", value: 1},
    {name: "февраль", value: 2},
    {name: "март", value: 3},
    {name: "апрель", value: 4},
    {name: "май", value: 5},
    {name: "июнь", value: 6},
    {name: "июль", value: 7},
    {name: "август", value: 8}
];

const defineYear = (yearsList) => {
    /*{
      begdate: "01-08-2012"
      enddate: "31-07-2013"
      name: "2012-2013"
      xp_key: 25
      year_grade_types: ""
    }*/
    const today = moment().format('YYYY-MM-DD');
    let currentPeriod = 0;

    yearsList.forEach(period => {
        const begDate = period.begdate.split('-').reverse().join('-');
        const endDate = period.enddate.split('-').reverse().join('-');

        if (moment(today).isBetween(begDate, endDate))
            currentPeriod = period.xp_key;
    });

    return currentPeriod;
};

const ScheduleSearchForm = ({list, selectedCurs, setSelectedCurs, dataToState, startRequest}) => {
    const month = moment().month() + 1;
    const {userData, faculty, curs, year} = list;

    const [selectedFaculty, setSelectedFaculty] = useState(userData.userDetails[0].facultyid || 1);
    const [selectedYear, setSelectedYear] = useState(defineYear(year.schoolYear));
    const [selectedMonth, setSelectedMonth] = useState(month);


    const getSchedule = () => {
        dataToState(
            `${selectedFaculty}/${selectedCurs}/${selectedYear}/${selectedMonth}`
        );
        startRequest();
    };

    return (
        <div className={'formContainer'}>
            <Row>
                <Col xs="5">
                    <ModalInputWrapper label={"Факультет"}>
                        <select
                            onChange={e => setSelectedFaculty(e.target.value)}
                            value={selectedFaculty}
                            className={styles.select}
                        >
                            {faculty.map(item => (
                                <option value={item.idfaculty} key={`faculty${item.idfaculty}`}>{item.faculty}</option>
                            ))}
                        </select>
                    </ModalInputWrapper>
                </Col>
                <Col>
                    <ModalInputWrapper label={"Учебный год"}>
                        <select
                            onChange={e => setSelectedYear(e.target.value)}
                            className={styles.select}
                            value={selectedYear}
                        >
                            {year.schoolYear && year.schoolYear.map(item => (
                                <option value={item.xp_key} key={`schoolYear${item.xp_key}`}>{item.name}</option>
                            ))}
                        </select>
                    </ModalInputWrapper>
                </Col>
                <Col>
                    <ModalInputWrapper label={"Курс(рота)"}>
                        <select
                            onChange={e => setSelectedCurs(e.target.value)}
                            className={styles.select}
                            value={selectedCurs}
                        >
                            {curs.educationYear &&
                            curs.educationYear.map(item => (
                                <option value={item.eyid} key={`ye${item.eyid}`}>{item.name}</option>
                            ))}
                        </select>
                    </ModalInputWrapper>
                </Col>
                <Col>
                    <ModalInputWrapper label={"Месяц"}>
                        <select
                            onChange={e => setSelectedMonth(e.target.value)}
                            value={selectedMonth.toString()}
                            className={styles.select}
                        >
                            {MONTHS.map(item => (
                                <option key={`month${item.value}`} value={item.value}>{item.name}</option>
                            ))}
                        </select>
                    </ModalInputWrapper>
                </Col>
                <Col>
                    <ModalInputWrapper label="">
                        <Button onClick={getSchedule} label="Обновить"/>
                    </ModalInputWrapper>
                </Col>
            </Row>
        </div>
    );
};

ScheduleSearchForm.propTypes = {
    list: PropTypes.object,
    setSelectedCurs: PropTypes.func,
};

export default ScheduleSearchForm;