import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './LibraryTypesModal.module.css';
import {ADD, EDIT} from "../../../constants/modalTypes";
import {Col, Row} from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {api} from "../../../App";
import SpinnerOuter from "../../UtulityComponents/SpinnerOuter";
import CatalogsModalWithForm from "../../Catalogs/ModalWithForm";

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Это обязательное поле')
        .max(1024, 'Недопустимая длинна названия'),
    mimetype: Yup.string()
        .notRequired()
});

const LibraryTypesModal = ({onSubmit, modalData, closeModal}) => {

    const [image, setImage] = useState('');
    const [inputFile, setInputFile] = useState(null);

    const {modalType, data} = modalData;

    if (data.mimetype === null) data.mimetype = '';

    const modalForm = useFormik({
        initialValues: data,
        validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: values => onSubmit(values)
    });

    const submitHandler = () => {
        if (inputFile) imageUploader(inputFile);
        modalForm.handleSubmit();
    };

    const imageUploader = (file) => {
        setImage(<SpinnerOuter isLoading/>);
        const formData = new FormData();
        formData.append("image", file);
        api.post(`/librarytype/${modalData.data.id}/image`, formData)
            .then(res => {
                imageUpdater(res.config.url);
            });
    }

    const imageNewTabViewHandler = (url) => {
        const win = window.open(url, '_blank');
        win.focus();
    };

    const imageLoader = () => {
        api.get(`/librarytype/${modalData.data.id}/image`)
            .then(res => {
                imageUpdater(res.config.url)
            })
            .catch(e => {
                console.log(e);
            })
    };

    const imageUpdater = (url) => setImage(<img
        className={styles.modalImage} src={url}
        onClick={() => imageNewTabViewHandler(url)} alt={""}
    />);

    useEffect(() => {
        if (inputFile) {
            const objectURL = URL.createObjectURL(inputFile);
            imageUpdater(objectURL);
        };
    }, [inputFile]);

    useEffect(() => {
        if (data.imageexist) imageLoader();
    }, [data.imageexist]);

    return (
        <CatalogsModalWithForm
            title={`Тип литературы: ${modalType === EDIT ? "редактирование" : "добавление"}`}
            closeModal={closeModal}
            submitHandler={submitHandler}

        >
            <Row>
                <Col md={'auto'}>
                    <div>
                        {image}
                    </div>
                    <div>
                        <input
                            type="file"
                            className={styles.fileInput}
                            onChange={(e) => setInputFile(e.target.files[0])}
                        />
                    </div>
                </Col>
                <Col>
                    <div style={{'minWidth': '300px'}}>
                        <ModalInputWrapper label={"Название"}
                                           name={"name"}
                                           value={modalForm.values.name}
                                           onChange={modalForm.handleChange}
                                           error={modalForm.errors.name}
                        />
                        <ModalInputWrapper label={"MIME type"}
                                           name={"mimetype"}
                                           value={modalForm.values.mimetype}
                                           onChange={modalForm.handleChange}
                                           error={modalForm.errors.mimetype}
                        />
                    </div>
                </Col>
            </Row>
        </CatalogsModalWithForm>
    );
};

LibraryTypesModal.propTypes = {
    modalData: PropTypes.object,
    setModalData: PropTypes.func,
    onSubmit: PropTypes.func,
    closeModal: PropTypes.func,
};

export default LibraryTypesModal;
