import { api } from "../../App";
import {
  START,
  SUCCESS,
  DUPLICATE_COUNT_REQUEST,
  DUPLICATE_LIST_REQUEST,
  DUPLICATE_BOOKS_REQUEST,
  DUPLICATE_BOOKS_MERGE
} from "../actions/action-types";
import { createSimpleActionsForReducer } from "../actions/actionsFactory";
import { createSimpleReducer } from "../reducers/reducersFactrory";
import {
  call,
  put,
  select,
  takeLatest
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";
import { setDuplicateSearchData } from "../../redux/actions/duplicate/search";

const mergeBooks = (params) => {
  return api.put("/bookduplicate", params);
};

const getCount = () => {
  return api.get("/bookduplicate/?count");
};

const getList = (maxResults, paginateFrom) => {
  return api.get(
    `/bookduplicate/?maxResults=${maxResults}&paginateFrom=${paginateFrom}`
  );
};

const getBooks = data => {
  return api.post(`/bookduplicate`, data);
};

export const duplicateActions = {
         ...createSimpleActionsForReducer("count", DUPLICATE_COUNT_REQUEST),
         ...createSimpleActionsForReducer("list", DUPLICATE_LIST_REQUEST),
         ...createSimpleActionsForReducer("books", DUPLICATE_BOOKS_REQUEST),
         ...createSimpleActionsForReducer("merge", DUPLICATE_BOOKS_MERGE)
       };

export const duplicateReducers = {
         count: createSimpleReducer(0, `${DUPLICATE_COUNT_REQUEST}${SUCCESS}`),
         list: createSimpleReducer([], `${DUPLICATE_LIST_REQUEST}${SUCCESS}`),
         books: createSimpleReducer([], `${DUPLICATE_BOOKS_REQUEST}${SUCCESS}`)
       };

export function* duplicateWatcher() {
  yield takeLatest(`${DUPLICATE_COUNT_REQUEST}${START}`, duplicateCount);
  yield takeLatest(`${DUPLICATE_LIST_REQUEST}${START}`, duplicateList);
  yield takeLatest(`${DUPLICATE_BOOKS_REQUEST}${START}`, duplicateBooks);
  yield takeLatest(`${DUPLICATE_BOOKS_MERGE}${START}`, mergeSaga);
}

function* duplicateCount() {
  const searchData = yield select(({ duplicate }) => duplicate.search);
  try {
    yield put(setDuplicateSearchData({ ...searchData, loader: true }));
    const response = yield call(getCount);
    const count = parseInt(response.data.count, 10);
    yield put(duplicateActions.countRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(
      setDuplicateSearchData({ ...searchData, pageCount, loader: true })
    );
    yield put(duplicateActions.listRequestStart());
  } catch (e) {
    console.log(e);
  }
}

function* duplicateList() {
  const searchData = yield select(({ duplicate }) => duplicate.search);
  const paginateFrom = searchData.maxResults * (searchData.page - 1);
  const maxResults = searchData.maxResults;
  try {
    const response = yield call(getList, maxResults, paginateFrom);
    yield put(duplicateActions.listRequestSuccess(response.data));
    yield put(setDuplicateSearchData({ ...searchData, loader: false }));
  } catch (e) {
    console.log(e);
  }
}

function* duplicateBooks({payload}) {
  try {
    const response = yield call(getBooks, payload);
    yield put(duplicateActions.booksRequestSuccess(response.data));
  } catch(e) {
    console.log(e)
  }
}

function* mergeSaga({payload}) {
  try{
    yield call(mergeBooks, payload);
    yield put(duplicateActions.countRequestStart());
  } catch(e) {
    console.log(e);
  }
}