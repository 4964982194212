import { combineReducers } from "redux";
import {countReducer} from "./count/countReducer";
import {listReducer} from "./list/listReducer";
import {searchReducer} from "./search/searchReducer";
import {formReducer} from "./form/formReducer";
import {loaderReducer} from "./loader/loaderReducer";
import {emptyReducer} from "./empty/emptyReducer";


export const schedulerMetadataReducer = combineReducers({
  count: countReducer,
  list: listReducer,
  search: searchReducer,
  form: formReducer,
  loader: loaderReducer,
  empty: emptyReducer,
});