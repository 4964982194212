import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { LIBRARY_INSTITUTIONS_REQUEST, START } from "../../../../actions/action-types";
import { institutionsList } from "../../../../../api/institutions/institutions";
import {
  libraryInstitutionsRequestError,
  libraryInstitutionsRequestSuccess
} from "../../../../actions/library/info/institutions";

export default function* libraryInstitutionsWatcher() {
  yield takeLatest(`${LIBRARY_INSTITUTIONS_REQUEST}${START}`, libraryInstitutionsWorker)
}

function* libraryInstitutionsWorker() {
  try {
    const response = yield call(institutionsList);
    yield put(libraryInstitutionsRequestSuccess(response.data));
  } catch (error) {
    yield put(libraryInstitutionsRequestError(error));
  }
}