import React, {useMemo, useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./MenuItem.module.css";
import styled from 'styled-components'
import {Link, NavLink} from "react-router-dom";
import {resolveMediaResRef} from "../../../../utils/resolveMediaResLink";

const resolveLink = (authData, link) =>
    typeof link === "function" ? link(authData) : link;

const MenuStyled = styled.div`
  a:hover {
    background-color: ${props => props.options.menu.hover};
    color: #ffffff
  }
  .menuActive {
    background-color: ${props => props.options.menu.hover};
    color: #ffffff
  }
`;

const MenuItem = ({link, title, dropdown, external, authData, layoutOptions, messageNotification, list, links}) => {
    const [open, setOpen] = useState(false);

    if (dropdown && dropdown[11] && !dropdown[12] && dropdown[11].title === "Медиапортал") {
        list.forEach(el => {
            dropdown.push({
                title: el.resourcename,
                href: resolveMediaResRef(el.id),
                isSubItem: true
            })
        });
    }

    const resolvedDropdown = useMemo(() => title === 'Электронные ресурсы' ? dropdown.map(el => {
        if (el.title === 'Профессорско-преподавательский состав') el.href = links.PasportPPSLink;
        if (el.title === 'Расписание') el.href = links.PasportScheduleLink;
        return el;
    }).filter(el => {
        if (el.title === 'Профессорско-преподавательский состав') return !!links.PasportPPSLink;
        if (el.title === 'Расписание') return !!links.PasportScheduleLink;
        return true;
    }) : dropdown, [dropdown]);

    return (
        <MenuStyled
            className={styles.container}
            options={layoutOptions}
            onPointerEnter={() => setOpen(true)}
            onPointerLeave={() => setOpen(false)}
        >
            {external ? (
                <a
                    href={resolveLink(authData, link) || ''}
                    className={classNames(styles.menuItem, {['menuActive']: open})}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {title}
                </a>
            ) : (
                <NavLink
                    to={resolveLink(authData, link) || ''}
                    exact
                    activeClassName={'menuActive'}
                    className={classNames(styles.menuItem, {['menuActive']: open})}
                >
                    {title}
                </NavLink>
            )}
            {resolvedDropdown && (
                <div
                    className={classNames(styles.dropdown, {
                        [styles.dropdownOpen]: open
                    })}
                >
                    {resolvedDropdown.map((item, index) =>
                        item.external || item.href ? (
                            <a
                                href={item.href ? item.href :resolveLink(authData, item.link)}
                                key={index}
                                target="_blank"
                                {...item.download ? {download: true} : {}}
                                rel="noopener noreferrer"
                            >
                                <div
                                    className={item.isSubItem ? styles.dropdownSubItem : styles.dropdownItem}
                                >
                                    {item.title}
                                </div>
                            </a>
                        ) : ( !(item.title === 'Профессорско-преподавательский состав' && links.PasportPPSLink) ||
                            !(item.title === 'Расписание' && links.PasportScheduleLink) ?
                            <Link to={resolveLink(authData, item.link) || ''} key={index}>
                                <div className={item.isSubItem ? styles.dropdownSubItem : styles.dropdownItem}>
                                    {item.title}
                                    {item.link === "/message" && messageNotification > 0 &&
                                    <span className={classNames("bg_red", styles.notificationContainer)}>{messageNotification}</span>
                                    }
                                </div>
                            </Link> : null
                        )
                    )}
                </div>
            )}
        </MenuStyled>
    );
};

MenuItem.propTypes = {
    label: PropTypes.string,
    link: PropTypes.any,
    dropdown: PropTypes.array,
    external: PropTypes.bool,
    messageNotification: PropTypes.number
};

export default MenuItem;
