import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from "./BookEditCodeTable.module.css";
import ModalInputWrapper from "../../../../Modal/InputWrapper";
import EditCodesModal from "../../../Modals/EditCodes";
import BookEditCodeItem from './CodeItem';
import Button from "../../../../Button";
import CodesSearchModal from "../CodesSearchModal";
import {Col, Row} from "react-bootstrap";

const BookEditCodeTable = ({ data, setData, type, directoryList, requestDirectoryList, collapseDirectoryList }) => {
  const CODE_NAME =
    type === 'udk' ? 'УДК'
    : type === 'bbk' ? 'ББК'
    : 'Неизвестный тип кода';


  useEffect(() => {
    requestDirectoryList(0);
  }, [requestDirectoryList]);

  const [codeEditor, setCodeEditor]   = useState('');
  const [isModalOpen, setModalOpen] = useState(true);
  const [modalData, setModalData]   = useState(null);
  const [searchModalOpen, setSearchModalOpen]   = useState(false);

  const onEditModalOpen = (data) => {
    setModalData(data);
    setModalOpen(true);
  };

  const onChangeCode = () => {
    editCode(modalData.index, modalData.value);
  };

  const onAddCode = () => {
    if(codeEditor) {
      let newCodeList = [...data[type]];
      newCodeList.push(codeEditor);
      setData({...data, [type]: newCodeList});
      setCodeEditor('');
    }
  };

  const editCode = (index, value) => {
    let newCodesList = [...data[type]];
    newCodesList[index] = value;
    setData({...data, [type]: newCodesList});
  };

  return (
    <>
      <CodesSearchModal
        type={type}
        isOpen={searchModalOpen}
        setOpen={setSearchModalOpen}
        data={directoryList}
        collapseList={collapseDirectoryList}
        fetchSubList={requestDirectoryList}
        setCodeEditor={setCodeEditor}
      />
      {modalData &&
        <EditCodesModal
          isOpen={isModalOpen}
          modalData={modalData}
          setModalData={setModalData}
          onRequestClose={() => setModalOpen(false)}
          onSubmit={onChangeCode}
        />
      }
      <div className={styles.container}>
        <Row>
          <Col>
            <div className={styles.addItemPanel}>
              <ModalInputWrapper
                label={CODE_NAME}
                value={codeEditor}
                onChange={({target: { value }}) => {
                  setCodeEditor(value);
                }}
                additionalButtonOnClick={onAddCode}
                additionalButtonText="Добавить"
              />
            </div>
          </Col>
          <Col md={"auto"}>
            <ModalInputWrapper label="">
              <Button
                label={'Поиск кода в справочнике'}
                colorType={'green'}
                onClick={() => setSearchModalOpen(true)}
              />
            </ModalInputWrapper>
          </Col>
        </Row>

        {data[type].map((item, index) => (
          <BookEditCodeItem
            key={index}
            item={item}
            index={index}
            type={type}
            data={data}
            setData={setData}
            openModal={onEditModalOpen}
          />
        ))}
      </div>
    </>
  );
};

BookEditCodeTable.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  directoryList: PropTypes.array,
  requestDirectoryList: PropTypes.func,
  collapseDirectoryList: PropTypes.func
};

BookEditCodeTable.defaultProps = {
  directoryList: [],
  data: {}
};

export default BookEditCodeTable;