import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './LibraryMaterialsSearchForm.module.css';
import ModalInputWrapper from "../../Modal/InputWrapper";
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import SearchableSelect from "../../Select";
import {categoriesToOptions} from "../../../utils/toSelectOption";
import {categoriesListRequestStart} from "../../../redux/actions/categories/list";

const mapStateToProps = ({categories}) => ({
    categoriesList: categories.list
});

const mapDispatchToProps = {
    requestCategories: categoriesListRequestStart
};

const LibraryMaterialsSearchForm = ({inputRef, isModal, data, setData, children, noVerifyStatus, requestCategories, categoriesList}) => {

    const onChange = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    };


    useEffect(() => {
        requestCategories();
    }, [requestCategories]);

    if (noVerifyStatus) {
        data.verifyStatus = 2;
    }

    return (
        <div className={styles.container}>
            <Row>
                <Col md={12}>
                    <ModalInputWrapper label="Категория">
                        <SearchableSelect
                            placeholder="Поиск"
                            options={categoriesToOptions(categoriesList)}
                            onChange={value =>
                                setData({...data, categoryid: (value || {}).value})
                            }
                        />
                    </ModalInputWrapper>
                </Col>
                <Col md={12}>
                    <ModalInputWrapper onChange={onChange}
                                       name="title"
                                       value={data.title}
                                       label="Название"
                                       inputRef={inputRef}
                                       isModal={isModal}/>
                </Col>
                <Col md={6}>
                    <ModalInputWrapper onChange={onChange}
                                       name="author"
                                       value={data.author}
                                       label="Автор"/>
                </Col>

                <Col md={6}>
                    <ModalInputWrapper onChange={onChange}
                                       name="year"
                                       value={data.year}
                                       label="Год"/>
                </Col>

                <Col md={12}>
                    {children}
                </Col>
            </Row>
        </div>
    );
};

LibraryMaterialsSearchForm.propTypes = {
    data: PropTypes.object,
    setData: PropTypes.func,
    hideInstitutions: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryMaterialsSearchForm);
