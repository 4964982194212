import { combineReducers } from "redux";
import { RESULTS_PER_PAGE } from "../../constants/pagination";
import { api } from "../../App";
import {
  START,
  SUCCESS,
  ERROR,
  SET_BOOK_SCAN_PLAN_SEARCH_DATA,
  SET_BOOK_SCAN_PLAN_FORM_DATA,
  BOOK_SCAN_PLAN_COUNT_REQUEST,
  BOOK_SCAN_PLAN_LIST_REQUEST,
  BOOK_SCAN_PLAN_EDIT_REQUEST
} from "../actions/action-types";
import { createSimpleActionsForReducer } from "../actions/actionsFactory";
import {createSimpleReducer} from "../reducers/reducersFactrory";
import {
  call,
  put,
  select,
  takeLatest
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";
import moment from "moment";


/// requests

const getBookScanPlanCount = ({ from, to, status }) => {
  const params = {
    count: true,
    status,
    from,
    to
  };
  return api.get("/bookscanorder", {params})
};

export const editBookScanPlan = ({ orderId, status, reason = null }) => {
  const params = {
    status,
    reason
  };

  return api.put(`/bookscanorder/${orderId}`, params)
};

const getBookScanPlanList = ({maxResults, paginateFrom, from, to, status}) => {
  const params = {
    status,
    maxResults,
    paginateFrom,
    from,
    to
  };

  return api.get(`/bookscanorder/`, {params});
};

/// actions

export const bookScanOrderEditRequestStart = ({ orderId, status, reason }) => ({
  type: `${BOOK_SCAN_PLAN_EDIT_REQUEST}${START}`,
  payload: { orderId, status, reason }
});

export const bookScanOrderEditRequestSuccess = (response) => ({
  type: `${BOOK_SCAN_PLAN_EDIT_REQUEST}${SUCCESS}`,
  payload: response
});

export const bookScanOrderEditRequestError = (error) => ({
  type: `${BOOK_SCAN_PLAN_EDIT_REQUEST}${ERROR}`,
  payload: error
});


export const bookScanPlanActions = {
  ...createSimpleActionsForReducer("count", BOOK_SCAN_PLAN_COUNT_REQUEST),
  ...createSimpleActionsForReducer("list", BOOK_SCAN_PLAN_LIST_REQUEST)
};

export const setBookScanPlanSearchData = data => ({
  type: SET_BOOK_SCAN_PLAN_SEARCH_DATA,
  payload: data
});

export const setBookScanPlanFormData = data => ({
  type: SET_BOOK_SCAN_PLAN_FORM_DATA,
  payload: data
});

/// reducers

const initialState = {
  page: 1,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0
};

const formInitialState = {
  from: moment().startOf('year').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
  status: ""
};

const bookScanPlanSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOK_SCAN_PLAN_SEARCH_DATA:
      return action.payload;
    default:
      return state;
  }
};

const bookScanPlanFormReducer = (state = formInitialState, action) => {
  switch (action.type) {
    case SET_BOOK_SCAN_PLAN_FORM_DATA:
      return action.payload;
    default:
      return state;
  }
};

export const bookScanPlanReducer = combineReducers({
  search: bookScanPlanSearchReducer,
  form: bookScanPlanFormReducer,
  count: createSimpleReducer(0, `${BOOK_SCAN_PLAN_COUNT_REQUEST}${SUCCESS}`),
  list: createSimpleReducer([], `${BOOK_SCAN_PLAN_LIST_REQUEST}${SUCCESS}`)
});

/// sagas

export function* bookScanPlanWatcher() {
  yield takeLatest(
    `${BOOK_SCAN_PLAN_COUNT_REQUEST}${START}`,
    bookScanPlanCount
  );
  yield takeLatest(`${BOOK_SCAN_PLAN_LIST_REQUEST}${START}`, bookScanPlanList);
  yield takeLatest(`${BOOK_SCAN_PLAN_EDIT_REQUEST}${START}`, bookScanPlanEditWorker);
}

function* bookScanPlanCount() {
  const searchData = yield select(({ bookScanPlan }) => bookScanPlan.search);
  const formData = yield select(({ bookScanPlan }) => bookScanPlan.form);
  try {
    const response = yield call(getBookScanPlanCount, {...formData, ...searchData});
    const count = parseInt(response.data.count, 10);
    yield put(bookScanPlanActions.countRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setBookScanPlanSearchData({ ...searchData, pageCount }));
    yield put(bookScanPlanActions.listRequestStart());
  } catch (e) {
    console.log(e);
  }
}

function* bookScanPlanEditWorker({ payload: { orderId, status, reason = null } }) {
  try {
    const response = yield call(editBookScanPlan, { orderId, status, reason });
    yield put(bookScanOrderEditRequestSuccess(response));
    yield put(bookScanPlanActions.countRequestStart());
  } catch(error) {
    yield put(bookScanOrderEditRequestError(error));
  }
}

function* bookScanPlanList() {
  const searchData = yield select(({ bookScanPlan }) => bookScanPlan.search);
  const formData = yield select(({ bookScanPlan }) => bookScanPlan.form);

  const maxResults = searchData.maxResults;
  const paginateFrom = (searchData.page - 1) * maxResults;
  try {
    const response = yield call(getBookScanPlanList, {...formData, ...searchData, paginateFrom});
    yield put(bookScanPlanActions.listRequestSuccess(response.data));
  } catch (e) {
    console.log(e);
  }
}
