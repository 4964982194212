import React from 'react';
import PropTypes from 'prop-types';
import DataTable from "../../../DataTable";
import DataTableCol from "../../../DataTable/Column";
import { ACCESS_CATEGORIES_SORT } from '../../../../constants/sort/accessCategoriesSort';


const SeriesPermissionsDataTable = ({ data, sortBy, setSort, onChange }) => {
  return (
    <div className={'mt-2'}>
      <DataTable data={data} sortBy={sortBy} onHeadClick={setSort}>
        <DataTableCol sortParams={[ACCESS_CATEGORIES_SORT.NAME]} title="Название категории" value="series" colSpan={6}/>
        <DataTableCol sortParams={[ACCESS_CATEGORIES_SORT.GROUP]} title="Группа" value="groupname" colSpan={4}/>
        <DataTableCol sortParams={[ACCESS_CATEGORIES_SORT.ACCESS]} title="Значение" resolver={(item) =>
          <select value={item.access} onChange={(e) => onChange(e, item)}>
            <option value="Нет">Нет</option>
            <option value="Чтение">Чтение</option>
            <option value="Полный">Полный</option>
          </select>
        } colSpan={1}/>
      </DataTable>
    </div>
  );
};

SeriesPermissionsDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  sortBy: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SeriesPermissionsDataTable;