import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";

import styles from './BookSupplyTitle.module.css';

const BookSupplyTitle = ({onSelectChange, yearsList}) => {
    return (
        <div className={styles.head}>
            <p className={styles.title}>
                Книгообеспеченность на
            </p>

            <select
                onChange={onSelectChange}
                className={classNames('mr-2', 'ml-2')}
            >
                <option selected disabled>
                    Выберите учебный год
                </option>
                {yearsList.year && yearsList.year.schoolYear &&
                yearsList.year.schoolYear.map(item => (
                    <option value={item.xp_key} key={item.xp_key}>{item.name}</option>
                ))}
            </select>

            {/*<h3 className="bookSupply_content_head_title">50,6%</h3>*/}
        </div>
    );
};

BookSupplyTitle.propTypes = {
    onSelectChange: PropTypes.func.isRequired,
    yearsList: PropTypes.object,
};

export default BookSupplyTitle;