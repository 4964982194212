import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
  INSTITUTIONS_ALL_LIST_REQUEST,
  INSTITUTIONS_COUNT_REQUEST,
  START,
  SUCCESS
} from "../../../actions/action-types";
import { institutionsCount, institutionsList } from "../../../../api/institutions/institutions";
import { institutionsCountRequestError, institutionsCountRequestSuccess } from "../../../actions/institutions/count";
import { setInstitutionsSearchData } from "../../../actions/institutions/search";
import {
  institutionsListRequestError,
  institutionsListRequestStart,
  institutionsListRequestSuccess
} from "../../../actions/institutions/list";
import { institutionsAllListRequestError, institutionsAllListRequestSuccess } from "../../../actions/institutions/all";
import { institutionsToOptions } from "../../../../utils/toSelectOption";

export default function* loadInstitutionsWatcher() {
  yield takeLatest(`${INSTITUTIONS_COUNT_REQUEST}${START}`, institutionsCountWorker);
  yield takeLatest(`${INSTITUTIONS_COUNT_REQUEST}${SUCCESS}`, institutionsListWorker);
  yield takeLatest(`${INSTITUTIONS_ALL_LIST_REQUEST}${START}`, institutionsAllWorker);
}

function* institutionsCountWorker() {
  const searchData = yield select(({ institutions }) => institutions.search);
  try {
    const response = yield call(institutionsCount, searchData.query);
    const count = parseInt(response.data.count, 10);
    yield put(institutionsCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setInstitutionsSearchData({ ...searchData, pageCount }));
  } catch (error) {
    yield put(institutionsCountRequestError(error));
  }
}

function* institutionsListWorker() {
  yield put(institutionsListRequestStart());
  const searchData = yield select(({ institutions }) => institutions.search);
  const paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
  try {
    const response = yield call(institutionsList, searchData.sortBy, searchData.maxResults, paginateFrom, searchData.query);
    yield put(institutionsListRequestSuccess(response.data));
  } catch (error) {
    yield put(institutionsListRequestError(error));
  }
}

function* institutionsAllWorker() {
  try {
    const response = yield call(institutionsList);
    yield put(institutionsAllListRequestSuccess(institutionsToOptions(response.data)));
  } catch (error) {
    yield put(institutionsAllListRequestError(error));
  }
}
