import React from 'react';
import PropTypes from 'prop-types';
import styles from "../../../Covers/List/Item/CoversListItem.module.css";
import classNames from "classnames";
import moment from "moment";
import {Link} from "react-router-dom";
import {BASE_URL} from "../../../../constants/api";

const ReadingListItemFiles = ({fileList, bookId}) => {

    const checkFileReadable = (file) => {
        let fileFormat = file.filename.split('.').reverse()[0];

        switch (fileFormat) {
            case 'pdf':
                return (
                    <Link
                        to={`/pdfViewer/${bookId}/${file.id}`}
                        target="_blank"
                        className={styles.button}
                    >
                        Открыть
                    </Link>
                );
            case 'epub':
                return (
                    <Link
                        to={`/epub_reader/${bookId}/${file.id}`}
                        target="_blank"
                        className={styles.button}
                    >
                        Открыть
                    </Link>
                );
            case 'djvu':
                return (
                    <Link
                        to={`/djvu_reader/${bookId}/${file.id}`}
                        target="_blank"
                        className={styles.button}
                    >
                        Открыть
                    </Link>
                );
            default:
                return (
                    <a href={`${BASE_URL}/book/${bookId}/file/${file.id}`}
                       target="_blank" rel="noopener noreferrer" download={`${file.filename}`}>Скачать</a>
                )
        }
    };

    return (
        <div className={styles.filesOpen}>
            <div className={classNames(styles.filesOpenItem, 'd-flex align-items-center')}>
                <div><b>Название</b></div>
                <div><b>Дата загрузки</b></div>
            </div>
            {fileList.map((item, index) => <div key={`fileListItem${index}`}
                                                className={classNames(styles.filesOpenItem, 'd-flex align-items-center')}>
                <div>{item.filename}</div>
                <div>{moment(item.filedate).format('DD.MM.YYYY')}</div>
                <div>
                    {checkFileReadable(item)}
                </div>
            </div>)}
        </div>
    );
};

ReadingListItemFiles.propTypes = {
    fileList: PropTypes.array,
    bookId: PropTypes.string
};

export default ReadingListItemFiles;