import { api } from "../../App";
import { SORT_DIRECTION } from "../../constants/sortDirections";

export const getDocumentList = ({ sortBy, maxResults, paginateFrom, sortDirection, query, documenttype, from, to }) => {
  const params = {
    maxResults,
    paginateFrom,
    documenttype,
    [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy,
    from,
    to,
    search: query,
  };
  return api.get('/document', { params });
};

export const getDocumentCount = ({ query: search, ...other }) => {
  const params = {
    ...other,
    count: true,
    search
  };
  return api.get('/document', { params });
};

export const addDocument = (data) => {
  return api.post('/document', data);
};

export const getDocumentData = (id) => {
  return api.get(`/document/${id}`);
};

export const editDocument = (data) => {

  return api.put(`/document/${data.id}`, data);
};

export const deleteDocument = (id) => {
  return api.delete(`/document/${id}`);
};

export const getDocumentNumber = () => {
  return api.get(`/documentnumber`);
};
