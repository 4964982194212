import { COVER_UPLOAD_REQUEST, SCAN_UPLOAD_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const coverUploadRequestStart = (bookId, image) => ({
  type: `${COVER_UPLOAD_REQUEST}${START}`,
  payload: {
    bookId, image
  }
});

export const coverUploadRequestSuccess = (data) => ({
  type: `${COVER_UPLOAD_REQUEST}${SUCCESS}`,
  payload: data
});

export const coverUploadRequestError = (data) => ({
  type: `${COVER_UPLOAD_REQUEST}${ERROR}`,
  payload: data
});

export const scanUploadRequestStart = (bookId, image) => ({
  type: `${SCAN_UPLOAD_REQUEST}${START}`,
  payload: {
    bookId, image
  }
});

export const scanUploadRequestSuccess = (data) => ({
  type: `${SCAN_UPLOAD_REQUEST}${SUCCESS}`,
  payload: data
});

export const scanUploadRequestError = (data) => ({
  type: `${SCAN_UPLOAD_REQUEST}${ERROR}`,
  payload: data
});
