import React from 'react';
import PropTypes from 'prop-types';
import styles from "../../scenes/SearchByImage/SearchByImage.module.css";
import ChooseImage from "../ChooseFile";
import Button from "../Button";
import Spinner from "react-bootstrap/Spinner";

const SearchByImageForm = ({file, imageName, loading, noResults, onChangeImage}) => {
    return (
        <>
            <div className={styles.loadButtonContainer}>

                <div className={styles.uploadedContainer}>
                    {file && <img src={URL.createObjectURL(file)} alt=""/>}
                    {!!imageName && <div className={styles.picName}>Исходная картинка: <b>{imageName}</b></div>}
                </div>

                <ChooseImage
                    onChange={file => {
                        onChangeImage(file);
                    }}
                    className={styles.chooseImage}
                >
                    <Button style={styles.loadButton} label="Загрузить картинку"/>
                </ChooseImage>
            </div>

            {file &&
            <Button colorType={'red'} label={'Удалить картинку'} onClick={() => onChangeImage(null)}/>
            }

            {(loading || noResults) && (
                <div className={styles.spinnerContainer}>
                    {!!loading && (
                        <>
                            <Spinner animation="grow" variant="primary"/>
                            Загрузка...
                        </>
                    )}
                    {!!noResults && "Нет результатов"}
                </div>
            )}
        </>
    );
};

SearchByImageForm.propTypes = {
    onChangeImage: PropTypes.func,
    loading: PropTypes.bool,
    noResults: PropTypes.bool,
};

export default SearchByImageForm;