import React from 'react';
import PropTypes from 'prop-types';
import PdfPrintPanel from "../../../PDFPrint/Panel";
import addFont from "../../../../fonts/fonts";
import jsPDF from "jspdf";
import {AgregationTableType} from "../../../../scenes/Reports/Aggregation/utils";


const AggregationPDFPrint = ({ title, data }) => {

    const orientation = 'landscape';
    const leftIndent = 15;

    addFont(jsPDF.API);
    const doc = new jsPDF({putOnlyUsedFonts: true, orientation: orientation});
    doc.setFont("Montserrat");

    doc.text(title, leftIndent, 15);

    data.forEach(item => {
        doc.autoTable({
            head: [['название', 'значение']],
            body: AgregationTableType.map(el => [el.label, item[el.value]]),
            styles: {font: "Montserrat", fontSize: 10},
            showHead: 'never',
            margin: {
                top: 25
            },
            headStyles: {
                halign: "center",
                valign: "middle",
                lineWidth: 0.25,
                lineColor: 200
            },
            bodyStyles: {
                lineWidth: 0.25,
                lineColor: 200
            },
        });
    });

    const toPdf = () => {
        doc.save(`${title}.pdf`);
    };

    const toPrint = () => {
        doc.autoPrint();
        doc.output('dataurlnewwindow');
    };

    return (
        <div className={'d-flex justify-content-end'}>
            <PdfPrintPanel loadPdf={toPdf} printPdf={toPrint}/>
        </div>
    );
};

AggregationPDFPrint.propTypes = {
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
};

export default AggregationPDFPrint;