import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import ModalInputWrapper from "../../../Modal/InputWrapper";
import PropTypes from "prop-types";
import Button from "../../../Button";
import {EDIT} from "../../../../constants/modalTypes";

const creatingValidationSchema = Yup.object().shape({
    lastname: Yup.string()
        .max(255, 'Максимальная длинна поля 255 символов')
        .required('Необходимо заполнить поле'),
    firstname: Yup.string()
        .max(255, 'Максимальная длинна поля 255 символов')
        .required('Необходимо заполнить поле'),
    patronymic: Yup.string()
        .max(255, 'Максимальная длинна поля 255 символов')
        .required('Необходимо заполнить поле'),
    login: Yup.string()
        .max(255, 'Максимальная длинна поля 255 символов')
        .required('Необходимо заполнить поле'),
    password: Yup.string()
      .max(255, 'Максимальная длинна поля 255 символов')
      .required('Необходимо заполнить поле'),
});

const editingValidationSchema = Yup.object().shape({
    lastname: Yup.string()
        .max(255, 'Максимальная длинна поля 255 символов')
        .required('Необходимо заполнить поле'),
    firstname: Yup.string()
        .max(255, 'Максимальная длинна поля 255 символов')
        .required('Необходимо заполнить поле'),
    patronymic: Yup.string()
        .max(255, 'Максимальная длинна поля 255 символов')
        .required('Необходимо заполнить поле'),
});

const AdminUsersModalForm = ({initialValues, onSubmit, onlyInfo, modalType}) => {
    const formik = useFormik({
        initialValues,
        validateOnChange: false,
        validationSchema: modalType === EDIT ? editingValidationSchema : creatingValidationSchema,
        onSubmit: values => onSubmit(values)
    });

    return (
        <div>
            <ModalInputWrapper
                label={"Фамилия*"}
                value={formik.values.lastname}
                name={'lastname'}
                onChange={formik.handleChange}
                error={formik.errors.lastname}
            />
            <ModalInputWrapper
                label={"Имя*"}
                value={formik.values.firstname}
                name={'firstname'}
                onChange={formik.handleChange}
                error={formik.errors.firstname}
            />
            <ModalInputWrapper
                label={"Отчетство*"}
                value={formik.values.patronymic}
                name={'patronymic'}
                onChange={formik.handleChange}
                error={formik.errors.patronymic}
            />

            {!onlyInfo && (<>
                <ModalInputWrapper
                    label={"Логин*"}
                    value={formik.values.login}
                    name={'login'}
                    onChange={formik.handleChange}
                    error={formik.errors.login}
                />
                <ModalInputWrapper
                    label={"Пароль*"}
                    value={formik.values.password}
                    name={'password'}
                    onChange={formik.handleChange}
                    error={formik.errors.password}
                />
            </>)}

            <Button label={'Сохранить'} type={'button'} onClick={formik.handleSubmit}/>
        </div>
    )
};

AdminUsersModalForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default AdminUsersModalForm;