import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import styles from "./BookLoanSpecialityItem.module.css";
import { Col, Row } from "react-bootstrap";
import ImagePlaceholder from "../../../ImagePlaceholder";

const BookLoanSpecialityItem = ({ data }) => {
  return (
    <div className={styles.container}>
      <Row>
        <Col md={2}>
          <ImagePlaceholder/>
        </Col>
        <Col md={10}>
          <Row>
            <Col md={3}>
              <div className={classNames(styles.label, styles.state)}>
                {data.state}
              </div>
            </Col>
            <Col md={9}>
              <div className={classNames(styles.value, styles.title)}>
                {data.title}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 9, offset: 3 }}>
              <div className={classNames(styles.value)}>
                {data.authors}
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <div className={classNames(styles.label, styles.state)}>
                Год:
              </div>
            </Col>
            <Col md={9}>
              <div className={classNames(styles.value, styles.title)}>
                {data.year}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className={classNames(styles.label, styles.state)}>
                ISBN:
              </div>
            </Col>
            <Col md={9}>
              <div className={classNames(styles.value, styles.title)}>
                {data.isbn}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className={classNames(styles.label, styles.state)}>
                Размещение:
              </div>
            </Col>
            <Col md={9}>
              <div className={classNames(styles.value, styles.title)}>
                {data.library}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className={classNames(styles.label, styles.state)}>
                Фонд:
              </div>
            </Col>
            <Col md={9}>
              <div className={classNames(styles.value, styles.title)}>
                {data.fund}
              </div>
            </Col>
          </Row>
          {data.state === "На руках" &&
          <>
            <Row>
              <Col md={3}>
                <div className={classNames(styles.label, styles.state)}>
                  Дата выдачи:
                </div>
              </Col>
              <Col md={9}>
                <div className={classNames(styles.value, styles.title)}>
                  {data.issueDate}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className={classNames(styles.label, styles.state)}>
                  Дата возврата:
                </div>
              </Col>
              <Col md={9}>
                <div className={classNames(styles.value, styles.title)}>
                  {data.returnDate}
                </div>
              </Col>
            </Row>
          </>}
        </Col>
      </Row>
    </div>
  );
};

BookLoanSpecialityItem.propTypes = {
  data: PropTypes.object
};

export default BookLoanSpecialityItem;
