import React from 'react';
import PropTypes from 'prop-types';
import styles from './AdminRolesTable.module.css';
import { ADD, EDIT } from "../../../constants/modalTypes";
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";

const AdminRolesTable = ({data, setModal, selectedId, handleSelect, onDelete}) => {
  const openEditModal = (item) => setModal({ modalType: EDIT, data: item });

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <div className={styles.button} onClick={() => setModal({ modalType: ADD, data: null })}>
          добавить
        </div>
        <div className={styles.button} onClick={onDelete}>
          удалить
        </div>
      </div>
      <DataTable data={data} onCellClick={openEditModal}>
        <DataTableCol title="Роль" value="name" colSpan={6} className={styles.leftAlign}/>
        <DataTableCol title="Количество пользователей" resolver={() => "n/a"}/>
        <DataTableCol title="Выбрано" passClicks resolver={(item) =>
          <input type="checkbox" checked={item.pmid === selectedId}
                 onChange={() => handleSelect(item.pmid)}/>}
        />
      </DataTable>
    </div>
  );
};

AdminRolesTable.propTypes = {
  data: PropTypes.array,
  setModal: PropTypes.func,
  selectedId: PropTypes.any,
  handleSelect: PropTypes.func,
  onDelete: PropTypes.func
};

export default AdminRolesTable;
