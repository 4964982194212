import { SET_IMPORT_BOOKS_FORM_DATA } from "../../../actions/action-types";

const initialState = {
  format: "rusmarc",
  file: null
};

export const importBooksFormReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_IMPORT_BOOKS_FORM_DATA:
      return action.payload;

    default: return state;
  }
};