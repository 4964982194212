import { INVENTARY_BOOK_LOAD_REQUEST, SUCCESS, ERROR, START } from "../../../actions/action-types";

const initialState = {
  isLoading: false,
  list: []
};

export const inventaryBookListReducer = (state = initialState, action) => {

  switch (action.type) {
    case `${INVENTARY_BOOK_LOAD_REQUEST}${START}`:
      return {
        ...state,
        isLoading: true,
      };
    case `${INVENTARY_BOOK_LOAD_REQUEST}${SUCCESS}`:
      return {
        isLoading: false,
        list: action.payload
      };
    case `${INVENTARY_BOOK_LOAD_REQUEST}${ERROR}`:
      return {
        ...state,
        isLoading: false,
      };
    default: return state;
  }

};
