import React from 'react';
import PropTypes from 'prop-types';
import DataTableCol from "../../DataTable/Column";
import DataTable from "../../DataTable";
import styles from './DocumentBooksTable.module.css';

const DocumentBooksTable = ({ data, selectedId, handleSelect, modalData }) => {

  return (
    <div className={styles.container}>
      {modalData.data.type === "Изьятие книг из оборота" ? (
          <DataTable data={data}>
            <DataTableCol title="Название" value="title" rowWidth={'90%'}/>
            <DataTableCol title="Выбрано" passClicks resolver={(item) =>
                <input type="checkbox" checked={item.id === selectedId}
                       onChange={() => handleSelect(item)}/>}
            />
          </DataTable>
      ) : (
          <DataTable data={data}>
            <DataTableCol title="Название" value="title" rowWidth={'50%'}/>
            <DataTableCol title="Кол-во" value="amount"/>
            <DataTableCol title="Цена" value="price"/>
            <DataTableCol title="Сумма" value="sum"/>
            <DataTableCol title="Выбрано" passClicks resolver={(item) =>
                <input type="checkbox" checked={item.id === selectedId}
                       onChange={() => handleSelect(item)}/>}
            />
          </DataTable>
      )}
    </div>
  );
};

DocumentBooksTable.propTypes = {
  data: PropTypes.array
};

export default DocumentBooksTable;
