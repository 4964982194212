import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { PUBLISHERS_DELETE_REQUEST, START } from "../../../actions/action-types";
import { publishersDeleteRequestError, publishersDeleteRequestSuccess } from "../../../actions/publishers/delete";
import { publishersDelete } from "../../../../api/publishers/publishers";
import { publishersCountRequestStart } from "../../../actions/publishers/count";

export default function* publishersDeleteWatcher() {
  yield takeLatest(`${PUBLISHERS_DELETE_REQUEST}${START}`, publishersDeleteWorker)
}

function* publishersDeleteWorker({payload: id}) {
  try {
    const response = yield call(publishersDelete, id);
    yield put(publishersDeleteRequestSuccess(response.data));
    yield put(publishersCountRequestStart());
  } catch (error) {
    yield put(publishersDeleteRequestError(...((error.response && error.response.data.result) ? [error.response.data.result, true] : [error, false])));
  }
}