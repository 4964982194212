import React from "react";
import Item from "../Item";

const Items = ({data}) => {

    return (
        <div>
            {data.map(item => (
                <Item data={item} key={item.bookid}/>
            ))}
        </div>
    );
};

export default Items;
