import { SET_SECURITY_LOG_SEARCH_DATA } from "../../../actions/action-types";
import { SORT_DIRECTION } from "../../../../constants/sortDirections";
import { RESULTS_PER_PAGE } from "../../../../constants/pagination";
import { SECURITY_ACCESS_SORT } from "../../../../constants/sort/securityAccessSort";

const initialState = {
  page: 1,
  sortBy: SECURITY_ACCESS_SORT.ISSUE_TIME,
  sortDirection: SORT_DIRECTION.DESC,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0
};

export const turnoutStatsSearchReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_SECURITY_LOG_SEARCH_DATA:
      return action.payload;

    default:
      return state;
  }
};
