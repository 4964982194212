import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { ROLE_DELETE_REQUEST, START } from "../../../actions/action-types";
import { roleDeleteRequestError, roleDeleteRequestSuccess } from "../../../actions/roles/delete";
import { deleteRole } from "../../../../api/roles/roles";
import { rolesListRequestStart } from "../../../actions/roles/list";

export default function* roleDeleteWatcher() {
  yield takeLatest(`${ROLE_DELETE_REQUEST}${START}`, roleDeleteWorker)
}

function* roleDeleteWorker({payload: id}) {
  try {
    const response = yield call(deleteRole, id);
    yield put(roleDeleteRequestSuccess(response.data));
    yield put(rolesListRequestStart());
  } catch(error) {
    yield put(roleDeleteRequestError(error));
  }
}