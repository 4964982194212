import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchTeachersList } from "../../redux/actions/teachers/list";
import UserSearchCard from "../../components/UserSearchCard";
import { BASE_URL } from "../../constants/api";
import { Col, Row } from "react-bootstrap";

const mapStateToProps = ({ teachers }) => ({
  data: teachers
});

const mapDispatchToProps = dispatch => ({
  fetchList: () => dispatch(fetchTeachersList())
});

const resolvePhoto = item => `${BASE_URL}/hlms/api/users/${item.id}/photo`;
const getName = item => `${item.lastname} ${item.firstname} ${item.patronymic}`;

const Teachers = ({ data, fetchList }) => {
  useEffect(() => {
    fetchList();
  }, []);
  if (!data || !data.length) return null;
  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }}>
        {data.map((element, index) => {
          return (
            <UserSearchCard
              key={index}
              photo={resolvePhoto(element)}
              photoExists={!!element.photosize}
              name={getName(element)}
            />
          );
        })}
      </Col>
    </Row>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Teachers);
