import {all} from "redux-saga/effects";
import authSaga from "./user/authSaga";
import {bookPlacesSagaWatcher} from "../factory/bookPlacesReduxData";
import publishersSaga from "./publishers/publishersSaga";
import udkSaga from "./udk/udkSaga";
import authorsSaga from "./authors/authorsSaga";
import librarySaga from "./library/librarySaga";
import institutionsSaga from "./institutions/institutionsSaga";
import readerSearchSaga from "./readerSearch/readerSearchSaga";
import regionsSaga from "./regions/regionsSaga";
import bbkSaga from "./bbk/bbkSaga";
import rubricatorSaga from "./rubricator/rubricatorSaga";
import coversSaga from "./covers/coversSaga";
import invNumbersSaga from "./invNumbers/invNumbersSaga";
import bookDataSaga from "./bookData/bookDataSaga";
import checkAuthSaga from "./user/checkAuthSaga";
import rolesSaga from "./roles/rolesSaga";
import optionsSaga from "./options/optionsSaga";
import adminUsersSaga from "./adminUsers/adminUsersSaga";
import locationSaga from "./location/locationSaga";
import collectionsSaga from "./collections/collectionsSaga";
import permissionsSaga from "./permissions/permissionsSaga";
import externalResourcesSaga from "./externalResources/externalResourcesSaga";
import pdfViewerSaga from "./pdfViewer/pdfViewerSaga";
import securityLogSaga from "./securityLog/securityLogSaga";
import reserveBookSaga from "./books/reserveBookSaga";
import reservedBooksListSaga from "./books/reservedBooksListSaga";
import auditLogSaga from "./auditLog/auditLogSaga";
import readerDataSaga from "./readerData/readerDataSaga";
import qrCodesSaga from "./qrCodes/qrCodesSaga";
import reportsSaga from "./reports/reportsSaga";
import importBooksSaga from "./import/importBooksSaga";
import havkinaSaga from "./havkina/havkinaSaga";
import documentTypesSaga from "./documentTypes/documentTypesSaga";
import documentSaga from "./documents/documentSaga";
import inventorySaga from "./inventory/inventorySaga";
import booksOrderSaga from "./booksOrder/booksOrderSaga";
import newBooksOrderSaga from "./newBooksOrder/newBooksOrderSaga";
import systemVersionSaga from "./systemVersion/systemVersionSaga";
import saveBookSaga from "./books/saveBookSaga";
import adminModalUsersSaga from "./adminModalUsersSearch/loadAdminUsersSaga";
import teachersSaga from "./teachers/teachersListSaga";
import scheduleSaga from "./schedule/scheduleSaga";
import {googleSagaWatcher} from "../factory/googleBooksReduxData";
import {scheduleFacultySagaWatcher} from "../factory/scheduleReduxData";
import {availableBooksSagaWatcher} from "../factory/availableBooksModalReduxData";
import {onHandsBooksSagaWatcher} from "../factory/onHandsBooksReduxData";
import {searchByImageSagaWatcher} from "../factory/searchByImageReduxData";
import curriculumSaga from "./curriculum/curriculumSaga";
import outsecrecyRequestWatcher from "./outsecrecy/load/loadPublishersSaga";
import inventaryBookWatcher from "./inventaryBook/inventaryBookSaga";
import fundMovingWatcher from "./fundMoving/fundMovingSaga";
import fundAccountingWatcher from "./fundAccounting/fundAccountingSaga";
import bookLoanWatcher from "./bookLoan/bookLoanSaga";
import {bookAuthorBooksSagaWatcher} from "../factory/authorsBooksReduxData";
import bookFileWatcher from "./books/bookFileSaga";
import {studentProjectsSagaWatcher} from "../factory/studentProjectsReduxData";
import {duplicateWatcher} from "../factory/duplicateReduxData";
import {remcomplectWatcher} from "../factory/remcomplectReduxData";
import turnoutStatsSaga from "./turnoutStats/turnoutStatsSaga";
import searchAuditSaga from "./searchAudit/searchAuditSaga";
import libraryMaterialsSaga from "./libraryMaterials/libraryMaterialsSaga";
import {bookScanPlanWatcher} from "../factory/bookScanPlanReduxData";
import exportBooksSaga from "./books/exportBooksSaga";
import {bigDataSagaWatcher} from "../factory/BigData/bigDataReduxData";
import {expertOpinionWatcher} from "../factory/ExpertOpinionReduxData";
import {bookChangeHistoryWatcher} from "../factory/bookHistoryChangeReduxData";
import {bookNotesWatcher} from "../factory/bookNotesReduxData";
import {pagesWatcher} from "../factory/PagesReduxData";
import {bookEditFilesWatcher} from '../factory/BookEditFiles/bookEditFilesReduxData';
import categoriesSaga from "./categories/categoriesSaga";
import gukTabSaga from "./gukTab/gukTabSaga";
import permissionsByCategoriesSaga from './permissions/byCategories/permissionsByCategoriesSaga';
import litersSaga from "./liters/litersSaga";
import seriesSaga from "./series/seriesSaga";
import permissionBySeriesSaga from "./permissions/bySeries/permissionBySeriesSaga";
import permissionByLitersSaga from "./permissions/byListers/permissionByLitersSaga";
import bookScanOrderSaga from "./bookScanOrders/bookScanOrderSaga";
import mediaResourceSaga from "./mediaResource/mediaResourceSaga";
import bookSupplySaga from "./bookSupply/bookSupplySaga";
import messageSaga from "./message/messageSaga";
import borrowingTestSaga from "./borrowingTest/borrowingTestSaga";
import bookInvNumbersListSaga from "./books/bookInvNumbersListSaga";
import bookTransferSaga from "./bookTransfer/bookTransferSaga";
import schedulerSaga from "./scheduler/schedulerSaga";
import bookreserveSaga from "./bookreserve/bookreserveSaga";
import creditsSaga from "./credits/creditsSaga";
import bookCardSaga from "./bookCard/bookCardSaga";
import courseWatcher from './electronicCourses/electronicCoursesSaga';
import electronicBookImportWatcher from "./ElectronicBookImport/ElectronicBookImportSaga";
import bookSourceRequestSaga from "./booksource/bookSourseSaga";
import epubReaderSaga from "./epubReader/epubReaderSaga";
import testsSaga from "./tests/testsSaga";
import inventoryCoversRequestWatcher from "./inventoryCovers/coversSaga";

export default function* rootSaga() {
    yield all([

        authSaga(),
        bookLoanWatcher(),
        bookSupplySaga(),
        bookTransferSaga(),
        borrowingTestSaga(),
        bookInvNumbersListSaga(),
        bookSourceRequestSaga(),
        bookreserveSaga(),
        checkAuthSaga(),
        courseWatcher(),
        publishersSaga(),
        authorsSaga(),
        bookScanOrderSaga(),
        udkSaga(),
        bbkSaga(),
        bookFileWatcher(),
        electronicBookImportWatcher(),
        epubReaderSaga(),
        librarySaga(),
        litersSaga(),
        fundMovingWatcher(),
        institutionsSaga(),
        regionsSaga(),
        readerSearchSaga(),
        rubricatorSaga(),
        coversSaga(),
        bookCardSaga(),
        categoriesSaga(),
        creditsSaga(),
        invNumbersSaga(),
        inventorySaga(),
        inventoryCoversRequestWatcher(),
        inventaryBookWatcher(),
        bookDataSaga(),
        rolesSaga(),
        optionsSaga(),
        adminUsersSaga(),
        locationSaga(),
        collectionsSaga(),
        permissionsSaga(),
        externalResourcesSaga(),
        pdfViewerSaga(),
        permissionBySeriesSaga(),
        securityLogSaga(),
        auditLogSaga(),
        reserveBookSaga(),
        reservedBooksListSaga(),
        readerDataSaga(),
        reportsSaga(),
        qrCodesSaga(),
        testsSaga(),
        importBooksSaga(),
        havkinaSaga(),
        documentTypesSaga(),
        systemVersionSaga(),
        documentSaga(),
        booksOrderSaga(),
        saveBookSaga(),
        adminModalUsersSaga(),
        newBooksOrderSaga(),
        teachersSaga(),
        turnoutStatsSaga(),
        scheduleSaga(),
        searchAuditSaga(),
        fundAccountingWatcher(),
        googleSagaWatcher(),
        scheduleFacultySagaWatcher(),
        seriesSaga(),
        schedulerSaga(),
        availableBooksSagaWatcher(),
        onHandsBooksSagaWatcher(),
        searchByImageSagaWatcher(),
        curriculumSaga(),
        outsecrecyRequestWatcher(),
        bookPlacesSagaWatcher(),
        bookAuthorBooksSagaWatcher(),
        studentProjectsSagaWatcher(),
        duplicateWatcher(),
        remcomplectWatcher(),
        libraryMaterialsSaga(),
        bookScanPlanWatcher(),
        exportBooksSaga(),
        bigDataSagaWatcher(),
        expertOpinionWatcher(),
        bookChangeHistoryWatcher(),
        bookNotesWatcher(),
        pagesWatcher(),
        bookEditFilesWatcher(),
        gukTabSaga(),
        permissionsByCategoriesSaga(),
        permissionByLitersSaga(),
        mediaResourceSaga(),
        messageSaga(),
    ]);
}
