import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import { Col, Row } from "react-bootstrap";
import ModalWindow from "../../ModalWindow";
import styles from "./SendBookModal.module.css";
import BookSearchForm from "../../Covers/SearchForm";
import CoversSearchFormControls from "../../Covers/SearchFormControls";
import {setCoversFormData} from "../../../redux/actions/covers/form";
import {coversCountRequestStart} from "../../../redux/actions/covers/count";
import {INV_NUMBERS_FORM_STRUCTURE} from "../../../constants/invNumbers";
import SpinnerOuter from "../../UtulityComponents/SpinnerOuter";
import Pagination from "../../Pagination";
import SendBookList from "../SendBookList";
import {setCoversSearchData} from "../../../redux/actions/covers/search";
import {optionsGetRequestStart} from "../../../redux/actions/options/get";

const mapStateToProps = ({ covers, options }) => ({
  list: covers.list,
  count: covers.count,
  formData: covers.form,
  searchData: covers.search,
  loader: covers.loader,
  options: options.data,
});

const mapDispatchToProps = dispatch => ({
  requestList: () => dispatch(coversCountRequestStart()),
  setFormData: (data) => dispatch(setCoversFormData(data)),
  setSearchData: (data) => dispatch(setCoversSearchData(data)),
  requestOptions: () => dispatch(optionsGetRequestStart()),
});

const SendBookModal = ({ isOpen,
                         onClose,
                         title,
                         options,
                         requestOptions,
                         count,
                         list,
                         formData,
                         setFormData,
                         requestList,
                         loader,
                         userId,
                         searchData,
                         setSearchData }) => {

  useEffect(() => {
    requestOptions();
  }, [requestOptions]);

  const handleFormClear = () => {
    setFormData(INV_NUMBERS_FORM_STRUCTURE);
  };

  const handleFormSubmit = () => {
    setFormData({
      ...formData,
      idclient: options.own_client_id
    });
    requestList();
  };

  const setCurrentPage = (page) => {
    setSearchData({ ...searchData, page });
    requestList()
  };

  return (
    <ModalWindow
      isOpen={isOpen}
      onRequestClose={onClose}
      title={title}
    >
      <Row>
        <Col md={12}>
          <BookSearchForm
              data={formData}
              onSubmit={handleFormSubmit}
              setData={setFormData}
              noVerifyStatus={true}
              hideInstitutions={true}
              disableAlphabet={true}
          />


        </Col>
        {loader ? (
          <SpinnerOuter />
        ) : (
          <>
            <Col md={12}>
              <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>

                <SendBookList data={list} userId={userId}/>

                <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
            </Col>
          </>
        )}
      </Row>
    </ModalWindow>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(SendBookModal);
