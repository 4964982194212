import React, {useEffect} from 'react';
import {connect} from "react-redux";
import Pagination from "../../components/Pagination";
import LibraryMaterialsSearchForm from "../../components/LibraryMaterials/SearchForm";
import Button from "../../components/Button";
import {libraryMaterialsCountRequestStart} from '../../redux/actions/LibraryMaterials/count';
import {setLibraryMaterialsFormData} from '../../redux/actions/LibraryMaterials/form';
import {setLibraryMaterialsSearchData} from '../../redux/actions/LibraryMaterials/search';
import LibraryMaterialsList from '../../components/LibraryMaterials/List';
import {libMaterialsInitialState} from "../../redux/reducers/libraryMaterials/form/libraryMaterialsFormReducer";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import {libraryTypesListRequestStart} from "../../redux/actions/library/types/list";

const mapStateToProps = ({libraryMaterials, library}) => ({
    list: libraryMaterials.list,
    count: libraryMaterials.count,
    formData: libraryMaterials.form,
    searchData: libraryMaterials.search,
    loader: libraryMaterials.loader,
    libraryTypes: library.types.list,
});

const mapDispatchToProps = {
    requestList: libraryMaterialsCountRequestStart,
    setFormData: setLibraryMaterialsFormData,
    setSearchData: setLibraryMaterialsSearchData,
    requestLibraryTypes: libraryTypesListRequestStart,
};

const LibraryMaterials = ({
                              count,
                              loader,
                              list,
                              requestList,
                              formData,
                              setFormData,
                              searchData,
                              setSearchData,
                              match,
                              libraryTypes,
                              requestLibraryTypes
                          }) => {

    useEffect(() => {
        !libraryTypes.length && requestLibraryTypes();
    }, [requestLibraryTypes]);

    const id = match.params.id;
    const title = libraryTypes.length && libraryTypes.find(item => item.id == id).name;

    const onSubmit = () => {
        requestList(id)
    };

    const clearForm = () => {
        setFormData(libMaterialsInitialState);
    };

    const PaginationComponent = () => (
        <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page}
                    setCurrent={(page) => {
                        setSearchData({...searchData, page});
                        requestList(match.params.id)
                    }}/>
    );

    return (
        <SceneContainer title={title}>
            <LibraryMaterialsSearchForm data={formData} setData={setFormData} noVerifyStatus={true}>
                <div className={'d-flex justify-content-end'}>
                    <Button
                        label={'Очистить'}
                        onClick={clearForm}
                    />
                    <Button
                        label={'Найти'}
                        colorType={'green'}
                        style={'ml-2'}
                        onClick={onSubmit}
                    />
                </div>
            </LibraryMaterialsSearchForm>

            <PaginationComponent/>
            {loader ?
                <SpinnerOuter/>
                :
                <LibraryMaterialsList data={list}/>
            }
            <PaginationComponent/>
        </SceneContainer>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryMaterials);
