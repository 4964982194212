import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {BOOK_SOURCE_REQUEST, START} from "../../actions/action-types";
import {bookSourceList} from "../../../api/sourceBook";
import {bookSourceRequestError, bookSourceRequestSuccess} from "../../actions/booksource";

export default function* bookSourceRequestWatcher() {
    yield takeLatest(`${BOOK_SOURCE_REQUEST}${START}`, bookSourceRequestWorker);
}

function* bookSourceRequestWorker() {
    try {
        const response = yield call(bookSourceList);
        yield put(bookSourceRequestSuccess(response.data));
    } catch (error) {
        yield put(bookSourceRequestError(error));
    }
}