import React from 'react';
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

const mapStateToProps = state => ({
  authData: state.auth.data
});

const PrivateRoute = ({ component, render, adminOnly, ...props}) => {
  const Component = render ? render :  component;
  const authDataExists = props.authData !== null && props.authData !== undefined;
  const islibrarian = authDataExists && props.authData.islibrarian;
  const isResourceAvailableForYou = adminOnly ? islibrarian : true;

  return <Route {...props} render={props =>
    authDataExists ?
      isResourceAvailableForYou ?
        <Component {...props} />
        :
        <Redirect to={'/'}/>
      :
      <Redirect to={'/auth'}/>
  }/>
};

PrivateRoute.propTypes = {
  ...Route.propTypes,
  adminOnly: PropTypes.bool,
};

export default connect(mapStateToProps)(PrivateRoute);