import { LITERS_ADD_REQUEST, ERROR, START, SUCCESS } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const litersCatalogAddRequestStart = (data) => ({
  type: `${LITERS_ADD_REQUEST}${START}`,
  payload: data
});

export const litersCatalogAddRequestSuccess = (data) => {
  createNotification('success', 'Литер успешно добавлен');
  return {
    type: `${LITERS_ADD_REQUEST}${SUCCESS}`,
    payload: data
  };
};

export const litersCatalogAddRequestError = (data) => {
  createNotification('error', 'Не удалось добавить литер');
  return {
    type: `${LITERS_ADD_REQUEST}${ERROR}`,
    payload: data
  };
};
