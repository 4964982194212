import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { LIBRARY_DELETE_REQUEST, START } from "../../../../actions/action-types";
import { libraryDeleteRequestError, libraryDeleteRequestSuccess } from "../../../../actions/library/info/delete";
import { libraryDelete } from "../../../../../api/library/library";
import { libraryListRequestStart } from "../../../../actions/library/info/list";

export default function* libraryDeleteWatcher() {
  yield takeLatest(`${LIBRARY_DELETE_REQUEST}${START}`, libraryDeleteWorker)
}

function* libraryDeleteWorker({payload: id}) {
  try {
    const response = yield call(libraryDelete, id);
    yield put(libraryDeleteRequestSuccess(response.data));
    yield put(libraryListRequestStart());
  } catch (error) {
    yield put(libraryDeleteRequestError(error));
  }
}