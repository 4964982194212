import { combineReducers } from "redux";
import { pdfViewerBookReducer } from "./book/pdfViewerBookReducer";
import {pdfViewerBookmarkReducer} from "./bookmark/pdfViewerBookmarkReducer";
import {pdfViewerFileDataReducer} from "./fileData/pdfViewerFileDataReducer";

export const pdfViewerReducer = combineReducers({
  book: pdfViewerBookReducer,
  bookmark: pdfViewerBookmarkReducer,
  fileData: pdfViewerFileDataReducer
});
