import { api } from "../../App";
import {
  START,
  SUCCESS,
  GET_BOOK_NOTES_TYPES,
  GET_BOOK_NOTES,
  ADD_BOOK_NOTE,
  DEL_BOOK_NOTE
} from "../actions/action-types";
import { createSimpleActionsForReducer } from "../actions/actionsFactory";
import { createSimpleReducer } from "../reducers/reducersFactrory";
import {
  call,
  put,
  takeLatest
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";
import { combineReducers } from "redux";

const getNotesTypes = () => api.get("/booknotetype");
const getNotes = bookId => api.get(`/booknote/${bookId}`);
const addNote = (bookId, data) => api.post(`/booknote/${bookId}`, data);
const delNote = bookId => api.delete(`/booknote/${bookId}`);

export const bookNotesActions = {
  ...createSimpleActionsForReducer("types", GET_BOOK_NOTES_TYPES),
  ...createSimpleActionsForReducer("list", GET_BOOK_NOTES),
  ...createSimpleActionsForReducer("add", ADD_BOOK_NOTE),
  ...createSimpleActionsForReducer("del", DEL_BOOK_NOTE)
};

export const bookNotesReducers = combineReducers({
  types: createSimpleReducer([], `${GET_BOOK_NOTES_TYPES}${SUCCESS}`),
  list: createSimpleReducer([], `${GET_BOOK_NOTES}${SUCCESS}`)
});

export function* bookNotesWatcher() {
  yield takeLatest(`${GET_BOOK_NOTES_TYPES}${START}`, getTypesSaga);
  yield takeLatest(`${GET_BOOK_NOTES}${START}`, getListSaga);
  yield takeLatest(`${ADD_BOOK_NOTE}${START}`, addNoteSaga);
  yield takeLatest(`${DEL_BOOK_NOTE}${START}`, delNoteSaga);
}

function* getTypesSaga() {
  try {
    const response = yield call(getNotesTypes);
    yield put(bookNotesActions.typesRequestSuccess(response.data));
  } catch (e) {
    console.log(e);
  }
}

function* getListSaga({ payload }) {
  try {
    const response = yield call(getNotes, payload);
    yield put(bookNotesActions.listRequestSuccess(response.data));
  } catch (e) {
    console.log(e);
  }
}

function* addNoteSaga({ payload }) {
  try {
    yield call(addNote, payload.bookId, payload.data);
    yield put(bookNotesActions.listRequestStart(payload.bookId));
  } catch (e) {
    console.log(e);
  }
}

function* delNoteSaga({ payload }) {
    console.log(payload)
  try {
    yield call(delNote, payload.id);
    yield put(bookNotesActions.listRequestStart(payload.bookId));
  } catch (e) {
    console.log(e);
  }
}
