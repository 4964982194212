import React from 'react';
import PropTypes from 'prop-types';
import styles from "./CoversSearchFormControls.module.css";
import classNames from "classnames";
import { Link } from "react-router-dom";

const CoversSearchFormControls = ({ onSubmit, onClear, disableAlphabet }) => {
  return (
    <div className={classNames(styles.container, 'd-flex justify-content-end')}>

      <div>
        {!disableAlphabet &&
        <Link to="/library/alphabet" className={styles.button}>
          Алфавитный указатель
        </Link>
        }

        <div className={styles.button} onClick={onClear}>
          Очистить
        </div>
        <div className={classNames(styles.button, styles.buttonFind)} onClick={() => onSubmit()}>
          Найти
        </div>
      </div>
    </div>
  );
};

CoversSearchFormControls.propTypes = {
  onSubmit: PropTypes.func,
  onClear: PropTypes.func
};

export default CoversSearchFormControls;
