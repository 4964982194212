import { SEARCH_AUDIT_ITEM_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const searchAudiItemRequestStart = (id) => ({
  type: `${SEARCH_AUDIT_ITEM_REQUEST}${START}`,
  payload: id
});

export const searchAudiItemRequestSuccess = (data) => ({
  type: `${SEARCH_AUDIT_ITEM_REQUEST}${SUCCESS}`,
  payload: data
});

export const searchAudiItemRequestError = (data) => ({
  type: `${SEARCH_AUDIT_ITEM_REQUEST}${ERROR}`,
  payload: data
});
