import { ERROR, PDF_VIEWER_BOOKMARK_SAVE, START, SUCCESS } from "../action-types";

export const pdfViewerBookmarkSaveStart = (bookId, fileId, bookmarks) => ({
    type: `${PDF_VIEWER_BOOKMARK_SAVE}${START}`,
    payload: {bookId, fileId, bookmarks}
});

export const pdfViewerBookmarkSaveSuccess = (data) => ({
    type: `${PDF_VIEWER_BOOKMARK_SAVE}${SUCCESS}`,
    payload: data
});

export const pdfViewerBookmarkSaveError = (data) => ({
    type: `${PDF_VIEWER_BOOKMARK_SAVE}${ERROR}`,
    payload: data
});