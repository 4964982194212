import React from 'react';
import PropTypes from 'prop-types';

import styles from './ElectronicCoursesTable.module.css';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import {ADD, EDIT} from "../../../constants/modalTypes";

const ElectronicCoursesTable = ({
                                    data, sortBy, setSort, addFile,
                                    handleSelect, selectedId, isLibrarian,
                                    onItemDelete, setModalData
                                }) => {

    const openModal = (item) => setModalData({ isOpen: true, modalType: EDIT, data: item });

    const tableData = data.map((item, index) => {
        item.index = index + 1;
        return item;
    });

    const anchorClickHandler = (id) => {
        const fileWindow = window.open(
            `https://biblio.nintegra.ru/api/course/${id}/file`
        );
        fileWindow.focus();
    };

    const inputOnChangeHandler = (e, id) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        addFile({id, file: formData});
    };

    const librarianControls = () => (
        <div className={styles.controls}>
            <div onClick={() => setModalData({
                isOpen: true, modalType: ADD, data: null,
            })}
            >добавить</div>
            <div onClick={onItemDelete}>удалить</div>
        </div>
    );

    return (
        <div className={styles.container}>
            {isLibrarian ? librarianControls() : null}
            <DataTable data={tableData} sortBy={sortBy} onHeadClick={setSort} onCellClick={openModal} >
                <DataTableCol title="№ п/п" value="index" colSpan={1}/>
                <DataTableCol title="Название" value="title" colSpan={2}/>
                <DataTableCol
                    title="Статус"
                    resolver={({status}) => status === 'DONE' ? 'Завершен' : 'В ходе выполнения'}
                    colSpan={2}/>
                <DataTableCol
                    title="Дата начала изучения"
                    resolver={({startdate}) => new Date(startdate).toLocaleString().split(',')[0]}
                    colSpan={2}/>
                <DataTableCol
                    title="Дата завершения изучения"
                    resolver={({enddate}) => enddate ? new Date(enddate).toLocaleString().split(',')[0] : 'Не указано'}
                    colSpan={2}/>
                <DataTableCol title="Продолжительность изучения" value="duration" colSpan={2}/>
                <DataTableCol
                    title="Результат"
                    colSpan={2}
                    resolver={(item) => {
                        return (<div className={styles.fileUploader}>
                            {isLibrarian ? <>
                                <label htmlFor="file">Загрузить файл</label>
                                <input
                                    type='file'
                                    id={'file'}
                                    onChange={e => inputOnChangeHandler(e, item.id)}
                                />
                            </> : null
                            }
                            {item.filename ? <a
                                    className={styles.anchor}
                                    onClick={() => anchorClickHandler(item.id)}
                                >Ссылка на курс</a> :
                                <p style={{margin: 0}}>-</p>}
                        </div>)
                    }}
                />
                <DataTableCol
                    title="Выбрано"
                    colSpan={1}
                    passClicks
                    resolver={(item) => <input
                        type={'checkbox'}
                        checked={item.id === selectedId}
                        onChange={() => handleSelect(item.id)}
                    />
                    }
                />
            </DataTable>
        </div>
    );
};

ElectronicCoursesTable.propTypes = {
    data: PropTypes.array,
    sortBy: PropTypes.string,
    setSort: PropTypes.func,
};

export default ElectronicCoursesTable;
