import React from 'react';
import PropTypes from 'prop-types';
import ModalWindow from "../../../../ModalWindow";
import UDKList from "../../../../UDK/List";
import BBKList from "../../../../BBK/List";

const CodesSearchModal = ({ type, isOpen, setOpen, data, fetchSubList, collapseList, setCodeEditor }) => {
  const onSelect = (item) => {
    setCodeEditor(item);
    setOpen(false);
  };

  return (
    <ModalWindow
      title={'Поиск кода в справочнике'}
      isOpen={isOpen}
      onRequestClose={() => setOpen(false)}
    >
      {type === 'udk' &&
        <UDKList
          data={data}
          fetchSubList={fetchSubList}
          collapseList={collapseList}
          onSelect={onSelect}
        />
      }
      {type === 'bbk' &&
        <BBKList
          data={data}
          fetchSubList={fetchSubList}
          collapseList={collapseList}
          onSelect={onSelect}
        />
      }

    </ModalWindow>
  );
};

CodesSearchModal.propTypes = {
  type: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  setCodeEditor: PropTypes.func.isRequired
};

export default CodesSearchModal;