import React from 'react';
import PropTypes from 'prop-types';
import styles from './ModalImagePopover.module.css';
import { Popover } from "react-bootstrap";

const ModalImagePopover = React.forwardRef(({uploadImage, deleteImage, ...props}, ref) => {
  return (
    <Popover id="modalPopover" {...props} show={props.show.toString()} ref={ref}>
      <span className={styles.button} onClick={uploadImage}>
        загрузить
      </span>
      <span className={styles.button} onClick={deleteImage}>
        удалить
      </span>
    </Popover>
  );
});

ModalImagePopover.propTypes = {
  uploadImage: PropTypes.func,
  deleteImage: PropTypes.func
};

export default ModalImagePopover;
