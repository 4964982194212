import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { READER_DATA_REQUEST, START } from "../../actions/action-types";
import { getReaderData } from "../../../api/reader/reader";
import { readerDataRequestError, readerDataRequestSuccess } from "../../actions/reader/getData";

export default function* readerDataWatcher() {
  yield takeLatest(`${READER_DATA_REQUEST}${START}`, readerDataWorker)
}

function* readerDataWorker({payload: mid}) {
  try {
    const response = yield call(getReaderData, mid);
    yield put(readerDataRequestSuccess(response.data));
  } catch (error) {
    yield put(readerDataRequestError(error));
  }
}