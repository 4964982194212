import {api} from "../../App";

export const getData = file => {
  const formData = new FormData();
  formData.append("cover", file);
  return api.post("/coversearch", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};