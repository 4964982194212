import React, {useCallback} from "react";
import View from "./index";
import {
    itemsNumberPerPage,
    searchByImageActions
} from "../../redux/factory/searchByImageReduxData";
import {connect} from "react-redux";
import {reserveBookRequestStart} from "../../redux/actions/books/reserveBook";
import {unreserveBookRequestStart} from "../../redux/actions/books/unreserveBook";
import {REQUEST_SOURCE} from "../../constants/requestSource";
import {createNotification} from "../../components/notification/notification";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";

const mapStateToProps = ({searchByImage}) => ({
    listData: searchByImage.list,
    isFirstTimeFetch: searchByImage.isFirstTimeFetch,
    search: searchByImage.search
});

const mapDispatchToProps = dispatch => ({
    requestList: file => dispatch((file)),
    reserveBook: instanceId => dispatch(reserveBookRequestStart(instanceId)),
    unreserveBook: instanceId =>
        dispatch(unreserveBookRequestStart(instanceId, REQUEST_SOURCE.COVERS)),
    setSearchData: data => dispatch(searchByImageActions.setSearchData(data))
});

const SearchByImageContainer = ({
                                    listData,
                                    requestList,
                                    reserveBook,
                                    unreserveBook,
                                    isFirstTimeFetch,
                                    search: pageInfo,
                                    setSearchData: setPageInfo
                                }) => {
    const imageFormats = ['JPG','JPEG','PNG'];

    const onImageChange = useCallback(
        file => {
            let fileFormat = file.name.split('.').slice(-1)[0].toUpperCase();
            if ( imageFormats.includes(fileFormat) ) {
                setPageInfo({file});
                requestList(file);
            } else {
                createNotification('error', 'Недопустимый формат');
            }
        },
        [requestList]
    );

    const setCurrentPage = useCallback(
        number => {
            setPageInfo({...pageInfo, currentPage: number});
        },
        [pageInfo, setPageInfo]
    );

    const getPartOfList = useCallback(() => {
        return (listData.data || []).slice(
            (pageInfo.currentPage - 1) * itemsNumberPerPage,
            (pageInfo.currentPage - 1) * itemsNumberPerPage + itemsNumberPerPage
        );
    }, [listData.data, pageInfo.currentPage]);


    return (
        <SceneContainer title={'Поиск по картинке'}>
            <View
                {...pageInfo}
                reserveBook={reserveBook}
                unreserveBook={unreserveBook}
                list={getPartOfList()}
                loading={listData.loading}
                onChangeImage={onImageChange}
                setCurrentPage={setCurrentPage}
                file={pageInfo.file}
                noResults={
                    !isFirstTimeFetch &&
                    !listData.loading &&
                    (!listData.data || listData.data.length === 0)
                }
            />
        </SceneContainer>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchByImageContainer);
