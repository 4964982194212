import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
  DOCUMENT_COUNT_REQUEST, DOCUMENT_DELETE_REQUEST,
  DOCUMENT_LIST_REQUEST, DOCUMENT_NUMBER_REQUEST,
  DOCUMENT_SAVE_REQUEST,
  START
} from "../../../actions/action-types";
import {
  addDocument,
  deleteDocument,
  editDocument,
  getDocumentCount,
  getDocumentList, getDocumentNumber
} from "../../../../api/documents/documents";
import {
  documentCountRequestError,
  documentCountRequestStart,
  documentCountRequestSuccess
} from "../../../actions/documents/documents/count";
import { setDocumentSearchData } from "../../../actions/documents/documents/search";
import {
  documentListRequestError,
  documentListRequestStart,
  documentListRequestSuccess
} from "../../../actions/documents/documents/list";
import { documentSaveRequestError, documentSaveRequestSuccess } from "../../../actions/documents/documents/save";
import { documentDeleteRequestError, documentDeleteRequestSuccess } from "../../../actions/documents/documents/delete";
import { setLatestDocument } from "../../../actions/documents/documents/latestDocument";
import {
  documentNumberRequestError,
  documentNumberRequestStart,
  documentNumberRequestSuccess
} from "../../../actions/documents/documents/number";

export default function* documentWatcher() {
  yield takeLatest(`${DOCUMENT_COUNT_REQUEST}${START}`, documentCountWorker);
  yield takeLatest(`${DOCUMENT_LIST_REQUEST}${START}`, documentListWorker);
  yield takeLatest(`${DOCUMENT_SAVE_REQUEST}${START}`, documentSaveWorker);
  yield takeLatest(`${DOCUMENT_NUMBER_REQUEST}${START}`, documentNumberWorker);
  yield takeLatest(`${DOCUMENT_DELETE_REQUEST}${START}`, documentDeleteWorker);
}

function* documentCountWorker() {
  const searchData = yield select(({ documents }) => documents.search);
  try {
    const response = yield call(getDocumentCount, searchData);
    const count = parseInt(response.data.count, 10);
    yield put(documentCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setDocumentSearchData({ ...searchData, pageCount }));
    yield put(documentListRequestStart());
  } catch (error) {
    yield put(documentCountRequestError(error));
  }
}

function* documentListWorker() {
  const searchData = yield select(({ documents }) => documents.search);
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);

  try {
    const response = yield call(getDocumentList, searchData);
    yield put(documentListRequestSuccess(response.data));
    yield put(documentNumberRequestStart());
  } catch (error) {
    yield put(documentListRequestError(error));
  }
}

function* documentSaveWorker({ payload: data }) {
  try {
    const response = yield call(data.id ? editDocument : addDocument, data);
    yield put(documentSaveRequestSuccess(response.data));
    yield put(setLatestDocument(response.data));
    yield put(documentCountRequestStart());
  } catch (error) {
    yield put(documentSaveRequestError(error))
  }
}

function* documentNumberWorker() {
  try {
    const response = yield call(getDocumentNumber);
    yield put(documentNumberRequestSuccess(response.data.nextdocumentnumber));
  } catch (error) {
    yield put(documentNumberRequestError(error));
  }
}

function* documentDeleteWorker({ payload: id }) {
  try {
    const response = yield call(deleteDocument, id);
    yield put(documentDeleteRequestSuccess(response.data));
    yield put(documentCountRequestStart());
  } catch (error) {
    yield put(documentDeleteRequestError(error));
  }
}
