import { SET_TESTS_SEARCH } from "../../../actions/action-types";
import { RESULTS_PER_PAGE } from "../../../../constants/pagination";

const initialState = {
    page: 1,
    maxResults: RESULTS_PER_PAGE,
    pageCount: 0
};

export const testsSearchReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_TESTS_SEARCH:
            return action.payload;

        default: return state;
    }
};
