import React, {useState, useEffect} from 'react';
import {Col, Row} from "react-bootstrap";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import styles from "../DocumentsModal.module.css";
import {EDIT} from "../../../../constants/modalTypes";
import SearchableSelect from "../../../Select";
import {useFormik} from 'formik';
import PropTypes from 'prop-types';
import * as Yup from "yup";
import Button from "../../../Button";
import ModalWindow from "../../../ModalWindow";


const MODAL_SAVE_TYPES = {
    EDIT: 'Сохранить документ',
    ADD: 'Создать документ'
};

const defaultValidationSchema = Yup.object().shape({
    "documentnumber": Yup.string()
        .required('Это поле необходимо заполнить'),
    "documenttype": Yup.string()
        .required('Выберите вид документа'),
    "documentdate": Yup.date()
        .required('Выберите дату документа'),
    "supplier": Yup.string()
        .when("documenttype", {
            is: (val) => val !== "18", //Не акт списания
            then: Yup.string().nullable().required('Выберите источник'),
            otherwise: Yup.string().nullable().notRequired(),
        }),
    "library": Yup.string()
        .nullable().required('Выберите библиотеку'),

    // "library": Yup.string()
    //     .when("documenttype", {
    //         is: (val) => val === "1", //Приход от поставщика
    //         then: Yup.string().nullable().required('Выберите библиотеку'),
    //         otherwise: Yup.string().nullable().notRequired(),
    //     })
});

const ModalHeader = ({
                         modalData,
                         modalType,
                         institutionsList,
                         types,
                         libraries,
                         saveDocument,
                         setFormValidity,
                         openSaveModal,
                         toggleSaveModal,
                         onModalSave,
                         setCurrentBookData
                     }) => {

    const modalForm = useFormik({
        initialValues: modalData,
        validationSchema: defaultValidationSchema,
        onSubmit: (values) => saveDocument(values),
    });

    const validityCheck = () => {
        modalForm.validateForm()
            .then(response => {
                !!Object.entries(response).length ? setFormValidity(false) : setFormValidity(true)
            })
    };

    useEffect(() => {
        validityCheck()
    }, []);

    useEffect(() => {
        if (modalForm.dirty) validityCheck();
    }, [modalForm.values]);



    const {values, errors} = modalForm;

    return (
        <>
            <Row>
                <Col md={6}>
                    <ModalInputWrapper
                        label="Номер документа*"
                        name="documentnumber"
                        value={values["documentnumber"]}
                        onChange={modalForm.handleChange}
                        error={errors["documentnumber"]}
                    />

                    <ModalInputWrapper
                        label="Вид документа*"
                        error={errors["documenttype"]}
                    >
                        <select
                            name="documenttype"
                            value={values["documenttype"]}
                            onChange={modalForm.handleChange}
                            disabled={modalType === EDIT ? true : null}
                            className={styles.select}
                        >
                            <option value="">Не выбрано</option>
                            {types.map((item, index) =>
                                <option value={item.id} key={index}>
                                    {item.name}
                                </option>
                            )}
                        </select>
                    </ModalInputWrapper>

                    <ModalInputWrapper
                        label="Дата документа*"
                        type="date"
                        name="documentdate"
                        value={values["documentdate"]}
                        onChange={modalForm.handleChange}
                        error={errors["documentdate"]}
                    />

                    { libraries && (
                        <ModalInputWrapper
                            label="Библиотеки"
                            error={errors["library"]}
                        >
                            <select
                                name="library"
                                value={values["library"]}
                                onChange={modalForm.handleChange}
                                disabled={modalType === EDIT ? true : null}
                                className={styles.select}
                            >
                                <option value="">Не выбрано</option>
                                {libraries.map((item, index) => (
                                    <option value={item.id} key={index}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </ModalInputWrapper>
                    )}
                </Col>
                <Col md={6}>
                    <ModalInputWrapper
                        label="Примечание"
                        type="textarea"
                        name="description"
                        value={values["description"] === "not null" ? '' : values["description"]}
                        onChange={modalForm.handleChange}
                    />

                    {values["documenttype"] !== "18" &&
                    <ModalInputWrapper
                        label="Источник*"
                        error={errors["supplier"]}
                    >
                        <SearchableSelect
                            placeholder="Поиск"
                            id="supplier"
                            provider={true}
                            options={institutionsList}
                            onChange={(val) => modalForm.setFieldValue("supplier", val)}
                            value={institutionsList.find(({value}) => value === parseInt(values["supplier"], 10))}
                        />
                    </ModalInputWrapper>
                    }
                </Col>
                <Col md={12}>
                    <div className={styles.bottomSection}>
                        <Button
                            type={'submit'}
                            label={MODAL_SAVE_TYPES[modalType]}
                            onClick={modalForm.handleSubmit}
                        />
                    </div>
                </Col>
            </Row>

            <ModalWindow
                title={`Документ: сохранение`}
                isOpen={openSaveModal}
                onRequestClose={() => toggleSaveModal(!openSaveModal)}
            >
                <div className={styles.saveModal}>
                    <h4> Перед добавлением книги необходимо сохранить документ</h4>

                    <div className={styles.saveModalButtons}>
                        <Button
                            type={'submit'}
                            colorType={'red'}
                            label={'Отмена'}
                            style={'mr-2'}
                            onClick={() => {
                                toggleSaveModal(false);
                                setCurrentBookData(null);
                            }}
                        />
                        <Button
                            type={'submit'}
                            colorType={'green'}
                            label={'Сохранить'}
                            onClick={() => {
                                modalForm.handleSubmit();
                                onModalSave();
                            }}
                        />
                    </div>

                </div>
            </ModalWindow>
        </>
    );
};

ModalHeader.propTypes = {
    modalData: PropTypes.object.isRequired,
};

export default ModalHeader;
