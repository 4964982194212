import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './BookSearchForm.module.css';
import ModalInputWrapper from "../../Modal/InputWrapper";
import {Col, Row} from "react-bootstrap";
import {libraryInstitutionsRequestStart} from "../../../redux/actions/library/info/institutions";
import {connect} from "react-redux";
import {nonNull} from "../../../utils/strings";
import {verifyStatusList} from '../../../constants/bookVerifyStatus'
import {collectionsListRequestStart} from "../../../redux/actions/collections/list";
import SearchableSelect from "../../Select";
import {categoriesToOptions} from "../../../utils/toSelectOption";
import {categoriesListRequestStart} from "../../../redux/actions/categories/list";
import CoversSearchFormControls from "../SearchFormControls";
import {INV_NUMBERS_FORM_STRUCTURE} from "../../../constants/invNumbers";

const mapStateToProps = ({library, collections, categories}) => ({
    institutions: library.info.institutions,
    collections: collections.list,
    categoriesList: categories.list
});

const mapDispatchToProps = {
    requestInstitutions: libraryInstitutionsRequestStart,
    requestCollections: collectionsListRequestStart,
    requestCategories: categoriesListRequestStart
};

const BookSearchForm = ({
                            inputRef,
                            isModal,
                            data,
                            setData,
                            children,
                            institutions,
                            requestInstitutions,
                            hideInstitutions,
                            collections,
                            requestCollections,
                            noVerifyStatus,
                            requestCategories,
                            categoriesList,
                            onSubmit,
                            disableAlphabet,
                            simplified,
                            disableDefaultPanel = false
                        }) => {
    const onChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    };

    useEffect(() => {
        requestInstitutions();
    }, [requestInstitutions]);

    useEffect(() => {
        requestCollections();
    }, [requestCollections]);

    useEffect(() => {
        requestCategories();
    }, [requestCategories]);

    if (noVerifyStatus && !simplified) {
        data.verifyStatus = 2;
    }

    const clearForm = () => {
        setData(INV_NUMBERS_FORM_STRUCTURE)
    };

    return (
        <div className={styles.container}>
            <Row>
                <Col md={12}>
                    <ModalInputWrapper onChange={onChange}
                                       name="title"
                                       value={data.title}
                                       label="Заглавие"
                                       inputRef={inputRef}
                                       isModal={isModal}/>
                </Col>
                {!simplified && <>
                    <Col md={6}>
                        <ModalInputWrapper onChange={onChange}
                                           name="author"
                                           value={data.author}
                                           label="Автор"/>
                    </Col>
                    < Col md={6}>
                        <ModalInputWrapper onChange={onChange}
                                           name="publisher"
                                           value={data.publisher}
                                           label="Издательство"/>
                    </Col>
                    <Col md={6}>
                        <ModalInputWrapper onChange={onChange}
                                           name="isbn"
                                           value={data.isbn}
                                           label="ISBN"/>
                    </Col>
                    <Col md={6}>
                        <ModalInputWrapper onChange={onChange}
                                           name="udk"
                                           value={data.udk}
                                           label="УДК"/>
                    </Col>
                    <Col md={6}>
                        <ModalInputWrapper onChange={onChange}
                                           name="year"
                                           value={data.year}
                                           label="Год"/>
                    </Col>
                    <Col md={6}>
                        <ModalInputWrapper onChange={onChange}
                                           name="bbk"
                                           value={data.bbk}
                                           label="ББК"/>
                    </Col>
                    <Col md={6}>
                        <ModalInputWrapper label="Категория">
                            <SearchableSelect
                                placeholder="Поиск"
                                options={categoriesToOptions(categoriesList)}
                                onChange={value =>
                                    setData({...data, categoryid: (value || {}).value})
                                }
                            />
                        </ModalInputWrapper>
                    </Col>
                    <Col md={6}>
                        <ModalInputWrapper onChange={onChange}
                                           name="discipline"
                                           value={data.discipline}
                                           label="Дисциплина"/>
                    </Col>
                    <Col md={12}>
                        <ModalInputWrapper onChange={onChange}
                                           name="textsearch"
                                           value={data.textsearch}
                                           label="Полнотекстовый поиск"/>
                    </Col>
                </>
                }
                {!noVerifyStatus &&
                <Col md={6}>
                    <ModalInputWrapper label={"Статус"}>
                        <select value={data.verifyStatus}
                                name="verifyStatus"
                                onChange={onChange}>
                            {verifyStatusList.map((item, index) =>
                                <option value={item.value} key={index}>
                                    {item.name}
                                </option>
                            )}
                        </select>
                    </ModalInputWrapper>
                </Col>
                }

                {!hideInstitutions &&
                <Col md={6}>
                    <ModalInputWrapper label={"Организация"}>
                        <select value={nonNull(data.idclient)}
                                name="idclient"
                                onChange={onChange}>
                            <option value={""}>
                                Не выбрано
                            </option>
                            {institutions.map((item, index) =>
                                <option value={item.idclient} key={index}>
                                    {item.name}
                                </option>
                            )}
                        </select>
                    </ModalInputWrapper>
                </Col>
                }
                {!simplified &&
                <Col md={6}>
                    <ModalInputWrapper label={"Коллекции"}>
                        <select value={nonNull(data.collectionid)}
                                name="collectionid"
                                onChange={onChange}>
                            <option value={""}>
                                Не выбрано
                            </option>
                            {collections.map(item =>
                                <option value={item.id} key={`collection${item.id}`}>
                                    {item.name}
                                </option>
                            )}
                        </select>
                    </ModalInputWrapper>
                </Col>
                }
                <Col md={12}>
                    {children}
                </Col>
            </Row>
            {!disableDefaultPanel &&
                <CoversSearchFormControls
                    disableAlphabet={disableAlphabet}
                    onSubmit={onSubmit}
                    onClear={clearForm}
                />
            }

        </div>
    );
};

BookSearchForm.propTypes = {
    data: PropTypes.object,
    setData: PropTypes.func,
    hideInstitutions: PropTypes.bool,
    onSubmit: PropTypes.func,
    disableAlphabet: PropTypes.bool,
    disableDefaultPanel: PropTypes.bool,
};



export default connect(mapStateToProps, mapDispatchToProps)(BookSearchForm);
