import React from 'react';
import PropTypes from 'prop-types';
import styles from './AuditLogForm.module.css'
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";

const AuditLogForm = ({ data, setData, search, dataset }) => {
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });
  return (
    <div className={styles.container}>
      <Row>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="from"
                             value={data.from}
                             label="С (дата)"
                             type="date"/>
        </Col>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="to"
                             value={data.to}
                             label="До (дата)"
                             type="date"/>
        </Col>
        <Col md={12}>
          <ModalInputWrapper onChange={onChange}
                             name="clientip"
                             value={data.clientip}
                             label="IP-адрес"/>
        </Col>
        <Col md={12}>
          <ModalInputWrapper onChange={onChange}
                             name="name"
                             value={data.name}
                             label="Имя пользователя"/>
        </Col>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="username"
                             value={data.username}
                             label="Логин"/>
        </Col>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="mid"
                             value={data.mid}
                             label="ID пользователя"/>
        </Col>
        <Col md={6}>
          <ModalInputWrapper label="Запись">
            <select className={styles.select} onChange={onChange} name={"write"} value={data.write}>
              <option value={""}>Не выбрано</option>
              <option value={true}>Да</option>
              <option value={false}>Нет</option>
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={6}>
          <ModalInputWrapper label="Отказано в доступе">
            <select className={styles.select} onChange={onChange} name={"accessdenied"} value={data.accessdenied}>
              <option value={""}>Не выбрано</option>
              <option value={true}>Да</option>
              <option value={false}>Нет</option>
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="bookid"
                             value={data.bookid}
                             label="ID книги"/>
        </Col>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="affectedid"
                             value={data.affectedid}
                             label="ID сущности"/>
        </Col>
        <Col md={12}>
          <ModalInputWrapper label="Датасет">
            <select className={styles.select} onChange={onChange} name={"dataset"} value={data.dataset}>
              <option value={""}>Не выбрано</option>
              {dataset.map((item, index) =>
                <option value={item} key={index}>{item}</option>
              )}
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={12} className={"text-right"}>
          <Button label={"Найти"} onClick={search}/>
        </Col>
      </Row>
    </div>
  );
};

AuditLogForm.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  search: PropTypes.func,
  dataset: PropTypes.array
};

export default AuditLogForm;
