import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import styles from './ModalWindow.module.css';

const ModalWindow = ({children, isOpen, title, onRequestClose, width, height}) => {

    return (
        <ReactModal
            onRequestClose={onRequestClose}
            isOpen={isOpen}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: '30px',
                },
                content: {
                    position: "relative",
                    border: "none",
                    boxShadow: "0 0 40px 0 rgba(0,0,0,.3)",
                    maxWidth: "1170px",
                    margin: "auto",
                    width: width,
                    height: height,
                    maxHeight: "90vh",
                    top: '0px',
                    left: '0px',
                    right: '0px',
                    bottom: '0px',
                }
            }}
        >
            <div className={styles.modalClose} onClick={onRequestClose}>
                <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" enableBackground="new 0 0 413.348 413.348"
                     height="512px" viewBox="0 0 413.348 413.348" width="512px">
                    <g>
                        <path
                            d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"
                            data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF"/>
                    </g>
                </svg>
            </div>
            <div className={styles.modalHeader}>{title}</div>
            <div className={styles.modalBody}>
                {children}
            </div>
        </ReactModal>
    );
};

ModalWindow.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    onRequestClose: PropTypes.func,
};

export default ModalWindow;
