import React, {useEffect} from 'react';
import {securityLogCountRequestStart} from "../../redux/actions/securityLog/count";
import {setSecurityLogFormData} from "../../redux/actions/securityLog/formData";
import {setSecurityLogSearchData} from "../../redux/actions/securityLog/searchData";
import {connect} from "react-redux";
import Pagination from "../../components/Pagination";
import CheckDataLogTable from "../../components/CheckDataLog/Table";
import CheckDataLogForm from "../../components/CheckDataLog/Form";
import {SORT_DIRECTION} from "../../constants/sortDirections";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";

const mapStateToProps = ({securityLog}) => ({
    list: securityLog.list,
    count: securityLog.count,
    formData: securityLog.form,
    searchData: securityLog.search
});

const CheckDataLog = ({count, list, requestList, formData, setFormData, searchData, setSearchData}) => {

    useEffect(() => {
        requestList()
    }, [requestList]);

    const setSort = (sortBy) => {
        let sortDir = searchData.sortDirection === SORT_DIRECTION.ASC ?
            SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;
        setSearchData({...searchData, sortBy, sortDirection: sortDir});
        requestList();
    };

    const setCurrentPage = (page) => {
        setSearchData({...searchData, page});
        requestList()
    };
    return (
        <SceneContainer title="Проверка целостности данных при миграции">
            <CheckDataLogForm data={formData} setData={setFormData} search={requestList}/>

            <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page}
                        setCurrent={setCurrentPage}/>
            <CheckDataLogTable data={list} sortBy={searchData.sortBy} setSort={setSort}/>
            <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page}
                        setCurrent={setCurrentPage}/>
        </SceneContainer>
    );
};

export default connect(mapStateToProps, {
    requestList: securityLogCountRequestStart,
    setFormData: setSecurityLogFormData,
    setSearchData: setSecurityLogSearchData
})(CheckDataLog);
