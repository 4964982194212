import React from 'react';
import PropTypes from 'prop-types';
import styles from './LibraryAlphabetSearch.module.css';
import {Col, Row} from "react-bootstrap";
import Button from "../../Button";
import ModalInputWrapper from "../../Modal/InputWrapper";

const LibraryAlphabetSearch = ({data, setData, onSubmit, count}) => {

    return (
        <div className={styles.container}>
            <Row>
                <Col>
                    <ModalInputWrapper
                        label={'Автор'}
                        value={data.author}
                        onChange={(e) => setData({...data, author: e.target.value})}
                    />
                </Col>
                <Col>
                    <ModalInputWrapper
                        label={'Название'}
                        alue={data.title}
                        onChange={(e) => setData({...data, title: e.target.value})}
                    />
                </Col>

                <Col md={{span: 9, offset: 3}}>
                    <div className={styles.label}>
                        <input type="checkbox" checked={data.emptyCards}
                               onChange={(e) => setData({...data, emptyCards: e.target.checked})}/> Пустые библиотечные
                        записи
                    </div>
                </Col>
                <Col md={{span: 6, offset: 3}}>
                    <div className={styles.foundCount}>
                        Найдено {count} записей
                    </div>
                </Col>
                <Col md={3}>
                    <div className={styles.buttonContainer}>
                        <Button label={"Найти"} onClick={onSubmit}/>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

LibraryAlphabetSearch.propTypes = {
    data: PropTypes.object,
    setData: PropTypes.func,
    onSubmit: PropTypes.func,
    count: PropTypes.number
};

export default LibraryAlphabetSearch;