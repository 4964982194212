import { ERROR, LIBRARY_TYPE_SAVE_REQUEST, START, SUCCESS } from "../../action-types";
import {createNotification} from "../../../../components/notification/notification";

export const libraryTypeSaveRequestStart = (data) => ({
  type: `${LIBRARY_TYPE_SAVE_REQUEST}${START}`,
  payload: data
});

export const libraryTypeSaveRequestSuccess = (data) => {
    createNotification('success', 'Успешно создано');

    return {
        type: `${LIBRARY_TYPE_SAVE_REQUEST}${SUCCESS}`,
        payload: data
    };
};

export const libraryTypeSaveRequestError = (data) => {
    createNotification('error', 'Ошибка создания');

    return {
        type: `${LIBRARY_TYPE_SAVE_REQUEST}${ERROR}`,
        payload: data
    };
};
