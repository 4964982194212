import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { RUBRICATOR_SAVE_REQUEST, START } from "../../../actions/action-types";
import { rubricatorAdd, rubricatorEdit } from "../../../../api/rubricator/rubricator";
import { rubricatorSaveRequestError, rubricatorSaveRequestSuccess } from "../../../actions/rubricator/save";
import { rubricatorCountRequestStart } from "../../../actions/rubricator/count";
import { ADD } from "../../../../constants/modalTypes";

export default function* rubricatorSaveWatcher() {
  yield takeLatest(`${RUBRICATOR_SAVE_REQUEST}${START}`, rubricatorSaveWorker)
}

function* rubricatorSaveWorker({payload: { data, modalType: type }, callback}) {

  try {
    const response = yield call(type === ADD ? rubricatorAdd : rubricatorEdit, data);
    yield put(rubricatorSaveRequestSuccess(response.data));
    yield call(callback);
    yield put(rubricatorCountRequestStart());
  } catch(error) {
    yield put(rubricatorSaveRequestError(error));
  }
}