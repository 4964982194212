import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import Button from "../../../Button";
import * as Yup from "yup";
import {useFormik} from "formik";
import moment from "moment";


const validationSchema = Yup.object().shape({
  dateFrom: Yup.string()
    .required('Выберите дату'),
  dateTo: Yup.string()
    .required('Выберите дату'),
});

const BookGivingStatsReportForm = ({ onSubmit }) => {

  const searchForm = useFormik({
    initialValues: {
      dateFrom: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD')
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: values => onSubmit(values)
  });

  return (
    <div className={"formContainer"}>
      <Row>
        <Col>
          <ModalInputWrapper
            label="C*"
            value={searchForm.values.dateFrom}
            name="dateFrom"
            error={searchForm.errors.dateFrom}
            onChange={searchForm.handleChange}
            type="date"
          />
        </Col>
        <Col>
          <ModalInputWrapper
            label="По*"
            value={searchForm.values.dateTo}
            name="dateTo"
            error={searchForm.errors.dateTo}
            onChange={searchForm.handleChange}
            type="date"
          />
        </Col>

        <Col md={"auto"} className={"text-right"}>
          <ModalInputWrapper label={''}>
            <Button label={"Найти"} onClick={searchForm.handleSubmit}/>
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

BookGivingStatsReportForm.propTypes = {
  onSubmit: PropTypes.func
};

export default BookGivingStatsReportForm;
