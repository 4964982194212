import React from 'react';
import PropTypes from 'prop-types';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import moment from "moment";
import styles from './BookScanOrdersList.module.css';

const STATUS_LIST = [
    {
        title: 'Новый',
        value: 1
    }, {
        title: 'Готово',
        value: 2
    }
];

const BookScanOrdersList = ({list, deleteItem}) => {

    const findStatus = item => {
        const statusName = STATUS_LIST.find(status => status.value === item.status);
        return statusName ? statusName.title : '-';
    };

    return (
        <div>

            <DataTable data={list}>
                <DataTableCol title='Название' colSpan={6} value='title'/>
                <DataTableCol title='Время создания' colSpan={6} resolver={item => {
                    const timestamp = item.timestamp || '';
                    const date = timestamp.split('T')[0];
                    const time = timestamp.split('T')[1].split('.')[0];

                    return moment(date).format('DD.MM.YYYY') + " " + time;
                }}/>
                <DataTableCol title='Статус' resolver={findStatus}/>
                <DataTableCol title='' colSpan={2} resolver={item =>
                    <span className={styles.delete} onClick={() => deleteItem(item.id)}>Удалить</span>
                }/>
            </DataTable>

        </div>
    );
};

BookScanOrdersList.propTypes = {
    list: PropTypes.array.isRequired,
    deleteItem: PropTypes.func
};

export default BookScanOrdersList;