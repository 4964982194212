import React from 'react';
import PropTypes from 'prop-types';
import styles from "../s.module.css";
import printIcon from "../../../img/icons/print.png";

const PdfPrintPanel = ({ loadPdf, printPdf }) => {
    return (
        <div className={styles.print}>
            <span onClick={loadPdf}>PDF</span>
            <div>|</div>
            <button onClick={printPdf}
                    title={'Печать'}
                    style={{backgroundImage: 'url(' + printIcon + ')'}}/>
        </div>
    );
};

PdfPrintPanel.propTypes = {
    loadPdf: PropTypes.func.isRequired,
    printPdf: PropTypes.func.isRequired,
};

export default PdfPrintPanel;