import React, {useEffect} from "react";
import styles from "./LibraryModal.module.css";
import PropTypes from "prop-types";
import {EDIT} from "../../../constants/modalTypes";
import {Tab, Tabs} from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import {connect} from "react-redux";
import {
    libraryFundListRequestStart,
} from "../../../redux/actions/library/info/fund/list";
import {
    libraryPointListRequestStart,
} from "../../../redux/actions/library/info/point/list";
import LibraryPointsTab from "./Points";
import LibraryFundsTab from "./Funds";
import {
    libraryAddFundClear
} from "../../../redux/actions/library/info/add/funds";
import {
    libraryAddPointClear
} from "../../../redux/actions/library/info/add/points";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {resetCurrentLibraryData} from "../../../redux/actions/library/info/list";
import CatalogsModalWithForm from "../../Catalogs/ModalWithForm";


const mapStateToProps = ({library}) => ({
    funds: library.info.funds,
    points: library.info.points
});

const mapDispatchToProps = {
    requestFunds: libraryFundListRequestStart,
    requestPoints: libraryPointListRequestStart,
    clearFundsAndPoints: resetCurrentLibraryData,
    libraryAddFundClear: libraryAddFundClear,
    libraryAddPointClear: libraryAddPointClear
};

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Это обязательное поле')
        .max(1024, 'Недопустимая длинна названия'),
    idclient: Yup.string()
        .required('Выберите организацию')
});

const LibraryModal = ({
                          onSubmit,
                          modalData,
                          funds,
                          requestFunds,
                          points,
                          requestPoints,
                          institutions,
                          clearFundsAndPoints,
                          libraryAddFundClear,
                          libraryAddPointClear,
                          closeModal
                      }) => {
    let id;
    const {modalType, data} = modalData;
    id = data ? data.id : null;


    useEffect(() => {
        id && requestFunds(id);
        id && requestPoints(id);
    }, [id, requestPoints, requestFunds]);

    const modalForm = useFormik({
        initialValues: data,
        validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: values => onSubmit(values)
    });

    const onCancel = () => {
        libraryAddFundClear();
        libraryAddPointClear();
        clearFundsAndPoints();
        closeModal();
    };

    return (
        <CatalogsModalWithForm
            closeModal={onCancel}
            submitHandler={modalForm.handleSubmit}
            title={`Библиотека: ${
                modalType === EDIT ? "редактирование" : "добавление"
            }`}
        >
            {data && (
                <Tabs defaultActiveKey="index" id={'libraryModal'}>
                    <Tab title="Главная" eventKey="index">
                        <div className={styles.content}>
                            <ModalInputWrapper
                                label="Название"
                                value={modalForm.values.name}
                                name="name"
                                error={modalForm.errors.name}
                                onChange={modalForm.handleChange}
                            />
                            <ModalInputWrapper
                                label="Название организации"
                                error={modalForm.errors.idclient}
                            >
                                <select
                                    name="idclient"
                                    value={modalForm.values.idclient}
                                    className={styles.select}
                                    onChange={modalForm.handleChange}
                                >
                                    <option value={""} disabled>
                                        Выберите организацию
                                    </option>
                                    {institutions.map((item, index) => (
                                        <option value={item.idclient} key={index}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </ModalInputWrapper>
                        </div>
                    </Tab>
                    <Tab title={"Пункты книговыдачи"} eventKey={"points"}>
                        <div className={styles.content}>
                            <LibraryPointsTab
                                data={points}
                                libraryId={id}
                                modalType={modalType}
                            />
                        </div>
                    </Tab>
                    <Tab title={"Фонды"} eventKey={"funds"}>
                        <div className={styles.content}>
                            <LibraryFundsTab
                                data={funds}
                                libraryId={id}
                                modalType={modalType}
                            />
                        </div>
                    </Tab>
                </Tabs>
            )}
        </CatalogsModalWithForm>
    );
};

LibraryModal.propTypes = {
    modalData: PropTypes.object,
    setModalData: PropTypes.func,
    onSubmit: PropTypes.func,
    institutions: PropTypes.array,
    closeModal: PropTypes.func.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LibraryModal);
