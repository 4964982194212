import React from 'react';
import PropTypes from 'prop-types';
import styles from './DocumentsEditBookForm.module.css';
import ModalInputWrapper from "../../Modal/InputWrapper";
import {Col, Row} from "react-bootstrap";
import Button from "../../Button";

const DocumentsEditBookForm = ({data, setData, onSave, onDelete, modalData}) => {
    const onChange = ({target: {name, value}}) => setData({...data, [name]: value});

    return (
        <div className={styles.container}>
            <Row>
                <Col md={12}>
                    <div className={styles.title}>
                        "{data.title}":
                    </div>
                    <div>
                        <ModalInputWrapper onChange={onChange}
                                           label="Количество"
                                           value={data.amount}
                                           name="amount"
                                           type="number"/>
                    </div>
                </Col>


                <Col md={12} className={"text-right"}>
                    <Button onClick={() => setData(null)}
                            style={styles.button}
                            label={"Отменить"}/>
                    <Button onClick={() => onSave(data)}
                            style={styles.button}
                            label={"Сохранить"}/>
                    <Button onClick={() => onDelete(data)}
                            style={styles.button}
                            label={"Удалить"}/>
                </Col>
            </Row>
        </div>
    );
};

DocumentsEditBookForm.propTypes = {
    data: PropTypes.object,
    setData: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func
};

export default DocumentsEditBookForm;
