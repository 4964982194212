import {
  call,
  put,
  takeLatest
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";
import { ROLE_MEMBER_SAVE_REQUEST, START } from "../../../actions/action-types";
import { memberRoleAction } from "../../../../api/roles/roles";
import { roleMembersListRequestStart } from "../../../actions/roles/membersList";
import {
  roleMemberSaveRequestError,
  roleMemberSaveRequestSuccess
} from "../../../actions/roles/saveMember";

export default function* roleMemberSaveWatcher() {
  yield takeLatest(`${ROLE_MEMBER_SAVE_REQUEST}${START}`, roleMemberSaveWorker);
}

function* roleMemberSaveWorker({ payload }) {
  const { roleId, memberId } = payload;
  try {
    const response = yield call(memberRoleAction, {
      roleId,
      memberId,
      actionType: "put"
    });
    yield put(roleMemberSaveRequestSuccess(response.data));
    yield put(roleMembersListRequestStart(roleId));
  } catch (error) {
    yield put(roleMemberSaveRequestError(error));
  }
}
