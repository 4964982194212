import {call, put, takeLatest} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {COLLECTIONS_DELETE_REQUEST, START} from "../../../actions/action-types";
import {collectionsDeleteRequestError, collectionsDeleteRequestSuccess} from "../../../actions/collections/delete";
import {deleteCollection} from "../../../../api/collections/collections";
import {collectionsListRequestStart} from "../../../actions/collections/list";
import {createNotification} from "../../../../components/notification/notification";

export default function* collectionsDeleteWatcher() {
    yield takeLatest(`${COLLECTIONS_DELETE_REQUEST}${START}`, collectionsDeleteWorker)
}

function* collectionsDeleteWorker({payload: id}) {
    try {
        const response = yield call(deleteCollection, id);
        yield put(collectionsDeleteRequestSuccess(response.data));
        yield put(collectionsListRequestStart());
        createNotification('success', 'Успешно удалено');
    } catch (error) {
        yield put(collectionsDeleteRequestError(error));
        createNotification('error', error.response.data.error);
    }
}