import React, { useEffect, useState } from 'react';
import styles from './HavkinaScene.module.css';
import { havkinaCountRequestStart } from "../../redux/actions/havkina/count";
import { setHavkinaSearchData } from "../../redux/actions/havkina/search";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import Search from "../../components/Search";
import Pagination from "../../components/Pagination";
import HavkinaTable from "../../components/Havkina/Table";
import { havkinaCalcIndexRequestStart } from "../../redux/actions/havkina/calcIndex";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";

const mapStateToProps = ({ havkina }) => ({
  list: havkina.list,
  count: havkina.count,
  searchData: havkina.search,
  index: havkina.index
});

const mapDispatchToProps = dispatch => ({
  requestList: () => dispatch(havkinaCountRequestStart()),
  setSearchData: (data) => dispatch(setHavkinaSearchData(data)),
  calculateIndex: (data) => dispatch(havkinaCalcIndexRequestStart(data))
});

const HavkinaScene = ({ count, list, requestList, searchData, setSearchData, index, calculateIndex }) => {
  useEffect(() => {
      requestList()
    },
    [requestList]);

  const setCurrentPage = (page) => {
    setSearchData({ ...searchData, page });
    requestList()
  };

  const [author, setAuthor] = useState("");

  return (
      <SceneContainer
        title={'Таблицы Хавкиной'}
      >
          <Row>
              <Col md={4}>
                  {index.length ? <div className={styles.indexContainer}>
                      Индекс: {index}
                  </div> : ""}
              </Col>
              <Col md={8} className={'mb-2'}>
                  <Search value={author} onChange={setAuthor} onSubmit={() => calculateIndex(author)}
                          placeholder={"Автор"} buttonLabel={"Вычислить"}/>
              </Col>
          </Row>
          <Search value={searchData.search} onChange={search => setSearchData({ ...searchData, search })}
                  onSubmit={requestList} placeholder={"Введите текст для поиска"} />

          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>

          <HavkinaTable data={list}/>

          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>

      </SceneContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HavkinaScene);
