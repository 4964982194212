import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { LIBRARY_TYPE_SAVE_REQUEST, START } from "../../../../actions/action-types";
import { libraryTypeSaveRequestError, libraryTypeSaveRequestSuccess } from "../../../../actions/library/types/save";
import { ADD } from "../../../../../constants/modalTypes";
import { addLibraryType, editLibraryType } from "../../../../../api/library/types";
import { libraryTypesListRequestStart } from "../../../../actions/library/types/list";

export default function* libraryTypesSaveWatcher() {
  yield takeLatest(`${LIBRARY_TYPE_SAVE_REQUEST}${START}`, libraryTypesSaveWorker)
}

function* libraryTypesSaveWorker({ payload }) {
  const { modalType: type, data } = payload;
  try {
    const response = yield call(type === ADD ? addLibraryType : editLibraryType, data);
    yield put(libraryTypeSaveRequestSuccess(response.data));
    yield put(libraryTypesListRequestStart());
  } catch (error) {
    yield put(libraryTypeSaveRequestError(error));
  }
}