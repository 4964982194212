import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import InvNumbersModal from "../../../InvNumbers/Modal";
import styles from "../../../../scenes/BookEdit/BookEditScene.module.css";
import Button from "../../../Button";
import classNames from "classnames";
import InvNumbersTable from "../../../InvNumbers/Table";
import Pagination from "../../../Pagination";
import {setInvNumbersSearchData} from "../../../../redux/actions/invNumbers/search";
import {bookInvNumbersCountRequestStart} from "../../../../redux/actions/bookEdit/invNumbersTab/count";
import {bookInvNumberSaveRequestStart} from "../../../../redux/actions/bookEdit/invNumbersTab/add";
import {bookInvNumberDeleteRequestStart} from "../../../../redux/actions/bookEdit/invNumbersTab/delete";

const mapStateToProps = ({invNumbers}) => ({
  list: invNumbers.list,
  count: invNumbers.count,
  searchData: invNumbers.search,
});

const mapDispatchToProps = {
  requestList: bookInvNumbersCountRequestStart,
  setSearchData: setInvNumbersSearchData,
  addInvNumber: bookInvNumberSaveRequestStart,
  deleteInvNumber: bookInvNumberDeleteRequestStart,
};

const DEFAULT_MODAL_DATA = { modalType: null, data: null };

const BookEditInvNumbersTab = ({
  requestList,
  setSearchData,
  addInvNumber,
  deleteInvNumber,
  list,
  count,
  searchData,
  bookId
}) => {

  const [invNumber, setInvNumber] = useState("");
  const [modalData, setModalData] = useState(DEFAULT_MODAL_DATA);
  const [selectedIds, setSelectedIds] = useState([]);


  useEffect(() => {
    requestList();
  }, [requestList]);

  const addNumber = () => {
    addInvNumber({ data: { bookId, number: invNumber }, modalType: "ADD" });
    setInvNumber("");
  };

  const onSubmitModal = () => {
    addInvNumber(modalData);
    setModalData({ modalType: null, data: null });
  };

  const onDeleteInventaryNumber = () => {
    selectedIds.length === 1 && deleteInvNumber(selectedIds[0]);
    setSelectedIds([]);
  };

  const handleSelect = id =>
    setSelectedIds(selectedIds => {
      const idIndex = selectedIds.indexOf(id);
      if (idIndex !== -1) selectedIds.splice(idIndex, 1);
      else selectedIds.push(id);
      return [...selectedIds];
    });

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page });
    requestList();
  };

  const closeModal = () => {
    setModalData(DEFAULT_MODAL_DATA);
  };

  return (
    <div>
      <InvNumbersModal
        bookEdit={true}
        isModal={true}
        setModalData={setModalData}
        modalData={modalData}
        onSubmit={onSubmitModal}
        closeModal={closeModal}
      />
      <div className={styles.invControlPanel}>
        <label>
          Инвентарный номер
          <input
            className={styles.input}
            onChange={e => setInvNumber(e.target.value)}
            value={invNumber}
          />
        </label>
        <Button
          label={"Добавить"}
          style={styles.button}
          onClick={addNumber}
        />
        <div
          className={classNames(styles.button, {
            [styles.disabled]: selectedIds.length !== 1
          })}
          onClick={onDeleteInventaryNumber}
        >
          Удалить
        </div>
      </div>

      <Pagination
        count={count}
        pageCount={searchData.pageCount}
        current={searchData.page}
        setCurrent={setCurrentPage}
      />

      <InvNumbersTable
        data={list}
        selectedIds={selectedIds}
        handleSelect={handleSelect}
        setModalData={setModalData}
      />

      <Pagination
        count={count}
        pageCount={searchData.pageCount}
        current={searchData.page}
        setCurrent={setCurrentPage}
      />
    </div>
  );
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookEditInvNumbersTab);