import { api } from "../../App";
import {SORT_DIRECTION} from "../../constants/sortDirections";


export const getInventaryBook = ({ sortBy, maxResults, paginateFrom, sortDirection, search, documenttype, from, to }) => {
  const params = {
    maxResults,
    paginateFrom,
    documenttype,
    from,
    to,
    search,
    [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: sortBy
  };

  return api.get(`/bookinventory`, { params });
};

export const getInventaryBookCount = ({ query: search, ...other }) => {
  const params = {
    ...other,
    count: true,
    search
  };
  console.log(params);
  return api.get('/bookinventory', { params });
};
