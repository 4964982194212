import React, {useEffect} from 'react';
import {Col, Row} from "react-bootstrap";
import Alphabet from "../../components/LibraryAlphabet/Alphabet";
import {alphabetRangeRequestStart} from "../../redux/actions/library/alphabet/range";
import {connect} from "react-redux";
import LibraryAlphabetSearch from "../../components/LibraryAlphabet/Search";
import SuggestionsAlphabet from "../../components/LibraryAlphabet/SuggestionsAlphabet";
import {setAlphabetSearchData} from "../../redux/actions/library/alphabet/search";
import LibraryCarousel from "../../components/LibraryAlphabet/Carousel";
import {alphabetLettersRequestStart} from "../../redux/actions/library/alphabet/letters";
import {alphabetCountRequestStart} from "../../redux/actions/library/alphabet/count";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";

const mapStateToProps = ({library}) => ({
    alphabetRange: library.alphabet.range,
    searchData: library.alphabet.search,
    items: library.alphabet.items,
    count: library.alphabet.count,
    letters: library.alphabet.letters
});

const mapDispatchToProps = dispatch => ({
    requestItems: () => dispatch(alphabetCountRequestStart()),
    requestRange: (char) => dispatch(alphabetRangeRequestStart(char)),
    setSearchData: (data) => dispatch(setAlphabetSearchData(data)),
    requestLetters: () => dispatch(alphabetLettersRequestStart())
});

const LibraryAlphabetScene = ({requestRange, alphabetRange, searchData, setSearchData, requestItems, items, count, letters, requestLetters}) => {
    useEffect(() => {
        !letters && requestLetters();
    }, [letters, requestLetters]);

    useEffect(() => {
        requestItems();
    }, [requestItems]);

    const nextPage = () => {
        const {page, pageCount} = searchData;
        if (page < pageCount) {
            setSearchData({...searchData, page: page + 1});
            requestItems()
        }
    };

    const prevPage = () => {
        const {page} = searchData;
        if (page > 1) {
            setSearchData({...searchData, page: page - 1});
            requestItems()
        }
    };

    const onSearchSubmit = () => {
        setSearchData({...searchData, letters: ""});
        requestItems()
    };

    return (
        <SceneContainer title={'Алфавитный каталог'}>
            <Row>
                <Col md={4}>
                    <Row>
                        <Col md={6}>
                            {letters &&
                            <Alphabet onSelect={(char) => requestRange(char)} items={letters}/>}
                        </Col>
                        <Col md={6}>
                            {alphabetRange && <SuggestionsAlphabet items={alphabetRange}
                                                                   onSelect={(letters) => {
                                                                       setSearchData({
                                                                           ...searchData,
                                                                           letters
                                                                       });
                                                                       requestItems()
                                                                   }}/>}
                        </Col>
                    </Row>
                </Col>
                <Col md={8}>
                    <LibraryAlphabetSearch data={searchData} setData={setSearchData} onSubmit={onSearchSubmit}
                                           count={count}/>
                    <LibraryCarousel data={items} nextPage={nextPage} prevPage={prevPage}/>
                </Col>
            </Row>
        </SceneContainer>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryAlphabetScene);
