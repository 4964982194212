import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import {rolesListRequestStart} from "../../../redux/actions/roles/list";
import CategoryPermissionsSearchForm from "../../../components/AdminCategoryPermissions/SearchForm";
import CategoryPermissionsDataTable from "../../../components/AdminPermissions/CategoryPermissons/DataTable";
import { setPermissionsByCategoryFormData } from '../../../redux/actions/permissions/byCategories/form';
import { permissionsByCategoryCountRequestStart } from '../../../redux/actions/permissions/byCategories/count';
import { setPermissionsByCategorySearchData } from '../../../redux/actions/permissions/byCategories/search';
import { permissionsByCategoryEditRequestStart } from '../../../redux/actions/permissions/byCategories/edit';
import { permissionsByCategoryAddRequestStart } from '../../../redux/actions/permissions/byCategories/add';
import Pagination from "../../../components/Pagination";
import SpinnerOuter from "../../../components/UtulityComponents/SpinnerOuter";
import {SORT_DIRECTION} from "../../../constants/sortDirections";


const mapStateToProps = (state) => ({
  roles: state.roles.list,
  list: state.accessCategories.list,
  loader: state.accessCategories.loader,
  formData: state.accessCategories.form,
  count: state.accessCategories.count,
  searchData: state.accessCategories.search,
});

const mapDispatchToProps = {
  requestList: permissionsByCategoryCountRequestStart,
  requestRoles: rolesListRequestStart,
  setFormData: setPermissionsByCategoryFormData,
  setSearchData: setPermissionsByCategorySearchData,
  setAccessStatus: permissionsByCategoryEditRequestStart,
  addCategoryToRole: permissionsByCategoryAddRequestStart,
};

const AdminCategoriesPermissionsScene = ({
   roles,
   list,
   requestRoles,
   formData,
   setFormData,
   requestList,
   count,
   searchData,
   setSearchData,
   loader,
   setAccessStatus,
   addCategoryToRole
}) => {

  useEffect(() => {
    !roles.length && requestRoles();
  }, [roles, requestRoles]);

  const startSearch = () => {
    requestList();
  };

  const setCurrentPage = (page) => {
    setSearchData({ ...searchData, page });
    requestList();
  };
  
  const setSort = (sortBy) => {
    let sortDir = searchData.sortDirection === SORT_DIRECTION.ASC ?
      SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;

    setSearchData({
      ...searchData,
      sortDirection: sortDir,
      'orderBy': sortDir === SORT_DIRECTION.ASC ? sortBy : null,
      'orderByD': sortDir === SORT_DIRECTION.DESC ? sortBy : null,
      page: 1
    });
    requestList();
  };


  const onChangeAccessStatus = (event, category) => {
    const params = {
      role: formData.roleid,
      access: event.target.value,
      category: category.category,
      categoryId: category.id
    };

    category.id ?
      setAccessStatus(params)
      :
      addCategoryToRole(params)
  };

  return (
    <SceneContainer title={'Администрирование / Настройка прав доступа для категорий'}>
      <CategoryPermissionsSearchForm
        onSubmit={startSearch}
        formData={formData}
        setFormData={setFormData}
        roles={roles}
      />

      {loader ?
        <SpinnerOuter isLoading={loader}/>
        :
        <>
          {!!list.length &&
            <div>
              <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>

              <CategoryPermissionsDataTable data={list} setSort={setSort} sortBy={searchData.sortBy} onChange={onChangeAccessStatus}/>

              <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
            </div>
          }
        </>
      }

    </SceneContainer>
  );
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCategoriesPermissionsScene);