import {ELECTRONIC_BOOK_JSON_SAVE, START, SUCCESS, ERROR} from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const electronicBookJsonSaveStart = (file) => ({
    type: `${ELECTRONIC_BOOK_JSON_SAVE}${START}`,
    payload: file
});

export const electronicBookJsonSaveSuccess = (data) => {
    createNotification('success', 'Библиографическая запись успешно создана');
    return {
        type: `${ELECTRONIC_BOOK_JSON_SAVE}${SUCCESS}`,
        payload: data
    };
};

export const electronicBookJsonSaveError = (data) => {
    createNotification('error', 'Ошибка создания библиографической записи');
    return {
        type: `${ELECTRONIC_BOOK_JSON_SAVE}${ERROR}`,
        payload: data
    };
};