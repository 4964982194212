import { RESULTS_PER_PAGE } from "../../../../constants/pagination";
import {SET_INV_NUMBERS_SEARCH_DATA, SET_LOCATION_CHANGE} from "../../../actions/action-types";

const initialState = {
  page: 1,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0
};

export const invNumbersSearchReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_INV_NUMBERS_SEARCH_DATA:
      return action.payload;

    case SET_LOCATION_CHANGE:
      return initialState;

    default: return state;
  }
};