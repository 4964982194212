import React, {useState, useEffect} from 'react';
import {newBooksCountRequestStart} from "../../redux/actions/newBooksOrder/count/count";
import {setNewBooksOrderSearchData} from "../../redux/actions/newBooksOrder/search/search";
import {documentSaveRequestStart} from "../../redux/actions/newBooksOrder/orders/save";
import {documentDeleteRequestStart} from "../../redux/actions/newBooksOrder/orders/delete";
import {connect} from "react-redux";
import Pagination from "../../components/Pagination";
import BooksOrderTable from "../../components/BooksOrder/Table";
import NewBooksOrderModal from "../../components/newBooksOrder/Modal";
import BooksOrderSearchForm from "../../components/BooksOrder/SearchForm";
import {documentTypesAllRequestStart} from "../../redux/actions/documents/types/all";
import {ADD, EDIT} from "../../constants/modalTypes";
import {curriculumListRequestStart} from "../../redux/actions/curriculum/list";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";

const mapStateToProps = ({newBooksOrder, curriculum}) => ({
    list: newBooksOrder.list,
    searchData: newBooksOrder.search,
    latestDocument: newBooksOrder.latest,
    curriculumCourses: curriculum.data,
    count: newBooksOrder.count,
});

const mapDispatchToProps = {
    requestList: newBooksCountRequestStart,
    requestAllTypes: documentTypesAllRequestStart,
    setSearchData: setNewBooksOrderSearchData,
    saveDocument: documentSaveRequestStart,
    deleteDocument: documentDeleteRequestStart,
    requestCurriculumCourses: curriculumListRequestStart,
};


const NewBooksOrderScene = ({
                                count, list, requestList, searchData, setSearchData,
                                saveDocument, deleteDocument, allTypes, requestAllTypes,
                                latestDocument, curriculumCourses, requestCurriculumCourses
                            }) => {
    const [modalData, setModalData] = useState({modalType: null, data: null});
    const [selectedId, setSelectedId] = useState(null);


    useEffect(() => {
        requestList();
        requestAllTypes();
        requestCurriculumCourses();
    }, [requestList, requestAllTypes]);

    useEffect(() => {
        setModalData(modalData =>
            modalData.modalType === ADD ? {
                modalType: EDIT,
                data: latestDocument
            } : modalData)
    }, [latestDocument]);

    const setCurrentPage = (page) => {
        setSearchData({...searchData, page});
        requestList()
    };

    const setSort = (sortBy) => {
        setSearchData({...searchData, sortBy, page: 1});
        requestList();
    };

    const handleSelect = (id) => {
        setSelectedId(selectedId === id ? null : id);
    };

    const onSubmitModal = () => {
        saveDocument(modalData.data);
    };

    const onDelete = () => {
        selectedId && deleteDocument(selectedId);
    };

    const PaginationComponent = () =>
        <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page}
                                                  setCurrent={setCurrentPage}/>;

    return (
        <SceneContainer title={"Заказ на поступление литературы"}>
            <BooksOrderSearchForm types={allTypes} data={searchData} setSearchData={setSearchData}
                                  startSearch={requestList}/>

            <PaginationComponent/>
            <NewBooksOrderModal types={allTypes} onSubmit={onSubmitModal} setModalData={setModalData}
                                modalData={modalData} courses={curriculumCourses}/>
            <BooksOrderTable data={list} selectedId={selectedId} handleSelect={handleSelect}
                             setModal={setModalData}
                             sortBy={searchData.sortBy} setSort={setSort} onDelete={onDelete}/>
            <PaginationComponent/>
        </SceneContainer>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewBooksOrderScene);
