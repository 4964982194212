import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { COLLECTIONS_SAVE_REQUEST, START } from "../../../actions/action-types";
import { collectionsSaveRequestError, collectionsSaveRequestSuccess } from "../../../actions/collections/save";
import { ADD } from "../../../../constants/modalTypes";
import { addCollection, editCollection } from "../../../../api/collections/collections";
import { collectionsListRequestStart } from "../../../actions/collections/list";

export default function* collectionsSaveWatcher() {
  yield takeLatest(`${COLLECTIONS_SAVE_REQUEST}${START}`, collectionsSaveWorker)
}

function* collectionsSaveWorker({payload: { data, modalType: type }, callback}) {
  try {
    const response = yield call(type === ADD ? addCollection : editCollection, data);
    yield put(collectionsSaveRequestSuccess(response.data));
    yield call(callback);
    yield put(collectionsListRequestStart());
  } catch(error) {
    yield put(collectionsSaveRequestError(error));
  }
}