import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { setCoversFormData } from "../../../redux/actions/covers/form";
import { coversCountRequestStart } from "../../../redux/actions/covers/count";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import styles from './InvNumbersModal.module.css';
import Button from "../../Button";
import BookSearchForm from "../../Covers/SearchForm";
import ModalInputWrapper from "../../Modal/InputWrapper";
import { ADD, EDIT } from "../../../constants/modalTypes";
import InvNumbersModalSearchItem from "./SearchItem";
import ModalWindow from "../../ModalWindow";
import classNames from "classnames";

const mapStateToProps = state => ({
  searchResults: state.covers.list,
  formData: state.covers.form,
});

const mapDispatchToProps = {
  setFormData: setCoversFormData,
  startSearch: coversCountRequestStart,
};

const InvNumbersModal = ({ bookEdit, isModal, searchResults, formData, setFormData, startSearch, modalData, setModalData, onSubmit, closeModal }) => {

  const { modalType, data } = modalData;
  const isVisible = !!modalType;
  const isADD = (modalType === ADD);
  const SCANNER_SOCKET_PORT = 'ws://127.0.0.1:18988/bookreader';
  let connection;

  const [selectedBook, setSelectedBook] = useState(null);
  const [error, setError] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const anotherRef = useRef(null);


  if (modalType === ADD && !data) {
    setModalData({
      modalType,
      data: {
        bookId: "",
        number: ""
      }
    })
  }

  useEffect(() => {
    setError(false);
  }, [data]);

  try {
    connection = new WebSocket(SCANNER_SOCKET_PORT);
    //to receive the message from server
    connection.onmessage = (e) => {
      let eventData = JSON.parse(e.data);
      if(eventData["booknumber"]) {
        onReadBook(eventData["booknumber"]);
      }
    };
  } catch (e) {
    console.log(e);
  }


  const onBookChange = (book) => {
    setSelectedBook(book);
    setModalData({ ...modalData, data: { ...data, bookId: book.bookid } });
    anotherRef.current.focus();
  };

  const checkedOnSubmit = () => {
    onSubmit(modalData)
  };


  const onClose = () => {
    connection.close();
    setModalData({ modalType: null, data: null });
  };

  const onReadBook = (bookNumber) => {
    setModalData({
      ...modalData,
      data: { ...data, rfidcode: bookNumber }
    });
    connection.close();
  };


  return (
      <ModalWindow
          title={`Инвентарный номер: ${modalType === EDIT ? "редактирование" : "добавление"}`}
          isOpen={isVisible}
          onRequestClose={closeModal}
      >
        {data &&
        <Row>
          <Col md={12}>
            <ModalInputWrapper
                value={selectedBook ? selectedBook.title : (data.title ? data.title : "")}
                onChange={() => {}}
                label={"Выбранная книга"}
            />
            <ModalInputWrapper
                value={data.number}
                onChange={({ target }) => setModalData({
                  ...modalData,
                  data: { ...data, number: target.value }
                })}
                inputRef={anotherRef}
                label={"Инвентарный номер"}
            />
            <ModalInputWrapper
                value={data.rfidcode}
                onChange={({ target }) => setModalData({
                  ...modalData,
                  data: { ...data, rfidcode: target.value }
                })}
                label={"BiblioTag"}
            />
          </Col>
          <Col md={6}>
            {!bookEdit && <div className={styles.sectionTitle}>
              Поиск книги
            </div>}
          </Col>
          <Col md={6} className="text-right">
            <div className={styles.sectionTitle}>
              {!bookEdit && (modalType === EDIT) && <Button label={searchOpen ? "Закрыть" : "Открыть"} onClick={() => setSearchOpen(!searchOpen)}/>}
            </div>
          </Col>
          {(isADD || searchOpen) && <>
            <Col md={12}>
              <BookSearchForm isModal={isModal} data={formData} setData={setFormData}>
                <Row>
                  <Col md={{ span: 3, offset: 9 }} className={"text-right"}>
                    <Button onClick={startSearch} label={"Найти"}/>
                  </Col>
                </Row>
              </BookSearchForm>
            </Col>
            <Col md={12}>
              <div className={styles.sectionTitle}>
                Результаты поиска
              </div>
            </Col>
            <Col md={12}>
              <div className={styles.searchResultsContainer}>
                {searchResults.map((item, index) => (
                    <InvNumbersModalSearchItem data={item} key={index}
                                               onClick={onBookChange}
                                               selected={selectedBook && (item.bookid === selectedBook.bookid)}/>
                ))}
              </div>
            </Col>
          </>}
          <Col md={12} className={styles.footerModal}>
            <Col md={6}>
              {error &&
              <div className={styles.errorMessage}>
                Вы должны заполнить хотя бы одно поле!
              </div>}
            </Col>
            <Col md={6}>
              <div className={styles.bottomSection}>
                <Button label="Сохранить" onClick={checkedOnSubmit} colorType="green" style={"mr-2"}/>
                <Button label="Отмена" onClick={onClose} colorType="red"/>
              </div>
            </Col>
          </Col>
        </Row>}
      </ModalWindow>
  );
};

InvNumbersModal.propTypes = {
  setModalData: PropTypes.func,
  modalData: PropTypes.object,
  onSubmit: PropTypes.func,
  closeModal: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(InvNumbersModal);