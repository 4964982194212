import React from 'react';
import AggregationReportScene from '../Reports/Aggregation';
import BookScanReportScene from '../Reports/BookScan';
import Button from "../../components/Button";
import CoversScanReportScene from "../Reports/CoversScan";
import LibrariesDiaryReportScene from "../Reports/LibrariesDiary";
import BookGivingStatsReportScene from "../Reports/BookGivingStats";
import AccountingJournalReportScene from "../Reports/AccountingJournal";
import ElectronicResourcesUsageReport from "../Reports/ElectronicResourcesUsage";
import StaticInformationReportScene from "../Reports/StaticInformation";


const ReportContentScene = ({match, history}) => {
    const id = match.params.id;

    // useEffect(() => {
    //   id && requestReport(id);
    // }, [id, requestReport]);

    const goBack = () => {
        history.push('/reports');
    };

    const ReportSceneSwitch = () => {
        switch (id) {
            case "1":
                return <AggregationReportScene ownId={id}/>;
            case "11":
                return <LibrariesDiaryReportScene ownId={id}/>;
            case "12":
                return <AccountingJournalReportScene ownId={id}/>;
            case "13":
                return <ElectronicResourcesUsageReport ownId={id}/>;
            case "14":
                return <BookGivingStatsReportScene ownId={id}/>;
            case "15":
                return <BookScanReportScene ownId={id}/>;
            case "16":
                return <CoversScanReportScene ownId={id}/>;
            case "19":
                return <StaticInformationReportScene owmId={id}/>;

            default:
                return <div>Такого отчета еще не существует</div>;
        }
    };

    return (
        <div>
            <div className={'mt-2'}>
                <Button label="← Назад" colorType="dark-blue" onClick={goBack}/>
            </div>

            <ReportSceneSwitch/>
        </div>
    );
};

export default ReportContentScene;
