import React, {useEffect, useState} from 'react';
import styles from "../../DocumentsModal.module.css";
import SearchableSelect from "../../../../Select";
import {
    authorsToOptions,
    optionsToAuthors,
    optionsToPublishers,
    publishersToOptions
} from "../../../../../utils/toSelectOption";
import {connect} from "react-redux";

import {setAuthorsSearchData} from "../../../../../redux/actions/authors/searchData";
import {setPublishersSearchData} from "../../../../../redux/actions/publishers/searchData";
import {authorsCountRequestStart} from "../../../../../redux/actions/authors/count";
import {documentBookSaveRequestStart} from "../../../../../redux/actions/documents/books/save";

import ModalWindow from "../../../../ModalWindow";
import ModalInputWrapper from "../../../../Modal/InputWrapper";
import {Col} from "react-bootstrap";
import Button from "../../../../Button";
import {transformBookData} from "../../../../../utils/transformBookData";
import {createNotification} from "../../../../notification/notification";
import {api} from "../../../../../App";
import {bookSaveRequestStart} from "../../../../../redux/actions/books/save";
import {publishersCountRequestStart} from "../../../../../redux/actions/publishers/count";

const mapStateToProps = ({authors, publishers}) => ({
    authorsList: authors.list,
    authorsSearch: authors.search,
    publishersList: publishers.list
});

const mapDispatchToProps = {
    requestAuthors: authorsCountRequestStart,
    setAuthorSearch: setAuthorsSearchData,
    setPublishersSearch: setPublishersSearchData,
    saveBook: documentBookSaveRequestStart,
    saveData: bookSaveRequestStart,
    requestPublishers: publishersCountRequestStart,
};

const CreateNewLibraryItemModal = ({
                                       authorsList,
                                       authorsSearch,
                                       publishersSearch,
                                       publishersList,
                                       showLibraryItemModal,
                                       setShowLibraryItemModal,
                                       requestPublishers,
                                       setPublishersSearch,
                                       requestAuthors,
                                       setAuthorSearch,
                                       saveData,
                                       bookData,
                                       onSelectFromSearch
                                   }) => {

    useEffect(() => {
        setData(bookData);
    }, [bookData]);

    const onSave = withoutPopup => {
        if (data !== null) {
            if (!!data.title) {
                saveData(transformBookData(data), withoutPopup);
                onSelectFromSearch({bookid: data.bookid, title: data.title});
                setShowLibraryItemModal(false);
            } else {
                createNotification("error", 'Пожалуйста, заполните поле "Название" ');
            }
        }
    };

    const checkedOnSave = () => {
        const checkRequestBody = {
            title: data.title,
            year: Number(data.year) || 0,
            pageNum: Number(data.pageNum) || 0,
            authorIds: data.authors.map(item => item.id),
            publisherIds: data.publishers.map(item => item.id),
            extractreason: Number(data.extractreason) || 0,
            subscriptionenddate: data.subscriptionenddate
        };
        api.post(`/bookduplicate`, checkRequestBody)
            .then(response => {
                if (
                    (response.data.length === 1 &&
                        Number(response.data[0].id) === Number(data.id)) ||
                    response.data.length === 0
                ) {
                    onSave();
                } else {
                    createNotification(
                        "error",
                        "Такая книга уже существует. Дубликаты запрещены."
                    );
                }
            })
    };

    const onChange = ({target: {value, name}}) => {
        setData({...data, [name]: value});
    };

    const onAuthorInputChange = name => {
        setAuthorSearch({...authorsSearch, query: name});
        requestAuthors();
    };

    const onPublisherInputChange = name => {
        setPublishersSearch({...publishersSearch, query: name});
        requestPublishers();
    };

    const [data, setData] = useState(bookData);

    return (data ?
            <ModalWindow
                title={`Добавить БЗ`}
                isOpen={showLibraryItemModal}
                onRequestClose={() => setShowLibraryItemModal(false)}
            >
                <div className={`${styles.saveModal} ${styles.modalAddBZ}`}>
                    <Col md={12}>
                        <ModalInputWrapper
                            label="Издательства"
                        >
                            <SearchableSelect
                                placeholder="Поиск"
                                publishers={true}
                                onInputChange={onPublisherInputChange}
                                options={publishersToOptions(publishersList)}
                                isMulti={true}
                                onChange={value =>
                                    setData({...data, publishers: optionsToPublishers(value)})
                                }
                                value={publishersToOptions(data.publishers)}
                            />
                        </ModalInputWrapper>
                    </Col>
                    <Col md={12}>
                        <ModalInputWrapper label="Авторы">
                            <SearchableSelect
                                authosr={true}
                                data={data}
                                setData={setData}
                                placeholder="Поиск"
                                onInputChange={onAuthorInputChange}
                                options={authorsToOptions(authorsList)}
                                isMulti={true}
                                onChange={value =>
                                    setData({...data, authors: optionsToAuthors(value)})
                                }
                                value={authorsToOptions(data.authors)}
                            />
                        </ModalInputWrapper>
                    </Col>
                    <Col md={12}>
                        <ModalInputWrapper
                            label="Заглавие"
                            value={data.title}
                            name="title"
                            onChange={onChange}
                        />
                    </Col>
                    <Col md={12}>
                        <ModalInputWrapper
                            label="Год издания"
                            value={data.year}
                            name={"year"}
                            onChange={onChange}
                        />
                    </Col>

                    <div className={styles.addBZbutton}>
                        <Button
                            onClick={checkedOnSave}
                            label={"Сохранить"}
                            style={'ml-2'}
                            colorType={'green'}
                        />
                    </div>
                </div>
            </ModalWindow>
            : null
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateNewLibraryItemModal);