import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
  BOOK_ORDER_LIST_REQUEST,
  BOOK_ORDER_BOOKS_DELETE_REQUEST,
  BOOK_ORDER_BOOKS_EDIT_REQUEST,
  START
} from "../../../actions/action-types";
import {
  getDocumentBooksList,
  deleteBookToDocument,
  editBookToDocument,
  addBookToDocument
} from "../../../../api/booksOrder/bookToDocument";

import {
  documentBookListRequestStart,
  documentBookListRequestSuccess,
  documentBookListRequestError,
} from "../../../actions/booksOrder/list/list";
import {
  orderBooksDeleteRequestSuccess,
  orderBooksDeleteRequestError
} from "../../../actions/booksOrder/orderItem/delete";
import {
  orderBooksEditRequestSuccess,
  orderBooksEditRequestError
} from "../../../actions/booksOrder/orderItem/edit";
import {documentListRequestStart} from "../../../actions/booksOrder/documents/list";
import {createNotification} from "../../../../components/notification/notification";

export default function* documentBooksWatcher() {
  yield takeLatest(`${BOOK_ORDER_LIST_REQUEST}${START}`, documentBooksListWorker);
  yield takeLatest(`${BOOK_ORDER_BOOKS_DELETE_REQUEST}${START}`, documentBooksDeleteWorker);
  yield takeLatest(`${BOOK_ORDER_BOOKS_EDIT_REQUEST}${START}`, documentBooksEditWorker)
}

function* documentBooksListWorker({ payload: documentId }) {
  try {
    const response = yield call(getDocumentBooksList, documentId);
    yield put(documentBookListRequestSuccess(response.data));
  } catch (error) {
    yield put(documentBookListRequestError(error));
  }
}

function* documentBooksDeleteWorker({ payload: data }) {
  try {
    const response = yield call(deleteBookToDocument, data.id);
    yield put(orderBooksDeleteRequestSuccess(response.data));
    yield call(createNotification,'success', 'Успешно удалено');
    yield put(documentBookListRequestStart(data.documentid));
  } catch (error) {
    yield put(orderBooksDeleteRequestError(error));
  }
}

function* documentBooksEditWorker({payload: data}) {
  try {
    const response = yield call(data.bookData.id ? editBookToDocument: addBookToDocument, data.bookData);
    yield put(orderBooksEditRequestSuccess(response.data));
    yield put(documentBookListRequestStart(data.document));
    yield put(documentListRequestStart());
  } catch (error) {
    yield put(orderBooksEditRequestError(error));
  }
}
