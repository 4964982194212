import {combineReducers} from "redux";
import {schedulerMetadataReducer} from "./Metadata/schedulerMetadataReducer";
import {schedulerEditMetadataReducer} from "./EditMetadata/schedulerEditMetadataReducer";
import {schedulerCategoryReducer} from "./Category/schedulerCategoryReducer";


export const schedulerReducer = combineReducers({
    editMetadata: schedulerEditMetadataReducer,
    metadata: schedulerMetadataReducer,
    categories: schedulerCategoryReducer
});