import {
  call,
  put,
  takeLatest,
  takeEvery,
  select
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";
import { getCategoryList } from "../../../api/categories/categories";
import {CATEGORIES_LIST_REQUEST, START} from "../../actions/action-types";
import {
  categoriesListRequestSuccess,
  categoriesListRequestError
} from '../../actions/categories/list';

export default function* categoriesWatcher() {
  yield takeLatest(`${CATEGORIES_LIST_REQUEST}${START}`, categoriesListWorker);
}


function* categoriesListWorker () {
    const searchData = yield select(({ categories }) => categories.search);
    try {
        const response = yield call(getCategoryList, searchData.query);
        yield put(categoriesListRequestSuccess(response.data));
    } catch(error) {
        yield put(categoriesListRequestError(error));
    }
}