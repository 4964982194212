import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { START, USERS_GET_PHOTO_REQUEST } from "../../../actions/action-types";
import { getUserPhoto } from "../../../../api/adminUsers/users";
import { usersGetPhotoRequestError, usersGetPhotoRequestSuccess } from "../../../actions/adminUsers/photo/getPhoto";

export default function* getUserPhotoWatcher() {
  yield takeLatest(`${USERS_GET_PHOTO_REQUEST}${START}`, getUserPhotoWorker)
}

function* getUserPhotoWorker({payload: mid}) {
  try {
    const response = yield call(getUserPhoto, mid);
    const url = `data:image/jpeg;base64, ${Buffer.from(response.data, 'binary')
      .toString('base64')}`;
    yield put(usersGetPhotoRequestSuccess(url));
  } catch (error) {
    yield put(usersGetPhotoRequestError(error));
  }
}