import { api } from "../../App";

export const getFundAccounting = ({ from, to, libraryid }) => {
  const params = {
    libraryid,
    fundid: 0,
    from,
    to
  };

  return api.get(`/fund_accounting`, {params});
};
