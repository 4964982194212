import {
  SUCCESS,
  START,
  ERROR, SCHEDULER_METADATA_COUNT_REQUEST, SCHEDULER_METADATA_LIST_REQUEST
} from "../../../../actions/action-types";

const initialState = false;

export const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${SCHEDULER_METADATA_COUNT_REQUEST}${START}`:
      return true;

    case `${SCHEDULER_METADATA_LIST_REQUEST}${SUCCESS}`:
      return false;

    case `${SCHEDULER_METADATA_LIST_REQUEST}${ERROR}`:
      return false;

    case `${SCHEDULER_METADATA_COUNT_REQUEST}${ERROR}`:
      return false;

    default: return state;
  }
};
