import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {Col, Row} from "react-bootstrap";
import InputWrapper from "../../../../Modal/InputWrapper";
import Button from "../../../../Button";

import styles from './ScheduleMaker.module.css';


const formatNumberToClockValue = (val) => {
    return (val + '').length === 1 ? '0' + val : '' + val
};

const ScheduleMaker = ({ scheduleData, setScheduleData }) => {
    const [ hoursSelect, setHoursSelect ] = useState(formatNumberToClockValue(0));
    const [ minutesSelect, setMinutesSelect ] = useState(formatNumberToClockValue(0));


    const deleteScheduleItem = (index) => {
        const updatedScheduleArray = [...scheduleData];
        updatedScheduleArray.splice(index, 1);
        setScheduleData(updatedScheduleArray);
    };

    const addScheduleItem = () => {
        const updatedScheduleArray = scheduleData ? [...scheduleData] : [];
        updatedScheduleArray.push(hoursSelect + ':' + minutesSelect);
        setScheduleData(updatedScheduleArray);
    };

    console.log(hoursSelect, minutesSelect)


    return (
        <div>
            {Array.isArray(scheduleData) ?
                scheduleData.map((item, index) =>
                    <div className={styles.timeValue}>
                        <button onClick={() => deleteScheduleItem(index)}
                                className={styles.deleteItem}
                                title={'Удалить время'}
                        />
                        { item }
                    </div>
                )
                :
                <div className={styles.noSchedule}>Добавьте время для расписания</div>
            }


            <Row className={'mt-2'}>
                <Col sm={6} md={2}>
                    <InputWrapper label={'часов'}>
                        <select name="hours" value={hoursSelect} onChange={e => setHoursSelect(e.target.value)}>
                            {new Array(24).fill('').map((item, index) =>
                                <option value={formatNumberToClockValue(index)}>
                                    { formatNumberToClockValue(index) }
                                </option>
                            )}
                        </select>
                    </InputWrapper>
                </Col>
                <Col sm={6} md={2}>
                    <InputWrapper label={'минут'}>
                        <select name="minutes" value={minutesSelect} onChange={e => setMinutesSelect(e.target.value)}>
                            {new Array(60).fill('').map((item, index) =>
                                <option value={formatNumberToClockValue(index)}>
                                    { formatNumberToClockValue(index) }
                                </option>
                            )}
                        </select>
                    </InputWrapper>
                </Col>
                <Col sm={'auto'}>
                    <InputWrapper label={''}>
                        <Button label={'Добавить время'} onClick={addScheduleItem}/>
                    </InputWrapper>
                </Col>
            </Row>
        </div>
    );
};

ScheduleMaker.propTypes = {
    scheduleData: PropTypes.array,
    setScheduleData: PropTypes.func.isRequired,
};

export default ScheduleMaker;