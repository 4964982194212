import React from "react";
import {Col, Collapse, Row} from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from './Collapse.module.css';
import Image from "../Img"

const CollapseBlock = ({ collapseId, books, index, mergeBooks }) => {
  const open = collapseId === index;

  return (
    <Collapse in={open}>
      <div className={'collapse-content'}>
        <div className={styles.mergeButton} onClick={() => mergeBooks(books)}>
          Объеденить дубликаты
        </div>
        {books.map(item => (
          <Row key={item.id}>
            <Col md={"auto"}>
              <Image item={item} />
            </Col>
            <Col>
              <div className={styles.title}>{item.title}</div>
              <Link
                to={`/book/${item.id}/edit`}
                target="_blank"
                className={styles.button}
              >
                Редактирование
              </Link>
            </Col>
          </Row>
        ))}
      </div>
    </Collapse>
  );
};

export default CollapseBlock;
