import {
    INVENTORY_COVERS_LIST_REQUEST,
    SUCCESS
} from "../../../actions/action-types";

const initialState = [];

export const coversListReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${INVENTORY_COVERS_LIST_REQUEST}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }
};