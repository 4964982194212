import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
  INV_NUMBER_CREATE_REQUEST,
  START,
} from "../../actions/action-types";
import {
  addInvNumber
} from "../../../api/invNumbers/invNumbers";
import {
  createInvNumberRequestSuccess,
  createInvNumberRequestError
} from "../../actions/bookLoan/create";
import { availableBooksActions } from '../../factory/availableBooksModalReduxData';


export default function* bookLoanWatcher() {
  yield takeLatest(`${INV_NUMBER_CREATE_REQUEST}${START}`, createInvNumberWorker);
}

function* createInvNumberWorker({ payload: data }) {
  try {
    const response = yield call(addInvNumber, data.bookId, data.number);

    yield put(availableBooksActions.moveBook({
      typeFrom: 1,
      placeFrom: null,
      placeTo: data.userId,
      typeTo: 2,
      returndate: data.returnDate,
      instanceId: response.data.id,
      mid: data.userId,
    }));
    yield put(createInvNumberRequestSuccess(response.data));
  } catch (e) {
    yield put(createInvNumberRequestError(e));
  }
}
