import {INVENTORY_COVERS_LIST_REQUEST, SUCCESS, START, SET_LOCATION_CHANGE} from "../../../actions/action-types";

const initialState = false;

export const coversLoadSuccessReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${INVENTORY_COVERS_LIST_REQUEST}${START}`:
            return false;

        case `${INVENTORY_COVERS_LIST_REQUEST}${SUCCESS}`:
            return true;

        default:
            return state;
    }
};
