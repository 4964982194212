import React from 'react';
import PropTypes from 'prop-types';
import CategoriesList from "../List";

const CategoriesListItem = ({ data }) => (
  <div style={{marginLeft: 20}}>
    {!!data.subcategories.length ?
      <details>
        <summary>{data.name}</summary>
        <CategoriesList list={data.subcategories}/>
      </details>
      :
      <>{data.name}</>
    }
  </div>
);

CategoriesListItem.propTypes = {
  data: PropTypes.object,
};

export default CategoriesListItem;