import React, { useState } from 'react';
import { invNumbersCountRequestStart } from "../../../redux/actions/invNumbers/count";
import { setInvNumbersFormData } from "../../../redux/actions/invNumbers/form";
import { setInvNumbersSearchData } from "../../../redux/actions/invNumbers/search";
import { Col, Row } from "react-bootstrap";
import Pagination from "../../../components/Pagination";
import BookSearchForm from "../../../components/Covers/SearchForm";
import { connect } from "react-redux";
import InvNumbersTable from "../../../components/InvNumbers/Table";
import InvSearchFormControls from "../../../components/InvNumbers/SearchFormControls";
import { INV_NUMBERS_FORM_STRUCTURE } from "../../../constants/invNumbers";
import InvNumbersModal from "../../../components/InvNumbers/Modal";
import { invNumberSaveRequestStart } from "../../../redux/actions/invNumbers/add";
import {
  invNumberDeleteRequestStart,
} from "../../../redux/actions/invNumbers/delete";
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import SpinnerOuter from "../../../components/UtulityComponents/SpinnerOuter";

const mapStateToProps = ({ invNumbers }) => ({
  list: invNumbers.list,
  count: invNumbers.count,
  loader: invNumbers.loader,
  formData: invNumbers.form,
  searchData: invNumbers.search,
});

const mapDispatchToProps = {
  requestList: invNumbersCountRequestStart,
  setFormData: setInvNumbersFormData,
  setSearchData: setInvNumbersSearchData,
  addInvNumber: invNumberSaveRequestStart,
  deleteInvNumber: invNumberDeleteRequestStart,
};

const initialModalData = { modalType: null, data: null };

const InvNumbersScene = ({ count, list, loader, requestList, formData, setFormData, searchData, setSearchData, addInvNumber, deleteInvNumber }) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [modalData, setModalData] = useState(initialModalData);



  const setCurrentPage = (page) => {
    setSearchData({ ...searchData, page });
    requestList()
  };

  const handleSelect = (id) =>
      setSelectedIds((selectedIds) => {
        const idIndex = selectedIds.indexOf(id);
        if (idIndex !== -1)
          selectedIds.splice(idIndex, 1);
        else
          selectedIds.push(id);
        return [...selectedIds];
      });

  const handleFormClear = () => {
    setFormData(INV_NUMBERS_FORM_STRUCTURE);
  };

  const onSubmitModal = (modalData) => {
    addInvNumber(modalData);
    setModalData({ modalType: null, data: null });
  };

  const onDelete = () => {
    selectedIds.length === 1 && deleteInvNumber(selectedIds[0]);
    setSelectedIds([]);
  };

  const closeModal = () => {
    setModalData(initialModalData);
  };

  return (
      <SceneContainer title={'Справочники / Инвентарные номера'}>
          {modalData.data &&
            <InvNumbersModal isModal={true} setModalData={setModalData}
                           modalData={modalData} onSubmit={onSubmitModal} closeModal={closeModal}/>
          }


          <BookSearchForm data={formData} setData={setFormData} hideInstitutions={true}>
            <InvSearchFormControls onSubmit={requestList} onDelete={onDelete} onClear={handleFormClear}
                                   selectedIds={selectedIds} setModal={setModalData}/>
          </BookSearchForm>
          {loader ?
              <SpinnerOuter isLoading={loader}/>
              :
              <>
                <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
                <InvNumbersTable data={list} selectedIds={selectedIds} setModalData={setModalData} handleSelect={handleSelect}/>
                <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
              </>
          }
      </SceneContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InvNumbersScene);