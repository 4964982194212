import {
    RESET_COVERS_FORM_DATA, RESET_INVENTORY_COVERS_FORM_DATA,
    SET_COVERS_FORM_DATA,
    SET_INVENTORY_COVERS_FORM_DATA
} from "../../../actions/action-types";


const initialState = {
    title: "",
};

export const coversFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INVENTORY_COVERS_FORM_DATA:
            return action.payload;

        case RESET_INVENTORY_COVERS_FORM_DATA:
            return initialState;

        default:
            return state;
    }
};
