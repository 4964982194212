import React from 'react';
import PropTypes from 'prop-types';
import ModalWindow from "../../../ModalWindow";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import Button from "../../../Button";

const EditCodesModal = ({ isOpen, onRequestClose, modalData, setModalData, onSubmit }) => {
  const onChange = (val) => {
    setModalData({...modalData, value: val});
  };

  return (
    <ModalWindow
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={'Редактирование знака'}
      width={'500px'}
      height={'300px'}
    >
      <div>
        <ModalInputWrapper
          label="Значение"
          value={modalData['value']}
          name={"authorSign"}
          onChange={({ target: { value } }) => onChange(value)}
          labelSpan={2}
          inputSpan={10}
        />
        <div className='text-right'>
          <Button
            label={'Сохранить'}
            onClick={() => {
              onSubmit();
              onRequestClose();
            }}
          />
        </div>
      </div>
    </ModalWindow>
  );
};

EditCodesModal.propTypes = {
  onRequestClose: PropTypes.func,
  isOpen: PropTypes.bool
};

export default EditCodesModal;