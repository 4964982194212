import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import {testsCountRequestStart} from "../../redux/actions/tests/count";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import TestsTable from "../../components/Tests/Table";
import Pagination from "../../components/Pagination";
import {setTestsSearchData} from "../../redux/actions/tests/search";
import TestsPDFPrint from "../../components/Tests/PDFPrint";

const mapStateToProps = ({tests}) => ({
    count: tests.count,
    list: tests.list,
    loader: tests.loader,
    searchData: tests.search,
});

const mapDispatchToProps = {
    requestList: testsCountRequestStart,
    setSearchData: setTestsSearchData
};

const TestsScene = ({
                        count,
                        list,
                        loader,
                        searchData,
                        requestList,
                        setSearchData
                    }) => {

    useEffect(() => {
        requestList();
    }, [requestList]);

    const setCurrentPage = (page) => {
        setSearchData({...searchData, page});
        requestList()
    };

    const PaginationComponent = () => (
        <Pagination
            count={count}
            pageCount={searchData.pageCount}
            current={searchData.page}
            setCurrent={setCurrentPage}
        />
    );

    return (
        <SceneContainer title={'Прохождение электронного тестирования'}>

            <PaginationComponent/>
            <TestsPDFPrint orientation={"portrait"} title={'Прохождение электронного тестирования'} currentPage={searchData.page} resultsPerPage={searchData.maxResults}/>
            {loader ?
                <SpinnerOuter/>
                :
                <TestsTable data={list} currentPage={searchData.page} resultsPerPage={searchData.maxResults}/>
            }
            <PaginationComponent/>
        </SceneContainer>
    );
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TestsScene);