import React, {useEffect, useState} from "react";
import classNames from "classnames";
import styles from "./BookLoanHistoryScene.module.css";
import "./BookLoanTabsStyle.css";
import LibraryCardBlock from "../../components/BookLoan/LibraryCardBlock";
import {Tab, Tabs} from "react-bootstrap";
import BookLoanHistoryList from "../../components/BookLoanHistory/List";
import {connect} from "react-redux";
import {unreserveBookRequestStart} from "../../redux/actions/books/unreserveBook";
import {reservedBooksListRequestStart} from "../../redux/actions/books/reservedList";
import {readerDataRequestStart} from "../../redux/actions/reader/getData";
import SearchBooksModal from "./Components/SearchBooksModal/SearchBooksModal";
import {getBookInstanceByBookTag, onHandsBooksActions} from "../../redux/factory/onHandsBooksReduxData";
import {availableBooksActions} from "../../redux/factory/availableBooksModalReduxData";
import moment from "moment";
import {EDIT} from "../../constants/modalTypes";
import AdminUsersModal from "../../components/AdminUsers/Modal";
import {usersSaveRequestStart} from "../../redux/actions/adminUsers/save";
import SendBookModal from "../../components/BookLoanHistory/SendBookModal";
import Button from "../../components/Button";
import {createNotification} from "../../components/notification/notification";
import {ICONS} from "../../utils/projectIcons";

const mapStateToProps = state => {
    const list = state.onHandsBooks.list || [];
    const bookDaysOnHands = (state.options.data || {}).bookdaysonhands;
    const nowDate = moment(new Date());
    return {
        reservedList: state.reservedBooks,
        readerData: state.readerData,
        onHandsBooks: state.onHandsBooks,
        outdatedBooks: list.filter(book => {
            if (!bookDaysOnHands) return false;
            const bookDate = moment(book.handedout);
            return nowDate.diff(bookDate, "days") > Number(bookDaysOnHands);
        }),
        auth: (state.auth.data || {})
    };
};

const mapDispatchToProps = {
    requestOnHandsBooks: onHandsBooksActions.listRequestStart,
    deleteBook: onHandsBooksActions.deleteRequestStart,
    editBook: onHandsBooksActions.editRequestStart,
    requestList: reservedBooksListRequestStart,
    unreserveBook: unreserveBookRequestStart,
    requestReaderData: readerDataRequestStart,
    moveBook: availableBooksActions.moveBook,
    saveUser: usersSaveRequestStart
};

const BookLoanHistoryScene = ({
                                  match,
                                  bookBan,
                                  reservedList,
                                  requestList,
                                  unreserveBook,
                                  readerData,
                                  requestReaderData,
                                  requestOnHandsBooks,
                                  onHandsBooks,
                                  moveBook,
                                  outdatedBooks,
                                  saveUser,
                                  deleteBook,
                                  editBook,
                                  auth
                              }) => {

    const mid = match.params.id;
    const isOwn = Number(mid) === Number(auth.mid);
    const isLibrarian = auth.islibrarian;

    const QR_CODE_CONNECTION_PORT = 'ws://localhost:18988/qrscanner';
    const BOOKTAG_READER_CONNECTION_PORT = 'ws://localhost:18988/bookreader';

    const onSocketOpen = (url) => {
        console.log(`Соединение установлено. url: ${url}`);
    };

    const onSocketClose = (event) => {
        console.log('Код: ' + event.code + ' причина: ' + event.reason);
    };

    const onSocketError = (error) => {
        console.log("Ошибка " + error.message);
    };

    useEffect(() => {
        requestOnHandsBooks(mid);
    }, [requestOnHandsBooks, mid]);

    useEffect(() => {
        requestList(mid);
    }, [requestList, mid]);

    useEffect(() => {
        requestReaderData(mid);
    }, [mid, requestReaderData]);

    useEffect(() => {
        const qrCodeScannerConnection = new WebSocket(QR_CODE_CONNECTION_PORT);
        const bookTagReaderConnection = new WebSocket(BOOKTAG_READER_CONNECTION_PORT);


        qrCodeScannerConnection.onopen = () => onSocketOpen(QR_CODE_CONNECTION_PORT);
        qrCodeScannerConnection.onclose = (event) => onSocketClose(event);
        qrCodeScannerConnection.onmessage = (event) => {
            const data = JSON.parse(event.data);
            const {status, qrdata} = data;
            if (status === "QR") {
                onScanQrCode(qrdata)
            }
        };
        qrCodeScannerConnection.onerror = (error) => onSocketError(error);


        bookTagReaderConnection.onopen = () => onSocketOpen(BOOKTAG_READER_CONNECTION_PORT);
        bookTagReaderConnection.onclose = (event) => onSocketClose(event);
        bookTagReaderConnection.onmessage = (event) => {
            const data = JSON.parse(event.data);
            const {status, booknumber} = data;
            if (status === "BOOK") {
                onScanBookTag(booknumber)
            }
        };
        bookTagReaderConnection.onerror = (error) => onSocketError(error);

        return () => {
            qrCodeScannerConnection.close(1000, "React компонент демонтирован");
            bookTagReaderConnection.close(1000, "React компонент демонтирован");
        }
    }, [onHandsBooks]);


    const [searchBooksModalVisible, setSearchBooksModalVisible] = useState(false);
    const [sendBookModalVisible, setSendBookModalVisible] = useState(false);
    const [isTableViewCompact, setTableViewCompact] = useState(false);
    const [editModalData, setEditModalData] = useState({
        modalType: null,
        data: null
    });


    const moveInstanceToOnHands = (instanceid) => {
        window.confirm('Выдать экземпляр книги?') &&
        onTurnIn(moment(new Date(), "DD-MM-YYYY").add(14, 'days').format("DD-MM-YYYY"), instanceid)
    };


    const onScanQrCode = (qrdata) => {
        const scannedIsnstanceId = qrdata.split(";")[0];
        const isScannedInstanceOnHands = onHandsBooks.list.find(item => parseInt(item.bookinstanceid) === parseInt(scannedIsnstanceId));

        if (isScannedInstanceOnHands) {
            window.confirm('Вернуть экземпляр книги?') &&
            onTurnOut(scannedIsnstanceId)
        } else {
            moveInstanceToOnHands(scannedIsnstanceId)
        }
    };

    const onScanBookTag = (rfidcode) => {
        const scannedInstanceOnHands = onHandsBooks.list.find(item => item.rfidcode === rfidcode);

        if (scannedInstanceOnHands) {
            window.confirm('Вернуть экземпляр книги?') &&
            onTurnOut(scannedInstanceOnHands.bookinstanceid)
        } else {
            getBookInstanceByBookTag(rfidcode)
                .then(res => {
                    const scannedIsnstanceId = !!res.data.length && res.data[0].instanceid;
                    !!res.data.length ?
                        moveInstanceToOnHands(scannedIsnstanceId)
                        :
                        createNotification('warning', 'Отсканированный экземлпяр не найден в базе')
                })
                .catch(err => createNotification('warning', `Ошибка сервера. ${JSON.stringify(err)}`))
        }
    };

    const onTurnOut = (instanceId) => {
        moveBook({
            typeFrom: 2,
            placeFrom: mid,
            placeTo: 0,
            typeTo: 1,
            instanceId: instanceId,
            mid: mid
        })
    };

    const onTurnIn = (returndate, instanceId) => {
        moveBook({
            typeFrom: 1,
            placeFrom: 0,
            placeTo: mid,
            typeTo: 2,
            returndate: returndate,
            instanceId: instanceId,
            mid: mid
        })
    };

    const checkOutdatedBooks = () => {
        return bookBan && outdatedBooks.length > 0;
    };

    return (
        <>
            <SendBookModal
                isOpen={sendBookModalVisible}
                onClose={() => setSendBookModalVisible(false)}
                userId={mid}
                title={"Выдача книги"}
            />
            <LibraryCardBlock
                onSearchClick={() => {
                    setSearchBooksModalVisible(true);
                }}
                onEditClick={() => {
                    setEditModalData({
                        modalType: EDIT,
                        data: readerData
                    });
                }}
                data={readerData}
                isOwn={isOwn}
                isLibrarian={isLibrarian}
            />
            <div className={classNames(styles.container, "bookLoanTabsContainer")}>
                <div className="mb-3 d-flex justify-content-between">

                    {isLibrarian &&
                    <Button
                        label={"Выдать книгу"}
                        onClick={() => outdatedBooks.length > 0 ?
                            createNotification('error', 'Читатель имеет задолженность', 'Ошибка выдачи книги')
                            : setSendBookModalVisible(true)}
                    />
                    }

                    <div>
                        <button
                            onClick={() => setTableViewCompact(true)}
                            className={classNames(styles.viewButton, isTableViewCompact && styles.viewButtonActive)}
                            title="Отображать таблицей"
                        >
                            <img src={ICONS.MENU} alt=""/>
                        </button>
                        <button
                            onClick={() => setTableViewCompact(false)}
                            className={classNames(styles.viewButton, !isTableViewCompact && styles.viewButtonActive)}
                            title="Отображать списком"
                        >
                            <img src={ICONS.LIST} alt=""/>
                        </button>
                    </div>
                </div>

                <Tabs defaultActiveKey="all" id="bookLoanTabs">
                    <Tab eventKey="current" title="ТОЛЬКО НА РУКАХ">
                        <BookLoanHistoryList
                            isOwn={isOwn}
                            isCompact={isTableViewCompact}
                            onTurnOut={onTurnOut}
                            data={(onHandsBooks && onHandsBooks.list) || []}
                        />
                    </Tab>
                    <Tab eventKey="all" title="ВСЕ">
                        <BookLoanHistoryList
                            isOwn={isOwn}
                            isCompact={isTableViewCompact}
                            isLibrarian={isLibrarian}
                            onDelete={deleteBook}
                            onEdit={editBook}
                            mid={mid}
                            data={onHandsBooks.history}
                        />
                    </Tab>
                    <Tab eventKey="outdated" title="ПРОСРОЧЕНЫ">
                        <BookLoanHistoryList
                            isOwn={isOwn}
                            isCompact={isTableViewCompact}
                            data={outdatedBooks}
                        />
                    </Tab>
                    <Tab eventKey="reserved" title="ЗАБРОНИРОВАНЫ">
                        <BookLoanHistoryList
                            data={reservedList}
                            reserve={true}
                            isOwn={isOwn}
                            isCompact={isTableViewCompact}
                            unreserveBook={unreserveBook}
                        />
                    </Tab>
                </Tabs>

                <AdminUsersModal
                    onlyInfo={true}
                    onSubmit={data => {
                        saveUser(data, () => {
                            requestReaderData(mid);
                        });
                        setEditModalData({modalType: null});
                    }}
                    modalData={editModalData}
                    setModalData={setEditModalData}
                />
                {searchBooksModalVisible && (
                    <SearchBooksModal
                        userId={mid}
                        resolution={() => checkOutdatedBooks()}
                        onClose={() => setSearchBooksModalVisible(false)}
                    />
                )}
            </div>
        </>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BookLoanHistoryScene);
