import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Button.module.css';
import {Spinner} from "react-bootstrap";

const Button = ({ onClick, label, style, type, colorType, disabled, spinner, ...props }) => {


  const setColor = () => {
    switch (colorType) {
      case 'red':
        return styles.redButton;
      case 'blue':
        return styles.blueButton;
      case 'green':
        return styles.greenButton;
      case 'dark-blue':
        return styles.darkBlueButton;
      case 'transparent':
        return styles.transparentButton;
      default:
        return '';
    }
  };

  return (
    <button
      className={classNames(styles.button, setColor(), style)}
      onClick={onClick}
      type={type}
      disabled={disabled} {...props}
    >
      {spinner &&
        <Spinner animation="border" size="sm" className={'mr-2'}/>
      }
      {label}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  style: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  spinner: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false
};

export default Button;
