import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classNames from "classnames";
import {DebounceInput} from 'react-debounce-input';
import ReadingListItem from "../../../Reading/List/Item";
import styles from "./SendBookListItem.module.css";
import {Col, Row} from "react-bootstrap";
import CoverPhotoPlaceholder from "../../../Covers/List/Item/CoverPhotoPlaceholder";
import {Link} from "react-router-dom";
import CoverListItemInfo from "../../../Covers/List/Item/InfoBlock";
import {createInvNumberRequestStart} from "../../../../redux/actions/bookLoan/create";
import {api} from "../../../../App";
import {availableBooksActions} from "../../../../redux/factory/availableBooksModalReduxData";
import {createNotification} from "../../../notification/notification";
import Button from "../../../Button";
import ModalInputWrapper from "../../../Modal/InputWrapper";


const mapStateToProps = () => {};

const mapDispatchToProps = dispatch => ({
    createInvNumber: data => dispatch(createInvNumberRequestStart(data)),
    moveBook: book => dispatch(availableBooksActions.moveBook(book)),
});

const SendBookListItem = ({data, createInvNumber, moveBook, userId}) => {
    const MAX_INSTANCEFIELD_NUMBER = 150;


    const [instancesNumber, setInstancesNumber] = useState('');
    const [instancesState, setInstancesState] = useState([]);

    useEffect(() => {
        onChangeNumber();
    }, [instancesNumber]);

    const onChangeNumber = () => {
        let valueNumber = instancesNumber;
        let inputs = [];

        if (valueNumber <= MAX_INSTANCEFIELD_NUMBER) {
            for (let i = 0; i < valueNumber; i++) {
                let currentValue =
                    instancesState[i]
                    ||
                    {
                        isConfirmed: false,
                        instanceId: '',
                        isDuplicated: false,
                        responceId: null,
                        returnDate: null,
                        isValid: true,
                    };
                inputs.push(currentValue);
            }
            setInstancesState(inputs);
        } else {
            createNotification('error', 'Недопустимое число экземпляров');
        }

    };

    const updateInstanceReturnDate = (date, index) => {
        const updatedInstances = [...instancesState];
        updatedInstances[index]['returnDate'] = date;
        setInstancesState(updatedInstances);
    };

    const updateInstance = async (value, index) => {
        const updatedInstances = [...instancesState];
        const bookid = Number(data.bookid);

        updatedInstances[index]['isConfirmed'] = false;
        api.get(`/book/instancebook/${bookid}?number=${value}`)
            .then((response) => {
                updatedInstances[index]['instanceId'] = value;
                updatedInstances[index]['isDuplicated'] = false;
                if (response.data.id) {
                    updatedInstances[index]['isConfirmed'] = true;
                    updatedInstances[index]['responceId'] = response.data.id;
                }
                setInstancesState(updatedInstances);
            });
    };

    const updateAllInstances = (value) => {
        const bookid = Number(data.bookid);
        api.get(`/book/instancebook/${bookid}?number=${value}`)
            .then(res => {
                setInstancesState(
                    instancesState.map(
                        instance => ({...instance, "instanceId": value, "isConfirmed": !!res.data.id})
                    )
                );
            })
    };

    const validateInstances = () => {
        let isInstancesValid = true;
        const updatedInstances = [...instancesState];

        for (let i = 0; i < instancesState.length; i++) {
            let instanceId = updatedInstances[i]['instanceId'];
            let returnDate = updatedInstances[i]['returnDate'];
            updatedInstances[i]['isValid'] = true;

            if (!instanceId || !returnDate) {
                isInstancesValid = false;
                updatedInstances[i]['isValid'] = false;
            }
        }
        setInstancesState(updatedInstances);
        return isInstancesValid;
    };

    const onSubmitInstances = () => {
        let values = [];
        let isSomeValuesDuplicated = false;
        let isInstancesValid = validateInstances();

        if (isInstancesValid) {
            const updatedInstances = [...instancesState];

            for (let i = 0; i < instancesState.length; i++) {
                let currentInstance = updatedInstances[i].instanceId;
                if (values.indexOf(currentInstance) === -1) {
                    values.push(currentInstance);
                } else {
                    updatedInstances[i].isDuplicated = true;
                    isSomeValuesDuplicated = true;
                }
            }
            setInstancesState(updatedInstances);

            if (!isSomeValuesDuplicated) {
                sendInstances();
            }
        }
    };

    const sendInstances = () => {

        for (let i = 0; i < instancesState.length; i++) {
            (function (i) {
                setTimeout(function () {
                    let bookData = {
                        bookId: data.bookid,
                        number: instancesState[i]['instanceId'],
                        userId: userId,
                        returnDate: instancesState[i]['returnDate']
                    };

                    if (instancesState[i].isConfirmed) {
                        moveBook({
                            typeFrom: 1,
                            placeFrom: 0,
                            placeTo: bookData.userId,
                            typeTo: 2,
                            returndate: bookData.returnDate,
                            instanceId: bookData.number,
                            mid: bookData.userId
                        });

                    } else {
                        createInvNumber(bookData)
                    }
                }, i * 700);
            })(i);
        }
        setInstancesState([]);
        setInstancesNumber('');
    };

    const autoFillInstancesNumber = (event) => {
        if (event.target.tagName !== 'INPUT' && !instancesNumber) {
            setInstancesNumber('1');
        }
    };


    return (
        <div
            className={styles.container}
            onClick={(event) => autoFillInstancesNumber(event)}
        >
            <Row>
                <Col md={2}>
                    <CoverPhotoPlaceholder
                        url={data.coverImage}
                        bookId={data.bookid}
                        alt={data.title}
                    />
                </Col>
                <Col md={10}>
                    <Row>
                        <Col md={{span: 9, offset: 3}}>
                            <Link to={`/book/${data.bookid}`} className={styles.title}>
                                {data.title}
                            </Link>
                            <div className={styles.value}>{data.authors}</div>
                        </Col>
                        <Col md={12}>
                            <CoverListItemInfo label={"Год"} value={data.year}/>
                            <CoverListItemInfo label={"ISBN"} value={data.isbn}/>
                            <CoverListItemInfo
                                label={"Размещение"}
                                value={data.clients || "n/a"}
                            />
                            <CoverListItemInfo label={"Фонд"} value={"n/a"}/>
                            {data.covermatch !== undefined && (
                                <CoverListItemInfo
                                    label={"% совпадения"}
                                    value={data.covermatch}
                                />
                            )}
                        </Col>
                        <Col md={12}>
                            <Row>
                                <Col md={3}>
                                    <div className={styles.label}>
                                        Количество экземпляров:
                                    </div>
                                </Col>
                                <Col md={9}>
                                    <DebounceInput
                                        debounceTimeout={500}
                                        type="number"
                                        min="0"
                                        value={instancesNumber}
                                        onChange={(e) => setInstancesNumber(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row>
                                {instancesNumber > 1 &&
                                <Col md={{span: 9, offset: 3}}>
                                    <ModalInputWrapper
                                        label={'Номер всем'}
                                        onChange={(e) => {
                                            updateAllInstances(e.target.value)
                                        }}
                                    />
                                    <ModalInputWrapper
                                        label={'Дата всем'}
                                        type={'date'}
                                        onChange={(e) => {
                                            setInstancesState(
                                                instancesState.map(instance => ({...instance, "returnDate": e.target.value}))
                                            );
                                        }}
                                    />
                                </Col>
                                }
                                <Col md={{span: 9, offset: 3}}>
                                    {
                                        instancesState.map((val, idx) => {
                                            const instanceNumber = `instance${idx}`;
                                            return (
                                                <div
                                                    key={idx}
                                                    className={classNames("d-flex my-2", styles.instanceItemOuter, instancesState[idx]['isValid'] ? '' : styles.instanceItemOuterError)}>
                                                    <div
                                                        className={classNames(styles.instanceItemNumber, 'd-flex align-items-center')}>{idx + 1}</div>
                                                    <div className={styles.instanceItemInput}>
                                                        <div className={styles.instanceItemLabel}>
                                                            Инвентарный номер:
                                                        </div>
                                                        <DebounceInput
                                                            debounceTimeout={500}
                                                            forceNotifyOnBlur={true}
                                                            name={instanceNumber}
                                                            data-idx={idx}
                                                            value={instancesState[idx].instanceId}
                                                            onChange={(e) => updateInstance(e.target.value, idx)}
                                                        />
                                                    </div>
                                                    <div className={styles.instanceItemInput}>
                                                        <div className={styles.instanceItemLabel}>
                                                            Предполагаемая дата возврата:
                                                        </div>
                                                        <input
                                                            type="date"
                                                            value={instancesState[idx].returnDate}
                                                            onChange={(e) => updateInstanceReturnDate(e.target.value, idx)}
                                                        />
                                                    </div>
                                                    <div className={styles.instanceItemInput}>
                                                        {val.isDuplicated &&
                                                        <div
                                                            className={classNames(styles.instanceNotification, styles.instanceItemDuplicated)}>
                                                            Дублирующее значение
                                                        </div>
                                                        }
                                                        {val.isConfirmed &&
                                                        <div
                                                            className={classNames(styles.instanceNotification, styles.instanceItemConfirmed)}>
                                                            Экземпляр найден
                                                        </div>
                                                        }
                                                        {val.instanceId && !val.isConfirmed &&
                                                        <div
                                                            className={classNames(styles.instanceNotification, styles.instanceItemDeflected)}>
                                                            Экземпляр не найден
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                    {instancesState.length > 0 &&
                                    <Button onClick={onSubmitInstances} label={"Выдать экземпляры"}/>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

ReadingListItem.propTypes = {
    data: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendBookListItem);
