import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {ADD, EDIT} from "../../constants/modalTypes";
import {SORT_DIRECTION} from '../../constants/sortDirections';

import {inventoryCountRequestStart} from "../../redux/actions/inventory/documents/count";
import {setInventorySearchData} from "../../redux/actions/inventory/documents/search";
import {inventorySaveRequestStart} from "../../redux/actions/inventory/documents/save";
import {inventoryDeleteRequestStart} from "../../redux/actions/inventory/documents/delete";
import {documentTypesAllRequestStart} from "../../redux/actions/inventory/types/all";

import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import Table from "../../components/Inventory/Table";
import SearchForm from "../../components/Inventory/SearchForm";
import DocumentsModal from "../../components/Inventory/Modal";
import Pagination from "../../components/Pagination";

const mapStateToProps = ({documents, documentTypes, inventory}) => ({
    list: inventory.list,
    allTypes: documentTypes.all,
    searchData: inventory.search,
    latestDocument: inventory.latest,
    count: inventory.count
});

const mapDispatchToProps = {
    requestList: inventoryCountRequestStart,
    saveInventory: inventorySaveRequestStart,
    requestAllTypes: documentTypesAllRequestStart,
    setSearchData: setInventorySearchData,
    deleteInventory: inventoryDeleteRequestStart,
};


const DocumentsScene = ({
                            count,
                            list,
                            requestList,
                            searchData,
                            setSearchData,
                            saveInventory,
                            deleteInventory,
                            allTypes,
                            requestAllTypes,
                            latestDocument
                        }) => {

        const [modalData, setModalData] = useState({modalType: null, data: null});
        const [selectedId, setSelectedId] = useState(null);

        useEffect(() => {
            requestList();
            // requestAllTypes();
        }, [requestList, requestAllTypes]);

        useEffect(() => {
            setModalData(modalData =>
                modalData.modalType === ADD ? {
                    modalType: EDIT,
                    data: latestDocument
                } : modalData)
        }, [latestDocument]);

        const setCurrentPage = (page) => {
            setSearchData({...searchData, page});
            requestList()
        };

        const setSort = (sortBy) => {
            let sortDir = searchData.sortDirection === SORT_DIRECTION.ASC ?
                SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;

            setSearchData({...searchData, sortDirection: sortDir, sortBy, page: 1});
            requestList();
        };

        const handleSelect = (id) => {
            setSelectedId(selectedId === id ? null : id)
        };

        const onDelete = () => {
            selectedId && deleteInventory(selectedId);
        };

        const PaginationComponent = () => (
            <Pagination
                count={count}
                pageCount={searchData.pageCount}
                current={searchData.page}
                setCurrent={setCurrentPage}
            />
        );

        return (
            <SceneContainer title={'Инвентаризация'}>
                <SearchForm
                    data={searchData}
                    setSearchData={setSearchData}
                    startSearch={requestList}
                />

                <PaginationComponent/>

                <DocumentsModal
                    types={allTypes}
                    onSave={saveInventory}
                    setModalData={setModalData}
                    modalData={modalData}
                />
                <Table
                    data={list}
                    selectedId={selectedId}
                    handleSelect={handleSelect}
                    onDelete={onDelete}
                    setModal={setModalData}
                    sortBy={searchData.sortBy}
                    setSort={setSort}
                />

                <PaginationComponent/>
            </SceneContainer>
        );
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsScene);
