import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { AUTHOR_SAVE_IMAGE_REQUEST, START } from "../../../actions/action-types";
import { saveAuthorImage } from "../../../../api/authors/authors";
import { authorSaveImageRequestError, authorSaveImageRequestSuccess } from "../../../actions/authors/saveImage";
import { authorImageRequestStart } from "../../../actions/authors/loadImage";
import { authorsCountRequestStart } from "../../../actions/authors/count";

export default function* authorSaveImageWatcher() {
  yield takeLatest(`${AUTHOR_SAVE_IMAGE_REQUEST}${START}`, authorSaveImageWorker)
}

function* authorSaveImageWorker({ payload: { file, id } }) {
  try {
    const response = yield call(saveAuthorImage, file, id);
    yield put(authorSaveImageRequestSuccess(response.data));
    yield put(authorsCountRequestStart());
    yield put(authorImageRequestStart(id));
  } catch (error) {
    yield put(authorSaveImageRequestError(error));
  }
}