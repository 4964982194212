import { call, put, select, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
  COVER_UPLOAD_REQUEST,
  COVERS_COUNT_REQUEST,
  COVERS_LIST_REQUEST,
  START,
  SCAN_UPLOAD_REQUEST,
  COVERS_READING_SEARCH_REQUEST,
  COVERS_READING_LIST_REQUEST,
  COVERS_ADVANCED_SEARCH_PARAMS_REQUEST,
  COVERS_ADVANCED_SEARCH_LIST_REQUEST, COVERS_BATCH_EDITING_REQUEST
} from "../../actions/action-types";
import { coversCountRequestError, coversCountRequestSuccess } from "../../actions/covers/count";
import {
  countCovers,
  searchCovers,
  countSimpleCoversSearch,
  simpleCoversSearch
} from "../../../api/covers/search";
import {
  getAdvancedSearchParameters
} from '../../../api/covers/advancedSearch';
import { setCoversSearchData } from "../../actions/covers/search";
import { coversListRequestError, coversListRequestStart, coversListRequestSuccess } from "../../actions/covers/list";
import { coversReadingListRequestStart} from '../../actions/covers/readingSearchList';
import { uploadCover, scanCover } from "../../../api/covers/upload";
import { getData } from '../../../api/covers/loadbyimage';
import { coverUploadRequestError, coverUploadRequestSuccess, scanUploadRequestSuccess, scanUploadRequestError } from "../../actions/covers/upload";
import { coversReadingSearchRequestSuccess, coversReadingSearchRequestError } from '../../actions/covers/readingSearch';
import { coversAdvancedSearchParamsRequestSuccess, coversAdvancedSearchParamsRequestError } from '../../actions/covers/advancedSearch/params';
import {
  booksBatchEditing
} from '../../../api/covers/batchEditing';
import {
  coversBatchEditingRequestSuccess,
  coversBatchEditingRequestError
} from '../../actions/covers/batchEditing';

export default function* coversRequestWatcher() {
  yield takeLatest(`${COVERS_COUNT_REQUEST}${START}`, coversCountWorker);
  yield takeLatest(`${COVERS_LIST_REQUEST}${START}`, coversListWorker);
  yield takeLatest(`${COVER_UPLOAD_REQUEST}${START}`, coverUploadWorker);
  yield takeLatest(`${SCAN_UPLOAD_REQUEST}${START}`, scanUploadWorker);
  yield takeLatest(`${COVERS_READING_SEARCH_REQUEST}${START}`, readingSearchCountWorker);
  yield takeLatest(`${COVERS_READING_LIST_REQUEST}${START}`, coversReadingListWorker);
  yield takeLatest(`${COVERS_ADVANCED_SEARCH_PARAMS_REQUEST}${START}`, advancedSearchParamsWorker);
  yield takeLatest(`${COVERS_ADVANCED_SEARCH_LIST_REQUEST}${START}`, advancedSearchWorker);
  yield takeLatest(`${COVERS_BATCH_EDITING_REQUEST}${START}`, batchEditingWorker);
}

function* coversCountWorker({ payload: data }) {
  const formData = yield select(({covers}) => covers.form);
  const searchData = yield select(({covers}) => covers.search);
  try {
    let response;
    if(data) {
      response = yield call(getData, data);
      yield put(coversListRequestSuccess(response.data));
      const count = parseInt(response.data.length, 10);
      yield put(coversCountRequestSuccess(count));
      const pageCount = Math.ceil(count / searchData.maxResults);
      yield put(setCoversSearchData({ ...searchData, pageCount }));
    } else {
      response = yield call(countCovers, formData);
      const count = parseInt(response.data.count, 10);
      yield put(coversCountRequestSuccess(count));
      const pageCount = Math.ceil(count / searchData.maxResults);
      yield put(setCoversSearchData({ ...searchData, pageCount }));
      yield put(coversListRequestStart());
    }

  } catch(error) {
    yield put(coversCountRequestError(error));
  }
}

function* readingSearchCountWorker({ payload: isSearchAdvanced }) {
  const formData = yield select(({covers}) => covers.form);
  const searchData = yield select(({covers}) => covers.search);

  try {
    let response;
    if(isSearchAdvanced) {
      response = yield call(countCovers, formData);
    } else {
      response = yield call(countSimpleCoversSearch, formData.searchField);
    }
    const count = parseInt(response.data.count, 10);
    yield put(coversReadingSearchRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setCoversSearchData({ ...searchData, pageCount }));
    if(isSearchAdvanced) {
      yield put(coversListRequestStart());
    } else {
      yield put(coversReadingListRequestStart());
    }
  } catch(error) {
    yield put(coversReadingSearchRequestError(error));
  }
}



function* advancedSearchWorker ({ payload: data }) {
  const searchData = yield select(({covers}) => covers.search);
  const formData = { "advancedsearch": data };
    try {
      const response = yield call(countCovers, formData);
      const count = parseInt(response.data.count, 10);
      yield put(coversReadingSearchRequestSuccess(count));
      const pageCount = Math.ceil(count / searchData.maxResults);
      yield put(setCoversSearchData({ ...searchData, pageCount }));
      yield put(coversListRequestStart(formData));
    } catch(error) {
      yield put(coversListRequestError(error));
    }
}

function* coversListWorker({ payload: data }) {
  const searchData = yield select(({covers}) => covers.search);
  let requestData;

  if(data) {
    requestData = data;
  } else {
    requestData = yield select(({covers}) => covers.form);
  }

  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
  try {
    const response = yield call(searchCovers, requestData, searchData);
    yield put(coversListRequestSuccess(response.data));
  } catch (error) {
    yield put(coversListRequestError(error));
  }
}


function* coversReadingListWorker() {
  const formData = yield select(({covers}) => covers.form);
  const searchData = yield select(({covers}) => covers.search);
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
  try {
    const response = yield call(simpleCoversSearch, formData.searchField, searchData);
    yield put(coversListRequestSuccess(response.data));
  } catch (error) {
    yield put(coversListRequestError(error));
  }
}

function* coverUploadWorker({ payload: { bookId, image }}) {
  try {
    const response = yield call(uploadCover, bookId, image);
    yield put(coverUploadRequestSuccess(response.data));
    yield put(coversListRequestStart());
  } catch (error) {
    yield put(coverUploadRequestError(error));
  }
}

function* scanUploadWorker({payload: { bookId, image }}) {
  try {
    const response = yield call(scanCover, bookId, image);
    console.log(response);
    yield put(scanUploadRequestSuccess(response.data));
    yield put(coversListRequestStart());
  } catch (error) {
    yield put(scanUploadRequestError(error));
  }
}

function* advancedSearchParamsWorker () {
    try {
        const response = yield call(getAdvancedSearchParameters);
        yield put(coversAdvancedSearchParamsRequestSuccess(response.data));
    } catch(error) {
        yield put(coversAdvancedSearchParamsRequestError(error));
    }
}

function* batchEditingWorker({ payload: data }) {
    try {
        yield call(booksBatchEditing, data);
        yield put(coversBatchEditingRequestSuccess());
    } catch(error) {
        yield put(coversBatchEditingRequestError());
    }
}