import React from 'react';
import PropTypes from 'prop-types';
import styles from './AuditLogTable.module.css';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import { AUDIT_ACCESS_SORT } from "../../../constants/sort/auditAccessSort";
import { SORT_DIRECTION } from "../../../constants/sortDirections";

const AuditLogTable = ({ data, sortBy, setSort }) => (
  <div className={styles.container}>
    <DataTable data={data} sortBy={sortBy} onHeadClick={setSort}>
      <DataTableCol title="Имя" sortParams={[AUDIT_ACCESS_SORT.DISPLAY_NAME]} value="displayname" className={styles.leftAlign} colSpan={3}/>
      <DataTableCol title="Логин" sortParams={[AUDIT_ACCESS_SORT.USERNAME]} value="login" className={styles.leftAlign} colSpan={2}/>
      <DataTableCol title="Книга"
                    resolver={(item) =>
                      <div>
                        {item.bookid ? (<strong>{ item.title }</strong>) : "n/a"}
                        {item.authors && item.authors.map((item) => `, ${item.name}`)}
                      </div>}
                    className={styles.bookContainer} colSpan={4}/>
      <DataTableCol title="Дата и время" sortParams={[AUDIT_ACCESS_SORT.EVENT_TIME, SORT_DIRECTION.DESC]} value="eventtime" resolver={item => new Date(item.eventtime).toLocaleString()}
                    colSpan={3}/>
      <DataTableCol title="Датасет" sortParams={[AUDIT_ACCESS_SORT.DATA_SET]} value="dataset" colSpan={3}/>
      <DataTableCol title="Событие" sortParams={[AUDIT_ACCESS_SORT.DATA_ACTION]} value="dataaction" colSpan={2}/>
      <DataTableCol title="IP" sortParams={[AUDIT_ACCESS_SORT.CLIENT_IP]} value="clientip" colSpan={2}/>
    </DataTable>
  </div>
);

AuditLogTable.propTypes = {
  data: PropTypes.array,
  sortBy: PropTypes.string,
  setSort: PropTypes.func,
};

export default AuditLogTable;
