import React from 'react';
import PropTypes from 'prop-types';
import styles from './LocationModal.module.css';
import { objectValuesNonEmpty } from "../../../utils/objectValuesNonEmpty";
import { ADD, EDIT } from "../../../constants/modalTypes";
import Modal from "../../Modal";
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import { LOCATION_MODAL } from "../../../constants/locationModal";
import ModalWindow from "../../ModalWindow";
import CatalogsModalWithForm from "../../Catalogs/ModalWithForm";

const LocationModal = ({ modalData, setModalData, saveRoom, saveRack, saveShelf, resetModal }) => {
  const { modalType, data, locationType, parentId } = modalData;
  const isVisible = !!modalType;

  if (modalType === ADD && !data) {
    setModalData({
      ...modalData,
      data: {
        name: "",
        code: "",
      }
    })
  }

  const onChange = ({ target: { name, value } }) =>
    setModalData({ ...modalData, data: { ...data, [name]: value } });

  const chooseEntityFunc = (type) => {
    switch (type) {
      case LOCATION_MODAL.RACK: return saveRack;
      case LOCATION_MODAL.ROOM: return saveRoom;
      case LOCATION_MODAL.SHELF: return saveShelf;
      default: return null;
    }
  };

  const checkedOnSubmit = () => {
    const saveEntity = chooseEntityFunc(locationType);
    console.log(data.name, data.code)
    if(data.name && data.code) {
      saveEntity(parentId, modalData.data);
      return resetModal();
    }
    setModalData({ ...modalData, error: true });
  };

  return (
      <CatalogsModalWithForm
          title={`${locationType}: ${modalType === EDIT ? "Редактирование" : "Добавление"}`}
          submitHandler={checkedOnSubmit}
          closeModal={() => setModalData({ modalType: null, data: null })}
      >
          {data &&
          <Row>
              <Col md={12}>
                  <ModalInputWrapper label="Название*"
                                     name="name"
                                     value={data.name}
                                     onChange={onChange}/>
                  <ModalInputWrapper label="Код*"
                                     name="code"
                                     value={data.code}
                                     onChange={onChange}/>
              </Col>
              <Col md={8}>
                  {modalData.error &&
                  <div className={styles.errorMessage}>
                      Вы должны заполнить все поля формы!
                  </div>}
              </Col>

          </Row>
          }
      </CatalogsModalWithForm>
  );
};

LocationModal.propTypes = {
  modalData: PropTypes.object,
  setModalData: PropTypes.func,
  saveRoom: PropTypes.func,
  saveRack: PropTypes.func,
  saveShelf: PropTypes.func,
  resetModal: PropTypes.func
};

export default LocationModal;
