import React from 'react';
import PropTypes from 'prop-types';
import CategoriesListItem from "../Item";
import styles from './CategoriesList.module.css'

const CategoriesList = ({ list }) => {


  return (
    <div className={styles.container}>
      {list.map(item => <CategoriesListItem key={item.id} data={item} />)}
    </div>
  );
};

CategoriesList.propTypes = {
  list: PropTypes.array,
};

export default CategoriesList;