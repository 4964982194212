import {
  call,
  put,
  takeLatest,
  select
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";
import {
  PERMISSIONS_BY_LITER_ADD_REQUEST,
  PERMISSIONS_BY_LITER_COUNT_REQUEST,
  PERMISSIONS_BY_LITER_EDIT_REQUEST,
  PERMISSIONS_BY_LITER_LIST_REQUEST,
  START
} from "../../../actions/action-types";
import {
  getLiter2roleCount,
  getLiter2role,
  addLiter2role,
  editLiter2role
} from "../../../../api/liters/liters2role";
import {
  permissionsByLiterCountRequestStart,
  permissionsByLiterCountRequestSuccess,
  permissionsByLiterCountRequestError
} from '../../../actions/permissions/byLiters/count';
import {
  permissionsByLiterRequestStart,
  permissionsByLiterRequestSuccess,
  permissionsByLiterRequestError
} from '../../../actions/permissions/byLiters/list';
import {
  permissionsByLiterAddRequestSuccess,
  permissionsByLiterAddRequestError
} from '../../../actions/permissions/byLiters/add';
import {
  permissionsByLiterEditRequestSuccess,
  permissionsByLiterEditRequestError
} from '../../../actions/permissions/byLiters/edit';
import {
  setPermissionsByLiterSearchData
} from "../../../actions/permissions/byLiters/search";




export default function* permissionsByLiterWatcher() {
  yield takeLatest(`${PERMISSIONS_BY_LITER_COUNT_REQUEST}${START}`, countWorker);
  yield takeLatest(`${PERMISSIONS_BY_LITER_LIST_REQUEST}${START}`, listWorker);
  yield takeLatest(`${PERMISSIONS_BY_LITER_ADD_REQUEST}${START}`, addWorker);
  yield takeLatest(`${PERMISSIONS_BY_LITER_EDIT_REQUEST}${START}`, editWorker);
}

function* countWorker() {
  const formData = yield select(({accessLiters}) => accessLiters.form);
  const searchData = yield select(({accessLiters}) => accessLiters.search);
  try {
    const response = yield call(getLiter2roleCount, {
      roleid: formData.roleid,
      set: formData.set
    });
    const count = parseInt(response.data.count, 10);
    yield put(permissionsByLiterCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setPermissionsByLiterSearchData({...searchData, pageCount }));
    yield put(permissionsByLiterRequestStart());
  } catch (error) {
    yield put(permissionsByLiterCountRequestError());
  }
}

function* listWorker() {
  const formData = yield select(({accessLiters}) => accessLiters.form);
  const searchData = yield select(({accessLiters}) => accessLiters.search);
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);

  try {
    const response = yield call(getLiter2role, {
      roleid: formData.roleid,
      set: formData.set,
      ...searchData
    });
    yield put(permissionsByLiterRequestSuccess(response.data));
  } catch (error) {
    yield put(permissionsByLiterRequestError(error));
  }
}

function* addWorker({ payload: { role, literid, access } }) {
  try {
    yield call(addLiter2role, { role, literid, access });
    yield put(permissionsByLiterAddRequestSuccess());
    yield put(permissionsByLiterCountRequestStart());
  } catch(error) {
    yield put(permissionsByLiterAddRequestError());
  }
}

function* editWorker({ payload: { role, literid, access, itemid } }) {
  try {
    yield call(editLiter2role, { role, literid, access, itemid });
    yield put(permissionsByLiterEditRequestSuccess());
    yield put(permissionsByLiterCountRequestStart());
  } catch(error) {
    yield put(permissionsByLiterEditRequestError());
  }
}