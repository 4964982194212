import React, {useEffect} from "react";
import {menuStructure} from "../../../constants/menuStructure";
import {readerMenuStructure} from "../../../constants/readerMenuStructure";
import styles from "./Menu.module.css";
import MenuItem from "./Item";
import {connect} from "react-redux";
import styled from 'styled-components'
import {messageNotificationRequestStart} from "../../../redux/actions/message/notifications";
import {mediaResourceReaderRequestStart} from "../../../redux/actions/mediaResource/getForReader";

const mapStateToProps = ({auth: {data}, options, message, mediaResource}) => ({
    authData: data,
    layoutOptions: options.layout,
    messageNotification: message.notification,
    list: mediaResource.forReader.list,
    links: options.data,
});

const mapDispatchToProps = {
    requestMessageNotification: messageNotificationRequestStart,
    getListForReader: mediaResourceReaderRequestStart
};

const MenuOuter = styled.div`
    background-color: ${props => props.options.menu.background};
  `;

const Menu = ({authData, layoutOptions, requestMessageNotification, messageNotification, getListForReader, list, links}) => {
    useEffect(() => {
        requestMessageNotification();
        getListForReader();
    }, []);

    let structureType = authData.islibrarian ? menuStructure : readerMenuStructure;

    return links && (
        <MenuOuter options={layoutOptions} className={styles.container}>
            {structureType.map((item, index) => (
                <MenuItem authData={authData} layoutOptions={layoutOptions} {...item}
                    links={links}
                          messageNotification={parseInt(messageNotification)} key={index} list={list}/>
            ))}
        </MenuOuter>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
