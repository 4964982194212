import { SET_NEW_BOOKS_ORDER_SEARCH_DATA } from "../../../actions/action-types";
import { SORT_DIRECTION } from "../../../../constants/sortDirections";
import { RESULTS_PER_PAGE } from "../../../../constants/pagination";
import { DOCUMENT_SORT } from "../../../../constants/sort/documentSort";

const initialState = {
    query: "",
    page: 1,
    documenttype: "",
    from: "",
    to: "",
    sortBy: DOCUMENT_SORT.NAME,
    sortDirection: SORT_DIRECTION.ASC,
    maxResults: RESULTS_PER_PAGE,
    pageCount: 0
};

export const newBooksOrderSearchReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_NEW_BOOKS_ORDER_SEARCH_DATA:
            return action.payload;

        default: return state;
    }
};
