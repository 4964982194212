import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { AUTHORS_DELETE_REQUEST, START } from "../../../actions/action-types";
import { authorDelete } from "../../../../api/authors/authors";
import { authorsDeleteRequestError, authorsDeleteRequestSuccess } from "../../../actions/authors/delete";
import { authorsCountRequestStart } from "../../../actions/authors/count";

export default function* deleteAuthorWatcher() {
  yield takeLatest(`${AUTHORS_DELETE_REQUEST}${START}`, deleteAuthorWorker)
}

function* deleteAuthorWorker({payload: mid}) {
  try {
    const response = yield call(authorDelete, mid);
    yield put(authorsDeleteRequestSuccess(response.data));
    yield put(authorsCountRequestStart());
  } catch (error) {
    yield put(authorsDeleteRequestError(error))
  }
}