import React, {useState, useEffect} from 'react';
import {documentCountRequestStart} from "../../redux/actions/booksOrder/count/count";
import {setDocumentSearchData} from "../../redux/actions/booksOrder/search/search";
import {documentSaveRequestStart} from "../../redux/actions/booksOrder/documents/save";
import {documentDeleteRequestStart} from "../../redux/actions/booksOrder/documents/delete";
import {connect} from "react-redux";
import Pagination from "../../components/Pagination";
import BooksOrderTable from "../../components/BooksOrder/Table";
import BooksOrderModal from "../../components/BooksOrder/Modal";
import BooksOrderSearchForm from "../../components/BooksOrder/SearchForm";
import {documentTypesAllRequestStart} from "../../redux/actions/documents/types/all";
import {ADD, EDIT} from "../../constants/modalTypes";
import {curriculumListRequestStart} from "../../redux/actions/curriculum/list";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";

const mapStateToProps = ({booksOrder, curriculum}) => ({
    list: booksOrder.list,
    searchData: booksOrder.search,
    latestDocument: booksOrder.latest,
    curriculumCourses: curriculum.data,
    count: booksOrder.count,
});

const mapDispatchToProps = {
    requestList: documentCountRequestStart,
    requestAllTypes: documentTypesAllRequestStart,
    setSearchData: setDocumentSearchData,
    saveDocument: documentSaveRequestStart,
    deleteDocument: documentDeleteRequestStart,
    requestCurriculumCourses: curriculumListRequestStart,
};

const BooksOrderScene = ({
                             count, list, requestList, searchData, setSearchData, saveDocument, allTypes, requestAllTypes,
                             latestDocument, deleteDocument, requestCurriculumCourses, curriculumCourses
                         }) => {
    const [modalData, setModalData] = useState({modalType: null, data: null});
    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        requestList();
        requestAllTypes();
        requestCurriculumCourses();
    }, [requestList, requestAllTypes]);

    useEffect(() => {
        setModalData(modalData =>
            modalData.modalType === ADD ? {
                modalType: EDIT,
                data: latestDocument
            } : modalData);
    }, [latestDocument]);

    const setCurrentPage = (page) => {
        setSearchData({...searchData, page});
        requestList();
    };

    const setSort = (sortBy) => {
        setSearchData({...searchData, sortBy, page: 1});
        requestList();
    };

    const handleSelect = (id) => {
        setSelectedId(selectedId === id ? null : id);
    };

    const onSubmitModal = () => {
        saveDocument(modalData.data);
    };

    const onDelete = () => {
        selectedId && deleteDocument(selectedId);
    };

    const PaginationComponent = () => (
        <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page}
                    setCurrent={setCurrentPage}/>
    );


    return (
        <SceneContainer title={"Заказ на подбор литературы"}>
            <BooksOrderSearchForm
                types={allTypes}
                data={searchData}
                setSearchData={setSearchData}
                startSearch={requestList}
            />

            <PaginationComponent/>
            <BooksOrderModal types={allTypes} onSubmit={onSubmitModal} setModalData={setModalData} modalData={modalData}
                             courses={curriculumCourses}/>
            <BooksOrderTable data={list} selectedId={selectedId} handleSelect={handleSelect} setModal={setModalData}
                             sortBy={searchData.sortBy} setSort={setSort} onDelete={onDelete}/>
            <PaginationComponent/>
        </SceneContainer>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(BooksOrderScene);
