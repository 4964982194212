import React from 'react';
import PropTypes from 'prop-types';
import DataTableCol from "../../DataTable/Column";
import DataTable from "../../DataTable";

const LitersList = ({ list, onSelectItem, selectedItem }) => {
  return (
    <div className={'mt-2 mb-2'}>
      <DataTable data={list}>
        <DataTableCol title="Название" value="name" colSpan={6}/>
        <DataTableCol title="Выделено" colSpan={1} resolver={(item) =>
          <input
            type="checkbox"
            checked={item.id === selectedItem}
            onChange={() => onSelectItem(item.id)}
          />
        }/>
      </DataTable>
    </div>
  );
};

LitersList.propTypes = {
  list: PropTypes.array,
  onSelectItem: PropTypes.func,
  selectedItem: PropTypes.any
};

export default LitersList;