import { ERROR, REPORT_GET_REQUEST, START, SUCCESS } from "../action-types";

export const reportGetRequestStart = ({id, formData}) => ({
  type: `${REPORT_GET_REQUEST}${START}`,
  payload: { id, formData }
});

export const reportGetRequestSuccess = ({id, data}) => ({
  type: `${REPORT_GET_REQUEST}${SUCCESS}`,
  payload: {id, data}
});

export const reportGetRequestError = (data) => ({
  type: `${REPORT_GET_REQUEST}${ERROR}`,
  payload: data
});
