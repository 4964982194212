import { ERROR, BOOK_EDIT_INV_NUMBERS_DELETE_REQUEST, START, SUCCESS } from "../../action-types";
import {createNotification} from "../../../../components/notification/notification";

export const bookInvNumberDeleteRequestStart = (number) => ({
  type: `${BOOK_EDIT_INV_NUMBERS_DELETE_REQUEST}${START}`,
  payload: number
});

export const bookInvNumberDeleteRequestSuccess = (data) => {
  if(data.ERROR) {
    createNotification('warning', 'Невозможно удалить, найдено движение этого номера')
  } else {
    createNotification('success', 'Успешно удалено')
  }
  return {
    type: `${BOOK_EDIT_INV_NUMBERS_DELETE_REQUEST}${SUCCESS}`,
    payload: data
  };
};

export const bookInvNumberDeleteRequestError = (data) => ({
  type: `${BOOK_EDIT_INV_NUMBERS_DELETE_REQUEST}${ERROR}`,
  payload: data
});