import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {ELECTRONIC_BOOK_JSON_SAVE, ELECTRONIC_BOOK_ZIP_SAVE, START} from "../../actions/action-types";
import {saveJson, saveZip} from "../../../api/electronicBookImport/api";
import {
    electronicBookJsonSaveError,
    electronicBookJsonSaveSuccess
} from "../../actions/ElectronicBookImport/saveJson";
import {electronicBookZipSaveError, electronicBookZipSaveSuccess} from "../../actions/ElectronicBookImport/saveZip";


export default function* ElectronicBookImportWatcher() {
    yield takeLatest(`${ELECTRONIC_BOOK_JSON_SAVE}${START}`, electronicBookJsonSaveWorker);
    yield takeLatest(`${ELECTRONIC_BOOK_ZIP_SAVE}${START}`, electronicBookZipSaveWorker);
}

function* electronicBookJsonSaveWorker({ payload: file }) {
    try {
        const response = yield call(saveJson, file);
        yield put(electronicBookJsonSaveSuccess(response.data));
    } catch (error) {
        yield put(electronicBookJsonSaveError(error));
    }
}

function* electronicBookZipSaveWorker( { payload: data, callback } ) {
    try {
        const response = yield call(saveZip, data);
        yield put(electronicBookZipSaveSuccess(`https://biblio.nintegra.ru/public/files/ebook/${response.data.id}`));
        callback();
    } catch (error) {
        yield put(electronicBookZipSaveError(error));
    }
}


