import { ERROR, PUBLISHERS_SAVE_REQUEST, START, SUCCESS } from "../action-types";

export const savePublishersDataRequestStart = (data, callback) => ({
  type: `${PUBLISHERS_SAVE_REQUEST}${START}`,
  payload: data, callback
});

export const savePublishersDataRequestSuccess = (data) => ({
  type: `${PUBLISHERS_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const savePublishersDataRequestError = (error) => ({
  type: `${PUBLISHERS_SAVE_REQUEST}${ERROR}`,
  payload: error
});

