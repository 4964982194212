import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from './ReadingListExportButton.module.css';
import {resolveExportLink, resolveOAIExportLink} from "../../../../../utils/resolveExportLinks";
import Button from "../../../../Button";
import classNames from 'classnames';
import {api} from "../../../../../App";
import {saveAs} from 'file-saver';
import {createNotification} from "../../../../notification/notification";

const ReadingListExportButton = ({bookId}) => {
    const [open, setOpen] = useState(false);

    const downloadFile = (link, format) =>
        api.get(link)
            .then(response => {
                let fileData = response.data;

                if(format === 'json') fileData = JSON.stringify(fileData);

                const blob = new Blob([fileData], {
                    type: response.headers['content-type']
                });

                saveAs(blob, `${bookId}.${format}`)
            })
            .catch((err) => {
                console.log(err)
                createNotification('error', 'Ошибка загрузки файла')
            });

    return (
        <div className={classNames(styles.container, 'ml-2')}>
            <Button
                label={'Экспорт'}
                colorType={'transparent'}
                onClick={() => setOpen(!open)}
            />
            {open &&
            <div className={styles.dropdown} onClick={() => setOpen(!open)}>
                <span onClick={() => downloadFile(resolveExportLink([bookId], "rusmarc"), 'rusmarc')} className={styles.link}>
                    RUSMARC
                </span>
                <span onClick={() => downloadFile(resolveExportLink([bookId], "json"), 'json')} className={styles.link}>
                    JSON
                </span>
                <span onClick={() => downloadFile(resolveOAIExportLink(bookId), 'xml')} className={styles.link}>
                    OAI-PMH
                </span>
            </div>
            }
        </div>
    );
};

ReadingListExportButton.propTypes = {
    bookId: PropTypes.string
};

export default ReadingListExportButton;
