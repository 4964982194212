import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./LibraryPointsTab.module.css";
import classNames from "classnames";
import { Col, Row } from "react-bootstrap";
import { libraryDeletePointRequestStart } from "../../../../redux/actions/library/info/point/delete";
import { librarySavePointRequestStart } from "../../../../redux/actions/library/info/point/save";
import { connect } from "react-redux";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import { nonNull } from "../../../../utils/strings";
import Button from "../../../Button";
import { ADD } from "../../../../constants/modalTypes";
import { libraryAddPoint } from "../../../../redux/actions/library/info/add/points";

const mapStateToProps = ({ library }) => ({
  addPoints: library.info.addPoints
});

const mapDispatchToProps = dispatch => ({
  savePoint: (data, libraryId) =>
    dispatch(librarySavePointRequestStart(data, libraryId)),
  deletePoint: (pointId, libraryId) =>
    dispatch(libraryDeletePointRequestStart(pointId, libraryId)),
  libraryAddPoint: data => dispatch(libraryAddPoint(data))
});

const LibraryPointsTab = ({
  data,
  savePoint,
  deletePoint,
  libraryId,
  modalType,
  libraryAddPoint,
  addPoints
}) => {
  const emptyFormItem = {
    name: ""
  };

  const [formItem, setFormItem] = useState(null);

  const handleSelect = item => {
    setFormItem(formItem && formItem.id === item.id ? null : item);
  };

  const onSave = () => {
    if (modalType !== ADD) {
      savePoint(formItem, libraryId);
      setFormItem(null);
    } else {
      libraryAddPoint(formItem);
      setFormItem(null);
    }
  };

  const onDelete = () => {
    formItem && formItem.id && deletePoint(formItem.id, libraryId);
    setFormItem(null);
  };

  const onChange = ({ target: { value } }) => {
    setFormItem({ ...formItem, name: value });
  };

  const addPointsMap = (item, index) => (
    <div className={classNames(styles.item)} key={index}>
      {item.name}
    </div>
  );

  const dataMap = (item, index) => (
    <div
      className={classNames(styles.item, {
        [styles.selected]: formItem && formItem.id === item.id
      })}
      key={index}
      onClick={() => handleSelect(item)}
    >
      {item.name}
    </div>
  );

  return (
    <div className={styles.container}>
      <Row>
        <Col md={7}>
          <div className={styles.list}>
            {modalType !== ADD
              ? data.map(dataMap)
              : addPoints.map(addPointsMap)}
          </div>
        </Col>
        <Col md={5}>
          {formItem && (
            <div className={styles.formContainer}>
              <ModalInputWrapper
                label={"Название"}
                value={nonNull(formItem.name)}
                onChange={onChange}
              />
              <Button
                label={formItem.id ? "Изменить" : "Добавить"}
                onClick={onSave}
              />
            </div>
          )}
        </Col>
        <Col md={5}>
          <div className={styles.buttonsContainer}>
            <div
              className={styles.listButton}
              onClick={() => setFormItem(emptyFormItem)}
            >
              Добавить
            </div>
            {modalType !== ADD && (
              <div className={styles.listButton} onClick={onDelete}>
                Удалить
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

LibraryPointsTab.propTypes = {
  data: PropTypes.array,
  libraryId: PropTypes.any
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LibraryPointsTab);
