import React from "react";
import PropTypes from "prop-types";
import qs from "qs";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";
import styles from "./InvSearchFormControls.module.css";
import { Link } from "react-router-dom";
import { ADD } from "../../../constants/modalTypes";
import { BASE_URL_WITHOUT_API } from "../../../constants/api";

const InvSearchFormControls = ({
  onSubmit,
  onClear,
  onDelete,
  setModal,
  selectedIds
}) => {

  const prtFormular = () => {
    const formularWin = window.open(
      `${BASE_URL_WITHOUT_API}/inv_printformular/${selectedIds[0]}`
    );
    formularWin.focus();
  };

  const printEnvelope = () => {
    const printedDoc = window.open(
      `${BASE_URL_WITHOUT_API}/envelope_print/${selectedIds[0]}`
    );
    printedDoc.focus();
  };

  return (
    <Row>
      <Col md={6}>
        <div
            className={styles.button}
             onClick={() => setModal({ modalType: ADD, data: {} })}
        >
          Добавить
        </div>
        <div
          className={classNames(styles.button, {
            [styles.disabled]: selectedIds.length !== 1
          })}
          onClick={onDelete}
        >
          Удалить
        </div>
        <Link
          target="_blank"
          to={{
            pathname: "/qr_codes",
            search: qs.stringify({ ids: selectedIds }, { encode: false })
          }}
          className={classNames(styles.button, {
            [styles.disabled]: !selectedIds.length
          })}
        >
          Печать этикеток
        </Link>
        <div
          onClick={prtFormular}
          className={classNames(styles.button, {
            [styles.disabled]: !selectedIds.length || selectedIds.length > 1
          })}
        >
          Печать формуляра
        </div>
      </Col>
      <Col md={{ span: 6 }} className="text-right">
      <div
          onClick={printEnvelope}
          className={classNames(styles.button, {
            [styles.disabled]: !selectedIds.length || selectedIds.length > 1
          })}
        >
          Печать конверта
        </div>
        <div className={styles.button} onClick={onClear}>
          Очистить
        </div>
        <div
          className={classNames(styles.button, styles.buttonFind)}
          onClick={onSubmit}
        >
          Найти
        </div>
      </Col>
    </Row>
  );
};

InvSearchFormControls.propTypes = {
  onSubmit: PropTypes.func,
  onClear: PropTypes.func,
  setModal: PropTypes.func,
  onDelete: PropTypes.func,
  selectedId: PropTypes.any
};

export default InvSearchFormControls;
