import { LIBRARY_FUNDS_REQUEST, SUCCESS, START } from "../../../actions/action-types";

const initialState = [];

export const libraryFundAllocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LIBRARY_FUNDS_REQUEST}${START}`:
      return [];
    case `${LIBRARY_FUNDS_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
