import React, {useRef, useEffect} from "react";
import PropTypes from "prop-types";
import {EDIT} from "../../../constants/modalTypes";
import styles from "../../Authors/Modal/AuthorModal.module.css";
import {Col, OverlayTrigger, Row} from "react-bootstrap";
import ModalImagePopover from "../../Modal/ImagePopover";
import ModalImage from "../../Modal/ImageWrapper";
import {usersGetPhotoRequestStart} from "../../../redux/actions/adminUsers/photo/getPhoto";
import {usersSavePhotoRequestStart} from "../../../redux/actions/adminUsers/photo/savePhoto";
import {connect} from "react-redux";
import {usersDeletePhotoRequestStart} from "../../../redux/actions/adminUsers/photo/deletePhoto";
import {changeUserPassword} from "../../../api/adminUsers/users";
import ModalWindow from "../../ModalWindow";
import AdminUsersModalForm from "./Form";
import {setUserPhoto} from "../../../redux/actions/adminUsers/photo/setPhoto";
import {usersPhotoReset} from "../../../redux/actions/adminUsers/photo/resetPhoto";
import SpinnerOuter from "../../UtulityComponents/SpinnerOuter";

const mapStateToProps = ({adminUsers}) => ({
    imgUrl: adminUsers.photo
});

const mapDispatchToProps = {
    requestPhoto: usersGetPhotoRequestStart,
    savePhoto: usersSavePhotoRequestStart,
    setPhoto: setUserPhoto,
    deletePhoto: usersDeletePhotoRequestStart,
    changePassword: changeUserPassword,
    resetPhoto: usersPhotoReset
};

const AdminUsersModal = ({
                             setModalData,
                             onlyInfo,
                             onSubmit,
                             modalData,
                             imgUrl,
                             requestPhoto,
                             deletePhoto,
                             setPhoto,
                             resetPhoto
                         }) => {
    const {modalType, data} = modalData;
    const isVisible = !!modalType;
    const imageInput = useRef(null);

    useEffect(() => {
        if (data && data.photosize && !imgUrl) {
            requestPhoto(data.mid);
        }
    }, [data, imgUrl, requestPhoto]);

    const onDeleteImage = () => {
        deletePhoto(data.mid);
    };

    const onSaveImage = () => {
        imageInput.current.click();
    };

    const onLoadImage = event => {
        setPhoto(event.target.files[0]);
    };

    const onSubmitModal = (values) => {
        onSubmit({...modalData, data: values});
    };

    const resolveUserPhoto = (photo) => {
        if (typeof photo === 'string') {
            return photo
        } else if (typeof photo === 'object') {
            return URL.createObjectURL(photo)
        }
    };

    return (
        <ModalWindow
            isOpen={isVisible}
            title={`Пользователь: ${modalType === EDIT ? "Редактирование" : "Добавление"}`}
            onRequestClose={() => {
                setModalData({modalType: null, data: null});
                resetPhoto();
            }}
            width={'600px'}
        >
            {data && (
                <Row>
                    <Col md={3}>
                        <div className={styles.imageContainer}>
                            {!imgUrl && data.photosize > 0 ? <SpinnerOuter isLoading/> :
                                <OverlayTrigger
                                    trigger="click"
                                    placement="right"
                                    overlay={props => (
                                        <ModalImagePopover
                                            deleteImage={onDeleteImage}
                                            uploadImage={onSaveImage}
                                            {...props}
                                        />
                                    )}
                                >
                                    {imgUrl ? (
                                        <ModalImage url={resolveUserPhoto(imgUrl)}/>
                                    ) : (
                                        <ModalImage placeholder/>
                                    )}

                                </OverlayTrigger>
                            }
                            <input
                                type="file"
                                className={styles.fileInput}
                                ref={imageInput}
                                onChange={onLoadImage}
                                accept="image/*"
                            />
                        </div>
                    </Col>

                    <Col md={9}>
                        <AdminUsersModalForm
                            modalType={modalType}
                            initialValues={modalData.data}
                            onSubmit={onSubmitModal}
                            onlyInfo={onlyInfo}/>
                    </Col>

                </Row>
            )}
        </ModalWindow>
    );
};

AdminUsersModal.propTypes = {
    modalData: PropTypes.object,
    setModalData: PropTypes.func,
    onlyInfo: PropTypes.bool,
    onSubmit: PropTypes.func
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminUsersModal);
