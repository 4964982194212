import React, {useMemo} from 'react';
import FundMovementTable from "../Table";
import PropTypes from 'prop-types';

const FundMovingContainer = ({data}) => {
    let updatedData = {};
    data.forEach(item => {
        if (updatedData[item.fund_id]) {
            updatedData[item.fund_id].types.push(item);
        } else {
            updatedData[item.fund_id] = {
                name: item.main_type,
                types: [item]
            }
        }
    });

    const tableIds = useMemo(() => Object.keys(updatedData).map(item => `[id='${updatedData[item].name}']`), [data]);

    return (
        <>
            {/*{!!Object.keys(updatedData).length && <DoublePrint title={'Книга-Движения'} tablesArray={tableIds}/>}*/}
            {Object.keys(updatedData).map((item, index) =>
                <FundMovementTable key={index} data={updatedData[item].types} title={updatedData[item].name}/>
            )}
        </>
    );
};

FundMovingContainer.propTypes = {
    data: PropTypes.array
};

export default FundMovingContainer;