import React from "react";
import DataTableCol from "../../DataTable/Column";
import DataTable from "../../DataTable";
import moment from "moment";
import {STATUS_LIST} from "../../../scenes/BookScanPlan";
import CoverPhotoPlaceholder from "../../Covers/List/Item/CoverPhotoPlaceholder";


const List = ({data = [], uploadPdf, openModal}) => {

    return (
        <DataTable data={data} onCellClick={openModal}>
            <DataTableCol title={''} resolver={item => <CoverPhotoPlaceholder url={item.coverImage} bookId={item.bookId}
                                                                              alt={item.title}/>}/>
            <DataTableCol colSpan={3} title={'Название'} value={'title'}/>
            <DataTableCol title={'Авторы'} resolver={item => (item.authors && item.authors.length > 0 &&
                item.authors.map(author => author.name))}
            />
            <DataTableCol title={'Издательства'} resolver={item => (item.publishers && item.publishers.length > 0
                && item.publishers.map(publisher => publisher.name))}
            />
            <DataTableCol title={'Год'} value={'year'}/>
            <DataTableCol title={'ISBN'} value={'isbn'}/>
            <DataTableCol title={'Страниц'} value={'pageNum'}/>
            <DataTableCol title={'Дата'}
                          resolver={item => item.timestamp && moment(item.timestamp).format("DD.MM.YYYY")}/>
            <DataTableCol title={'Статус'} resolver={item => (item.status && STATUS_LIST.get(item.status))}/>
            <DataTableCol title={'Создатель'} value={'uploader'}/>
            {/*
      <DataTableCol title={''} resolver={item => (
        <ChoosePDF
          onChange={file =>
            uploadPdf({
              bookId: item.bookId,
              file: file,
              body: {
                filedate: new Date().toISOString()
              }
            })
          }
        >
          <Button label={'+файл'} />
        </ChoosePDF>
      )}/>
      */}
        </DataTable>
    );
};

export default List;
