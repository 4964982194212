import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import styles from './ElectronicBookImport.module.css';
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import Input from "../../components/ElectronicBookImport/Input";
import {electronicBookJsonSaveStart} from "../../redux/actions/ElectronicBookImport/saveJson";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import BookData from "../../components/ElectronicBookImport/BookData";
import {api} from "../../App";
import {createNotification} from "../../components/notification/notification";
import {electronicBookResetData} from "../../redux/actions/ElectronicBookImport/reset";
import {electronicBookZipSaveStart} from "../../redux/actions/ElectronicBookImport/saveZip";

const mapStateToProps = ({electronicBook}) => ({
    jsonImport: electronicBook.jsonImport,
    zipURL: electronicBook.zipURL
});

const mapDispatchToProps = {
    saveJson: electronicBookJsonSaveStart,
    saveZip: electronicBookZipSaveStart,
    resetData: electronicBookResetData
};

const ElectronicBookImport = ({jsonImport, saveJson, saveZip, resetData, zipURL}) => {

    const [showData, setShowData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bookDeleteLoading, setBookDeleteLoading] = useState(false);
    const [urlLoading, setUrlLoading] = useState(false);

    useEffect(() => {
        if (jsonImport.title) {
            setLoading(false);
            setShowData(true);
        }
    }, [jsonImport]);

    const jsonInputOnChangeHandler = (fileData) => {
        setLoading(true);
        const file = new FormData();
        file.append("file", fileData);
        saveJson(file);
    };

    const zipInputOnChangeHandler = (fileData) => {
        setUrlLoading(true);
        const file = new FormData();
        file.append("file", fileData);
        saveZip(
            {
                file,
                id: jsonImport.id
            },
            () => setUrlLoading(false)
        )


    };

    const deleteNoteHandler = (id) => {
        setBookDeleteLoading(true);
        api.delete(`/book/${id}`).then(() => {
            createNotification('success', 'Библиографическая запись удалена');
            setBookDeleteLoading(false);
            setShowData(false);
            resetData();
        });
    };

    return (
        <SceneContainer title="Загрузка электронного учебника">
            <div className={styles.container}>
                {loading ? <SpinnerOuter isLoading/>
                    :
                    <>
                        {showData ?
                            <BookData
                                data={jsonImport}
                                deleteNoteHandler={deleteNoteHandler}
                                bookDeleteLoading={bookDeleteLoading}
                                inputHandler={zipInputOnChangeHandler}
                                href={zipURL}
                                urlLoading={urlLoading}
                                setUrlLoading={setUrlLoading}
                            />
                            :
                            <Input
                                inputHandler={jsonInputOnChangeHandler}
                                label={'Загрузить JSON'}
                            />
                        }
                    </>
                }
            </div>
        </SceneContainer>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ElectronicBookImport);
