import {ELECTRONIC_BOOK_ZIP_SAVE, START, SUCCESS, ERROR} from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const electronicBookZipSaveStart = (data, callback) => ({
    type: `${ELECTRONIC_BOOK_ZIP_SAVE}${START}`,
    payload: data, callback
});

export const electronicBookZipSaveSuccess = (data) => {
    createNotification('success', 'Архив успешно загружен');
    return {
        type: `${ELECTRONIC_BOOK_ZIP_SAVE}${SUCCESS}`,
        payload: data
    };
};

export const electronicBookZipSaveError = (data) => {
    createNotification('error', 'Ошибка сохраения архива');
    return {
        type: `${ELECTRONIC_BOOK_ZIP_SAVE}${ERROR}`,
        payload: data
    };
};