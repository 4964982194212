import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { ALPHABET_COUNT_REQUEST, ALPHABET_ITEMS_REQUEST, START } from "../../../../actions/action-types";
import {
  alphabetItemsRequestError,
  alphabetItemsRequestStart,
  alphabetItemsRequestSuccess
} from "../../../../actions/library/alphabet/items";
import { alphabetBooks, alphabetCount } from "../../../../../api/library/alphabet";
import { alphabetCountRequestSuccess } from "../../../../actions/library/alphabet/count";
import { setAlphabetSearchData } from "../../../../actions/library/alphabet/search";

const ALL_LETTERS = "аа - ая";

export default function* alphabetItemsWatcher() {
  yield takeLatest(`${ALPHABET_COUNT_REQUEST}${START}`, alphabetCountWorker);
  yield takeLatest(`${ALPHABET_ITEMS_REQUEST}${START}`, alphabetItemsWorker);
}

function* alphabetItemsWorker() {
  const searchData = yield select(({library}) => library.alphabet.search);
  const request = formRequest(searchData);
  try {
    const response = yield call(alphabetBooks, request);
    yield put(alphabetItemsRequestSuccess(response.data));
  } catch (error) {
    yield put(alphabetItemsRequestError(error))
  }
}

function* alphabetCountWorker() {
  const searchData = yield select(({library}) => library.alphabet.search);
  if (searchData.letters === "") {
    searchData.letters = ALL_LETTERS;
  }
  const request = formRequest(searchData);
  try {
    const response = yield call(alphabetCount, request);
    const count = parseInt(response.data.count, 10);
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(alphabetCountRequestSuccess(count));
    yield put(setAlphabetSearchData({...searchData, pageCount}));
    yield put(alphabetItemsRequestStart())
  } catch (error) {
    yield put(alphabetItemsRequestError(error))
  }
}

const formRequest = (searchData) => {
  const request = {
    ...searchData,
    afrom: "",
    ato: "",
    paginateFrom: searchData.maxResults * (searchData.page - 1)
  };
  const splittedLetters = searchData.letters.split(' - ');
  request.afrom = splittedLetters[0];
  request.ato = splittedLetters[1];
  return request;
};