import React, {useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import styles from "./BookLoanItem.module.css";
import {Col, Row} from "react-bootstrap";
import Button from "../../../Button";
import CoverPhotoPlaceholder from "../../../Covers/List/Item/CoverPhotoPlaceholder";
import CoverListItemInfo from "../../../Covers/List/Item/InfoBlock";
import ModalWindow from "../../../ModalWindow";
import ModalInputWrapper from "../../../Modal/InputWrapper";


const dateFormatString = "DD.MM.YYYY hh:mm";
const dateFormatStringWithoutTime = "DD.MM.YYYY";

const BookLoanItem = ({
                          data,
                          reserve,
                          unreserveBook,
                          onTurnIn,
                          currentMid,
                          isOwn,
                          onDelete,
                          onEdit,
                          isCompact,
                          isLibrarian
                      }) => {
    const [bookEditModalOpen, setBookEditModalOpen] = useState(false);
    const [bookDates, setBookDates] = useState({
        movedate: moment(data.movedate).format('YYYY-MM-DD'),
        returndate: data.returndate ? moment(data.returndate).format('YYYY-MM-DD') : null
    });
    const [isEditModalError, setEditModalError] = useState(false);

    const isIssueOfBook = !!(data.typeto === 2 && data.placeto === currentMid);
    const isReturnOfBook = !!(data.typefrom === 2 && data.placefrom === currentMid);


    const onChange = (e) => {
        setBookDates({
            ...bookDates,
            [e.target.name]: e.target.value
        })
    };

    const onSubmitEdit = () => {
        const isMovingDateAfterReturnDate = moment(bookDates.movedate).isAfter(bookDates.returndate);

        if (!isMovingDateAfterReturnDate) {
            onEdit({id: data.id, data: bookDates});
            setBookEditModalOpen(false);
        }
        setEditModalError(isMovingDateAfterReturnDate);
    };

    const onSubmitDelete = () => {
        onDelete({id: data.id, mid: currentMid});
    };


    return (
        <>
            <ModalWindow
                isOpen={bookEditModalOpen}
                onRequestClose={() => setBookEditModalOpen(false)}
                title={"Редактировать книгу"}
                width={'500px'}
                height={'350px'}
            >
                {isIssueOfBook && <>
                    <ModalInputWrapper
                        name="movedate"
                        value={bookDates.movedate}
                        onChange={onChange}
                        type={"date"}
                        label={"Дата выдачи"}
                    />
                    <ModalInputWrapper
                        name="returndate"
                        value={bookDates.returndate}
                        onChange={onChange}
                        type={"date"}
                        label={"Планируемая дата возврата"}
                    />
                </>}
                {isReturnOfBook && <>
                    <ModalInputWrapper
                        name="movedate"
                        value={bookDates.movedate}
                        onChange={onChange}
                        type={"date"}
                        label={"Дата возврата"}
                    />
                </>}
                {isEditModalError && <div className={styles.formError}>
                    Ошибка! Неверно указаны даты выдачи.
                </div>}
                <div className="d-flex justify-content-end mt-3">
                    <Button
                        label={'Сохранить'}
                        onClick={() => onSubmitEdit(data.id)}
                    />
                </div>
            </ModalWindow>
            {isCompact ? (
                <tr>
                    <td>
                        {data.handedout && <>
                            {data.handedout && moment(bookDates.movedate).format(dateFormatStringWithoutTime)}
                        </>}
                        {data.movedate && <>
                            {data.movedate && moment(bookDates.movedate).format(dateFormatStringWithoutTime)}
                        </>}

                        {isReturnOfBook && <>
                            {data.movedate && moment(bookDates.movedate).format(dateFormatStringWithoutTime)}
                        </>}
                    </td>
                    <td>
                        {data.number}
                    </td>
                    <td>
                        <div className={'text-left'}>
                            <div><b>{data.title}</b></div>
                            <div>
                                {data.authors && data.authors.map(item => `${item.name} `)}
                            </div>
                        </div>
                    </td>
                    <td>
                        {data.editable && isLibrarian &&
                        <div className="d-flex justify-content-end">
                            <Button
                                label={"Редактировать"}
                                onClick={() => setBookEditModalOpen(true)}
                            />
                            <Button
                                label={"Удалить"}
                                colorType={'red'}
                                style={'ml-3'}
                                onClick={onSubmitDelete}
                            />
                        </div>
                        }
                        {onTurnIn && !isOwn && (
                            <Col md={12} className="text-right">
                                <Button onClick={onTurnIn} label="Сдать"/>
                            </Col>
                        )}
                        {reserve && (
                            <Col md={12} className="text-right">
                                <Button
                                    onClick={() => unreserveBook(data.id)}
                                    label="Снять бронь"
                                />
                            </Col>
                        )}
                    </td>
                </tr>
            ) : (
                <div className={styles.container}>
                    <Row>
                        <Col md={2}>
                            <CoverPhotoPlaceholder
                                url={data.coverImage}
                                bookId={data.bookid}
                                alt={data.title}
                            />
                        </Col>
                        <Col md={10}>
                            <Row>

                                <Col md={3}>
                                    {isIssueOfBook &&
                                    <div className={classNames(styles.label, styles.state, styles.labelIssue)}>
                                        выдача
                                    </div>}
                                    {isReturnOfBook && <div className={classNames(styles.label, styles.state)}>
                                        возврат
                                    </div>}
                                </Col>

                                <Col md={9}>
                                    <div className={classNames(styles.value, styles.title)}>
                                        {data.title}
                                    </div>
                                </Col>
                            </Row>
                            {!!data.authors && (
                                <CoverListItemInfo label={"Авторы"} value={Array.isArray(data.authors)
                                    ? data.authors.map(a => a.name || a).join(", ")
                                    : data.authors}/>
                            )}

                            {!!data.year && (
                                <CoverListItemInfo label={"Год"} value={data.year}/>
                            )}
                            {!!data.publishers && (
                                <CoverListItemInfo label={"Издательство"}
                                                   value={data.publishers.map((item, index) => <div
                                                       key={index}>{item.name}</div>)}/>
                            )}
                            {!!data.isbn && (
                                <CoverListItemInfo label={"ISBN"} value={data.isbn}/>
                            )}
                            {!!data.number && (
                                <CoverListItemInfo label={"Инв.номер"} value={data.number}/>
                            )}
                            {!!data.pageNum && (
                                <CoverListItemInfo label={"Страниц"} value={data.pageNum}/>
                            )}

                            {isIssueOfBook &&
                            <>
                                <CoverListItemInfo
                                    label={"Дата выдачи"}
                                    value={data.movedate ? moment(bookDates.movedate).format(dateFormatString) : 'Не установлено'}
                                />
                            </>
                            }

                            {data.handedout &&
                            <>
                                <CoverListItemInfo
                                    label={"Дата выдачи"}
                                    value={data.handedout ? moment(bookDates.movedate).format(dateFormatString) : 'Не установлено'}
                                />
                            </>
                            }

                            {data.returndate &&
                            <>
                                <CoverListItemInfo
                                    label={"Планируемая дата возврата"}
                                    value={data.returndate ? moment(bookDates.movedate).format(dateFormatString) : 'Не установлено'}
                                />
                            </>
                            }

                            {isReturnOfBook && <>
                                <CoverListItemInfo
                                    label={"Дата возврата"}
                                    value={data.movedate ? moment(bookDates.movedate).format(dateFormatString) : 'Не установлено'}
                                />
                            </>}

                            {data.editable && isLibrarian &&
                            <div className="d-flex justify-content-end">
                                <Button
                                    label={"Редактировать"}
                                    onClick={() => setBookEditModalOpen(true)}
                                />
                                <Button
                                    label={"Удалить"}
                                    colorType={'red'}
                                    style={'ml-3'}
                                    onClick={onSubmitDelete}
                                />
                            </div>
                            }
                        </Col>
                        {onTurnIn && !isOwn && (
                            <Col md={12} className="text-right">
                                <Button onClick={onTurnIn} label="Сдать"/>
                            </Col>
                        )}
                        {reserve && (
                            <Col md={12} className="text-right">
                                <Button
                                    onClick={() => unreserveBook(data.id)}
                                    label="Снять бронь"
                                />
                            </Col>
                        )}
                    </Row>
                </div>
            )}
        </>
    );
};

BookLoanItem.propTypes = {
    data: PropTypes.object,
    reserve: PropTypes.bool
};

export default BookLoanItem;
