import { api } from '../../App';

export const searchCovers = (formData, {maxResults, paginateFrom}) => {

  const params = {
    maxResults, paginateFrom,
  };
  return api.post('/search', formData, { params });
};

export const countCovers = (formData) => {
  const params = {
    count: true,
  };
  return api.post('/search', formData, { params });
};

export const countSimpleCoversSearch = (searchString = "") => {
    const params = {
        count: true,
    };
    return api.post(`/search?search=${searchString}`, null, { params });
};

export const simpleCoversSearch = (searchString = "", {maxResults, paginateFrom}) => {
    const params = {
        maxResults, paginateFrom,
    };

    return api.post(`/search?search=${searchString}`, null, { params });
};