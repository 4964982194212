import { SET_LATEST_DOCUMENT } from "../../../actions/action-types";

const initialState = null;

export const documentLatestReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_LATEST_DOCUMENT:
      return action.payload;

    default: return state;
  }
};
