import { api } from "../../App";
import {
  START,
  SUCCESS,
  REMCOMPLECT_COUNT_REQUEST,
  REMCOMPLECT_LIST_REQUEST
} from "../actions/action-types";
import { createSimpleActionsForReducer } from "../actions/actionsFactory";
import { createSimpleReducer } from "../reducers/reducersFactrory";
import {
  call,
  put,
  select,
  takeLatest
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";
import { setRemcomplectSearchData } from "../../redux/actions/remcomplect/search";

const getCount = () => {
  return api.get("/fundwithdrawal/?count");
};

const getList = (maxResults, paginateFrom) => {
  return api.get(
    `/fundwithdrawal/?maxResults=${maxResults}&paginateFrom=${paginateFrom}`
  );
};

export const remcomplectActions = {
  ...createSimpleActionsForReducer("count", REMCOMPLECT_COUNT_REQUEST),
  ...createSimpleActionsForReducer("list", REMCOMPLECT_LIST_REQUEST)
};

export const remcomplectReducers = {
  count: createSimpleReducer(0, `${REMCOMPLECT_COUNT_REQUEST}${SUCCESS}`),
  list: createSimpleReducer([], `${REMCOMPLECT_LIST_REQUEST}${SUCCESS}`)
};

export function* remcomplectWatcher() {
  yield takeLatest(`${REMCOMPLECT_COUNT_REQUEST}${START}`, remcomplectCount);
  yield takeLatest(`${REMCOMPLECT_LIST_REQUEST}${START}`, remcomplectList);
}

function* remcomplectCount() {
  const searchData = yield select(({ remcomplect }) => remcomplect.search);
  try {
    yield put(setRemcomplectSearchData({ ...searchData }));
    const response = yield call(getCount);
    const count = parseInt(response.data.count, 10);
    yield put(remcomplectActions.countRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setRemcomplectSearchData({ ...searchData, pageCount }));
    yield put(remcomplectActions.listRequestStart());
  } catch (e) {
    console.log(e);
  }
}

function* remcomplectList() {
  const searchData = yield select(({ remcomplect }) => remcomplect.search);
  const paginateFrom = searchData.maxResults * (searchData.page - 1);
  const maxResults = searchData.maxResults;
  try {
    const response = yield call(getList, maxResults, paginateFrom);
    yield put(remcomplectActions.listRequestSuccess(response.data));
    yield put(setRemcomplectSearchData({ ...searchData }));
  } catch (e) {
    console.log(e);
  }
}
