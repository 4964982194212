export const menuStructure = [
    {
        title: "Главная",
        link: "/"
    },
    {
        title: "Комплектование",
        link: "/wip",
        dropdown: [
            {
                title: "Первичные документы",
                link: "/documents"
            },
            {
                title: "Проверка на дублетность",
                link: "/duplicate"
            },
            {
                title: "Рекомплектование",
                link: "/remcomplect"
            },
            {
                title: "Книгообеспеченность",
                link: "/booksupply"
            },
            {
                title: "Нормы книгообеспеченности",
                link: "/booksupplystandarts"
            },
            {
                title: "Инвентарная книга",
                link: "/bookinventory"
            },
            {
                title: "Книга движения",
                link: "/fund_moving"
            },
            {
                title: "Книга суммарного учета",
                link: "/fund_accounting"
            },
            {
                title: "Заказы на подбор литературы",
                link: "/bookorders"
            },
            {
                title: "Заказы на поступление литературы",
                link: "/newbookorders"
            },
            {
                title: "Инвентаризация",
                link: "/inventory"
            }
        ]
    },
    {
        title: "Каталогизация",
        link: "/library/alphabet",
        dropdown: [
            {
                title: "Алфавитный каталог",
                link: "/library/alphabet"
            },
            {
                title: "Авторский знак (таблицы Хавкиной)",
                link: "/havkina"
            },
            {
                title: "УДК",
                link: "/udk"
            },
            {
                title: "Импорт",
                link: "/import"
            },
            {
                title: "Загрузка электронного учебника",
                link: "/electronic_book_import"
            },
            {
                title: "ББК",
                link: "/bbk"
            },
            {
                title: "Рубрикатор",
                link: "/rubricator"
            },
            {
                title: "Выгрузка в изделие 83т260",
                link: `/spo_intergartion`
            }
        ]
    },
    {
        title: "Отчётность",
        link: "/reports",
        dropdown: [
            {
                title: "Отчёты",
                link: "/reports"
            },
            {
                title: "Средство анализа больших данных",
                link: "/bigdata"
            }
        ]
    },
    {
        title: "Справочники",
        link: "/publishers",
        dropdown: [
            {
                title: "Издательства",
                link: "/publishers"
            },
            {
                title: "УДК",
                link: "/udk"
            },
            {
                title: "ББК",
                link: "/bbk"
            },
            {
                title: "Авторы",
                link: "/authors"
            },
            {
                title: "Организации",
                link: "/institutions"
            },
            {
                title: "Библиотеки",
                link: "/library"
            },
            {
                title: "Типы обрабатываемой литературы",
                link: "/library/types"
            },
            {
                title: "Типы документов",
                link: "/document_types"
            },
            {
                title: "Обложки",
                link: "/covers"
            },
            {
                title: "Адресное хранение",
                link: "/location"
            },
            {
                title: "Инвентарные номера",
                link: "/inv_numbers"
            },
            {
                title: "Коллекции",
                link: "/collections"
            },
            {
                title: "Категории",
                link: "/categories"
            },
            {
                title: "Библиографические записи",
                link: "/books"
            },
            {
                title: "Экспорт БЗ",
                link: "/booksexport"
            },
            {
                title: "Серии",
                link: "/series"
            },
            {
                title: "Литера",
                link: "/leters"
            }
        ]
    },
    {
        title: "Сканирование",
        link: "/scan",
        dropdown: [
            {
                title: "План оцифровки бумажных носителей",
                link: "/bookscan"
            },
            {
                title: "План сканирования обложек",
                link: "/covers"
            },
            /*
             Пункты меню скрыты, компоненты не удалены
            {
              title: "Добавление электронных экземпляров",
              link: "/digitalitems"
            },
            {
              title: "Каталожные карточки",
              link: "/catalogcards"
            }*/
        ]
    },
    {
        title: "Книговыдача",
        link: "/book_loan",
        dropdown: [
            {
                title: "Алфавитный указатель",
                link: "/library/alphabet"
            },
            {
                title: "Читательский формуляр",
                link: "/library/reader_search"
            },
            {
                title: "Заявки на выдачу",
                link: "/bookreserve"
            }
            /*
            Пункты меню скрыты,компоненты не удалены
            {
              title: "Книги",
              link: "/book_loan/books"
            },
            {
              title: "По специальности",
              link: "/book_loan/specialty"
            }
             */
        ]
    },
    {
        title: "Электронные ресурсы",
        link: "/external",
        dropdown: [
            {
                title: "Внешние информационные ресурсы",
                link: "/external"
            },
            {
                title: "Электронный читальный зал",
                link: "/reading"
            },
            {
                title: "Поиск ресурсов в сторонней АБИС",
                link: "/external_resources"
            },
            {
                title: "Поиск по картинке",
                link: "/search_by_image"
            },
            {
                title: "Интеграция с изделием 83т645",
            },
            {
                title: "Профессорско-преподавательский состав",
                link: "/search_by_image",
                isSubItem: true
            },
            {
                title: "Расписание",
                link: "/search_by_image",
                isSubItem: true
            }
        ]
    },
    {
        title: "Личный кабинет",
        link: authData => `/library_card/${authData.mid}`,
        dropdown: [
            {
                title: "Профессорско-преподавательский состав",
                link: "/teachers"
            },
            {
                title: "Расписание",
            },
            {
                title: "Общее",
                link: "/schedule",
                isSubItem: true
            },
            {
                title: "Личное расписание",
                link: "/schedule_on_date",
                isSubItem: true
            },

            {
                title: "Результаты",
            },
            {
                title: "Статистика посещаемости",
                link: "/turnout_stats",
                isSubItem: true
            },
            {
                title: "Прохождение электронных курсов",
                link: "/electronic_courses",
                isSubItem: true
            },
            {
                title: "Прохождение электронного тестирования",
                link: "/tests",
                isSubItem: true
            },
            {
                title: "Проверка на заимствования",
                link: "/borrowing_test",
                isSubItem: true
            },

            {
                title: "Задания",
            },
            {
                title: "Индивидуальные задания",
                link: "/student_projects",
                isSubItem: true
            },

            {
                title: "Заказы на сканирование",
                link: "/book_scan_orders",
            },
            {
                title: "Уведомления",
                link: "/message"
            },
            {
                title: "Медиапортал",
            },
            // {
            //     title: "Поисковые запросы",
            //     link: "/search_audit"
            // },
            // {
            //     title: "Уведомления",
            //     link: "/message"
            // },
            // {
            //     title: "Заказы на загрузку",
            //     link: "/book_transfer"
            // },
            // {
            //     title: "Читательский билет",
            //     link: authData => `/library_card/${authData.mid}`
            // }
        ]
    }
];
