import React, {useEffect, useState} from 'react';
import {ADD, EDIT} from "../../../constants/modalTypes";
import {api} from "../../../App";

import {connect} from "react-redux";
import {inventoryBookListRequestStart, inventoryBooksCleanup} from "../../../redux/actions/inventory/books/list";
import {institutionsAllListRequestStart} from "../../../redux/actions/institutions/all";
import {librariesListRequestStart} from "../../../redux/actions/inventory/fundAllocation/libraries";
import {bookDataRequestStart} from "../../../redux/actions/bookData/data";

import DocumentsEdit from './DocumentsEdit';
import ModalHeader from "./DocumentModalHeader";
import ModalWindow from '../../ModalWindow';
import CreateNewLibraryItemModal from './submodals/CreateNewLibraryItemModal/CreateNewLibraryItemModal'


const mapStateToProps = ({inventory, institutions, options}) => ({
    latestNumber: inventory.number,
    institutionsList: institutions.all,
    libraries: inventory.libraries,
    clientData: options.data,
    bookData: inventory.books
});

const mapDispatchToProps = {
    requestBooks: inventoryBookListRequestStart,
    requestInstitutions: institutionsAllListRequestStart,
    requestLibraries: librariesListRequestStart,
    booksCleanup: inventoryBooksCleanup,
    requestData: bookDataRequestStart,
};

const DocumentsModal = ({
                            types,
                            modalData,
                            setModalData,
                            requestBooks,
                            latestNumber,
                            booksCleanup,
                            onSave,
                            institutionsList,
                            requestInstitutions,
                            requestLibraries,
                            libraries,
                            clientData,
                            bookData,
                            requestData,
                        }) => {

    const {modalType, data} = modalData;
    const documentId = data ? data.id : null;
    const isVisible = !!modalType;
    const [searchOpen, setSearchOpen] = useState(false);
    const [currentBook, setCurrentBook] = useState(null);
    const [currentBookData, setCurrentBookData] = useState(null);
    const [formValidity, setFormValidity] = useState(false);
    const [openSaveModal, toggleSaveModal] = useState(false);
    const [documentid, setDocumentid] = useState(false);
    const [showLibraryItemModal, setShowLibraryItemModal] = useState(false);

    useEffect(() => {
        if (modalType === EDIT) setFormValidity(true);
    }, [modalType]);

    useEffect(() => {
        if (modalData.data) setDocumentid(modalData.data.id);
    }, [modalData]);

    useEffect(() => {
        if (documentId && modalType === EDIT) requestBooks(documentId);
    }, [modalData]);

    useEffect(() => {
        requestInstitutions();
    }, [requestInstitutions]);

    useEffect(() => {
        modalType === ADD && setCurrentBook(null);
    }, [modalType]);

    useEffect(() => {
        clientData && requestLibraries(clientData.own_client_id);
    }, [requestLibraries, clientData]);

    const onModalSave = ({bookid, title} = currentBookData) => {

        setCurrentBook({
            bookid,
            title,
            amount: 0,
            price: 0,
            documentid: documentId
        });

        toggleSaveModal(false);
        setSearchOpen(false);
    };

    if (modalType === ADD && !data) {
        setModalData({
            modalType,
            data: {
                documentname: "",
                documentnumber: latestNumber,
                documenttype: "",
                documentdate: new Date().toISOString().split("T")[0],
                supplier: ""
            }
        })
    }

    const onCreateNewBook = () => {
        const params = {
            "title": "",
            "publisher": "",
            "year": 0
        };

        api.post('/book', params)
            .then((res) => {
                requestData(res.data.book.id);
                setShowLibraryItemModal(true);
            });
    };

    const onAddBook = () => {
        setSearchOpen(!searchOpen)
    };

    const onSelectFromSearch = ({bookid, title}) => {
        setCurrentBook({
            bookid,
            title,
            amount: 1,
            price: 0,
            documentid: documentId
        });

        setSearchOpen(false);
    };

    const closeModal = () => {
        booksCleanup();
        setModalData({modalType: null, data: null});
        setSearchOpen(false);
        setCurrentBook(null);
        setCurrentBookData(null);
    };

    const saveDocument = (data) => {
        const {id} = data;
        const params = {
            id,
            ...data
        };
        onSave(params);
    };

    return (
        <ModalWindow
            title={`Документ: ${modalType === EDIT ? "редактирование" : "добавление"}`}
            isOpen={isVisible}
            onRequestClose={closeModal}
        >
            {data && <>
                <ModalHeader
                    modalData={data}
                    libraries={libraries}
                    modalType={modalType}
                    institutionsList={institutionsList}
                    types={types}
                    saveDocument={saveDocument}
                    setFormValidity={setFormValidity}
                    openSaveModal={openSaveModal}
                    toggleSaveModal={toggleSaveModal}
                    onModalSave={onModalSave}
                    setCurrentBookData={setCurrentBookData}
                />

                <DocumentsEdit
                    formValidity={formValidity}
                    modalData={modalData}
                    setCurrentBook={setCurrentBook}
                    currentBook={currentBook}
                    onAddBook={onAddBook}
                    searchOpen={searchOpen}
                    requestBooks={requestBooks}
                    onSelectFromSearch={onSelectFromSearch}
                    onCreateNewBook={onCreateNewBook}
                    documentid={documentid}
                />


                {/*<CreateNewLibraryItemModal*/}
                    {/*showLibraryItemModal={showLibraryItemModal}*/}
                    {/*setShowLibraryItemModal={setShowLibraryItemModal}*/}
                    {/*bookData={bookData}*/}
                    {/*onSelectFromSearch={onSelectFromSearch}*/}
                {/*/>*/}

            </>}
        </ModalWindow>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsModal);