import { api } from '../../App';

export const searchLibraryMaterials = (formData, {maxResults, paginateFrom}, id) => {
  const params = {
    maxResults,
    paginateFrom,
  };
  const searchBody = {
    ...formData,
    typeId: id
  };
  return api.post('/search', searchBody, { params });
};

export const countLibraryMaterials = (formData, id) => {
  const params = {
    count: true,
  };

  const searchBody = {
    ...formData,
    typeId: id
  };

  return api.post('/search',  searchBody, { params });
};
