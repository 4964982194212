import React, {useEffect} from 'react';
import {turnoutStatsCountRequestStart} from "../../redux/actions/turnoutStats/count";
import {setSecurityLogFormData} from "../../redux/actions/securityLog/formData";
import {setSecurityLogSearchData} from "../../redux/actions/securityLog/searchData";
import {connect} from "react-redux";
import Pagination from "../../components/Pagination";
import TurnoutStatsForm from '../../components/TurnoutStats/Form';
import TurnoutStatsTable from '../../components/TurnoutStats/Table';
import {SORT_DIRECTION} from "../../constants/sortDirections";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";

const mapStateToProps = ({securityLog}) => ({
    list: securityLog.list,
    count: securityLog.count,
    formData: securityLog.form,
    searchData: securityLog.search
});

const mapDispatchToProps = dispatch => ({
    requestList: () => dispatch(turnoutStatsCountRequestStart()),
    setFormData: (data) => dispatch(setSecurityLogFormData(data)),
    setSearchData: (data) => dispatch(setSecurityLogSearchData(data))
});

const SecurityAccessLogScene = ({count, list, requestList, formData, setFormData, searchData, setSearchData}) => {
    useEffect(() => {
        requestList()
    }, [requestList]);

    const setSort = (sortBy) => {
        let sortDir = searchData.sortDirection === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;
        setSearchData({...searchData, sortBy, sortDirection: sortDir});
        requestList();
    };

    const setCurrentPage = (page) => {
        setSearchData({...searchData, page});
        requestList()
    };

    return (
        <SceneContainer title={"Статистика посещаемости"}>
            <TurnoutStatsForm data={formData} setData={setFormData} search={requestList}/>

            <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page}
                        setCurrent={setCurrentPage}/>
            <TurnoutStatsTable data={list} sortBy={searchData.sortBy} setSort={setSort}/>
            <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page}
                        setCurrent={setCurrentPage}/>

        </SceneContainer>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityAccessLogScene);
