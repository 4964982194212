import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { RUBRICATOR_DELETE_REQUEST, START } from "../../../actions/action-types";
import { rubricatorDeleteRequestError, rubricatorDeleteRequestSuccess } from "../../../actions/rubricator/delete";
import { rubricatorDelete } from "../../../../api/rubricator/rubricator";
import { rubricatorCountRequestStart } from "../../../actions/rubricator/count";

export default function* rubricatorDeleteWatcher() {
  yield takeLatest(`${RUBRICATOR_DELETE_REQUEST}${START}`, rubricatorDeleteWorker)
}

function* rubricatorDeleteWorker({payload: id}) {
  try {
    const response = yield call(rubricatorDelete, id);
    yield put(rubricatorDeleteRequestSuccess(response.data));
    yield put(rubricatorCountRequestStart());
  } catch (error) {
    yield put(rubricatorDeleteRequestError(error));
  }
}