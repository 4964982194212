import { api } from "../../App";

export const invNumbersList = (formData, {maxResults, paginateFrom}) => {
  const params = {
    maxResults, paginateFrom
  };

  return api.post('/searchinstance', formData, { params });
};

export const invNumbersCount = (formData) => {
  const params = {
    count: true
  };

  return api.post('/searchinstance', formData , { params });
};

export const addInvNumber = (bookId, number) => {
  return api.post(`/book/instance/${bookId}`, { number });
};

export const editInvNumber = (instanceId, number, rfidcode ) => {
  return api.put(`/book/instance/${instanceId}`, { number, rfidcode });
};

export const deleteInvNumber = (number) => {
  return api.delete(`/book/instance/${number}`);
};