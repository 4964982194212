import React, {useState, useEffect} from 'react';
import {Col, Row} from "react-bootstrap";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import styles from "../DocumentsModal.module.css";
import {ADD, EDIT} from "../../../../constants/modalTypes";
import SearchableSelect from "../../../Select";
import {useFormik} from 'formik';
import PropTypes from 'prop-types';
import * as Yup from "yup";
import Button from "../../../Button";
import ModalWindow from "../../../ModalWindow";


const MODAL_SAVE_TYPES = {
    EDIT: 'Сохранить документ',
    ADD: 'Создать документ'
};

const defaultValidationSchema = Yup.object().shape({
    "name": Yup.string()
        .required('Это поле необходимо заполнить'),
    "date_form": Yup.date()
        .required('Выберите дату документа'),
    "datestart": Yup.date()
        .required('Выберите дату начала'),
    "dateend": Yup.date()
        .required('Выберите дату завершения'),

});

const ModalHeader = ({
                         modalData,
                         modalType,
                         institutionsList,
                         types,
                         libraries,
                         saveDocument,
                         setFormValidity,
                         openSaveModal,
                         toggleSaveModal,
                         onModalSave,
                         setCurrentBookData
                     }) => {

    const modalForm = useFormik({
        initialValues: modalData,
        validationSchema: defaultValidationSchema,
        onSubmit: (values) => saveDocument(values),
    });

    const validityCheck = () => {
        modalForm.validateForm()
            .then(response => {
                modalType === ADD || !!Object.entries(response).length ? setFormValidity(false) : setFormValidity(true)
            })
    };

    const resolveDate = date => new Date(date)
        .toLocaleString('ru')
        .split(',')[0]
        .split('.').reverse().join('-');

    useEffect(() => {
        if (modalForm.dirty) validityCheck();
    }, [modalForm.values]);


    const {values, errors} = modalForm;

    return (
        <div style={{minWidth: '80vw'}} >
            <Row>
                <Col md={6}>
                    <ModalInputWrapper
                        label="Имя документа*"
                        name="name"
                        value={values["name"]}
                        onChange={modalForm.handleChange}
                        error={errors["name"]}
                    />

                    <ModalInputWrapper
                        label="Дата документа*"
                        type="date"
                        name="date_form"
                        value={resolveDate(values["date_form"])}
                        onChange={modalForm.handleChange}
                        error={errors["date_form"]}
                    />
                </Col>

                <Col md={6}>
                    <ModalInputWrapper
                        label="Дата начала*"
                        type="date"
                        name="datestart"
                        value={resolveDate(values["datestart"])}
                        onChange={modalForm.handleChange}
                        error={errors["datestart"]}
                    />

                    <ModalInputWrapper
                        label="Дата завершения*"
                        type="date"
                        name="dateend"
                        value={resolveDate(values["dateend"])}
                        onChange={modalForm.handleChange}
                        error={errors["dateend"]}
                    />
                </Col>

                <Col md={12}>
                    <div className={styles.bottomSection}>
                        <Button
                            type={'submit'}
                            label={MODAL_SAVE_TYPES[modalType]}
                            onClick={modalForm.handleSubmit}
                        />
                    </div>
                </Col>
            </Row>

            <ModalWindow
                title={`Документ: сохранение`}
                isOpen={openSaveModal}
                onRequestClose={() => toggleSaveModal(!openSaveModal)}
            >
                <div className={styles.saveModal}>
                    <h4> Перед добавлением книги необходимо сохранить документ</h4>

                    <div className={styles.saveModalButtons}>
                        <Button
                            type={'submit'}
                            colorType={'red'}
                            label={'Отмена'}
                            style={'mr-2'}
                            onClick={() => {
                                toggleSaveModal(false);
                                setCurrentBookData(null);
                            }}
                        />
                        <Button
                            type={'submit'}
                            colorType={'green'}
                            label={'Сохранить'}
                            onClick={() => {
                                modalForm.handleSubmit();
                                onModalSave();
                            }}
                        />
                    </div>

                </div>
            </ModalWindow>
        </div>
    );
};

ModalHeader.propTypes = {
    modalData: PropTypes.object.isRequired,
};

export default ModalHeader;
