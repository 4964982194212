
export const resolveDateWithTimeZone = (date, separator = '.') => {
  //example 2020-01-15T12:01:50.681Z
  if(typeof date !== 'string')
    return console.error('Параметр date должен быть строкой');
  if(separator && typeof separator !== 'string')
    return console.error('Параметр separator должен быть строкой');

  const formattedDate = date
    .split('T')[0]
    .split('-')
    .reverse()
    .join(separator);
  const formattedTime = date
    .split('T')[1]
    .split('.')[0];

  return formattedDate + ' ' + formattedTime;
};