import {
    COVERS_COUNT_REQUEST,
    COVERS_READING_SEARCH_REQUEST, SET_LOCATION_CHANGE,
    SUCCESS
} from "../../../actions/action-types";

const initialState = 0;

export const coversCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${COVERS_COUNT_REQUEST}${SUCCESS}`:
            return action.payload;
        case `${COVERS_READING_SEARCH_REQUEST}${SUCCESS}`:
            return action.payload;
        case SET_LOCATION_CHANGE:
            return initialState;
        default:
            return state;
    }
};