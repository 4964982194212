import React, { useEffect } from "react";
import styles from "./Item.module.css";
import CoverListItemInfo from "../../Covers/List/Item/InfoBlock";
import CollapseBlock from "../Collapse";

const WITHOUT_VALUE = "Нет";

const Item = ({
  mergeBooks,
  item,
  requestBook,
  books,
  collapseId,
  setCollapseId,
  index,
  requestBooksClose
}) => {

  useEffect(() => {
    collapseId === index && requestBook(item);
  }, [collapseId]);

  const onClose = () => {
      setCollapseId(false);
      requestBooksClose([]);
  };

  return (
    <>
      <div
        className={styles.item}
        onClick={() => (collapseId === index ? onClose() : setCollapseId(index))}
        aria-expanded={collapseId}
      >
        <div className={styles.title}>{item.title || WITHOUT_VALUE}</div>
        <CoverListItemInfo
          label={"Дубликаты"}
          value={item.duplicates || WITHOUT_VALUE}
        />
        <CoverListItemInfo label={"Год"} value={item.year || WITHOUT_VALUE} />
        <CoverListItemInfo
          label={"Авторы"}
          value={
            (item.authors && item.authors.map(item => `${item} `)) || WITHOUT_VALUE
          }
        />
        <CoverListItemInfo label={"Страниц"} value={item.pageNum || WITHOUT_VALUE} />
        <CoverListItemInfo
          label={"Издатель"}
          value={
            (item.publishers && item.publishers.map(item => `${item} `)) ||
            WITHOUT_VALUE
          }
        />
      </div>
      <CollapseBlock collapseId={collapseId} books={books} index={index} mergeBooks={mergeBooks} />
    </>
  );
};

export default Item;