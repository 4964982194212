import React, {useEffect, useState} from 'react';
import styles from './ElectronicCoursesModal.module.css';
import ModalWindow from "../../ModalWindow";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import {EDIT} from "../../../constants/modalTypes";
import {api} from "../../../App";
import Select from "react-select";
import {selectCustomStyles} from "../../Select/customStyles";

const initialValues = {
    title: '',
    status: '',
    startdate: '',
    enddate: '',
    duration: '',
};

const DocumentsModal = ({
                            addItem,
                            modalData,
                            setModalData,
                        }) => {

    const [values, setValues] = useState(initialValues);
    const [users, setUsers] = useState([]);
    const [usersLabel, setUsersLabel] = useState('');
    const [userInEditMode, setUserInEditMode] = useState('');

    useEffect(() => {
        console.log(modalData)
        if (modalData.modalType === EDIT && modalData.data) {
            api.get(`/people/${modalData.data.mid}`)
                .then(res => {
                    const {lastname, firstname, patronymic} = res.data;
                    setUserInEditMode(`${lastname} ${firstname} ${patronymic}`)
                })
        }
    }, [modalData]);

    useEffect(() => {

        const params = {
            maxResults: 15,
            paginateFrom: 0,
            search: '',
            orderBy: 'lastname'
        };

        api.get('/people', {params})
            .then(res => {
                setUsers(userDataResolver(res.data));
            });

        return () => {
            setValues(initialValues);
            setUsersLabel('');
        };

    }, []);

    useEffect(() => {
        const data = modalData.data;
        if (modalData.modalType === EDIT && modalData.data) setValues({
            title: data.title,
            status: data.status,
            startdate: dateResolver(data.startdate),
            enddate: dateResolver(data.enddate),
            duration: data.duration,
            mid: data.mid
        })
    }, [modalData]);

    const userDataResolver = data => data.map(item => ({
        label: item.lastname + item.firstname + item.patronymic,
        value: item.mid
    }));

    const onUsersInputChange = (name) => {

        const params = {
            maxResults: 15,
            paginateFrom: 0,
            search: name,
            orderBy: 'lastname'
        };

        api.get('/people', {params})
            .then(res => {
                setUsers(userDataResolver(res.data))
            })
    };

    const onUsersChange = ({value, label}) => {
        setValues({...values, mid: value});
        setUsersLabel({value, label});
    };

    const closeModal = () => {
        setModalData({isOpen: false, modalType: null, data: null});
        setValues(initialValues);
    };

    const onChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    };

    const dateResolver = (date) => date ? date.split('T')[0] : '';

    return (
        <ModalWindow
            title={modalData.modalType === EDIT ?
                `Редактирование электронного курса`
                : `Добавление электронного курса`}
            isOpen={modalData.isOpen}
            onRequestClose={closeModal}
        >
            <div className={styles.wrapper}>

                {modalData && modalData.modalType && modalData.modalType == EDIT ?

                    <ModalInputWrapper
                        label="Пользователь"
                        name="title"
                        value={userInEditMode}
                    />
                    :
                    <ModalInputWrapper label="Пользователь">
                        <Select
                            isSearchable
                            isMulti={false}
                            onInputChange={onUsersInputChange}
                            options={users}
                            onChange={onUsersChange}
                            placeholder={'Поиск...'}
                            value={usersLabel}
                            noOptionsMessage={() => null}
                            styles={selectCustomStyles}
                        />
                    </ModalInputWrapper>}

                <ModalInputWrapper
                    label="Название курса*"
                    name="title"
                    value={values["title"]}
                    onChange={onChange}
                />

                <ModalInputWrapper
                    label="Статус*"
                >
                    <select
                        name="typeId"
                        value={values["status"] ? values["status"] : ""}
                        onChange={e => setValues({...values, status: e.target.value})}
                        className={styles.select}
                    >
                        <option value="" disabled>Выберите из списка</option>
                        <option value={'DONE'}>Завершен</option>
                        <option value={'PROGRESS'}>В процессе</option>
                    </select>
                </ModalInputWrapper>

                <ModalInputWrapper
                    label="Дата начала изучения*"
                    type={'date'}
                    name="startdate"
                    value={values["startdate"]}
                    onChange={onChange}
                />
                <ModalInputWrapper
                    label="Дата завершения изучения*"
                    name="enddate"
                    type={'date'}
                    value={values["enddate"]}
                    onChange={onChange}
                />
                <ModalInputWrapper
                    label="Продолжительность изучения*"
                    name="duration"
                    value={values["duration"]}
                    onChange={onChange}
                />

                <Button
                    type={'submit'}
                    label={'Сохранить'}
                    onClick={() => addItem(values, closeModal)}
                />
            </div>
        </ModalWindow>
    );
};

export default DocumentsModal;