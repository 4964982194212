import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "react-bootstrap";
import InputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";

const BooksOrderSearchForm = ({data, setSearchData, startSearch}) => {
    const onChange = ({target: {name, value}}) => setSearchData({...data, [name]: value});

    return (
        <div className={'formContainer'}>
            <Row>
                <Col md={4}>
                    <InputWrapper
                        name="from"
                        onChange={onChange}
                        type={"date"}
                        label={"С"}/>
                </Col>
                <Col md={4}>
                    <InputWrapper
                        name="to"
                        onChange={onChange}
                        type={"date"}
                        label={"По"}/>
                </Col>

                <Col md={2}>
                    <InputWrapper label={""}>
                        <Button label={"Найти"} onClick={startSearch}/>
                    </InputWrapper>
                </Col>
            </Row>
        </div>
    );
};

BooksOrderSearchForm.propTypes = {
    data: PropTypes.object,
    setSearchData: PropTypes.func,
    startSearch: PropTypes.func,
    types: PropTypes.array
};

export default BooksOrderSearchForm;
