import trashIcon from "../img/icons/delete-photo.svg";
import downloadIcon from "../img/icons/download.svg";
import linkIcon from "../img/icons/logout.svg";
import plusIcon from '../img/icons/plus.svg'
import cancelIcon from '../img/icons/close.svg';
import rotateIcon from '../img/icons/refresh.svg';
import wiewIcon from '../img/icons/view.svg';
import menuIcon from '../img/icons/menu.svg';
import listIcon from '../img/icons/list.svg';


export const ICONS = {
    DOWNLOAD: downloadIcon,
    TRASH: trashIcon,
    LINK: linkIcon,
    LIST: listIcon,
    MENU: menuIcon,
    PLUS: plusIcon,
    CANCEL: cancelIcon,
    ROTATE: rotateIcon,
    VIEW: wiewIcon,
};