import { combineReducers } from "redux";
import { coversCountReducer } from "./count/coversCountReducer";
import { coversListReducer } from "./list/coversListReducer";
import { coversSearchReducer } from "./search/coversSearchReducer";
import { coversLoaderReducer } from "./loader/coversLoaderReducer";
import { coversLoadSuccessReducer } from './loadSuccess/coversLoadSuccessReducer';
import {coversFormReducer} from "./form/coversFormReducer";

export const inventoryCoversReducer = combineReducers({
  count: coversCountReducer,
  list: coversListReducer,
  search: coversSearchReducer,
  loader: coversLoaderReducer,
  loadSuccess: coversLoadSuccessReducer,
  form: coversFormReducer,
});
