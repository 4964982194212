import { combineReducers } from "redux";
import { importBooksErrorReducer } from "./error/importBooksErrorReducer";
import { importBooksFormReducer } from "./form/importBooksFormReducer";
import { importBooksDataReducer } from "./data/importBooksDataReducer";

export const importBooksReducer = combineReducers({
  data: importBooksDataReducer,
  error: importBooksErrorReducer,
  form: importBooksFormReducer
});
