import React from 'react';
import PropTypes from 'prop-types';
import styles from './SearchAuditTable.module.css';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import { AUDIT_ACCESS_SORT } from "../../../constants/sort/auditAccessSort";
import { SORT_DIRECTION } from "../../../constants/sortDirections";
import Button from "../../Button";

const SearchAuditTable = ({ data, sortBy, setSort, setCurrentItem }) => (
  <div className={styles.container}>
    <DataTable data={data} sortBy={sortBy} onHeadClick={setSort}>
      <DataTableCol title="Дата и время" sortParams={[AUDIT_ACCESS_SORT.EVENT_TIME, SORT_DIRECTION.DESC]} value="eventtime" resolver={item => new Date(item.eventtime).toLocaleString()}
                    colSpan={2}/>
      <DataTableCol title="IP" sortParams={[AUDIT_ACCESS_SORT.CLIENT_IP]} value="clientip" colSpan={2}/>
      <DataTableCol title="" resolver={(item) => <Button label="Подробнее" onClick={() => setCurrentItem(item)}/>}/>
    </DataTable>
  </div>
);

SearchAuditTable.propTypes = {
  data: PropTypes.array,
  sortBy: PropTypes.string,
  setSort: PropTypes.func,
};

export default SearchAuditTable;
