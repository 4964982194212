import { combineReducers } from "redux";
import { optionsDataReducer } from "./data/optionsDataReducer";
import { optionsStatusReducer } from "./status/optionsStatusReducer";
import { optionsLogoReducer } from "./logo/optionsLogoReducer";
import { optionsLayoutReducer } from './layout/optionsLayoutReducer';
import { layoutImagesReducer } from './layoutImages/layoutImagesReducer';

export const optionsReducer = combineReducers({
  data: optionsDataReducer,
  status: optionsStatusReducer,
  logo: optionsLogoReducer,
  layout: optionsLayoutReducer,
  headerImages: layoutImagesReducer
});
