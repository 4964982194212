import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ExternalResourcesEditableItem.module.css';
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import { objectValuesNonEmpty } from "../../../utils/objectValuesNonEmpty";

const emptyExternalResource = {
  resourcename: "",
  resourceurl: ""
};

const ExternalResourcesEditableItem = ({ data, onSave, onDelete }) => {
  const [localData, setLocalData] = useState(emptyExternalResource);
  useEffect(() => setLocalData(data || emptyExternalResource), [data]);

  const onChange = ({ target }) => setLocalData({ ...localData, [target.name]: target.value });

  return (
    <div className={styles.container}>
      <Row>
        <Col md={4}>
          <ModalInputWrapper label={"Название ресурса"}
                             name="resourcename"
                             value={localData.resourcename}
                             onChange={onChange}
          />
        </Col>
        <Col md={5}>
          <ModalInputWrapper label={"Адрес ресурса"}
                             name="resourceurl"
                             value={localData.resourceurl}
                             onChange={onChange}
          />
        </Col>
        <Col md={3}>
          <div className={styles.buttonContainer}>
            <Button label="Сохранить"
                    style={styles.button}
                    onClick={() => onSave(localData)}/>
            <Button label="Удалить"
                    style={styles.button}
                    onClick={() => onDelete(localData.id)}/>
          </div>
        </Col>
      </Row>
    </div>
  );
};

ExternalResourcesEditableItem.propTypes = {
  data: PropTypes.object,
  onSave: PropTypes.func,
  onDelete: PropTypes.func
};

export default ExternalResourcesEditableItem;
