import { SERIES_ADD_REQUEST, ERROR, START, SUCCESS } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const seriesCatalogAddRequestStart = (data) => ({
  type: `${SERIES_ADD_REQUEST}${START}`,
  payload: data
});

export const seriesCatalogAddRequestSuccess = (data) => {
  createNotification('success', 'Серия успешно добавлена');
  return {
    type: `${SERIES_ADD_REQUEST}${SUCCESS}`,
    payload: data
  };
};

export const seriesCatalogAddRequestError = (data) => {
  createNotification('error', 'Не удалось добавить серию');
  return {
    type: `${SERIES_ADD_REQUEST}${ERROR}`,
    payload: data
  };
};
