import React from 'react';
import PropTypes from 'prop-types';
import {ANTI_PLAGIAT_STATUS_LIST} from "../../../scenes/BorrowingTest";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import {Col, Row} from "react-bootstrap";


const BorrowingTestForm = ({ setFormData, formData, onSubmit }) => {
  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  return (
    <div className="formContainer">
      <Row>
        <Col>
          <ModalInputWrapper
            label="Комментарий"
            name="query"
            onChange={onChange}
          />
        </Col>

        <Col md={4} lg={3}>
          <ModalInputWrapper label="Статус">
            <select
              name="status"
              onChange={onChange}
            >
              {Array.from(ANTI_PLAGIAT_STATUS_LIST.entries())
                .map((item, index) => <option key={index} value={item[0]}>{item[1]}</option>)
              }
            </select>
          </ModalInputWrapper>
        </Col>

        <Col md={"auto"}>
          <ModalInputWrapper label="">
            <Button label="Поиск" onClick={onSubmit}/>
          </ModalInputWrapper>
        </Col>
      </Row>
    </div>
  );
};

BorrowingTestForm.propTypes = {
  setFormData: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default BorrowingTestForm;