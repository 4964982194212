import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
    DOCUMENT_BOOK_ORDER_COUNT_REQUEST,
    DOCUMENT_BOOK_ORDER_REQUEST,
    DOCUMENT_BOOK_ORDER_DELETE_REQUEST,
    DOCUMENT_BOOK_ORDER_SAVE_REQUEST,
    START
} from "../../../actions/action-types";
import {
    getBookOrdersCount,
    getBookOrdersList,
    saveBookOrder,
    deleteBookOrder
} from "../../../../api/booksOrder/documents";
import {
    documentCountRequestStart,
    documentCountRequestSuccess,
    documentCountRequestError
} from "../../../actions/booksOrder/count/count";
import { setLatestDocument } from "../../../actions/booksOrder/documents/latest";
import { setDocumentSearchData } from "../../../actions/booksOrder/search/search";
import {
    documentListRequestError,
    documentListRequestStart,
    documentListRequestSuccess
} from "../../../actions/booksOrder/documents/list";
import {
    documentSaveRequestSuccess,
    documentSaveRequestError
} from "../../../actions/booksOrder/documents/save";
import {
    documentDeleteRequestSuccess,
    documentDeleteRequestError
} from "../../../actions/booksOrder/documents/delete";

export default function* documentWatcher() {
    yield takeLatest(`${DOCUMENT_BOOK_ORDER_COUNT_REQUEST}${START}`, documentCountWorker);
    yield takeLatest(`${DOCUMENT_BOOK_ORDER_REQUEST}${START}`, documentListWorker);
    yield takeLatest(`${DOCUMENT_BOOK_ORDER_DELETE_REQUEST}${START}`, documentDeleteWorker);
    yield takeLatest(`${DOCUMENT_BOOK_ORDER_SAVE_REQUEST}${START}`, documentSaveWorker);
}

function* documentCountWorker() {
    const searchData = yield select(({ booksOrder }) => booksOrder.search);
    console.log(searchData);
    try {
        const response = yield call(getBookOrdersCount, searchData);
        const count = parseInt(response.data.count, 10);
        yield put(documentCountRequestSuccess(count));
        const pageCount = Math.ceil(count / searchData.maxResults);
        yield put(setDocumentSearchData({ ...searchData, pageCount }));
        yield put(documentListRequestStart());
    } catch (error) {
        yield put(documentCountRequestError(error));
    }
}

function* documentListWorker() {
    const searchData = yield select(({ booksOrder }) => booksOrder.search);
    searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
    try {
        const response = yield call(getBookOrdersList, searchData);
        yield put(documentListRequestSuccess(response.data));
    } catch (error) {
        yield put(documentListRequestError(error));
    }
}

function* documentDeleteWorker({ payload: orderId }) {
    try {
        const response = yield call(deleteBookOrder, orderId);
        yield put(documentDeleteRequestSuccess(response));
        yield put(documentCountRequestStart());
    } catch (error) {
        yield put(documentDeleteRequestError(error));
    }
}

function* documentSaveWorker({ payload: data }) {
    try {
        if(!data.id) {
            const response = yield call( saveBookOrder, data);
            yield put(documentSaveRequestSuccess(response.data));
            yield put(setLatestDocument(response.data));
            yield put(documentCountRequestStart());
        }
    } catch (error) {
        yield put(documentSaveRequestError(error));
    }
}
