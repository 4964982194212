import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {ADD, EDIT} from "../../../constants/modalTypes";
import styles from './DocumentsTable.module.css';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import {DOCUMENT_SORT} from "../../../constants/sort/documentSort";
import moment from "moment";

const DocumentsTable = ({data, setModal, sortBy, setSort, selectedId, handleSelect, onDelete}) => {
    const openModal = (item) => setModal({modalType: EDIT, data: item});
    const openCreationModal = () => setModal({modalType: ADD, data: null});


    return (
        <div className={styles.container}>
            <div className={styles.buttonContainer}>
                <div className={styles.button} onClick={openCreationModal}>
                    добавить
                </div>
                <div className={styles.button} onClick={onDelete}>
                    удалить
                </div>
            </div>
            <DataTable data={data} sortBy={sortBy} onHeadClick={setSort} onCellClick={openModal}>
                <DataTableCol title="Вид документа" value="type" colSpan={3}/>
                <DataTableCol sortParams={[DOCUMENT_SORT.NUMBER]} title="Номер документа" value="documentnumber"
                              colSpan={2}/>
                <DataTableCol sortParams={[DOCUMENT_SORT.SUM]} title="Сумма документа"
                              resolver={({sum}) => sum ? sum : "n/a"} colSpan={2}/>
                <DataTableCol sortParams={[DOCUMENT_SORT.DATE]} title="Дата документа"
                              resolver={({documentdate}) => moment(documentdate).format('DD.MM.YYYY')} colSpan={2}/>
                <DataTableCol title="Выбрано" passClicks resolver={(item) => (
                    <input type="checkbox" checked={item.id === selectedId}
                           onChange={() => handleSelect(item.id)}/>)}
                />
            </DataTable>
        </div>
    );
};

DocumentsTable.propTypes = {
    data: PropTypes.array,
    setModal: PropTypes.func,
    sortBy: PropTypes.string,
    setSort: PropTypes.func,
    selectedId: PropTypes.any,
    handleSelect: PropTypes.func,
    onDelete: PropTypes.func
};

DocumentsTable.defaultProps = {
    data: []
};

export default DocumentsTable;
