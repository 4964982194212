import {
  call,
  put,
  takeLatest,
  select
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";
import {
  BOOK_RESERVE_COUNT_REQUEST,
  BOOK_RESERVE_LIST_REQUEST,
  START, SUCCESS, UNRESERVE_BOOK_REQUEST
} from "../../actions/action-types";
import {getBookReserveCount, getBookReserveList} from "../../../api/bookreserve/bookreserve";
import {
  bookreserveCountRequestError,
  bookreserveCountRequestStart,
  bookreserveCountRequestSuccess
} from "../../actions/bookreserve/count";
import {setBookreserveSearchData} from "../../actions/bookreserve/search";
import {
  bookreserveListRequestError,
  bookreserveListRequestStart,
  bookreserveListRequestSuccess
} from "../../actions/bookreserve/list";



export default function* bookreserveWatcher () {
  yield takeLatest(`${BOOK_RESERVE_COUNT_REQUEST}${START}`, countWorker);
  yield takeLatest(`${BOOK_RESERVE_LIST_REQUEST}${START}`, listWorker);
  yield takeLatest(`${UNRESERVE_BOOK_REQUEST}${SUCCESS}`, unreserveWorker);
}

function* countWorker() {
  const searchData = yield select(({ bookreserve }) => bookreserve.search);

  try {
    const response = yield call(getBookReserveCount);
    const count = parseInt(response.data.count, 10);
    yield put(bookreserveCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setBookreserveSearchData({...searchData, pageCount}));
    if(count > 0) {
      yield put(bookreserveListRequestStart());
    }
  } catch (error) {
    yield put(bookreserveCountRequestError(error));
  }
}

function* listWorker() {
  const searchData = yield select(({ bookreserve }) => bookreserve.search);

  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
  try {
    const response = yield call(getBookReserveList, 0, searchData);
    yield put(bookreserveListRequestSuccess(response.data));
  } catch (error) {
    yield put(bookreserveListRequestError(error));
  }
}

function* unreserveWorker() {
    try {
      yield put(bookreserveCountRequestStart());
    } catch(error) {
      console.log(error)
    }
}