import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./SelectUserModal.module.css";
import Modal from "../Modal";
import { adminModalUsersCountRequestStart } from "../../redux/actions/adminModalUsers/count";
import { connect } from "react-redux";
import Pagination from "../Pagination";
import { resetAdminModalSearchData } from "../../redux/actions/adminModalUsers";
import { setAdminModalSearchData } from "../../redux/actions/adminModalUsers/searchData";
import classNames from "classnames";
import { Col, Row } from "react-bootstrap";
import Search from "../Search";
import {roleAddNewMember, roleClearNewMembers} from "../../redux/actions/roles/newMembers";
import { ADD } from "../../constants/modalTypes";


const mapStateToProps = ({ adminModalUsers: { list: users, search }, roles }) => ({
  users,
  searchData: search
});

const mapDispatchToProps = dispatch => ({
  requestUsers: () => dispatch(adminModalUsersCountRequestStart()),
  setSearchData: data => dispatch(setAdminModalSearchData(data)),
  reset: () => dispatch(resetAdminModalSearchData()),
  roleAddNewMember: data => dispatch(roleAddNewMember(data))
});

const SelectUsersModal = ({
  requestUsers,
  users,
  searchData,
  reset,
  setSearchData,
  onSubmit,
  onClose,
  modalType,
  roleAddNewMember
}) => {
  useEffect(() => {
    requestUsers();
    return () => {
      reset();
    };
  }, []);

  const setCurrentPage = page => {
    setSearchData({ ...searchData, page });
    requestUsers();
  };

  const setQuery = query => {
    setSearchData({ ...searchData, query });
  };

  const onAddMember = () => {
    if (modalType === ADD) {
      roleAddNewMember({id: selectedId, name: selectedName});
      onClose();
    } else {
      selectedId && onSubmit(selectedId);
    }
  }

  const onSelect = (item) => {
    if (modalType === ADD) {
      setSelectedId(item.mid);
      setSelectedName(item.displayname);
    } else {
      setSelectedId(item.mid);
    }
  }

  const [selectedId, setSelectedId] = useState(null);
  const [selectedName, setSelectedName] = useState(null);

  return (
    <Modal
      title={`Роль`}
      isVisible={true}
      className={styles.modalContainer}
      contentClassName={styles.modalContent}
    >
      <Pagination
        pageCount={searchData.pageCount}
        current={searchData.page}
        setCurrent={setCurrentPage}
      />
      <div className={styles.searchContainer}>
        <Search
          onSubmit={requestUsers}
          onChange={setQuery}
          value={searchData.query}
        />
      </div>
      <div className={classNames(styles.list)}>
        {!!users &&
          users.map((item, index) => {
            return (
              <div
                className={classNames(styles.item, {
                  [styles.selected]: selectedId === item.mid
                })}
                key={index}
                onClick={() => onSelect(item)}
              >
                <Row>
                  <Col md={6}>{item.displayname}</Col>
                  <Col md={{ offset: 2, span: 4 }}>n/a</Col>
                </Row>
              </div>
            );
          })}
      </div>
      <Row>
        <Col md={4}>
          <div className={styles.flexSection}>
            <div
              onClick={onAddMember}
              className={classNames(styles.button, {
                [styles.disabled]: !selectedId
              })}
            >
              Добавить
            </div>
            <div onClick={onClose} className={classNames(styles.button)}>
              Отмена
            </div>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

SelectUsersModal.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

SelectUsersModal.defaultProps = {
  onSubmit: () => null,
  onClose: () => null
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectUsersModal);
