import { SCHEDULER_EDIT_REQUEST, START, SUCCESS, ERROR } from "../../action-types";
import {createNotification} from "../../../../components/notification/notification";

export const schedulerEditRequestStart = (data, callback) => ({
  type: `${SCHEDULER_EDIT_REQUEST}${START}`,
  payload: data, callback
});

export const schedulerEditRequestSuccess = (response) => {
  createNotification('success', 'Успешно отредактировано');

  return {
    type: `${SCHEDULER_EDIT_REQUEST}${SUCCESS}`,
    payload: response
  };
};

export const schedulerEditRequestError = (error) => ({
  type: `${SCHEDULER_EDIT_REQUEST}${ERROR}`,
  payload: error
});
