import React from 'react';
import PropTypes from 'prop-types';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import classNames from "classnames";
import styles from "../../BorrowingTest/Table/BorrowingTestTable.module.css";
import {ICONS} from "../../../utils/projectIcons";
import {DAYS} from "../../../scenes/DataScheduler";
import Button from "../../Button";

const DataSchedulerTable = ({ list, isEmpty, deleteItem, setModalData, initialModalFormData }) => {

  const openEditModal = (item) => setModalData({type: 'EDIT', data: item});
  const openCreationModal = () => setModalData({type: 'ADD', data: initialModalFormData});

  return (
    <div>
      <div className="mb-2 formContainer">
        <Button label="Добавить" colorType="green" onClick={openCreationModal}/>
      </div>
      {isEmpty ?
        <div className="emptyNotify">Еще не создано ни одного расписания</div>
        :
        <div className="mt-2 mb-2">
          <DataTable data={list} onCellClick={item => openEditModal(item)}>
            <DataTableCol colSpan={7} title="День" resolver={item => DAYS.get(item.day)}/>
            <DataTableCol colSpan={7} title="Комментарий" resolver={item => item.schedule ? item.schedule.toString() : item.interval + ' мин'}/>
            <DataTableCol title="" resolver={(item) =>
              <div className="d-flex">
                <button
                  title="Удалить"
                  className={classNames("bg_red", styles.iconButton)}
                  onClick={() => deleteItem(item.id)}
                >
                  <img src={ICONS.TRASH} alt=""/>
                </button>
              </div>}/>
          </DataTable>
        </div>
      }
    </div>
  );
};

DataSchedulerTable.propTypes = {
  list: PropTypes.array,
  isEmpty: PropTypes.bool,
  deleteItem: PropTypes.func.isRequired,
  setModalData: PropTypes.func,
  initialModalFormData: PropTypes.object
};

DataSchedulerTable.defaultProps = {
  list: []
};

export default DataSchedulerTable;