import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { CHECK_AUTH_REQUEST, START } from "../../actions/action-types";
import { checkAuthRequestError, checkAuthRequestSuccess } from "../../actions/user/checkAuth";
import { checkAuth } from "../../../api/user/auth";

export default function* checkAuthWatcher() {
  yield takeLatest(`${CHECK_AUTH_REQUEST}${START}`, checkAuthWorker)
}

function* checkAuthWorker() {
  try {
    const response = yield call(checkAuth);
    yield put(checkAuthRequestSuccess(response.data));
  } catch (error) {
    yield put(checkAuthRequestError(error));
  }
}