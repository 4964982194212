import React from 'react';
import PropTypes from 'prop-types';
import PdfPrintPanel from "../../PDFPrint/Panel";
import addFont from "../../../fonts/fonts";
import jsPDF from "jspdf";
import {fetchTestsList} from "../../../api/Tests";
import {createNotification} from "../../notification/notification";
import moment from "moment";

const TestsPDFPrint = ({orientation, title, currentPage, resultsPerPage}) => {

    const headers = [
        {
            value: (item, index) => (currentPage - 1) * resultsPerPage + index + 1,
            title: '№ п/п'
        },{
            value: 'title',
            title: 'Курс'
        },{
            value: ({status}) => status === 'DONE' ? 'Завершен' : 'В ходе выполнения',
            title: 'Статус'
        },{
            value: ({starttime}) => starttime ? moment(starttime).format('DD.MM.YYYY') : 'Не указано',
            title: 'Дата начала изучения'
        },{
            value: ({starttime}) => starttime ? moment(starttime).format('DD.MM.YYYY') : 'Не указано',
            title: 'Дата завершения изучения'
        },{
            value: ({period}) => `${period.hours}ч. ${period.minutes}м. ${period.seconds}сек.`,
            title: 'Продолжительность изучения'
        },{
            value: "rating",
            title: 'Результат'
        }
    ];

    const createData = (tableData) => {
        let result = [];

        tableData.forEach((item, index) => {
            const generatedItem = [];

            headers.forEach(({value}) => {
                generatedItem.push(typeof value === 'function'
                    ? value(item, index)
                    : (item[value] || '-'));
            });

            result.push(generatedItem);
        });

        return result;
    };

    const createHeaders = () => headers.map(header => header.title);

    const createPdfDoc = (tableData) => {
        addFont(jsPDF.API);
        const doc = new jsPDF({putOnlyUsedFonts: true, orientation: orientation});
        doc.setFont("Montserrat");

        doc.text(title, 15, 15);

        doc.autoTable({
            head: [createHeaders()],
            body: createData(tableData),
            styles: {font: "Montserrat", fontSize: 10},
            margin: {
                top: 25
            },
            headStyles: {
                halign: "center",
                valign: "middle",
                lineWidth: 0.25,
                lineColor: 200
            },
            bodyStyles: {
                halign: "center",
                lineWidth: 0.25,
                lineColor: 200
            },
        });

        return doc;
    };

    const toPdf = () => {
        fetchTestsList()
            .then(res => {
                const doc = createPdfDoc(res.data);
                doc.save(`${title}.pdf`);
            })
            .catch(err => createNotification('error', err.response))
    };

    const toPrint = () => {
        fetchTestsList()
            .then(res => {
                const doc = createPdfDoc(res.data);
                doc.autoPrint();
                doc.output('dataurlnewwindow');
            })
            .catch(err => createNotification('error', err.response))
    };

    return (
        <div className={'d-flex justify-content-end mb-1'}>
            <PdfPrintPanel printPdf={toPrint} loadPdf={toPdf}/>
        </div>
    );
};

TestsPDFPrint.propTypes = {
    title: PropTypes.string,
    orientation: PropTypes.oneOf(['landscape', 'portrait'])
};

export default TestsPDFPrint;