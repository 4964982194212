import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./Formular.css";
import { readerDataRequestStart } from "../../../../redux/actions/reader/getData";


const months = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря"
];

const AMOUNT_OF_ROWS = 20;

const mapStateToProps = ({ readerData }) => ({
  readerData: readerData
});

const matchDispatchToProps = {
  getData: readerDataRequestStart
};

const Formular = ({ match, getData, readerData }) => {
  const date = new Date();

  useEffect(() => {
    getData(match.params.mid);
  }, []);

  useEffect(() => {
    readerData && readerData.mid && window.print();
  }, [readerData]);

  const createArr = (n) => {
    const arr = [];
    for(let i =0; i<=n; i++) {
      arr.push(null);
    }
    return arr;
  };

  return (
    <div>
      <div className="cardFormular">
        <div className="leftBlock"></div>
        <div className="rightBlock">
          <p className="titleFormular">
            № {<div className="mid">{match.params.mid}</div>}
          </p>
          <table className="tableFormular">
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
          </table>
          <div className="titleFormular">Читательский формуляр</div>
          <div className="strFormular">
            <div className="strFormularTitle">Фамилия</div>
            <div className="strFormularData">
              {readerData && readerData.lastname}
            </div>
          </div>
          <div className="strFormular">
            <div className="strFormularTitle">Имя</div>
            <div className="strFormularData">
              {readerData && readerData.firstname}
            </div>
          </div>
          <div className="strFormular">
            <div className="strFormularTitle">Отчество</div>
            <div className="strFormularData">
              {readerData && readerData.patronymic}
            </div>
          </div>
          <div className="strFormular">
            <div className="strFormularTitle">Факультет</div>
            <div className="strFormularData">
              {readerData && readerData.faculty}
            </div>
          </div>
          <div className="strFormular">
            <div className="strFormularTitle">Группа</div>
            <div className="strFormularData">
              {readerData && readerData.groupname}
            </div>
          </div>
          <div className="strFormular">
            <div className="strFormularTitle">Адрес</div>
            <div className="strFormularData"></div>
          </div>
          <div className="strFormularClear"></div>
          <div className="strFormular phone">
            <div className="strFormularTitle">Телефон</div>
            <div className="strFormularDataContainer">
              <div className="strFormular">
                <div className="strFormularTitle">служебн.</div>
                <div className="strFormularData"></div>
              </div>
              <div className="strFormular">
                <div className="strFormularData addHeight"></div>
              </div>
              <div className="strFormular">
                <div className="strFormularTitle">домашн.</div>
                <div className="strFormularData"></div>
              </div>
            </div>
          </div>
          <div className="podpStrFormular">Подпись читателя_________</div>
          <div className="dateStrFormular">{`${date.getDate()} ${
            months[date.getMonth()]
          } ${date.getFullYear()}г.`}</div>
        </div>
      </div>
      <div className="secondCardFormular">
        <table className="secondTableCardFormular">
          <tr className="titleRowSecondTable">
            <td className="firstTd">Дата выдачи</td>
            <td className="secondTd">Инв.номер</td>
            <td className="thirdTd">Автор и заглавие книги</td>
            <td className="fourthTd">Расписка получателя в получении</td>
            <td className="fifthTd">Расписка библиотекаря о возврате</td>
          </tr>
          {createArr(AMOUNT_OF_ROWS).map(() => (
            <tr>
              <td className="firstTd" />
              <td className="secondTd" />
              <td className="thirdTd" />
              <td className="fourthTd" />
              <td className="fifthTd" />
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(Formular);
