import React from "react";
import styles from "./BooksListItem.module.css";
import { resolveBookCoverUrl } from "../../../utils/resolvePhotoUrl";
import { Col, Row } from "react-bootstrap";

const ItemCatalogCard = ({ data, label, setModalVisible }) => {
  return (
    <Row>
      <Col md={3}>
        <div className={styles.label}>{label}:</div>
        {data.subscriptionCover ? (<img
            className={styles.imgContainer}
            src={resolveBookCoverUrl(data.subscriptionCover, data.bookid)}
            alt="scan"
            onClick={() => setModalVisible(true)}
          />) : "Отсутствует"}
      </Col>
    </Row>
  );
};

export default ItemCatalogCard;
