import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';



const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

const DJVUReader = ({}) => {
    return (
        <div>
            это дежавю ридер
        </div>
    );
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DJVUReader);