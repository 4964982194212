import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-konva';

import { getDragBoundFunc, getBoundBoxFunc, EditableRectangle, Field, Rectangle } from "rectangle-composer";

const Composer = props => {
  const {
    initialRectangles,
    relativeWidth,
    relativeHeight,
    wrapperClassName,
    gridClassName,
    onChange,
    onChangeRect,
    isDraggable,
    setRectangles
  } = props;

  const [editedIndex, setEditedIndex] = useState(null);

  const [gridDimensions, setGridDimensions] = useState({ x: 0, y: 0, width: 1, height: 1 });

  const handleSwitchEditedRectangle = index => event => {
    // eslint-disable-next-line no-param-reassign
    event.cancelBubble = true;
    if(isDraggable) {
      setEditedIndex(index);
    }
    onChangeRect(index);
  };

  const widthRatio = gridDimensions.width / relativeWidth;

  const heightRatio = gridDimensions.height / relativeHeight;

  const getRectProps = ({ top, left, bottom, right, ...rest }, index) => ({
    ...rest,
    x: left * widthRatio,
    y: top * heightRatio,
    width: (right - left) * widthRatio,
    height: (bottom - top) * heightRatio,
    index,
  });

  const rectanglesWithoutEdited = initialRectangles
    .map(getRectProps)
    .filter(rectProps => rectProps.index !== editedIndex);

  const visualizedRectangles = rectanglesWithoutEdited
    .map(rectProps => (
      <Rectangle
        key={`rect-${rectProps.index}`}
        onClick={handleSwitchEditedRectangle(rectProps.index)}
        {...rectProps}
      >
        <Text text={rectProps.title}
              fontSize={15}
              fill="white"
              x={5}
              y={5}
              width={rectProps.width}
              height={rectProps.height}
              onClick={handleSwitchEditedRectangle(rectProps.index)}
        />
      </Rectangle>
    ));
  const handleUpdateEditedRectangle = ({ x, y, width, height }) => {
    const newRectangles = JSON.parse(JSON.stringify(initialRectangles));
    const left = x / widthRatio;
    const top = y / heightRatio;
    const right = left + width / widthRatio;
    const bottom = top + height / heightRatio;
    newRectangles[editedIndex] = { ...newRectangles[editedIndex], left, top, right, bottom };
    setRectangles(newRectangles);
    onChange(newRectangles[editedIndex]);
  };

  const isEditMode = editedIndex === null;

  const editedRectangleProps = isEditMode
    ? null
    : getRectProps(initialRectangles[editedIndex], editedIndex);

  const boundParamsForIntersectionPreventing = isEditMode ? null : {
    x: editedRectangleProps.x,
    y: editedRectangleProps.y,
    canvasWidth: gridDimensions.width,
    canvasHeight: gridDimensions.height,
    width: editedRectangleProps.width,
    height: editedRectangleProps.height,
    obstacles: rectanglesWithoutEdited,
  };

  const haveIntersection = (r1, r2) => {
    return !(
      r2.x > r1.x + r1.width ||
      r2.x + r2.width < r1.x ||
      r2.y > r1.y + r1.height ||
      r2.y + r2.height < r1.y
    );
  }

  const rectDblClick = () => {
    const newRect = {
      ...editedRectangleProps,
      width: editedRectangleProps.height,
      height: editedRectangleProps.width
    }
    let isIntersected = false;
    initialRectangles.map(getRectProps).map((rect, index) => {
      if(editedIndex === index || isIntersected) {
        return;
      }
      if (haveIntersection(newRect, rect)) {
        isIntersected = true;
      }
    })
    if(isIntersected) {
      return;
    }
    handleUpdateEditedRectangle(newRect);
  }

  const editedRectangle = isEditMode
    ? null
    : (
      <EditableRectangle
        onEndEdit={handleUpdateEditedRectangle}
        {...editedRectangleProps}
        dragBoundFunc={getDragBoundFunc(boundParamsForIntersectionPreventing)}
        boundBoxFunc={getBoundBoxFunc(boundParamsForIntersectionPreventing)}
        onDblClick={rectDblClick}
        stroke='black'
        strokeWidth={0}
        shadowOffsetX={1}
        shadowOffsetY={1}
        shadowBlur={0}
      >
        <Text
          text={editedRectangleProps.title}
          fontSize={15}
          fill="white"
          x={5}
          y={5}
          width={editedRectangleProps.width}
          height={editedRectangleProps.height}
          onDblClick={rectDblClick}
        />
      </EditableRectangle>
    );

  return (
    <Field
      onGridResize={setGridDimensions}
      wrapperClassName={wrapperClassName}
      gridClassName={gridClassName}
    >
      {visualizedRectangles}
      {isDraggable && editedRectangle}
    </Field>
  );
};

Composer.propTypes = {
  /**
   * initial rectangles dimensions in relative units
   * additionally you can pass any props for styling or events
   * https://konvajs.org/api/Konva.Rect.html
   */
  initialRectangles: PropTypes.arrayOf(PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    bottom: PropTypes.number,
    right: PropTypes.number,
    title: PropTypes.string
  })).isRequired,
  /**
   * maximum width in relative units
   * in fact it is a bound for possible rectangle dimensions
   */
  relativeWidth: PropTypes.number.isRequired,
  /**
   * maximum height in relative units
   * in fact it is a bound for possible rectangle dimensions
   */
  relativeHeight: PropTypes.number.isRequired,
  /**
   * className for main wrapper. you can use `.yourclass.yourclass`
   * selector or `!important` in css to override styled-components properties
   */
  wrapperClassName: PropTypes.string,
  /**
   * className for grid wrapper
   */
  gridClassName: PropTypes.string,
  /**
   * callback for changes
   * return array of new rectangles
   */
  onChange: PropTypes.func,
  /**
   * callback for change rect
   * return index rect
   */
  onChangeRect: PropTypes.func,
  /**
   * allows drag and drop
   */
  isDraggable: PropTypes.bool,
  /**
   * callback for new rect coordinates
   * return new rect
   */
  setRectangles: PropTypes.func,
};

Composer.defaultProps = {
  wrapperClassName: '',
  gridClassName: '',
  onChange: () => {},
  onChangeRect: () => {},
  setRectangles: () => {},
  isDraggable: true,
};

export default Composer;
