import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import Pagination from "../../components/Pagination";
import {setSchedulerSearchData} from "../../redux/actions/scheduler/metadata/search";
import DataSchedulerTable from "../../components/DataScheduler/Table";
import {schedulerDeleteRequestStart} from "../../redux/actions/scheduler/metadata/delete";
import DataSchedulerModal from "../../components/DataScheduler/Modal";
import {schedulerMetadataCountRequestStart} from "../../redux/actions/scheduler/metadata/count";
import {schedulerEditRequestStart} from "../../redux/actions/scheduler/metadata/edit";
import {schedulerAddRequestStart} from "../../redux/actions/scheduler/metadata/add";

const mapStateToProps = ({ scheduler }) => ({
  list: scheduler.metadata.list,
  count: scheduler.metadata.count,
  loader: scheduler.metadata.loader,
  searchData: scheduler.metadata.search,
  isEmpty: scheduler.metadata.empty
});

const mapDispatchToProps = {
  requestList: schedulerMetadataCountRequestStart,
  setSearchData: setSchedulerSearchData,
  deleteItemRequest: schedulerDeleteRequestStart,
  editItem: schedulerEditRequestStart,
  addItem: schedulerAddRequestStart
};

export const DAYS = new Map([
  [0, 'Все'],
  [1, 'Понедельник'],
  [2, 'Вторник'],
  [3, 'Среда'],
  [4, 'Четверг'],
  [5, 'Пятница'],
  [6, 'Суббота'],
  [7, 'Воскресенье'],
]);

const MODAL_FORM_INITIAL = {
  day: 0,
  schedule: null,
  interval: null
};

export const EVENT_TYPES = {
  TIME: 'time',
  INTERVAL: 'interval'
};

const DataScheduler = ({
                         requestList,
                         list,
                         count,
                         loader,
                         setSearchData,
                         searchData,
                         isEmpty,
                         deleteItemRequest,
                         editItem,
                         addItem
                       }) => {
  useEffect(() => {
    requestList();
  }, [requestList]);

  const [modalData, setModalData] = useState({type: null, data: null});


  const setCurrentPage = (page) => {
    setSearchData({ ...searchData, page });
    requestList()
  };

  const deleteItem = (id) => {
    const confirm = window.confirm('Вы действительно хотите удалить это расписание?');

    if(confirm) {
      deleteItemRequest(id);
    }
  };

  const closeModal = () => {
    setModalData({type: null, data: null});
  };

  const onSubmitModal = (eventType, formData) => {
    const updatedFormData = {...formData};

    if(eventType === EVENT_TYPES.TIME)
      updatedFormData.interval = null;
    else if(eventType === EVENT_TYPES.INTERVAL)
      updatedFormData.schedule = null;

    if(modalData.type === 'ADD')
      addItem(updatedFormData, closeModal);
    else if(modalData.type === 'EDIT')
      editItem(updatedFormData, closeModal);
  };

  return (
    <SceneContainer title="Миграция метаданных СПО ЭБ по расписанию">

      {modalData.data &&
        <DataSchedulerModal
          closeModal={closeModal}
          modalData={modalData}
          onSubmit={onSubmitModal}
        />
      }


      {(loader && !isEmpty) ?
        <SpinnerOuter isLoading={loader}/>
        :
        <>
          <DataSchedulerTable list={list} isEmpty={isEmpty}
                              deleteItem={deleteItem}
                              setModalData={setModalData}
                              initialModalFormData={MODAL_FORM_INITIAL}
          />
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
        </>
      }

    </SceneContainer>
  );
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataScheduler);