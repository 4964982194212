import React from 'react';
import PropTypes from 'prop-types';
import styles from './LibraryTypesTable.module.css';
import {ADD, EDIT} from "../../../constants/modalTypes";
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";

const LibraryTypesTable = ({initialFormFields, data, setModal, selectedId, handleSelect, onDelete}) => {
    const openEditModal = (item) => setModal({modalType: EDIT, data: item});

    const openCreationModal = () => setModal({modalType: ADD, data: initialFormFields});

    return (
        <div className={styles.container}>
            <div className={styles.buttonContainer}>
                <div className={styles.button} onClick={openCreationModal}>
                    добавить
                </div>
                <div className={styles.button} onClick={onDelete}>
                    удалить
                </div>
            </div>
            <DataTable data={data} onCellClick={openEditModal}>
                <DataTableCol title="Типы обрабатываемой литературы" value="name" colSpan={6}
                              className={styles.leftAlign}/>
                <DataTableCol title="Количество в библиотеке" value="amount" />
                <DataTableCol title="Выбрано" passClicks resolver={(item) =>
                    <input type="checkbox" checked={item.id === selectedId}
                           onChange={() => handleSelect(item.id)}/>}
                />
            </DataTable>
        </div>
    );
};

LibraryTypesTable.propTypes = {
    data: PropTypes.array,
    setModal: PropTypes.func,
    selectedId: PropTypes.any,
    handleSelect: PropTypes.func,
    onDelete: PropTypes.func,
    initialFormFields: PropTypes.object.isRequired,
};

export default LibraryTypesTable;
