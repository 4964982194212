import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
  PDF_VIEWER_BOOK_REQUEST,
  PDF_VIEWER_BOOKMARK_REQUEST,
  PDF_VIEWER_BOOKMARKS_SET,
  START
} from "../../actions/action-types";
import { pdfViewerBookRequestError, pdfViewerBookRequestSuccess } from "../../actions/pdfViewer/book";
import {
  pdfViewerBookmarkRequestError,
  pdfViewerBookmarkRequestStart,
  pdfViewerBookmarkRequestSuccess
} from "../../actions/pdfViewer/getBookmark";
import { pdfViewerBookmarkSaveError, pdfViewerBookmarkSaveSuccess } from "../../actions/pdfViewer/saveBookmark";

import { getBookInfo } from "../../../api/book/book";
import {getBookmark, setBookmark} from "../../../api/pdfViewer/bookmark";

export default function* pdfViewerWatcher() {
  yield takeLatest(`${PDF_VIEWER_BOOK_REQUEST}${START}`, pdfViewerBookWorker);
  yield takeLatest(`${PDF_VIEWER_BOOKMARK_REQUEST}${START}`, pdfViewerBookmarkGetWorker);
  yield takeLatest(PDF_VIEWER_BOOKMARKS_SET, pdfViewerBookmarkSaveWorker);
}

function* pdfViewerBookWorker({ payload: bookId }) {
  try {
    const response = yield call(getBookInfo, bookId);
    yield put(pdfViewerBookRequestSuccess(response.data));
  } catch (error) {
    yield put(pdfViewerBookRequestError(error));
  }
}

function* pdfViewerBookmarkGetWorker({ payload: {bookId, fileId} }) {
  try {
    const response = yield call(getBookmark, bookId, fileId);
    yield put(pdfViewerBookmarkRequestSuccess(response.data.mark ? JSON.parse(response.data.mark) : []));
  } catch (error) {
    yield put(pdfViewerBookmarkRequestError(error));
  }
}

function* pdfViewerBookmarkSaveWorker() {
  let bookmarks = yield select(({ pdfViewer }) => pdfViewer.bookmark);
  bookmarks = JSON.stringify(bookmarks);
  const { bookId, fileId } = yield select(({ pdfViewer }) => pdfViewer.fileData);

  try {
    const response = yield call(setBookmark, bookId, fileId, bookmarks);
    yield put(pdfViewerBookmarkSaveSuccess(response.data));
    yield put(pdfViewerBookmarkRequestStart(bookId, fileId));
  } catch (error) {
    yield put(pdfViewerBookmarkSaveError(error));
  }
}