import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { IMPORT_BOOKS_REQUEST, START } from "../../actions/action-types";
import { importBooks } from "../../../api/import/import";
import { importBooksRequestError, importBooksRequestSuccess } from "../../actions/import/import";

export default function* importBooksWatcher() {
  yield takeLatest(`${IMPORT_BOOKS_REQUEST}${START}`, importBooksWorker)
}

function* importBooksWorker() {
  const { file, format} = yield select(({ importBooks }) => importBooks.form);
  try {
    const response = yield call(importBooks, file, format);
    const { data } = response;
    yield put(importBooksRequestSuccess(data.ok ? data.ok : null));
    yield put(importBooksRequestError(data.error ? data.error : null))
  } catch ({ response }) {
    yield put(importBooksRequestError(response.data));
  }
}