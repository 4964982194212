import React, { useRef } from "react";
import styles from "./ChooseFile.module.css";

const ChooseImage = ({ onChange, children, className }) => {
  const ref = useRef(null);
  return (
    <div className={className} onClick={() => ref.current.click()}>
      {children}
      <input
        accept="image/*"
        onChange={e => {
          onChange(e.target.files[0]);
        }}
        className={styles.nativeInput}
        ref={ref}
        type="file"
        id="file"
        name="file"
      />
    </div>
  );
};

ChooseImage.defaultProps = {
  onChange: () => null
};

export default ChooseImage;
