import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DataTableCol from "../../DataTable/Column";
import DataTable from "../../DataTable";
import styles from './BookExportList.module.css';

const BookExportList = ({ data, deleteItem }) => {
  return (
    <div>
      <DataTable data={data}>
        <DataTableCol title="Дата создания" resolver={(item) => moment(item.created).format('DD-MM-YYYY hh:mm')}/>
        <DataTableCol title="Статус" resolver={(item) => {
          return item.finished ? moment(item.finished).format('DD-MM-YYYY hh:mm') : 'В работе'
        }}/>
        <DataTableCol title="Количество книг" value="books"/>
        <DataTableCol title="Ссылка на файл" resolver={item =>
          <a href={`/public/exports/${item.resultfile}`} download>{item.resultfile}</a>
        }/>
        <DataTableCol title="" resolver={item => <span className={styles.delete} onClick={() => deleteItem(item.id)}>Удалить</span>}/>
      </DataTable>
    </div>
  );
};

BookExportList.propTypes = {
  data: PropTypes.array,
  deleteItem: PropTypes.func,
};

export default BookExportList;
