import React from 'react';
import PropTypes from 'prop-types';
import styles from './AccessLogForm.module.css';
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import { SECURITY_LOG_TYPE } from "../../../constants/securityLogTypes";
import Button from "../../Button";

const SecurityLogForm = ({ data, setData, search }) => {
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  return (
    <div className={styles.container}>
      <Row>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="from"
                             value={data.from}
                             label="С (дата)"
                             type="date"
                             labelSpan={12}
                             inputSpan={12}/>
        </Col>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="to"
                             value={data.to}
                             label="До (дата)"
                             type="date"
                             labelSpan={12}
                             inputSpan={12}/>
        </Col>
        <Col md={12}>
          <ModalInputWrapper onChange={onChange}
                             name="fromip"
                             value={data.fromip}
                             label="IP-адрес"
                             labelSpan={12}
                             inputSpan={12}/>
        </Col>
        <Col md={6}>
          <ModalInputWrapper onChange={onChange}
                             name="username"
                             value={data.username}
                             label="Логин"
                             labelSpan={12}
                             inputSpan={12}/>
        </Col>
        <Col md={6}>
          <ModalInputWrapper label="Тип"
                             labelSpan={12}
                             inputSpan={12}>
            <select className={styles.select} onChange={onChange} name={"issue_type"} value={data.issue_type}>
              <option value={""}>Не выбрано</option>
              <option value={1}>{SECURITY_LOG_TYPE[1]}</option>
              <option value={2}>{SECURITY_LOG_TYPE[2]}</option>
              <option value={3}>{SECURITY_LOG_TYPE[3]}</option>
              <option value={4}>{SECURITY_LOG_TYPE[4]}</option>
            </select>
          </ModalInputWrapper>
        </Col>
        <Col md={12} className={"text-right"}>
          <Button label={"Найти"} onClick={search}/>
        </Col>
      </Row>
    </div>
  );
};

SecurityLogForm.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  search: PropTypes.func
};

export default SecurityLogForm;
