import React, {useEffect} from 'react';
import {ADD, EDIT} from "../../../constants/modalTypes";
import styles from '../../Documents/Table/DocumentsTable.module.css';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import moment from "moment";

const DocumentsTable = ({data, setModal, sortBy, setSort, selectedId, handleSelect, onDelete}) => {
    const openModal = (item) => setModal({modalType: EDIT, data: item});
    const openCreationModal = () => setModal({modalType: ADD, data: null});

    return (
        <div className={styles.container}>
            <div className={styles.buttonContainer}>
                <div className={styles.button} onClick={openCreationModal}>
                    добавить
                </div>
                <div className={selectedId ? styles.button : styles.disabled}
                     title={!selectedId ? 'Необходимо выбрать документ' : null} onClick={onDelete}>
                    удалить
                </div>
            </div>
            {!!data.length ?
                <DataTable data={data} sortBy={sortBy} onHeadClick={setSort} onCellClick={openModal}>
                    <DataTableCol title="Имя документа" value="name" colSpan={3}/>
                    <DataTableCol title="Дата документа" colSpan={2}
                                  resolver={({date_form}) => moment(date_form).format('DD.MM.YYYY')}
                    />
                    <DataTableCol title="Дата начала" colSpan={2}
                                  resolver={({datestart}) => moment(datestart).format('DD.MM.YYYY')}
                    />
                    <DataTableCol title="Дата завершения" colSpan={2}
                                  resolver={({dateend}) => moment(dateend).format('DD.MM.YYYY')}
                    />
                    <DataTableCol title="Выбрано" passClicks resolver={(item) => (
                        <input id={item.id} name={item.id} type="checkbox" checked={item.id === selectedId}
                               onChange={() => handleSelect(item.id)}/>
                    )}
                    />
                </DataTable>
                : <div className={styles.notFound}>Записи отсутствуют</div>}
        </div>
    )
};

export default DocumentsTable;
