import { combineReducers } from "redux";
import { alphabetItemsReducer } from "./items/alphabetItemsReducer";
import { alphabetRangeReducer } from "./range/alphabetRangeReducer";
import { alphabetSearchReducer } from "./search/alphabetSearchReducer";
import { alphabetLettersReducer } from "./letters/alphabetLettersReducer";
import { alphabetCountReducer } from "./count/alphabetCountReducer";

export const alphabetReducer = combineReducers({
  count: alphabetCountReducer,
  items: alphabetItemsReducer,
  range: alphabetRangeReducer,
  search: alphabetSearchReducer,
  letters: alphabetLettersReducer
});
