import { BOOKINVENTORY_COUNT_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = 0;

export const inventaryBookCountReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${BOOKINVENTORY_COUNT_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};
