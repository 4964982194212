import { combineReducers } from "redux";
import { documentCountReducer } from "./count/documentCountReducer";
import { inventoryListReducer } from "./list/documentListReducer";
import { documentSearchReducer } from "./search/documentSearchReducer";
import { documentBooksReducer } from "./books/documentBooksReducer";
import { documentNumberReducer } from "./number/documentNumberReducer";
import { documentLatestReducer } from "./latest/documentLatestReducer";
import { documentInventaryReducer } from './Inventary/documentInventaryReducer';
import { documentFundAllocationReducer } from './fundAllocation/documentFundAllocationReducer';
import { libraryFundAllocationReducer } from './fundAllocation/libraryFundAllicationReducer';
import { documentBookDistributeReducer } from './bookDistribute/documentBookDistributeReducer';

export const inventoryReducer = combineReducers({
  count: documentCountReducer,
  list: inventoryListReducer,
  search: documentSearchReducer,
  books: documentBooksReducer,
  inventary: documentInventaryReducer,
  bookDistribute: documentBookDistributeReducer,
  number: documentNumberReducer,
  latest: documentLatestReducer,
  libraries: documentFundAllocationReducer,
  libraryFunds: libraryFundAllocationReducer
});
