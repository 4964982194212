import React from 'react';
import PropTypes from 'prop-types';
import Specialization from "../Specialization";

import styles from './BookSupplyTable.module.css';

const BookSupplyTable = ({milspecs}) => {

    if(!milspecs.length) {
        return null;
    }

    return (
        <div className={styles.table}>
            <div className={styles.row}>
                <div className="tableTitle_rowTitle"/>
                <div className={styles.cell}>1 курс</div>
                <div className={styles.cell}>2 курс</div>
                <div className={styles.cell}>3 курс</div>
                <div className={styles.cell}>4 курс</div>
                <div className={styles.cell}>5 курс</div>
                <div className={styles.cell}>общие</div>
            </div>
            {milspecs.map((item, index) => (
                <Specialization key={index} data={item}/>
            ))}
        </div>
    );
};

BookSupplyTable.propTypes = {
    milspecs: PropTypes.array.isRequired,
};

export default BookSupplyTable;