import React from 'react';
import PropTypes from 'prop-types';
import ModalInputWrapper from "../../Modal/InputWrapper";
import CoversSearchFormControls from "../../Covers/SearchFormControls";


const ReadingSmallSearch = ({data, setData, onSubmit}) => {
    const onChange = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    };

    const clearForm = () => {
        setData({...data, searchField: ''})
    };

    return (
        <div>
            <ModalInputWrapper
                onChange={onChange}
                name="searchField"
                value={data.searchField}
                label="Поиск"
            />
            <CoversSearchFormControls
                onSubmit={onSubmit}
                onClear={clearForm}
            />
        </div>
    );
};

ReadingSmallSearch.propTypes = {
    setData: PropTypes.func,
    data: PropTypes.object
};

export default ReadingSmallSearch;