import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./CoverItemModal.module.css";

const CoverItemModal = ({ show, close, places, request, modalId }) => {
  useEffect(() => {
    modalId && request(modalId);
  }, [modalId]);

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton className={styles.header}>
        Места хранения
      </Modal.Header>
      <Modal.Body>
        {places.map(item => (
          <div className={styles.line}>
            <div>Инв.номер: {item.number}</div>
            <div>Место хранения: {item.placename}</div>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default CoverItemModal;
