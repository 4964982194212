import React, { useState, useEffect } from "react";
import styles from "./NotesTab.module.css";

const typesToOptions = types =>
  types.map(item => ({ value: item.id, label: item.name }));

const NotesTab = ({
  getTypes,
  types,
  getList,
  list,
  bookId,
  addNote,
  delNote
}) => {
  const [noteTypeId, setNoteTypeId] = useState(300);
  const [note, setNote] = useState("");

  useEffect(() => {
    getTypes();
  }, []);
  useEffect(() => {
    getList(bookId);
  }, [bookId]);

  return (
    <div>
      <div className={styles.controlPanel}>
        <select
          className={styles.select}
          onChange={e => setNoteTypeId(e.target.value)}
          value={noteTypeId}
        >
          {typesToOptions(types).map((item, index) => (
            <option value={item.value} key={`typesToOptions${index}`}>{item.label}</option>
          ))}
        </select>
        <label className={styles.label}>
          Примечание:
          <input
            className={styles.input}
            value={note}
            onChange={e => setNote(e.target.value)}
          />
        </label>
        <button
          className={styles.button}
          onClick={() => {
            addNote(bookId, { noteTypeId, note });
            setNote("");
          }}
        >
          Добавить
        </button>
      </div>
      <div className={styles.notesList}>
        {list.map((item, index) => (
          <div key={`listItem${index}`} className={styles.note}>
            <span className={styles.noteSpan}>
              <div
                className={styles.buttondel}
                onClick={() => delNote({ id: item.id, bookId: bookId })}
              >
                Х
              </div>
              [{item.notetype}]
            </span>{" "}
            {item.note}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotesTab;
