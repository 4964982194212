import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from './ReadingAdvancedSearch.module.css';
import Button from "../../Button";
import classNames from 'classnames';
import AdvancedSearchItem from './Item'
import CoversSearchFormControls from "../../Covers/SearchFormControls";
import {advancedSearchFormRowTemplate} from "../../../scenes/Reading";



const ReadingAdvancedSearch = ({searchParams, data, setData, onSubmit}) => {


    const clearForm = () => {
        setData([JSON.parse(JSON.stringify(advancedSearchFormRowTemplate))]);
    };

    const addRow = () => {
        let updatedSearchData = [...data];

        updatedSearchData.push(JSON.parse(JSON.stringify(advancedSearchFormRowTemplate)));
        setData(updatedSearchData);
    };

    const deleteRow = () => {
        let updatedSearchData = [...data];
        updatedSearchData.pop();
        setData(updatedSearchData);
    };

    return (
        <div className={styles.container}>
            <div className={'d-flex'}>
                <div className={classNames(styles.title, styles.fieldWrapper)}>Параметр поиска</div>
                <div className={classNames(styles.title, styles.fieldWrapper)}>Условие поиска</div>
                <div className={classNames(styles.title, styles.fieldWrapper)}>Значение</div>
                <div className={classNames(styles.title, styles.fieldWrapper)}>Знак</div>
            </div>
            {searchParams && data.map((item, index) =>
                <AdvancedSearchItem searchParams={searchParams} setSearchData={setData} data={item} key={index}
                                    index={index} searchData={data}/>)
            }

            <div className={'d-flex justify-content-end mt-3'}>
                <Button
                    onClick={addRow}
                    label={'Добавить строку'}
                    colorType={'dark-blue'}
                />
                <Button
                    onClick={deleteRow}
                    label={'Удалить строку'}
                    colorType={'red'}
                    style={'ml-2'}
                    disabled={data.length === 1}
                />
            </div>
            <CoversSearchFormControls
                onSubmit={onSubmit}
                onClear={clearForm}
            />
        </div>
    );
};

ReadingAdvancedSearch.propTypes = {
    searchParams: PropTypes.object,
    addRow: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
};

export default ReadingAdvancedSearch;