import { DOCUMENT_BOOK_DISTRIBUTE_GET_LIST_REQUEST, START, SUCCESS, ERROR } from "../../action-types";

export const bookDistributeLoadRequestStart = (data) => ({
  type: `${DOCUMENT_BOOK_DISTRIBUTE_GET_LIST_REQUEST}${START}`,
  payload: data
});

export const bookDistributeLoadRequestSuccess = (data) => ({
  type: `${DOCUMENT_BOOK_DISTRIBUTE_GET_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const bookDistributeLoadRequestError = (data) => ({
  type: `${DOCUMENT_BOOK_DISTRIBUTE_GET_LIST_REQUEST}${ERROR}`,
  payload: data
});
