export const INV_NUMBERS_FORM_STRUCTURE = {
  title: "",
      author: "",
      category: "",
      isbn: "",
      year: "",
      udk: "",
      bbk: "",
      discipline: "",
      publisher: "",
      textsearch: "",
      verifyStatus: ""
};
