import React from 'react';
import PropTypes from 'prop-types';
import { ADD, EDIT } from "../../../constants/modalTypes";
import styles from "../../LibraryTypes/Table/LibraryTypesTable.module.css";
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import { COLLECTION_TYPE } from "../../../constants/collectionTypes";

const CollectionsTable = ({ data, setModal, selectedId, handleSelect, onDelete, initialFormFields }) => {
    const openEditModal = (item) => setModal({ modalType: EDIT, data: item });
    const openCreationModal = () => setModal({ modalType: ADD, data: initialFormFields });

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <div className={styles.button} onClick={openCreationModal}>
          добавить
        </div>
        <div className={styles.button} onClick={onDelete}>
          удалить
        </div>
      </div>
      <DataTable data={data} onCellClick={openEditModal}>
        <DataTableCol title="Название коллекции" value="name" colSpan={6} className={styles.leftAlign}/>
        <DataTableCol title="Тип" colSpan={2}
                      resolver={(item) => item.ctype === COLLECTION_TYPE.DYNAMIC ? "Динамическая" : "Статическая"}/>
        <DataTableCol title="Выбрано" passClicks resolver={(item) =>
          <input type="checkbox" checked={item.id === selectedId}
                 onChange={() => handleSelect(item.id)}/>}
        />
      </DataTable>
    </div>
  );
};

CollectionsTable.propTypes = {
  data: PropTypes.array,
  setModal: PropTypes.func,
  selectedId: PropTypes.any,
  handleSelect: PropTypes.func,
  onDelete: PropTypes.func
};

export default CollectionsTable;
