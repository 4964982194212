import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { ROLE_MEMBERS_LIST_REQUEST, START } from "../../../actions/action-types";
import {
  roleMembersListRequestError,
  roleMembersListRequestSuccess
} from "../../../actions/roles/membersList";
import { getRoleMembers } from "../../../../api/roles/roles";

export default function* roleMembersListWatcher() {
  yield takeLatest(`${ROLE_MEMBERS_LIST_REQUEST}${START}`, roleMembersListWorker)
}

function* roleMembersListWorker({payload: id}) {
  try {
    const response = yield call(getRoleMembers, id);
    yield put(roleMembersListRequestSuccess(response.data));
  } catch (error) {
    yield put(roleMembersListRequestError(error))
  }
}