import React from 'react';
import PropTypes from 'prop-types';
import styles from './BooksList.module.css';
import BooksListItem from "../ListItem";
import Button from "../../Button";

const BooksList = ({data, termOfUse, createBook, onExportBooks, setBatchModalOpen, handleSelect, selectedIds}) => {

    return (
        <div>
            <div className={'mt-2'}>
                <Button onClick={createBook} label={'Добавить'}/>
                <Button onClick={onExportBooks} label={'Выгрузить'} style={'ml-2'} colorType={'dark-blue'}/>

                <Button onClick={createBook} label={'Загрузить'} style={'ml-2'} colorType={'green'}/>
                {!!selectedIds.length &&
                <Button onClick={() => setBatchModalOpen(true)} label={'Пакетное редактирование'} style={'ml-2'}
                        colorType={'transparent'}/>
                }
            </div>
            <div className={styles.container}>
                {data.map((item, index) =>
                    <BooksListItem data={item} index={index} termOfUse={termOfUse} key={index} onSelect={handleSelect}
                                   selectedIds={selectedIds}/>
                )}
            </div>
        </div>
    );
};


BooksList.propTypes = {
    data: PropTypes.array,
    termOfUse: PropTypes.number,
    updateBookId: PropTypes.func,
    batchModalData: PropTypes.object
};

export default BooksList;
