import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import styles from "../BookEdit/BookEditScene.module.css";
import BookExportList from '../../components/BookExport/List';
import { bookExportsLoadRequestStart } from '../../redux/actions/bookExport/getExports';
import { bookExportsDeleteRequestStart, bookExportsDeleteRequestReset } from '../../redux/actions/bookExport/delete';
import {createNotification} from "../../components/notification/notification";


const mapStateToProps = ({ booksExport }) => ({
  list: booksExport.list,
  deleteItem: booksExport.delete
});

const mapDispatchToProps = {
  loadBookExports: bookExportsLoadRequestStart,
  deleteBookExport: bookExportsDeleteRequestStart,
  resetDeleteReducer: bookExportsDeleteRequestReset
};

const BookExportScene = ({ loadBookExports, list, deleteBookExport, resetDeleteReducer, deleteItem }) => {

  const onDelete = (id) => {
    deleteBookExport(id);
  };

  useEffect(() => {
    loadBookExports();
  }, [loadBookExports]);

  useEffect(() => {
    if(deleteItem.isCompleted) {
      createNotification('success', 'Задание на выгрузку успешно удалено');
      resetDeleteReducer();
      loadBookExports();
    }
    if(deleteItem.isError) {
      createNotification('error', 'Не удалось удалить задание на выгрузку');
      resetDeleteReducer();
    }
  }, [deleteItem]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Задания на выгрузку БЗ</div>
      <BookExportList
        data={list}
        deleteItem={onDelete}
      />
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookExportScene);
