import React, { useState, useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import Search from "../../../components/Search";
import { institutionsCountRequestStart } from "../../../redux/actions/institutions/count";
import { setInstitutionsSearchData } from "../../../redux/actions/institutions/search";
import { institutionsSaveRequestStart } from "../../../redux/actions/institutions/save";
import { connect } from "react-redux";
import Pagination from "../../../components/Pagination";
import InstitutionsModal from "../../../components/Institutions/Modal";
import InstitutionsTable from "../../../components/Institutions/Table";
import { regionsListRequestStart } from "../../../redux/actions/regions/list";
import { institutionsDeleteRequestStart } from "../../../redux/actions/institutions/delete";
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";


const mapStateToProps = (state) => ({
  searchData: state.institutions.search,
  list: state.institutions.list,
  count: state.institutions.count,
  regions: state.regions.list
});

const mapDispatchToProps = {
  requestInstitutions: institutionsCountRequestStart,
  setSearchData: setInstitutionsSearchData,
  saveInstitution: institutionsSaveRequestStart,
  requestRegions: regionsListRequestStart,
  deleteInstitution: institutionsDeleteRequestStart
};

const MODAL_FORM_FIELDS = {
  name: "",
  region: ""
};

const InstitutionsScene = ({
  count,
  list,
  searchData,
  setSearchData,
  requestInstitutions,
  requestRegions,
  regions,
  saveInstitution,
  deleteInstitution
}) => {
  useEffect(() => {
    requestInstitutions();
    requestRegions()
  }, [requestInstitutions, requestRegions]);

  const [modalData, setModalData] = useState({ modalType: null, data: null });
  const [selectedId, setSelectedId] = useState(null);


  const onSubmitModal = (values) => {
    saveInstitution({...modalData, data: {...modalData.data, ...values}}, closeModal);
  };

  const handleSelect = (id) => setSelectedId(selectedId === id ? null : id);

  const setSort = (sortBy) => {
    setSearchData({ ...searchData, sortBy, page: 1 });
    requestInstitutions();
  };

  const onDelete = () =>
    selectedId && deleteInstitution(selectedId);

  const closeModal = () => {
    setModalData({ modalType: null, data: null });
  };

  return (
    <SceneContainer title="Справочники / Организации/НИО/ВЧ">
      <Row>
        <Col md={12}>
          <Search value={searchData.query} onChange={query => setSearchData({ ...searchData, query })}
                  onSubmit={requestInstitutions} placeholder={"поиск"}/>
        </Col>
        <Col md={12}>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={(page) => {
            setSearchData({ ...searchData, page });
            requestInstitutions()
          }}/>
        </Col>
        <Col md={12}>
          {modalData.data &&
            <InstitutionsModal modalData={modalData} setModalData={setModalData} onSubmit={onSubmitModal}
                               regionList={regions} closeModal={closeModal}/>
          }

          <InstitutionsTable setModal={setModalData} setSort={setSort} data={list} sortBy={searchData.sortBy}
                             selectedId={selectedId} handleSelect={handleSelect} onDelete={onDelete} initialFormFields={MODAL_FORM_FIELDS}/>
        </Col>
        <Col md={12}>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={(page) => {
            setSearchData({ ...searchData, page });
            requestInstitutions()
          }}/>

        </Col>
      </Row>
    </SceneContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionsScene);
