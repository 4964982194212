import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import styles from './UserMenu.module.css';
import classNames from 'classnames';
import { Link } from "react-router-dom";
import { logoutRequestStart } from "../../../../redux/actions/user/logout";

const mapStateToProps = state => ({
  authData: state.auth.data
});

const mapDispatchToProps = dispatch => ({
  logOut: () => dispatch(logoutRequestStart())
});

const UserMenu = ({ authData, logOut, classes, layoutOptions }) => {
  const displayName = `${authData.lastname} ${authData.firstname} ${authData.patronymic}`;


  return (
    <div className={classNames(classes, styles.container)} style={{backgroundColor: `${layoutOptions.header.background}a0`}}>
      <div className={styles.info}>
        {displayName}
      </div>
      <div className={styles.links}>
        {authData.islibrarian &&
          <>
          <Link to={'/options'} className={styles.link}>
            настройки
          </Link>
          <Link to={'/admin/menu'} className={styles.link}>
            администрирование
          </Link>
          </>
        }

        <div className={styles.link} onClick={logOut}>
          выйти
        </div>
      </div>
    </div>
  );
};

UserMenu.propTypes = {
  data: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
