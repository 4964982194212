import {
    call,
    put,
    takeLatest,
    select
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";
import {
    addSchedulerItem,
    deleteSchedulerItem,
    editSchedulerItem,
    getSchedulerCategoryCount,
    getSchedulerCategoryList,
    getSchedulerMetadataCount,
    getSchedulerMetadataList
} from "../../../api/sheduler/sheduler";
import {schedulerEditRequestError, schedulerEditRequestSuccess} from "../../actions/scheduler/metadata/edit";
import {schedulerDeleteRequestError, schedulerDeleteRequestSuccess} from "../../actions/scheduler/metadata/delete";
import {schedulerAddRequestError, schedulerAddRequestSuccess} from "../../actions/scheduler/metadata/add";
import {
    SCHEDULER_ADD_REQUEST, SCHEDULER_CATEGORY_COUNT_REQUEST, SCHEDULER_CATEGORY_LIST_REQUEST,
    SCHEDULER_DELETE_REQUEST,
    SCHEDULER_EDIT_REQUEST,
    START
} from "../../actions/action-types";
import {
    schedulerMetadataCountRequestStart,
} from "../../actions/scheduler/metadata/count";
import {
    schedulerCategoriesCountRequestError, schedulerCategoriesCountRequestStart,
    schedulerCategoriesCountRequestSuccess
} from "../../actions/scheduler/categories/count";
import {setSchedulerCategoriesSearchData} from "../../actions/scheduler/categories/search";
import {
    schedulerCategoriesListRequestError,
    schedulerCategoriesListRequestStart,
    schedulerCategoriesListRequestSuccess
} from "../../actions/scheduler/categories/list";


export default function* schedulerMetadataWatcher() {
    yield takeLatest(`${SCHEDULER_CATEGORY_COUNT_REQUEST}${START}`, categoryCountWorker);
    yield takeLatest(`${SCHEDULER_CATEGORY_LIST_REQUEST}${START}`, categoryListWorker);
    yield takeLatest(`${SCHEDULER_EDIT_REQUEST}${START}`, editWorker);
    yield takeLatest(`${SCHEDULER_DELETE_REQUEST}${START}`, deleteWorker);
    yield takeLatest(`${SCHEDULER_ADD_REQUEST}${START}`, addWorker);
}

function* categoryCountWorker({ payload: id }) {

    const searchData = yield select(({scheduler}) => scheduler.categories.search);

    try {
        const response = yield call(getSchedulerCategoryCount, id);
        const count = parseInt(response.data.count, 10);
        yield put(schedulerCategoriesCountRequestSuccess(count));
        const pageCount = Math.ceil(count / searchData.maxResults);
        yield put(setSchedulerCategoriesSearchData({...searchData, pageCount}));
        if (count > 0) {
            yield put(schedulerCategoriesListRequestStart(id))
        }
    } catch(error) {
        yield put(schedulerCategoriesCountRequestError(error));
    }
}

function* categoryListWorker({ payload: id }) {
    try {
        const response = yield call(getSchedulerCategoryList, id);
        yield put(schedulerCategoriesListRequestSuccess(response.data));
    } catch(error) {
        yield put(schedulerCategoriesListRequestError(error));
    }
}



function* editWorker({payload: data, callback}) {
    try {
        const response = yield call(editSchedulerItem, data);
        yield put(schedulerEditRequestSuccess(response.data));
        if(callback) {
            yield call(callback);
        }
        yield put(schedulerCategoriesCountRequestStart(data.categoryId));
    } catch (error) {
        yield put(schedulerEditRequestError(error));
    }
}

function* deleteWorker({payload: id}) {
    try {
        yield call(deleteSchedulerItem, id);
        yield put(schedulerDeleteRequestSuccess());
        yield put(schedulerCategoriesCountRequestStart());
    } catch (error) {
        yield put(schedulerDeleteRequestError(error));
    }
}

function* addWorker({payload: data, callback}) {
    try {
        const response = yield call(addSchedulerItem, data);
        yield put(schedulerAddRequestSuccess(response.data));
        yield call(callback);
        yield put(schedulerCategoriesCountRequestStart());
    } catch (error) {
        yield put(schedulerAddRequestError(error));
    }
}