import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Alphabet.module.css';
import { Col, Row } from "react-bootstrap";

const Alphabet = ({ onSelect, items, span, itemClassName }) => {
  return (
    <div className={styles.container}>
      <Row>
        {items.map((item, index) => (
          <Col md={span} key={index}>
            <div className={classNames(styles.mainItem, itemClassName)} onClick={() => onSelect(item)}>
              {item}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

Alphabet.propTypes = {
  onSelect: PropTypes.func,
  items: PropTypes.array,
  itemClassName: PropTypes.string,
  span: PropTypes.number
};

Alphabet.defaultProps = {
  span: 6
};

export default Alphabet;
