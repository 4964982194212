import React, { useEffect, useState } from 'react';
import SpinnerOuter from "../../../UtulityComponents/SpinnerOuter";
import DataTable from "../../../DataTable";
import DataTableCol from "../../../DataTable/Column";
import {connect} from "react-redux";
import { getBookFile } from '../../../../api/book/files';
import { bookEditFilesActions, setBookEditFilesSortData } from "../../../../redux/factory/BookEditFiles/bookEditFilesReduxData";
import blankFile from '../../../../img/icons/file-formats/blank.png';
import djvuFile from '../../../../img/icons/file-formats/djvu-format.png';
import docFile from '../../../../img/icons/file-formats/doc-format.png';
import jpgFile from '../../../../img/icons/file-formats/jpg-format.png';
import pdfFile from '../../../../img/icons/file-formats/pdf-format.png';
import pngFile from '../../../../img/icons/file-formats/png-format.png';
import txtFile from '../../../../img/icons/file-formats/txt-format.png';
import xlsFile from '../../../../img/icons/file-formats/xls-format.png';
import moment from "moment";
import Button from "../../../Button";
import LoadForm from "./loadForm";
import styles from './BookEditFiles.module.css';
import PropTypes from 'prop-types';
import BookEditFilesModal from "./Modal";


const mapStateToProps = ({ bookEdit }) => ({
  data: bookEdit.files.data,
  loader: bookEdit.files.loading
});

const mapDispatchToProps = {
  onRequest: bookEditFilesActions.getRequestStart,
  onUpload: bookEditFilesActions.uploadRequestStart,
  onEdit: bookEditFilesActions.editRequestStart,
  onDelete: bookEditFilesActions.deleteRequestStart,
  setSortData: setBookEditFilesSortData
};

const getIcon = (filename) => {
  let fileFormat = filename.split('.').reverse()[0];

  switch(fileFormat) {
    case 'djvu':
      return djvuFile;
    case 'doc':
      return docFile;
    case 'docx':
      return docFile;
    case 'rtf':
      return docFile;
    case 'jpg':
      return jpgFile;
    case 'jpeg':
      return jpgFile;
    case 'pdf':
      return pdfFile;
    case 'png':
      return pngFile;
    case 'txt':
      return txtFile;
    case 'xls':
      return xlsFile;
    case 'xlsx':
      return xlsFile;
    case 'csv':
      return xlsFile;
    default:
      return blankFile;
  }
};

const BookEditFilesTab = ({ bookId, data, loader, onRequest, onUpload, onEdit, onDelete }) => {
  useEffect(() => {
    onRequest(Number(bookId));
  }, [onRequest]);

  const [modalData, setModalData] = useState(null);

  const uploadFile = file => {
    onUpload({
      bookId: bookId,
      file: file,
      body: {
        filedate: new Date().toISOString()
      }
    })
  };

  const setVisibility = (item) => {
    const revertedVisibility = !item.visible;
    const editedData = {
      ...item,
      "visible": revertedVisibility,
    };

    editItem(editedData)
  };

  const editItem = (item) => {
    onEdit({
      bookId,
      fileId: item.id,
      data: item
    });
  };

  const closeModal = () => {
    setModalData(null)
  };

  const submitModal = () => {
    editItem(modalData);
    closeModal();
  };

  const downloadFile = (item) => {
    const bookId = item.bookid;
    const fileId = item.id;

    getBookFile(bookId, fileId)
      .then(response => {

        let url = response.request.responseURL;
        let a = document.createElement('a');
        a.href = url;
        a.download = item.filename;
        a.target = "_blank";
        a.click();
      })
      .catch(err => {
        console.log(err)
      });
  };


  return (
    <div>
      {loader ?
        <SpinnerOuter isLoading={loader} />
        :
        <>
          <LoadForm onUpload={uploadFile}/>

          {modalData &&
          <BookEditFilesModal data={modalData} onSubmit={submitModal} closeModal={closeModal} setData={setModalData}/>
          }

          <DataTable data={data} onCellClick={item => setModalData(item)}>
            <DataTableCol title={"Тип"} resolver={(item) => <img className={styles.fileIcon} src={getIcon(item.filename)} alt=""/>}/>
            <DataTableCol title={"Название"} colSpan={3} resolver={item =>
              <span className={styles.loadLink} onClick={() => downloadFile(item)}>{item.filename}</span>
            }/>
            <DataTableCol title={"Дата загрузки"} colSpan={2} resolver={(item) => (moment(item.filedate).format('DD.MM.YYYY hh:mm'))}/>
            <DataTableCol title={"Версия"} colSpan={2} value={"version"}/>
            <DataTableCol title={"Виден"} resolver={(item) =>
              <input
                type="checkbox"
                checked={item.visible}
                onChange={() => setVisibility(item)}
              />}
            />
            <DataTableCol colSpan={2} title={''} resolver={item =>
              <Button label={'Удалить'} onClick={() => onDelete({bookId, fileId: item.id})} colorType={'red'}/>}
            />
          </DataTable>
        </>
      }
    </div>
  );
};

BookEditFilesTab.propTypes = {
  bookId: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookEditFilesTab);