import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "react-bootstrap";
import styles from "./BookEditControls.module.css";
import Button from "../../Button";
import {api} from "../../../App";
import {createNotification} from "../../notification/notification";

const BookEditControls = ({bookData, bookId, history, compressBook, decompressBook, checkedOnSave, requestData}) => {

    const [bookDeleteLoading, setBookDeleteLoading] = useState(false);

    const onCancel = () => {
        api.delete(`/book/${bookId}`)
            .then(() => {
                history.push(`/books`);
            });
    };

    const onDeleteBook = () => {
        setBookDeleteLoading(true);
        api.delete(`/book/${bookId}`).then(() => {
            createNotification('success', 'Библиографическая запись удалена');
            requestData(bookId);
            setBookDeleteLoading(false);
        });
    };

    const printCatalogCard = () => {
        if (bookId) {
            const printedDoc = window.open(
                `/catalogcard_print/${bookId}`
            );
            printedDoc.focus();
        }
    };

    return (
        <div className={styles.container}>
            <Row>
                <Col md={6}>
                    {history.location.search === "?newbook" ? (
                        <Button
                            onClick={onCancel}
                            label={"Отменить создание"}
                        />
                    ) : (
                        <>
                            <Button
                                onClick={() => history.push(`/books`)}
                                colorType={'transparent'}
                                label={"Закрыть"}
                            />
                            <Button
                                onClick={onDeleteBook}
                                spinner={bookDeleteLoading}
                                colorType={'red'}
                                label={"Удалить библ. запись"}
                                style={'ml-2'}
                            />
                        </>
                    )}
                </Col>
                <Col md={6} className={"text-right"}>
                    <div>
                        {bookData && !bookData.pdfIsCompressed ? (
                            bookData.pdfCanBeCompressed && (
                                <Button
                                    onClick={() => compressBook(bookData.bookid)}
                                    label={"Сжать"}
                                    style={'ml-2'}
                                />
                            )
                        ) : (
                            <Button
                                onClick={() => decompressBook(bookData.bookid)}
                                label={"Распаковать"}
                                style={'ml-2'}
                            />
                        )}
                        <Button
                            onClick={printCatalogCard}
                            label={"Печать карты"}
                            style={'ml-2'}
                        />
                        <Button
                            onClick={checkedOnSave}
                            label={"Сохранить"}
                            style={'ml-2'}
                            colorType={'green'}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

BookEditControls.propTypes = {
    bookData: PropTypes.object,
    bookId: PropTypes.any,
    compressBook: PropTypes.func,
    decompressBook: PropTypes.func,
    checkedOnSave: PropTypes.func,
};

export default BookEditControls;