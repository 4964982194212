import {combineReducers} from "redux";
import {authReducer} from "./auth/authReducer";
import {publishersReducer} from "./publishers/publishersReducer";
import {udkListReducer} from "./udk/udkListReducer";
import {authorsReducer} from "./authors/authorsReducer";
import {libraryReducer} from "./library/libraryReducer";
import {institutionsReducer} from "./institutions/institutionsReducer";
import {readerSearchReducer} from "./readerSearch/readerSearchReducer";
import {regionsReducer} from "./regions/regionsReducer";
import {bbkListReducer} from "./bbk/bbkListReducer";
import {rubricatorReducer} from "./rubricator/rubricatorReducer";
import {coversReducer} from "./covers/coversReducer";
import {invNumbersReducer} from "./invNumbers/invNumbersReducer";
import {bookDataReducer} from "./bookData/bookDataReducer";
import {rolesReducer} from "./roles/rolesReducer";
import {optionsReducer} from "./options/optionsReducer";
import {usersReducer} from "./adminUsers/usersReducer";
import {locationReducer} from "./location/locationReducer";
import {collectionsReducer} from "./collections/collectionsReducer";
import {permissionsReducer} from "./permissions/permissionsReducer";
import {externalResourcesReducer} from "./externalResources/externalResourcesReducer";
import {pdfViewerReducer} from "./pdfViewer/pdfViewerReducer";
import {securityLogReducer} from "./securityLog/securityLogReducer";
import {reservedBooksListReducer} from "./reservedBooks/reservedBooksListReducer";
import {auditLogReducer} from "./auditLog/auditLogReducer";
import {readerDataReducer} from "./readerData/readerDataReducer";
import {qrCodesReducer} from "./qrCodes/qrCodesReducer";
import {reportsReducer} from "./reports/reportsReducer";
import {importBooksReducer} from "./import/importBooksReducer";
import {havkinaReducer} from "./havkina/havkinaReducer";
import {documentTypesReducer} from "./documentTypes/documentTypesReducer";
import {documentReducer} from "./documents/documentReducer";
import {inventoryReducer} from "./inventory/inventoryReducer";
import {booksOrderReducer} from "./booksOrder/booksOrderReducer";
import {newBooksOrderReducer} from "./newBooksOrder/newBooksOrderReducer";
import {systemVersionReducer} from "./systemVersion/systemVersionReducer";
import {adminModalUsersReducer} from "./adminModalUsers/reducer";
import {teachersReducer} from "./teachers/teachersReducer";
import {scheduleReducer} from "./schedule/scheduleReducer";
import {googleBooksReducers} from "../factory/googleBooksReduxData";
import {availableBooksReducers} from "../factory/availableBooksModalReduxData";
import {onHandsBooksReducers} from "../factory/onHandsBooksReduxData";
import {bookPlacesReducer} from "../factory/bookPlacesReduxData";
import {schedulePersonalReducer} from "../factory/scheduleReduxData";
import {searchByImageReducers} from "../factory/searchByImageReduxData";
import {curriculumListReducer} from "./curriculum/curriculumListReducer";
import {secrecyReducer} from "./outsecrecy/secrecyReducer";
import {booksEditReducer} from "./bookEdit/bookEditReducer";
import {authorBooksReducer} from "../factory/authorsBooksReduxData";
import {inventaryBookReducer} from "./inventaryBook/inventaryBookReducer";
import {fundMovingReducer} from "./fundMoving/fundMovingReducer";
import {fundAccountingReducer} from "./fundAccounting/fundAccountingReducer";
import {studentProjectsReducers} from "../factory/studentProjectsReduxData";
import {duplicateReducer} from "./duplicate/duplicateReducer";
import {remcomplectReducer} from "./remcomplect/remcomplectReducer";
import {turnoutStatsReducer} from "./turnoutStats/turnoutStatsReducer";
import {searchAuditReducer} from "./searchAudit/searchAuditReducer";
import {bookScanPlanReducer} from "../factory/bookScanPlanReduxData";
import {libraryMaterialsReducer} from "../reducers/libraryMaterials/libraryMaterialsReducer";
import {booksExportReducer} from "./booksExport/booksExportReducer";
import {bigDataReducer} from "../factory/BigData/bigDataReduxData";
import {expertOpinionReducers} from "../factory/ExpertOpinionReduxData";
import {getBookChangeHistoryReducers} from "../factory/bookHistoryChangeReduxData";
import {bookNotesReducers} from "../factory/bookNotesReduxData";
import {pagesReducers} from "../factory/PagesReduxData";
import {categoriesReducer} from './categories/categoriesReducer';
import {accessCategoryReducer} from './accessCategory/accessCategoryReducer';
import {litersReducer} from './liters/litersReducer';
import {seriesReducer} from './series/seriesReducer';
import {accessSeriesReducer} from './accessSeries/accessSeriesReducer';
import {accessLitersReducer} from './accessLiters/accessLitersReducer';
import {bookScanOrdersReducer} from './bookScanOrders/bookScanOrdersReducer';
import {mediaResourceReducer} from "./mediaResource/mediaResourceReducer";
import {bookSupplyReducer} from "./bookSupply/bookSupplyReducer";
import {messageReducer} from "./message/messageReducer";
import {borrowingTestReducer} from "./borrowingTest/borrowingTestReducer";
import {historyLocationReducer} from "./historyLocation/historyLocationReducer";
import {bookTransferReducer} from "./bookTransfer/bookTransferReducer";
import {schedulerReducer} from "./scheduler/schedulerReducer";
import {bookreserveReducer} from "./bookreserve/bookreserveReducer";
import {creditsReducer} from "./credits/creditsReducer";
import {bookCardReducer} from "./bookCard/bookCardReducer";
import {electronicCoursesReducer} from "./electronicCourses/electronicCoursesReducer";
import {electronicBookImportReducer} from "./ElectronicBookImport/ElectronicBookImportReducer";
import {bookSourceReducer} from "./bookSource/bookSourceReducer";
import {epubReaderReducer} from "./epubReader/epubReaderReducer";
import {testsReducer} from "./tests/testsReducer";
import {inventoryCoversReducer} from "./inventoryCovers/coversReducer";

export const rootReducer = combineReducers({
    accessCategories: accessCategoryReducer,
    accessLiters: accessLitersReducer,
    accessSeries: accessSeriesReducer,
    adminUsers: usersReducer,
    auditLog: auditLogReducer,
    auth: authReducer,
    authors: authorsReducer,
    bbk: bbkListReducer,
    bigData: bigDataReducer,
    bookCard: bookCardReducer,
    bookData: bookDataReducer,
    bookEdit: booksEditReducer,
    bookChangeHistory: getBookChangeHistoryReducers,
    bookNotes: bookNotesReducers,
    bookScanPlan: bookScanPlanReducer,
    bookSource: bookSourceReducer,
    bookSupply: bookSupplyReducer,
    booksExport: booksExportReducer,
    booksOrder: booksOrderReducer,
    bookreserve: bookreserveReducer,
    bookScanOrders: bookScanOrdersReducer,
    bookTransfer: bookTransferReducer,
    borrowingTest: borrowingTestReducer,
    categories: categoriesReducer,
    collections: collectionsReducer,
    covers: coversReducer,
    credits: creditsReducer,
    curriculum: curriculumListReducer,
    documents: documentReducer,
    documentTypes: documentTypesReducer,
    electronicBook: electronicBookImportReducer,
    electronicCourses: electronicCoursesReducer,
    epubReader: epubReaderReducer,
    externalResources: externalResourcesReducer,
    fundMoving: fundMovingReducer,
    fundAccounting: fundAccountingReducer,
    havkina: havkinaReducer,
    historyLocation: historyLocationReducer,
    importBooks: importBooksReducer,
    institutions: institutionsReducer,
    inventory: inventoryReducer,
    inventoryCovers: inventoryCoversReducer,
    invNumbers: invNumbersReducer,
    inventaryBook: inventaryBookReducer,
    library: libraryReducer,
    location: locationReducer,
    liters: litersReducer,
    mediaResource: mediaResourceReducer,
    message: messageReducer,
    options: optionsReducer,
    pdfViewer: pdfViewerReducer,
    permissions: permissionsReducer,
    publishers: publishersReducer,
    qrCodes: qrCodesReducer,
    readerData: readerDataReducer,
    readerSearch: readerSearchReducer,
    regions: regionsReducer,
    reports: reportsReducer,
    reservedBooks: reservedBooksListReducer,
    roles: rolesReducer,
    rubricator: rubricatorReducer,
    securityLog: securityLogReducer,
    scheduler: schedulerReducer,
    systemVersion: systemVersionReducer,
    adminModalUsers: adminModalUsersReducer,
    newBooksOrder: newBooksOrderReducer,
    teachers: teachersReducer,
    tests: testsReducer,
    turnoutStats: turnoutStatsReducer,
    schedule: scheduleReducer,
    series: seriesReducer,
    googleBooks: googleBooksReducers,
    availableBooks: availableBooksReducers,
    onHandsBooks: onHandsBooksReducers,
    schedulePersonalReducer: schedulePersonalReducer,
    searchByImage: searchByImageReducers,
    udk: udkListReducer,
    outsecrecy: secrecyReducer,
    bookPlaces: bookPlacesReducer,
    currentAuthorBooks: authorBooksReducer,
    studentProjects: studentProjectsReducers,
    duplicate: duplicateReducer,
    searchAudit: searchAuditReducer,
    remcomplect: remcomplectReducer,
    libraryMaterials: libraryMaterialsReducer,
    expertOpinion: expertOpinionReducers,
    pages: pagesReducers
});
