import React from 'react';
import PropTypes from 'prop-types';

import ModalInputWrapper from "../../Modal/InputWrapper";

const SDPT645 = ({options, setOptions, saveOptions}) => {

    const onChange = (e) => {
        setOptions({...options, [e.target.name]: e.target.value});
    };


    return (
        <div>
            <div className={'formContainer'}>

                <ModalInputWrapper
                    label={'Профессорско-преподавательский состав'}
                    name={'PasportPPSLink'}
                    value={options.PasportPPSLink}
                    onChange={onChange}
                />

                <ModalInputWrapper
                    label={'Расписание'}
                    name={'PasportScheduleLink'}
                    value={options.PasportScheduleLink}
                    onChange={onChange}
                />

            </div>
        </div>
    );
};

SDPT645.propTypes = {
    options: PropTypes.object,
    setOptions: PropTypes.func,
    saveOptions: PropTypes.func,
};

export default SDPT645;