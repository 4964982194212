import {
    call,
    put,
    takeLatest,
    select
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";
import {START, USERS_SAVE_REQUEST} from "../../../actions/action-types";
import {ADD, EDIT} from "../../../../constants/modalTypes";
import {addUser, changeUserPassword, editUser} from "../../../../api/adminUsers/users";
import {
    usersSaveRequestError,
    usersSaveRequestSuccess
} from "../../../actions/adminUsers/save";
import {usersCountRequestStart} from "../../../actions/adminUsers/count";
import {usersSavePhotoRequestStart} from "../../../actions/adminUsers/photo/savePhoto";
import {usersPhotoReset} from "../../../actions/adminUsers/photo/resetPhoto";

export default function* saveUserWatcher() {
    yield takeLatest(`${USERS_SAVE_REQUEST}${START}`, saveUserWorker);
}

function* saveUserWorker({payload}) {
    const {modalType: type, data, cb} = payload;
    const userImage = yield select(({adminUsers}) => adminUsers.photo);
    try {
        const response = yield call(type === ADD ? addUser : editUser, data);
        if (type === EDIT && data.password) {
            yield call(changeUserPassword(data.mid, data.password));
        }
        yield put(usersSaveRequestSuccess(response.data));
        yield put(usersPhotoReset());
        if (userImage) {
            yield put(usersSavePhotoRequestStart(userImage, response.data.mid));
        }
        yield put(usersCountRequestStart());
        cb && cb();
    } catch (error) {
        yield put(usersSaveRequestError(error));
    }
}
