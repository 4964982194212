import React from 'react';
import PropTypes from 'prop-types';
import styles from './SendBookList.module.css';
import SendBookListItem from "./Item";
import ReadingListItem from "../../Reading/List/Item";

const SendBookList = ({ data, userId }) => {
  return (
    <div className={styles.container}>
      {data.map((item, index) => <SendBookListItem  data={item} userId={userId} key={index}/>)}
    </div>
  );
};

SendBookList.propTypes = {
  data: PropTypes.array,
};

export default SendBookList;
