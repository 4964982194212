import React from 'react';
import PropTypes from 'prop-types';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";
import moment from "moment";

const TestsTable = ({data, currentPage, resultsPerPage}) => {

    return (
        <DataTable data={data}>
            <DataTableCol
                title="№ п/п"
                resolver={item => (currentPage - 1) * resultsPerPage + item.column_index}
            />
            <DataTableCol title="Курс" value="title" colSpan={1}/>
            <DataTableCol
                title="Статус"
                resolver={({status}) => status === 'DONE' ? 'Завершен' : 'В ходе выполнения'}
                colSpan={2}/>
            <DataTableCol
                title="Дата начала изучения"
                resolver={({starttime}) => starttime ? moment(starttime).format('DD.MM.YYYY') : 'Не указано'}
                colSpan={2}/>
            <DataTableCol
                title="Дата завершения изучения"
                resolver={({starttime}) => starttime ? moment(starttime).format('DD.MM.YYYY') : 'Не указано'}
                colSpan={2}/>
            <DataTableCol title="Продолжительность изучения" resolver={({period}) =>
                `${period.hours}ч. ${period.minutes}м. ${period.seconds}сек.`
            } colSpan={2}/>
            <DataTableCol title="Результат" value="rating"/>
        </DataTable>
    );
};

TestsTable.propTypes = {
    data: PropTypes.array,
    currentPage: PropTypes.number,
    resultsPerPage: PropTypes.number,
};

export default TestsTable;