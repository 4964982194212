import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "react-bootstrap";
import moment from "moment";

import {STATUS_LIST} from "../../../scenes/BookScanPlan";
import ModalInputWrapper from "../../Modal/InputWrapper";
import BookEditFilesTab from "../../BookEdit/Tabs/Files";
import Button from "../../Button";
import ModalWindow from "../../ModalWindow";
import BookCover from "../../BookEdit/Tabs/IndexTab/BookCover";
import {resolveBookCoverUrl} from "../../../utils/resolvePhotoUrl";

import styles from './Modal.module.css';


const Modal = ({data, setClosed, editStatus, setData, onUploadBookCover, cleanBookCover, options}) => {

    const {
        bookId,
        uploadedCover,
        coverImage,
        authors,
        year,
        pageNum,
        publishers,
        timestamp,
        title,
        status
    } = data;

    const onChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    };

    return (
        <ModalWindow
            title={'Редатирование плана сканирования книг'}
            onRequestClose={setClosed}
            isOpen={true}
        >
            <Row>
                <Col md={3}>
                    <BookCover
                        bookId={bookId}
                        onUpload={onUploadBookCover}
                        onClean={cleanBookCover}
                        coverUrl={
                            uploadedCover
                            ||
                            (coverImage &&
                                resolveBookCoverUrl(coverImage, bookId))
                        }
                    />

                </Col>
                <Col md={9}>
                    <h2>{title}</h2>
                    <div>
                        <div className={styles.subtitle}>
                            <strong>Авторы: </strong>
                            {authors && authors.length > 0 &&
                            authors.map(author => author.name)}
                        </div>
                        <div className={styles.subtitle}>
                            <strong>Издательства: </strong>
                            {Array.isArray(publishers) && publishers.map(publisher => publisher.name)}
                        </div>
                        <div className={styles.subtitle}>
                            <strong>Год:</strong>
                            {year}
                        </div>
                        <div className={styles.subtitle}>
                            <strong>Число страниц:</strong>
                            {pageNum}
                        </div>
                        <div className={styles.subtitle}>
                            <strong>Дата:</strong>
                            {timestamp && moment(timestamp).format("DD.MM.YYYY")}
                        </div>
                    </div>

                    <Row>
                        {Number(data.status) === 4 &&
                        <Col>
                            <ModalInputWrapper
                                label={'Причина отмены'}
                                name={'reason'}
                                value={data.reason}
                                onChange={onChange}
                            />
                        </Col>
                        }
                        <Col>
                            <ModalInputWrapper label={'Статус'}>
                                <select name="status" value={data.status} className={styles.select} onChange={onChange}>
                                    <option value="" disabled>Не задано</option>
                                    {Array.from(STATUS_LIST.entries()).map((item, index) =>
                                        <option key={index} value={item[0]}>{item[1]}</option>
                                    )}
                                </select>
                            </ModalInputWrapper>
                        </Col>
                        <Col md={'auto'}>
                            <ModalInputWrapper label={''}>
                                <Button label={'Обновить статус'} onClick={editStatus}/>
                            </ModalInputWrapper>
                        </Col>
                    </Row>
                </Col>
                <Col md={12}>
                    <div className={styles.borderTop}>
                        <BookEditFilesTab bookId={bookId}/>
                    </div>
                    <div className={"my-2 d-flex justify-content-end"}>
                        <a href={options.gotoscanbutton} target="_blank" className={styles.button}>Перейти к
                            сканированию</a>
                    </div>
                </Col>
            </Row>
        </ModalWindow>
    );
};

Modal.propTypes = {
    data: PropTypes.object,
    editStatus: PropTypes.func,
};

export default Modal;