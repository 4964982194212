import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { START, USERS_CHANGE_PASSWORD_REQUEST } from "../../../actions/action-types";
import { changeUserPassword } from "../../../../api/adminUsers/users";
import {
  changeUserPasswordRequestError,
  changeUserPasswordRequestSuccess
} from "../../../actions/adminUsers/changePassword";

export default function* changeUserPasswordWatcher() {
  yield takeLatest(`${USERS_CHANGE_PASSWORD_REQUEST}${START}`, changeUserPasswordWorker)
}

function* changeUserPasswordWorker({mid, password}) {
  try {
    const response = yield call(changeUserPassword, mid, password);
    yield put(changeUserPasswordRequestSuccess(response.data));
  } catch (error) {
    yield put(changeUserPasswordRequestError(error));
  }
}