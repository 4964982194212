export const START = "_START";
export const SUCCESS = "_SUCCESS";
export const ERROR = "_ERROR";
export const RESET = "_RESET";


export const SET_LOCATION_CHANGE = "SET_LOCATION_CHANGE";

export const GET_BOOK_CHANGE_HISTORY = "GET_BOOK_CHANGE_HISTORY";

export const EXPERT_OPINION_SAVE_REQUEST = "EXPERT_OPINION_SAVE_REQUEST";
export const EXPERT_OPINION_DEL_REQUEST = "EXPERT_OPINION_DEL_REQUEST";
export const EXPERT_OPINION_EDIT_REQUEST = "EXPERT_OPINION_EDIT_REQUEST";
export const EXPERT_OPINION_LIST_REQUEST = "EXPERT_OPINION_LIST_REQUEST";

export const GET_BOOK_NOTES_TYPES = "GET_BOOK_NOTES_TYPES";
export const GET_BOOK_NOTES = "GET_BOOK_NOTES";
export const ADD_BOOK_NOTE = "ADD_BOOK_NOTE";
export const DEL_BOOK_NOTE = "DEL_BOOK_NOTE";
export const PAGES_SAVE_REQUEST = "PAGES_SAVE_REQUEST";
export const PAGES_DEL_REQUEST = "PAGES_OPINION_DEL_REQUEST";
export const PAGES_EDIT_REQUEST = "PAGES_EDIT_REQUEST";
export const PAGES_LIST_REQUEST = "PAGES_LIST_REQUEST";

export const AUTHOR_SIGN_GENERATE = "AUTHOR_SIGN_GENERATE";

export const SET_DUPLICATE_SEARCH_DATA = "SET_DUPLICATE_SEARCH_DATA";
export const DUPLICATE_COUNT_REQUEST = "DUPLICATE_COUNT_REQUEST";
export const DUPLICATE_LIST_REQUEST = "DUPLICATE_LIST_REQUEST";
export const DUPLICATE_BOOKS_REQUEST = "DUPLICATE_BOOKS_REQUEST";
export const DUPLICATE_BOOKS_MERGE = "DUPLICATE_BOOKS_MERGE";

export const SET_REMCOMPLECT_SEARCH_DATA = "SET_REMCOMPLECT_SEARCH_DATA";
export const REMCOMPLECT_COUNT_REQUEST = "REMCOMPLECT_COUNT_REQUEST";
export const REMCOMPLECT_LIST_REQUEST = "REMCOMPLECT_LIST_REQUEST";

export const SAVE_STUDENT_PROJECT = "SAVE_STUDENT_PROJECT";
export const STUDENT_PROJECTS = "STUDENT_PROJECTS";
export const STUDENT_PROJECT = "STUDENT_PROJECT";
export const STUDENT_PROJECTS_YEARS = "STUDENT_PROJECTS_YEARS";
export const STUDENT_PROJECTS_TERMS = "STUDENT_PROJECTS_TERMS";
export const STUDENT_PROJECTS_DISCS = "STUDENT_PROJECTS_DISCS";

export const AUTHOR_BOOKS_REQUEST = "AUTHOR_BOOKS_REQUEST";

export const BOOK_PLACES_REQUEST = "BOOK_PLACES_REQUEST";

export const SET_BOOK_SCAN_PLAN_SEARCH_DATA = "SET_BOOK_SCAN_PLAN_SEARCH_DATA";
export const SET_BOOK_SCAN_PLAN_FORM_DATA = "SET_BOOK_SCAN_PLAN_FORM_DATA";
export const BOOK_SCAN_PLAN_COUNT_REQUEST = "BOOK_SCAN_PLAN_COUNT_REQUEST";
export const BOOK_SCAN_PLAN_LIST_REQUEST = "BOOK_SCAN_PLAN_LIST_REQUEST";
export const BOOK_SCAN_PLAN_EDIT_REQUEST = "BOOK_SCAN_PLAN_EDIT_REQUEST";

export const AUTH_REQUEST = "AUTH_REQUEST";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const CHECK_AUTH_REQUEST = "CHECK_AUTH_REQUEST";
export const RESET_AUTH_DATA = "RESET_AUTH_DATA";

export const PUBLISHERS_COUNT_REQUEST = "PUBLISHERS_COUNT_REQUEST";
export const PUBLISHERS_LIST_REQUEST = "PUBLISHERS_LIST_REQUEST";
export const PUBLISHERS_SAVE_REQUEST = "PUBLISHERS_SAVE_REQUEST";
export const PUBLISHERS_DELETE_REQUEST = "PUBLISHERS_DELETE_REQUEST";
export const PUBLISHERS_RESET_ERROR = "PUBLISHERS_RESET_ERROR";

export const OUTSECRECY_COUNT_REQUEST = "OUTSECRECY_COUNT_REQUEST";
export const OUTSECRECY_LIST_REQUEST = "OUTSECRECY_LIST_REQUEST";

export const SET_PUBLISHERS_SEARCH_DATA = "SET_PUBLISHERS_SEARCH_DATA";
export const SET_OUTSECRECY_SEARCH_DATA = "SET_OUTSECRECY_SEARCH_DATA";

export const UDK_LIST_REQUEST = "UDK_LIST_REQUEST";
export const UDK_LIST_COLLAPSE = "UDK_LIST_COLLAPSE";

export const BBK_LIST_REQUEST = "BBK_LIST_REQUEST";
export const BBK_LIST_COLLAPSE = "BBK_LIST_COLLAPSE";

export const AUTHORS_COUNT_REQUEST = "AUTHORS_COUNT_REQUEST";
export const AUTHORS_LIST_REQUEST = "AUTHORS_LIST_REQUEST";
export const AUTHORS_SAVE_REQUEST = "AUTHORS_SAVE_REQUEST";
export const AUTHORS_DELETE_REQUEST = "AUTHORS_DELETE_REQUEST";

export const SET_AUTHORS_SEARCH_DATA = "SET_AUTHORS_SEARCH_DATA";
export const AUTHOR_IMAGE_REQUEST = "AUTHOR_IMAGE_REQUEST";
export const AUTHOR_DELETE_IMAGE_REQUEST = "AUTHOR_DELETE_IMAGE_REQUEST";
export const AUTHOR_SAVE_IMAGE_REQUEST = "AUTHOR_SAVE_IMAGE_REQUEST";
export const CLEAR_AUTHOR_IMAGE = "CLEAR_AUTHOR_IMAGE";
export const SET_AUTHOR_IMAGE = "SET_AUTHOR_IMAGE";

export const ALPHABET_LETTERS_REQUEST = "ALPHABET_LETTERS_REQUEST";
export const ALPHABET_RANGE_REQUEST = "ALPHABET_RANGE_REQUEST";
export const ALPHABET_ITEMS_REQUEST = "ALPHABET_ITEMS_REQUEST";
export const ALPHABET_COUNT_REQUEST = "ALPHABET_COUNT_REQUEST";

export const SET_ALPHABET_SEARCH_DATA = "SET_ALPHABET_SEARCH_DATA";

export const INSTITUTIONS_COUNT_REQUEST = "INSTITUTIONS_COUNT_REQUEST";
export const INSTITUTIONS_LIST_REQUEST = "INSTITUTIONS_LIST_REQUEST";
export const INSTITUTIONS_ALL_LIST_REQUEST = "INSTITUTIONS_ALL_LIST_REQUEST";
export const INSTITUTIONS_SAVE_REQUEST = "INSTITUTIONS_SAVE_REQUEST";
export const INSTITUTIONS_DELETE_REQUEST = "INSTITUTIONS_DELETE_REQUEST";

export const SET_INSTITUTIONS_SEARCH_DATA = "SET_INSTITUTIONS_SEARCH_DATA";

export const REGIONS_COUNT_REQUEST = "REGIONS_COUNT_REQUEST";
export const REGIONS_LIST_REQUEST = "REGIONS_LIST_REQUEST";

export const SET_READER_SEARCH_DATA = "SET_READER_SEARCH_DATA";
export const READER_SEARCH_REQUEST = "READER_SEARCH_REQUEST";
export const READER_SEARCH_BY_CARD_REQUEST = "READER_SEARCH_BY_CARD_REQUEST";

export const LIBRARY_ADD_FOUND = "LIBRARY_ADD_FOUND";
export const LIBRARY_ADD_FOUND_CLEAR = "LIBRARY_ADD_FOUND_CLEAR";

export const LIBRARY_ADD_POINT = "LIBRARY_ADD_POINT";
export const LIBRARY_ADD_POINT_CLEAR = "LIBRARY_ADD_POINT_CLEAR";

export const LIBRARY_LIST_REQUEST = "LIBRARY_LIST_REQUEST";
export const LIBRARY_FUND_LIST_REQUEST = "LIBRARY_FUND_LIST_REQUEST";
export const LIBRARY_POINT_LIST_REQUEST = "LIBRARY_POINT_LIST_REQUEST";
export const LIBRARY_INSTITUTIONS_REQUEST = "LIBRARY_INSTITUTIONS_REQUEST";

export const LIBRARY_SAVE_REQUEST = "LIBRARY_SAVE_REQUEST";
export const LIBRARY_SAVE_FUND_REQUEST = "LIBRARY_SAVE_FUND_REQUEST";
export const LIBRARY_SAVE_POINT_REQUEST = "LIBRARY_SAVE_POINT_REQUEST";

export const LIBRARY_DELETE_REQUEST = "LIBRARY_DELETE_REQUEST";
export const LIBRARY_DELETE_POINT_REQUEST = "LIBRARY_DELETE_POINT_REQUEST";
export const LIBRARY_DELETE_FUND_REQUEST = "LIBRARY_DELETE_FUND_REQUEST";

export const SET_LIBRARY_SEARCH_DATA = "SET_LIBRARY_SEARCH_DATA";
export const RESET_CURRENT_LIBRARY_DATA = "RESET_CURRENT_LIBRARY_DATA";

export const LIBRARY_TYPES_LIST_REQUEST = "LIBRARY_TYPES_LIST_REQUEST";
export const LIBRARY_TYPE_SAVE_REQUEST = "LIBRARY_TYPE_SAVE_REQUEST";
export const LIBRARY_TYPE_DELETE_REQUEST = "LIBRARY_TYPE_DELETE_REQUEST";

export const RUBRICATOR_COUNT_REQUEST = "RUBRICATOR_COUNT_REQUEST";
export const RUBRICATOR_LIST_REQUEST = "RUBRICATOR_LIST_REQUEST";
export const RUBRICATOR_SAVE_REQUEST = "RUBRICATOR_SAVE_REQUEST";
export const RUBRICATOR_ITEM_REQUEST = "RUBRICATOR_ITEM_REQUEST";
export const SET_RUBRICATOR_SEARCH_DATA = "SET_RUBRICATOR_SEARCH_DATA";
export const RUBRICATOR_DELETE_REQUEST = "RUBRICATOR_DELETE_REQUEST";
export const RUBRICATOR_PARENT_SEARCH_REQUEST =
  "RUBRICATOR_PARENT_SEARCH_REQUEST";

export const COVERS_LIST_REQUEST = "COVERS_LIST_REQUEST";
export const COVERS_COUNT_REQUEST = "COVERS_COUNT_REQUEST";
export const COVERS_READING_SEARCH_REQUEST = "COVERS_READING_SEARCH_REQUEST";
export const COVERS_READING_LIST_REQUEST = "COVERS_READING_LIST_REQUEST";
export const COVERS_BATCH_EDITING_REQUEST = "COVERS_BATCH_EDITING_REQUEST";
export const SET_COVERS_FORM_DATA = "SET_COVERS_FORM_DATA";
export const RESET_COVERS_FORM_DATA = "RESET_COVERS_FORM_DATA";
export const SET_COVERS_SEARCH_DATA = "SET_COVERS_SEARCH_DATA";
export const SET_COVERS_FILE_DATA = "SET_COVERS_FILE_DATA";
export const COVERS_ADVANCED_SEARCH_PARAMS_REQUEST = "COVERS_ADVANCED_SEARCH_PARAMS_REQUEST";
export const COVERS_ADVANCED_SEARCH_LIST_REQUEST = "COVERS_ADVANCED_SEARCH_LIST_REQUEST";

export const SET_LIBRARY_MATERIALS_FORM_DATA =
  "SET_LIBRARY_MATERIALS_FORM_DATA";
export const SET_LIBRARY_MATERIALS_SEARCH_DATA =
  "SET_LIBRARY_MATERIALS_SEARCH_DATA";
export const LIBRARY_MATERIALS_COUNT_REQUEST =
  "LIBRARY_MATERIALS_COUNT_REQUEST";
export const LIBRARY_MATERIALS_LIST_REQUEST = "LIBRARY_MATERIALS_LIST_REQUEST";

export const INV_NUMBERS_COUNT_REQUEST = "INV_NUMBERS_COUNT_REQUEST";
export const INV_NUMBERS_LIST_REQUEST = "INV_NUMBERS_LIST_REQUEST";
export const SET_INV_NUMBERS_FORM_DATA = "SET_INV_NUMBERS_FORM_DATA";
export const SET_INV_NUMBERS_SEARCH_DATA = "SET_INV_NUMBERS_SEARCH_DATA";
export const INV_NUMBER_SAVE_REQUEST = "INV_NUMBER_SAVE_REQUEST";
export const INV_NUMBER_DELETE_REQUEST = "INV_NUMBER_DELETE_REQUEST";

export const BOOK_DATA_REQUEST = "BOOK_DATA_REQUEST";
export const BOOK_SCAN_SAVE = "BOOK_SCAN_SAVE";
export const BOOK_CLEAN_SCAN = "BOOK_CLEAN_SCAN";
export const BOOK_PDF_SAVE = "BOOK_PDF_SAVE";
export const BOOK_PDF_DELETE = "BOOK_PDF_DELETE";
export const SET_BOOK_DATA = "SET_BOOK_DATA";

export const BOOK_COVER_SAVE = "BOOK_COVER_SAVE";
export const BOOK_CLEAN_COVER = "BOOK_CLEAN_COVER";

export const ROLE_ADD_NEW_MEMBER = "ROLE_ADD_NEW_MEMBER";
export const ROLE_CLEAR_NEW_MEMBERS = "ROLE_CLEAR_NEW_MEMBERS";

export const ROLES_LIST_REQUEST = "ROLES_LIST_REQUEST";
export const ROLE_SAVE_REQUEST = "ROLE_SAVE_REQUEST";
export const ROLE_DELETE_REQUEST = "ROLE_DELETE_REQUEST";
export const ROLE_MEMBERS_LIST_REQUEST = "ROLES_MEMBERS_REQUEST";
export const ROLE_MEMBER_SAVE_REQUEST = "ROLE_MEMBER_SAVE_REQUEST";
export const ROLE_MEMBER_DELETE_REQUEST = "ROLE_MEMBER_DELETE_REQUEST";

export const OPTIONS_GET_REQUEST = "OPTIONS_GET_REQUEST";
export const OPTIONS_SAVE_REQUEST = "OPTIONS_SAVE_REQUEST";
export const SET_OPTIONS_DATA = "SET_OPTIONS_DATA";
export const SET_LAYOUT_OPTIONS_DATA = "SET_LAYOUT_OPTIONS_DATA";
export const LAYOUT_OPTIONS_GET_REQUEST = "LAYOUT_OPTIONS_GET_REQUEST";
export const LAYOUT_OPTIONS_SAVE_REQUEST = "LAYOUT_OPTIONS_SAVE_REQUEST";
export const HEADER_IMAGES_LIST_REQUEST = "HEADER_IMAGES_LIST_REQUEST";
export const OPTIONS_LOGO_GET_REQUEST = "OPTIONS_LOGO_GET_REQUEST";
export const OPTIONS_LOGO_SAVE_REQUEST = "OPTIONS_LOGO_SAVE_REQUEST";

export const USERS_COUNT_REQUEST = "USERS_COUNT_REQUEST";
export const USERS_LIST_REQUEST = "USERS_LIST_REQUEST";
export const SET_USERS_SEARCH_DATA = "SET_USERS_SEARCH_DATA";
export const USERS_SAVE_REQUEST = "USERS_SAVE_REQUEST";
export const USERS_DELETE_REQUEST = "USERS_DELETE_REQUEST";

export const SCHEDULE_LIST_REQUEST = "SCHEDULE_LIST_REQUEST";
export const SET_SCHEDULE_SEARCH_DATA = "SET_SCHEDULE_SEARCH_DATA";

export const USERS_GET_PHOTO_REQUEST = "USERS_GET_PHOTO_REQUEST";
export const SET_USERS_PHOTO = "SET_USERS_PHOTO";
export const USERS_SAVE_PHOTO_REQUEST = "USERS_SAVE_PHOTO_REQUEST";
export const USERS_DELETE_PHOTO_REQUEST = "USERS_DELETE_PHOTO_REQUEST";

export const USERS_CHANGE_PASSWORD_REQUEST = "USERS_CHANGE_PASSWORD_REQUEST";

export const ROOMS_LIST_REQUEST = "ROOMS_LIST_REQUEST";
export const ROOM_SAVE_REQUEST = "ROOM_SAVE_REQUEST";
export const ROOM_DELETE_REQUEST = "ROOM_DELETE_REQUEST";
export const RACKS_LIST_REQUEST = "RACKS_LIST_REQUEST";
export const RACK_SAVE_REQUEST = "RACK_SAVE_REQUEST";
export const RACK_DELETE_REQUEST = "RACK_DELETE_REQUEST";
export const SHELVES_LIST_REQUEST = "SHELVES_LIST_REQUEST";
export const SHELF_SAVE_REQUEST = "SHELF_SAVE_REQUEST";
export const SHELF_DELETE_REQUEST = "SHELF_DELETE_REQUEST";

export const COLLECTIONS_LIST_REQUEST = "COLLECTIONS_LIST_REQUEST";
export const COLLECTIONS_SAVE_REQUEST = "COLLECTIONS_SAVE_REQUEST";
export const COLLECTIONS_DELETE_REQUEST = "COLLECTIONS_DELETE_REQUEST";
export const COLLECTION_RESET_DATA = "COLLECTION_RESET_DATA";
export const COLLECTION_GET_DATA_REQUEST = "COLLECTIONS_GET_DATA_REQUEST";
export const COLLECTION_SAVE_DATA_REQUEST = "COLLECTION_SAVE_DATA_REQUEST";
export const COLLECTION_DELETE_DATA_REQUEST = "COLLECTION_DELETE_DATA_REQUEST";

export const PERMISSIONS_BY_ROLE_REQUEST = "PERMISSIONS_BY_ROLE_REQUEST";
export const PERMISSIONS_SAVE_REQUEST = "PERMISSIONS_SAVE_REQUEST";
export const PERMISSIONS_BY_CATEGORY_REQUEST = "PERMISSIONS_BY_CATEGORY_REQUEST";
export const PERMISSIONS_BY_CATEGORY_COUNT_REQUEST = "PERMISSIONS_BY_CATEGORY_COUNT_REQUEST";
export const PERMISSIONS_BY_CATEGORY_EDIT_REQUEST = "PERMISSIONS_BY_CATEGORY_EDIT_REQUEST";
export const PERMISSIONS_BY_CATEGORY_ADD_REQUEST = "PERMISSIONS_BY_CATEGORY_ADD_REQUEST";
export const ACCESS_CATEGORY_LIST_REQUEST = "ACCESS_CATEGORY_LIST_REQUEST";
export const SET_ACCESS_CATEGORY_FORM_DATA = "SET_ACCESS_CATEGORY_FORM_DATA";
export const SET_PERMISSIONS_BY_CATEGORY_SEARCH_DATA = "SET_PERMISSIONS_BY_CATEGORY_SEARCH_DATA";
export const GUK_TAB_DATA_REQUEST = "GUK_TAB_DATA_REQUEST";

export const PERMISSIONS_BY_SERIES_ADD_REQUEST = "PERMISSIONS_BY_SERIES_ADD_REQUEST";
export const PERMISSIONS_BY_SERIES_EDIT_REQUEST = "PERMISSIONS_BY_SERIES_EDIT_REQUEST";
export const PERMISSIONS_BY_SERIES_LIST_REQUEST = "PERMISSIONS_BY_SERIES_LIST_REQUEST";
export const PERMISSIONS_BY_SERIES_COUNT_REQUEST = "PERMISSIONS_BY_SERIES_COUNT_REQUEST";
export const SET_PERMISSIONS_BY_SERIES_FORM_DATA = "SET_PERMISSIONS_BY_SERIES_FORM_DATA";
export const SET_PERMISSIONS_BY_SERIES_SEARCH_DATA = "SET_PERMISSIONS_BY_SERIES_SEARCH_DATA";

export const PERMISSIONS_BY_LITER_ADD_REQUEST = "PERMISSIONS_BY_LITER_ADD_REQUEST";
export const PERMISSIONS_BY_LITER_EDIT_REQUEST = "PERMISSIONS_BY_LITER_EDIT_REQUEST";
export const PERMISSIONS_BY_LITER_LIST_REQUEST = "PERMISSIONS_BY_LITER_LIST_REQUEST";
export const PERMISSIONS_BY_LITER_COUNT_REQUEST = "PERMISSIONS_BY_LITER_COUNT_REQUEST";
export const SET_PERMISSIONS_BY_LITER_FORM_DATA = "SET_PERMISSIONS_BY_LITER_FORM_DATA";
export const SET_PERMISSIONS_BY_LITER_SEARCH_DATA = "SET_PERMISSIONS_BY_LITER_SEARCH_DATA";

export const EXTERNAL_RESOURCES_REQUEST = "EXTERNAL_RESOURCES_REQUEST";
export const EXTERNAL_RESOURCES_FULL_REQUEST = "EXTERNAL_RESOURCES_FULL_REQUEST";
export const EXTERNAL_RESOURCES_SAVE_REQUEST = "EXTERNAL_RESOURCES_SAVE_REQUEST";
export const EXTERNAL_RESOURCES_DELETE_REQUEST = "EXTERNAL_RESOURCES_DELETE_REQUEST";
export const SET_EXTERNAL_RESOURCES_SAVE_STATUS = "SET_EXTERNAL_RESOURCES_SAVE_STATUS";

export const PDF_VIEWER_BOOK_REQUEST = "PDF_VIEWER_BOOK_REQUEST";

export const PDF_VIEWER_BOOKMARK_REQUEST = "PDF_VIEWER_BOOKMARK_REQUEST";
export const PDF_VIEWER_BOOKMARK_SAVE = "PDF_VIEWER_BOOKMARK_SAVE";
export const PDF_VIEWER_BOOKMARKS_SET = 'PDF_VIEWER_BOOKMARKS_SET';
export const SET_PDF_VIEWER_FILE_DATA = 'SET_PDF_VIEWER_FILE_DATA';

export const EPUB_READER_BOOK_REQUEST = 'EPUB_READER_BOOK_REQUEST';

export const SECURITY_LOG_LIST_REQUEST = "SECURITY_LOG_LIST_REQUEST";
export const SECURITY_LOG_COUNT_REQUEST = "SECURITY_LOG_COUNT_REQUEST";
export const SET_SECURITY_LOG_SEARCH_DATA = "SET_SECURITY_LOG_SEARCH_DATA";
export const SET_SECURITY_LOG_FORM_DATA = "SET_SECURITY_LOG_FORM_DATA";

export const TURNOUT_STATS_LIST_REQUEST = "TURNOUT_STATS_LIST_REQUEST";
export const TURNOUT_STATS_COUNT_REQUEST = "TURNOUT_STATS_COUNT_REQUEST";
export const SET_TURNOUT_STATS_SEARCH_DATA = "SET_TURNOUT_STATS_SEARCH_DATA";
export const SET_TURNOUT_STATS_FORM_DATA = "SET_TURNOUT_STATS_FORM_DATA";

export const RESERVE_BOOK_REQUEST = "RESERVE_BOOK_REQUEST";
export const UNRESERVE_BOOK_REQUEST = "UNRESERVE_BOOK_REQUEST";
export const RESERVED_BOOKS_LIST_REQUEST = "RESERVED_BOOKS_LIST_REQUEST";
export const EXPORT_BOOKS_REQUEST = "EXPORT_BOOKS_REQUEST";
export const BOOK_EXPORTS_LOAD = "BOOK_EXPORTS_LOAD";
export const BOOK_EXPORTS_DELETE = "BOOK_EXPORTS_DELETE";

export const AUDIT_LOG_LIST_REQUEST = "AUDIT_LOG_LIST_REQUEST";
export const AUDIT_LOG_COUNT_REQUEST = "AUDIT_LOG_COUNT_REQUEST";
export const AUDIT_DATASETS_REQUEST = "AUDIT_DATASETS_REQUEST";
export const SET_AUDIT_LOG_SEARCH_DATA = "SET_AUDIT_LOG_SEARCH_DATA";
export const SET_AUDIT_LOG_FORM_DATA = "SET_AUDIT_LOG_FORM_DATA";

export const SEARCH_AUDIT_LIST_REQUEST = "SEARCH_AUDIT_LIST_REQUEST";
export const SEARCH_AUDIT_ITEM_REQUEST = "SEARCH_AUDIT_ITEM_REQUEST";
export const SEARCH_AUDIT_COUNT_REQUEST = "SEARCH_AUDIT_COUNT_REQUEST";
export const SET_SEARCH_AUDIT_SEARCH_DATA = "SET_SEARCH_AUDIT_SEARCH_DATA";
export const SET_SEARCH_AUDIT_FORM_DATA = "SET_SEARCH_AUDIT_FORM_DATA";

export const READER_DATA_REQUEST = "READER_DATA_REQUEST";
export const QR_CODES_REQUEST = "QR_CODES_REQUEST";
export const QR_CODES_SHELVES_REQUEST = "QR_CODES_SHELVES_REQUEST";

export const REPORTS_LIST_REQUEST = "REPORTS_LIST_REQUEST";
export const REPORT_GET_REQUEST = "REPORT_GET_REQUEST";

export const IMPORT_BOOKS_REQUEST = "IMPORT_BOOKS_REQUEST";
export const SET_IMPORT_BOOKS_FORM_DATA = "SET_IMPORT_BOOKS_FORM_DATA";

export const HAVKINA_LIST_REQUEST = "HAVKINA_LIST_REQUEST";
export const HAVKINA_COUNT_REQUEST = "HAVKINA_COUNT_REQUEST";
export const HAVKINA_CALC_INDEX_REQUEST = "HAVKINA_CALC_INDEX_REQUEST";
export const SET_HAVKINA_SEARCH_DATA = "SET_HAVKINA_SEARCH_DATA";

export const DOCUMENT_TYPES_COUNT_REQUEST = "DOCUMENT_TYPES_COUNT_REQUEST";
export const DOCUMENT_TYPES_LIST_REQUEST = "DOCUMENT_TYPES_LIST_REQUEST";
export const DOCUMENT_TYPES_ALL_REQUEST = "DOCUMENT_TYPES_ALL_REQUEST";
export const SET_DOCUMENT_TYPES_SEARCH_DATA = "SET_DOCUMENT_TYPES_SEARCH_DATA";
export const DOCUMENT_TYPES_SAVE_REQUEST = "DOCUMENT_TYPES_SAVE_REQUEST";
export const DOCUMENT_TYPES_DELETE_REQUEST = "DOCUMENT_TYPES_DELETE_REQUEST";

export const DOCUMENT_COUNT_REQUEST = "DOCUMENT_COUNT_REQUEST";
export const DOCUMENT_LIST_REQUEST = "DOCUMENT_LIST_REQUEST";
export const SET_DOCUMENT_SEARCH_DATA = "SET_DOCUMENT_SEARCH_DATA";
export const SET_LATEST_DOCUMENT = "SET_LATEST_DOCUMENT";
export const DOCUMENT_NUMBER_REQUEST = "DOCUMENT_NUMBER_REQUEST";
export const DOCUMENT_SAVE_REQUEST = "DOCUMENT_SAVE_REQUEST";
export const DOCUMENT_DELETE_REQUEST = "DOCUMENT_DELETE_REQUEST";

export const DOCUMENT_BOOK_LIST_REQUEST = "DOCUMENT_BOOK_LIST_REQUEST";
export const DOCUMENT_BOOK_SAVE_REQUEST = "DOCUMENT_BOOK_SAVE_REQUEST";
export const DOCUMENT_BOOK_DELETE_REQUEST = "DOCUMENT_BOOK_DELETE_REQUEST";
export const DOCUMENT_BOOK_CLEANUP = "DOCUMENT_BOOK_CLEANUP";
export const DOCUMENT_BOOK_ORDER_COUNT_REQUEST =
  "DOCUMENT_BOOK_ORDER_COUNT_REQUEST";
export const SET_BOOK_ORDER_SEARCH_DATA = "SET_BOOK_ORDER_SEARCH_DATA";
export const DOCUMENT_BOOK_ORDER_DELETE_REQUEST =
  "DOCUMENT_BOOK_ORDER_DELETE_REQUEST";
export const DOCUMENT_BOOK_ORDER_SAVE_REQUEST =
  "DOCUMENT_BOOK_ORDER_SAVE_REQUEST";
export const SET_LATEST_BOOK_ORDER = "SET_LATEST_BOOK_ORDER";
export const DOCUMENT_BOOK_DISTRIBUTE_SAVE_REQUEST =
  "DOCUMENT_BOOK_DISTRIBUTE_SAVE_REQUEST";
export const DOCUMENT_BOOK_DISTRIBUTE_GET_LIST_REQUEST =
  "DOCUMENT_BOOK_DISTRIBUTE_GET_LIST_REQUEST";

export const DOCUMENT_INSTANCE_LIST_REQUEST = "DOCUMENT_INSTANCE_LIST_REQUEST";
export const DOCUMENT_INSTANCE_DELETE_REQUEST =
  "DOCUMENT_INSTANCE_DELETE_REQUEST";
export const DOCUMENT_INSTANCE_ADD_REQUEST = "DOCUMENT_INSTANCE_ADD_REQUEST";

export const DOCUMENT_BOOK_ORDER_REQUEST = "DOCUMENT_BOOK_ORDER_REQUEST";
export const BOOK_ORDER_LIST_REQUEST = "BOOK_ORDER_LIST_REQUEST";
export const BOOK_ORDER_BOOKS_DELETE_REQUEST =
  "BOOK_ORDER_BOOKS_DELETE_REQUEST";
export const BOOK_ORDER_BOOKS_EDIT_REQUEST = "BOOK_ORDER_BOOKS_EDIT_REQUEST";

export const NEW_BOOKS_ORDER_LIST_REQUEST = "NEW_BOOKS_ORDER_LIST_REQUEST";
export const NEW_BOOKS_ORDER_COUNT_REQUEST = "NEW_BOOKS_ORDER_COUNT_REQUEST";
export const NEW_BOOKS_ORDER_REQUEST = "NEW_BOOKS_ORDER_REQUEST";
export const NEW_BOOKS_ORDER_DELETE_REQUEST = "NEW_BOOKS_ORDER_DELETE_REQUEST";
export const NEW_BOOKS_ORDER_SAVE_REQUEST = "NEW_BOOKS_ORDER_SAVE_REQUEST";
export const NEW_BOOKS_ORDER_BOOKS_EDIT_REQUEST =
  "NEW_BOOKS_ORDER_BOOKS_EDIT_REQUEST";
export const NEW_BOOKS_ORDER_BOOKS_DELETE_REQUEST =
  "NEW_BOOKS_ORDER_BOOKS_DELETE_REQUEST";
export const SET_NEW_BOOKS_ORDER_SEARCH_DATA =
  "SET_NEW_BOOKS_ORDER_SEARCH_DATA";
export const SET_LATEST_NEW_BOOK_ORDER = "SET_LATEST_NEW_BOOK_ORDER";

export const SYSTEM_VERSION_REQUEST = "SYSTEM_VERSION_REQUEST";
export const BOOK_SAVE_REQUEST = "BOOK_SAVE_REQUEST";
export const BOOK_COMPRESS_REQUEST = "BOOK_COMPRESS_REQUEST";
export const BOOK_DECOMPRESS_REQUEST = "BOOK_DECOMPRESS_REQUEST";

export const COVER_UPLOAD_REQUEST = "COVER_UPLOAD_REQUEST";
export const SCAN_UPLOAD_REQUEST = "SCAN_UPLOAD_REQUEST";

export const ADMIN_MODAL_USERS_COUNT_REQUEST =
  "ADMIN_MODAL_USERS_COUNT_REQUEST";
export const ADMIN_MODAL_USERS_REQUEST = "ADMIN_MODAL_USERS_REQUEST";
export const SET_ADMIN_MODAL_SEARCH_DATA = "SET_ADMIN_MODAL_SEARCH_DATA";
export const RESET_ADMIN_MODAL_USERS = "RESET_ADMIN_MODAL_USERS";

export const TEACHERS_LIST_REQUEST = "TEACHERS_LIST_REQUEST";
export const CURRICULUM_LIST_REQUEST = "CURRICULUM_LIST_REQUEST";

export const INVENTARY_BOOK_LOAD_REQUEST = "INVENTARY_BOOK_LOAD_REQUEST";

export const FUND_MOVING_LOAD_REQUEST = "FUND_MOVING_LOAD_REQUEST";
export const SET_FUND_MOVING_SEARCH_DATA = "SET_FUND_MOVING_SEARCH_DATA";

export const FUND_ACCOUNTING_REQUEST = "FUND_ACCOUNTING_REQUEST";
export const SET_FUND_ACCOUNTING_SEARCH_DATA = "SET_FUND_ACCOUNTING_SEARCH_DATA";

export const INSTANCE_NUMBER_CHECK_REQUEST = "INSTANCE_NUMBER_CHECK_REQUEST";

export const INV_NUMBER_CREATE_REQUEST = "INV_NUMBER_CREATE_REQUEST";

export const BOOK_FILE_REQUEST = "BOOK_FILE_REQUEST";

export const LIBRARIES_LIST_REQUEST = "LIBRARIES_LIST_REQUEST";

export const LIBRARY_FUNDS_REQUEST = "LIBRARY_FUNDS_REQUEST";

export const SET_BOOKINVENTORY_SEARCH_DATA = "SET_BOOKINVENTORY_SEARCH_DATA";
export const BOOKINVENTORY_COUNT_REQUEST = "BOOKINVENTORY_COUNT_REQUEST";

export const BIG_DATA_REQUEST = "BIG_DATA_REQUEST";

export const BOOK_FILES_GET_REQUEST = "BOOK_FILES_GET_REQUEST";
export const BOOK_FILES_UPLOAD_REQUEST = "BOOK_FILES_UPLOAD_REQUEST";
export const BOOK_FILES_EDIT_REQUEST = "BOOK_FILES_EDIT_REQUEST";
export const BOOK_FILES_DELETE_REQUEST = "BOOK_FILES_DELETE_REQUEST";
export const SET_BOOK_FILES_SORT_DATA = "SET_BOOK_FILES_SORT_DATA";

export const CATEGORIES_LIST_REQUEST = "CATEGORIES_LIST_REQUEST";
export const SET_CATEGORIES_SEARCH_DATA = "SET_CATEGORIES_SEARCH_DATA";

export const LITERS_ADD_REQUEST = "LITERS_ADD_REQUEST";
export const LITERS_DELETE_REQUEST = "LITERS_DELETE_REQUEST";
export const LITERS_COUNT_REQUEST = "LITERS_COUNT_REQUEST";
export const LITERS_EDIT_REQUEST = "LITERS_EDIT_REQUEST";
export const SET_LITERS_FORM_DATA = "SET_LITERS_FORM_DATA";
export const LITERS_LIST_REQUEST = "LITERS_LIST_REQUEST";
export const SET_LITERS_SEARCH_DATA = "SET_LITERS_SEARCH_DATA";

export const SERIES_ADD_REQUEST = "SERIES_ADD_REQUEST";
export const SERIES_DELETE_REQUEST = "SERIES_DELETE_REQUEST";
export const SERIES_COUNT_REQUEST = "SERIES_COUNT_REQUEST";
export const SERIES_EDIT_REQUEST = "SERIES_EDIT_REQUEST";
export const SET_SERIES_FORM_DATA = "SET_SERIES_FORM_DATA";
export const SERIES_LIST_REQUEST = "SERIES_LIST_REQUEST";
export const SET_SERIES_SEARCH_DATA = "SET_SERIES_SEARCH_DATA";

export const BOOK_SCAN_ORDER_ADD_REQUEST = "BOOK_SCAN_ORDER_ADD_REQUEST";
export const BOOK_SCAN_ORDER_COUNT_REQUEST = "BOOK_SCAN_ORDER_COUNT_REQUEST";
export const BOOK_SCAN_ORDER_LIST_REQUEST = "BOOK_SCAN_ORDER_LIST_REQUEST";
export const BOOK_SCAN_ORDER_DELETE_REQUEST = "BOOK_SCAN_ORDER_DELETE_REQUEST";
export const SET_BOOK_SCAN_ORDER_FORM_DATA = "SET_BOOK_SCAN_ORDER_FORM_DATA";
export const SET_BOOK_SCAN_ORDER_SEARCH_DATA = "SET_BOOK_SCAN_ORDER_SEARCH_DATA";

export const MEDIA_RESOURCE_GET_FULL_REQUEST = "MEDIA_RESOURCE_GET_FULL_REQUEST";
export const MEDIA_RESOURCE_GET_FOR_READER_REQUEST = "MEDIA_RESOURCE_GET_FOR_READER_REQUEST";
export const MEDIA_RESOURCE_EDIT_REQUEST = "MEDIA_RESOURCE_EDIT_REQUEST";
export const MEDIA_RESOURCE_ADD_REQUEST = "MEDIA_RESOURCE_ADD_REQUEST";
export const MEDIA_RESOURCE_ADD_LOGO_REQUEST = "MEDIA_RESOURCE_ADD_LOGO_REQUEST";
export const MEDIA_RESOURCE_DELETE_REQUEST = "MEDIA_RESOURCE_DELETE_REQUEST";
export const MEDIA_RESOURCE_DELETE_LOGO_REQUEST = "MEDIA_RESOURCE_DELETE_LOGO_REQUEST";

export const BOOK_SUPPLY_LIST_REQUEST = "BOOK_SUPPLY_LIST_REQUEST";

export const MESSAGE_LIST_REQUEST = "MESSAGE_LIST_REQUEST";
export const MESSAGE_COUNT_REQUEST = "MESSAGE_COUNT_REQUEST";
export const MESSAGE_DELETE_REQUEST = "MESSAGE_DELETE_REQUEST";
export const MESSAGE_EDIT_REQUEST = "MESSAGE_EDIT_REQUEST";
export const MESSAGE_NOTIFY_REQUEST = "MESSAGE_NOTIFY_REQUEST";
export const SET_MESSAGE_FORM_DATA = "SET_MESSAGE_FORM_DATA";
export const SET_MESSAGE_SEARCH_DATA = "SET_MESSAGE_SEARCH_DATA";

export const BORROWING_TEST_COUNT_REQUEST = "BORROWING_TEST_COUNT_REQUEST";
export const BORROWING_TEST_LIST_REQUEST = "BORROWING_TEST_LIST_REQUEST";
export const BORROWING_TEST_DELETE_REQUEST = "BORROWING_TEST_DELETE_REQUEST";
export const BORROWING_TEST_ADD_REQUEST = "BORROWING_TEST_ADD_REQUEST";
export const BORROWING_TEST_EDIT_REQUEST = "BORROWING_TEST_EDIT_REQUEST";
export const SET_BORROWING_TEST_SEARCH_DATA = "SET_BORROWING_TEST_SEARCH_DATA";
export const SET_BORROWING_TEST_FORM_DATA = "SET_BORROWING_TEST_FORM_DATA";

export const BOOK_EDIT_INV_NUMBERS_COUNT_REQUEST = "BOOK_EDIT_INV_NUMBERS_COUNT_REQUEST";
export const BOOK_EDIT_INV_NUMBERS_LIST_REQUEST = "BOOK_EDIT_INV_NUMBERS_LIST_REQUEST";
export const BOOK_EDIT_INV_NUMBERS_SAVE_REQUEST = "BOOK_EDIT_INV_NUMBERS_SAVE_REQUEST";
export const BOOK_EDIT_INV_NUMBERS_DELETE_REQUEST = "BOOK_EDIT_INV_NUMBERS_DELETE_REQUEST";

export const BOOK_CARD_DATA_REQUEST = "BOOK_CARD_DATA_REQUEST";

export const BOOK_TRANSFER_COUNT_REQUEST = "BOOK_TRANSFER_COUNT_REQUEST";
export const BOOK_TRANSFER_LIST_REQUEST = "BOOK_TRANSFER_LIST_REQUEST";
export const BOOK_TRANSFER_DELETE_REQUEST = "BOOK_TRANSFER_DELETE_REQUEST";
export const BOOK_TRANSFER_ADD_REQUEST = "BOOK_TRANSFER_ADD_REQUEST";
export const SET_BOOK_TRANSFER_FORM_DATA = "SET_BOOK_TRANSFER_FORM_DATA";
export const SET_BOOK_TRANSFER_SEARCH_DATA = "SET_BOOK_TRANSFER_SEARCH_DATA";

export const SCHEDULER_LIST_REQUEST = "SCHEDULER_LIST_REQUEST";
export const SCHEDULER_COUNT_REQUEST = "SCHEDULER_COUNT_REQUEST";
export const SCHEDULER_DELETE_REQUEST = "SCHEDULER_DELETE_REQUEST";
export const SCHEDULER_EDIT_REQUEST = "SCHEDULER_EDIT_REQUEST";
export const SCHEDULER_ADD_REQUEST = "SCHEDULER_ADD_REQUEST";
export const SET_SCHEDULER_FORM_DATA = "SET_SCHEDULER_FORM_DATA";
export const SET_SCHEDULER_SEARCH_DATA = "SET_SCHEDULER_SEARCH_DATA";

export const SCHEDULER_CATEGORY_COUNT_REQUEST = "SCHEDULER_CATEGORY_COUNT_REQUEST";
export const SCHEDULER_CATEGORY_LIST_REQUEST = "SCHEDULER_CATEGORY_LIST_REQUEST";
export const SCHEDULER_CATEGORY_SAVE_REQUEST = "SCHEDULER_CATEGORY_SAVE_REQUEST";
export const SET_SCHEDULER_CATEGORY_SEARCH_DATA = "SET_SCHEDULER_CATEGORY_SEARCH_DATA";

export const SCHEDULER_METADATA_LIST_REQUEST = "SCHEDULER_METADATA_LIST_REQUEST";
export const SCHEDULER_METADATA_COUNT_REQUEST = "SCHEDULER_METADATA_COUNT_REQUEST";

export const BOOK_RESERVE_COUNT_REQUEST = "BOOK_RESERVE_COUNT_REQUEST";
export const BOOK_RESERVE_LIST_REQUEST = "BOOK_RESERVE_LIST_REQUEST";
export const SET_BOOK_RESERVE_SEARCH_DATA = "SET_BOOK_RESERVE_SEARCH_DATA";

export const CREDITS_LIST_REQUEST = "CREDITS_LIST_REQUEST";

export const ELECTRONIC_COURSES_COUNT_REQUEST = "ELECTRONIC_COURSES_COUNT_REQUEST";
export const ELECTRONIC_COURSES_LIST_REQUEST = "ELECTRONIC_COURSES_LIST_REQUEST";
export const ELECTRONIC_COURSES_ITEM_SAVE = "ELECTRONIC_COURSES_ITEM_SAVE";
export const ELECTRONIC_COURSES_ITEM_DELETE = "ELECTRONIC_COURSES_ITEM_DELETE";
export const ELECTRONIC_COURSES_ITEM_FILE_ADD = "ELECTRONIC_COURSES_ITEM_FILE_ADD";
export const SET_ELECTRONIC_COURSES_SEARCH_DATA = "SET_ELECTRONIC_COURSES_SEARCH_DATA";

export const TESTS_COUNT_REQUEST = "TESTS_COUNT_REQUEST";
export const TESTS_LIST_REQUEST = "TESTS_LIST_REQUEST";
export const SET_TESTS_SEARCH = "SET_TESTS_SEARCH";

export const ELECTRONIC_BOOK_JSON_SAVE = "ELECTRONIC_BOOK_JSON_SAVE";
export const ELECTRONIC_BOOK_ZIP_SAVE = "ELECTRONIC_BOOK_ZIP_SAVE";
export const ELECTRONIC_BOOK_RESET = "ELECTRONIC_BOOK_RESET";

export const BOOK_SOURCE_REQUEST = "BOOK_SOURCE_REQUEST";

export const INVENTORY_COUNT_REQUEST = "INVENTORY_COUNT_REQUEST";
export const INVENTORY_LIST_REQUEST = "INVENTORY_LIST_REQUEST";
export const INVENTORY_SAVE_REQUEST = "INVENTORY_SAVE_REQUEST";
export const INVENTORY_DELETE_REQUEST = "INVENTORY_DELETE_REQUEST";
export const SET_LATEST_INVENTORY = "SET_LATEST_INVENTORY";
export const SET_INVENTORY_SEARCH_DATA = "SET_INVENTORY_SEARCH_DATA";
export const INVENTORY_BOOK_LIST_REQUEST = "INVENTORY_BOOK_LIST_REQUEST";
export const INVENTORY_BOOK_CLEANUP = "INVENTORY_BOOK_CLEANUP";

export const INVENTORY_BOOK_SAVE_REQUEST = "INVENTORY_BOOK_SAVE_REQUEST";
export const INVENTORY_BOOK_DELETE_REQUEST = "INVENTORY_BOOK_DELETE_REQUEST";

export const SET_INVENTORY_COVERS_SEARCH_DATA = "SET_INVENTORY_COVERS_SEARCH_DATA";
export const INVENTORY_COVERS_COUNT_REQUEST = "INVENTORY_COVERS_COUNT_REQUEST";
export const INVENTORY_COVERS_LIST_REQUEST = "INVENTORY_COVERS_LIST_REQUEST";
export const SET_INVENTORY_COVERS_FORM_DATA = "SET_INVENTORY_COVERS_FORM_DATA";
export const RESET_INVENTORY_COVERS_FORM_DATA = "RESET_INVENTORY_COVERS_FORM_DATA";