import { INVENTORY_COVERS_LIST_REQUEST, ERROR, START, SUCCESS } from "../action-types";

export const inventoryCoversListRequestStart = (data) => ({
  type: `${INVENTORY_COVERS_LIST_REQUEST}${START}`,
  payload: data
});

export const inventoryCoversListRequestSuccess = (data) => ({
  type: `${INVENTORY_COVERS_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const inventoryCoversListRequestError = (data) => ({
  type: `${INVENTORY_COVERS_LIST_REQUEST}${ERROR}`,
  payload: data
});

