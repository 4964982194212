import { REPORT_GET_REQUEST, SUCCESS } from "../../../actions/action-types";

const initialState = {};

export const reportReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${REPORT_GET_REQUEST}${SUCCESS}`:
      return {
        ...state,
        [action.payload.id]: action.payload.data
      };

    default: return state;
  }
};