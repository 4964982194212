import { ERROR, RUBRICATOR_DELETE_REQUEST, START, SUCCESS } from "../action-types";

export const rubricatorDeleteRequestStart = (data) => ({
  type: `${RUBRICATOR_DELETE_REQUEST}${START}`,
  payload: data
});

export const rubricatorDeleteRequestSuccess = (data) => ({
  type: `${RUBRICATOR_DELETE_REQUEST}${SUCCESS}`,
  payload: data
});

export const rubricatorDeleteRequestError = (data) => ({
  type: `${RUBRICATOR_DELETE_REQUEST}${ERROR}`,
  payload: data
});
