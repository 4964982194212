import {
  call,
  put,
  takeLatest,
  select
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";
import {
  MESSAGE_LIST_REQUEST,
  MESSAGE_COUNT_REQUEST,
  MESSAGE_EDIT_REQUEST,
  START, MESSAGE_DELETE_REQUEST, MESSAGE_NOTIFY_REQUEST
} from "../../actions/action-types";
import {
  getMessageCount,
  getMessageList,
  editMessage,
  deleteMessage,
  getMessageNotificationsCount
} from "../../../api/message/message";
import {messageCountRequestError, messageCountRequestSuccess} from "../../actions/message/count";
import {messageListRequestError, messageListRequestStart, messageListRequestSuccess} from "../../actions/message/list";
import {setMessageSearchData} from "../../actions/message/search";
import {editMessageRequestError, editMessageRequestSuccess} from "../../actions/message/edit";
import {deleteMessageRequestError, deleteMessageRequestSuccess} from "../../actions/message/delete";
import {
  messageNotificationRequestError,
  messageNotificationRequestStart,
  messageNotificationRequestSuccess
} from "../../actions/message/notifications";


export default function* messageWatcher () {
  yield takeLatest(`${MESSAGE_COUNT_REQUEST}${START}`, countWorker);
  yield takeLatest(`${MESSAGE_LIST_REQUEST}${START}`, listWorker);
  yield takeLatest(`${MESSAGE_EDIT_REQUEST}${START}`, editWorker);
  yield takeLatest(`${MESSAGE_DELETE_REQUEST}${START}`, deleteWorker);
  yield takeLatest(`${MESSAGE_NOTIFY_REQUEST}${START}`, notificationsWorker);
}

function* countWorker() {
  const formData = yield select(({ message }) => message.form);
  const searchData = yield select(({ message }) => message.search);

  try {
    const response = yield call(getMessageCount, formData);
    const count = parseInt(response.data.count, 10);
    yield put(messageCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setMessageSearchData({...searchData, pageCount}));
    if(count > 0) {
      yield put(messageListRequestStart());
    }
  } catch (error) {
    yield put(messageCountRequestError(error));
  }
}

function* listWorker() {
  const formData = yield select(({ message }) => message.form);
  const searchData = yield select(({ message }) => message.search);
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
  try {
    const response = yield call(getMessageList, formData, searchData);
    yield put(messageListRequestSuccess(response.data));
  } catch (error) {
    yield put(messageListRequestError(error));
  }
}

function* editWorker({ payload: id }) {
    try {
        yield call(editMessage, id);
        yield put(editMessageRequestSuccess());
        yield put(messageListRequestStart());
        yield put(messageNotificationRequestStart());
    } catch(error) {
        yield put(editMessageRequestError(error));
    }
}

function* deleteWorker({ payload: id }) {
    try {
        yield call(deleteMessage, id);
        yield put(deleteMessageRequestSuccess());
        yield put(messageListRequestStart());
    } catch(error) {
        yield put(deleteMessageRequestError(error));
    }
}

function* notificationsWorker() {
    try {
        const response = yield call(getMessageNotificationsCount);
        yield put(messageNotificationRequestSuccess(response.data.count));
    } catch(error) {
        yield put(messageNotificationRequestError(error));
    }
}