import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from "./OptionsLogoPopover.module.css";
import { Popover } from "react-bootstrap";

const OptionsLogoPopover = React.forwardRef(({ uploadImage, deleteImage, ...props }, ref) => {
  return (
    <Popover id="logoPopover" {...props} show={props.show.toString()} ref={ref}
             className={classNames(styles.popover, props.className)}>
      <span className={styles.button} onClick={uploadImage}>
        загрузить
      </span>
    </Popover>
  );
});

OptionsLogoPopover.propTypes = {
  uploadImage: PropTypes.func,
  deleteImage: PropTypes.func
};

export default OptionsLogoPopover;
