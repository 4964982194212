import {
  call,
  put,
  takeLatest,
  select
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";
import {
  getMediaResourcesFull,
  getMediaResources,
  addMediaResource,
  addMediaResourceLogo,
  deleteMediaResource,
  deleteMediaResourceLogo,
  editMediaResource
} from '../../../api/mediaResource/mediaResource';
import {
  MEDIA_RESOURCE_GET_FULL_REQUEST,
  MEDIA_RESOURCE_GET_FOR_READER_REQUEST,
  MEDIA_RESOURCE_EDIT_REQUEST,
  MEDIA_RESOURCE_DELETE_REQUEST,
  MEDIA_RESOURCE_DELETE_LOGO_REQUEST,
  MEDIA_RESOURCE_ADD_REQUEST,
  START, MEDIA_RESOURCE_ADD_LOGO_REQUEST
} from '../../actions/action-types';
import {
  mediaResourceFullRequestError,
  mediaResourceFullRequestStart,
  mediaResourceFullRequestSuccess
} from "../../actions/mediaResource/getFull";
import {
  mediaResourceReaderRequestError,
  mediaResourceReaderRequestSuccess
} from "../../actions/mediaResource/getForReader";
import {mediaResourceEditRequestError, mediaResourceEditRequestSuccess} from "../../actions/mediaResource/edit";
import {mediaResourceDeleteRequestError, mediaResourceDeleteRequestSuccess} from "../../actions/mediaResource/delete";
import {
  mediaResourceDeleteLogoRequestError,
  mediaResourceDeleteLogoRequestSuccess
} from "../../actions/mediaResource/deleteLogo";
import {mediaResourceAddRequestError, mediaResourceAddRequestSuccess} from "../../actions/mediaResource/add";
import {
  mediaResourceAddLogoRequestError,
  mediaResourceAddLogoRequestSuccess
} from "../../actions/mediaResource/addLogo";


export default function* mediaResourceWatcher () {
  yield takeLatest(`${MEDIA_RESOURCE_GET_FULL_REQUEST}${START}`, getFullWorker);
  yield takeLatest(`${MEDIA_RESOURCE_GET_FOR_READER_REQUEST}${START}`, getForReaderWorker);
  yield takeLatest(`${MEDIA_RESOURCE_EDIT_REQUEST}${START}`, editWorker);
  yield takeLatest(`${MEDIA_RESOURCE_DELETE_REQUEST}${START}`, deleteWorker);
  yield takeLatest(`${MEDIA_RESOURCE_DELETE_LOGO_REQUEST}${START}`, deleteLogoWorker);
  yield takeLatest(`${MEDIA_RESOURCE_ADD_REQUEST}${START}`, addWorker);
  yield takeLatest(`${MEDIA_RESOURCE_ADD_LOGO_REQUEST}${START}`, addLogo);
}

function* getFullWorker() {
    try {
        const response = yield call(getMediaResourcesFull);
        yield put(mediaResourceFullRequestSuccess(response.data));
    } catch(error) {
        yield put(mediaResourceFullRequestError(error));
    }
}

function* getForReaderWorker() {
    try {
        const response = yield call(getMediaResources);
        yield put(mediaResourceReaderRequestSuccess(response.data));
    } catch(error) {
        yield put(mediaResourceReaderRequestError(error));
    }
}

function* editWorker({ payload: {resourceId, data }}) {
    try {
        const response = yield call(editMediaResource, resourceId, data);
        yield put(mediaResourceEditRequestSuccess(response.data));
        yield put(mediaResourceFullRequestStart());
    } catch(error) {
        yield put(mediaResourceEditRequestError(error));
    }
}

function* deleteWorker({ payload: resourceId }) {
    try {
        const response = yield call(deleteMediaResource, resourceId);
        yield put(mediaResourceDeleteRequestSuccess(response.data));
        yield put(mediaResourceFullRequestStart());
    } catch(error) {
        yield put(mediaResourceDeleteRequestError(error));
    }
}

function* deleteLogoWorker({ payload: resourceId }) {
    try {
        const response = yield call(deleteMediaResourceLogo, resourceId);
        yield put(mediaResourceDeleteLogoRequestSuccess(response.data));
        yield put(mediaResourceFullRequestStart());
    } catch(error) {
        yield put(mediaResourceDeleteLogoRequestError(error));
    }
}

function* addWorker({ payload: { resourcename, resourceurl } }) {
    try {
        const response = yield call(addMediaResource, { resourcename, resourceurl });
        yield put(mediaResourceAddRequestSuccess(response));
        yield put(mediaResourceFullRequestStart());
    } catch(error) {
        yield put(mediaResourceAddRequestError(error));
    }
}

function* addLogo({ payload: { resourceId, logo } }) {
    try {
        const response = yield call(addMediaResourceLogo, resourceId, logo);
        yield put(mediaResourceAddLogoRequestSuccess(response));
        yield put(mediaResourceFullRequestStart());
    } catch(error) {
        yield put(mediaResourceAddLogoRequestError(error));
    }
}