import React from 'react';
import PropTypes from 'prop-types';
import styles from './FormFieldError.module.css';

const FormFieldError = ({ text }) => (<div className={styles.error}>{text}</div>);

FormFieldError.propTypes = {
  text: PropTypes.string,
};

export default FormFieldError;