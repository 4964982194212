import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import styles from "./CategoryPermissionsSearchForm.module.css";
import Button from "../../Button";
import FormFieldError from "../../UtulityComponents/FormFieldError";
import Select from "react-select";
import {rolesToOptions} from "../../../utils/toSelectOption";
import { accessIsSetByUserStatusList } from '../../../constants/adminCategoriesPermissions/accessIsSetByUserStatusList';


const CategoryPermissionsSearchForm = ({ roles, formData, setFormData, onSubmit }) => {

  const [errors, setErrors] = useState({
    role: {
      active: false,
      text: 'Необходимо выбрать роль',
      check(formData) {
        return !!formData.roleid
      }
    }
  });

  const checkBeforeSubmit = () => {
    let updatedErrors = {...errors};
    let isFormValid = true;

    for(let key in updatedErrors) {
      updatedErrors[key].active = false;

      let isFieldValid = updatedErrors[key].check(formData);
      if(!isFieldValid) {
        updatedErrors[key].active = true;
        isFormValid = false;
      }
    }

    setErrors(updatedErrors);

    if(isFormValid) {
      onSubmit();
    }
  };

  return (
    <div className={styles.container}>
      <Row>
        <Col md={5} xl={4}>
          <ModalInputWrapper label={"Роль"}>
            <Select
              defaultValue={formData.roleid}
              placeholder={'Выберите роль'}
              onChange={value => setFormData({ ...formData, roleid: value.value })}
              options={rolesToOptions(roles)}
            />

          </ModalInputWrapper>
          {errors.role.active && <FormFieldError text={errors.role.text}/>}
        </Col>
        <Col md={5} xl={4}>
          <ModalInputWrapper label={"Фильтр"}>
            <Select
              defaultValue={formData.set}
              placeholder={'Выберите значение фильтрации'}
              onChange={value => setFormData({ ...formData, set: value.value })}
              options={accessIsSetByUserStatusList}
            />
          </ModalInputWrapper>
        </Col>
        <Col md={2} className={'d-flex align-items-center'}>
          <Button label={"Найти"} onClick={checkBeforeSubmit}/>
        </Col>
      </Row>
    </div>
  );
};

CategoryPermissionsSearchForm.propTypes = {
  roles: PropTypes.array.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CategoryPermissionsSearchForm;