import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { REPORT_GET_REQUEST, REPORTS_LIST_REQUEST, START } from "../../actions/action-types";
import { getReportsList } from "../../../api/report/list";
import { reportsListRequestError, reportsListRequestSuccess } from "../../actions/reports/list";
import { getReport } from "../../../api/report/report";
import { reportGetRequestError, reportGetRequestSuccess } from "../../actions/reports/get";

export default function* reportsWatcher() {
  yield takeLatest(`${REPORTS_LIST_REQUEST}${START}`, reportsListWorker);
  yield takeLatest(`${REPORT_GET_REQUEST}${START}`, reportWorker);
}

function* reportsListWorker() {
  try {
    const response = yield call(getReportsList);
    yield put(reportsListRequestSuccess(response.data));
  } catch (error) {
    yield put(reportsListRequestError(error));
  }
}

function* reportWorker({ payload: { id, formData } }) {
  try {
    const response = yield call(getReport, id, formData);
    yield put(reportGetRequestSuccess({id, data: response.data}));
  } catch (error) {
    yield put(reportGetRequestError(error));
  }
}