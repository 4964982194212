import React, {useEffect, useState, useCallback} from 'react';
import {connect} from "react-redux";
import {BASE_URL_WITHOUT_API} from "../../constants/api";
import {resolveFileApiLink} from "../../utils/resolvePdfLink";

import {pdfViewerBookRequestStart} from "../../redux/actions/pdfViewer/book";
import {pdfViewerBookmarkRequestStart} from "../../redux/actions/pdfViewer/getBookmark";
import {pdfViewerBookmarkSaveStart} from "../../redux/actions/pdfViewer/saveBookmark";
import {pdfViewerSetBookmarksData} from "../../redux/actions/pdfViewer/setBookmarks";

import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import Sidebar from "./Sidebar";
import {pdfViewerSetFileData} from "../../redux/actions/pdfViewer/setFileData";
import Viewer from "./Viewer";

import styles from './PDFViewerScene.module.css';

const mapStateToProps = ({pdfViewer}) => ({
    book: pdfViewer.book,
    bookmark: pdfViewer.bookmark,
});

const mapDispatchToProps = {
    requestBook: pdfViewerBookRequestStart,
    requestBookmarks: pdfViewerBookmarkRequestStart,
    saveBookmark: pdfViewerBookmarkSaveStart,
    setBookmarks: pdfViewerSetBookmarksData,
    setFileData: pdfViewerSetFileData
};

const PDFViewerScene = ({requestBook, match, requestBookmarks, bookmark, setBookmarks, setFileData}) => {

    let scrollViewerTo = (highlight) => {
    };

    const {bookId, fileId} = match.params;

    const [bookUrl, setBookUrl] = useState('');
    const [scrollHandler, setScrollHandler] = useState({handler: scrollViewerTo});
    const [selectedHighlight, setSelectedHighlight] = useState("");
    const [bookmarkShow, setBookmarkShow] = useState(true);

    const scrollToHighlightFromHash = useCallback(
        (bookmark) => {

            const highlight = getHighlightById(parseIdFromHash(), bookmark);

            if (highlight) {
                scrollHandler.handler(highlight);
            }
        }, [bookmark, scrollHandler]
    );

    useEffect(() => {
        requestBookmarks(bookId, fileId);
        setFileData(bookId, fileId);
    }, []);

    useEffect(() => {
        requestBook(bookId)
    }, [requestBook, bookId]);


    useEffect(() => {
        resolveFileApiLink(bookId, fileId)
            .then(response => setBookUrl(`${BASE_URL_WITHOUT_API}${response.data.url}`))
    }, []);

    useEffect(() => {
        const listener = () => {
            scrollToHighlightFromHash(bookmark)
        };

        window.addEventListener(
            "hashchange",
            listener,
            false
        );

        return () => {
            window.removeEventListener(
                "hashchange",
                listener,
                false
            )
        }
    }, [scrollToHighlightFromHash]);



    const getHighlightById = (id, highlights) => {
        return highlights.find(highlight => highlight.id === id)
    };


    const resetHash = () => {
        document.location.hash = "";
    };


    const getNextId = () => String(Math.random()).slice(2);


    const addHighlight = (highlight) => {
        const updatedBookmarks = [...bookmark, {...highlight, id: getNextId()}];
        setBookmarks(updatedBookmarks);
    };


    const resetHighlights = () => {
        const confirm = window.confirm('Вы действительно хотите очистить список заметок?');

        if (confirm)
            setBookmarks([]);
    };

    const parseIdFromHash = () =>
        document.location.hash.slice("#highlight-".length);

    const updateHighlight = (highlightId, position, content) => {

        setBookmarks(
            bookmark.map(h => {
                return h.id === highlightId
                    ? {
                        ...h,
                        position: {...h.position, ...position},
                        content: {...h.content, ...content}
                    }
                    : h;
            })
        );
    };

    const deleteItem = (highlight) => {
        const updatedHighlights = bookmark.filter(item => item.id !== highlight.id);

        setBookmarks(updatedHighlights);
    };



    if (!bookUrl) {
        return <SpinnerOuter/>
    }
    return (
        <div className={'d-flex'} style={{height: "100vh"}}>
            <button
                className={styles.switchHighlights}
                onClick={() => setBookmarkShow(!bookmarkShow)}
            >
                {bookmarkShow ? "Скрыть заметки" : "Показать заметки"}
            </button>
            {bookmarkShow &&
            <Sidebar
                highlights={bookmark}
                resetHighlights={resetHighlights}
                selectedId={selectedHighlight}
                deleteItem={deleteItem}
            />
            }

            <Viewer
                bookUrl={bookUrl}
                resetHash={resetHash}
                setScrollHandler={setScrollHandler}
                scrollToHighlightFromHash={scrollToHighlightFromHash}
                bookmarkList={bookmark}
                addHighlight={addHighlight}
                selectedHighlight={selectedHighlight}
                setSelectedHighlight={setSelectedHighlight}
                updateHighlight={updateHighlight}
                bookmarkShow={bookmarkShow}
                setBookmarkShow={setBookmarkShow}
            />
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PDFViewerScene);