import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
    DOCUMENT_BOOK_DELETE_REQUEST,
    DOCUMENT_BOOK_LIST_REQUEST,
    DOCUMENT_BOOK_SAVE_REQUEST,
    DOCUMENT_BOOK_CLEANUP,
    START
} from "../../../actions/action-types";
import {
    addBookToDocument,
    deleteBookToDocument,
    editBookToDocument,
    getDocumentBooksList
} from "../../../../api/documents/bookToDocument";

import {
    documentBookListRequestError,
    documentBookListRequestStart,
    documentBookListRequestSuccess
} from "../../../actions/documents/books/list";
import {
    documentBookSaveRequestError,
    documentBookSaveRequestSuccess
} from "../../../actions/documents/books/save";
import {
    documentBookDeleteRequestError,
    documentBookDeleteRequestSuccess
} from "../../../actions/documents/books/delete";

export default function* documentBooksWatcher() {
    yield takeLatest(`${DOCUMENT_BOOK_LIST_REQUEST}${START}`, documentBooksListWorker);
    yield takeLatest(`${DOCUMENT_BOOK_SAVE_REQUEST}${START}`, documentBooksSaveWorker);
    yield takeLatest(`${DOCUMENT_BOOK_DELETE_REQUEST}${START}`, documentBooksDeleteWorker);
}

function* documentBooksListWorker({ payload: documentId }) {
    try {
        const response = yield call(getDocumentBooksList, documentId);
        yield put(documentBookListRequestSuccess(response.data));
    } catch (error) {
        yield put(documentBookListRequestError(error));
    }
}

function* documentBooksSaveWorker({ payload: data }) {
    try {
        const response = yield call(data.id ? editBookToDocument : addBookToDocument, data);
        yield put(documentBookSaveRequestSuccess(response.data));
        yield put(documentBookListRequestStart(data.documentid));
    } catch (error) {
        yield put(documentBookSaveRequestError(error))
    }
}

function* documentBooksDeleteWorker({ payload: data }) {
    try {
        const response = yield call(deleteBookToDocument, data.id);
        yield put(documentBookDeleteRequestSuccess(response.data));
        yield put(documentBookListRequestStart(data.documentid));
    } catch (error) {
        yield put(documentBookDeleteRequestError(error));
    }
}
