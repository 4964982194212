import React from 'react';
import PropTypes from 'prop-types';
import styles from './SearchForm.module.css';
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";

const DocumentsSearchForm = ({ data, setSearchData, startSearch, types }) => {
    const onChange = ({ target: { name, value } }) => setSearchData({ ...data, [name]: value });

    return (
        <div className={"formContainer"}>
            <Row>
                <Col md={10}>
                    <Row>
                        <Col md={12}>
                            <ModalInputWrapper
                                label={'Поиск по имени документа'}
                                onChange={onChange}
                                name={"query"}
                                value={data.query}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col md={2}>
                    <ModalInputWrapper label={''}>
                        <Button
                            label={"Найти"}
                            onClick={startSearch}
                        />
                    </ModalInputWrapper>
                </Col>
            </Row>
        </div>
    );
};

DocumentsSearchForm.propTypes = {
    data: PropTypes.object,
    setSearchData: PropTypes.func,
    startSearch: PropTypes.func,
    types: PropTypes.array
};

export default DocumentsSearchForm;
