import React from 'react';
import PropTypes from 'prop-types';
import { ADD, EDIT } from "../../../constants/modalTypes";
import styles from "./AuthorsTable.module.css";
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";

const AuthorsTable = ({ initialFormFields, data, setModal, sortBy, setSort, selectedItem, handleSelect, onDelete, requestAuthorBooks }) => {
  const openEditModal = (item) => {
    requestAuthorBooks(item.id);
    setModal({ modalType: EDIT, data: item })
  };

  const openCreationModal = () => setModal({ modalType: ADD, data: initialFormFields });

  return (
    <div className={styles.container}>
      <div
        className={styles.button}
        onClick={openCreationModal}
      >
        добавить
      </div>
      <div className={styles.button} onClick={onDelete}>
        удалить
      </div>
      <DataTable
        data={data}
        sortBy={sortBy}
        onHeadClick={setSort}
        onCellClick={openEditModal}
      >
        <DataTableCol
          title="ФИО Автора"
          value="displayname"
          className={styles.leftAlign}
          colSpan={4}
        />
        <DataTableCol title="Авторский знак" value="authorsign" />
        <DataTableCol title="Книг на учёте" value="authornumbooks" />
        <DataTableCol
          title="Выбрано"
          passClicks
          resolver={item => (
            <input
              type="checkbox"
              checked={item.id === selectedItem.id}
              onChange={() => handleSelect(item)}
            />
          )}
        />
      </DataTable>
    </div>
  );
};

AuthorsTable.propTypes = {
  data: PropTypes.array,
  setModal: PropTypes.func,
  sortBy: PropTypes.string,
  setSort: PropTypes.func,
  selectedId: PropTypes.string,
  handleSelect: PropTypes.func,
  onDelete: PropTypes.func,
  initialFormFields: PropTypes.object
};

AuthorsTable.defaultProps = {
  data: []
};

export default AuthorsTable;
