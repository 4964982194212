import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { RESERVE_BOOK_REQUEST, START, UNRESERVE_BOOK_REQUEST } from "../../actions/action-types";
import { reserveBook, unReserveBook } from "../../../api/book/reserveBook";
import { reserveBookRequestError, reserveBookRequestSuccess } from "../../actions/books/reserveBook";
import { unreserveBookRequestError, unreserveBookRequestSuccess } from "../../actions/books/unreserveBook";
import { coversCountRequestStart } from "../../actions/covers/count";
import { REQUEST_SOURCE } from "../../../constants/requestSource";
import { reservedBooksListRequestStart } from "../../actions/books/reservedList";

export default function* reserveBookWatcher() {
  yield takeLatest(`${RESERVE_BOOK_REQUEST}${START}`, reserveBookWorker);
  yield takeLatest(`${UNRESERVE_BOOK_REQUEST}${START}`, unreserveBookWorker);
}

function* reserveBookWorker({ payload: instanceId }) {
  const mid = yield select(({ auth }) => auth.data.mid);
  try {
    const response = yield call(reserveBook, instanceId, mid);
    yield put(reserveBookRequestSuccess(response.data));
    yield put(coversCountRequestStart());
  } catch (error) {
    yield put(reserveBookRequestError(error));
  }
}

function* unreserveBookWorker({ payload: { instanceId, source } }) {
  try {
    const response = yield call(unReserveBook, instanceId);
    yield put(unreserveBookRequestSuccess(response.data));
    if(source === REQUEST_SOURCE.COVERS)
      yield put(coversCountRequestStart());
    else
      yield put(reservedBooksListRequestStart());
  } catch (error) {
    yield put(unreserveBookRequestError(error));
  }
}