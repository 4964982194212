import React, {useCallback, useEffect} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "./BookEditIndexTab.module.css";
import {Col, Row} from "react-bootstrap";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import {nonNull} from "../../../../utils/strings";
import {verifyStatusList} from "../../../../constants/bookVerifyStatus";
import {extractReasons} from "../../../../constants/extractReasons";
import {rightOfUseList} from "../../../../constants/bookRightOfUse";
import {authorsCountRequestStart} from "../../../../redux/actions/authors/count";
import {setAuthorsSearchData} from "../../../../redux/actions/authors/searchData";
import {connect} from "react-redux";
import SearchableSelect from "../../../Select";
import {
    authorsToOptions, categoriesToOptions,
    optionsToAuthors, optionsToCategories,
    optionsToPublishers,
    outsecrecyToOptions,
    publishersToOptions
} from "../../../../utils/toSelectOption";
import {publishersCountRequestStart} from "../../../../redux/actions/publishers/count";
import {setPublishersSearchData} from "../../../../redux/actions/publishers/searchData";
import {institutionsCountRequestStart} from "../../../../redux/actions/institutions/count";
import {setInstitutionsSearchData} from "../../../../redux/actions/institutions/search";
import BookScans from "./BookScans";
import {resolveBookCoverUrl} from "../../../../utils/resolvePhotoUrl";
import {
    cleanBookCover,
    cleanBookScan,
    saveBookCover,
    saveBookScan,
    savePdf,
    deletePdfRequest
} from "../../../../redux/actions/bookData/data";
import BookCover from "./BookCover";
import {outsecrecyCountRequestStart} from "../../../../redux/actions/outsecrecy/count";
import {setOutsecrecySearchData} from "../../../../redux/actions/outsecrecy/searchData";
import UDKCodes from "./UDKCodes";
import {udkListCollapse, udkListRequestStart} from "../../../../redux/actions/udk/list";
import {bbkListCollapse, bbkListRequestStart} from "../../../../redux/actions/bbk/list";
import {categoriesListRequestStart} from "../../../../redux/actions/categories/list";
import {setCategoriesSearchData} from "../../../../redux/actions/categories/searchData";
import {libraryTypesListRequestStart} from '../../../../redux/actions/library/types/list';
import {bookCardDataRequestStart} from "../../../../redux/actions/bookData/bookCard";
import Input from "../../../ElectronicBookImport/Input";
import {electronicBookZipSaveStart} from "../../../../redux/actions/ElectronicBookImport/saveZip";
import {createNotification} from "../../../notification/notification";
import {generateSign} from "../../../../api/book/edit";
import {bookSourceRequestStart} from "../../../../redux/actions/booksource";

const SEIZED_ID = "5";
const BY_SUBSCRIPTION_VALUE = "По подписке";

const mapStateToProps = ({ authors, bookCard, bookSource, categories, publishers, library, institutions, outsecrecy, udk, bbk }) => ({
  authorsList: authors.list,
  authorsSearch: authors.search,
  categoriesList: categories.list,
  categoriesSearch: categories.search,
  categoriesListLoading: categories.loader,
  libraryTypes: library.types.list,
  publishersSearch: publishers.search,
  publishersList: publishers.list,
  institutionsSearch: institutions.search,
  institutionsList: institutions.list,
  outsecrecySearch: outsecrecy.search,
  outsecrecyList: outsecrecy.list,
  UDKlist: udk,
  BBKlist: bbk,
  bookCardData: bookCard,
  bookSource: bookSource,

});

const mapDispatchToProps = {
    requestAuthors: authorsCountRequestStart,
    requestCategories: categoriesListRequestStart,
    requestLibraryTypes: libraryTypesListRequestStart,
    requestBookCardData: bookCardDataRequestStart,
    setAuthorSearch: setAuthorsSearchData,
    setCategoriesSearch: setCategoriesSearchData,
    requestPublishers: publishersCountRequestStart,
    requestOutsecrecy: outsecrecyCountRequestStart,
    setOutsecrecySearch: setOutsecrecySearchData,
    setPublishersSearch: setPublishersSearchData,
    requestInstitutions: institutionsCountRequestStart,
    setInstitutionsSearch: setInstitutionsSearchData,
    uploadBookScan: saveBookScan,
    uploadPdf: savePdf,
    deletePdf: deletePdfRequest,
    cleanBookScan: cleanBookScan,
    uploadBookCover: saveBookCover,
    cleanBookCover: cleanBookCover,
    requestUDKList: udkListRequestStart,
    collapseUDKList: udkListCollapse,
    requestBBKList: bbkListRequestStart,
    collapseBBKList: bbkListCollapse,
    saveZip: electronicBookZipSaveStart,
    requestBookSourceList: bookSourceRequestStart
};

const BookEditIndexTab = ({
                              data,
                              setData,
                              authorsSearch,
                              authorsList,
                              categoriesList,
                              categoriesSearch,
                              requestAuthors,
                              requestCategories,
                              setAuthorSearch,
                              setCategoriesSearch,
                              publishersSearch,
                              publishersList,
                              requestPublishers,
                              setPublishersSearch,
                              uploadBookScan,
                              cleanBookScan,
                              cleanBookCover,
                              setOutsecrecySearch,
                              outsecrecySearch,
                              outsecrecyList,
                              requestOutsecrecy,
                              UDKlist,
                              requestUDKList,
                              collapseUDKList,
                              BBKlist,
                              requestBBKList,
                              collapseBBKList,
                              libraryTypes,
                              requestLibraryTypes,
                              uploadBookCover,
                              requestBookCardData,
                              bookCardData,
                              saveZip,
                              requestBookSourceList,
                              bookSource
                          }) => {

    const onChange = ({target: {value, name}}) => {
        setData({...data, [name]: value});
    };

    useEffect(() => {
        requestLibraryTypes();
    }, [requestLibraryTypes]);

    useEffect(() => {
        requestBookSourceList();
    }, [requestBookSourceList]);

    useEffect(() => {
        requestOutsecrecy();
    }, []);

    useEffect(() => {
        requestBookCardData(data.bookid);
    }, [requestBookCardData]);

    const generateSignHandler = () => {
        const requestQuery =
            (data.authors &&
            data.authors[0] &&
            data.authors[0].name)
        ?
            data.authors[0].name.split(" ")[0]
        :
            data.title.split(" ")[0];

        generateSign(requestQuery)
            .then(res => {
                const newAuthorSign = !!res.data.length
                    ? res.data[0].index
                    : '';
                setData({...data, authorSign: newAuthorSign});
                if(!newAuthorSign) {
                    createNotification('warning', 'Не удалось определить авторский знак')
                }
            })
            .catch(err => console.log(err))
    };

    const onAuthorInputChange = name => {
        setAuthorSearch({...authorsSearch, query: name});
        requestAuthors();
    };

    const onCategoriesInputChange = (name) => {
        setCategoriesSearch({...categoriesSearch, query: name});
        requestCategories();
    };

    const onOutsecrecyInputChange = name => {
        setOutsecrecySearch({...outsecrecySearch, query: name});
        requestOutsecrecy();
    };

    const onPublisherInputChange = name => {
        setPublishersSearch({...publishersSearch, query: name});
        requestPublishers();
    };


    const onUploadBookCover = (formData) => {

        /*let reader = new FileReader();

        reader.onload = (e) => {
          const src = reader.result;

          //setData({ ...data, uploadedCover: src })
        };
        reader.readAsDataURL(formData.file);
        */
        uploadBookCover(formData);
    };

    const zipInputOnChangeHandler = (fileData) => {
        const file = new FormData();
        file.append("file", fileData);
        saveZip(
            {
                file,
                id: data.bookid
            },
            () => {}
        );
    };

    const outsecrecyOptions = useCallback(() => {
        return outsecrecyToOptions(outsecrecyList);
    }, [outsecrecyList]);

    const getCurrentOutsecrecyValue = useCallback(() => {
        const options = outsecrecyOptions();
        return (options || []).find(({value}) => value === data.eerOutSecrecyId);
    }, [outsecrecyList, data.eerOutSecrecyId]);


    return (
        <div className={styles.container}>
            <Row>
                <Col
                    sm={12}
                    md={4}
                >
                    <BookCover
                        bookId={data.bookid}
                        onUpload={onUploadBookCover}
                        onClean={cleanBookCover}
                        coverUrl={
                            data.uploadedCover
                                ?
                                data.uploadedCover
                                :
                                data.coverImage &&
                                resolveBookCoverUrl(data.coverImage, data.bookid)
                        }
                    />
                    <BookScans
                        isCard={true}
                        onUpload={uploadBookScan}
                        onClean={cleanBookScan}
                        bookId={data.bookid}
                        bookCardData={bookCardData}
                        backCoverUrl={
                            data.subscriptionBackCover &&
                            resolveBookCoverUrl(data.subscriptionBackCover, data.bookid)
                        }
                        frontCoverUrl={
                            data.subscriptionCover &&
                            resolveBookCoverUrl(data.subscriptionCover, data.bookid)
                        }
                    />


                    {data.typeId === '12' ?
                        <div style={{marginTop: '1rem'}}>
                            <Input inputHandler={zipInputOnChangeHandler} label={'Загрузить архив'}/>
                        </div>
                        : null
                    }

                </Col>
                <Col sm={12} lg={8}>
                    <Row>
                        <Col md={12}>
                            <ModalInputWrapper
                                label="Авторский знак"
                                value={data.authorSign}
                                name={"authorSign"}
                                onChange={onChange}
                                additionalButtonOnClick={generateSignHandler}
                                additionalButtonText={"Определить"}
                            />
                        </Col>
                        <Col md={12}>
                            <ModalInputWrapper label="Авторы">
                                <SearchableSelect
                                    author={true}
                                    data={data}
                                    setData={setData}
                                    placeholder="Поиск"
                                    onInputChange={onAuthorInputChange}
                                    options={authorsToOptions(authorsList)}
                                    isMulti
                                    onChange={value =>
                                        setData({...data, authors: optionsToAuthors(value)})
                                    }
                                    value={authorsToOptions(data.authors)}
                                />
                            </ModalInputWrapper>
                        </Col>

                        <Col md={12}>
                            <ModalInputWrapper
                                label="Тип"
                            >
                                <select
                                    name="typeId"
                                    value={data.typeId}
                                    onChange={e => setData({...data, typeId: e.target.value})}
                                    className={styles.select}
                                >
                                    <option value="" disabled>Выберите из списка</option>
                                    {libraryTypes.map((item, index) =>
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )}
                                </select>
                            </ModalInputWrapper>
                        </Col>

                        <Col md={12}>
                            <ModalInputWrapper label="Категории">
                                <SearchableSelect
                                    data={data}
                                    setData={setData}
                                    placeholder="Поиск"
                                    onInputChange={onCategoriesInputChange}
                                    options={categoriesToOptions(categoriesList)}
                                    isMulti={true}
                                    onChange={value =>
                                        setData({...data, categories: optionsToCategories(value)})
                                    }
                                    value={categoriesToOptions(data.categories)}
                                />
                            </ModalInputWrapper>
                        </Col>
                        <Col md={12}>
                            <ModalInputWrapper
                                label="Заглавие"
                                value={data.title}
                                name="title"
                                onChange={onChange}
                            />
                        </Col>
                        <Col md={12}>
                            <ModalInputWrapper
                                label="Издательства"
                            >
                                <SearchableSelect
                                    placeholder="Поиск"
                                    publishers={true}
                                    onInputChange={onPublisherInputChange}
                                    options={publishersToOptions(publishersList)}
                                    isMulti={true}
                                    onChange={value =>
                                        setData({...data, publishers: optionsToPublishers(value)})
                                    }
                                    value={publishersToOptions(data.publishers)}
                                />
                            </ModalInputWrapper>
                        </Col>
                        <Col md={12}>
                            <ModalInputWrapper
                                label="Год издания"
                                value={data.year}
                                name={"year"}
                                onChange={onChange}
                            />
                        </Col>
                        <Col md={6}>
                            <ModalInputWrapper
                                label="Кол-во страниц"
                                value={data.pageNum}
                                onChange={onChange}
                                name={"pageNum"}
                            />
                        </Col>
                        <Col md={6}>
                            <ModalInputWrapper
                                label="ISBN"
                                value={data.isbn.join(",")}
                                onChange={({target: {value}}) =>
                                    setData({...data, isbn: value.split(",")})
                                }
                            />
                        </Col>
                        <Col md={12}>
                            <ModalInputWrapper
                                label="Аннотация"
                                value={data.annotation}
                                name={"annotation"}
                                onChange={onChange}
                                type={"textarea"}
                            />
                        </Col>
                    </Row>
                    <UDKCodes
                        data={data}
                        setData={setData}
                        type={'udk'}
                        directoryList={UDKlist}
                        requestDirectoryList={requestUDKList}
                        collapseDirectoryList={collapseUDKList}
                    />
                    <UDKCodes
                        data={data}
                        setData={setData}
                        type={'bbk'}
                        directoryList={BBKlist}
                        requestDirectoryList={requestBBKList}
                        collapseDirectoryList={collapseBBKList}
                    />
                    <Row>
                        <Col md={12}>
                            <ModalInputWrapper
                                label="Сведения, относящиеся к заглавию"
                            />
                        </Col>
                        <Col md={12}>
                            <ModalInputWrapper
                                label="Ключевые слова"
                                value={nonNull(data.keywords)}
                                name={"keywords"}
                                onChange={onChange}
                            />
                        </Col>
                    </Row>


                    <Row>
                        <Col md={12}>
                            <ModalInputWrapper label="Гриф">
                                <SearchableSelect
                                    placeholder="Поиск"
                                    onInputChange={onOutsecrecyInputChange}
                                    options={outsecrecyOptions()}
                                    onChange={value =>
                                        setData({...data, eerOutSecrecyId: (value || {}).value})
                                    }
                                    value={getCurrentOutsecrecyValue()}
                                />
                            </ModalInputWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <ModalInputWrapper label={"Статус"}>
                                <select
                                    value={data.verifyStatus}
                                    className={styles.select}
                                    name="verifyStatus"
                                    onChange={onChange}
                                >
                                    <option value={""}>Не выбрано</option>
                                    {verifyStatusList.map((item, index) => (
                                        <option value={item.value} key={index}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </ModalInputWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <ModalInputWrapper label={"Источник"}>
                                <select
                                    value={nonNull(data.book_source)}
                                    className={styles.select}
                                    name="book_source"
                                    onChange={onChange}
                                >
                                    <option value={""} disabled >Не выбрано</option>
                                    {bookSource.map(item => (
                                        <option value={item.id} key={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </ModalInputWrapper>
                        </Col>
                    </Row>
                    {data && !!data.verifyStatus && data.verifyStatus.toString() === SEIZED_ID && (
                        <Row>
                            <Col md={12}>
                                <ModalInputWrapper
                                    label={"Причина"}
                                >
                                    <select
                                        value={nonNull(data.extractreason)}
                                        className={styles.select}
                                        name="extractreason"
                                        onChange={onChange}
                                    >
                                        <option value={""}>Не выбрано</option>
                                        {extractReasons.map((item, index) => (
                                            <option value={item.value} key={index}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </ModalInputWrapper>
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Col md={12}>
                            <ModalInputWrapper
                                label={"Право использования"}
                            >
                                <select
                                    value={nonNull(data.rightofuse)}
                                    className={styles.select}
                                    name="rightofuse"
                                    onChange={onChange}
                                >
                                    {rightOfUseList.map((item, index) => (
                                        <option value={item} key={index}>
                                            {item}
                                        </option>
                                    ))}
                                </select>
                            </ModalInputWrapper>
                        </Col>
                    </Row>
                    {data.rightofuse === BY_SUBSCRIPTION_VALUE && (
                        <Row>
                            <Col md={12}>
                                <ModalInputWrapper
                                    label="Срок окончания подписки"
                                    value={moment(data.subscriptionenddate).format("YYYY-MM-DD")}
                                    name={"subscriptionenddate"}
                                    onChange={onChange}
                                    type="date"
                                />
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </div>
    );
};

BookEditIndexTab.propTypes = {
    data: PropTypes.object,
    setData: PropTypes.func
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BookEditIndexTab);
