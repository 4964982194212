import { SERIES_DELETE_REQUEST, ERROR, START, SUCCESS } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const seriesCatalogDeleteRequestStart = (data) => ({
  type: `${SERIES_DELETE_REQUEST}${START}`,
  payload: data
});

export const seriesCatalogDeleteRequestSuccess = (data) => {
  createNotification('success', 'Серия успешно удалена');
  return {
    type: `${SERIES_DELETE_REQUEST}${SUCCESS}`,
    payload: data
  };
};

export const seriesCatalogDeleteRequestError = (error) => {
  createNotification('error', `${error.result}`);
  return {
    type: `${SERIES_DELETE_REQUEST}${ERROR}`,
    payload: error
  };
};
