import React from 'react';
import PropTypes from 'prop-types';
import styles from './InvNumbersModalSearchItem.module.css';
import { Col, Row } from "react-bootstrap";
import CoverPhotoPlaceholder from "../../../Covers/List/Item/CoverPhotoPlaceholder";
import { Link } from "react-router-dom";
import classNames from 'classnames';
import CoverListItemInfo from "../../../Covers/List/Item/InfoBlock";


const InvNumbersItem = ({ data, selected, onClick }) => {
  return (
    <div className={classNames(styles.container, { [styles.selected]: selected })} onClick={() => onClick(data)}>
      <Row>
        <Col md={2}>
          <CoverPhotoPlaceholder url={data.coverImage} bookId={data.bookid} alt={data.title}/>
        </Col>
        <Col md={10}>
          <Row>
            <Col md={{ span: 9, offset: 3 }}>
              <Link to={`/book/${data.bookid}`} className={styles.title}>
                {data.title}
              </Link>
            </Col>
            <Col md={12}>
              <CoverListItemInfo label={"Автор"} value={data.authors}/>
              <CoverListItemInfo label={"Год"} value={data.year}/>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

InvNumbersItem.propTypes = {
  data: PropTypes.object,
  selected: PropTypes.bool
};

export default InvNumbersItem;
