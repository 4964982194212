import {
    RESET_INVENTORY_COVERS_FORM_DATA,
    SET_INVENTORY_COVERS_FORM_DATA
} from "../action-types";

export const setInventoryCoversFormData = (data) => ({
  type: SET_INVENTORY_COVERS_FORM_DATA,
  payload: data
});

export const resetCoversFormData = () => ({
    type: RESET_INVENTORY_COVERS_FORM_DATA
});