import React from 'react';
import PropTypes from 'prop-types';
import styles from './AuthForm.module.css';
import Button from "../../Button";
import ModalInputWrapper from "../../Modal/InputWrapper";

const AuthForm = ({ data, setData, onSubmit }) => {

  return (
    <form onSubmit={onSubmit} className={styles.container}>
      <div className={styles.label}>
        Пожалуйста, авторизуйтесь
      </div>
      <div className={styles.iconContainer}>
        <div className={styles.userIcon}/>
      </div>
      <div className={styles.inputContainer}>
        <ModalInputWrapper label="Логин" value={data.username}
                           onChange={(e) => setData({ ...data, username: e.target.value })}/>
        <ModalInputWrapper label="Пароль" type={"password"} value={data.password}
                           onChange={(e) => setData({ ...data, password: e.target.value })}/>
        <br/>
        <Button onClick={onSubmit} label={"Войти"} type="submit"/>
      </div>
    </form>
  );
};

AuthForm.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  onSubmit: PropTypes.func
};

export default AuthForm;
