import { BOOK_SCAN_ORDER_ADD_REQUEST, ERROR, START, SUCCESS } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const bookScanOrderAddRequestStart = (data) => ({
  type: `${BOOK_SCAN_ORDER_ADD_REQUEST}${START}`,
  payload: data
});

export const bookScanOrderAddRequestSuccess = (data) => {
  createNotification('success', 'Заказ на сканирование успешно добавлен');
  return {
    type: `${BOOK_SCAN_ORDER_ADD_REQUEST}${SUCCESS}`,
    payload: data
  };
};

export const bookScanOrderAddRequestError = (data) => {
  createNotification('error', 'Не удалось добавить заказ на сканирование');
  return {
    type: `${BOOK_SCAN_ORDER_ADD_REQUEST}${ERROR}`,
    payload: data
  };
};
