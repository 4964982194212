import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { BOOK_DATA_REQUEST, START } from "../../actions/action-types";
import { bookDataRequestError, bookDataRequestSuccess } from "../../actions/bookData/data";
import { getBookInfo } from "../../../api/book/book";

export default function* bookDataWatcher() {
  yield takeLatest(`${BOOK_DATA_REQUEST}${START}`, bookDataWorker)
}

function* bookDataWorker({payload: bookId}) {
  try {
    const response = yield call(getBookInfo, bookId);
    yield put(bookDataRequestSuccess(response.data));
  } catch(error) {
    yield put(bookDataRequestError(error));
  }
}