import React from "react";
import PropTypes from "prop-types";
import styles from "./DataTable.module.css";

const DataTable = ({data, children, sortBy, onCellClick, onHeadClick, tableId}) => {


    return (
        <table id={tableId || null} className={styles.table}>
            <thead>
            <tr>
                {React.Children.map(children, Column =>
                    React.cloneElement(Column, {
                        isHeader: true,
                        sortBy: sortBy,
                        onClick: onHeadClick
                    })
                )}
            </tr>
            </thead>
            <tbody>
            {data.map((item, index) => (
                <tr key={index}>
                    {React.Children.map(children, Column => React.cloneElement(Column, {
                            item: item,
                            onClick: onCellClick,
                            sortBy: sortBy,
                            column: index + 1
                        })
                    )}
                </tr>
            ))}
            </tbody>
        </table>
    );
};

DataTable.propTypes = {
    data: PropTypes.array.isRequired,
    modal: PropTypes.node,
    sortBy: PropTypes.string,
    onCellClick: PropTypes.func,
    onHeadClick: PropTypes.func,

    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

export default DataTable;
