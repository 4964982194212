import React from 'react';
import PropTypes from 'prop-types';
import ModalWindow from "../../ModalWindow";
import {ADD} from "../../../constants/modalTypes";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import LoadForm from "../../BookEdit/Tabs/Files/loadForm";


const BorrowingTestModal = ({ modalData, setModalData, closeModal, isOpen, modalErrors }) => {
  const { type, data, methods } = modalData;

  const onChange = (e) => {
    setModalData({...modalData, data: {...data, [e.target.name]: e.target.value} })
  };

  const onUploadFile = (file) => {
    setModalData({...modalData, data: { ...data, file } });
  };

  return (
    <ModalWindow
      title={`${type === ADD ? "Создать новый запрос" : "Редактировать запрос"}`}
      onRequestClose={closeModal}
      isOpen={isOpen}
      width={'500px'}
    >
      <ModalInputWrapper
        type="textarea"
        label="Комментарий"
        onChange={onChange}
        name="comment"
        value={data["comment"]}
      />


      {data.status === 100 &&
        <div className="error_container mb-1">
          {data.message}
        </div>
      }


      {type === ADD ?
        <LoadForm onUpload={onUploadFile}/>
        :
        <span>Файл: { data.fileName }</span>
      }

      <div>
        {modalErrors.file.active &&
          <div className="color_red">{modalErrors.file.text}</div>
        }
      </div>


      <div className={'d-flex justify-content-end mt-3'}>
        <Button
          label={`${type === ADD ? "Создать" : "Сохранить"}`}
          onClick={() => methods.onSubmit(data)}
        />
      </div>

    </ModalWindow>
  );
};

BorrowingTestModal.propTypes = {
  modalData: PropTypes.object.isRequired,
  setModalData: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  modalErrors: PropTypes.object
};

BorrowingTestModal.defaultProps = {
  isOpen: true
};

export default BorrowingTestModal;