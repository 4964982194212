import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./LibraryMaterialsListItem.module.css";
import {saveAs} from 'file-saver';
import {Link} from "react-router-dom";
import {createNotification} from "../../../notification/notification";

import {Col, Row} from "react-bootstrap";
import CoverPhotoPlaceholder from "../../../Covers/List/Item/CoverPhotoPlaceholder";
import CoverListItemInfo from "../../../Covers/List/Item/InfoBlock";
import {getBookFile} from "../../../../api/book/files";
import Button from "../../../Button";
import {BASE_URL_WITHOUT_API} from "../../../../constants/api";
import ModalWindow from "../../../ModalWindow";


const LibraryMaterialsListItem = ({data}) => {

    const [modalOpen, setModalOpen] = useState(false);

    const bookId = data.bookid;
    const fileName = data.fileName;
    const fileItem = data.files ? data.files[0] : {};

    const downloadFile = () => {
        const fileId = fileItem.id;

        getBookFile(bookId, fileId)
            .then(response => {
                const file = new File([response], fileItem.filename);
                saveAs(file);
            })
            .catch(err => {
                createNotification('error', 'Ошибка загрузки файла')
            });
    };

    const openEbookHandler = (id) => {
        const ebookWindow = window.open(
            `https://biblio.nintegra.ru/public/files/ebook/${id}/`
        );
        ebookWindow.focus();
    };

    const isItem3dModel = data.typeId === 10;
    const isItemAudio = data.typeId === 6;
    const isItemVideo = data.typeId === 5;

    return (
        <div className={styles.container}>
            <Row>
                <Col md={2}>
                    <CoverPhotoPlaceholder
                        url={data.coverImage}
                        bookId={data.bookid}
                        alt={data.title}
                    />
                </Col>
                <Col md={10}>
                    <Row>
                        <Col md={{span: 9, offset: 3}}>
                            <h4>{data.title}</h4>
                            <div className={styles.value}>{data.authors}</div>
                        </Col>
                        <Col md={12}>
                            <CoverListItemInfo label={"Год"} value={data.year || "-"}/>
                            <CoverListItemInfo
                                label={"Размещение"}
                                value={data.clients || "n/a"}
                            />
                            {data.covermatch !== undefined && (
                                <CoverListItemInfo
                                    label={"% совпадения"}
                                    value={data.covermatch}
                                />
                            )}
                        </Col>
                        <Col md={{span: 9, offset: 3}} className={'d-flex justify-content-between align-items-center'}>
                            {isItem3dModel && <Link to={'/3d_viewer'} target="_blank"> Просмотр модели </Link>}
                            {isItemAudio &&
                            <div>
                                <audio controls
                                       src={`${BASE_URL_WITHOUT_API}/public/files/library/${bookId}/${fileName}`}/>
                            </div>}
                            <div>
                                {isItemVideo &&
                                <Button
                                    onClick={() => setModalOpen(true)}
                                    colorType={'transparent'}
                                    label="Cмотреть видео"
                                    style={'mr-4'}
                                />
                                }
                                {data.electronFormat === 'HTML' ?
                                    <Button
                                        colorType={'transparent'}
                                        label="Открыть"
                                        onClick={() => openEbookHandler(data.id)}
                                    />
                                    :
                                    <Button
                                        key={fileItem.id}
                                        colorType={'transparent'}
                                        label="Скачать файл"
                                        onClick={downloadFile}
                                        title={`${fileItem.filename}.${fileItem.filetype}`}
                                    />
                                }
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ModalWindow
                title="Просмотр видео"
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
            >
                <div>
                    <video width="1000" controls
                           src={`${BASE_URL_WITHOUT_API}/public/files/library/${bookId}/${fileName}`}/>
                </div>
            </ModalWindow>
        </div>
    );
};

LibraryMaterialsListItem.propTypes = {
    data: PropTypes.object,
};

export default LibraryMaterialsListItem;
