import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./CoversListItem.module.css";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button'
import CoverPhotoPlaceholder from "./CoverPhotoPlaceholder";
import CoverListItemInfo from "./InfoBlock";
import { Link } from "react-router-dom";
import { coverUploadRequestStart, scanUploadRequestStart } from "../../../../redux/actions/covers/upload";
import { connect } from "react-redux";
import { statusList } from "../../../../constants/statusList";
import { cleanBookCover } from "../../../../redux/actions/bookData/data";
import SpinnerOuter from "../../../UtulityComponents/SpinnerOuter";

const mapDispatchToProps = dispatch => ({
  uploadCover: (bookId, image) =>
    dispatch(coverUploadRequestStart(bookId, image)),
  scanCover: (bookId, image) =>
    dispatch(scanUploadRequestStart(bookId, image)),
  cleanBookCover: ({ bookId }) => dispatch(cleanBookCover({ bookId }))
});

const CoversListItem = ({
  data,
  uploadCover,
  scanCover,
  cleanBookCover,
  setModalShow,
  setModalId
}) => {
  const [delModal,setDelModal] = useState(false);
  const [scanModal, setScanModal] = useState(false);
  const [imageScanState, setImageScanState] = useState({
    endpoint: 'ws://localhost:18988/scanner',
    image: null,
    blob: null,
    isLoading: true,
    isError: false
  });
  const onUpload = ({ target }) => uploadCover(data.bookid, target.files[0]);
  const bookId = data.bookid;

  const delCover = () => {
    cleanBookCover({ bookId });
    setDelModal(false);
  };

  const onClickBookPlace = () => {
    setModalId(bookId);
    setModalShow(true);
  };

  const onLoadScan = () => {
    const connection = new WebSocket(imageScanState.endpoint);
    setImageScanState({
      ...imageScanState,
      isLoading: true
    });
    setScanModal(true);
    connection.onopen = function () {
      console.log("WebSocket OPENED")
    };

    connection.onerror = function () {
      setImageScanState({
        ...imageScanState,
        isError: true,
      });
    };

    //to receive the message from server
    connection.onmessage = function (e) {
      let blob = new Blob([e.data], {type: 'image/jpeg'});
      let base64image = URL.createObjectURL(blob);
      setImageScanState({
        ...imageScanState,
        image: base64image,
        blob: blob,
        isLoading: false
      });
    };
  };

  const onScanUpload = () => {
    scanCover(data.bookid, imageScanState.blob);
    setScanModal(false);
  };


  return (
    <div className={styles.container}>

      <Modal centered show={scanModal}>
        <Modal.Header>
         Получение изображения со сканера
        </Modal.Header>
        <Modal.Body>
          {imageScanState.isError ? (
            <>
              <h3>Сканер не обнаружен</h3>
              <div style={{textAlign: 'right'}} className={'mt-3'}>
                <Button variant="danger" onClick={() => setScanModal(false)}>Закрыть</Button>
              </div>
            </>
          ) : (
            <>
              {imageScanState.isLoading ? (
                <SpinnerOuter />
              ) : (
                <>
                  <img src={imageScanState.image} alt='Загрузка изображения со скана' style={{maxWidth: '100%'}}/>
                  <div style={{textAlign: 'right'}} className={'mt-3'}>
                    <Button variant="primary" onClick={onScanUpload}>Загрузить обложку</Button>
                    <Button variant="danger" onClick={() => setScanModal(false)} className="ml-3">Отмена</Button>
                  </div>
                </>
              )}
            </>
          )}

        </Modal.Body>
      </Modal>

      <Modal centered show={delModal}>
        <Modal.Header>
          Вы уверены, что хотите удалить привязанное изображение?
        </Modal.Header>
        <Modal.Body style={{display:"flex", justifyContent:"space-between"}}>
          <Button variant="primary" onClick={delCover}>Да</Button>
          <Button variant="danger" onClick={() => setDelModal(false)}>Отмена</Button>
        </Modal.Body>
      </Modal>
      <Row>
        <Col md={2}>
          <CoverPhotoPlaceholder
            url={data.coverImage}
            bookId={data.bookid}
            alt={data.title}
          />
          <div className={styles.status}>{statusList[data.verifyStatus]}</div>
        </Col>
        <Col md={10}>
          <Row>
            <Col md={{ span: 9, offset: 3 }}>
              <Link to={`/book/${data.bookid}`} className={styles.title}>
                {data.title}
              </Link>
              <div className={styles.value}>{data.authors}</div>
            </Col>
            <Col md={12}>
              <CoverListItemInfo label={"Год"} value={data.year} />
              <CoverListItemInfo label={"ISBN"} value={data.isbn} />
              <CoverListItemInfo
                label={"Размещение"}
                value={data.clients || "n/a"}
              />
              <CoverListItemInfo label={"Фонд"} value={"n/a"} />
            </Col>
            <Col md={{ span: 9, offset: 3 }}>
              <label className={styles.button}>
                Загрузить
                <input
                  type="file"
                  className={styles.fileInput}
                  onChange={onUpload}
                />
              </label>
              <div className={styles.button} onClick={onLoadScan}>Сканировать</div>
              <Link
                to={`/book/${data.bookid}/edit`}
                target="_blank"
                className={styles.button}
              >
                Редактирование
              </Link>
              <div className={styles.button} onClick={() => setDelModal(true)}>
                Очистить
              </div>
              <div className={styles.button} onClick={onClickBookPlace}>
                Места хранения
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

CoversListItem.propTypes = {
  data: PropTypes.object
};

export default connect(
  undefined,
  mapDispatchToProps
)(CoversListItem);
