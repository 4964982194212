import { ALPHABET_COUNT_REQUEST, SUCCESS } from "../../../../actions/action-types";

const initialState = 0;

export const alphabetCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${ALPHABET_COUNT_REQUEST}${SUCCESS}`:
      return action.payload;

    default: return state;
  }
};