import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalWindow from "../../ModalWindow";
import ModalInputWrapper from "../../Modal/InputWrapper";
import Button from "../../Button";
import {DAYS, EVENT_TYPES} from "../../../scenes/DataScheduler";
import styles from './DataSchedulerModal.module.css';
import TimePicker from 'react-time-picker';
import classNames from 'classnames';
import {ICONS} from "../../../utils/projectIcons";
import {createNotification} from "../../notification/notification";


const DataSchedulerModal = ({closeModal, modalData, onSubmit}) => {
  const { data, type } = modalData;

  const initialEventTypeState = type === 'ADD' ? EVENT_TYPES.TIME :
    !!data.schedule ? EVENT_TYPES.TIME : EVENT_TYPES.INTERVAL;

  const [eventType, setEventType] = useState(initialEventTypeState);
  const [formData, setFormData] = useState(data);

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  };

  const onChangeTimepicker = (value, index) => {
    const updatedSchedule = [...formData.schedule];
    updatedSchedule[index] = value;
    setFormData({...formData, schedule: [...updatedSchedule]});
  };

  const deleteTimePicker = (index) => {
    const updatedSchedule = [...formData.schedule];
    updatedSchedule.splice(index, 1);
    setFormData({...formData, schedule: [...updatedSchedule]});
  };

  const addTimePicker = () => {
    const updatedSchedule = formData.schedule ? [...formData.schedule] : [];
    updatedSchedule.push(null);
    setFormData({...formData, schedule: [...updatedSchedule]});
  };

  const checkedOnSubmit = () => {
    if(eventType === EVENT_TYPES.INTERVAL &&
    formData.interval &&
    Number(formData.interval) < 0 ) {
      return createNotification('warning', 'Интервал не может быть меньше 0')
    }

    onSubmit(eventType, formData)
  };

  return (
    <ModalWindow
      isOpen={true}
      onRequestClose={closeModal}
      title="Создать расписание миграции метаданных"
    >
      <ModalInputWrapper label={'День'}>
        <select name="day" value={formData.day} onChange={onChange}>
          {Array.from(DAYS.entries()).map((item, index) => <option key={index} value={item[0]}>{item[1]}</option>)}
        </select>
      </ModalInputWrapper>
      <div className={styles.switcherOuter}>
        <button
          className={classNames(styles.buttonSwitcher, eventType === EVENT_TYPES.TIME && styles.buttonSwitcher_Active)}
          onClick={() => setEventType(EVENT_TYPES.TIME)}
        >
          Время
        </button>
        <button
          className={classNames(styles.buttonSwitcher, eventType === EVENT_TYPES.INTERVAL && styles.buttonSwitcher_Active)}
          onClick={() => setEventType(EVENT_TYPES.INTERVAL)}
        >
          Интервал
        </button>
      </div>
      <div className="mt-2 mb-2">
        {eventType === EVENT_TYPES.TIME &&
        <div>
          {formData.schedule && formData.schedule.map((time, index) =>
            <div key={index} className="d-flex mb-2">
              <TimePicker
                value={formData.schedule[index]}
                onChange={value => onChangeTimepicker(value, index)}
                disableClock
              />
              <button
                title="Удалить"
                className={"bg_red iconButton"}
                onClick={() => deleteTimePicker(index)}
              >
                <img src={ICONS.TRASH} alt=""/>
              </button>
            </div>
          )}
          <button
            title="Добавить"
            className={"bg_accent iconButton"}
            onClick={addTimePicker}
          >
            <img src={ICONS.PLUS} alt=""/>
          </button>
        </div>
        }
        {eventType === EVENT_TYPES.INTERVAL &&
          <ModalInputWrapper
            label={"Интервал (мин)"}
            name={'interval'}
            type={'number'}
            value={formData.interval}
            onChange={onChange}
          />
        }
      </div>


      <div className={'mt-2 d-flex justify-content-end'}>
        <Button label="Сохранить" onClick={checkedOnSubmit} colorType="green" style={'mr-1'}/>
        <Button label="Отмена" onClick={closeModal} colorType="red"/>
      </div>

    </ModalWindow>
  );
};

DataSchedulerModal.propTypes = {
  closeModal: PropTypes.func,
  modalData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
};

export default DataSchedulerModal;