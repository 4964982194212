import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {AreaHighlight, Highlight, PdfHighlighter, PdfLoader, Popup, Tip} from "../../../libs/react-pdf-highlighter/src";
import SpinnerOuter from "../../../components/UtulityComponents/SpinnerOuter";

const Viewer = ({
                    bookUrl,
                    resetHash,
                    setScrollHandler,
                    scrollToHighlightFromHash,
                    bookmarkList,
                    addHighlight,
                    selectedHighlight,
                    setSelectedHighlight,
                    updateHighlight,
                    bookmarkShow,
                }) => {


    const HighlightPopup = ({comment}) =>
        comment.text ? (
            <div className="Highlight__popup">
                {comment.emoji} {comment.text}
            </div>
        ) : null;

    return (
        <div
            style={{
                height: "100vh",
                width: bookmarkShow ? "75vw" : '100%',
                position: "relative",
                backgroundColor: "#6d6d6d"
            }}
        >
            {bookmarkShow ?
                <PdfLoader url={bookUrl} beforeLoad={<SpinnerOuter/>}>
                    {pdfDocument => (
                        <PdfHighlighter
                            pdfDocument={pdfDocument}
                            enableAreaSelection={event => event.altKey}
                            onScrollChange={resetHash}
                            selectedId={selectedHighlight}
                            scrollRef={scrollTo => {
                                setScrollHandler({handler: scrollTo});
                                scrollToHighlightFromHash(bookmarkList);
                            }}
                            onSelectionFinished={(
                                position,
                                content,
                                hideTipAndSelection,
                                transformSelection,
                            ) => (
                                <Tip
                                    onOpen={transformSelection}
                                    onConfirm={comment => {
                                        addHighlight({content, position, comment});
                                        hideTipAndSelection();
                                    }}
                                />
                            )}
                            highlightTransform={(
                                highlight,
                                index,
                                setTip,
                                hideTip,
                                viewportToScaled,
                                screenshot,
                                isScrolledTo,
                                selectedId
                            ) => {
                                const isTextHighlight = !Boolean(
                                    highlight.content && highlight.content.image
                                );

                                const component = isTextHighlight ? (
                                    <Highlight
                                        isScrolledTo={isScrolledTo}
                                        position={highlight.position}
                                        comment={highlight.comment}
                                        itemId={highlight.id}
                                        selectedId={selectedId}
                                        setSelectedHighlight={setSelectedHighlight}
                                    />
                                ) : (
                                    <AreaHighlight
                                        highlight={highlight}
                                        selectedHighlight={selectedHighlight}
                                        setSelectedHighlight={setSelectedHighlight}
                                        onChange={boundingRect => {
                                            updateHighlight(
                                                highlight.id,
                                                {boundingRect: viewportToScaled(boundingRect)},
                                                {image: screenshot(boundingRect)}
                                            );
                                        }}
                                    />
                                );

                                return (
                                    <Popup
                                        popupContent={<HighlightPopup {...highlight} />}
                                        onMouseOver={popupContent =>
                                            setTip(highlight, highlight => popupContent)
                                        }
                                        onMouseOut={hideTip}
                                        key={index}
                                        children={component}
                                    />
                                );
                            }}
                            highlights={bookmarkShow ? bookmarkList : []}
                        />
                    )}
                </PdfLoader>
                :
                <PdfLoader url={bookUrl} beforeLoad={<SpinnerOuter/>}>
                    {pdfDocument => (
                        <PdfHighlighter
                            pdfDocument={pdfDocument}
                            enableAreaSelection={null}
                            onScrollChange={resetHash}
                            selectedId={selectedHighlight}
                            scrollRef={scrollTo => {
                                setScrollHandler({handler: scrollTo});
                                scrollToHighlightFromHash(bookmarkList);
                            }}
                            onSelectionFinished={() => {}}
                            highlightTransform={() => {}}
                            highlights={bookmarkShow ? bookmarkList : []}
                        />
                    )}
                </PdfLoader>
            }
        </div>
    );
};

Viewer.propTypes = {
    bookUrl: PropTypes.string,
    resetHash: PropTypes.func,
    setScrollHandler: PropTypes.func,
    scrollToHighlightFromHash: PropTypes.func,
    bookmarkList: PropTypes.array,
    addHighlight: PropTypes.func,
    selectedHighlight: PropTypes.string,
    setSelectedHighlight: PropTypes.func,
    updateHighlight: PropTypes.func,
};

export default Viewer;