import {
  createSimpleActionsForReducer
} from "../actions/actionsFactory";
import { START, SUCCESS } from "../actions/action-types";
import {
  call,
  put,
  takeLatest,
  select
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";
import { api } from "../../App";

const SCHEDULE_REQUEST = "SCHEDULE_REQUEST";
const SCHEDULE_DATA_TO_STATE = "SCHEDULE_DATA_TO_STATE";
const SCHEDULE_LOAD_REQUEST = "SCHEDULE_LOAD_REQUEST";
const SCHEDULE_FACULTY_REQUEST = "SCHEDULE_FACULTY_REQUEST";
const SCHEDULE_CURS_REQUEST = "SCHEDULE_CURS_REQUEST";
const SCHEDULE_YEAR_REQUEST = "SCHEDULE_YEAR_REQUEST";
const USER_DATA_REQUEST = "USER_DATA_REQUEST";

const getFacultyApi = () => {
  return api.get("/hlms/api/faculty");
};

const getCursApi = () => {
  return api.get("/hlms/api/year/edy");
};

const getYearApi = () => {
  return api.get("/hlms/api/year/scy");
};

const getScheduleApi = data => {
  return api.get(`/hlms/api/schedule/${data}`);
};

const getUserData = mid => {
  return api.get(`/hlms/api/users/${mid}`);
};

export const scheduleActions = {
  ...createSimpleActionsForReducer("request", `${SCHEDULE_REQUEST}`),
  ...createSimpleActionsForReducer("load", `${SCHEDULE_LOAD_REQUEST}`),
  ...createSimpleActionsForReducer("faculty", `${SCHEDULE_FACULTY_REQUEST}`),
  ...createSimpleActionsForReducer("curs", `${SCHEDULE_CURS_REQUEST}`),
  ...createSimpleActionsForReducer("year", `${SCHEDULE_YEAR_REQUEST}`),
  ...createSimpleActionsForReducer("user", `${USER_DATA_REQUEST}`),
};

export const requestAction = data => ({
  type: SCHEDULE_DATA_TO_STATE,
  data
});

const initialState = {
  loader: false
};

export const schedulePersonalReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${SCHEDULE_REQUEST}${START}`:
      return {
        ...state,
        loader: true
      };
    case `${SCHEDULE_FACULTY_REQUEST}${SUCCESS}`:
      return {
        ...state,
        faculty: action.payload
      };
    case `${SCHEDULE_CURS_REQUEST}${SUCCESS}`:
      return {
        ...state,
        curs: action.payload
      };
    case `${SCHEDULE_YEAR_REQUEST}${SUCCESS}`:
      return {
        ...state,
        year: action.payload
      };
    case `${SCHEDULE_REQUEST}${SUCCESS}`:
      return {
        ...state,
        loader: false,
        response: action.payload
      };
    case `${USER_DATA_REQUEST}${SUCCESS}`:
      return {
        ...state,
        userData: action.payload
      };
    case SCHEDULE_DATA_TO_STATE:
      return {
        ...state,
        user: action.data
      };

    default:
      return state;
  }
};

function* scheduleLoadSaga() {
  const userId = yield select(({auth}) => auth.data.mid);

  try {
    const resFaculty = yield call(getFacultyApi);
    const resCurs = yield call(getCursApi);
    const resYear = yield call(getYearApi);
    const resUser = yield call(getUserData, userId);
    yield put(scheduleActions.facultyRequestSuccess(resFaculty.data));
    yield put(scheduleActions.cursRequestSuccess(resCurs.data));
    yield put(scheduleActions.yearRequestSuccess(resYear.data));
    yield put(scheduleActions.userRequestSuccess(resUser.data));
  } catch (e) {
    console.log(e);
  }
}

function* scheduleRequestSaga() {
  const data = yield select(({ schedulePersonalReducer: { user } }) => user);
  try {
    const res = yield call(getScheduleApi, data);
    yield put(scheduleActions.requestRequestSuccess(res));
  } catch (e) {
    console.log(e);
  }
}

export function* scheduleFacultySagaWatcher() {
  yield takeLatest(`${SCHEDULE_LOAD_REQUEST}${START}`, scheduleLoadSaga);
  yield takeLatest(`${SCHEDULE_REQUEST}${START}`, scheduleRequestSaga);
}
