import React from 'react';
import PropTypes from 'prop-types';
import DataTableCol from "../../DataTable/Column";
import DataTable from "../../DataTable";
import styles from "./FundMovementTable.module.css";

const FundMovementTable = ({ data, title }) => {

  return (
    <div className={styles.container}>
      <h4 className={styles.tabletitle}>{title}</h4>
      <DataTable data={data} tableId={title} >
        <DataTableCol title="Тип" resolver={item => <>
          {item.orderstr ? <>{item.child_type}</> : <b>{item.child_type}</b>}
        </>} colSpan={20}/>
        <DataTableCol colSpan={10} title="Поступило наименований" value="received_items"/>
        <DataTableCol colSpan={10} title="Поступило экземпляров" value="received_copies"/>
        <DataTableCol colSpan={10} title="Выбыло наименований" value="retired_items" />
        <DataTableCol colSpan={10} title="Выбыло экземпляров" value="retired_copies" />
      </DataTable>
    </div>
  );
};

export default FundMovementTable;
