import React from 'react';
import PropTypes from 'prop-types';
import Input from "../../Input";
import classNames from 'classnames';

import styles from './ModalInputWrapper.module.css';

const InputWrapper = ({
  inputRef,
  isModal,
  label,
  type,
  value,
  onChange,
  name,
  children,
  error,
  additionalButtonOnClick,
  additionalButtonText,
  ...otherProps
}) => {

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        {label}
      </div>
      <div className={classNames(styles.inputContainer, error && styles.inputOnError)}>
        {children ? children : <>
          <Input ref={inputRef} isModal={isModal} type={type} name={name}
                 value={value} onChange={onChange} readOnly={value && !onChange}
                 style={styles.input}
            {...otherProps}
          />
        </>
        }
        {!!error && <span>! {error}</span>}
      </div>
      {additionalButtonOnClick &&
        <button className={styles.additionalButton} onClick={additionalButtonOnClick}>
          { additionalButtonText }
        </button>
      }
    </div>
  );
};

InputWrapper.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  additionalButtonOnClick: PropTypes.func,
  additionalButtonText: PropTypes.string
};

InputWrapper.defaultProps = {
  type: 'text',
};

export default InputWrapper;
