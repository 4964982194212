import React from 'react';
import PropTypes from 'prop-types';
import ModalWindow from "../../ModalWindow";
import {Col, Row} from "react-bootstrap";
import CoverPhotoPlaceholder from "../../Covers/List/Item/CoverPhotoPlaceholder";
import Button from "../../Button";
import {resolveDateWithTimeZone} from "../../../utils/Dates/resolveDateWithTimeZone";
import {Link} from "react-router-dom";
import styles from "../../Books/ListItem/BooksListItem.module.css";

const MessageModal = ({ data, setData, deleteItem }) => {

  const { bookId, coverImage, title, message, ts, id } = data;

  const messageDate = resolveDateWithTimeZone(ts);

  return (
    <ModalWindow
      title="Просмотр сообщения"
      onRequestClose={() => setData(null)}
      isOpen={true}
      width="600px"
    >
      <Row>
        {bookId &&
          <Col md={3} lg={2}>
            <CoverPhotoPlaceholder bookId={bookId} alt={title} url={coverImage}/>
          </Col>
        }
        <Col>
          <Link
              to={`/book/${data.bookid}`}
              target="_blank"
              className={styles.titleLink}
          >
            <h3>{ title }</h3>
          </Link>
          <div>
            <b className={'mr-1'}>Дата и время:</b>
            <span>{ messageDate }</span>
          </div>
          <div>
            <b className={'mr-1'}>Сообщение:</b>
            <span>{ message }</span>
          </div>
          <div className={'mt-2 d-flex justify-content-end'}>
            <Button colorType="red" label="Удалить сообщение" onClick={() => deleteItem(id)}/>
          </div>
        </Col>
      </Row>
    </ModalWindow>
  );
};

MessageModal.propTypes = {
  data: PropTypes.object.isRequired
};

export default MessageModal;