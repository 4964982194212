import React from 'react';
import PropTypes from 'prop-types';
import styles from './HavkinaTable.module.css';
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";

const HavkinaTable = ({ data }) => {
  return (
    <div className={styles.container}>
      <DataTable data={data}>
        <DataTableCol title="Индекс" value="index"/>
        <DataTableCol title="Текст" value="text"/>
      </DataTable>
    </div>
  );
};

HavkinaTable.propTypes = {
  data: PropTypes.array
};

export default HavkinaTable;
