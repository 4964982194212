import React from "react";
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import styles from "./GUK.module.css";
import SearchableSelect from "../../../Select";
import {categoriesToOptions, optionsToCategories} from "../../../../utils/toSelectOption";

const options_eerKindId = [
  { value: 0, label: "Не выбрано" },
  { value: 1, label: "Электронный документ (ЭД)" },
  { value: 2, label: "Электронное издание (ЭИ)" }
];
const options_eerTypeId = [
  { value: 0, label: "Не выбрано" },
  { value: 1, label: "Электронный аналог печатного издания" },
  { value: 2, label: "Самостоятельное электронное издание" }
];
const options_eerOksoId = [
  { value: 0, label: "Не выбрано" },
  { value: 1, label: "Заполнить список" }
];
const options_eerInfoTypeId = [
  { value: 0, label: "Не выбрано" },
  { value: 1, label: "Заполнить список" }
];
const options_eerTechPeriodId = [
  { value: 0, label: "Не выбрано" },
  { value: 1, label: "Непереодическое электронное издание" },
  { value: 2, label: "Сериальное электронное издание" },
  { value: 3, label: "Переодическое электронное издание" },
  { value: 4, label: "Продолжающееся электронное издание" },
  { value: 5, label: "Обновляемое электронное издание" }
];
const options_eerTechStructId = [
  { value: 0, label: "Не выбрано" },
  { value: 1, label: "Однотомное электронное издание" },
  { value: 2, label: "Многотомное электронное издание" },
  { value: 3, label: "Электронная серия" }
];
const options_eerOutPlagiaryId = [
  { value: 0, label: "Не выбрано" },
  { value: 1, label: "Проверено" },
  { value: 2, label: "Не проверено" }
];

const GUKTab = ({ data, setData, selectData }) => {

  const selectors = selectData.data;
  const onChange = ({ target: { value, name } }) => {
    setData({ ...data, [name]: value });
  };


  return (
    <div>
      <div className={styles.title}>
        Сведения о виде и типе электронного образовательного ресурса (ЭОР)
      </div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Вид ЭОР">
            <select
              className={styles.select}
              name="eerKindId"
              value={data.eerKindId}
              onChange={onChange}
            >
              {options_eerKindId.map((item , index) => (
                <option value={item.value} key={`eerKindId${index}`}>{item.label}</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Тип ЭОР">
            <select
              className={styles.select}
              name="eerTypeId"
              value={data.eerTypeId}
              onChange={onChange}
            >
              {options_eerTypeId.map((item, index) => (
                <option value={item.value} key={`eerTypeId${index}`}>{item.label}</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
      </Row>
      <ModalInputWrapper label="По новизне публикации">
        <SearchableSelect
          data={selectors.novPublication}
          setData={setData}
          placeholder="Выберите из списка"
          options={categoriesToOptions(selectors.novPublication)}
          isMulti={false}
          onChange={value =>
            setData({ ...data, err_nov_publication_id: value})
          }
          value={data.err_nov_publication_id}
        />
      </ModalInputWrapper>

      <ModalInputWrapper label="По природе основной информации">
        <SearchableSelect
          data={selectors.natureBasicInformation}
          setData={setData}
          placeholder="Выберите из списка"
          options={categoriesToOptions(selectors.natureBasicInformation)}
          isMulti={false}
          onChange={value =>
            setData({ ...data, err_nature_basic_information_id: value})
          }
          value={data.err_nature_basic_information_id}
        />
      </ModalInputWrapper>

      <ModalInputWrapper label="По повторности выпуска">
        <SearchableSelect
          data={selectors.repetitionRelease}
          setData={setData}
          placeholder="Выберите из списка"
          options={categoriesToOptions(selectors.repetitionRelease)}
          isMulti={false}
          onChange={value =>
            setData({ ...data, err_repetition_release_id: value})
          }
          value={data.err_repetition_release_id}
        />
      </ModalInputWrapper>

      <ModalInputWrapper label="По информационно-технологической конструкции">
        <SearchableSelect
          data={selectors.informationTechnologyDesign}
          setData={setData}
          placeholder="Выберите из списка"
          options={categoriesToOptions(selectors.informationTechnologyDesign)}
          isMulti={false}
          onChange={value =>
            setData({ ...data, err_information_technology_design_id: value})
          }
          value={data.err_information_technology_design_id}
        />
      </ModalInputWrapper>

      <ModalInputWrapper label="По способу установки">
        <SearchableSelect
          data={selectors.installationMethod}
          setData={setData}
          placeholder="Выберите из списка"
          options={categoriesToOptions(selectors.installationMethod)}
          isMulti={false}
          onChange={value =>
            setData({ ...data, err_installation_method_id: value})
          }
          value={data.err_installation_method_id}
        />
      </ModalInputWrapper>

      {data.err_repetition_release_id &&
        <ModalInputWrapper label="Вид образования">
          {data.err_repetition_release_id.value === 1 &&
            <SearchableSelect
              data={selectors.generalEducationLevels}
              setData={setData}
              placeholder="Выберите из списка"
              options={categoriesToOptions(selectors.generalEducationLevels)}
              isMulti={false}
              onChange={value =>
                setData({
                  ...data,
                  err_general_education_levels_id: value,
                  err_professional_education_levels_id: null,
                  err_subspecies_continuing_education_id: null
                })
              }
              value={data.err_general_education_levels_id}
            />
          }

          {data.err_repetition_release_id.value === 2 &&
          <SearchableSelect
            data={selectors.professionalEducationLevels}
            setData={setData}
            placeholder="Выберите из списка"
            options={categoriesToOptions(selectors.professionalEducationLevels)}
            isMulti={false}
            onChange={value =>
              setData({
                ...data,
                err_professional_education_levels_id: value,
                err_general_education_levels_id: null,
                err_subspecies_continuing_education_id: null
              })
            }
            value={data.err_professional_education_levels_id}
          />
          }

          {data.err_repetition_release_id.value === 3 &&
          <SearchableSelect
            data={selectors.subspeciesContinuingEducation}
            setData={setData}
            placeholder="Выберите из списка"
            options={categoriesToOptions(selectors.subspeciesContinuingEducation)}
            isMulti={false}
            onChange={value =>
              setData({
                ...data,
                err_professional_education_levels_id: null,
                err_general_education_levels_id: null,
                err_subspecies_continuing_education_id: value
              })
            }
            value={data.err_subspecies_continuing_education_id}
          />
          }
        </ModalInputWrapper>
      }

      <ModalInputWrapper label="Специальность по ФГОС ВО.">
        <SearchableSelect
          data={selectors.okso}
          setData={setData}
          placeholder="Выберите из списка"
          options={categoriesToOptions(selectors.okso)}
          isMulti={false}
          onChange={value =>
            setData({ ...data, eerOksoId: value})
          }
          value={data.eerOksoId}
        />
      </ModalInputWrapper>

      <ModalInputWrapper label="Информация об изменениях ЭОР">
        <SearchableSelect
          data={selectors.informationChangesAor}
          setData={setData}
          placeholder="Выберите из списка"
          options={categoriesToOptions(selectors.informationChangesAor)}
          isMulti={false}
          onChange={value =>
            setData({ ...data, err_information_changes_aor_id: value})
          }
          value={data.err_information_changes_aor_id}
        />
      </ModalInputWrapper>

      <div className={styles.title}>Сведения о назначении ЭОР</div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Целевое назначение ЭОР">
            <SearchableSelect
              data={selectors.purpose}
              setData={setData}
              placeholder="Выберите из списка"
              options={categoriesToOptions(selectors.purpose)}
              isMulti={true}
              onChange={value =>
                setData({ ...data, eer_purpose: optionsToCategories(value)})
              }
              value={categoriesToOptions(data.eer_purpose)}
            />
          </ModalInputWrapper>

        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Вид образования">
            <SearchableSelect
              data={selectors.purposeEducationLevels}
              setData={setData}
              placeholder="Выберите из списка"
              options={categoriesToOptions(selectors.purposeEducationLevels)}
              isMulti={false}
              onChange={value =>
                setData({ ...data, eer_purpose_education_level: value})
              }
              value={data.eer_purpose_education_level}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12}>

          <ModalInputWrapper label="Целевое назначение ЭОР по форме получения образования и форме обучения">
            <SearchableSelect
              data={selectors.purposeEducationForms}
              setData={setData}
              placeholder="Выберите из списка"
              options={categoriesToOptions(selectors.purposeEducationForms)}
              isMulti={true}
              onChange={value =>
                setData({ ...data, eer_purpose_education_form: optionsToCategories(value)})
              }
              value={categoriesToOptions(data.eer_purpose_education_form)}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Целевое назначение по элементам ОПОП или ДПП"
            name="eerPurposeEducationDetailssId"
            value={data.eerPurposeEducationDetailssId}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Целевая аудитория">
            <SearchableSelect
              data={selectors.purposeReaders}
              setData={setData}
              placeholder="Выберите из списка"
              options={categoriesToOptions(selectors.purposeReaders)}
              isMulti={true}
              onChange={value =>
                setData({ ...data, eer_purpose_reader: value})
              }
              value={data.eer_purpose_reader}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Целевое назначение по форме реализации ЭОР">
            <SearchableSelect
              data={selectors.purposeImplementations}
              setData={setData}
              placeholder="Выберите из списка"
              options={categoriesToOptions(selectors.purposeImplementations)}
              isMulti={true}
              onChange={value =>
                setData({ ...data, eer_purpose_implementation: value})
              }
              value={data.eer_purpose_implementation}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Кафедра"
            name="eerCathedra"
            value={data.eerCathedra}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Курс"
            name="eerClass"
            value={data.eerClass}
            onChange={onChange}
          />
        </Col>
      </Row>
      <div className={styles.title}>Сведения о содержании ЭОР</div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Общероссийская специальность образования">
            <select
              className={styles.select}
              name="eerOksoId"
              value={data.eerOksoId}
              onChange={onChange}
            >
              {options_eerOksoId.map((item, index) => (
                <option value={item.value} key={index}>{item.label}</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Тип основной информации">
            <select
              className={styles.select}
              name="eerInfoTypeId"
              value={data.eerInfoTypeId}
              onChange={onChange}
            >
              {options_eerInfoTypeId.map((item, index) => (
                <option value={item.value} key={index}>{ item.label }</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Литературный жанр">
            <SearchableSelect
              data={selectors.genres}
              setData={setData}
              placeholder="Выберите из списка"
              options={categoriesToOptions(selectors.genres)}
              isMulti={true}
              onChange={value =>
                setData({ ...data, eer_genre: value})
              }
              value={data.eer_genre}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Переодичность издания">
            <SearchableSelect
              data={selectors.techperiods}
              setData={setData}
              placeholder="Выберите из списка"
              options={categoriesToOptions(selectors.techperiods)}
              isMulti={false}
              onChange={value =>
                setData({ ...data, eerTechPeriodId: value})
              }
              value={data.eerTechPeriodId}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Группа литературы, к которой относится ЭОР"
            name="eerPurposeLiteratureGroupsId"
            value={data.eerPurposeLiteratureGroupsId}
            onChange={onChange}
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Язык оригинала"
            name="translation"
            value={data.translation}
            onChange={onChange}
          />
          <ModalInputWrapper
            label="Тема (раздел) дисциплины"
            name="theme_discipline"
            value={data.theme_discipline}
            onChange={onChange}
          />
          <ModalInputWrapper
            label="Учебный вопрос"
            name="training_question"
            value={data.training_question}
            onChange={onChange}
          />
          <ModalInputWrapper
            label="Программное обеспечение создания ЭОР"
            name="err_creation_software_aor"
            value={data.err_creation_software_aor}
            onChange={onChange}
          />
          <ModalInputWrapper
            label="Сведения о технической подготовке материалов для ЭОР (Только для литературных)"
            name="err_information_technical_preparation"
            value={data.err_information_technical_preparation}
            onChange={onChange}
          />
          <ModalInputWrapper
            label="Тираж, экз"
            name="err_edition"
            value={data.err_edition}
            onChange={onChange}
          />
          <ModalInputWrapper
            label="Процессор тип"
            name="processor_type"
            value={data.processor_type}
            onChange={onChange}
          />
          <ModalInputWrapper
            label="Процессор тактовая частота"
            name="processor_clock_speed"
            value={data.processor_clock_speed}
            onChange={onChange}
          />
          <ModalInputWrapper
            label="Объем дисковой памяти"
            name="disk_space"
            value={data.disk_space}
            onChange={onChange}
          />
          <ModalInputWrapper
            label="Объем оперативной памяти"
            name="ram_size"
            value={data.ram_size}
            onChange={onChange}
          />
          <ModalInputWrapper
            label="Браузер тип"
            name="browser_type"
            value={data.browser_type}
            onChange={onChange}
          />
          <ModalInputWrapper
            label="Версия браузера"
            name="browser_version"
            value={data.browser_version}
            onChange={onChange}
          />
          <ModalInputWrapper
            label="Надстройки"
            name="browser_addin"
            value={data.browser_addin}
            onChange={onChange}
          />
          <ModalInputWrapper
            label="Скорость подключения к информационно-телекоммуникационным сетям"
            name="speed_connection"
            value={data.speed_connection}
            onChange={onChange}
          />
          <ModalInputWrapper
            label="Переферийное оборудование"
            name="periphery_equipment"
            value={data.periphery_equipment}
            onChange={onChange}
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Наличие медиа"
            name="eerMedia"
            value={data.eerMedia}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Приложения"
            name="eerAppendix"
            value={data.eerAppendix}
            onChange={onChange}
          />
        </Col>
      </Row>
      <div className={styles.title}>Технологические характеристики ЭОР</div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Технология распространения" labelSpan={2} inputSpan={10}>
            <SearchableSelect
              data={selectors.techdisributions}
              setData={setData}
              placeholder="Выберите из списка"
              options={categoriesToOptions(selectors.techdisributions)}
              isMulti={true}
              onChange={value =>
                setData({ ...data, eer_tech_distribution: optionsToCategories(value)})
              }
              value={categoriesToOptions(data.eer_tech_distribution)}
            />
          </ModalInputWrapper>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Периодичность издания">
            <select
              className={styles.select}
              name="eerTechPeriodId"
              value={data.eerTechPeriodId}
              onChange={onChange}
            >
              {options_eerTechPeriodId.map(item => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Структура ЭОР">
            <select
              className={styles.select}
              name="eerTechStructId"
              value={data.eerTechStructId}
              onChange={onChange}
            >
              {options_eerTechStructId.map(item => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
      </Row>
      <div className={styles.title}>Выпускные сведения</div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Характер взаимодействия пользователя и ЭОР">
            <SearchableSelect
              data={selectors.techCommunics}
              setData={setData}
              placeholder="Выберите из списка"
              options={categoriesToOptions(selectors.techCommunics)}
              isMulti={false}
              onChange={value =>
                setData({ ...data, eerTechCommunicId: value})
              }
              value={data.eerTechCommunicId}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Продолжительность звуковых и видеофрагментов"
            name="eerOutDurationMin"
            value={data.eerOutDurationMin}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Комплектность ЭОР"
            name="eerOutKit"
            value={data.eerOutKit}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Регистрационный номер и регистрирующий орган"
            name="eerOutRegistration"
            value={data.eerOutRegistration}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Вид носителя">
            <SearchableSelect
              data={selectors.outCarriers}
              setData={setData}
              placeholder="Выберите из списка"
              options={categoriesToOptions(selectors.outCarriers)}
              isMulti={false}
              onChange={value =>
                setData({ ...data, eerOutCarrierId: value})
              }
              value={data.eerOutCarrierId}
            />
          </ModalInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Статус ЭОР">
            <SearchableSelect
              data={selectors.outStatus}
              setData={setData}
              placeholder="Выберите из списка"
              options={categoriesToOptions(selectors.outStatus)}
              isMulti={false}
              onChange={value =>
                setData({ ...data, eerOutStatusId: value})
              }
              value={data.eerOutStatusId}
            />
          </ModalInputWrapper>
        </Col>
      </Row>



      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Кем присвоен статус ЭОР"
            name="eerOutStatusWho"
            value={data.eerOutStatusWho}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Когда присвоен статус ЭОР"
            name="eerOutStatusWhen"
            value={data.eerOutStatusWhen}
            onChange={onChange}
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Номер государственной регистрации"
            name="eerOutGovernmentRegNum"
            value={data.eerOutGovernmentRegNum}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Штрих-коды"
            name="eerOutBarCodes"
            value={data.eerOutBarCodes}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Знак охраны авторского права"
            name="eerOutCopyrightNotice"
            value={data.eerOutCopyrightNotice}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper label="Результат проверки на заимствование (антиплагиат)">
            <select
              className={styles.select}
              name="eerOutPlagiaryId"
              value={data.eerOutPlagiaryId}
              onChange={onChange}
            >
              {options_eerOutPlagiaryId.map(item => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
          </ModalInputWrapper>
        </Col>
      </Row>
      <div className={styles.title}>Минимальные системные требования</div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Требования к компьютеру"
            name="eerReqComputer"
            value={data.eerReqComputer}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Требования к операционной системе"
            name="eerReqOS"
            value={data.eerReqOS}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Требования к видеосистеме"
            name="eerReqVideo"
            value={data.eerReqVideo}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Требования к акустической системе"
            name="eerReqAudio"
            value={data.eerReqAudio}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Необходимое дополнительное программное обеспечение (не входящее в состав электронного издания) и оборудование"
            name="eerReqAdditSoftware"
            value={data.eerReqAdditSoftware}
            onChange={onChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default GUKTab;
