import { SORT_DIRECTION } from "../../constants/sortDirections";
import { api } from "../../App";

export const getUsersList = (orderBy, maxResults, paginateFrom, search, sortDirection) => {
  const params = {
    maxResults,
    paginateFrom,
    search,
    [sortDirection === SORT_DIRECTION.ASC ? 'orderBy' : 'orderByD']: orderBy
  };

  return api.get('/people', { params });
};

export const getUsersCount = (search) => {
  const params = {
    count: true, search
  };

  return api.get('/people', { params });
};

export const addUser = (data) => {
  return api.post('/people', data);
};

export const editUser = (data) => {
  return api.put(`/people/${data.mid}`, data);
};

export const deleteUser = (mid) => {
  return api.delete(`/people/${mid}`);
};

export const changeUserPassword = (mid, password) => {
  return api.put(`/people/password/${mid}`, { password })
};

export const getUserPhoto = (mid) => {
  const config = {
    responseType: 'arraybuffer'
  };

  return api.get(`/people/photo/${mid}`, config);
};

export const saveUserPhoto = (photo, mid) => {
  const formData = new FormData();
  formData.append("photo", photo);

  const headers = [
    { 'Content-Type': 'multipart/form-data' }
  ];

  return api.post(`/people/photo/${mid}`, formData, { headers });
};

export const deleteUserPhoto = (mid) => {
  return api.delete(`/people/photo/${mid}`);
};
