import { ALPHABET_RANGE_REQUEST, SUCCESS } from "../../../../actions/action-types";

const initialState = [];

export const alphabetRangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${ALPHABET_RANGE_REQUEST}${SUCCESS}`:
      return action.payload;

    default:
      return state;
  }
};