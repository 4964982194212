import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
    NEW_BOOKS_ORDER_LIST_REQUEST,
    NEW_BOOKS_ORDER_BOOKS_EDIT_REQUEST,
    NEW_BOOKS_ORDER_BOOKS_DELETE_REQUEST,
    START
} from "../../../actions/action-types";
import {
    getNewBookOrderBooksList,
    addBookToDocument,
    editBookToDocument,
    deleteBookToDocument
} from "../../../../api/newBooksOrder/orderToDocument";
import {
    newBooksOrderListRequestStart
} from "../../../actions/newBooksOrder/orders/list";
import {
    documentBookListRequestStart,
    documentBookListRequestError,
    documentBookListRequestSuccess
} from "../../../actions/newBooksOrder/list/list";
import {
    orderBooksEditRequestSuccess,
    orderBooksEditRequestError
} from "../../../actions/newBooksOrder/orderItem/edit";
import {
    orderBooksDeleteRequestSuccess,
    orderBooksDeleteRequestError
} from "../../../actions/newBooksOrder/orderItem/delete";

export default function* documentBooksWatcher() {
    yield takeLatest(`${NEW_BOOKS_ORDER_LIST_REQUEST}${START}`, documentBooksListWorker);
    yield takeLatest(`${NEW_BOOKS_ORDER_BOOKS_EDIT_REQUEST}${START}`, documentBookEditWorker);
    yield takeLatest(`${NEW_BOOKS_ORDER_BOOKS_DELETE_REQUEST}${START}`, documentBookDeleteWorker);
}

function* documentBooksListWorker({ payload: documentId }) {
    try {
        const response = yield call(getNewBookOrderBooksList, documentId);
        yield put(documentBookListRequestSuccess(response.data));
        yield put(newBooksOrderListRequestStart());
    } catch (error) {
        yield put(documentBookListRequestError(error));
    }
}

function* documentBookEditWorker({ payload: data }) {
    try {
        const response = yield call(data.bookData.id ? editBookToDocument : addBookToDocument, data);
        yield put(orderBooksEditRequestSuccess(response.data));
        yield put(documentBookListRequestStart(data.document));
    } catch (error) {
        yield put(orderBooksEditRequestError(error));
    }
}

function* documentBookDeleteWorker({ payload: data }) {
    try {
        const response = yield call(deleteBookToDocument, data.bookId);
        yield put(orderBooksDeleteRequestSuccess(response.data));
        yield put(documentBookListRequestStart(data.document));
    } catch (error) {
        yield put(orderBooksDeleteRequestError(error));
    }
}
