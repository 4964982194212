import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { START, USERS_DELETE_REQUEST } from "../../../actions/action-types";
import { deleteUser } from "../../../../api/adminUsers/users";
import { usersDeleteRequestError, usersDeleteRequestSuccess } from "../../../actions/adminUsers/delete";
import { usersCountRequestStart } from "../../../actions/adminUsers/count";

export default function* deleteUserWatcher() {
  yield takeLatest(`${USERS_DELETE_REQUEST}${START}`, deleteUserWorker)
}

function* deleteUserWorker({payload: id}) {
  try {
    const response = yield call(deleteUser, id);
    yield put(usersDeleteRequestSuccess(response.data));
    yield put(usersCountRequestStart());
  } catch (error) {
    yield put(usersDeleteRequestError(error))
  }
}