import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {reportGetRequestStart} from "../../../redux/actions/reports/get";

const mapStateToProps = ({ reports }) => ({
    data: reports.report,
    loader: reports.loader
});

const mapDispatchToProps = {
    requestReport: reportGetRequestStart
};

const StaticInformationReportScene = ({ ownId, data, loader, requestReport }) => {



    return (
        <div>
            StaticInformationReportScene
        </div>
    );
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StaticInformationReportScene);