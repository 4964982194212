import React, {useEffect, useState} from 'react';
import {collectionsListRequestStart} from "../../../redux/actions/collections/list";
import {collectionsSaveRequestStart} from "../../../redux/actions/collections/save";
import {collectionsDeleteRequestStart} from "../../../redux/actions/collections/delete";
import CollectionsModal from "../../../components/Collections/Modal";
import CollectionsTable from "../../../components/Collections/Table";
import {connect} from "react-redux";
import {collectionResetData} from "../../../redux/actions/collections/data/resetData";
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";

const mapStateToProps = ({collections}) => ({
    list: collections.list
});

const mapDispatchToProps = {
    requestList: collectionsListRequestStart,
    saveCollection: collectionsSaveRequestStart,
    deleteCollection: collectionsDeleteRequestStart,
    resetCollectionData: collectionResetData
};


const MODAL_FORM_FIELDS = {
    name: "",
    ctype: 0,
};

const CollectionsScene = ({list, requestList, saveCollection, deleteCollection, resetCollectionData}) => {
    useEffect(() => {
        requestList()
    }, [requestList]);

    const [modalData, setModalData] = useState({modalType: null, data: null});
    const [selectedId, setSelectedId] = useState(null);

    const onSubmitModal = (values) => {
        saveCollection({...modalData, data: {...modalData.data, ...values}}, closeModal);

    };

    const handleSelect = (id) => setSelectedId(selectedId === id ? null : id);

    const onDelete = () =>
        selectedId && deleteCollection(selectedId);

    const closeModal = () => {
        setModalData({modalType: null, data: null});
        resetCollectionData()
    };

    return (
        <SceneContainer title={'Справочники / Коллекции'}>
            {modalData.modalType &&
            <CollectionsModal onSubmit={onSubmitModal} modalData={modalData} setModalData={setModalData}
                              onCloseModal={closeModal}
                              resetCollectionData={resetCollectionData}/>
            }
            <CollectionsTable data={list} setModal={setModalData}
                              selectedId={selectedId}
                              handleSelect={handleSelect}
                              initialFormFields={MODAL_FORM_FIELDS}
                              onDelete={onDelete}/>
        </SceneContainer>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsScene);
