import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './BookItem.module.css';
import Input from "../../../../../Input";

const FundMovingBookItem = ({ bookItem, fundsList, distributionData, distributedBooks }) => {

  const calcSum = (arr) => {
    return arr.reduce(function(sum, current) {
      return sum + Number(current["amount"]);
    }, 0);
  };

  const createInitialState = () => {
    const updatedFundList = fundsList.map(item => ({ id: item.id, name: item.name, amount: 0 }));

    if(distributedBooks && !!distributedBooks.length) {
      let currentBook = distributedBooks.find(item => item.bookid == bookItem.bookid);
      currentBook.funds.map(item => {
        let findedIndex = updatedFundList.findIndex(el => el.id === item.id);
        updatedFundList[findedIndex].amount = item.amount;
      });
    }

    return {
      sum: calcSum(updatedFundList),
      amount: bookItem.amount,
      funds: [...updatedFundList]
    };
  };

  const onChange = (e, index) => {
    let updatedState = Object.assign({}, distributionList);
    updatedState.funds[index]['amount'] = Number(e.target.value);
    updatedState.sum = calcSum(updatedState.funds);
    setDistributionList(updatedState);
  };

  const[distributionList, setDistributionList] = useState(createInitialState());
  distributionData[bookItem.bookid] = distributionList;
  const isSumExceeded = distributionList.sum > bookItem.amount;
  const isSumEquals = distributionList.sum === bookItem.amount;
  console.log(distributionList);

  return (
    <tr>
      <td>{bookItem.title}</td>
      <td>{bookItem.amount}</td>
      {distributionList.funds.map((item, index) => (
          <td key={index}>
            <Input
              value={item.amount}
              type="number"
              onChange={(e) => onChange(e, index)}
            />
          </td>
        )
      )}
      <td className={classNames(
        isSumExceeded && styles.redNotification,
        isSumEquals && styles.greenNotification
      )}>{distributionList.sum}</td>
    </tr>
  );
};

FundMovingBookItem.propTypes = {
  bookItem: PropTypes.object.isRequired,
  fundsList: PropTypes.array.isRequired
};

export default FundMovingBookItem;
