import {call, put, select, takeLatest} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
    START,
    INVENTORY_COVERS_COUNT_REQUEST,
    INVENTORY_COVERS_LIST_REQUEST
} from "../../actions/action-types";
import {coversCountRequestError, coversCountRequestSuccess} from "../../actions/covers/count";
import {
    countCovers, countSimpleCoversSearch,
    searchCovers, simpleCoversSearch,
} from "../../../api/covers/search";
import {setCoversSearchData} from "../../actions/covers/search";
import {coversListRequestError, coversListRequestStart, coversListRequestSuccess} from "../../actions/covers/list";
import {inventoryCoversCountRequestSuccess} from "../../actions/inventoryCovers/count";
import {setInventoryCoversSearchData} from "../../actions/inventoryCovers/search";
import {inventoryCoversListRequestStart, inventoryCoversListRequestSuccess} from "../../actions/inventoryCovers/list";

export default function* inventoryCoversRequestWatcher() {
    yield takeLatest(`${INVENTORY_COVERS_COUNT_REQUEST}${START}`, coversCountWorker);
    yield takeLatest(`${INVENTORY_COVERS_LIST_REQUEST}${START}`, coversListWorker);
}

function* coversCountWorker({payload: data}) {
    const searchData = yield select(({inventoryCovers}) => inventoryCovers.search);
    const formData = yield select(({inventoryCovers}) => inventoryCovers.form);

    try {
        const response = yield call(countSimpleCoversSearch, formData.title ? formData.title : '');
        const count = parseInt(response.data.count, 10);
        yield put(inventoryCoversCountRequestSuccess(count));
        const pageCount = Math.ceil(count / searchData.maxResults);
        yield put(setInventoryCoversSearchData({...searchData, pageCount}));
        yield put(inventoryCoversListRequestStart(formData.title));
    } catch (error) {
        yield put(coversCountRequestError(error));
    }
}

function* coversListWorker({payload: data}) {
    const searchData = yield select(({inventoryCovers}) => inventoryCovers.search);
    const formData = yield select(({inventoryCovers}) => inventoryCovers.form);

    searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
    try {
        const response = yield call(simpleCoversSearch, formData.title ? formData.title : '', searchData);
        yield put(inventoryCoversListRequestSuccess(response.data));
    } catch (error) {
        yield put(coversListRequestError(error));
    }
}