import React, { useEffect } from "react";
import { connect } from "react-redux";
import {Col, Row} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import {EDIT} from "../../../../constants/modalTypes";
import DocumentInstancesTable from "../../InstancesTable";
import Button from "../../../Button";
import BookSearchForm from "../../../Covers/SearchForm";
import styles from "../DocumentsModal.module.css";
import InvNumbersItem from "../InvNumbersItem";
import {documentInstanceDeleteRequestStart} from "../../../../redux/actions/documents/instances/delete";
import {setInvNumbersFormData} from "../../../../redux/actions/invNumbers/form";
import {documentInstancesListRequestStart} from "../../../../redux/actions/documents/instances/list";
import {invNumbersCountRequestStart} from "../../../../redux/actions/invNumbers/count";
import {createNotification} from "../../../notification/notification";
import {documentInstancesAddRequestStart} from "../../../../redux/actions/documents/instances/add";


const mapStateToProps = ({ documents, invNumbers }) => ({
    inventary: documents.inventary,
    invFormData: invNumbers.form,
    invList: invNumbers.list,
    loader: invNumbers.loader,
});

const mapDispatchToProps = dispatch => ({
    addInstance: data => dispatch(documentInstancesAddRequestStart(data)),
    deleteInstance: data => dispatch(documentInstanceDeleteRequestStart(data)),
    setInvFormData: (data) => dispatch(setInvNumbersFormData(data)),
    requestInstances: documentId => dispatch(documentInstancesListRequestStart(documentId)),
    startInvNumbersSearch: () => dispatch(invNumbersCountRequestStart()),
});

const InstancesEdit = ({inventary, requestInstances, addInstance, modalData, deleteInstance, currentBook, onAddBook,
                           invFormData, setInvFormData, loader, startInvNumbersSearch, invList, searchOpen}) => {
    const { modalType, data } = modalData;
    const documentId = data ? data.id : null;

    useEffect(() => {
        (documentId && modalType === EDIT) && requestInstances(documentId);
    }, [modalType, requestInstances, documentId]);


    const onInvNumberDelete = (data) => {
        const confirm = window.confirm(`Вы действительно хотите удалить инвентарный номер ${data.number} из документа?`);
        if(confirm) {
            deleteInstance(data);
        }
    };

    const onInvNumberAdd = (data) => {
        data.documentId = modalData.data.id;
        addInstance(data);
    };

    return (
        <Col md={12}>
            <div className={styles.booksSection}>
                <div className={styles.title}>
                    Инвентарные номера
                </div>
                {modalType === EDIT && <DocumentInstancesTable data={inventary}
                                        modalData={modalData}
                                        handleSelect={onInvNumberDelete}
                                        selectedId={currentBook ? currentBook.id : null}/>
                }
            </div>
            <Button label={!searchOpen ? "Добавить книгу" : "Отменить"}
                    style={styles.searchButton}
                    onClick={onAddBook}/>
            {searchOpen && <>
                <BookSearchForm data={invFormData} setData={setInvFormData} hideInstitutions={true}>
                    <Row>
                        <Col md={{ span: 3, offset: 9 }} className={"text-right"}>
                            <Button onClick={startInvNumbersSearch} label={"Найти"}/>
                        </Col>
                    </Row>
                </BookSearchForm>
                {loader && (
                    <div className={styles.spinnerContainer}>
                        <Spinner animation="grow" variant="primary"/>
                    </div>
                )}
                {invList.length ? <>
                    <div className={styles.title}>
                        Результаты поиска
                    </div>
                    {invList.map((item, index) => (
                        <InvNumbersItem data={item}
                                        key={index}
                                        onClick={onInvNumberAdd}/>
                    ))}
                </>:''}
            </>}
        </Col>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(InstancesEdit);
