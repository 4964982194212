import React from 'react';
import PropTypes from 'prop-types';
import styles from './BookLoanSpecialityList.module.css';
import BookLoanSpecialityItem from "./Item";

const BookLoanSpecialityList = ({data}) => {
  return (
    <div className={styles.container}>
      {data.map((item, index) =>
        <BookLoanSpecialityItem data={item} key={index}/>
      )}
    </div>
  );
};

BookLoanSpecialityList.propTypes = {
  data: PropTypes.array
};

export default BookLoanSpecialityList;
