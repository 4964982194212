import React, { useEffect, useState } from 'react';
import styles from './RubricatorScene.module.css';
import { Col, Row } from "react-bootstrap";
import { rubricatorCountRequestStart } from "../../../redux/actions/rubricator/count";
import { setRubricatorSearchData } from "../../../redux/actions/rubricator/search";
import { connect } from "react-redux";
import Search from "../../../components/Search";
import Pagination from "../../../components/Pagination";
import RubricatorTable from "../../../components/Rubricator/Table";
import RubricatorModal from "../../../components/Rubricator/Modal";
import { rubricatorSaveRequestStart } from "../../../redux/actions/rubricator/save";
import { rubricatorDeleteRequestStart } from "../../../redux/actions/rubricator/delete";

const mapStateToProps = ({ rubricator }) => ({
  searchData: rubricator.search,
  list: rubricator.list,
  count: rubricator.count
});

const mapDispatchToProps = {
  requestList: rubricatorCountRequestStart,
  setSearchData: setRubricatorSearchData,
  saveRubricatorData: rubricatorSaveRequestStart,
  deleteRubricatorData: rubricatorDeleteRequestStart
};

const MODAL_FORM_FIELDS = {
  name: "",
  parentId: 0
};

const RubricatorScene = ({
      count,
      list,
      requestList,
      searchData,
      setSearchData,
      saveRubricatorData,
      deleteRubricatorData
}) => {

  useEffect(() => {
    requestList()
  }, [requestList]);

  const [modalData, setModalData] = useState({ modalType: null, data: null });
  const [selectedId, setSelectedId] = useState(null);

  const onSubmitModal = (values) => {
    saveRubricatorData({...modalData, data: {...modalData.data, ...values}}, closeModal);
  };

  const closeModal = () => {
    setModalData({ modalType: null, data: null });
  };

  const handleSelect = (id) => setSelectedId(selectedId === id ? null : id);

  return (
    <div className={styles.container}>
      <Row>
        <Col md={6}>
          <div className={styles.title}>
            Каталогизация / Рубрикатор
          </div>
        </Col>
        <Col md={6}>
          <Search value={searchData.query} onChange={query => setSearchData({ ...searchData, query })}
                  onSubmit={requestList} placeholder={"поиск"} />
        </Col>
        <Col md={12}>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={(page) => {
            setSearchData({ ...searchData, page });
            requestList()
          }}/>
        </Col>
        <Col md={12}>
          {modalData.data &&
            <RubricatorModal
              modalData={modalData}
              setModalData={setModalData}
              onSubmit={onSubmitModal}
              closeModal={closeModal}
            />
          }

          <RubricatorTable data={list} setModal={setModalData} selectedId={selectedId} handleSelect={handleSelect}
                           initialFormFields={MODAL_FORM_FIELDS} onDelete={() => deleteRubricatorData(selectedId)}/>
        </Col>
        <Col md={12}>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={(page) => {
            setSearchData({ ...searchData, page });
            requestList()
          }}/>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RubricatorScene);
