import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { LIBRARY_LIST_REQUEST, START } from "../../../../actions/action-types";
import { libraryList } from "../../../../../api/library/library";
import { libraryListRequestError, libraryListRequestSuccess } from "../../../../actions/library/info/list";

export default function* libraryListWatcher() {
  yield takeLatest(`${LIBRARY_LIST_REQUEST}${START}`, libraryListWorker)
}

function* libraryListWorker({ payload: idclient }) {
  const searchData = yield select(({library}) => library.info.search);
  try {
    const response = yield call(libraryList, searchData.orderBy, idclient);
    yield put(libraryListRequestSuccess(response.data));
  } catch (error) {
    yield put(libraryListRequestError(error));
  }
}