import { SCHEDULER_DELETE_REQUEST, START, SUCCESS, ERROR } from "../../action-types";
import {createNotification} from "../../../../components/notification/notification";

export const schedulerDeleteRequestStart = (id) => ({
  type: `${SCHEDULER_DELETE_REQUEST}${START}`,
  payload: id
});

export const schedulerDeleteRequestSuccess = () => {
  createNotification(
    'success',
    `Успешно удалено`
  );

  return {
    type: `${SCHEDULER_DELETE_REQUEST}${SUCCESS}`
  }
};

export const schedulerDeleteRequestError = (error) => {
  createNotification(
    'error',
    `Ошибка удаления. Статус: ${error.response.status}`
  );

  return {
    type: `${SCHEDULER_DELETE_REQUEST}${ERROR}`,
    payload: error
  };
};
