export const readerMenuStructure = [
    {
        title: "Главная",
        link: "/",
    },
    {
        title: "Книги",
        link: "/bookScanOrders",
    },
    {
        title: "Документы",
        link: "/librarymaretials/7",
    },
    {
        title: "Презентации",
        link: "/librarymaretials/4",
    },
    {
        title: "Аудио",
        link: "/librarymaretials/6",
    },
    {
        title: "Видео",
        link: "/librarymaretials/5",
    },
    {
        title: "3D модели",
        link: "/librarymaretials/10",
    },
    {
        title: "Карты",
        link: "/librarymaretials/14",
    },
    {
        title: "Периодика",
        link: "/librarymaretials/11",
    },
    {
        title: "Научные работы",
        link: "/librarymaretials/13",
    },
    {
        title: "Руководство пользователя",
        link: "/wip",
    },
    {
        title: "Электронные ресурсы",
        link: "/external",
        dropdown: [
            {
                title: "Внешние информационные ресурсы",
                link: "/external"
            },
            {
                title: "Электронный читальный зал",
                link: "/reading"
            },
            {
                title: "Поиск ресурсов в сторонней АБИС",
                link: "/external_resources"
            },
            {
                title: "Поиск по картинке",
                link: "/search_by_image"
            }
        ]
    },
    {
        title: "Личный кабинет",
        link: authData => `/library_card/${authData.mid}`,
        dropdown: [
            {
                title: "Читательский билет",
                link: authData => `/library_card/${authData.mid}`
            },
            {
                title: "Профессорско-преподавательский состав",
                link: "/teachers"
            },
            {
                title: "Личное расписание",
                link: "/schedule_on_date"
            },
            {
                title: "Расписание",
                link: "/schedule"
            },
            {
                title: "Статистика посещаемости",
                link: "/turnout_stats"
            },
            {
                title: "Прохождение электронных курсов",
                link: "/electronic_courses"
            },
            {
                title: "Прохождение электронного тестирования",
                link: "/tests",
            },
            {
                title: "Поисковые запросы",
                link: "/search_audit"
            },
            {
                title: "Индивидуальные задания",
                link: "/student_projects"
            },
            {
                title: "Проверка на заимствования",
                link: "/borrowing_test"
            },
            {
                title: "Медиа портал",
                link: "/media_resource"
            },
            {
                title: "Уведомления",
                link: "/message"
            },
            {
                title: "Заказы на загрузку",
                link: "/book_transfer"
            },
            {
                title: "Электронная зачетка",
                link: "/credits"
            },
            {
                title: "Заказы на сканирование",
                link: "/book_scan_orders",
            },
        ]
    }
];
