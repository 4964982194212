import React from 'react';
import PropTypes from 'prop-types';


import Button from "../../../Button";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {Spinner} from "react-bootstrap";

import PDFDocument from './Document';


const PrintDestroyAct = ({}) => {
    return (
        <PDFDownloadLink
            document={
                <PDFDocument/>
            }
            fileName={`Акт уничтожения носителей сведений, составляющих государственную тайну.pdf`}
        >
            {({blob, url, loading, error}) => (loading ?
                    <Spinner animation={'grow'} />
                    :
                    <Button
                        label={'Печать акта уничтожения'}
                        style={'ml-3'}
                        colorType={'dark-blue'}
                    />
            )}
        </PDFDownloadLink>
    );
};

PrintDestroyAct.propTypes = {};

export default PrintDestroyAct;