import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { ROOM_DELETE_REQUEST, ROOM_SAVE_REQUEST, ROOMS_LIST_REQUEST, START } from "../../../actions/action-types";
import {
  roomsListRequestError,
  roomsListRequestStart,
  roomsListRequestSuccess
} from "../../../actions/location/rooms/list";
import { addRoom, deleteRoom, editRoom, getRoomsList } from "../../../../api/location/rooms";
import { roomSaveRequestError, roomSaveRequestSuccess } from "../../../actions/location/rooms/save";
import { roomDeleteRequestError, roomDeleteRequestSuccess } from "../../../actions/location/rooms/delete";

export default function* roomsListWatcher() {
  yield takeLatest(`${ROOMS_LIST_REQUEST}${START}`, roomsListWorker);
  yield takeLatest(`${ROOM_SAVE_REQUEST}${START}`, roomSaveWorker);
  yield takeLatest(`${ROOM_DELETE_REQUEST}${START}`, roomDeleteWorker);
}

function* roomsListWorker({ payload: libraryId }) {
  try {
    const response = yield call(getRoomsList, libraryId);
    yield put(roomsListRequestSuccess(response.data));
  } catch (error) {
    yield put(roomsListRequestError(error));
  }
}

function* roomSaveWorker({ payload: { libraryId, data } }) {
  try {
    const response = yield call(data.id ? editRoom : addRoom, libraryId, data);
    yield put(roomSaveRequestSuccess(response.data));
    yield put(roomsListRequestStart(libraryId));
  } catch (error) {
    yield put(roomSaveRequestError(error));
  }
}

function* roomDeleteWorker({ payload: { libraryId, roomId } }) {
  try {
    const response = yield call(deleteRoom, libraryId, roomId);
    yield put(roomDeleteRequestSuccess(response.data));
    yield put(roomsListRequestStart(libraryId));
  } catch (error) {
    yield put(roomDeleteRequestError(error));
  }
}