import React from "react";
import styles from "./OpenPhotoModal.module.css";

const OpenPhotoModal = ({ visible, src, onClose }) => {
  return (
    <div
      className={styles.container}
      style={{
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? "all" : "none"
      }}
      onClick={onClose}
    >
      <div className={styles.closeButton} onClick={onClose}>
        &#10005;
      </div>
      <img
        onClick={() => null}
        alt="Картинка"
        src={src}
        className={styles.image}
      />
    </div>
  );
};

export default OpenPhotoModal;
