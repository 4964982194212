import React from 'react';
import PropTypes from 'prop-types';
import styles from './BBKList.module.css';
import BBKListItem from "./Item";

const BBKList = ({ data, fetchSubList, collapseList, onSelect }) => {

  const createSublist = (data, level) => {
    return data.map((item, index) => <React.Fragment key={index}>
      <BBKListItem fetchSubList={fetchSubList} data={item} level={level} collapseList={collapseList} onSelect={onSelect}/>
      {item.subList && createSublist(item.subList, level + 1)}
    </React.Fragment>)
  };

  return (
    <div className={styles.container}>
      <table>
        <thead>
        <tr>
          <th colSpan={6}>Код ББК</th>
          <th colSpan={17}>Описание</th>
          <th colSpan={8}>Примечания</th>
        </tr>
        </thead>
        <tbody>
          {createSublist(data, 1)}
        </tbody>
      </table>
    </div>
  );
};

BBKList.propTypes = {
  data: PropTypes.array.isRequired,
  fetchSubList: PropTypes.func.isRequired,
  collapseList: PropTypes.func.isRequired
};

export default BBKList;
