import React from 'react';
import PropTypes from 'prop-types';
import styles from './OptionsLogoWrapper.module.css';

const OptionsLogoWrapper = ({ url, ...props }) => {
  return (
    <div className={styles.container} {...props}>
      <div className={styles.label}>
        Логотип
      </div>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={url} alt={""}/>
      </div>
    </div>
  );
};

OptionsLogoWrapper.propTypes = {
  url: PropTypes.string
};

export default OptionsLogoWrapper;
