import React from 'react';
import PropTypes from 'prop-types';
import styles from './BookEditFiles.module.css';
import classNames from 'classnames';


const LoadForm = ({ onUpload }) => {
  return (
    <div className={classNames(styles.loadForm, 'd-flex justify-content-end')}>
      <label>
        <input
          type="file"
          className={styles.fileInput}
          onChange={(e) => onUpload(e.target.files[0])}
        />
      </label>

    </div>
  );
};

LoadForm.propTypes = {
  onUpload: PropTypes.func
};

export default LoadForm;