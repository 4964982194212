import React, {useState} from "react";
import {connect} from "react-redux";
import {
    setBookScanPlanSearchData,
    bookScanPlanActions, setBookScanPlanFormData, bookScanOrderEditRequestStart
} from "../../redux/factory/bookScanPlanReduxData";
import Pagination from "../../components/Pagination";
import List from "../../components/BookScanPlan/List";
import {cleanBookCover, saveBookCover, savePdf} from "../../redux/actions/bookData/data";
import SearchForm from "../../components/BookScanPlan/SearchForm";
import Modal from "../../components/BookScanPlan/Modal";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";


const mapStateToProps = ({bookScanPlan, options}) => ({
    searchData: bookScanPlan.search,
    formData: bookScanPlan.form,
    count: bookScanPlan.count,
    list: bookScanPlan.list,
    options: options.data,
});

const matchDispatchToProps = {
    uploadPdf: savePdf,
    setSearchData: setBookScanPlanSearchData,
    setFormData: setBookScanPlanFormData,
    requestList: bookScanPlanActions.countRequestStart,
    editBookScanOrder: bookScanOrderEditRequestStart,
    uploadBookCover: saveBookCover,
    cleanBookCover: cleanBookCover,
};

export const STATUS_LIST = new Map([
    [1, 'новый'],
    [2, 'в работе'],
    [3, 'завершено'],
    [4, 'отменено'],
]);

const BookScanPlan = ({
                          searchData,
                          setSearchData,
                          count,
                          requestList,
                          list,
                          uploadPdf,
                          formData,
                          setFormData,
                          editBookScanOrder,
                          uploadBookCover,
                          cleanBookCover,
                          options
                      }) => {
    const [modalData, setModalData] = useState(null);

    const setCurrentPage = page => {
        setSearchData({...searchData, page});
        requestList();
    };

    const openModal = (item) => {
        setModalData(item);
    };

    const closeModal = () => {
        setModalData(null);
    };

    const editStatus = () => {
        const params = {
            orderId: modalData.id,
            status: Number(modalData.status),
            reason: modalData.reason
        };

        editBookScanOrder(params);
    };

    const onUploadBookCover = ({file, bookId}) => {
        uploadBookCover({file, bookId});
        requestList();
        setModalData({...modalData, coverImage: file.name});
    };

    const onCleanBookCover = ({bookId}) => {
        cleanBookCover({bookId});
        requestList();
        setModalData({...modalData, coverImage: null})
    };

    return (
        <SceneContainer title={'План оцифровки бумажных носителей'}>
            <SearchForm
                formData={formData}
                setFormData={setFormData}
                onSubmit={requestList}
            />

            <Pagination
                count={count}
                pageCount={searchData.pageCount}
                current={searchData.page}
                setCurrent={setCurrentPage}
            />

            {modalData &&
            <Modal
                data={modalData}
                setData={setModalData}
                setClosed={closeModal}
                editStatus={editStatus}
                cleanBookCover={onCleanBookCover}
                onUploadBookCover={onUploadBookCover}
                options={options}
            />
            }

            {!!list.length &&
                <List data={list} uploadPdf={uploadPdf} openModal={openModal}/>
            }

            <Pagination
                count={count}
                pageCount={searchData.pageCount}
                current={searchData.page}
                setCurrent={setCurrentPage}
            />
        </SceneContainer>
    );
};

export default connect(
    mapStateToProps,
    matchDispatchToProps
)(BookScanPlan);
