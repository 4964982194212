import {
  LIBRARY_FUND_LIST_REQUEST,
  RESET_CURRENT_LIBRARY_DATA,
  SUCCESS
} from "../../../../actions/action-types";

const initialState = [];

export const libraryFundReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CURRENT_LIBRARY_DATA:
      return initialState;

    case `${LIBRARY_FUND_LIST_REQUEST}${SUCCESS}`:
      return action.payload;

    default:
      return state;
  }
};