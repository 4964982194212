import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "react-bootstrap";
import styles from './MessageItem.module.css';
import classNames from 'classnames';
import {resolveDateWithTimeZone} from "../../../utils/Dates/resolveDateWithTimeZone";

const MessageItem = ({item, updateMessage, setModalData}) => {
    const {ts, tsread, message, id, subject, title} = item;

    const messageDate = resolveDateWithTimeZone(ts);
    const shortMessage = (message.length > 100) ? `${message.substring(0, 100)}...` : message;

    const openModal = () => {
        setModalData(item);

        if (!tsread) {
            updateMessage(id);
        }
    };


    return (
        <div>
            <div
                onClick={openModal}
                className={classNames(styles.shortItem, !tsread && styles.notRead)}
            >
                <Row>
                    <Col md={3} lg={2}>
                        <span>{messageDate}</span>
                    </Col>
                    <Col md={3} lg={2}>
                        <span>{subject}</span>
                    </Col>
                    <Col md={3} lg={6}>
                        <span>{title ? title : ''}</span>
                    </Col>
                    <Col md={3} lg={2}>
                        <span>{shortMessage ? shortMessage : ''}</span>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

MessageItem.propTypes = {
    item: PropTypes.object,
    setModalData: PropTypes.func
};


export default MessageItem;