import PropTypes from 'prop-types';
import React from "react";
import addFont from "../../../fonts/fonts";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import PdfPrintPanel from "../Panel";


const HTMLTablePrint = ({table, title, fontSize, orientation, tablesArray}) => {
    addFont(jsPDF.API);
    const doc = new jsPDF({orientation: orientation});


    const addTable = table => doc.autoTable({
        html: `${table}`,
        styles: {font: "Montserrat", fontSize: fontSize || 10}
    });

    const toPdf = () => {
        if (tablesArray) tablesArray.forEach(table => addTable(table));
        else addTable(table);
        doc.save(`${title}.pdf`);
    };

    const toPrint = () => {
        if (tablesArray) tablesArray.forEach(table => addTable(table));
        else addTable(table);
        doc.autoPrint();
        doc.output('dataurlnewwindow');
    };

    return (
        <PdfPrintPanel printPdf={toPrint} loadPdf={toPdf}/>
    );
};

HTMLTablePrint.propTypes = {
    table: PropTypes.string,
    tablesArray: PropTypes.array,
    title: PropTypes.string,
    fontSize: PropTypes.number,
    orientation: PropTypes.oneOf(['landscape', 'portrait'])
};

export default HTMLTablePrint;