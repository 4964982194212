import { ERROR, RESET, START, SUCCESS } from "../../actions/action-types";

export const searchDataDefaultInitialState = {
  query: "",
  page: 1,
  maxResults: 15,
  pageCount: 0
};

const getStateFromActionFroSimpleReducer = (
  initialState,
  setActionType,
  resetActionType,
  customResolver
) => (state, action) => {
  if (action.type === setActionType) {
    if (
      typeof state === "object" &&
      typeof state !== "function" &&
      !Array.isArray(state)
    ) {
      return {
        ...state,
        ...action.payload
      };
    }
    return action.payload;
  } else if (action.type === resetActionType && !!resetActionType) {
    return initialState;
  }
  if (typeof customResolver === "function") {
    const result = customResolver(state, action);
    if (result !== undefined) {
      return result;
    }
  }
  return state;
};

export const createSimpleReducer = (
  initialState,
  setActionType,
  resetActionType,
  customResolver
) => (state = initialState, action) =>
  getStateFromActionFroSimpleReducer(
    initialState,
    setActionType,
    resetActionType,
    customResolver
  )(state, action);

export const createSimpleReducerWithLoader = (
  initialState,
  actionType,
  customResolver
) => (state = { loading: false, data: initialState }, action) => {
  if (action.type === `${actionType}${START}`) {
    return {
      ...state,
      loading: true
    };
  }
  const newDataState = getStateFromActionFroSimpleReducer(
    initialState,
    `${actionType}${SUCCESS}`,
    `${actionType}${RESET}`,
    customResolver
  )(state.data, action);
  if (newDataState === initialState) {
    return {
      loading: false,
      data: initialState
    };
  } else if (
    action.type === `${actionType}${SUCCESS}` ||
    action.type === `${actionType}${ERROR}`
  ) {
    return {
      loading: false,
      data: newDataState
    };
  }
  if (typeof action.type === "string" && !action.type.includes(actionType)) {
    return state;
  }
  return {
    ...state,
    data: newDataState
  };
};
