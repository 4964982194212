import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import "./Literature.css";
import Book from '../Book';

const Razdel = ({ razdel }) => {
  const { name, course_all, course_five, course_four, course_three, course_two, course_one, books } = razdel;

  const [lit, setLit] = useState(false);

  const toggleLit = e => {
    if (e.target.id === "lit") {
      setLit(!lit);
    }
  };

  return (
    <div onClick={toggleLit}>
      <div className="tableLitRow">
        <div id="lit" className="tableLitRow_titleCell">
          { name }
        </div>
        <div className="tableLitRow_Cell">{ course_one }</div>
        <div className="tableLitRow_Cell">{ course_two }</div>
        <div className="tableLitRow_Cell">{ course_three }</div>
        <div className="tableLitRow_Cell">{ course_four }</div>
        <div className="tableLitRow_Cell">{ course_five }</div>
        <div className="tableLitRow_Cell">{ course_all }</div>
      </div>
      <Collapse in={lit}>
        <div className={'collapse-books'}>
            {books.map((book, index) => <Book key={index} book={book}/>)}
        </div>
      </Collapse>
    </div>
  );
};

export default Razdel;
