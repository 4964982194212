import { api } from "../../App";
import { START, SUCCESS, AUTHOR_BOOKS_REQUEST } from "../actions/action-types";
import { createSimpleActionsForReducer } from "../actions/actionsFactory";
import { createSimpleReducer } from "../reducers/reducersFactrory";
import {
  call,
  put,
  takeLatest
} from "redux-saga/dist/redux-saga-effects-npm-proxy.esm";

const searchCovers = id => {
  return api.post("/search", { authorid: id });
};

export const authorBooksActions = createSimpleActionsForReducer(
  "authorBooks",
  AUTHOR_BOOKS_REQUEST
);

export const authorBooksReducer = createSimpleReducer(
  [],
  `${AUTHOR_BOOKS_REQUEST}${SUCCESS}`
);

export function* bookAuthorBooksSagaWatcher() {
  yield takeLatest(`${AUTHOR_BOOKS_REQUEST}${START}`, bookAuthorBooksSaga);
}

function* bookAuthorBooksSaga({ payload }) {
  try {
    const response = yield call(searchCovers, payload);
    yield put(authorBooksActions.authorBooksRequestSuccess(response.data));
  } catch (e) {
    yield put(authorBooksActions.authorBooksRequestError());
  }
}

