import React from 'react';
import styles from './ImportScene.module.css';
import { setImportBooksFormData } from "../../redux/actions/import/form";
import { importBooksRequestStart } from "../../redux/actions/import/import";
import { connect } from "react-redux";
import Button from "../../components/Button";
import { Col, Row } from "react-bootstrap";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";

const mapStateToProps = ({ importBooks }) => ({
  data: importBooks.data,
  error: importBooks.error,
  formData: importBooks.form
});

const mapDispatchToProps = {
  setFormData: setImportBooksFormData,
  startImport: importBooksRequestStart
};

const ImportScene = ({ data, error, formData, setFormData, startImport }) => {
  const onChange = (name, value) => setFormData({ ...formData, [name]: value });

  const handleFileRead = ({ target }) => onChange(target.name, target.files[0]);

    console.log(typeof error)
    console.log(error)

  return (
    <SceneContainer title="Импорт">
      <Row>
        <Col md={6}>
          <div className={styles.sectionTitle}>
            Формат загружаемого файла:
          </div>
          <div className={styles.sectionContainer}>
            <div className={styles.inputContainer}>
              <input type="radio" checked={formData.format === "json"}
                     id={"json-radio"}
                     className={styles.radioInput}
                     value={"json"}
                     name={"format"}
                     onChange={({ target }) => onChange(target.name, target.value)}/>
              <label htmlFor={"json-radio"}>JSON</label>
            </div>
            <div className={styles.inputContainer}>
              <input type="radio" checked={formData.format === "rusmarc"}
                     id={"rusmarc-radio"}
                     className={styles.radioInput}
                     value={"rusmarc"}
                     name={"format"}
                     onChange={({ target }) => onChange(target.name, target.value)}/>
              <label htmlFor={"rusmarc-radio"}>RUSMARC</label>
            </div>
          </div>
          <div className={styles.sectionTitle}>
            Файл:
          </div>
          <div className={styles.sectionContainer}>
            <input type="file" onChange={handleFileRead} name="file"/>
          </div>
          <Button label="Загрузить" onClick={startImport} style={styles.button}/>
        </Col>
        {(data || error) &&
        <Col md={6}>
          {data &&
          <>
            <div className={styles.sectionTitle}>
              Добавлено:
            </div>
            <div className={styles.dataContainer}>
              {data.map((item, index) => <div key={index} className={styles.dataTitle}>{item.title}</div>)}
            </div>
          </>}
          {error &&
          <>
            <div className={styles.sectionTitle}>
              Ошибки:
            </div>
            <div className={styles.errorContainer}>
              {error}
            </div>
          </>}
        </Col>}
      </Row>
    </SceneContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportScene);
