import React from "react";
import PropTypes from "prop-types";
import styles from "./BookLoanHistoryList.module.css";
import BookLoanItem from "./Item";

const BookLoanHistoryList = ({
                                 mid,
                                 data,
                                 reserve,
                                 unreserveBook,
                                 onTurnOut,
                                 onDelete,
                                 onEdit,
                                 isOwn,
                                 isCompact,
                                 isLibrarian
                             }) => {

    return (
        <div>

            {isCompact ? (<>
                    <table className={styles.table}>
                        <thead>
                        <tr>
                            <td>Дата выдачи</td>
                            <td>Инв.номер</td>
                            <td>Автор и заглавие книги</td>
                            <td>Сдать</td>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((item, index) => (
                            <BookLoanItem
                                isOwn={isOwn}
                                currentMid={mid}
                                onTurnIn={onTurnOut && (() => onTurnOut && onTurnOut(item.bookinstanceid))}
                                data={item}
                                key={index}
                                onDelete={onDelete}
                                onEdit={onEdit}
                                reserve={reserve}
                                unreserveBook={unreserveBook}
                                isCompact={isCompact}
                                isLibrarian={isLibrarian}
                            />
                        ))}
                        </tbody>

                    </table>
                </>
            ) : (<>
                {data.map((item, index) => (
                    <BookLoanItem
                        isOwn={isOwn}
                        currentMid={mid}
                        onTurnIn={onTurnOut && (() => onTurnOut && onTurnOut(item.bookinstanceid))}
                        data={item}
                        key={index}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        reserve={reserve}
                        unreserveBook={unreserveBook}
                        isCompact={isCompact}
                        isLibrarian={isLibrarian}
                    />
                ))}
            </>)}
        </div>
    );
};

BookLoanHistoryList.propTypes = {
    data: PropTypes.array,
    reserve: PropTypes.bool,
    isLibrarian: PropTypes.bool,
};

export default BookLoanHistoryList;
