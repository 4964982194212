import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {

  let currentYear = new Date().getFullYear();

  return (
    <div className={styles.container}>
      © { currentYear } ООО Нинтегра
    </div>
  );
};

Footer.propTypes = {

};

export default Footer;
