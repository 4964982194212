import React from 'react';
import PropTypes from 'prop-types';
import DataTable from "../../../DataTable";
import DataTableCol from "../../../DataTable/Column";
import moment from "moment";

const ElectronicResourcesUsageTable = ({ data }) => {

    /*{
        "id": 6341,
        "mid": "2",
        "bookid": "202459",
        "affectedid": null,
        "eventtime": "2019-07-19T08:30:16.533Z",
        "clientip": "127.0.0.1",
        "logevent": 0,
        "write": false,
        "accessdenied": false,
        "errormessage": null,
        "payloadurl": "/api/book/:id/pdf",
        "payloadbody": "{}",
        "payloadarg": "{}",
        "method": "GET",
        "paction": "Выгрузка на электронный носитель",
        "login": "nnz",
        "lastname": "Фамилия",
        "firstname": "Имя",
        "patronymic": "Отчество",
        "title": " 2-Й Кадетский Императора Петра Великаго корпус С-Пб",
        "authors": null
    }*/

    return (
        <DataTable data={data} className={'my-2'}>
            <DataTableCol title={"Время"} resolver={item => {
                return moment(item.eventtime).format('hh:mm:ss DD.MM.YYYY')
            }}/>
            <DataTableCol title="Способ открытия" value={"paction"}/>
            <DataTableCol title={"Документ"} colSpan={2} resolver={item => {
                return (<>
                        <div><strong>{ item.title }</strong></div>
                        <span>{ item.authors && item.authors.map(author => author.name) }</span>
                    </>
                )
            }}/>
            {/*JI ID*/}
            <DataTableCol title={"ФИО"} colSpan={2} resolver={item => {
                return `${item.lastname} ${item.firstname} ${item.patronymic}`
            }}/>
            <DataTableCol title={"ID пользователя"} value={'mid'}/>
            {/*категория */}
            <DataTableCol title={"IP"} value="clientip"/>
        </DataTable>
    );
};

ElectronicResourcesUsageTable.propTypes = {
    data: PropTypes.array.isRequired,
};

export default ElectronicResourcesUsageTable;