import { call, put, takeLatest, select } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
  SEARCH_AUDIT_LIST_REQUEST,
  SEARCH_AUDIT_COUNT_REQUEST,
  SEARCH_AUDIT_ITEM_REQUEST,
  START,
} from "../../actions/action-types";
import {
  getSearchAuditCount,
  getSearchAuditList,
  getSearchAuditItem
} from "../../../api/searchAudit/searchAudit";
import {
  searchAuditCountRequestError,
  searchAuditCountRequestSuccess
} from "../../actions/searchAudit/count";
import {
  searchAudiItemRequestSuccess,
  searchAudiItemRequestError
} from "../../actions/searchAudit/item";
import {
  searchAuditSearchData
} from "../../actions/searchAudit/searchData";
import {
  searchAuditListRequestSuccess,
  searchAuditListRequestStart,
  searchAuditListRequestError
} from "../../actions/searchAudit/list";


export default function* searchAuditWatcher() {
  yield takeLatest(`${SEARCH_AUDIT_COUNT_REQUEST}${START}`, searchAuditCountWorker);
  yield takeLatest(`${SEARCH_AUDIT_LIST_REQUEST}${START}`, searchAuditListWorker);
  yield takeLatest(`${SEARCH_AUDIT_ITEM_REQUEST}${START}`, searchAuditItemWorker)
}

function* searchAuditCountWorker() {
  const formData = yield select(({ searchAudit }) => searchAudit.form);
  const searchData = yield select(({ searchAudit }) => searchAudit.search);
  try {
    const response = yield call(getSearchAuditCount, formData);
    const count = parseInt(response.data.count, 10);
    yield put(searchAuditCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(searchAuditSearchData({...searchData, pageCount}));
    yield put(searchAuditListRequestStart());
  } catch (error) {
    yield put(searchAuditCountRequestError(error));
  }

}

function* searchAuditListWorker() {
  const formData = yield select(({ searchAudit }) => searchAudit.form);
  const searchData = yield select(({ searchAudit }) => searchAudit.search);

  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
  try {
    const response = yield call(getSearchAuditList, searchData, formData);
    yield put(searchAuditListRequestSuccess(response.data));
  } catch (error) {
    yield put(searchAuditListRequestError(error));
  }
}

function* searchAuditItemWorker({ payload: id }) {
  try {
    const response = yield call(getSearchAuditItem, id);
    yield put(searchAudiItemRequestSuccess(response.data));
  } catch (e) {
    yield put(searchAudiItemRequestError(e));
  }
}
