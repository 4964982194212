import React, {useEffect, useState} from 'react';
import Pagination from "../../components/Pagination";
import {setBookInventorySearchData} from "../../redux/actions/bookInventory/search";
import {connect} from "react-redux";
import InventaryBookSearchForm from '../../components/InventaryBook/SearchForm';
import InventaryBookTable from '../../components/InventaryBook/InventaryTable';
import {bookInventaryCountRequestStart} from '../../redux/actions/bookInventory/count';
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import DocumentsModal from "../../components/Documents/Modal";
import {documentTypesAllRequestStart} from "../../redux/actions/documents/types/all";
import {documentSaveRequestStart} from "../../redux/actions/documents/documents/save";
import {getDocumentData} from "../../api/documents/documents";


const mapStateToProps = ({inventaryBook, documentTypes}) => ({
    list: inventaryBook.list,
    searchData: inventaryBook.searchData,
    count: inventaryBook.count,
    allTypes: documentTypes.all
});

const mapDispatchToProps = {
    setSearchData: setBookInventorySearchData,
    requestList: bookInventaryCountRequestStart,
    requestAllTypes: documentTypesAllRequestStart,
    saveDocument: documentSaveRequestStart,
};

const BookInventory = ({searchData, setSearchData, list, requestList, count, allTypes, requestAllTypes, saveDocument}) => {
    const [modalData, setModalData] = useState({modalType: null, data: null});

    const onSaveModal = (data) => {
        saveDocument(data);
    };

    const onClickHandler = (item) => {
        getDocumentData(item.documentid).then(res => {
            setModalData({modalType: 'EDIT', data: res.data})
        });

    };


    useEffect(() => {
        requestList();
    }, []);

    useEffect(() => {
        requestAllTypes();
    }, [requestAllTypes]);

    const setCurrentPage = (page) => {
        setSearchData({...searchData, page});
        requestList()
    };

    return (
        <SceneContainer title="Инвентарная книга">
            <InventaryBookSearchForm data={searchData} setSearchData={setSearchData} startSearch={requestList}/>
            <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page}
                        setCurrent={setCurrentPage}/>
            <DocumentsModal types={allTypes} onSave={onSaveModal} setModalData={setModalData} modalData={modalData}/>
            <InventaryBookTable data={list} setModal={setModalData} onCellClick={onClickHandler}/>
            <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page}
                        setCurrent={setCurrentPage} onSave={onSaveModal}/>
        </SceneContainer>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(BookInventory);
