import { FUND_ACCOUNTING_REQUEST, START, SUCCESS, ERROR } from "../action-types";

export const fundAccountingRequestStart = () => ({
  type: `${FUND_ACCOUNTING_REQUEST}${START}`,

});

export const fundAccountingRequestSuccess = (data) => ({
  type: `${FUND_ACCOUNTING_REQUEST}${SUCCESS}`,
  payload: data
});

export const fundAccountingRequestError = (data) => ({
  type: `${FUND_ACCOUNTING_REQUEST}${ERROR}`,
  payload: data
});
