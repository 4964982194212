import { ERROR, INSTITUTIONS_SAVE_REQUEST, START, SUCCESS } from "../action-types";

export const institutionsSaveRequestStart = (data, callback) => ({
  type: `${INSTITUTIONS_SAVE_REQUEST}${START}`,
  payload: data, callback
});

export const institutionsSaveRequestSuccess = (data) => ({
  type: `${INSTITUTIONS_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const institutionsSaveRequestError = (error) => ({
  type: `${INSTITUTIONS_SAVE_REQUEST}${ERROR}`,
  payload: error
});

