import React, {useEffect, useMemo} from 'react';

import {connect} from "react-redux";
import {fundAccountingRequestStart} from "../../redux/actions/fundAccounting/list";
import {setFundAccountingSearchData} from "../../redux/actions/fundAccounting/search";
import {libraryListRequestStart} from "../../redux/actions/library/info/list";

import FundAccountingSearchForm from "../../components/FundAccounting/SearchForm";
import FundAccountingTable from "../../components/FundAccounting/Table";
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";


const mapStateToProps = ({fundAccounting, options, library}) => ({
    list: fundAccounting.list,
    loader: fundAccounting.loader,
    optionsData: options.data,
    librariesList: library.info.list,
    searchData: fundAccounting.search,
});

const mapDispatchToProps = {
    setSearchData: setFundAccountingSearchData,
    requestList: fundAccountingRequestStart,
    getLibrariesList: libraryListRequestStart,
};

const FundAccounting = ({
                            list,
                            loader,
                            requestList,
                            optionsData,
                            getLibrariesList,
                            librariesList,
                            searchData,
                            setSearchData
                        }) => {

    useEffect(() => {
        if (optionsData) {
            getLibrariesList(optionsData.own_client_id);
        }
    }, [getLibrariesList, optionsData]);

    const fundAccountingParts = useMemo(() => {
        const partsConfig = new Map([
            [1, {
                title: 'Часть 1. Поступление в фонд.',
                actionSumTitle: 'Всего поступило книг',
                content: []
            }],
            [2, {
                title: 'Часть 2. Выбытие документов из фонда.',
                actionSumTitle: 'Всего выбыло книг',
                content: []
            }]
        ]);

        if (list.length) {
            list.forEach(item => partsConfig.get(item['part_num']).content.push(item));
        }

        return Array.from(partsConfig.values());
    }, [list]);


    return (
        <SceneContainer title={"Книга суммарного учета"}>
            <FundAccountingSearchForm
                setSearchData={setSearchData}
                searchData={searchData}
                onSubmit={requestList}
                librariesList={librariesList}
            />

            {loader ?
                <SpinnerOuter isLoading={loader}/>
                :
                <>
                    {fundAccountingParts.map((item, index) => <FundAccountingTable key={index} data={item}/>)}
                </>
            }

        </SceneContainer>
    )
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FundAccounting);
