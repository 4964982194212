import React from 'react';
import PropTypes from 'prop-types';
import styles from './SuggestionsAlphabet.module.css';
import Alphabet from "../Alphabet";

const SuggestionsAlphabet = ({ items, ...props }) => {
  const pairs = [];
  items.forEach((item, index) =>
    index < items.length - 1 && pairs.push(`${item} - ${items[index + 1]}`));
  return (
    <Alphabet items={pairs} span={12} {...props} itemClassName={styles.item}/>
  );
};

SuggestionsAlphabet.propTypes = {
  items: PropTypes.array
};

export default SuggestionsAlphabet;
