
import { SET_ELECTRONIC_COURSES_SEARCH_DATA } from "../../../actions/action-types";
import { RESULTS_PER_PAGE } from "../../../../constants/pagination";

const initialState = {
    page: 1,
    maxResults: RESULTS_PER_PAGE,
    pageCount: 0
};

export const electronicCoursesSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ELECTRONIC_COURSES_SEARCH_DATA:
            return action.payload;

        default: return state;
    }
};