export const AgregationTableType = [
    {
        label: "Название библиотеки",
        value: 'libraryname'
    },{
        label: "Количество фондов",
        value: 'fundcount'
    },{
        label: "Количество книгохранилищ",
        value: 'bookstorecount'
    },{
        label: "Количество стеллажей",
        value: 'bookshelfcount'
    },{
        label: "Количество полок",
        value: 'shelfcount'
    },{
        label: "Количество названий всех видов документов и форм их представления",
        value: 'shelfcount'
    },{
        label: "Количество БЗ с обложками",
        value: 'coverrecordscount'
    },{
        label: "Количество названий электронных документов",
        value: 'digitrecordcount'
    },{
        label: "Количество программ обучения",
        value: 'eduprogramcount'
    },{
        label: "Количество программ без связки с книгами в электронной библиотеке",
        value: 'eduprogramwithoutbookscount'
    },{
        label: "Количество дисциплин для обеспечения литературой",
        value: 'coursecount'
    },{
        label: "% обеспеченности бумажными изданиями основной литературы с учетом нормы ФГОС",
        value: 'mainbookFGOSpercent'
    },{
        label: "% обеспеченности бумажными изданиями основной литературы по требованиям МО РФ",
        value: 'mainbookMOpercent'
    },{
        label: "% обеспеченности бумажными изданиями дополнительной литературы с учетом нормы ФГОС",
        value: 'eduprogramwithoutbookscount'
    },{
        label: "% обеспеченности бумажными изданиями дополнительной литературы по требованиям МО РФ",
        value: 'extrabookMOpercent'
    },{
        label: "% обеспеченности электронными изданиями основной литературы",
        value: 'digitmainbookpercent'
    },{
        label: "% обеспеченности электронными изданиями дополнительной литературы",
        value: 'digitextrabookpercent'
    },{
        label: "Количество библиографических записей применяемых для обучения по основным образовательным направлениям",
        value: 'edurecordcount'
    },{
        label: "Количество библиографических записей применяемых для обучения по основным образовательным направлениям с обложками",
        value: 'educoverrecordcount'
    },{
        label: "Количество библиографических записей применяемых для обучения по основным образовательным направлениям с электронными экземпляром",
        value: 'edudigitrecordcount'
    },{
        label: "Количество входов пользователей",
        value: 'logincount'
    },{
        label: "Количество запросов на получение бумажных экземпляров",
        value: 'bookrequestcount'
    },{
        label: "Количество выданных бумажных экземпляров книг",
        value: 'booktakecount'
    },{
        label: "Количество выданных электронных книг",
        value: 'digitbooktakecount'
    },{
        label: "Количество гигабайт, занимаемых электронными документами на электронных накопителях библиотеки",
        value: 'allbooksize'
    },{
        label: "Количество гигабайт, занимаемых заархивированными (сжатыми) электронными документами на электронных накопителях библиотеки",
        value: 'zipbooksize'
    },{
        label: "Количество условных единиц учета по сумме всех видов документов и форм их представления",
        value: 'allinstancebzcount'
    },
];