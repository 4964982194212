import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminPermissionsTable.module.css';
import { Col, Row } from "react-bootstrap";

const AdminPermissionsTable = ({ data, onPermissionChange }) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected((selected) =>
      selected ?
        data.find((item) => item.xp_key === selected.xp_key) : null
    );
  }, [data]);

  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <Row noGutters>
          <Col md={4}>
            <div className={styles.headItem}>
              Раздел
            </div>
          </Col>
          <Col md={6}>
            <div className={styles.headItem}>
              Режим
            </div>
          </Col>
          <Col md={2}>
            <div className={styles.headItem}>
              Доступ
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles.content}>
        <Row noGutters>
          <Col md={4} className={styles.parentSection}>
            {data.map((item, index) => (
              <div className={classNames(styles.item, styles.parentItem)} key={index} onClick={() => setSelected(item)}>
                {item.name}
              </div>
            ))}
          </Col>
          <Col md={6}>
            {selected && selected.children && selected.children.map((item, index) => (
              <div className={styles.item} key={index}>
                {item.name}
              </div>
            ))}
          </Col>
          <Col md={2}>
            {selected && selected.children && selected.children.map((item, index) => (
              <div className={classNames(styles.item, styles.checkboxContainer)} key={index}>
                <select onChange={({ target: { value } }) => onPermissionChange(item.xp_key, value)} value={item.value}>
                  <option value="Нет">Нет</option>
                  <option value="Чтение">Чтение</option>
                  <option value="Полный">Полный</option>
                </select>
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );
};

AdminPermissionsTable.propTypes = {
  data: PropTypes.array,
  onPermissionChange: PropTypes.func
};

export default AdminPermissionsTable;
