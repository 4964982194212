import React, { useEffect, useState } from "react";
import styles from "./BookDescriptionScene.module.css";
import { Col, Row } from "react-bootstrap";
import BookDescriptionInfoWrapper from "../../components/BookDescription/InfoWrapper";
import { bookDataRequestStart } from "../../redux/actions/bookData/data";
import { connect } from "react-redux";
import BookDescriptionImage from "../../components/BookDescription/Image";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { resolveBookCoverUrl } from "../../utils/resolvePhotoUrl";
import OpenPhotoModal from "../../components/OpenPhotoModal";
import ScanImages from "./Components/ScanImages";

const mapStateToProps = ({ bookData }) => ({
  data: bookData
});

const mapDispatchToProps = dispatch => ({
  requestData: id => dispatch(bookDataRequestStart(id))
});

const getScanImagesUrls = (data, bookId) => {
  const images = [];
  data.subscriptionCover &&
    images.push(resolveBookCoverUrl(data.subscriptionCover, bookId));
  data.subscriptionBackCover &&
    images.push(resolveBookCoverUrl(data.subscriptionBackCover, bookId));
  return images;
};

const BookDescriptionScene = ({ data, match, requestData, history }) => {
  const bookId = match.params.id;

  useEffect(() => {
    requestData(bookId);
  }, [requestData, bookId]);

  const [selectedImage, setSelectedImage] = useState("");
  const [imageModalVisible, setImageModalVisible] = useState(false);

  return (
    <div className={styles.container}>
      {data && (
        <Row>
          <Col md={12}>
            <span
              className={styles.navButton}
              onClick={history && history.goBack}
            >
              Назад
            </span>
            <Link to={`/book/${bookId}/edit`} className={styles.navButton}>
              Редактировать
            </Link>
          </Col>

          <Col md={5}>
            <BookDescriptionImage
              url={data.coverImage}
              bookId={data.bookid}
              alt={data.title}
            />
          </Col>
          <Col md={7}>
            <div className={styles.topInfoContainer}>
              <div className={styles.title}>{data.title}</div>
              <div className={styles.author}>
                {data.authors.map(item => item.name).join(", ")}
              </div>
              <BookDescriptionInfoWrapper
                value={data.publishers.map(item => item.name).join(", ")}
                label={"Издательство"}
              />
              <BookDescriptionInfoWrapper value={data.series} label={"Серия"} />
              <BookDescriptionInfoWrapper value={data.year} label={"Год"} />
              <BookDescriptionInfoWrapper
                value={data.isbn.join(",")}
                label={"ISBN"}
              />
              <BookDescriptionInfoWrapper
                value={data.pageNum}
                label={"Кол-во страниц"}
              />
            </div>
            {data.verifyStatus === 5 && data.removalData && (
              <Col md={12}>
                <div className={styles.seized}>
                  <h3>Книга изъята</h3>
                  <p>
                    <span>Номер документа: </span>
                    {data.removalData.documentnumber}
                  </p>
                  <p>
                    <span>Дата документа: </span>
                    {new Date(
                      data.removalData.documentdate
                    ).toLocaleDateString()}
                  </p>
                  <p>
                    <span>ФИО: </span>
                    {data.removalData.documentcreator}
                  </p>
                </div>
              </Col>
            )}
          </Col>
          <Col md={12}>
            <div className={styles.descLabel}>Аннотация:</div>
            <div className={styles.description}>{data.annotation}</div>
          </Col>
          {(!!data.subscriptionCover || !!data.subscriptionBackCover) && (
            <Col md={12}>
              <Row className={styles.scanImagesContainer}>
                <ScanImages
                  images={getScanImagesUrls(data, bookId)}
                  onClick={image => {
                    setImageModalVisible(true);
                    setSelectedImage(image);
                  }}
                />
                <OpenPhotoModal
                  visible={imageModalVisible}
                  onClose={() => setImageModalVisible(false)}
                  src={selectedImage}
                />
              </Row>
            </Col>
          )}
          <Col md={10}>
            <div className={styles.button}>
              Все книги
              <br />
              этого автора
            </div>
            <div className={styles.button}>
              Все книги издательства
              <br />
              {data.publishers.map(item => item.name).join(", ")}
            </div>
            <div className={styles.button}>
              Все книги серии
              <br />
              {data.series}
            </div>
            {data.pdfFile && data.pdfFile.length ? (
              <Link
                to={`/pdfViewer/${data.bookid}`}
                target="_blank"
                className={styles.button}
              >
                Открыть
              </Link>
            ) : (
              <div className={classNames(styles.button, styles.inactive)}>
                Открыть
              </div>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookDescriptionScene);
