import React, {useEffect} from "react";
import {Route, Switch, BrowserRouter} from "react-router-dom";
import {connect} from "react-redux";
import AuthScene from "../scenes/Auth";
import Layout from "../scenes/Layout";
import PrivateRoute from "../components/Routing/PrivateRoute";
import IndexScene from "../scenes/Index";
import PublishersScene from "../scenes/Catalogs/Publishers";
import UDKScene from "../scenes/Catalogs/UDK";
import BBKScene from "../scenes/Catalogs/BBK";
import AuthorsScene from "../scenes/Catalogs/Authors";
import LibraryAlphabetScene from "../scenes/LibraryAlphabet";
import ElectronicBookImport from "../scenes/ElectronicBookImport";
import InstitutionsScene from "../scenes/Catalogs/Institutions";
import ReaderSearchScene from "../scenes/ReaderSearch";
import LibraryListScene from "../scenes/LibraryList";
import LibraryTypesScene from "../scenes/Catalogs/LibraryTypes";
import BookLoanScene from "../scenes/BookLoan";
import BookLoanHistoryScene from "../scenes/BookLoanHistory";
import BookLoanSpecialtyScene from "../scenes/BookLoanSpecialty";
import BookDescriptionScene from "../scenes/BookDescription";
import ScrollToTop from "../components/Routing/ScrollToTop";
import RubricatorScene from "../scenes/Catalogs/Rubricator";
import InvNumbersScene from "../scenes/Catalogs/InvNumbers";
import CoversScene from "../scenes/Catalogs/Covers";
import BookInventory from "../scenes/BookInventory";
import AdminMenuScene from "../scenes/AdminMenu";
import OptionsScene from "../scenes/OptionsScene";
import AdminRolesScene from "../scenes/AdminRoles";
import AdminUsersScene from "../scenes/AdminUsers";
import LocationScene from "../scenes/Location";
import CollectionsScene from "../scenes/Catalogs/Collections";
import AdminPermissionsScene from "../scenes/Permissions/AdminPermissions";
import ExternalResourcesScene from "../scenes/ExternalResources";
import ReadingScene from "../scenes/Reading";
import PDFViewerScene from "../scenes/PDFViewer";
import SecurityAccessLogScene from "../scenes/SecurityLog";
import AuditLogScene from "../scenes/AuditLog";
import ReportsScene from "../scenes/Reports/List";
import QRCodesScene from "../scenes/QRCodes";
import ImportScene from "../scenes/ImportScene";
import ExternalResourcesEditScene from "../scenes/ExternalResourcesEdit";
import BookEditScene from "../scenes/BookEdit";
import HavkinaScene from "../scenes/Havkina";
import DocumentTypesScene from "../scenes/Catalogs/DocumentTypes";
import DocumentsScene from "../scenes/Documents";
import InventoryScene from "../scenes/Inventory";
import BooksOrderScene from "../scenes/BooksOrder";
import NewBooksOrderScene from "../scenes/NewBooksOrder";
import SystemInfoScene from "../scenes/SystemInfo";
import BooksScene from "../scenes/Catalogs/Books";
import BookSupply from "../scenes/BookSupply";
import BookSupplyStandarts from "../scenes/BookSupplyStandatrs/BookSupplyStandarts";
import Teachers from "../scenes/Teachers";
import ScheduleOnDate from "../scenes/ScheduleOnDate";
import ExternalSearch from "../scenes/ExternalSearch";
import ScheduleScene from "../scenes/Schedule";
import SearchByImageContainer from "../scenes/SearchByImage/container";
import FundAccounting from "../scenes/FundAccounting";
import FundMovingScene from "../scenes/FundMoving";
import {optionsGetRequestStart} from "../redux/actions/options/get";
import EnvelopePrint from "../scenes/BookEdit/EnvelopePrint";
import CatalogCard from "../scenes/BookEdit/CatalogCard";
import Formular from "../components/BookLoan/LibraryCardBlock/Formular/Formular";
import PrintInvFormular from "../scenes/Catalogs/InvNumbers/PrintInvFormular";
import StudentProjects from "../scenes/StudentProjects";
import Duplicate from "../scenes/Duplicate";
import TurnoutStats from "../scenes/TurnoutStats";
import SearchAudit from "../scenes/SearchAudit";
import Remcomplect from "../scenes/Remcomplect";
import LibraryMaterials from "../scenes/LibraryMaterials";
import BookScanPlan from "../scenes/BookScanPlan";
import BookExportScene from '../scenes/BookExport';
import BigDataScene from '../scenes/BigData';
import ReportContentScene from '../scenes/ReportContent';
import CheckDataLog from "../scenes/CheckDataLog";
import ElectronicCourses from './../scenes/ElectronicCourses/index';
import DigitalItems from './../scenes/DigitalItems';
import CatalogCards from './../scenes/CatalogCards';
import QRCodesSehlvesScene from './../scenes/QRCodesShelves';
import CategoriesScene from './../scenes/Catalogs/Categories';
import AdminCategoriesPermissionsScene from "../scenes/Permissions/AdminCategoriesPermissions";
import SeriesScene from "../scenes/Catalogs/Series";
import LitersScene from "../scenes/Catalogs/Liters";
import LitersPermissionsScene from "../scenes/Permissions/LitersPermissions";
import SeriesPermissionsScene from "../scenes/Permissions/SeriesPermissions";
import BookScanOrdersScene from "../scenes/BookScanOrders";
import MediaResource from "../scenes/MediaResource";
import MediaResourceEdit from "../scenes/MediaResource/Edit";
import MessageScene from "../scenes/Message";
import BorrowingTest from "../scenes/BorrowingTest";
import BookTransfer from "../scenes/BookTransfer";
import DataScheduler from "../scenes/DataScheduler";
import BookReserve from "../scenes/BookReserve";
import LibraryCardPass from "../components/BookLoan/LibraryCardBlock/LibraryCardPass";
import SPOIntegrationScene from "../scenes/SPOIntegration";
import CreditsScene from "../scenes/Credits";
import SchedulerScene from '../scenes/Scheduler';
import EPUBReader from '../scenes/EPUBReader';
import DJVUReader from '../scenes/DJVUReader';
import ModelViewer from '../scenes/3dViewer';
import TestsScene from "../scenes/Tests";


const RoutesComponent = ({requestOptions}) => {
    useEffect(() => {
        requestOptions();
    }, [requestOptions]);

    return (
        <BrowserRouter>
            <ScrollToTop>
                <Switch>
                    <Route exact path={"/pdfViewer/:bookId/:fileId"} component={PDFViewerScene}/>
                    <Route
                        exact
                        path={"/epub_reader/:bookId/:fileId"}
                        component={EPUBReader}
                    />
                    <Route
                        exact
                        path={"/djvu_reader/:bookId/:fileId"}
                        component={DJVUReader}
                    />
                    <Route exact path={"/qr_codes"} component={QRCodesScene}/>
                    <Route exact path={"/3d_viewer"} component={ModelViewer}/>
                    <Route exact path={"/qr_codes_shelves"} component={QRCodesSehlvesScene}/>
                    <Route exact path={"/envelope_print/:id"} component={EnvelopePrint}/>
                    <Route
                        exact
                        path={"/catalogcard_print/:id"}
                        component={CatalogCard}
                    />
                    <Route
                        exact
                        path={"/formular_print/:mid"}
                        component={Formular}
                    />
                    <Route
                        exact
                        path={"/library_card_pass_print/:mid"}
                        component={LibraryCardPass}
                    />
                    <Route
                        exact
                        path={"/inv_printformular/:id"}
                        component={PrintInvFormular}
                    />
                    <Route
                        path={"/"}
                        render={(history) => (
                            <Layout history={history}>
                                <PrivateRoute exact path={"/"} component={IndexScene}/>
                                <PrivateRoute
                                    exact
                                    path={"/rubricator"}
                                    component={RubricatorScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/publishers"}
                                    component={PublishersScene}
                                />
                                <PrivateRoute exact path={"/udk"} component={UDKScene}/>
                                <PrivateRoute exact path={"/bbk"} component={BBKScene}/>
                                <PrivateRoute
                                    exact
                                    path={"/authors"}
                                    component={AuthorsScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/book_loan"}
                                    component={BookLoanScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/book_loan/books"}
                                    component={BookLoanHistoryScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/book_loan/specialty"}
                                    component={BookLoanSpecialtyScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/books"}
                                    component={BooksScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/booksexport"}
                                    component={BookExportScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/book/:id"}
                                    component={BookDescriptionScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/book/:id/edit"}
                                    component={BookEditScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/library"}
                                    component={LibraryListScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/library/alphabet"}
                                    component={LibraryAlphabetScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/electronic_book_import"}
                                    component={ElectronicBookImport}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/library/reader_search"}
                                    component={ReaderSearchScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/library/types"}
                                    component={LibraryTypesScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/institutions"}
                                    component={InstitutionsScene}
                                />
                                <PrivateRoute exact path={"/covers"} component={CoversScene}/>
                                <PrivateRoute
                                    exact
                                    path={"/inv_numbers"}
                                    component={InvNumbersScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/admin/menu"}
                                    component={AdminMenuScene}
                                    adminOnly
                                />
                                <PrivateRoute
                                    exact
                                    path={"/admin/roles"}
                                    component={AdminRolesScene}
                                    adminOnly
                                />
                                <PrivateRoute
                                    exact
                                    path={"/options"}
                                    component={OptionsScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/admin/users"}
                                    component={AdminUsersScene}
                                    adminOnly
                                />
                                <PrivateRoute
                                    exact
                                    path={"/admin/permissions"}
                                    component={AdminPermissionsScene}
                                    adminOnly
                                />
                                <PrivateRoute
                                    exact
                                    path={"/admin/categories_permissions"}
                                    component={AdminCategoriesPermissionsScene}
                                    adminOnly
                                />
                                <PrivateRoute
                                    exact
                                    path={"/admin/liters_permissions"}
                                    component={LitersPermissionsScene}
                                    adminOnly
                                />
                                <PrivateRoute
                                    exact
                                    path={"/admin/series_permissions"}
                                    component={SeriesPermissionsScene}
                                    adminOnly
                                />
                                <PrivateRoute
                                    exact
                                    path={"/location"}
                                    component={LocationScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/collections"}
                                    component={CollectionsScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/categories"}
                                    component={CategoriesScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/external"}
                                    component={ExternalResourcesScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/external/edit"}
                                    component={ExternalResourcesEditScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/reading"}
                                    component={ReadingScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/librarymaretials/:id"}
                                    component={LibraryMaterials}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/library_card/:id"}
                                    component={BookLoanHistoryScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/bookreserve"}
                                    component={BookReserve}
                                    adminOnly
                                />
                                <PrivateRoute
                                    exact
                                    path={"/admin/log/audit"}
                                    component={AuditLogScene}
                                    adminOnly
                                />
                                <PrivateRoute
                                    exact
                                    path={"/admin/log/journal"}
                                    component={SecurityAccessLogScene}
                                    adminOnly
                                />
                                <PrivateRoute
                                    exact
                                    path={"/admin/system"}
                                    component={SystemInfoScene}
                                    adminOnly
                                />
                                <PrivateRoute
                                    exact
                                    path={"/admin/log/check"}
                                    component={CheckDataLog}
                                    adminOnly
                                />
                                <PrivateRoute
                                    exact
                                    path={"/admin/scheduler"}
                                    component={SchedulerScene}
                                    adminOnly
                                />
                                <PrivateRoute
                                    exact
                                    path={"/data_migration/edit"}
                                    component={DataScheduler}
                                    adminOnly
                                />
                                <PrivateRoute
                                    exact
                                    path={"/reports"}
                                    component={ReportsScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/book_transfer"}
                                    component={BookTransfer}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/credits"}
                                    component={CreditsScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/bigdata"}
                                    component={BigDataScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/report/:id"}
                                    component={ReportContentScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/import"}
                                    component={ImportScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/havkina"}
                                    component={HavkinaScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/document_types"}
                                    component={DocumentTypesScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/documents"}
                                    component={DocumentsScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/inventory"}
                                    component={InventoryScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/bookorders"}
                                    component={BooksOrderScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/booksupply"}
                                    component={BookSupply}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/booksupplystandarts"}
                                    component={BookSupplyStandarts}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/bookinventory"}
                                    component={BookInventory}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/fund_accounting"}
                                    component={FundAccounting}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/newbookorders"}
                                    component={NewBooksOrderScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/fund_moving"}
                                    component={FundMovingScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/teachers"}
                                    component={Teachers}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/spo_intergartion"}
                                    component={SPOIntegrationScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/schedule_on_date"}
                                    component={ScheduleOnDate}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/external_resources"}
                                    component={ExternalSearch}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/student_projects"}
                                    component={StudentProjects}
                                />
                                <PrivateRoute exact path={"/duplicate"} component={Duplicate}/>
                                <Route exact path="/auth" component={AuthScene}/>
                                <PrivateRoute
                                    exact
                                    path={"/schedule"}
                                    component={ScheduleScene}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/turnout_stats"}
                                    component={TurnoutStats}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/electronic_courses"}
                                    component={ElectronicCourses}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/search_audit"}
                                    component={SearchAudit}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/remcomplect"}
                                    component={Remcomplect}
                                />
                                <PrivateRoute
                                    exact
                                    path={"/bookscan"}
                                    component={BookScanPlan}
                                />
                                <PrivateRoute
                                    exact
                                    path="/search_by_image"
                                    component={SearchByImageContainer}
                                />
                                <PrivateRoute
                                    exact
                                    path="/digitalitems"
                                    component={DigitalItems}
                                />
                                <PrivateRoute
                                    exact
                                    path="/catalogcards"
                                    component={CatalogCards}
                                />
                                <PrivateRoute
                                    exact
                                    path="/series"
                                    component={SeriesScene}
                                />
                                <PrivateRoute
                                    exact
                                    path="/leters"
                                    component={LitersScene}
                                />
                                <PrivateRoute
                                    exact
                                    path="/book_scan_orders"
                                    component={BookScanOrdersScene}
                                />
                                <PrivateRoute
                                    exact
                                    path="/media_resource"
                                    component={MediaResource}
                                />
                                <PrivateRoute
                                    exact
                                    path="/media_resource/edit"
                                    component={MediaResourceEdit}
                                />
                                <PrivateRoute
                                    exact
                                    path="/message"
                                    component={MessageScene}
                                />
                                <PrivateRoute
                                    exact
                                    path="/borrowing_test"
                                    component={BorrowingTest}
                                />
                                <PrivateRoute
                                    exact
                                    path="/tests"
                                    component={TestsScene}
                                />
                            </Layout>
                        )}
                    />
                </Switch>
            </ScrollToTop>
        </BrowserRouter>
    );
};

export const Routes = connect(
    null,
    dispatch => {
        return {
            requestOptions: () => dispatch(optionsGetRequestStart())
        };
    }
)(RoutesComponent);
