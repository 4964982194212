import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
  LIBRARY_DELETE_POINT_REQUEST,
  LIBRARY_POINT_LIST_REQUEST,
  LIBRARY_SAVE_POINT_REQUEST,
  START
} from "../../../../actions/action-types";
import {
  libraryPointListRequestError, libraryPointListRequestStart,
  libraryPointListRequestSuccess
} from "../../../../actions/library/info/point/list";
import {
  addLibraryPoint,
  deleteLibraryPoint,
  editLibraryPoint,
  getLibraryPoints
} from "../../../../../api/library/library";
import {
  librarySavePointRequestError,
  librarySavePointRequestSuccess
} from "../../../../actions/library/info/point/save";
import {
  libraryDeletePointRequestError,
  libraryDeletePointRequestSuccess
} from "../../../../actions/library/info/point/delete";

export default function* libraryPointWatcher() {
  yield takeLatest(`${LIBRARY_POINT_LIST_REQUEST}${START}`, libraryPointListWorker);
  yield takeLatest(`${LIBRARY_SAVE_POINT_REQUEST}${START}`, libraryListPointSaveWorker);
  yield takeLatest(`${LIBRARY_DELETE_POINT_REQUEST}${START}`, libraryListPointDeleteWorker);
}

function* libraryPointListWorker({ payload: libraryId }) {
  try {
    const response = yield call(getLibraryPoints, libraryId);
    yield put(libraryPointListRequestSuccess(response.data));
  } catch (error) {
    yield put(libraryPointListRequestError(error));
  }
}

function* libraryListPointSaveWorker({ payload: { data, libraryId } }) {
  try {
    const response = yield call(data.id ? editLibraryPoint : addLibraryPoint, libraryId, data);
    yield put(librarySavePointRequestSuccess(response.data));
    yield put(libraryPointListRequestStart(libraryId));
  } catch (error) {
    yield put(librarySavePointRequestError(error))
  }
}

function* libraryListPointDeleteWorker({ payload: { libraryId, pointId } }) {
  try {
    const response = yield call(deleteLibraryPoint, libraryId, pointId);
    yield put(libraryDeletePointRequestSuccess(response.data));
    yield put(libraryPointListRequestStart(libraryId));
  } catch (error) {
    yield put(libraryDeletePointRequestError(error));
  }
}