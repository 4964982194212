import React from 'react';
import PropTypes from 'prop-types';
import {Col, OverlayTrigger, Row} from "react-bootstrap";
import ModalInputWrapper from "../../Modal/InputWrapper";
import {nonNull} from "../../../utils/strings";
import styles from "../../../scenes/OptionsScene/OptionsScene.module.css";
import OptionsLogoPopover from "../LogoPopover";
import OptionsLogoWrapper from "../LogoWrapper";
import {Link} from "react-router-dom";
import Button from "../../Button";

const MainOptions = ({options, setOptions, institutions, outsecrecyOptions, logoUrl, onSaveImage, imageInput, onLoadImage, saveOptions, saveStatus}) => {

    const onChange = (name, value) => {
        setOptions({...options, [name]: value});
    };

    return (
        <div className={styles.container}>
            <Row>
                <Col md={12}>
                    <ModalInputWrapper
                        value={options.GOU_Name.join("\n")}
                        label={"Полное наименование организации"}
                        type="textarea"
                        name="GOU_Name"
                        onChange={({target}) =>
                            onChange(target.name, target.value.split("\n"))
                        }
                    />
                    <ModalInputWrapper
                        label={"Ссылка в справочнике организаций"}
                    >
                        <select
                            value={nonNull(options.own_client_id)}
                            className={styles.select}
                            name="own_client_id"
                            onChange={({target}) =>
                                onChange(target.name, target.value)
                            }
                        >
                            {institutions.map((item, index) => (
                                <option value={item.idclient} key={index}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </ModalInputWrapper>
                    <ModalInputWrapper
                        label={"Сегмент"}
                    >
                        <select
                            value={nonNull(options.outsecrecy)}
                            className={styles.select}
                            name="outsecrecy"
                            onChange={({target}) =>
                                onChange(target.name, target.value)
                            }
                        >
                            {outsecrecyOptions().map((item, index) => (
                                <option value={item.value} key={index}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </ModalInputWrapper>
                    <ModalInputWrapper
                        value={options.address.join("\n")}
                        label={"Адрес"}
                        name="address"
                        onChange={({target}) =>
                            onChange(target.name, target.value.split("\n"))
                        }
                    />
                </Col>
                <Col md={7}>
                    <ModalInputWrapper
                        value={options.phones.join(";")}
                        label={"Телефоны"}
                        name="phones"
                        onChange={({target}) =>
                            onChange(target.name, target.value.split(";"))
                        }
                    />
                </Col>
                <Col md={12}>
                    <Row>
                        <Col md={3}>
                            {logoUrl && (
                                <OverlayTrigger
                                    trigger="click"
                                    placement="right"
                                    overlay={props => (
                                        <OptionsLogoPopover
                                            uploadImage={onSaveImage}
                                            {...props}
                                        />
                                    )}
                                >
                                    <OptionsLogoWrapper url={logoUrl}/>
                                </OverlayTrigger>
                            )}
                            <input
                                type="file"
                                className={styles.fileInput}
                                ref={imageInput}
                                onChange={onLoadImage}
                                accept="image/*"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col md={7}>
                    <ModalInputWrapper
                        value={options.termofuseedubook}
                        label={"Срок использования учебной литературы (лет)"}
                        name="termofuseedubook"
                        onChange={({target}) =>
                            onChange(target.name, target.value)
                        }
                    />
                </Col>
                <Col md={7}>
                    <ModalInputWrapper
                        value={options.bookdaysonhands}
                        label={"Срок выдачи литературы (дней)"}
                        name="bookdaysonhands"
                        onChange={({target}) =>
                            onChange(target.name, target.value)
                        }
                    />
                </Col>
                <Col md={7}>
                    <ModalInputWrapper
                        value={options.gotoscanbutton}
                        label={"Перейти к сканированию"}
                        name="gotoscanbutton"
                        onChange={({ target }) =>
                            onChange(target.name, target.value)
                        }
                    />
                </Col>

                <Col md={7}>
                    <label>Запрет выдачи литературы при наличии задолженности
                        <input
                            style={{marginLeft: "10px"}}
                            type="checkbox"
                            name="deliveryforbiddenifdebt"
                            checked={options.deliveryforbiddenifdebt}
                            onChange={({target}) =>
                                onChange(target.name, target.checked)
                            }
                        />
                    </label>
                </Col>
                <Col md={12}>
                    <Link className={styles.link} to={"/external/edit"}>
                        Редактирование внешних информационных ресурсов
                    </Link>
                </Col>
                <Col md={12}>
                    <Link className={styles.link} to={"/media_resource/edit"}>
                        Настройка медиа портала
                    </Link>
                </Col>
                <Col md={12}>
                    <Link className={styles.link} to={"/data_migration/edit"}>
                        Миграция метаданных СПО ЭБ по расписанию
                    </Link>
                </Col>
                <Col md={{span: 2, offset: 8}}>
                    {saveStatus && (
                        <div className={styles.status}>Сохранено!</div>
                    )}
                </Col>

            </Row>
        </div>
    );
};

MainOptions.propTypes = {
    options: PropTypes.object,
    setOptions: PropTypes.func,
    institutions: PropTypes.array,
    onLoadImage: PropTypes.func,
    onSaveImage: PropTypes.func
};

export default MainOptions;
