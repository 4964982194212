import React, {useState, useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {authorsCountRequestStart} from "../../../redux/actions/authors/count";
import {setAuthorsSearchData} from "../../../redux/actions/authors/searchData";
import {saveAuthorRequestStart} from "../../../redux/actions/authors/save";
import {connect} from "react-redux";
import Pagination from "../../../components/Pagination";
import AuthorModal from "../../../components/Authors/Modal";
import AuthorsTable from "../../../components/Authors/Table";
import {authorsDeleteRequestStart} from "../../../redux/actions/authors/delete";
import {SORT_DIRECTION} from "../../../constants/sortDirections";
import {authorBooksActions} from "../../../redux/factory/authorsBooksReduxData";
import {libraryInstitutionsRequestStart} from "../../../redux/actions/library/info/institutions";
import {libraryListRequestStart} from "../../../redux/actions/library/info/list";
import SceneContainer from "../../../components/UtulityComponents/SceneContainer";
import SpinnerOuter from "../../../components/UtulityComponents/SpinnerOuter";
import {createNotification} from "../../../components/notification/notification";
import AuthorsSearchForm from "../../../components/Authors/SearchForm";

const mapStateToProps = ({authors, library}) => ({
    searchData: authors.search,
    count: authors.count,
    list: authors.list,
    loader: authors.loader,
    libraryList: library.info.list,
    institutions: library.info.institutions
});

const mapDispatchToProps = {
    requestAuthorBooks: authorBooksActions.authorBooksRequestStart,
    requestAuthors: authorsCountRequestStart,
    setSearchData: setAuthorsSearchData,
    saveAuthor: saveAuthorRequestStart,
    deleteAuthor: authorsDeleteRequestStart,
    requestInstitutions: libraryInstitutionsRequestStart,
    requestLibraryList: libraryListRequestStart
};

const MODAL_FORM_FIELDS = {
    name: "",
    lastname: "",
    firstname: "",
    patronymic: "",
    displayname: "",
    description: "",
    authorsign: "",
    academicdegree: "",
    birthdate: null,
    deathdate: null,
};

const AuthorsScene = ({
                          institutions,
                          requestInstitutions,
                          count,
                          searchData,
                          list,
                          loader,
                          setSearchData,
                          requestAuthors,
                          saveAuthor,
                          deleteAuthor,
                          requestAuthorBooks,
                          requestLibraryList,
                          libraryList
                      }) => {
    useEffect(() => {
        requestInstitutions();
    }, []);

    useEffect(() => {
        requestLibraryList();
    }, []);

    useEffect(() => {
        requestAuthors();
    }, [requestAuthors]);

    useEffect(() => {
        setModalData(modalData => {
            const {data} = modalData;
            if (data) {
                data.photosize = list.find(item => item.id === data.id).photosize;
                return {...modalData, data: {...data}};
            }
            return modalData;
        });
    }, [list]);

    const [modalData, setModalData] = useState({modalType: null, data: null});
    const [selectedItem, setSelectedItem] = useState({});


    const onDeleteAuthor = () => {
        if (selectedItem.authornumbooks === "0") {
            deleteAuthor(selectedItem.id);
            setSelectedItem({});
        } else {
            createNotification('error', 'За данным автором закреплены книги', 'Ошибка')
        }
    };

    const onSubmitModal = values => {
        saveAuthor({...modalData, data: {...modalData.data, ...values}}, closeModal);
    };

    const setSort = sortBy => {
        setSearchData({
            ...searchData,
            sortBy,
            page: 1,
            sortDirection:
                searchData.sortDirection === SORT_DIRECTION.ASC
                    ? SORT_DIRECTION.DESC
                    : SORT_DIRECTION.ASC
        });
        requestAuthors();
    };


    const handleSelect = item => {
        if (item.id === selectedItem.id) {
            setSelectedItem({});
        } else {
            setSelectedItem(item);
        }
    };

    const closeModal = () => {
        setModalData({modalType: null, data: null});
    };

    return (
        <SceneContainer title="Справочники / Авторы">
            <AuthorsSearchForm
                institutions={institutions}
                libraryList={libraryList}
                searchData={searchData}
                setSearchData={setSearchData}
                onSubmit={requestAuthors}
            />
            {modalData.data &&
            <AuthorModal
                onSubmit={onSubmitModal}
                setModalData={setModalData}
                modalData={modalData}
                closeModal={closeModal}
            />
            }
            <Row>
                <Col md={12}>
                    <Pagination
                        count={count}
                        pageCount={searchData.pageCount}
                        current={searchData.page}
                        setCurrent={page => {
                            setSearchData({...searchData, page});
                            requestAuthors();
                        }}
                    />
                </Col>
                <Col md={12}>

                    {loader ?
                        <SpinnerOuter isLoading={loader}/>
                        :
                        <AuthorsTable
                            data={list}
                            requestAuthorBooks={requestAuthorBooks}
                            setModal={setModalData}
                            sortBy={searchData.sortBy}
                            setSort={setSort}
                            handleSelect={handleSelect}
                            selectedItem={selectedItem}
                            onDelete={onDeleteAuthor}
                            initialFormFields={MODAL_FORM_FIELDS}
                        />
                    }
                </Col>
                <Col md={12}>
                    <Pagination
                        count={count}
                        pageCount={searchData.pageCount}
                        current={searchData.page}
                        setCurrent={page => {
                            setSearchData({...searchData, page});
                            requestAuthors();
                        }}
                    />
                </Col>
            </Row>
        </SceneContainer>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthorsScene);