import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import SceneContainer from "../../components/UtulityComponents/SceneContainer";
import {messageCountRequestStart} from "../../redux/actions/message/count";
import MessageSearchForm from "../../components/Message/Form";
import Pagination from "../../components/Pagination";
import {setMessageSearchData} from "../../redux/actions/message/search";
import {setMessageFormData} from "../../redux/actions/message/form";
import MessageList from "../../components/Message/List";
import {editMessageRequestStart} from "../../redux/actions/message/edit";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import {deleteMessageRequestStart} from "../../redux/actions/message/delete";
import MessageModal from "../../components/Message/Modal";


const mapStateToProps = ({message}) => ({
  list: message.list,
  count: message.count,
  loader: message.loader,
  searchData: message.search,
  formData: message.form,
  isEmpty: message.empty
});

const mapDispatchToProps = {
  requestList: messageCountRequestStart,
  setSearchData: setMessageSearchData,
  setFormData: setMessageFormData,
  updateMessage: editMessageRequestStart,
  deleteItem: deleteMessageRequestStart
};

const MessageScene = ({
  list,
  requestList,
  count,
  searchData,
  setSearchData,
  formData,
  setFormData,
  updateMessage,
  loader,
  isEmpty,
  deleteItem
}) => {

  useEffect(() => {
    requestList();
  }, [requestList]);

  const [modalData, setModalData] = useState(null);

  const setCurrentPage = (page) => {
    setSearchData({ ...searchData, page });
    requestList()
  };

  const onSubmitForm = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
    requestList();
  };

  const onDeleteItem = (id) => {
    const confirm = window.confirm('Вы действительно хотите удалить сообщение?');

    if(confirm) {
      deleteItem(id);
      closeModal();
    }
  };

  const closeModal = () => {
    setModalData(null)
  };

  return (
    <SceneContainer title="Уведомления">
      <MessageSearchForm data={formData} onSubmit={onSubmitForm} />

      {modalData &&
        <MessageModal data={modalData} setData={setModalData} deleteItem={onDeleteItem}/>
      }

      {(loader && !isEmpty) ?
        <SpinnerOuter isLoading={loader}/>
        :
        <>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
          <MessageList list={list} updateMessage={updateMessage} isEmpty={isEmpty} setModalData={setModalData}/>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
        </>
      }
    </SceneContainer>
  );
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageScene);