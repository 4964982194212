import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {INV_NUMBERS_FORM_STRUCTURE} from "../../constants/invNumbers";
import {api} from "../../App";
import {createNotification} from "../../components/notification/notification";
import styles from "../Catalogs/Books/BooksScene.module.css";
import {Col, Row} from "react-bootstrap";
import BookSearchForm from "../../components/Covers/SearchForm";
import CoversSearchFormControls from "../../components/Covers/SearchFormControls";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "../../components/Pagination";
import BooksList from "../../components/Books/List";
import {optionsGetRequestStart} from "../../redux/actions/options/get";
import {coversCountRequestStart} from "../../redux/actions/covers/count";
import {setCoversFormData} from "../../redux/actions/covers/form";
import {setCoversSearchData} from "../../redux/actions/covers/search";
import {exportBooksClearReducer, exportBooksRequestStart} from "../../redux/actions/bookExport/exportBooks";

const mapStateToProps = ({ covers, options, booksExport }) => ({
  list: covers.list,
  count: covers.count,
  formData: covers.form,
  searchData: covers.search,
  loader: covers.loader,
  options: options.data,
  exports: booksExport.export,
});

const mapDispatchToProps = dispatch => ({
  requestOptions: () => dispatch(optionsGetRequestStart()),
  requestList: () => dispatch(coversCountRequestStart()),
  setFormData: (data) => dispatch(setCoversFormData(data)),
  setSearchData: (data) => dispatch(setCoversSearchData(data)),
  exportBooks: (bookIds) => dispatch(exportBooksRequestStart(bookIds)),
  exportBooksClearReducer: () => dispatch(exportBooksClearReducer()),
});

const DigitalItemsScene = ({ count, loader, list, requestList, formData, setFormData,
                             searchData, setSearchData, requestOptions, options, history, exportBooks, exports,
                             exportBooksClearReducer
                           }) => {

  const setCurrentPage = (page) => {
    setSearchData({ ...searchData, page });
    requestList()
  };

  const handleFormClear = () => {
    setFormData(INV_NUMBERS_FORM_STRUCTURE);
  };

  const onCreateNewBook = () => {
    const params = {
      "title": "",
      "publisher": "",
      "year": 0
    };
    api.post('/book', params)
      .then((response) => {
        history.push(`/book/${response.data.book.id}/edit?newbook`);
      });

  };

  const onExportBooks = (books) => {
    let booksIds =
      books
        .filter(item => item.checked)
        .map(item => item.bookid);
    if(!booksIds.length) {
      return createNotification('warning', 'Вы ничего не добавили в список выгрузки')
    }
    exportBooks(booksIds);
  };

  useEffect(() => {
    requestOptions()
  }, [requestOptions]);

  useEffect(() => {
    if(exports.isCompleted) {
      createNotification('success', 'Задание на выгрузку успешно создано');
      exportBooksClearReducer();
      requestList();
    }
    if(exports.isError) {
      createNotification('error', 'Не удалось создать задание на выгрузку');
      exportBooksClearReducer();
    }
  }, [exports]);


  return (
    <div className={styles.container}>
      <Row>
        <Col md={12}>
          <div className={styles.title}>
            Справочники / Библиографические записи
          </div>
        </Col>
        <Col md={12}>
          <BookSearchForm data={formData} setData={setFormData}>
            <CoversSearchFormControls onSubmit={requestList} onClear={handleFormClear}/>
          </BookSearchForm>
        </Col>

        {loader ?
          <div className={styles.spinnerContainer}>
            <Spinner animation="grow" variant="primary"/>
          </div>
          :
          <>
            <Col md={12}>
              <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
            </Col>
            <Col md={12}>
              <BooksList
                data={list}
                termOfUse={options ? options.termofuseedubook : undefined}
                createBook={onCreateNewBook}
                onExportBooks={onExportBooks}
              />
            </Col>
            <Col md={12}>
              <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
            </Col>
          </>}
      </Row>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DigitalItemsScene);