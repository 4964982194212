import { ERROR, PUBLISHERS_COUNT_REQUEST, START, SUCCESS } from "../action-types";

export const publishersCountRequestStart = () => ({
  type: `${PUBLISHERS_COUNT_REQUEST}${START}`
});

export const publishersCountRequestSuccess = (count) => ({
  type: `${PUBLISHERS_COUNT_REQUEST}${SUCCESS}`,
  payload: count
});

export const publishersCountRequestError = (error) => ({
  type: `${PUBLISHERS_COUNT_REQUEST}${ERROR}`,
  payload: error
});


