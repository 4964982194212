import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import { getAllTeachersRequest } from "../../../api/teachers/teachers";
import {saveTeachersList} from "../../actions/teachers/list";
import {START, TEACHERS_LIST_REQUEST} from "../../actions/action-types";

export default function* teachersWatcher() {
  yield takeLatest(`${TEACHERS_LIST_REQUEST}${START}`, getAllTeachers)
}

function* getAllTeachers() {
  try {
    const teachersList = yield call(getAllTeachersRequest);
    yield put(saveTeachersList(teachersList.data));
  }catch (e) {
    console.log(e);
  }
}