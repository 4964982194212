import { call, put, select, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
  LIBRARY_MATERIALS_COUNT_REQUEST,
  LIBRARY_MATERIALS_LIST_REQUEST,
  START,
  SUCCESS,
  BOOK_DATA_REQUEST,
} from "../../actions/action-types";
import {
  searchLibraryMaterials,
  countLibraryMaterials
} from '../../../api/libraryMaterials/search';
import {
  libraryMaterialsCountRequestSuccess,
  libraryMaterialsCountRequestError
} from '../../actions/LibraryMaterials/count';
import {
  libraryMaterialsListRequestStart,
  libraryMaterialsListRequestSuccess,
  libraryMaterialsListRequestError,
} from '../../actions/LibraryMaterials/list';
import {
  setLibraryMaterialsSearchData
} from '../../actions/LibraryMaterials/search';



export default function* libraryMaterialsRequestWatcher() {
  yield takeLatest(`${LIBRARY_MATERIALS_COUNT_REQUEST}${START}`, libraryMaterialsCountWorker);
  yield takeLatest(`${LIBRARY_MATERIALS_LIST_REQUEST}${START}`, libraryMaterialsListWorker);
  //yield takeLatest(`${BOOK_DATA_REQUEST}${SUCCESS}`, libraryMaterialsListWorker);
}

function* libraryMaterialsCountWorker({ payload: id }) {
  const formData = yield select(({libraryMaterials}) => libraryMaterials.form);
  const searchData = yield select(({libraryMaterials}) => libraryMaterials.search);
  try {
    const response = yield call(countLibraryMaterials, formData, id);
    const count = parseInt(response.data.count, 10);
    yield put(libraryMaterialsCountRequestSuccess(count));
    const pageCount = Math.ceil(count / searchData.maxResults);
    yield put(setLibraryMaterialsSearchData({ ...searchData, pageCount }));
    yield put(libraryMaterialsListRequestStart(id));
  } catch(error) {
    yield put(libraryMaterialsCountRequestError(error));
  }
}

function* libraryMaterialsListWorker({ payload: id }) {

  const formData = yield select(({libraryMaterials}) => libraryMaterials.form);
  const searchData = yield select(({libraryMaterials}) => libraryMaterials.search);
  searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);
  try {
    const response = yield call(searchLibraryMaterials, formData, searchData, id);
    yield put(libraryMaterialsListRequestSuccess(response.data));
  } catch (error) {
    yield put(libraryMaterialsListRequestError(error));
  }
}
