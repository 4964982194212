import { api } from "../../App";

export const getInventoryBooksList = (documentId) => {
  return api.get(`/Inventory_spcf/${documentId}`);
};

export const addBookToInventory = ({documentid, bookid, amount}) => {
  const params = {
    inventory: documentid.toString(),
    book_instance: parseInt(bookid),
    quantity: amount.toString()
  };

  return api.post('/Inventory_spcf', params );
};

export const editBookToInventory = ({documentid, book_instance, amount, id}) => {
  const params = {
    inventory: documentid,
    quantity: amount,
    book_instance,
  };



  return api.put(`/Inventory_spcf/${id}`, params);
};

export const deleteBookToInventory = (id) => {
  return api.delete(`/Inventory_spcf/${id}`);
};
