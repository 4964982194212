import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./LibraryCardBlock.module.css";
import {Col, Row} from "react-bootstrap";
import LibraryCard from "../LibraryCard";
import {NavLink} from "react-router-dom";
import Button from "../../../components/Button";

const LibraryCardBlock = ({isOwn, data, onEditClick, onSearchClick, isLibrarian}) => {

    const printFormular = () => window.open(
        `/formular_print/${data.mid}`
    );

    const printLibraryCardPass = () => window.open(
        `/library_card_pass_print/${data.mid}`
    );

    return (
        <div className={styles.container}>
            <Row>
                <Col md={5}>
                    <LibraryCard isOwn={isOwn} data={data}/>
                </Col>
                <Col md={7} className={styles.heightInherit}>
                    <div className={styles.infoSection}>
                        <div className={styles.infoBlock}>{data && data.faculty}</div>
                        <div className={styles.infoBlock}>{data && data.cathedra}</div>
                        <div className={styles.infoBlock}>
                            Специальность: {data && data.specialisation}
                        </div>
                        <div className={styles.infoBlock}>
                            Группа: {data && data.groupname}
                        </div>
                        <div className="d-flex">
                            {!isOwn && <Button onClick={printFormular} label="Печать формуляра"/>}
                            {isLibrarian && <Button onClick={printLibraryCardPass} label="Печать карты" style="ml-2"/>}
                        </div>
                    </div>
                    <div className={styles.buttonSection}>
                        <div className={styles.buttonsContainer}>
                            <NavLink
                                to={"/book_scan_orders"}
                                onClick={onSearchClick}
                                className={styles.button}
                            >
                                <div
                                    className={classNames(styles.buttonIcon, styles.iconSearch)}
                                />
                                Поиск
                            </NavLink>
                            <div className={styles.button}>
                                <div
                                    className={classNames(styles.buttonIcon, styles.iconHistory)}
                                />
                                История
                            </div>
                            <div className={styles.button}>
                                <div
                                    onClick={onEditClick}
                                    className={classNames(styles.buttonIcon, styles.iconEdit)}
                                />
                                Изменить
                            </div>
                        </div>

                        <NavLink
                            className={styles.closeContainer}
                            to="/library/reader_search"
                        >
                            <div className={styles.button}>
                                <div
                                    className={classNames(styles.buttonIcon, styles.iconClose)}
                                />
                                Закрыть читательский билет
                            </div>
                        </NavLink>

                    </div>
                </Col>
            </Row>
        </div>
    );
};

LibraryCardBlock.propTypes = {
    data: PropTypes.object,
    onSearchClick: PropTypes.func
};

export default LibraryCardBlock;
