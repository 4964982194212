import {call, put, takeLatest, select} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {
    getTurnoutStatsCount
} from "../../../api/turnoutStats/stats";
import {
    TURNOUT_STATS_LIST_REQUEST,
    TURNOUT_STATS_COUNT_REQUEST,
    START
} from '../../actions/action-types';
import {securityLogCountRequestError, securityLogCountRequestSuccess} from "../../actions/securityLog/count";
import {setSecurityLogSearchData} from "../../actions/securityLog/searchData";
import {securityLogListRequestStart} from "../../actions/securityLog/list";


export default function* turnoutStatsWatcher() {
    yield takeLatest(`${TURNOUT_STATS_LIST_REQUEST}${START}`, turnoutStatsListWorker);
    yield takeLatest(`${TURNOUT_STATS_COUNT_REQUEST}${START}`, turnoutStatsCountWorker)
}

function* turnoutStatsCountWorker() {
    const formData = yield select(({securityLog}) => securityLog.form);
    const searchData = yield select(({securityLog}) => securityLog.search);
    try {
        const response = yield call(getTurnoutStatsCount, formData);
        const count = parseInt(response.data.count, 10);
        yield put(securityLogCountRequestSuccess(count));
        const pageCount = Math.ceil(count / searchData.maxResults);
        yield put(setSecurityLogSearchData({...searchData, pageCount}));
        yield put(securityLogListRequestStart());
    } catch (error) {
        yield put(securityLogCountRequestError(error));
    }
}


function* turnoutStatsListWorker() {
    const formData = yield select(({securityLog}) => securityLog.form);
    const searchData = yield select(({securityLog}) => securityLog.search);
    searchData.paginateFrom = Math.max(searchData.maxResults * (searchData.page - 1), 0);

    try {

    } catch (e) {

    }
}
