import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./MembersList.module.css";
import { Col, Row } from "react-bootstrap";
import SelectUsersModal from "../../SelectUserModal";
import { ADD } from "../../../constants/modalTypes";

const RoleMembersList = ({
  data,
  onSubmit,
  onClose,
  onSaveRoleMember,
  onDeleteRoleMember,
  modalType,
  roleClearNewMembers,
  newMembers
}) => {
  const [selectedId, setSelectedId] = useState(null);
  const [selectModalVisible, setSelectModalVisible] = useState(false);

  const handleSelect = id => setSelectedId(id);

  const onCancel = () => {
    roleClearNewMembers();
    onClose();
  };

  const renderMembers = (item, index) => (
    <div
      className={classNames(styles.item, {
        [styles.selected]: item.mid === selectedId
      })}
      key={index}
      onClick={() => handleSelect(item.mid)}
    >
      <Row>
        <Col md={6}>{item.displayname}</Col>
        <Col md={{ offset: 2, span: 4 }}>n/a</Col>
      </Row>
    </div>
  );

  const renderNewMembers = (item, index) => (
    <div
      className={classNames(styles.item)}
      key={index}
      onClick={() => handleSelect(item.id)}
    >
      <Row>
        <Col md={6}>{item.name}</Col>
        <Col md={{ offset: 2, span: 4 }}>n/a</Col>
      </Row>
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {modalType !== ADD
          ? data.map(renderMembers)
          : newMembers.map(renderNewMembers)}
      </div>
      <div className={styles.bottom}>
        <Row>
          <Col md={4}>
            <div className={styles.flexSection}>
              <div
                onClick={() => setSelectModalVisible(true)}
                className={classNames(styles.button)}
              >
                Добавить
              </div>
              {modalType !== ADD && (
                <div
                  onClick={() => selectedId && onDeleteRoleMember(selectedId)}
                  className={classNames(styles.button, {
                    [styles.disabled]: !selectedId
                  })}
                >
                  Удалить
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            {modalType !== ADD && (
              <div className={styles.flexSection}>
                <div className={styles.count}>Всего записей: {data.length}</div>
              </div>
            )}
          </Col>
          <Col md={{ offset: 4, span: 4 }}>
            <div className={styles.flexSection}>
              <div className={styles.button} onClick={onSubmit}>
                Сохранить
              </div>
              <div className={styles.button} onClick={onCancel}>
                Отмена
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {selectModalVisible && (
        <SelectUsersModal
          modalType={modalType}
          onSubmit={id => {
            onSaveRoleMember(id);
            setSelectModalVisible(false);
          }}
          onClose={() => setSelectModalVisible(false)}
        />
      )}
    </div>
  );
};

RoleMembersList.propTypes = {
  data: PropTypes.array,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onSaveRoleMember: PropTypes.func
};

RoleMembersList.defaultProps = {
  data: []
};

export default RoleMembersList;
