import React, { useEffect, useState } from 'react';
import styles from './SearchAuditScene.module.css';
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { searchAuditCountRequestStart } from "../../redux/actions/searchAudit/count";
import { setSearchAuditFormData } from "../../redux/actions/searchAudit/formData";
import { searchAuditSearchData } from "../../redux/actions/searchAudit/searchData";
import { searchAudiItemRequestStart } from "../../redux/actions/searchAudit/item";
import Pagination from "../../components/Pagination";
import SearchAuditForm from "../../components/SearchAudit/Form";
import SearchAuditTable from "../../components/SearchAudit/Table";
import { SORT_DIRECTION } from "../../constants/sortDirections";
import SpinnerOuter from "../../components/UtulityComponents/SpinnerOuter";
import Button from "../../components/Button";

const mapStateToProps = ({ searchAudit }) => ({
  list: searchAudit.list,
  item: searchAudit.item,
  count: searchAudit.count,
  formData: searchAudit.form,
  searchData: searchAudit.search,
  datasetList: searchAudit.dataset
});

const mapDispatchToProps = {
  requestList: searchAuditCountRequestStart,
  setFormData: setSearchAuditFormData,
  setSearchData: searchAuditSearchData,
  requestItem: searchAudiItemRequestStart
};

const SearchAuditScene = ({ list, requestList, item, requestItem, formData, setFormData, searchData, setSearchData, count }) => {
  useEffect(() => {
    requestList()
  }, [requestList]);
  const [isModalVisible, setModalVisible] = useState(false);

  const setSort = (sortBy, sortDirection = SORT_DIRECTION.ASC) => {
    setSearchData({ ...searchData, sortBy, sortDirection: sortDirection });
    requestList();
  };

  const setCurrentPage = (page) => {
    setSearchData({ ...searchData, page });
    requestList()
  };

  const setCurrentItem = (item) => {
    setModalVisible(true);
    requestItem(item.id);
  };

  return (
    <div className={styles.container}>
      <Modal
        show={isModalVisible}
        centered={true}
        onHide={()=> setModalVisible(false)}
      >
        <Modal.Header>
          Подробности поискового запроса
        </Modal.Header>
        <Modal.Body>
          {item.isLoading ? (
            <SpinnerOuter />
          ) : (
            <table style={{width: '100%'}}>
              <tbody>
                <tr>
                  <td>Название</td>
                  <td>{item.data.title || '-'}</td>
                </tr>
                <tr>
                  <td>Авторы</td>
                  <td>{item.data.author || '-'}</td>
                </tr>
                <tr>
                  <td>Категория</td>
                  <td>{item.data.category || '-'}</td>
                </tr>
                <tr>
                  <td>ISBN</td>
                  <td>{item.data.isbn || '-'}</td>
                </tr>
                <tr>
                  <td>Год</td>
                  <td>{item.data.year || '-'}</td>
                </tr>
                <tr>
                  <td>УДК</td>
                  <td>{item.data.udk || '-'}</td>
                </tr>
                <tr>
                  <td>ББК</td>
                  <td>{item.data.bbk || '-'}</td>
                </tr>
                <tr>
                  <td>Дисциплина</td>
                  <td>{item.data.discipline || '-'}</td>
                </tr>
                <tr>
                  <td>Издательство</td>
                  <td>{item.data.publisher || '-'}</td>
                </tr>
                <tr>
                  <td>Текстовый поиск</td>
                  <td>{item.data.textsearch || '-'}</td>
                </tr>
                <tr>
                  <td>Статус</td>
                  <td>{item.data.verifyStatus || '-'}</td>
                </tr>
                <tr>
                  <td>Клиент</td>
                  <td>{item.data.idclient || '-'}</td>
                </tr>
              </tbody>
            </table>
          )}
          <div className="text-right mt-2">
            <Button
              onClick={() => setModalVisible(false)}
              label={'Закрыть'}
              colorType={'red'}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Row>
        <Col md={12}>
          <div className={styles.title}>
            Поисковые запросы
          </div>
        </Col>
        <Col md={12}>
          <SearchAuditForm data={formData} setData={setFormData} search={requestList}/>
        </Col>
        <Col md={12}>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
        </Col>
        <Col md={12}>
          <SearchAuditTable data={list} sortBy={searchData.sortBy} setSort={setSort} setCurrentItem={setCurrentItem}/>
        </Col>
        <Col md={12}>
          <Pagination count={count} pageCount={searchData.pageCount} current={searchData.page} setCurrent={setCurrentPage}/>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchAuditScene);
