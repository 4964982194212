import { all } from 'redux-saga/effects';
import getOptionsSaga from "./get/getOptionsSaga";
import saveOptionsSaga from "./save/saveOptionsSaga";
import optionsLogoSaga from "./image/optionsLogoSaga";
import optionsLayoutSaga from './layout/layoutOptionsSaga';

export default function* optionsSaga() {
  yield all([
    getOptionsSaga(),
    saveOptionsSaga(),
    optionsLogoSaga(),
    optionsLayoutSaga()
  ]);
}
