import { BORROWING_TEST_EDIT_REQUEST, START, SUCCESS, ERROR } from "../action-types";
import {createNotification} from "../../../components/notification/notification";

export const borrowingTestEditRequestStart = ({comment, itemId}) => ({
  type: `${BORROWING_TEST_EDIT_REQUEST}${START}`,
  payload: {comment, itemId}
});

export const borrowingTestEditRequestSuccess = () => {
  createNotification(
    'success',
    `Успешно отредактировано`
  );

  return {
    type: `${BORROWING_TEST_EDIT_REQUEST}${SUCCESS}`
  };
};

export const borrowingTestEditRequestError = (error) => {
  createNotification(
    'error',
    `Ошибка редактирования. Статус: ${error.response.status}`
  );

  return {
    type: `${BORROWING_TEST_EDIT_REQUEST}${ERROR}`,
    payload: error
  };
};
