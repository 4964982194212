import React from 'react';
import PropTypes from 'prop-types';
import styles from './Search.module.css';
import Input from "../Input";
import Button from "../Button";
import {createNotification} from "../notification/notification";

const Search = ({ onChange, onSubmit, value, label, placeholder, type, inputClassName, buttonLabel, pattern }) => {

    const checkBeforeSubmit = () => {
        if(pattern) {
            value.match(pattern) ?
                onSubmit()
                :
                createNotification('warning', 'Использованы недопустимые символы')
        } else {
            onSubmit();
        }
    };

    return (
        <div className={styles.container}>
            {label && <div className={styles.label}>
                {label}
            </div>}
            <Input type={type} placeholder={placeholder} value={value} style={inputClassName} onChange={(e) => {
                onChange(e.target.value)
            }} onKeyPress={(e) => {
                if(e.key === 'Enter') onSubmit();
            }}
            />
            <Button label={buttonLabel} onClick={checkBeforeSubmit} style={styles.button}/>
        </div>
    );
};

Search.propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.string,
    inputClassName: PropTypes.string,
    buttonLabel: PropTypes.string
};

Search.defaultProps = {
    type: "text",
    buttonLabel: "Найти"
};

export default Search;