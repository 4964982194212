import { DOCUMENT_LIST_REQUEST, START, SUCCESS, ERROR } from "../../action-types";

export const documentListRequestStart = () => ({
  type: `${DOCUMENT_LIST_REQUEST}${START}`
});

export const documentListRequestSuccess = (data) => ({
  type: `${DOCUMENT_LIST_REQUEST}${SUCCESS}`,
  payload: data
});

export const documentListRequestError = (data) => ({
  type: `${DOCUMENT_LIST_REQUEST}${ERROR}`,
  payload: data
});
