import { ERROR, START, SUCCESS, USERS_SAVE_REQUEST } from "../action-types";

export const usersSaveRequestStart = (data) => ({
  type: `${USERS_SAVE_REQUEST}${START}`,
  payload: data
});

export const usersSaveRequestSuccess = (data) => ({
  type: `${USERS_SAVE_REQUEST}${SUCCESS}`,
  payload: data
});

export const usersSaveRequestError = (data) => ({
  type: `${USERS_SAVE_REQUEST}${ERROR}`,
  payload: data
});