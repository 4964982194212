import React from 'react';
import PropTypes from 'prop-types';
import styles from './CoverListItemInfo.module.css';
import { Col, Row } from "react-bootstrap";

const CoverListItemInfo = ({label, value}) => {
  return (
    <Row>
      <Col md={3}>
        <div className={styles.label}>
          {label}:
        </div>
      </Col>
      <Col md={9}>
        <div className={styles.value}>
          {value}
        </div>
      </Col>
    </Row>
  );
};

CoverListItemInfo.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any
};

export default CoverListItemInfo;
