import React, {useState} from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import styles from "./BooksListItem.module.css";
import {Col, Row} from "react-bootstrap";
import CoverPhotoPlaceholder from "../../Covers/List/Item/CoverPhotoPlaceholder";
import {Link} from "react-router-dom";
import CoverListItemInfo from "../../Covers/List/Item/InfoBlock";
import {statusList} from "../../../constants/statusList";
import ItemCatalogCard from "./ItemCatalogCard";
import OpenPhotoModal from "../../OpenPhotoModal";
import {resolveBookCoverUrl} from "../../../utils/resolvePhotoUrl";
import Input from "../../Input";

const BooksListItem = ({data, termOfUse, onSelect, selectedIds}) => {
    const [modalVisible, setModalVisible] = useState(false);

    const outdated =
        termOfUse && data.year + termOfUse < new Date().getFullYear();
    const isChecked = selectedIds.includes(data.bookid);

    return (
        <div className={classNames(styles.container, isChecked && styles.checked)}>
            <Row>
                <Col md={3}>
                    <CoverPhotoPlaceholder
                        url={data.coverImage}
                        bookId={data.bookid}
                        alt={data.title}
                    />
                    <div className={styles.status}>{statusList[data.verifyStatus]}</div>
                    <OpenPhotoModal
                        src={
                            data.subscriptionCover &&
                            resolveBookCoverUrl(data.subscriptionCover, data.bookid)
                        }
                        visible={modalVisible}
                        onClose={() => setModalVisible(false)}
                    />
                    <ItemCatalogCard
                        label={"Каталожная карточка"}
                        data={data}
                        setModalVisible={setModalVisible}
                    />
                </Col>
                <Col md={6}>
                    <Row>
                        <Col md={{span: 9, offset: 3}}>
                            <Link to={`/book/${data.bookid}`} className={styles.title}>
                                {data.title}
                            </Link>
                            <div className={styles.value}>{data.authors}</div>
                        </Col>
                        <Col md={12}>
                            <CoverListItemInfo label={"Год"} value={data.year}/>
                            <CoverListItemInfo label={"ISBN"} value={data.isbn}/>
                            <CoverListItemInfo
                                label={"Размещение"}
                                value={data.clients || "n/a"}
                            />
                            <CoverListItemInfo label={"Фонд"} value={"n/a"}/>
                            <CoverListItemInfo label={"Страниц"} value={data.pageNum}/>
                            <CoverListItemInfo
                                label={"Издательство"}
                                value={data.publicationPlace || data.publishers}
                            />
                        </Col>
                        <Col md={{span: 5, offset: 3}}>
                            {outdated && (
                                <div className={styles.outdatedMessage}>
                                    Превышен срок использования
                                </div>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col md={2}>
                    <label htmlFor="">
                        Выбран:
                        <Input type="checkbox" onChange={() => onSelect(data.bookid)} checked={isChecked}/>
                    </label>
                    <Link
                        to={`/book/${data.bookid}/edit`}
                        target="_blank"
                        className={styles.button}
                    >
                        Редактирование
                    </Link>
                </Col>
            </Row>
        </div>
    );
};

BooksListItem.propTypes = {
    data: PropTypes.object,
    termOfUse: PropTypes.number
};

export default BooksListItem;
