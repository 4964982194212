import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import Index from "../Razdel";
import "./Discipline.css";

const Discipline = ({ data }) => {

  const { name, course_all, course_five, course_four, course_three, course_two, course_one, razdels } = data;
  const [disc, setDisc] = useState(false);

  const toggleDisc = e => {
    if (e.target.id === "disc") {
      setDisc(!disc);
    }
  };

  return (
    <div onClick={toggleDisc}>
      <div className="tableDiscRow">
        <div id="disc" className="tableDiscRow_titleCell">
          Дисциплина: { name }
        </div>
        <div className="tableDiscRow_Cell">{ course_one }</div>
        <div className="tableDiscRow_Cell">{ course_two }</div>
        <div className="tableDiscRow_Cell">{ course_three }</div>
        <div className="tableDiscRow_Cell">{ course_four }</div>
        <div className="tableDiscRow_Cell">{ course_five }</div>
        <div className="tableDiscRow_Cell">{ course_all }</div>
      </div>
      <Collapse in={disc}>
        <div className={'collapse-razdel'}>
            {razdels.map((razdel, index) => <Index key={index} razdel={razdel} />)}
        </div>
      </Collapse>
    </div>
  );
};

export default Discipline;
