import React from 'react';
import PropTypes from 'prop-types';
import MessageItem from "../Item";
import {Col, Row} from "react-bootstrap";
import styles from './MessageList.module.css';

const MessageList = ({ list, updateMessage, isEmpty, setModalData }) => {
  return (
    <div className={'mt-3 mb-3'}>
      {isEmpty ?
        <div className="emptyNotify">Ничего не найдено</div>
      :
        <>
          <div className={styles.header}>
            <Row>
              <Col md={3} lg={2}>
                <span>Время</span>
              </Col>
              <Col md={3} lg={2}>
                <span>Тип сообщения</span>
              </Col>
              <Col md={3} lg={6}>
                <span>Книга</span>
              </Col>
              <Col md={3} lg={2}>
                <span>Сообщение</span>
              </Col>
            </Row>
          </div>
          {list.map(item => <MessageItem item={item} key={`MessageItem${item.id}`} updateMessage={updateMessage} setModalData={setModalData}/>)}
        </>
      }
    </div>
  );
};

MessageList.propTypes = {
  list: PropTypes.array.isRequired,
  isEmpty: PropTypes.bool,
  deleteItem: PropTypes.func,
  setModalData: PropTypes.func
};

export default MessageList;