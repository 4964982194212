import React from "react";
import { Col, Row } from "react-bootstrap";
import ModalInputWrapper from "../../../Modal/InputWrapper";
import styles from "./AdditionalDataTab.module.css";

const AdditionalDataTab = ({ data, setData }) => {
  const onChange = ({ target: { value, name } }) => {
    setData({ ...data, [name]: value });
  };
  return (
    <div>
      <div className={styles.title}>
        Область заглавия и сведений об ответственности
      </div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Параллельное название"
            name="titleParallelName"
            value={data.titleParallelName}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Последующие сведения об ответственности"
            name="titleInfo"
            value={data.titleInfo}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Последующие сведения об ответственности"
            name="translation"
            value={data.translation}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <ModalInputWrapper
            label="Литературный жанр"
            name="genresId"
            value={data.genresId}
            onChange={onChange}
          />
        </Col>
        <Col md={6}>
          <ModalInputWrapper
            label="Тип материала"
            name="contentTypesId"
            value={data.contentTypesId}
            onChange={onChange}
          />
        </Col>
      </Row>
      <div className={styles.title}>Область издания</div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Сведения об издании"
            name="pubInfo"
            value={data.pubInfo}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Параллельные сведения об издании"
            name="pubParallelInfo"
            value={data.pubParallelInfo}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Сведения об ответственности, относящиеся к изданию"
            name="pubResponsibility"
            value={data.pubResponsibility}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Дополнительные сведения об издании"
            name="pubAdditionalInfo"
            value={data.pubAdditionalInfo}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Сведения об ответственности, относящиеся к дополнительным сведениям об издании"
            name="pubAdditionalInfoResponsibility"
            value={data.pubAdditionalInfoResponsibility}
            onChange={onChange}
          />
        </Col>
      </Row>
      <div className={styles.title}>Область выходных данных</div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Сведения о функции издателя, распространителя"
            name="pubPurpose"
            value={data.pubPurpose}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Место изготовления"
            name="pubManufacturePlace"
            value={data.pubManufacturePlace}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Имя изготовления"
            name="pubManufactureName"
            value={data.pubManufactureName}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Дата изготовления"
            name="pubManufactureDate"
            value={data.pubManufactureDate}
            onChange={onChange}
          />
        </Col>
      </Row>
      <div className={styles.title}>Область физической характеристики</div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Специфическое обозначение материала и объем"
            name="physMaterial"
            value={data.physMaterial}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Другие сведения о физической характеристике"
            name="physAdditionalInfo"
            value={data.physAdditionalInfo}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Сведения о сопроводительном материале"
            name="physAdditionalMaterial"
            value={data.physAdditionalMaterial}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <ModalInputWrapper
            label="Размеры"
            name="physSizes"
            value={data.physSizes}
            onChange={onChange}
          />
        </Col>
        <Col md={4}>
          <ModalInputWrapper
            label="Количество формул"
            name="statFormulaCount"
            value={data.statFormulaCount}
            onChange={onChange}
          />
        </Col>
        <Col md={4}>
          <ModalInputWrapper
            label="Количество таблиц"
            name="statTableCount"
            value={data.statTableCount}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <ModalInputWrapper
            label="Количество интерактивных изображений"
            name="statAnimationsCount"
            value={data.statAnimationsCount}
            onChange={onChange}
          />
        </Col>
        <Col md={4}>
          <ModalInputWrapper
            label="Количество тестов"
            name="statTestCount"
            value={data.statTestCount}
            onChange={onChange}
          />
        </Col>
        <Col md={4}>
          <ModalInputWrapper
            label="Количество интерактивных тестов"
            name="statAnimationTestCount"
            value={data.statAnimationTestCount}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Международный формат (типографский формат)"
            name="typographFormat"
            value={data.typographFormat}
            onChange={onChange}
          />
        </Col>
      </Row>
      <div className={styles.title}>Область серии</div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Параллельная серия или подсерии"
            name="seriesParallelName"
            value={data.seriesParallelName}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Сведения, относящиеся к заглавию серии или подсерии"
            name="seriesInfo"
            value={data.seriesInfo}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Сведения об ответственности, относящиеся к серии или подсерии"
            name="seriesResponsibility"
            value={data.seriesResponsibility}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <ModalInputWrapper
            label="ISSN"
            name="seriesISSN"
            value={data.seriesISSN}
            onChange={onChange}
          />
        </Col>
        <Col md={6}>
          <ModalInputWrapper
            label="Номер выпуска серии или подсерии"
            name="seriesReleaseNum"
            value={data.seriesReleaseNum}
            onChange={onChange}
          />
        </Col>
      </Row>
      <div className={styles.title}>
        Область стандартного номера (и его альтернативы) и условий доступности
      </div>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Ключевое заглавие"
            name="keyTitle"
            value={data.keyTitle}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <ModalInputWrapper
            label="Стандартный номер (или его альтернатива)"
            name="stdNum"
            value={data.stdNum}
            onChange={onChange}
          />
        </Col>
        <Col md={6}>
          <ModalInputWrapper
            label="Условия доступности и(или) цена"
            name="priceOrAccessConditions"
            value={data.priceOrAccessConditions}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Дополнительные сведения к элементам области"
            name="additionalInfo"
            value={data.additionalInfo}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ModalInputWrapper
            label="Специфические сведения"
            name="spesificalInfo"
            value={data.spesificalInfo}
            onChange={onChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdditionalDataTab;
