import React from 'react';
import PropTypes from 'prop-types';
import { ADD, EDIT } from "../../../constants/modalTypes";
import styles from "./InstitutionsTable.module.css";
import DataTable from "../../DataTable";
import DataTableCol from "../../DataTable/Column";

const InstitutionsTable = ({ initialFormFields, data, setModal, sortBy, setSort, selectedId, handleSelect, onDelete }) => {
  const openEditModal = (item) => setModal({ modalType: EDIT, data: item });
  const openCreationModal = () => setModal({ modalType: ADD, data: initialFormFields });

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <div className={styles.button} onClick={openCreationModal}>
          добавить
        </div>
        <div className={styles.button} onClick={onDelete}>
          удалить
        </div>
      </div>
      <DataTable data={data} sortBy={sortBy} onHeadClick={setSort} onCellClick={openEditModal}>
        <DataTableCol title="Название организации" value="name" colSpan={4}
                      className={styles.leftAlign}/>
        <DataTableCol title="Регион" value="subject"/>
        <DataTableCol title="Книг на учёте" value="books"/>
        <DataTableCol title="Выбрано" passClicks resolver={(item) =>
          <input type="checkbox" checked={item.idclient === selectedId}
                 onChange={() => handleSelect(item.idclient)}/>}
        />
      </DataTable>
    </div>
  );
};

InstitutionsTable.propTypes = {
  data: PropTypes.array,
  setModal: PropTypes.func,
  sortBy: PropTypes.string,
  setSort: PropTypes.func,
  selectedId: PropTypes.any,
  handleSelect: PropTypes.func,
  onDelete: PropTypes.func
};

InstitutionsTable.defaultProps = {
  data: []
};

export default InstitutionsTable;
