import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import photoPlaceholder from "../../../img/noCover.png";
import { resolveBookCoverUrl } from "../../../utils/resolvePhotoUrl";
import styles from "./BookDescriptionImage.module.css";

const BookDescriptionImage = ({url, bookId, alt}) => {
  const [src, setSrc] = useState(photoPlaceholder);

  useEffect(() => {
    setSrc(url ? resolveBookCoverUrl(url, bookId) : photoPlaceholder);
  }, [url, bookId]);

  return (
    <img onError={() => {setSrc(photoPlaceholder)}} className={styles.image} alt={alt} src={src}/>
  );
};

BookDescriptionImage.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  bookId: PropTypes.string
};

export default BookDescriptionImage;
