import React from 'react';
import PropTypes from 'prop-types';

import {Page, Text, View, Document, Note} from '@react-pdf/renderer';
import {pdfStyles as ps} from './styles';


const PDFDocument = () => {
    return (
        <Document>
            <Page size="A4" style={ps.page}>
                <View>
                    <Text style={ps.bold}>{"По п.12"}</Text>
                    <Text style={ps.bold}>{"Форма акта"}</Text>
                </View>
                <View style={ps.textRight}>
                    <Text style={ps.mt5}>Гриф секретности</Text>
                    <Text style={ps.mt5}>п. _____</Text>
                    <Text style={ps.mt5}>экз. № 1</Text>
                </View>

                <View style={ps.textCenter}>
                    <Text style={ps.mt5}>АКТ</Text>
                    <Text style={ps.mt5}>уничтожения носителей сведений, составляющих государственную тайну</Text>
                </View>

                {/* table */}
                <View style={ps.table}>
                    <View style={[ps.tableRow, ps.textCenter]}>
                        <View style={ps.tableCol5}>
                            <View style={ps.tableCol}>
                                <Text>№ п/п</Text>
                            </View>
                        </View>
                        <View style={ps.tableCol20}>
                            <View style={ps.tableCol}>
                                <Text>Наименование документа</Text>
                            </View>
                        </View>
                        <View style={ps.tableCol15}>
                            <View style={ps.tableCol}>
                                <Text>Номер документа</Text>
                            </View>
                        </View>
                        <View style={ps.tableCol15}>
                            <View style={ps.tableCol}>
                                <Text>Гриф секретности</Text>
                            </View>
                        </View>
                        <View style={ps.tableCol15}>
                            <View style={ps.tableCol}>
                                <Text>Количество экземпляров</Text>
                            </View>
                        </View>
                        <View style={ps.tableCol15}>
                            <View style={ps.tableCol}>
                                <Text>Номер экземпляра</Text>
                            </View>
                        </View>
                        <View style={ps.tableCol15}>
                            <View style={ps.tableCol}>
                                <Text>Общее количество листов</Text>
                            </View>
                        </View>
                    </View>
                </View>
                {/* table */}
                <View>
                    <Text style={ps.mt10}>
                        Всего подлежит уничтожению  пятьдесят девять наименований. Записи акта с учетными данными сверены.
                    </Text>
                </View>

                <View style={[ps.dFlex, ps.justifyBetween, ps.mt10]}>
                    <View style={[ps.tableCol30, ps.textRight]}>
                        <Text style={ps.bold}>Председатель комиссии:</Text>
                    </View>
                    <View style={ps.tableCol40}>
                        <Text>полковник</Text>
                    </View>
                    <View style={ps.tableCol20}>
                        <Text>Рогов А.Ю.</Text>
                    </View>
                </View>

                <View>
                    <Text style={ps.mt10}>
                        {'После утверждения акта перечисленные документы перед уничтожением сверили с записями в акте и полностью уничтожили путем <метод уничтожения> «___»_______________ 20___г.'}
                    </Text>
                </View>
            </Page>
        </Document>
    );
};

PDFDocument.propTypes = {};

export default PDFDocument;