import { ERROR, LIBRARY_TYPE_DELETE_REQUEST, START, SUCCESS } from "../../action-types";
import {createNotification} from "../../../../components/notification/notification";

export const libraryTypeDeleteRequestStart = (data) => ({
  type: `${LIBRARY_TYPE_DELETE_REQUEST}${START}`,
  payload: data
});

export const libraryTypeDeleteRequestSuccess = (data) => {
    createNotification('success', 'Успешно удалено');

    return {
        type: `${LIBRARY_TYPE_DELETE_REQUEST}${SUCCESS}`,
        payload: data
    };
};

export const libraryTypeDeleteRequestError = (data) => {
    createNotification('error', 'Ошибка удаления');

    return {
        type: `${LIBRARY_TYPE_DELETE_REQUEST}${ERROR}`,
        payload: data
    };
};
