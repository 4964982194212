import { api } from "../../App";

export const institutionsList = (orderBy, maxResults, paginateFrom, search) => {
  const params = {
    maxResults,
    paginateFrom,
    search,
    orderBy
  };

  return api.get('/xp_client', { params });
};

export const institutionsCount = (search) => {
  const params = {
    count: true,
    search
  };

  return api.get('/xp_client', { params });
};

export const institutionAdd = (data) => {
  return api.post('/xp_client/', data);
};

export const institutionEdit = (data) => {
  return api.put(`/xp_client/${data.idclient}`, data);
};

export const institutionDelete = (id) => {
  return api.delete(`/xp_client/${id}`);
};