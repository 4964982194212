import { SCHEDULER_ADD_REQUEST, START, SUCCESS, ERROR } from "../../action-types";
import {createNotification} from "../../../../components/notification/notification";

export const schedulerAddRequestStart = (data, callback) => ({
  type: `${SCHEDULER_ADD_REQUEST}${START}`,
  payload: data, callback
});

export const schedulerAddRequestSuccess = (data) => {
  createNotification('success', 'Успешно добавлено');

  return {
    type: `${SCHEDULER_ADD_REQUEST}${SUCCESS}`,
    payload: data
  };
};

export const schedulerAddRequestError = (error) => ({
  type: `${SCHEDULER_ADD_REQUEST}${ERROR}`,
  payload: error
});
