import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import Pagination from "../../Pagination";
import {setSchedulerCategoriesSearchData} from "../../../redux/actions/scheduler/categories/search";
import {schedulerCategoriesCountRequestStart} from "../../../redux/actions/scheduler/categories/count";
import ModalWindow from "../../ModalWindow";
import SpinnerOuter from "../../UtulityComponents/SpinnerOuter";
import SchedulerModalTable from "./Table";
import SchedulerEditModal from "./EditModal";
import {schedulerEditRequestStart} from "../../../redux/actions/scheduler/metadata/edit";


const mapStateToProps = ({scheduler}) => ({
    searchData: scheduler.categories.search,
    count: scheduler.categories.count,
    list: scheduler.categories.list,
    loader: scheduler.categories.loader,
});

const mapDispatchToProps = {
    setSearchData: setSchedulerCategoriesSearchData,
    requestList: schedulerCategoriesCountRequestStart,
    saveItem: schedulerEditRequestStart,
};

export const WEEK_DAYS = [
    {
        name: 'Все',
        number: 0
    },
    {
        name: 'Понедельник',
        number: 1
    },
    {
        name: 'Вторник',
        number: 2
    },
    {
        name: 'Среда',
        number: 3
    },
    {
        name: 'Четверг',
        number: 4
    },
    {
        name: 'Пятница',
        number: 5
    },
    {
        name: 'Суббота',
        number: 6
    },
    {
        name: 'Воскресенье',
        number: 7
    }
];

const SchedulerModal = ({
                            closeModal,
                            modalData,
                            setModalData,
                            onSubmit,
                            searchData,
                            setSearchData,
                            requestList,
                            count,
                            list,
                            loader,
                            saveItem
                        }) => {

    const {schedulename, id} = modalData;

    const [editModal, setEditModal] = useState(null);

    useEffect(() => {
        requestList(id);
    }, [requestList]);

    const closeEditModal = () => {
        setEditModal(null)
    };

    const PaginationComponent = () => (
        <Pagination
            count={count}
            pageCount={searchData.pageCount}
            current={searchData.page}
            setCurrent={(page) => {
                setSearchData({...searchData, page});
                requestList()
            }}
        />
    );

    return (
        <ModalWindow
            title={`Редактирование: ${schedulename}`}
            isOpen={true}
            onRequestClose={closeModal}
        >
            {editModal &&
                <SchedulerEditModal onRequestClose={closeEditModal} modalData={editModal} saveItem={saveItem} id={id}/>
            }
            <PaginationComponent/>
            {loader ?
                <SpinnerOuter/>
                :
                <SchedulerModalTable data={list} setModal={setEditModal}/>
            }
            <PaginationComponent/>
        </ModalWindow>
    );
};

SchedulerModal.propTypes = {
    closeModal: PropTypes.func,
    setModalData: PropTypes.func,
    modalData: PropTypes.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SchedulerModal);