import React, { useState } from "react";
import OrganizationItem from "./OrganizationItem";
import styles from "./Organizations.module.css";
import {
  institutionsToOptions,
  optionsToInstitutions
} from "../../../../utils/toSelectOption";
import SearchableSelect from "../../../Select";
import { institutionsCountRequestStart } from "../../../../redux/actions/institutions/count";
import { setInstitutionsSearchData } from "../../../../redux/actions/institutions/search";
import { connect } from "react-redux";
import Button from "../../../Button";
import {bookTransferAddRequestStart} from "../../../../redux/actions/bookTransfer/add";

const mapStateToProps = ({ institutions }) => ({
  institutionsSearch: institutions.search,
  institutionsList: institutions.list
});

const mapDispatchToProps = {
  requestInstitutions: institutionsCountRequestStart,
  setInstitutionsSearch: setInstitutionsSearchData,
  bookTransferAddRequest: bookTransferAddRequestStart
};

const Organizations = ({
  data,
  setInstitutionsSearch,
  institutionsSearch,
  requestInstitutions,
  institutionsList,
  setData,
  bookTransferAddRequest
}) => {
  const [selectedOrg, setSelectedOrg] = useState(null);

  const onInstitutionsInputChange = name => {
    setInstitutionsSearch({ ...institutionsSearch, query: name });
    requestInstitutions();
  };

  const addRequestToBookTransfer = (clientid) => {
    const params = {
      clientid,
      bookid: data.bookid
    };

    bookTransferAddRequest(params);
  };

  return (
    <div>
      <div className={"formContainer d-flex"}>
        <SearchableSelect
          placeholder="Поиск"
          onInputChange={onInstitutionsInputChange}
          options={institutionsToOptions(institutionsList)}
          onChange={setSelectedOrg}
          value={selectedOrg}
          className={styles.searchInput}
        />
        <Button
          onClick={() => {
            if (!selectedOrg) return;
            setData({
              ...data,
              clients: [
                ...optionsToInstitutions([selectedOrg]),
                ...(data.clients || [])
              ]
            });
            setSelectedOrg(null);
          }}
          label="Добавить"
        />
      </div>
      <div className={styles.organizationsContainer}>
        {data.clients &&
          data.clients.map((client, index) => (
            <OrganizationItem
              onDelete={id => {
                setData({
                  ...data,
                  clients: (data.clients || []).filter(
                    ({ id: itemId }) => id !== itemId
                  )
                });
              }}
              onTransferRequest={addRequestToBookTransfer}
              isTransferPossible={!data.files && client.filesexist}
              key={index}
              item={client}
            />
          ))}
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Organizations);
