import { INVENTORY_COUNT_REQUEST, START, SUCCESS, ERROR } from "../../action-types";

export const inventoryCountRequestStart = () => ({
  type: `${INVENTORY_COUNT_REQUEST}${START}`
});

export const inventoryCountRequestSuccess = (data) => ({
  type: `${INVENTORY_COUNT_REQUEST}${SUCCESS}`,
  payload: data
});

export const inventoryCountRequestError = (data) => ({
  type: `${INVENTORY_COUNT_REQUEST}${ERROR}`,
  payload: data
});
