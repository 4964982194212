import {PDF_VIEWER_BOOKMARK_REQUEST, PDF_VIEWER_BOOKMARKS_SET, SUCCESS} from "../../../actions/action-types";

const initialState = [];


export const pdfViewerBookmarkReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${PDF_VIEWER_BOOKMARK_REQUEST}${SUCCESS}`:
            return action.payload;
        case `${PDF_VIEWER_BOOKMARKS_SET}`:
            return action.payload;

        default:
            return state;
    }
};
