import React from 'react';
import PropTypes from 'prop-types';
import styles from "./SummaryControlTable.module.css";
import moment from "moment";
import idGen from "../../../utils/generateID";
import HTMLTablePrint from "../../PDFPrint/HTMLTablePrint";


const FundAccountingTable = ({data}) => {
    const {title, actionSumTitle, content} = data;
    const id = idGen();

    return (
        <div>
            {!!content.length &&
            <>
                <h3 className={styles.title}>{title}</h3>

                <div className={'d-flex justify-content-end'}>
                    <HTMLTablePrint table={`[id='${id}']`} title={title} orientation={"landscape"}/>
                </div>

                <table id={id} className={styles.table}>
                    <thead>
                    <tr>
                        <th colSpan={20}>Дата</th>
                        <th colSpan={35}>Поставщик</th>
                        <th colSpan={15}>Документ</th>
                        <th colSpan={10}>Сумма</th>
                        <th colSpan={10}>{actionSumTitle}</th>
                        <th colSpan={10}>Книг по фондам</th>
                    </tr>
                    </thead>
                    <tbody>
                    {content.map((item, index) =>
                        <React.Fragment key={index}>
                            {item.orderstr === -1 && <>
                                <tr className={styles.libraryTitle}>
                                    <td colSpan={70}><strong>{item.library_name}</strong></td>
                                    <td colSpan={10}>{item.summ}</td>
                                    <td colSpan={10}>{item.book_count}</td>
                                    <td colSpan={10}>{item.book_count}</td>
                                </tr>
                            </>}
                            {item.orderstr === 0 && <>
                                <tr className={styles.supplierTitle}>
                                    <td colSpan={15}>{moment(item.doc_date).format('DD.MM.YYYY')}</td>
                                    <td colSpan={40}><strong>{item.supplier_name}</strong></td>
                                    <td colSpan={15}>{item.doc_num}</td>
                                    <td colSpan={10}>{item.summ}</td>
                                    <td colSpan={10}>{item.book_count}</td>
                                    <td colSpan={10}>{item.book_count}</td>
                                </tr>

                            </>}
                            {item.orderstr === 1 && <>
                                <tr className={styles.fundTitle}>
                                    <td colSpan={70}><strong>{item.fund_name}</strong></td>
                                    <td colSpan={10}>{item.summ}</td>
                                    <td colSpan={10}>{item.book_count}</td>
                                    <td colSpan={10}>{item.book_count}</td>
                                </tr>
                            </>}
                            {item.orderstr === 2 && <>
                                <tr>
                                    {Array(item.orderstr).fill(1).map((num, index) => (
                                        <td colSpan={1} key={index}></td>
                                    ))}
                                    <td colSpan={70 - item.orderstr}>{item.udk_title}</td>
                                    <td colSpan={10}>{item.summ}</td>
                                    <td colSpan={10}>{item.book_count}</td>
                                    <td colSpan={10}>{item.book_count}</td>
                                </tr>
                            </>}

                        </React.Fragment>
                    )}
                    </tbody>
                </table>
            </>
            }
        </div>
    );
};

FundAccountingTable.propTypes = {
    data: PropTypes.object,
};

export default FundAccountingTable;
