import React from 'react';
import PropTypes from 'prop-types';
import MediaResourceEditorListItem from "../Item";

const MediaResourceEditorList = ({ list, deleteItem, editItem, deleteLogo, onUploadLogo }) => {
  return (
    <div>
      {list.map(item =>
        <MediaResourceEditorListItem
          key={`mediaResEditItem${item.id}`}
          data={item}
          deleteItem={deleteItem}
          editItem={editItem}
          deleteLogo={deleteLogo}
          onUploadLogo={onUploadLogo}
        />
      )}
    </div>
  );
};

MediaResourceEditorList.propTypes = {
  list: PropTypes.array.isRequired,
  deleteItem: PropTypes.func,
  editItem: PropTypes.func,
  onUploadLogo: PropTypes.func
};

export default MediaResourceEditorList;