import { api } from "../../App";
import { SORT_DIRECTION } from "../../constants/sortDirections";

export const authorsList = (
         orderBy,
         maxResults,
         paginateFrom,
         search,
         sortDirection,
         selectedInstitution,
         selectedLibrary
       ) => {
         const params = {
           maxResults,
           paginateFrom,
           search,
           bookcount: true,
           selectedInstitution,
           selectedLibrary
         };

         if (sortDirection === SORT_DIRECTION.ASC) params.orderBy = orderBy;
         else params.orderByD = orderBy;

         return api.get(
           `/author?maxResults=${params.maxResults}&paginateFrom=${params.paginateFrom}&search=${params.search}&bookcount=${params.bookcount}&xp_client=${params.selectedInstitution}&libraryid=${params.selectedLibrary}`
         );
       };

export const authorsCount = (search, selectedInstitution, selectedLibrary) => {
         const params = {
           count: true,
           search
         };

         return api.get(
           `/author?count=${params.count}&search=${search}&xp_client=${selectedInstitution}&libraryid=${selectedLibrary}`
         );
       };

export const authorAdd = (data) => {
  return api.post('/author', data);
};

export const authorEdit = (data) => {
  return api.put(`/author/${data.id}`, data);
};

export const authorDelete = (id) => {
  return api.delete(`/author/${id}`);
};

export const saveAuthorImage = (photo, id) => {
  const formData = new FormData();
  formData.append("photo", photo);

  const headers = [
    { 'Content-Type': 'multipart/form-data' }
  ];

  return api.post(`/author/photo/${id}`, formData, { headers });
};

export const deleteAuthorImage = (id) => {
  return api.delete(`/author/photo/${id}`);
};

export const getAuthorImage = (id) => {
  const config = {
    responseType: 'arraybuffer'
  };

  return api.get(`/author/photo/${id}`, config)
};