import { api } from "../../App";
import { SORT_DIRECTION } from "../../constants/sortDirections";




export const getBookFileList = (bookId, { orderBy, sortDirection, filename, filetype }) => {
  const params = {
    filename,
    filetype
  };
  if (sortDirection === SORT_DIRECTION.ASC) params.orderBy = orderBy;
  else params.orderByD = orderBy;

  return api.get(`/bookfiles/${bookId}`, {params});
};

export const uploadBookFile = (payload) => {
  const formData = new FormData();
  formData.append("file", payload.file);


  return api.post(`/bookfiles/${payload.bookId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    body: payload.body
  });
};

export const editBookFile = (bookId, fileId, data) => {
  return api.put(`/bookfiles/${bookId}/${fileId}`, data);
};

export const deleteBookFiles = (bookId, fileId) => {
  return api.delete(`/bookfiles/${bookId}/${fileId}`);
};

export const getBookFile = (bookId, fileId) => {
  return api.get(`/book/${bookId}/file/${fileId}`)
};