import { call, put, takeLatest } from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {QR_CODES_REQUEST, QR_CODES_SHELVES_REQUEST, START} from "../../actions/action-types";
import { qrCodesRequestError, qrCodesRequestSuccess } from "../../actions/qrcodes/list";
import { qrCodesShelvesRequestSuccess, qrCodesShelvesRequestError } from "../../actions/qrcodes/listShelves";
import { getInstancesList } from "../../../api/book/instances";
import { getShelvesQrCodes } from '../../../api/qrCodes/qrCodes';

export default function* qrCodesWatcher() {
  yield takeLatest(`${QR_CODES_REQUEST}${START}`, qrCodesWorker);
  yield takeLatest(`${QR_CODES_SHELVES_REQUEST}${START}`, qrCodesShelvesWorker);
}

function* qrCodesWorker({ payload: bookIds }) {
  try {
    const response = yield call(getInstancesList, bookIds);
    yield put(qrCodesRequestSuccess(response.data));
  } catch (error) {
    yield put(qrCodesRequestError(error));
  }
}

function* qrCodesShelvesWorker({ payload: bookIds }) {
  try {
    const response = yield call(getShelvesQrCodes, bookIds);
    yield put(qrCodesShelvesRequestSuccess(response.data));
  } catch (error) {
    yield put(qrCodesShelvesRequestError(error));
  }
}
