import { combineReducers } from "redux";
import { inventaryBookListReducer } from "./list/inventaryBookListReducer";
import { inventaryBookSearchReducer } from "./search/inventaryBookSearchReducer";
import { inventaryBookCountReducer } from "./count/inventaryBookCountReducer";

export const inventaryBookReducer = combineReducers({
  list: inventaryBookListReducer,
  searchData: inventaryBookSearchReducer,
  count: inventaryBookCountReducer
});
