import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from "./CodeItem.module.css";
import pencilIcon from "../../../../../../img/icons/pencil-edit-button.svg";
import trashIcon from "../../../../../../img/icons/delete-photo.svg";
import {udkList} from "../../../../../../api/udk/list";
import {bbkList} from "../../../../../../api/bbk/list";

const BookEditCodeItem = ({ item, index, data, setData, openModal, type }) => {
  const [codeDecryption, setCodeDecryption] = useState('-');
  useEffect(() => {
    let codeDecryptor = type === 'udk' ? udkList : type === 'bbk' ? bbkList : () => {};
    codeDecryptor(0, item)
      .then((response) => {
        if(response.data.length) {
          setCodeDecryption(response.data[0]['description']);
        }
      });
  }, [item]);

  return (
    <div className={styles.item}>
      <div className={styles.encryptionOuter}>
        <span className={styles.encryption}>{item}</span>
      </div>
      <div className={styles.decryptionOuter}>{codeDecryption}</div>
      <div className='d-flex'>
        <button
          title="Редактировать"
          className={styles.editButton}
          onClick={() => openModal({ index, type: type, value: item })}
        >
          <img src={pencilIcon} alt=""/>
        </button>
        <button
        title="Удалить"
        className={styles.deleteButton}
        onClick={() => {
          let newCodeList = [...data[type]];
          newCodeList.splice(index, 1);
          setData({...data, [type]: newCodeList});
        }}
      >
        <img src={trashIcon} alt=""/>
      </button>
      </div>
    </div>
  );
};

BookEditCodeItem.propTypes = {
  item: PropTypes.string,
  index: PropTypes.number,
  data: PropTypes.object,
  type: PropTypes.string
};

export default BookEditCodeItem;