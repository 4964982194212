import { SET_SCHEDULER_SEARCH_DATA } from "../../../../actions/action-types";
import { RESULTS_PER_PAGE } from "../../../../../constants/pagination";
import {SORT_DIRECTION} from "../../../../../constants/sortDirections";

const initialState = {
  page: 1,
  maxResults: RESULTS_PER_PAGE,
  pageCount: 0,
  orderBy: "name",
  sortDirection: SORT_DIRECTION.ASC
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCHEDULER_SEARCH_DATA:
      return action.payload;

    default: return state;
  }
};