import React, {useRef} from "react";
import PropTypes from "prop-types";
import styles from "./SearchableSelect.module.css";
import Select from "react-select";
import classNames from "classnames";
import { selectCustomStyles } from "./customStyles";
import { saveAuthorRequestStart } from '../../redux/actions/authors/save';
import { savePublishersDataRequestStart } from '../../redux/actions/publishers/save';
import { connect } from "react-redux";
import { institutionsSaveRequestStart } from "../../redux/actions/institutions/save";
import { ADD } from "../../constants/modalTypes";

const mapDispatchToProps = dispatch => ({
  saveAuthor: authorData => dispatch(saveAuthorRequestStart(authorData)),
  savePublishers: publishersData =>
    dispatch(savePublishersDataRequestStart(publishersData)),
  saveInstitution: (data) => dispatch(institutionsSaveRequestStart(data)),
});

const SearchableSelect = ({
  onInputChange,
  isMulti,
  options,
  onChange,
  value,
  placeholder,
  className,
  author,
  provider,
  publishers,
  saveAuthor,
  savePublishers,
  saveInstitution,
  data,
  setData
}) => {
  let timeout;

  const debouncedOnChange = value => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      onInputChange(value);
    }, 500);
  };

  const selectRef = useRef(null);

  const onEnter = e => {
    if(provider && (e.ctrlKey && e.key === "Enter")) {
      e.preventDefault();
      saveInstitution({
        modalType: ADD,
        data: {
          "name": e.target.value,
          "shortname": e.target.value,
        }
      });
    }

    if (author && (e.ctrlKey && e.key === "Enter")) {
      e.preventDefault();
      const fio = e.target.value.split(" ");

      for (let option of options) {
        if (option.label.split(" ")[0] === fio[0]) {
          const newAuthors = data.authors;
          newAuthors.push({name: option.label, id: option.value});
          setData({ ...data, authors: newAuthors });
          selectRef.current.blur();
          return;
        }
      }

      const authorData = {
        modalType: "ADD",
        data: {
          name: e.target.value,
          lastname: fio[0],
          firstname: fio[1],
          patronymic: fio[2],
          description: null,
          authorsign: null,
          academicdegree: null,
          birthdate: null,
          deathdate: null
        }
      };
      saveAuthor(authorData);
    }

    if (publishers && (e.ctrlKey && e.key == "Enter")) {
      e.preventDefault();
      const publishersData = {
        modalType: "ADD",
        data: {
          name: e.target.value,
          fullName: null,
          city: null,
          isbn: null
        }
      };
      savePublishers(publishersData);
    }
  };

  return (
    <div className={classNames(styles.container, className)}>
      <Select
        isSearchable
        isMulti={isMulti}
        onInputChange={onInputChange ? debouncedOnChange : undefined}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        noOptionsMessage={() => null}
        styles={selectCustomStyles}
        onKeyDown={onEnter}
        ref={selectRef}
      />
    </div>
  );
};

SearchableSelect.propTypes = {
  onInputChange: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool
};

export default connect(undefined, mapDispatchToProps)(SearchableSelect);
