import { api } from "../../App";

export const getSeries2roleCount = ({ roleid, ...otherParams }) => {
  const params = {
    count: true,
    roleid: roleid,
    ...otherParams
  };

  return api.get(`/series2role`, {params});
};

export const getSeries2role = ({ roleid, ...otherParams }) => {
  const params = {
    roleid: roleid,
    ...otherParams
  };

  return api.get(`/series2role`, {params});
};

export const addSeries2role = ({ role, seriesid, access }) => {
  return api.post(`/series2role`, {role, seriesid, access});
};

export const editSeries2role = ({ role, seriesid, access, itemid }) => {
  return api.put(`/series2role/${itemid}`, {role, seriesid, access});
};