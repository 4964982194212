import React, {useCallback, useMemo} from 'react';
import DataTableCol from "../../DataTable/Column";
import DataTable from "../../DataTable";
import styles from "./InventaryBook.module.css";
import SpinnerOuter from "../../UtulityComponents/SpinnerOuter";
import idGen from "../../../utils/generateID";


const InventaryBookTable = ({data, onCellClick}) => {

    const printFormular = () => window.print();

    const priceResolver = (price) => {
        if (!price) return 'Цена не указана';
        return price.toFixed(2) + ' руб';
    };

    const tableId = useMemo(idGen, [data.list]);

    const Table = useCallback(() => (<DataTable data={data.list} tableId={tableId} onCellClick={onCellClick}>
        <DataTableCol colSpan={10} title="Дата записи"
                      resolver={({documentdate}) => new Date(documentdate).toLocaleDateString()}/>
        <DataTableCol colSpan={10} title="Инвентарный номер" value="bookinstancenumber"/>
        <DataTableCol colSpan={10} title="Отметка о проверке фонда" value="-"/>
        <DataTableCol title="Автор и Заглавие" resolver={({title, authors}) => {
            authors = authors || [];
            authors = authors.map(item => item.name);
            return `${title} ${authors.toString()}`
        }} colSpan={30}/>
        <DataTableCol colSpan={10} title="Год издания" value="year"/>
        <DataTableCol colSpan={10} title="Цена" resolver={({price}) => priceResolver(price)}/>
        <DataTableCol colSpan={10} title="№ записи в книге сумарного учета" value=""/>
        <DataTableCol colSpan={10} title="Отдел" value="-"/>
        <DataTableCol title="№ и дата документа приема"
                      resolver={({documentnumber, documentdate, document_type_name}) => (<div>
                          <p>{document_type_name}</p> {new Date(documentdate).toLocaleDateString()} №{documentnumber}
                      </div>)} colSpan={20}/>
        <DataTableCol colSpan={10} title="Тип" value="-"/>
        <DataTableCol colSpan={10} title="№ и дата акта о выбытии" value="-"/>
        <DataTableCol colSpan={10} title="Примечание" value="-"/>
    </DataTable>), [data.list]);

    return (
        <div className={styles.container}>
            {/*{!!data.list.length && <DoublePrint table={`[id='${tableId}']`} title={'Инвентарная-Книга.pdf'} landscape fontSize={8}/>}*/}
            {data.isLoading ? (
                <SpinnerOuter/>
            ) : (
                <Table/>
            )}
        </div>
    );
};


export default InventaryBookTable;
